export const permissionConstants = {
    
    /* Visualizza gli utenti */
    SHOW_USERS: 'SHOW_USERS',

    /* Visualizza le impostazioni */
    SHOW_SETTINGS: 'SHOW_SETTINGS',

    SHOW_ARCHIVES: 'SHOW_ARCHIVES',

    /* Visualizza solo i progetti dell'utente loggato */
    SHOW_ONLY_OWN_PROJECTS: 'SHOW_ONLY_OWN_PROJECTS',

    /* Modifica i progetti */
    EDIT_PROJECTS: 'EDIT_PROJECTS',

    /* Crea i progetti */
    CREATE_PROJECTS: 'CREATE_PROJECTS',

    /* Mostra i dispositivi */
    SHOW_DEVICES: 'SHOW_DEVICES',

    /* Mostra solo gli utenti creati dall'utente loggato */
    SHOW_ONLY_OWN_USERS: 'SHOW_ONLY_OWN_USERS'
};

/*global google*/
import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input

} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Periferals from "./Periferals";
import { StartingPointsAndGroupsMap } from "./StartingPointsAndGroupsMap";
import { DRAW_MODE, SHOW_MODE, GroupsColorPalette, StartingPointsColorPalette } from "./MapConstants";
import { mapSquare, mapPin } from "../Navigator/Map";
import { MAP } from "react-google-maps/lib/constants"

class StartingPointsAndGroups extends Periferals {

    constructor(props) {
        super(props);

        const optionsGroups = this.getOptionsGroups(props);
        const optionsStartingPoints = this.getOptionsStartingPoints(props);
        const selectedStartingPoint = (optionsStartingPoints.length === 1 ? optionsStartingPoints[0] : null);
        const selectedGroup = (optionsGroups.length === 1 ? optionsGroups[0] : null);
        const indexes = this.getIndexes(props);

        if (!props.conf.configParam.groupColorPalette) {
            props.conf.configParam.groupColorPalette = GroupsColorPalette;
        }

        if (!props.conf.configParam.plColorPalette) {
            props.conf.configParam.plColorPalette = StartingPointsColorPalette;
        }

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            selection: {},
            drawMode: DRAW_MODE.NONE,
            showMode: SHOW_MODE.GROUPS,
            showEditForm: false,
            points: [],
            rectBounds: null,
            polygonPaths: [],
            optionsGroups,
            optionsStartingPoints,
            selectedStartingPoint,
            selectedGroup,
            markers: props.markers,
            showPalette: false,
            selectedColorIndex: 0,
            groupColorPalette: props.conf.configParam.groupColorPalette.slice(0),
            plColorPalette: props.conf.configParam.plColorPalette.slice(0),
            ...indexes
        }
        this.toggleRow = this.toggleRow.bind(this);
        this.handleMapMounted = this.handleMapMounted.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let indexes = this.getIndexes(nextProps);
        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem,
            markers: nextProps.markers,
            groupColorPalette: nextProps.conf.configParam.groupColorPalette.slice(0),
            plColorPalette: nextProps.conf.configParam.plColorPalette.slice(0),
            ...indexes
        });
    }

    getIndexes(props) {

        const { conf, cabinet, devices, selectedMenuItem } = props;

        let periferalsByLightPointId = {};
        let gruppi = [];
        let partenze = [];

        if (devices) {

            let expIndex = selectedMenuItem.expIndex;
            let periferalsByCode = {};
            let expansion = conf.configParam.expansions[expIndex];
            if (expansion.periferals) {
                expansion.periferals.forEach((periferal, keyper) => {
                    // periferal.code hex --> dec
                    let code = 0;
                    try {
                        code = parseInt(periferal.code, 16);
                    } catch (error) {
                        //console.log(error);
                    }
                    periferalsByCode[code] = periferal;

                    if (periferal.properties && periferal.properties.group !== null && !gruppi.includes(periferal.properties.group)) {
                        gruppi.push(periferal.properties.group);
                    }

                    if (periferal.properties && periferal.properties.pl && !partenze.includes(periferal.properties.pl)) {
                        partenze.push(periferal.properties.pl);
                    }

                });
            }

            gruppi.sort();
            partenze.sort();

            let cabinetMacAddress = cabinet.device[0].macAddress;

            // if (isNaN(cabinetMacAddress)){
            //   cabinetMacAddress = parseInt(cabinetMacAddress.split(':').join(''), 16);
            // }

            let cabinetDevices = devices.filter(
                (device) => {
                    let deviceMacAddress = device.macAddress;
                    // if (isNaN(deviceMacAddress)){
                    //   deviceMacAddress = parseInt(deviceMacAddress.split(':').join(''), 16);
                    // }
                    return (deviceMacAddress === cabinetMacAddress);
                }
            )

            cabinetDevices.map((device) => {
                if (device.lightPointId && periferalsByCode[device.addressCode]) {
                    periferalsByLightPointId[device.lightPointId] = periferalsByCode[device.addressCode];
                }
                return false;
            });
        }

        return {
            periferalsByLightPointId,
            partenze,
            gruppi
        }

    }

    render() {
        return (
            <div
            // style = { { flex: 1 }}
            >
                {this.renderMap()}
                {this.renderTable()}
            </div>
        );
    }

    toggleRow(key, index) {

        let { selection } = this.state;

        const newSelected = Object.assign({}, this.state.selection[key].selected);
        newSelected[index] = !this.state.selection[key].selected[index];

        selection[key] = {
            selected: newSelected,
            selectAll: 2
        }

        this.setState({ selection });
    }

    toggleSelectAll(key, data) {

        let { selection } = this.state;

        let newSelected = {};

        if (selection[key].selectAll === 0) {
            data.forEach(x => {
                newSelected[x.index] = true;
            });
        }

        selection[key] = {
            selected: newSelected,
            selectAll: selection[key].selectAll === 0 ? 1 : 0
        }

        this.setState({
            selection
        });
    }

    getOptionsStartingPoints(props) {
        const { conf } = props;
        let espansioniAndrosPls = [];
        if (conf.configParam && conf.configParam.expansions) {
            conf.configParam.expansions.map((exp) => {
                if (exp.type === "androsPls") {
                    espansioniAndrosPls.push(exp);
                }
                return false;
            });
        }
        let optionsStartingPoints = [];
        for (let i = 1; i <= espansioniAndrosPls.length; i++) {
            optionsStartingPoints.push({ value: i, label: "" + i });
        }
        return optionsStartingPoints;
    }

    getOptionsGroups(props) {
        const { t } = props;
        let optionsGroups = [
            { value: 0, label: t("Non installato") }
        ];
        for (let i = 1; i <= 32; i++) {
            optionsGroups.push(
                { value: i, label: "" + i }
            );
        }
        return optionsGroups;
    }

    renderTable() {

        const { t, dispatch, loading, conf, selectedMenuItem } = this.props;

        const { selection, editingIndex, editingItem, optionsStartingPoints, optionsGroups } = this.state;

        let key = selectedMenuItem.key;

        let expIndex = selectedMenuItem.expIndex;

        let data = [];

        let expansion = conf.configParam.expansions[expIndex];

        let title = t("Partenze e gruppi"); // +" - "+this.getExpansionName(expansion.type)+ " " + expansion.address;

        if (expansion.periferals) {

            expansion.periferals.forEach((periferal, keyper) => {
                if (periferal.properties === null) {
                    periferal.properties = {
                        label: ""
                    };
                }
                periferal.index = expIndex + "_" + keyper;
                data.push(periferal);
            });
        }

        if (!this.state.selection[key]) {
            const selection = {
                ...this.state.selection,
                [key]: {
                    selected: {},
                    selectAll: 0
                }
            };
            this.setState({ selection });
            return null;
        }

        return (

            <Card key={key}>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >
                        <span style={{ flex: 1 }}>{title}</span>
                        {/* <Button
                  color="info"
                  onClick={

                    ()=>{

                      if (this.state.isNew ){
                        return;
                      }

                      const index = expIndex+"_0";
                      let item = {
                        type: "SYRAS",
                        index,
                        code: ""+nextCodes.SYRAS.codeHi+(nextCodes.SYRAS.codeLo++).toString(16).padStart(2,'0').toUpperCase(),
                        properties: {
                          label: "",
                          pl: "",
                          group: "",
                        }
                      };

                      if (!conf.configParam.expansions[expIndex].periferals){
                         conf.configParam.expansions[expIndex].periferals=[];
                      }
                      conf.configParam.expansions[expIndex].periferals.unshift(item);
                      this.setState( { isNew: true, isNewTarget: conf.configParam.expansions[expIndex].periferals, conf, editingIndex: expIndex+"_0", editingItem: item });

                    }

                  }
                  style={{  fontSize: 14, fontWeight: 400, margin: 0, padding: '5px'}}
                >
                {t("Nuovo")}
                </Button> */}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        data={data}
                        className="-striped -highlight configurations"
                        pageSize={data && data.length}
                        style={{
                            height: "calc ( 100vh - 180px) " // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        showPagination={false}
                        NoDataComponent={() => null}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[

                            {
                                id: "checkbox",
                                accessor: "",
                                Cell: ({ original }) => {
                                    return (
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={selection[key].selected[original.index] === true}
                                            onChange={() => this.toggleRow(key, original.index)}
                                        />
                                    );
                                },
                                Header: x => {
                                    return (
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={selection[key].selectAll === 1}
                                            ref={input => {
                                                if (input) {
                                                    input.indeterminate = (selection[key].selectAll === 2);
                                                }
                                            }}
                                            onChange={() => this.toggleSelectAll(key, data)}
                                        />
                                    );
                                },
                                sortable: false,
                                width: 45
                            },

                            {
                                Header: t("Etichetta"),
                                accessor: "properties.label",
                                filterable: false,
                                sortable: true,
                                Cell: (row) => {
                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.properties.label}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.properties.label = value;
                                                    this.setState({ editingItem })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="left">{row.value}</div>)
                                    }
                                }
                            },
                            // {
                            //   Header: t("Espansione"),
                            //   accessor: "index",
                            //   filterable: false,
                            //   Cell: (row)=>{

                            //         const isEditing = (row.original.index===editingIndex);

                            //         if (isEditing){

                            //           const index = editingItem.index.split('_');

                            //           const selectedOption = this.getSelected(optionsTipi,index[0]);

                            //           return (
                            //               <Select
                            //                 // menuContainerStyle = \{ \{position: 'relative'\} \}
                            //                 placeholder={t("Espansione")}
                            //                 name="group"
                            //                 value={ selectedOption }
                            //                 options={ optionsTipi }
                            //                 onChange={option => {
                            //                   if (option){

                            //                     let precIndex = editingItem.index.split("_");
                            //                     if (precIndex[0] !== option.value){
                            //                       // rimuovo l'elemento dalla vecchia posizione
                            //                       conf.configParam.expansions[precIndex[0]].periferals.splice(precIndex[1],1);
                            //                       // lo aggiungo alla nuova
                            //                       editingItem.index = option.value+"_0";
                            //                       conf.configParam.expansions[option.value].periferals.unshift(editingItem);
                            //                     }
                            //                     this.setState({ editingItem })
                            //                   }
                            //                 }}
                            //               />
                            //           );

                            //       } else {

                            //             const index = row.value.split('_');
                            //             const selectedOption = this.getSelected(optionsTipi,index[0]);
                            //             return (<span>{selectedOption && selectedOption.label}</span>);
                            //           }
                            //         }
                            // },

                            // {
                            //   Header: t("Indirizzo"),
                            //   accessor: "subCode",
                            //   filterable: false,
                            //   sortable: true,
                            //   Cell: (row) => {
                            //         return ( <div align="right">{ row.value }</div>  )
                            //   },

                            // },

                            {
                                Header: t("Codice"),
                                accessor: "code",
                                filterable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.code}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.code = value;
                                                    this.setState({ editingItem })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="left">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Partenza"),
                                accessor: "properties.pl",
                                filterable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        const selectedOption = this.getSelected(optionsStartingPoints, editingItem.properties.pl);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Partenza")}
                                                name="pl"
                                                value={selectedOption}
                                                options={optionsStartingPoints}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.properties.pl = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsStartingPoints, row.value);
                                        return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    }

                                    // let isEditing = (row.original.index===editingIndex);
                                    // if (isEditing){
                                    //   return (
                                    //     <Input
                                    //           type="text"
                                    //           value= {editingItem.properties.pl}
                                    //           onChange = { (e)=>{
                                    //             const { name, value } = e.target;
                                    //             editingItem.properties.pl = value;
                                    //             this.setState({ editingItem })

                                    //           }}
                                    //       />);
                                    //   } else {
                                    //     return ( <div className="no-overflow" align="left">{ row.value }</div>  )
                                    // }

                                }

                            },

                            {
                                Header: t("Gruppo"),
                                accessor: "properties.group",
                                filterable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        const selectedOption = this.getSelected(optionsGroups, editingItem.properties.group);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Gruppo")}
                                                name="group"
                                                value={selectedOption}
                                                options={optionsGroups}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.properties.group = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsGroups, row.value);
                                        return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    }

                                    // let isEditing = (row.original.index===editingIndex);
                                    // if (isEditing){
                                    //   return (
                                    //     <Input
                                    //           type="text"
                                    //           value= {editingItem.properties.group}
                                    //           onChange = { (e)=>{
                                    //             const { name, value } = e.target;
                                    //             editingItem.properties.group = value;
                                    //             this.setState({ editingItem })

                                    //           }}
                                    //       />);
                                    //   } else {
                                    //     return ( <div align="left">{ row.value }</div>  )
                                    // }

                                }

                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"per3_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.original.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>
                                                {" "}

                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"per3_del_" + row.index}
                                                    onClick={() => {

                                                        this.confirmDelete(
                                                            t("Elimina dispositivo lampada"),
                                                            () => {

                                                                const index = row.original.index.split("_");
                                                                let periferals = conf.configParam.expansions[index[0]].periferals.filter(
                                                                    ((curr, index_per) => {
                                                                        return (index_per !== index[1])
                                                                    })
                                                                );
                                                                conf.configParam.expansions[index[0]].periferals = periferals;
                                                                dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                this.setState({
                                                                    alert: null
                                                                });

                                                            }

                                                        );

                                                    }}
                                                    color="danger"
                                                    size="sm"
                                                    tooltipText={t("Elimina")}
                                                >
                                                    <i className="fa fa-trash" />
                                                </ButtonWithTooltip>

                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"per3_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        const index = editingItem.index.split("_");
                                                        conf.configParam.expansions[index[0]].periferals[index[1]] = editingItem;

                                                        // se c'è una selezione, applico le modifiche a tutti gli elementi
                                                        let selectedIndexes = [];
                                                        if (selection[key].selected) {
                                                            const indexes = Object.keys(selection[key].selected);
                                                            selectedIndexes = indexes.filter((x) => selection[key].selected[x] === true);

                                                            selectedIndexes.forEach((checkedIndex) => {

                                                                if (checkedIndex !== editingItem.index) {
                                                                    const cheind = checkedIndex.split("_");

                                                                    // è cambiata la partenza ?
                                                                    if (editingItem.properties.pl !== row.original.properties.pl) {
                                                                        conf.configParam.expansions[cheind[0]].periferals[cheind[1]].properties.pl = editingItem.properties.pl;
                                                                    }

                                                                    // è cambiato il gruppo ?
                                                                    if (editingItem.properties.group !== row.original.properties.group) {
                                                                        conf.configParam.expansions[cheind[0]].periferals[cheind[1]].properties.group = editingItem.properties.group;
                                                                    }
                                                                }

                                                            });

                                                        }

                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"per3_del_" + row.index}
                                                    onClick={() => {
                                                        if (this.state.isNew) {
                                                            const index = row.original.index.split("_");
                                                            conf.configParam.expansions[index[0]].periferals.splice(index[1], 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }
                        ]}
                    />
                </CardBody>
            </Card>
        )
    }

    handleMapMounted(map) {
        if (map) {
            this.setState({
                map
            })
        }
    }

    getColor(marker) {
        const { showMode, periferalsByLightPointId, groupColorPalette, plColorPalette } = this.state;

        if (marker.selected) {
            return "lightgray";
        }

        const lightPointId = marker.item.id;

        let periferal = periferalsByLightPointId[lightPointId];

        if (periferal && periferal.properties) {
            if (showMode === SHOW_MODE.GROUPS) {
                return groupColorPalette[periferal.properties.group];
            }
            else if (showMode === SHOW_MODE.STARTING_POINTS) {
                return plColorPalette[periferal.properties.pl];
            }
        }
        return "gray";
    }

    getMapMarkers() {
        const { cabinet } = this.props;
        const { markers } = this.state;

        let mapMarkers = [];
        if (!cabinet) {
            return mapMarkers;
        }
        markers && markers.map((marker) => {

            if (marker.type === 0 && marker.item && (marker.item.id !== cabinet.id)) {
                return false;
            }

            if (marker.type === 1 && marker.item && (marker.item.cabinetId !== cabinet.id)) {
                return false;
            }

            let mapMarker = Object.assign({}, marker);
            if (mapMarker.type === 0) {
                mapMarker.icon = {
                    ...mapSquare,
                    fillColor: "gray",
                    strokeColor: "darkgray",
                    strokeWeight: 1
                };
            } else {

                mapMarker.icon = {
                    ...mapPin,
                    fillColor: this.getColor(marker),
                    strokeColor: "darkgray",
                    strokeWeight: 1
                };
            }
            mapMarkers.push(mapMarker);

            return false;
        });

        return mapMarkers;

    }

    renderMap() {
        let { map, partenze, gruppi } = this.state;
        let { cabinet, selectedProject } = this.props;
        let center = cabinet && cabinet.geometry;
        let zoom = (map ? map.getZoom() : selectedProject.zoom);
        let mapMarkers = this.getMapMarkers();

        return (

            <Card
                className=""
            >
                <CardBody className="no-padding">

                    <StartingPointsAndGroupsMap
                        id={"stgrMap"}
                        loadingElement={<div style={{ height: "50vh" }} />}
                        containerElement={<div style={{ height: "50vh", borderRadius: "0.1875rem", boxShadow: "0 1px 15px 1px rgba(39, 39, 39, 0.1" }} />}
                        mapElement={<div style={{ height: "50vh", borderRadius: "0.1875rem" }} />}
                        handleMapMounted={this.handleMapMounted}
                        center={center}
                        zoom={zoom}
                        mapMarkers={mapMarkers}
                        {...this.state}
                        {...this.props}
                        gruppi={gruppi}
                        partenze={partenze}
                        onClickShowMode={this.handleClickShowMode.bind(this)}
                        onClickDrawMode={this.handleClickDrawMode.bind(this)}
                        onMapClick={this.handleMapClick.bind(this)}
                        onMapDblClick={this.handleMapDblClick.bind(this)}
                        onMouseMove={this.handleMouseMove.bind(this)}
                        onChangeSelect={this.handleChangeSelect.bind(this)}
                        onCancelClick={this.handleCancelClick.bind(this)}
                        onSaveClick={this.handleSaveClick.bind(this)}
                        onColorClick={this.handleColorClick.bind(this)}
                        onPaletteCancelClick={this.handlePaletteCancelClick.bind(this)}
                        onColorChange={this.handleColorChange.bind(this)}
                        onPaletteSaveClick={this.handlePaletteSaveClick.bind(this)}
                    />

                </CardBody>
            </Card>

        );

    }

    handleClickDrawMode(mode) {

        const { map } = this.state;

        if (map && map.context[MAP]) {
            map.context[MAP].setOptions({ draggableCursor: (mode === DRAW_MODE.NONE ? "default" : "crosshair") });
            return false
        }

        this.setState({
            drawMode: mode,
            points: [],
            rectBounds: null,
            polygonPaths: []
        });

    }

    handleClickShowMode(target) {
        this.setState(
            { showMode: target }
        );
    }

    handleMapClick(e) {

        let { drawMode, points } = this.state;

        points.push(
            e.latLng
        );

        if ((drawMode === DRAW_MODE.RECTANGLE) && points.length === 2) {
            this.setState({
                showEditForm: true,
                points: [],
            });
            return;
        }

        this.setState({
            points
        });

    }

    handleMapDblClick() {
        const { drawMode, points } = this.state;
        if ((drawMode === DRAW_MODE.POLYGON) && points.length > 1) {
            this.setState({
                showEditForm: true
            });
        }
    }

    handleMouseMove(e) {

        let { rectBounds, points, drawMode, showEditForm } = this.state;

        if (drawMode === DRAW_MODE.NONE || showEditForm) {
            return;
        }

        if (drawMode === DRAW_MODE.RECTANGLE && points.length === 2) {
            return;
        }

        const a = e.latLng;
        var poly = null;

        if (drawMode === DRAW_MODE.RECTANGLE) {

            if (points.length > 0) {
                const b = points[points.length - 1];
                if (a.lat() <= b.lat() && a.lng() >= b.lng()) {
                    rectBounds = {
                        north: a.lat(),
                        east: a.lng(),
                        south: b.lat(),
                        west: b.lng(),
                    }
                }
                else if (a.lat() <= b.lat() && a.lng() < b.lng()) {
                    rectBounds = {
                        north: a.lat(),
                        east: b.lng(),
                        south: b.lat(),
                        west: a.lng(),
                    }
                }
                else if (a.lat() > b.lat() && a.lng() >= b.lng()) {
                    rectBounds = {
                        north: b.lat(),
                        east: a.lng(),
                        south: a.lat(),
                        west: b.lng(),
                    }
                }
                else if (a.lat() > b.lat() && a.lng() < b.lng()) {
                    rectBounds = {
                        north: b.lat(),
                        east: b.lng(),
                        south: a.lat(),
                        west: a.lng(),
                    }
                }
            }

            if (rectBounds) {
                poly = new google.maps.Polygon({
                    paths: [
                        new google.maps.LatLng(rectBounds.north, rectBounds.east),
                        new google.maps.LatLng(rectBounds.north, rectBounds.west),
                        new google.maps.LatLng(rectBounds.south, rectBounds.west),
                        new google.maps.LatLng(rectBounds.south, rectBounds.east)
                    ]
                });
                this.selectMarkers(poly);
            }

            this.setState({
                rectBounds
            });

        }

        else if (drawMode === DRAW_MODE.POLYGON) {

            let polygonPaths = [];
            points.map((point) => {
                polygonPaths.push(point);
                return false;
            });

            polygonPaths.push(a);

            let poly = new google.maps.Polygon({
                paths: polygonPaths
            });

            this.selectMarkers(poly);

            this.setState({
                polygonPaths
            });

        }

    }

    selectMarkers(poly) {
        let { markers } = this.state;
        // ciclo sui markers
        markers.map((marker) => {
            if (google.maps.geometry && poly && marker.item.geometry) {
                let point = new google.maps.LatLng(marker.item.geometry.lat, marker.item.geometry.lng);
                if (google.maps.geometry.poly.containsLocation(point, poly)) {
                    marker.selected = true;
                }
                else {
                    marker.selected = false;
                }
            }
            return false;
        });
    }

    handleChangeSelect(showMode, option) {
        if (showMode === SHOW_MODE.STARTING_POINTS) {
            this.setState({
                selectedStartingPoint: option
            });
        }
        else if (showMode === SHOW_MODE.GROUPS) {
            this.setState({
                selectedGroup: option
            });
        }

    }

    resetMapControls() {
        const { markers } = this.state;
        // deseleziono tutti i marker
        markers.map((marker) => {
            marker.selected = false;
            return false;
        });
        this.setState({
            points: [],
            showEditForm: false,
            rectBounds: null,
            polygonPaths: []
        });
    }

    handleCancelClick() {
        this.resetMapControls();
    }

    // countSelected(){
    //   const { markers } = this.state;
    //   let conto=0;
    //   markers.map((marker) => {
    //     if (marker.selected) {
    //       conto++;
    //     }
    //   });
    //   return conto;
    // }

    handleSaveClick() {

        const { periferalsByLightPointId, selectedStartingPoint, selectedGroup, markers } = this.state;
        const { dispatch, conf } = this.props;
        markers.map((marker) => {
            if (marker.selected) {
                let periferal = periferalsByLightPointId[marker.item.id];
                if (periferal) {
                    if (!periferal.properties) {
                        periferal.properties = {};
                    }
                    if (selectedGroup)
                        periferal.properties.group = selectedGroup.value;
                    if (selectedStartingPoint)
                        periferal.properties.pl = selectedStartingPoint.value;
                }
            }
            return false;
        });
        conf.dateUpdate = moment().toISOString(true);
        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
        this.resetMapControls();
    }

    handleColorClick(index) {
        this.setState({
            showPalette: true,
            selectedColorIndex: index
        });
    }

    handlePaletteCancelClick() {
        const { showMode } = this.state;
        const { conf } = this.props;
        if (showMode === SHOW_MODE.GROUPS) {
            this.setState({
                showPalette: false,
                groupColorPalette: conf.configParam.groupColorPalette.slice(0)
            });
        }
        else {
            this.setState({
                showPalette: false,
                plColorPalette: conf.configParam.plColorPalette.slice(0)
            });
        }
    }

    handleColorChange(color) {
        const { showMode, groupColorPalette, plColorPalette, selectedColorIndex } = this.state;
        if (showMode === SHOW_MODE.GROUPS) {
            groupColorPalette[selectedColorIndex] = color.hex;
            this.setState({
                groupColorPalette
            });
        }
        else {
            plColorPalette[selectedColorIndex] = color.hex;
            this.setState({
                plColorPalette
            });

        }
    }

    handlePaletteSaveClick() {
        const { dispatch, conf } = this.props;
        const { showMode, groupColorPalette, plColorPalette } = this.state;
        if (showMode === SHOW_MODE.GROUPS) {
            conf.configParam.groupColorPalette = groupColorPalette;
        }
        else {
            conf.configParam.plColorPalette = plColorPalette;
        }
        conf.dateUpdate = moment().toISOString(true);
        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
        this.setState({
            showPalette: false
        });

    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default StartingPointsAndGroups = withTranslation()(connect(mapStateToProps)(StartingPointsAndGroups));

import React, { Fragment } from "react";
import { withTranslation } from 'react-i18next';
import logo from "assets/img/umpi-brand-small.png";

let toggleLoader = function (toggleValue) {
    try {
        this.toggle(toggleValue);
    }
    catch (e) { /* DO NOTHING */ }
}

class Loader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSpinnerOpen: false,
            isReloadEnabled: false,
        };

        this.toggle = this.toggle.bind(this);

        toggleLoader = toggleLoader.bind(this);

        this.timeoutSpinner = 0;
        this.timeoutForceReload = 0;
    }

    toggle(toggleValue) {
        const setTO = () => {
            this.setState({
                isSpinnerOpen: toggleValue
            }, (toggleValue) ? () => {
                this.timeoutForceReload = setTimeout(() => {
                    this.setState({
                        isReloadEnabled: true
                    });
                }, 8000);
            } : null);
        };

        clearTimeout(this.timeoutSpinner);
        clearTimeout(this.timeoutForceReload);

        if (toggleLoader) {
            this.timeoutSpinner = setTimeout(setTO, 250);
        } else {
            setTO();
        }
    }

    render() {
        let { t } = this.props;
        let { isSpinnerOpen, isReloadEnabled } = this.state;

        return (
            <Fragment>{isSpinnerOpen &&
                <div className="umpi-spinner-loader">
                    <img src={logo} alt="minosx-logo" /><br />
                    <i className="fa fa-spinner fa-spin" /><br />
                    {isReloadEnabled &&
                        <span onClick={() => window.location.reload()}>{
                            t('Click here to reload')
                        }</span>
                    }
                </div>
            }</Fragment>
        );
    }
}

export default Loader = withTranslation()(Loader);
export { toggleLoader };

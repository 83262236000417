import React from "react";
import { Button } from "components";
import { UncontrolledTooltip } from "reactstrap";

class ButtonWithTooltip extends React.Component {
    render() {
        // `this.props.id` ci deve essere, altrimenti non si riesce a legare il tooltip
        const id = this.props.id
        if (!id) {
            throw new Error('prop "id" is needed by Tooltip component.')
        }
        return (
            <span className="custom-display-inline-block">
                <Button
                    id={id}
                    onClick={this.props.onClick || (() => {})}
                    color={this.props.color}
                    size={this.props.size}
                    round
                    icon={!this.props.notOnlyIconBtn}
                    disabled={this.props.disabled}
                    {...(
                        !this.props.className ?
                        {}
                        :
                        {
                            className: this.props.className
                        }
                    )}
                >
                    {this.props.children}
                </Button>
                {
                    !this.props.disabled
                    &&
                    <UncontrolledTooltip placement={this.props.placement || "left"} target={id} delay={0} >
                        {this.props.tooltipText}
                    </UncontrolledTooltip>
                }
            </span>
        );
    }
}

export default ButtonWithTooltip

import React from "react";
export default function SignalBars(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.62597 439.532"
            height={props.height}
            width={props.width}
            y="0px"
            x="0px"
        >
            <title>{props.title}</title>

            <g
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
                transform="translate(0.5,-36.047)"
            >
                <path
                    fill={props.strength > 0 ? props.fill : "none"}
                    d="M 63.953,401.992 H 9.135 c -2.666,0 -4.853,0.855 -6.567,2.567 C 0.859,406.271 0,408.461 0,411.126 v 54.82 c 0,2.665 0.855,4.855 2.568,6.563 1.714,1.718 3.905,2.57 6.567,2.57 h 54.818 c 2.663,0 4.853,-0.853 6.567,-2.57 1.712,-1.708 2.568,-3.898 2.568,-6.563 v -54.82 c 0,-2.665 -0.856,-4.849 -2.568,-6.566 -1.714,-1.712 -3.904,-2.568 -6.567,-2.568 z"
                />
                <path
                    fill={props.strength > 1 * props.step ? props.fill : "none"}
                    d="m 173.589,365.451 h -54.818 c -2.667,0 -4.854,0.855 -6.567,2.566 -1.711,1.711 -2.568,3.901 -2.568,6.563 v 91.358 c 0,2.669 0.854,4.859 2.568,6.57 1.713,1.711 3.899,2.566 6.567,2.566 h 54.818 c 2.663,0 4.853,-0.855 6.567,-2.566 1.709,-1.711 2.568,-3.901 2.568,-6.57 V 374.58 c 0,-2.662 -0.859,-4.853 -2.568,-6.563 -1.714,-1.71 -3.905,-2.566 -6.567,-2.566 z"
                />
                <path
                    fill={props.strength > 2 * props.step ? props.fill : "none"}
                    d="m 283.225,292.36 h -54.818 c -2.667,0 -4.854,0.855 -6.567,2.566 -1.711,1.711 -2.57,3.901 -2.57,6.563 v 164.456 c 0,2.665 0.856,4.855 2.57,6.563 1.713,1.718 3.899,2.57 6.567,2.57 h 54.818 c 2.665,0 4.855,-0.853 6.563,-2.57 1.711,-1.708 2.573,-3.898 2.573,-6.563 V 301.49 c 0,-2.662 -0.862,-4.853 -2.573,-6.563 -1.707,-1.711 -3.898,-2.567 -6.563,-2.567 z"
                />
                <path
                    fill={props.strength > 3 * props.step ? props.fill : "none"}
                    d="m 392.857,182.725 h -54.819 c -2.666,0 -4.856,0.855 -6.57,2.568 -1.708,1.714 -2.563,3.901 -2.563,6.567 v 274.086 c 0,2.665 0.855,4.855 2.563,6.563 1.714,1.718 3.904,2.57 6.57,2.57 h 54.819 c 2.666,0 4.856,-0.853 6.563,-2.57 1.718,-1.708 2.57,-3.898 2.57,-6.563 V 191.86 c 0,-2.666 -0.853,-4.853 -2.57,-6.567 -1.707,-1.713 -3.897,-2.568 -6.563,-2.568 z"
                />
                <path
                    fill={props.strength > 4 * props.step ? props.fill : "none"}
                    d="m 509.06,39.115 c -1.718,-1.714 -3.901,-2.568 -6.57,-2.568 h -54.816 c -2.662,0 -4.853,0.854 -6.567,2.568 -1.714,1.709 -2.569,3.899 -2.569,6.563 v 420.268 c 0,2.665 0.855,4.855 2.569,6.563 1.715,1.718 3.905,2.57 6.567,2.57 h 54.816 c 2.669,0 4.853,-0.853 6.57,-2.57 1.711,-1.708 2.566,-3.898 2.566,-6.563 V 45.679 c 0,-2.664 -0.855,-4.854 -2.566,-6.564 z"
                />
            </g>

        </svg>
    )
}

import { userConstants } from '../constants';
import { userService, userroleService, projectService } from '../services';
import { history } from '../helpers';
import i18n from '../i18n';

export const authenticationActions = {
    login,
    logout
};

/*
    Autentica l'utente passato come argomento.
    Se le credenziali sono corrette, memorizza il token di autorizzazione e carica altri dati,
    altrimenti genera un errore.
    @param {String} username utente.
    @param {String} password utente.
    @return
*/
function login(username, password) {
    return async (dispatch) => {
        dispatch(request({ username }));

        try {
            let token = await userService.login(username, password);
            let user = await userService.getById(token.userId);

            localStorage.setItem('user', JSON.stringify(user));
            i18n.changeLanguage(user.language);

            let userroles = await userroleService.getAll();
            localStorage.setItem('userroles', JSON.stringify(userroles));

            // TODO scaricare solo i progetti associati all'utente
            /*const filter = {
                include: {
                    relation: 'users',
                    scope: {
                        fields: ['id'],
                        where: {
                            id: user.id
                        }
                    }
                }
            }

            let projects = await projectService.getAll(filter);*/
            let projects = await projectService.getAllByUser(user);

            // se utente superuserumpi -> tutti
            /*projects = projects.filter((project)=>{
                let ok = false;
                if (project.users){
                    for(const prjuser of project.users){
                        if (prjuser.id==user.id){
                            ok=true;
                            break;
                        }
                    }
                }
                return ok;
            });*/

            localStorage.setItem('projects', JSON.stringify(projects));
            dispatch(success(user, projects));

            // let users = await userService.getAll();
            // localStorage.setItem('users', JSON.stringify(users));

            // let devicetypes = await devicetypeService.getAll();
            // localStorage.setItem('devicetypes', JSON.stringify(devicetypes));

            history.push('/');
        }
        catch (error) {
            dispatch(failure(error))
        }
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user, projects) { return { type: userConstants.LOGIN_SUCCESS, user, projects } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

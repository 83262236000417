import React, { Component } from "react";

export default class ChartLoader extends Component {
    render() {

        if (this.props.loading) {
            return (
                <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <span></span>
                </div>
            )
        }
        else {
            return null;
        }

    }
}

import BaseView from "./BaseView";

export default class Periferals extends BaseView {

    /*constructor(props) {
        super(props);
    }*/

    getOptionsEspansioni() {
        const { conf } = this.props;
        let optionsEspansioni = [];
        if (conf.configParam && conf.configParam.expansions) {
            conf.configParam.expansions.map((exp, key) => {
                if (exp.type !== "wattmeter") {
                    const label = exp.address + " - " + this.getExpansionName(exp.type);
                    optionsEspansioni.push({ value: key, label });
                }
                return false;
            });
        }
        // le ordino per label
        optionsEspansioni && optionsEspansioni.sort((a, b) => {
            return a.label > b.label;
        });
        return optionsEspansioni;
    }

    getPeriferalsByType(type) {

        const { conf } = this.props;
        let data = [];

        if (conf && conf.configParam && conf.configParam.expansions) {
            conf.configParam.expansions.forEach((expansion, keyexp) => {
                // ciclo sulle periferiche dell'espansione
                if (expansion.periferals) {
                    expansion.periferals.forEach((periferal, keyper) => {
                        // se mancano le properties le creo con etichetta vuota
                        if (periferal.properties === null) {
                            periferal.properties = {
                                label: ""
                            };
                        }
                        // indicizzo la periferica --> posizione espansione concatenata a posizione periferica
                        periferal.index = keyexp + "_" + keyper;
                        if (periferal.type === type) {
                            data.push(periferal);
                        }
                    });
                }
            });
        }
        return data;
    }

    getNextCodes() {
        const { params } = this.props;
        // contatori per l'assegnamento del campo code
        let nextCodes = {};
        if (params.androsLiveParams && params.androsLiveParams.periferals) {
            let types = Object.keys(params.androsLiveParams.periferals);
            types.map((type) => {
                const periferal = params.androsLiveParams.periferals[type];
                nextCodes[type] = {
                    codeHi: periferal.addressCodeHi,
                    codeLo: 0
                };
                return false;
            });
        }
        return nextCodes;
    }

}

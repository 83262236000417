import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
} from "reactstrap";
import { Button } from "components";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Commands from "./Commands";

class CommandsDigitalInput extends Commands {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            sorted: []
        }

    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);
    }

    render() {

        const { loading, conf, t, dispatch } = this.props;
        const { editingIndex, editingItem } = this.state;

        let dataInp = this.getCommandsByType("EVE_TYPE_IN");
        let optionsOuts = this.getOptionsOuts();
        let optionsInps = this.getOptionsInps();
        //let optionsDay = this.getOptionsDay();
        let optionsEvent = this.getOptionsEvent();

        return (
            <Card>

                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >

                        <span style={{ flex: 1 }}>{t("Ingressi digitali")}</span>
                        <Button
                            disabled={true}
                            color="primary"
                            onClick={

                                () => {
                                    if (this.state.isNew) {
                                        return;
                                    }
                                    let item = {
                                        type: 'EVE_TYPE_IN',
                                        input: "",
                                        event: "",
                                        output: "",
                                        value: 0
                                    };
                                    if (!conf.configParam.commands) {
                                        conf.configParam.commands = [];
                                    }
                                    conf.configParam.commands.unshift(item);
                                    this.setState({ isNew: true, isNewTarget: conf.configParam.commands, conf, editingIndex: 0, editingItem: item, sorted: [] });
                                }

                            }
                            style={{ fontSize: 14, fontWeight: 400, margin: 0, padding: '5px' }}
                        >
                            {t("Nuovo")}
                        </Button>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        data={dataInp}
                        className="-striped -highlight configurations"
                        pageSize={dataInp && dataInp.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        sorted={this.state.sorted}
                        onSortedChange={(newSorted) => { this.setState({ sorted: newSorted }) }}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[
                            {
                                Header: t("Ingresso"),
                                accessor: "input",
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsInps, editingItem.input);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Comando")}
                                                name="group"
                                                value={selectedOption && selectedOption.value}
                                                options={optionsInps}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.input = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    }
                                    else {
                                        const selectedOption = this.getSelected(optionsInps, row.value);
                                        const label = (selectedOption ? selectedOption.label : "");

                                        return (<div className="no-overflow" >{label}</div>);
                                    }
                                }

                            },
                            {
                                Header: t("Evento"),
                                accessor: "event",
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsEvent, editingItem.event);

                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Evento")}
                                                name="group"
                                                value={selectedOption}
                                                options={optionsEvent}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.event = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsEvent, row.value);
                                        return (<span>{selectedOption && selectedOption.label}</span>);
                                    }
                                }
                            },
                            {
                                Header: t("Comando"),
                                accessor: "output",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsOuts, editingItem.output);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Comando")}
                                                name="group"
                                                value={selectedOption && selectedOption.value}
                                                options={optionsOuts}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.output = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    }
                                    else {
                                        const selectedOption = this.getSelected(optionsOuts, row.value);
                                        const label = (selectedOption ? selectedOption.label : "");

                                        return (<div className="no-overflow" >{label}</div>);
                                    }
                                }

                            },

                            {
                                Header: t("Valore"),
                                accessor: "value",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {
                                    return this.renderValue('value', row, editingIndex, editingItem, optionsOuts);

                                    // let isEditing = (row.original.index === editingIndex);
                                    // if (isEditing) {
                                    //   return (
                                    //     <Input
                                    //       type="number"
                                    //       value={editingItem.value}
                                    //       onChange={(e) => {
                                    //         const { name, value } = e.target;
                                    //         editingItem.value = value;
                                    //         this.setState({ editingItem })
                                    //       }}
                                    //     />);
                                    // } else {
                                    //   return (<div className="no-overflow" align="">{row.value}</div>)
                                    // }
                                }

                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com3_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com3_del_" + row.index}
                                                    onClick={() => {

                                                        this.confirmDelete(
                                                            t("Elimina ingresso digitale"),
                                                            () => {

                                                                let commands = conf.configParam.commands.filter(
                                                                    ((curr, index) => {
                                                                        return (index !== row.original.index)
                                                                    })
                                                                );
                                                                conf.configParam.commands = commands;
                                                                dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                this.setState({
                                                                    alert: null
                                                                });

                                                            }

                                                        );

                                                    }}
                                                    color="danger"
                                                    size="sm"
                                                    tooltipText={t("Elimina")}
                                                >
                                                    <i className="fa fa-trash" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"com3_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        conf.configParam.commands[row.original.index] = editingItem;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"com3_del_" + row.index}
                                                    onClick={() => {

                                                        if (this.state.isNew) {
                                                            conf.configParam.commands.splice(row.original.index, 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    //defaultPageSize={10}
                    />
                </CardBody>
            </Card>
        )
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default CommandsDigitalInput = withTranslation()(connect(mapStateToProps)(CommandsDigitalInput));

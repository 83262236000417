import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Button, Documents } from "components";
import { loopbackActions } from '../../actions';
import { loopbackService } from '../../services';
import ErrorModal from "../Components/ErrorModal"
import { withTranslation } from 'react-i18next';
import { roleConstants } from '../../constants';
import User from '../../entities/User';

class SupportEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentSupportId: 0,
            mediaStorage: [],
            mediaPreview: null,
            support: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuemnts = this.handleDeleteDocuemnts.bind(this);
    }

    componentWillMount() {
        this.loadSupportData(this.props.supportId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentSupportId !== nextProps.supportId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadSupportData(nextProps.supportId);
            });
        }

        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }/*
        else if (nextProps.editEnd) {
            history.push('/supports');
        }*/
        else if (nextProps.support) {
            this.setState({
                support: nextProps.support
            });
        }
    }

    handleUpdateDocuments(p_documents) {
        let support = this.state.support;
        support.documents = p_documents;
        this.setState({ support });
    }

    async handleDeleteDocuemnts(name, refId) {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Delete doc media
        await loopbackService.deleteProjectDocumentToModel(
            name,
            selectedProjectId,
            "SUPPORTS",
            refId
        );

        // Refresh
        this.loadDocuments(refId);
    }

    loadDocuments = async (supportId) => {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Create doc media
        const docs = await loopbackService.listProjectDocumentFromModel(
            selectedProjectId,
            "SUPPORTS",
            supportId
        );

        // Save to storage
        this.setState({
            mediaStorage: Array.isArray(docs) ? docs : []
        });
    }

    loadSupportData = (supportId) => {
        this.setState({
            addMode: supportId === 0,
            currentSupportId: supportId,
            mediaStorage: [],
            mediaPreview: null,
            support: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null
        }, () => {
            if (supportId > 0) {
                this.props.dispatch(loopbackActions.get('plant', 'supports', supportId));
                this.loadDocuments(supportId);
            }
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { support } = this.state;
        support[name] = value;
        this.setState({ support });
    }

    handleSubmit(e) {
        e.preventDefault();
        let { support, addMode } = this.state;
        const { dispatch } = this.props;

        this.setState({ submitted: true });
        support.projectId = localStorage.getItem('selectedProjectId');

        if (addMode) {
            delete support.id;
        }

        dispatch(loopbackActions.edit(
            'plant',
            'supports',
            support,
            null,
            async (response) => {
                // Get project active
                let selectedProjectId = localStorage.getItem('selectedProjectId');

                // Check for documents
                if (response &&
                    response.instance &&
                    response.instance.id > 0 &&
                    response.instance.documents &&
                    selectedProjectId > 0) {
                    // Get keys
                    let keys = Object.keys(response.instance.documents);

                    // Check for docs into storage
                    for (let i = 0; i < keys.length; i++) {
                        // Get current media
                        let media = response.instance.documents[keys[i]];

                        // Create doc media
                        await loopbackService.pushProjectDocumentToModel(
                            selectedProjectId,
                            {
                                "filename": media.name,
                                "type":     "SUPPORTS",
                                "deviceId": response.instance.id,
                                "file":     media.file
                            }
                        );
                    }
                }

                // Close modal
                this.closeModal();
            }
        ));
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentSupportId: 0,
            mediaStorage: [],
            mediaPreview: null,
            support: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    checkPreSubmit() {
        const { support } = this.state;

        return (!(
            support &&
            support.type
        )) ? true : false;
    }

    render() {
        const { t } = this.props;
        const { addMode, error, support, mediaStorage, mediaPreview, currentSupportId } = this.state;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);

        return (
            <div>
                {error && <ErrorModal
                    error={error}
                    onClickHandler={() => {
                        this.setState({ error: null })
                    }}
                />}
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi supporto") : t("Modifica supporto"))}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Tipo")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={support.type}
                                                    name='type'
                                                    onChange={this.handleChange}
                                                    style={(!(support && support.type && support.type.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Materiale")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={support.material || ''}
                                                    name='material'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Altezza")} (m)</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="number"
                                                    min="0"
                                                    value={support.height || ''}
                                                    name='height'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Documents
                                                canEdit={canEdit}
                                                acceptFile={["pdf", "image"]}
                                                documents={mediaStorage}
                                                onUpdate={this.handleUpdateDocuments}
                                                onDelete={(media) => this.handleDeleteDocuemnts(media.name, currentSupportId)}
                                                onPreview={(media) => this.setState({ mediaPreview: media })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { supports } = loopback;
    return {
        support: supports && supports.instance,
        error: supports && supports.error,
        editEnd: supports && supports.editEnd,
    };
}

const connectedSupportEdit = withTranslation()(connect(mapStateToProps)(SupportEdit));
export { connectedSupportEdit as SupportEdit };

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { App } from './App';
import { store } from './helpers';

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.css?v=1.1.0";
import "assets/css/minoslive.css";

import './i18n';

/**
 */
ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
    document.getElementById("root")
);

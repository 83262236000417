import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

const BoundsOnChangeMap = withScriptjs(
    withGoogleMap(props => {
        /* Defaults */
        const zoom = props.defaultZoom || 6;
        let center = { lat: 41.89193, lng: 12.51133 };

        if (props.defaultCenter &&
            typeof props.defaultCenter.lat === "number" &&
            typeof props.defaultCenter.lng === "number"
        ) {
            center = props.defaultCenter;
        }

        /* /Defaults */
        const markers = props.markers || [];

        return (
            <GoogleMap
                center={center}
                options={props.options || null}
                zoom={zoom}
                defaultOptions={{ scrollwheel: true }}
                ref={props.handleMapMounted}
                onDragEnd={props.onDragEnd}
                onZoomChanged={props.onZoomChanged}>
                {markers.map((marker, index) => {
                    let label = marker.label;
                    delete marker.label;
                    return <Marker key={`key-marker-${index}`} title={label} {...marker} position={marker.position} />
                })}
            </GoogleMap>
        );
    })
);

export default BoundsOnChangeMap;

import { connect } from 'react-redux';
import React, { Fragment } from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Button } from "components";
import { loopbackActions } from '../../actions';
import ErrorModal from "../Components/ErrorModal";
import { withTranslation } from 'react-i18next';
import Select from 'react-select/lib/Select';

class DeviceEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentDeviceId: 0,
            devicetypes: (
                props.deviceTypes &&
                Array.isArray(props.deviceTypes.instances)
            ) ? props.deviceTypes.instances : [],
            device: {
                sn: '',
                deviceTypeId: 1,
                macAddress: '',
                firmwareVersion: '',
                hardwareVersion: '',
                addressCode: null,
                tbDeviceId: null,
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.loadDeviceData(this.props.DeviceId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentDeviceId !== nextProps.deviceId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadDeviceData(nextProps.deviceId);
            });
        }

        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }/*
        else if (nextProps.editEnd) {
            history.push('/Devices');
        }*/
        else if (nextProps.device) {
            this.setState({
                device: nextProps.device
            });
        }
        else if (
            nextProps.deviceTypes &&
            Array.isArray(nextProps.deviceTypes.instances)
        ) {
            this.setState({
                devicetypes: nextProps.deviceTypes.instances.map((item) => {
                    return {
                        label: item.model,
                        value: item.id
                    }
                })
            });
        }
    }

    loadDeviceData = (deviceId) => {
        this.setState({
            addMode: deviceId === 0,
            currentDeviceId: deviceId,
            device: {
                sn: '',
                deviceTypeId: 1,
                macAddress: '',
                firmwareVersion: '',
                hardwareVersion: '',
                addressCode: null,
                tbDeviceId: null,
            },
            submitted: false,
            error: null
        }, () => {
            if (typeof deviceId === "string") {
                this.props.dispatch(loopbackActions.get('archive', 'devices', deviceId));
            }
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { device } = this.state;

        // Reset old data
        if ([
            "addressCode",
            "tbDeviceId"
        ].includes(name)) {
            device["addressCode"] = null;
            device["tbDeviceId"]  = null;
        }

        // Set tmp data
        device[name] = value;

        // Set new value
        this.setState({ device });
    }

    handleSelectChange(name, value) {
        let { device } = this.state;
        device[name] = value;
        this.setState({ device });
    }

    handleSubmit(e) {
        e.preventDefault();
        let { device } = this.state;
        const { dispatch } = this.props;

        if (!(
            // === 1
            (
                device &&
                device.sn &&
                device.sn.length > 0 &&
                device.deviceTypeId === 1 &&
                device.addressCode &&
                device.addressCode.length > 0
            ) ||
            // === 2
            (
                device &&
                device.sn &&
                device.sn.length > 0 &&
                device.deviceTypeId === 2 &&
                device.macAddress &&
                device.macAddress.length > 0
            ) ||
            // === 3
            (
                device &&
                device.sn &&
                device.sn.length > 0 &&
                device.deviceTypeId === 3 &&
                device.macAddress &&
                device.macAddress.length > 0 &&
                device.addressCode &&
                device.addressCode.length > 0
            ) ||
            // === 4
            (
                device &&
                device.sn &&
                device.sn.length > 0 &&
                device.deviceTypeId === 4 &&
                device.addressCode &&
                device.addressCode.length > 0
            )
        )) {
            return;
        }

        this.setState({ submitted: true });
        device.projectId = localStorage.getItem('selectedProjectId');

        // Normalize pre-submit
        if ([1, 3, 4].includes(device.deviceTypeId)) {
            try {
                let tmpAC = parseInt(device["addressCode"], 10);
                device["addressCode"] = (tmpAC + this.props.syraOffset);
            }
            catch (eAc) {
                // Force reset, invalid
                device["addressCode"] = null;
            }
        }

        dispatch(loopbackActions.edit(
            'archive',
            'devices',
            device,
            (this.state.currentDeviceId === 0) ? "POST" : "PUT",
            async () => {
                // Close modal
                this.closeModal();
            }
        ));
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentDeviceId: 0,
            device: {
                sn: '',
                deviceTypeId: 1,
                macAddress: '',
                firmwareVersion: '',
                hardwareVersion: '',
                addressCode: null,
                tbDeviceId: null,
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    getNormalizedAddressCode = (addressCode) => {
        try {
            let tmpAC = Number(addressCode);
            return ("" + (
                (tmpAC >= this.props.syraOffset) ?
                (tmpAC - this.props.syraOffset) :
                tmpAC
            ));
        }
        catch (eAc) {
            return "";
        }
    }

    render() {
        const { t } = this.props;
        const { addMode, error, device, mediaPreview, devicetypes } = this.state;

        return (
            <div>
                {error && <ErrorModal
                    error={error}
                    onClickHandler={() => {
                        this.setState({ error: null });
                    }}
                />}
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi Device") : t("Modifica Device"))}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Serial Number")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={device.sn}
                                                    name='sn'
                                                    onChange={this.handleChange}
                                                    style={(!(device && device.sn && device.sn.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Firmware Version")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={device.firmwareVersion}
                                                    name='firmwareVersion'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Hardware Version")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={device.hardwareVersion}
                                                    name='hardwareVersion'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Device Type")}</strong>
                                                </Label>
                                                <Select
                                                    multi={false}
                                                    closeOnSelect={true}
                                                    clearable={false}
                                                    placeholder=""
                                                    name="deviceTypeId"
                                                    value={device.deviceTypeId}
                                                    options={devicetypes}
                                                    onChange={(e) => this.handleSelectChange("deviceTypeId", e.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {device.deviceTypeId === 1 ? ( // SYRA
                                        <Row>
                                            <Col>
                                                <FormGroup className="">
                                                    <Label>
                                                        <strong style={{ color: "#990000"}}>{t("Address Code")}</strong>
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        value={this.getNormalizedAddressCode(device.addressCode)}
                                                        name='addressCode'
                                                        onChange={this.handleChange}
                                                        style={(!(device && device.addressCode && (device.addressCode + "").length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    ) : device.deviceTypeId === 2 ? ( // ANDROS
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <FormGroup className="">
                                                        <Label>
                                                            <strong style={{ color: "#990000"}}>{t("Mac Address")}</strong>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            value={device.macAddress}
                                                            name='macAddress'
                                                            onChange={this.handleChange}
                                                            style={(!(device && device.macAddress && device.macAddress.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup className="">
                                                        <Label>
                                                            <strong>{t("Certificate Id")}</strong>
                                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            value={device.tbDeviceId ? device.tbDeviceId : ""}
                                                            name='tbDeviceId'
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    ) : device.deviceTypeId === 3 ? ( // SYRA RE-C
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup className="">
                                                    <Label>
                                                        <strong style={{ color: "#990000"}}>{t("Mac Address")}</strong>
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        value={device.macAddress}
                                                        name='macAddress'
                                                        onChange={this.handleChange}
                                                        style={(!(device && device.macAddress && device.macAddress.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="">
                                                    <Label>
                                                        <strong style={{ color: "#990000"}}>{t("Address Code")}</strong>
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        value={this.getNormalizedAddressCode(device.addressCode)}
                                                        name='addressCode'
                                                        onChange={this.handleChange}
                                                        style={(!(device && device.addressCode && (device.addressCode + "").length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ) : device.deviceTypeId === 4 ? ( // SYRA RE
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup className="">
                                                    <Label>
                                                        <strong style={{ color: "#990000"}}>{t("Address Code")}</strong>
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        value={this.getNormalizedAddressCode(device.addressCode)}
                                                        name='addressCode'
                                                        onChange={this.handleChange}
                                                        style={(!(device && device.addressCode && (device.addressCode + "").length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ) : null}
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save">
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { devices } = loopback;
    return {
        device: devices && devices.instance,
        error: devices && devices.error,
        editEnd: devices && devices.editEnd,
    };
}

const connectedDeviceEdit = withTranslation()(connect(mapStateToProps)(DeviceEdit));
export { connectedDeviceEdit as DeviceEdit };

import { utils } from '../helpers';

/**
 * Helper per creare action creator che crea action creator comuni ai vari moduli.
 *
 * Genera le seguenti action:
 *
 *  - filterCount: conta i record filtrati;
 *  - filter: recupera i record con i filtri;
 *  - delete: elimina un record;
 *
 */
const commonActionCreatorHelper = {

    /**
     * Crea action creator per le azioni CRUD.
     */
    createCrudActions: function ({
        /**
         * Callback per customizzare i filtri, es.:
         *
         *      function(filters) {
         *          filters.where.field = { like: '%123%' };
         *      }
         */
        customizeFilters,

        /**
         * Service da usare per le chiamate che recuperano i record.
         * Implementa i metodi:
         *
         *  - filterCount(filters)
         *  - filter(filters)
         *  - delete(id)
         *
         */
        service,

        /* ================================================== */
        /* filterCount */
        /**
         * Action creator `filterCount` request
         */
        filterCountRequestActionCreator,

        /**
         * Action creator `filterCount` success
         */
        filterCountSuccessActionCreator,

        /**
         * Action creator `filterCount` failure
         */
        filterCountFailureActionCreator,
        /* /================================================== */

        /* ================================================== */
        /* filter */
        /**
         * Action creator `filter` request
         */
        filterRequestActionCreator,

        /**
         * Action creator `filter` success
         */
        filterSuccessActionCreator,

        /**
         * Action creator `filter` failure
         */
        filterFailureActionCreator,
        /* /================================================== */

        /* ================================================== */
        /* delete */
        /**
         * Action creator `delete` request
         */
        deleteRequestActionCreator,

        /**
         * Action creator `delete` success
         */
        deleteSuccessActionCreator,

        /**
         * Action creator `delete` failure
         */
        deleteFailureActionCreator,
        /* /================================================== */

        /* ================================================== */
        /* filterAllNoLimit */
        /**
         * Action creator `filterAllNoLimit` request
         */
        filterAllNoLimitRequestActionCreator,

        /**
         * Action creator `filterAllNoLimit` success
         */
        filterAllNoLimitSuccessActionCreator,

        /**
         * Action creator `filterAllNoLimit` failure
         */
        filterAllNoLimitFailureActionCreator
        /* /================================================== */
    }) {
        const ret = (function () {
            let prevFilters = null;

            function setupFiltersFromOptions(options) {
                let { filters, next } = options || {};

                if (filters) {
                    const filtersClone = {}
                    utils.deepObjectCloningExtend(filtersClone, filters)
                    filters = filtersClone
                }
                if (!filters) {
                    if (!options.ignorePrevFilters) {
                        filters = prevFilters;
                    }
                    else {
                        filters = null;
                    }
                }
                if (typeof customizeFilters === "function") {
                    // Customizzazione globale che, se valorizzata con una `function`,
                    // viene fatta sempre per qualsiasi action creator.
                    const ret = customizeFilters(filters);
                    if (ret) {
                        filters = ret;
                    }
                }
                if (typeof options.customizeFilters === "function") {
                    // Customizzazione locale, che se settata, viene fatta solo per
                    // il singolo action creator che viene creato.
                    const ret = options.customizeFilters(filters);
                    if (ret) {
                        filters = ret;
                    }
                }
                prevFilters = filters;

                return { filters, next }
            }

            return {
                /**
                 * Action che recupera tutti i record correntemente filtrati senza tenere conto della paginazione.
                 */
                filterAllNoLimit: function filterAllNoLimit(options) {
                    const request = filterAllNoLimitRequestActionCreator;
                    const success = filterAllNoLimitSuccessActionCreator;
                    const failure = filterAllNoLimitFailureActionCreator;

                    const { filters, next } = setupFiltersFromOptions(options)

                    return async (dispatch) => {
                        dispatch(request(filters));
                        try {
                            let records = await service.filterAllNoLimit(filters);
                            dispatch(success(records));
                            if (typeof next === "function") {
                                next(options, filters, dispatch);
                            }
                        } catch (error) {
                            if (typeof (error.json) === 'function') {
                                dispatch(failure(await error.json()));
                            }
                            dispatch(failure(error));
                        }
                    };
                },

                /**
                 * Action che conta tutti i record corrispondenti ai filtri attualmente settati.
                 */
                filterCount: function filterCount(options) {
                    const request = filterCountRequestActionCreator;
                    const success = filterCountSuccessActionCreator;
                    const failure = filterCountFailureActionCreator;

                    const { filters, next } = setupFiltersFromOptions(options)

                    return async (dispatch) => {
                        dispatch(request(filters));
                        try {
                            let count = await service.filterCount(filters);
                            dispatch(success(count, filters.limit));
                            if (typeof next === "function") {
                                next({ ...filters, count: count.count }, dispatch);
                            }
                        } catch (error) {
                            if (typeof (error.json) === 'function') {
                                dispatch(failure(await error.json()));
                            }
                            dispatch(failure(error));
                        }
                    }
                },

                /**
                 * Action che recupera i record filtrati.
                 */
                filter: function filter(filters) {
                    const request = filterRequestActionCreator;
                    const success = filterSuccessActionCreator;
                    const failure = filterFailureActionCreator;

                    return async (dispatch) => {
                        dispatch(request(filters));
                        try {
                            let records = await service.filter(filters);
                            dispatch(success(records));
                        } catch (error) {
                            if (typeof (error.json) === 'function') {
                                dispatch(failure(await error.json()));
                            }
                            dispatch(failure(error));
                        }
                    }
                },

                /**
                 * Action che elimina un record.
                 */
                delete: function _delete(id) {
                    const request = deleteRequestActionCreator;
                    const success = deleteSuccessActionCreator;
                    const failure = deleteFailureActionCreator;
                    return async (dispatch) => {
                        dispatch(request(id));
                        try {
                            await service.delete(id);
                            dispatch(success(id));
                            dispatch(
                                this.filterCount({
                                    filters: prevFilters,
                                    next: (filters) => {
                                        // let skip = filters.skip;
                                        // if (filters && filters.count) {
                                        //     skip = Math.ceil(filters.count / filters.limit) * filters.limit;
                                        //     //Viene calcolato lo skip in modo che venga caricata correttamente l'ultima pagina.
                                        //     if (filters.count <= skip) {
                                        //         skip = (Math.ceil(filters.count / filters.limit) - 1) * filters.limit;
                                        //     }
                                        // }
                                        // dispatch(this.filter({ ...filters, skip: skip }));
                                        dispatch(this.filter({ ...filters }));
                                    }
                                }));
                        } catch (error) {
                            dispatch(failure(id, error));
                        }
                    }
                }
            }
        }());

        return ret;
    }
};

export default commonActionCreatorHelper;

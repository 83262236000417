export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',
    
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    EDIT_REQUEST: 'USERS_EDIT_REQUEST',
    EDIT_SUCCESS: 'USERS_EDIT_SUCCESS',
    EDIT_FAILURE: 'USERS_EDIT_FAILURE',

    ADD_REQUEST: 'USERS_ADD_REQUEST',
    ADD_SUCCESS: 'USERS_ADD_SUCCESS',
    ADD_FAILURE: 'USERS_ADD_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    FILTER_COUNT_REQUEST: 'USERS_FILTER_COUNT_REQUEST',
    FILTER_COUNT_SUCCESS: 'USERS_FILTER_COUNT_SUCCESS',
    FILTER_COUNT_FAILURE: 'USERS_FILTER_COUNT_FAILURE',

    FILTER_REQUEST: 'USERS_FILTER_REQUEST',
    FILTER_SUCCESS: 'USERS_FILTER_SUCCESS',
    FILTER_FAILURE: 'USERS_FILTER_FAILURE',

    FILTER_ALL_NO_LIMIT_REQUEST: 'USERS_FILTER_ALL_NO_LIMIT_REQUEST',
    FILTER_ALL_NO_LIMIT_SUCCESS: 'USERS_FILTER_ALL_NO_LIMIT_SUCCESS',
    FILTER_ALL_NO_LIMIT_FAILURE: 'USERS_FILTER_ALL_NO_LIMIT_FAILURE',    

};

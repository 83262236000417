import React, { Component } from "react";
import ReactTable from "react-table";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { Button } from "components";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import "../../assets/css/react-table.css";
import { withTranslation } from 'react-i18next';

const CheckboxTable = checkboxHOC(ReactTable);

class ArchiveChooser extends Component {

    constructor(props) {
        super(props);

        const { selectedArchiveId } = props;

        this.state = {
            selection: selectedArchiveId,
            selectAll: false
        }
    }

    toggleSelection = (key) => {
        this.setState({ selection: key });
    };

    isSelected = key => {
        return this.state.selection === key;
    };

    render() {
        const { t, onConfirm, onCancel, instances, title, model } = this.props;
        const { toggleSelection, isSelected } = this;
        const { selection } = this.state;
        let data = [];

        if (instances) {
            data = instances.map((instance, key) => {
                return {
                    _id: instance.id,
                    index: instance.id,
                    ...instance
                }
            });
        }

        const columns = model.map((column) => {
            return {
                Header: column.label,
                accessor: column.field,
                filterable: false,
                searchable: false
            }
        });

        const checkboxProps = {
            isSelected,
            toggleSelection,
            selectType: "radio",
            getTrProps: (s, r) => {
                return {
                    onClick: (e) => {
                        toggleSelection(r.original._id);
                    }
                };
            }
        };

        return (
            <Modal size="lg" isOpen={true} className="modal-umpi-container ArchiveChooser">
                <ModalHeader toggle={() => onCancel()}>
                    {t(title)}
                </ModalHeader>
                <ModalBody>
                    <CheckboxTable
                        ref={r => (this.checkboxTable = r)}
                        data={data}
                        previousText={t('Precedente')}
                        nextText={t('Successiva')}
                        noDataText={t('Nessun record')}
                        pageText={t('Pagina')}
                        ofText={t('di')}
                        rowsText={t('righe')}
                        columns={columns}
                        defaultPageSize={data.length}
                        showPaginationBottom={false}
                        className="-striped"
                        {...checkboxProps}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="default" className="umpi-modal-btn-cancel" onClick={() => onCancel()}>
                        {t("Annulla")}
                    </Button>
                    &nbsp;&nbsp;
                    <Button color="success" className="umpi-modal-btn-save" onClick={() => { onConfirm(selection); }}>
                        {t("Salva")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ArchiveChooser = withTranslation()(ArchiveChooser);

import { connect } from 'react-redux';
import React, { Component } from "react";
import ReactTable from "react-table";
import { Card, CardBody, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { PanelHeader } from "components";
import Button from "components/CustomButton/CustomButton.jsx";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from 'actions';
import ErrorModal from "../Components/ErrorModal"
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import { withTranslation } from 'react-i18next';
import { DeviceEdit } from './DeviceEdit';
import { refreshSearchEngine } from '../../components/Header/Header';
import { loopbackService } from '../../services';
import { ModelPaths, ModelRelations, Models } from '../../constants';

//-------------------------------------------------------|
// CHANGE OFFSET HERE
//-------------------------------------------------------|
const SYRA_OFFSET = Number(3584); // 3585 = 1 (3584 + 1)
//-------------------------------------------------------|

class Devices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            error: null,
            filterDeviceByName: null,
            isDeviceEditModalOpen: false,
            selectedDeviceId: 0
        }

        selectedProjectChangeListeners.push(() => {
            this.fetchData();
        });

    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchData();

        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            this.setState({
                filterDeviceByName: search_value
            });
        }, this.props.t("Search a device"));
    }

    toggleDeviceEditModal = (state, deviceId = 0) => {
        this.setState({
            isDeviceEditModalOpen: (state === true) ? true : false,
            selectedDeviceId: (state === true) ? deviceId : 0
        }, () => {
            if (state === false) {
                this.fetchData();
            }
        });
    }

    fetchData() {
        const { dispatch } = this.props;

        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        let args = [
            { path: ModelPaths.PLANT, model: Models.CABINETS, where: null, limit: null, include: ModelRelations.DEVICE },
            { path: ModelPaths.PLANT, model: Models.LIGHTPOINTS, where: null, limit: null, include: [ModelRelations.DEVICE, ModelRelations.RADIOCONTROLLER] },
            { path: ModelPaths.ARCHIVE, model: Models.DEVICES, where: { projectId: selectedProjectId }, limit: null, include: null },
            { path: ModelPaths.ARCHIVE, model: Models.DEVICETYPES, where: { projectId: selectedProjectId }, limit: null, include: null }
        ];

        dispatch(loopbackActions.multiList(args));
    }

    deleteConfirm(sn) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Delete device")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            const { dispatch } = this.props;
                            if (sn) {
                                await dispatch(loopbackActions._delete('archive', 'devices', sn));
                                this.setState({ alert: null }, () => this.fetchData());
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.devices && nextProps.devices.error) {
            this.setState({ error: nextProps.devices.error });
        }
    }

    buildData() {
        const { t, devices, lightpoints, cabinets } = this.props;
        const { filterDeviceByName } = this.state;
        let data = [];

        const __buildDeviceAssocUI = (sn, value) => {
            return (
                sn && sn.length > 0 &&
                value && value.length > 0
            ) ? (
                <div className='umpi-device-assoc-id-view-tb'>
                    <div>{value}</div>
                    <ButtonWithTooltip
                        size="sm"
                        id={"unlinkDevice_" + sn}
                        tooltipText={t("Unlink device")}
                        className="btn-simple umpi-btn-item-properties"
                        onClick={async () => {
                        // elimino la vecchia associazione
                        await loopbackService.patch("archive", "devices", sn + "/setLink", {
                            lightPointId: null,
                            cabinetId: null
                        });
                        this.fetchData();
                    }}>
                        <i className="fa fa-trash" />
                    </ButtonWithTooltip>
                </div>
            ) : ""
        }

        const __extractDataFromProps = (type, target) => {
            switch (type) {
                case "cabinets": {
                    let extract = ((
                        cabinets &&
                        Array.isArray(cabinets.instances)
                    ) ? cabinets.instances : []).filter(x => x.id === target);

                    if (extract.length > 0) {
                        return extract[0];
                    }
                    break;
                }

                case "lightpoints": {
                    let extract = ((
                        lightpoints &&
                        Array.isArray(lightpoints.instances)
                    ) ? lightpoints.instances : []).filter(x => x.id === target);

                    if (extract.length > 0) {
                        return extract[0];
                    }
                    break;
                }

                default: break;
            }

            return null;
        }

        if (devices && devices.instances) {
            data = devices.instances.map((instance, key) => {
                let injectTypeData = {};

                // SYRAH
                if (instance.deviceTypeId === 1) {
                    let data = __extractDataFromProps("lightpoints", instance.lightPointId);

                    try {
                        let tmpAC = Number(instance["addressCode"]);
                        injectTypeData["addressCode"] = (
                            tmpAC >= SYRA_OFFSET
                        ) ? (tmpAC - SYRA_OFFSET) : tmpAC;
                    }
                    catch (eAc) {
                        injectTypeData["addressCode"] = instance["addressCode"];
                    }

                    injectTypeData["calcDeviceType"]     = "Syra";
                    injectTypeData["calcDeviceAddrCert"] = injectTypeData["addressCode"];
                    injectTypeData["calcDeviceAssocId"]  = data ? __buildDeviceAssocUI(
                        instance.sn,
                        data.label
                    ) : "";
                }
                // ANDROS
                else if (instance.deviceTypeId === 2) {
                    let data = __extractDataFromProps("cabinets", instance.cabinetId);

                    injectTypeData["calcDeviceType"]     = "Andros";
                    injectTypeData["calcDeviceAddrCert"] = instance.tbDeviceId;
                    injectTypeData["calcDeviceAssocId"]  = data ? __buildDeviceAssocUI(
                        instance.sn,
                        data.label
                    ) : "";
                }
                // Syra RE-C
                else if (instance.deviceTypeId === 3) {
                    let data = __extractDataFromProps("lightpoints", instance.lightPointId);

                    try {
                        let tmpAC = Number(instance["addressCode"]);
                        injectTypeData["addressCode"] = (
                            tmpAC >= SYRA_OFFSET
                        ) ? (tmpAC - SYRA_OFFSET) : tmpAC;
                    }
                    catch (eAc) {
                        injectTypeData["addressCode"] = instance["addressCode"];
                    }

                    injectTypeData["calcDeviceType"]     = "Syra RE-C";
                    injectTypeData["calcDeviceAddrCert"] = injectTypeData["addressCode"];
                    injectTypeData["calcDeviceAssocId"]  = data ? __buildDeviceAssocUI(
                        instance.sn,
                        data.label
                    ) : "";
                }
                // Syra RE
                else if (instance.deviceTypeId === 4) {
                    let data = __extractDataFromProps("lightpoints", instance.lightPointId);

                    try {
                        let tmpAC = Number(instance["addressCode"]);
                        injectTypeData["addressCode"] = (
                            tmpAC >= SYRA_OFFSET
                        ) ? (tmpAC - SYRA_OFFSET) : tmpAC;
                    }
                    catch (eAc) {
                        injectTypeData["addressCode"] = instance["addressCode"];
                    }

                    injectTypeData["calcDeviceType"]     = "Syra RE";
                    injectTypeData["calcDeviceAddrCert"] = injectTypeData["addressCode"];
                    injectTypeData["calcDeviceAssocId"]  = data ? __buildDeviceAssocUI(
                        instance.sn,
                        data.label
                    ) : "";
                }
                // NULL DEFAULT
                else {
                    injectTypeData["calcDeviceType"]     = "";
                    injectTypeData["calcDeviceAddrCert"] = "";
                    injectTypeData["calcDeviceAssocId"]  = "";
                }

                let searchObj = {
                    index: key,
                    ...instance,
                    ...injectTypeData
                }

                if (filterDeviceByName &&
                    !(searchObj.sn && searchObj.sn.toLowerCase().includes(
                        filterDeviceByName.toLowerCase()
                    )) &&
                    !(searchObj.macAddress && searchObj.macAddress.toLowerCase().includes(
                        filterDeviceByName.toLowerCase()
                    ))) {
                    return null;
                }

                return {
                    index: key,
                    ...instance,
                    ...injectTypeData,
                    actions: (
                        <div className="actions-right">
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-edit"
                                id={"editLamp_" + instance.sn}
                                onClick={() => this.toggleDeviceEditModal(true, instance.sn)}
                                size="sm"
                                tooltipText={t("Modifica")}>
                                <i className="fa fa-pencil" />
                            </ButtonWithTooltip>
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-del"
                                id={"deleteConfirmLamp_" + instance.sn}
                                onClick={() => {
                                    this.deleteConfirm(instance.sn);
                                }}
                                size="sm"
                                tooltipText={t("Elimina")}>
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    )
                };
            }).filter(x => x);
        }
        return data;
    }

    render() {
        const { error, isDeviceEditModalOpen } = this.state;
        const { t, devices } = this.props;
        let loading = devices && devices.loading;
        let data = this.buildData();

        return (
            <div>
                {this.state.alert}
                <PanelHeader size="sm" />
                {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Elenco Device")}</h2>
                            <span className="pull-right ">
                                {this.props.additionalTopButtons}
                                {
                                    !this.props.hideNewButton &&
                                    <Button onClick={() => this.toggleDeviceEditModal(true)} color="primary" round size="sm" className="custom-no-margin btn-add-new-users">{t("NewDevices")}</Button>
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-padding-paper">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        className="-striped -highlight"
                                        previousText={t('Precedente')}
                                        nextText={t('Successiva')}
                                        noDataText={loading ? '' : (isDeviceEditModalOpen ? t('Editing in progress') : t('Nessun risultato'))}
                                        pageText={t('Pagina')}
                                        ofText={t('di')}
                                        rowsText={t('righe')}
                                        LoadingComponent={
                                            () => {
                                                if (loading) {
                                                    return (
                                                        <div className="-loading -active">
                                                            <div className="-loading-inner custom-margin-0-auto">
                                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                        }
                                        columns={[
                                            {
                                                Header: t("ID/SN"),
                                                accessor: "sn",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("MAC ADDRESS"),
                                                accessor: "macAddress",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("TIPOLOGIA"),
                                                accessor: "calcDeviceType",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("COD. INDIRIZZO/ID CERTIFICATO"),
                                                accessor: "calcDeviceAddrCert",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("ASSOCIATO A"),
                                                accessor: "calcDeviceAssocId",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                //Header: () => (<div align="right">{t("Azioni")}</div>),
                                                Header: () => (<div align="right">&nbsp;</div>),
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false,
                                                width: 80
                                            }

                                        ]}
                                        defaultPageSize={10}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </div>
                <DeviceEdit
                    deviceId={this.state.selectedDeviceId}
                    deviceTypes={this.props.devicetypes}
                    isOpenModal={this.state.isDeviceEditModalOpen}
                    onModalClose={() => this.toggleDeviceEditModal(false)}
                    syraOffset={SYRA_OFFSET}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        devices: loopback.devices,
        devicetypes: loopback.devicetypes,
        cabinets: loopback.cabinets,
        lightpoints: loopback.lightpoints,
    };
}

const connectedDevices = withTranslation()(connect(mapStateToProps)(Devices));
export { connectedDevices as Devices };

import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import { utils } from '../../helpers';
import ButtonWithTooltip2 from '../../components/Custom/ButtonWithTooltip2';
import {
    Input,
    TabPane,
    TabContent,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { Button, Documents } from "components";
import { loopbackActions } from "../../actions";
import ArchiveChooser from "./ArchiveChooser";
import VerticalDots from "../../assets/img/vertical_dots";
import { withTranslation } from 'react-i18next';
import User from '../../entities/User';
import { roleConstants, ItemTypes, NavModes, DeviceTypes, FieldNames, Models, ModelPaths } from '../../constants';
import Select from "react-select";
import { loopbackService } from "../../services";
import { Tree } from "../../helpers/Tree";
import { ChromePicker } from 'react-color';

class ItemProperties extends Component {

    foreignKeyIndex = {
        luminares: 0,
        supports: 1,
        lamps: 2,
        drivers: 3
    }

    constructor(props) {
        super(props);

        let data = {
            conf: [],
            gen: [],
            foreignKeys: [],
            luminares: [],
            suppports: [],
            lamps: [],
            drivers: [],
        };

        let state = this.stateFromProps(
            data,
            "gen",
            this.props
        );

        this.state = {
            mediaStorage: [],
            mediaDocuments: [],
            mediaPreview: null,
            previewModalOpened: false,
            ...state,
            editing: props.isEditing,
            choosingArchve: null,
            showPalette: false,
            detailsPanelIsOpen: false
        }

        this.renderEditable = this.renderEditable.bind(this);
        this.renderReadonly = this.renderReadonly.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.save = this.save.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.handleArchiveConfirm = this.handleArchiveConfirm.bind(this);
        this.handleArchiveCancel = this.handleArchiveCancel.bind(this);
        this.resetForeignKey = this.resetForeignKey.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuments = this.handleDeleteDocuments.bind(this);
        this.handleUploadDocuments = this.handleUploadDocuments.bind(this);
    }

    componentWillMount() {
        if (this.props.item && this.props.item.id > 0) {
            this.setState({
                mediaStorage: [],
                mediaDocuments: [],
                mediaPreview: null
            }, () => this.loadDocuments(
                this.props.item.type,
                this.props.item.id
            ));
        }
    }

    componentWillReceiveProps(nextProps) {
        let { data, hTabs } = this.state;

        if (
            (nextProps.type === ItemTypes.CABINET || nextProps.type === ItemTypes.ZONE)
            && this.props.item.type === ItemTypes.LIGTHPOINT
        ) {
            hTabs = "gen";
        }

        let state = this.stateFromProps(data, hTabs, nextProps);

        this.setState({
            ...state,
            editing: nextProps.isEditing
        });

        if (this.props.item && this.props.item.id > 0 &&
            nextProps.item && nextProps.item.id > 0 &&
            this.props.item.id !== nextProps.item.id) {
            this.setState({
                mediaStorage: [],
                mediaDocuments: [],
                mediaPreview: null
            }, () => this.loadDocuments(
                nextProps.item.type,
                nextProps.item.id
            ));
        }
    }

    toggleDetailsPanel = () => {
        this.setState({
            detailsPanelIsOpen: !this.state.detailsPanelIsOpen
        });
    }

    stateFromProps(data, hTabs, props) {
        const { item, relations } = props;
        const { t } = this.props;
        const user = User.current();

        if (item.type === ItemTypes.CABINET) {
            // item = cabinet
            data.gen = [
                { field: 'label', label: t('Etichetta'), val: item.label }
            ];

            // solo se utente SUPERUSERUMPI o ADMIN visualizzo serial number
            if (user.userRoleId === roleConstants.SUPERUSERUMPI || user.userRoleId === roleConstants.ADMIN) {
                const sn = (relations.cabinets_devices && relations.cabinets_devices[item.id] ? relations.cabinets_devices[item.id] : null);
                data.gen.push(
                    { field: 'andros_sn', label: t('Numero seriale'), val: sn }
                );
            }

            data.gen = data.gen.concat([
                { field: 'energy_supplyer', label: t('Fornitore di energia'), val: item.energy_supplyer },
                { field: 'pod', label: t('Pod'), val: item.pod },
                { field: 'client_code', label: t('Codice cliente'), val: item.client_code },
                { field: 'power', label: t('Potenza'), val: item.power },
                { field: 'note', label: t('Note'), val: item.note },
                { field: 'city', label: t('Città'), val: item.city },
                { field: 'address', label: t('Indirizzo'), val: item.address },
                { field: 'lat', label: t('Lat'), val: (item.geometry ? item.geometry.lat : 0) },
                { field: 'lng', label: t('Lng'), val: (item.geometry ? item.geometry.lng : 0) }
            ]);
        }
        else if (item.type === ItemTypes.ZONE) {
            // item = zone
            data.gen = [
                { field: 'label', label: t('Etichetta'), val: item.label },
                { field: 'description', label: t('Descrizione'), val: item.description },
                { field: 'color', label: t('Colore'), val: item.color },
                // { field: 'timerProfileWeeklyId', label: t('Profilo timer settimanale'), val: item.timerProfileWeeklyId },
                // { field: 'timerProfileWeeklyStartDate', label: t('Valido dal'), val: item.timerProfileWeeklyStartDate },
                // { field: 'note', label: t('Note'), val: item.note }
            ];
        }
        else {
            // item = lightpoint
            data.gen = [];
            data.conf = [];
            let val = null;

            if ((user.userRoleId === roleConstants.ADMIN || user.userRoleId === roleConstants.SUPERUSERUMPI) && item.radioController) {
                val = item.radioController.configuration && item.radioController.configuration.h24mode;
                data.conf.push(
                    { field: "h24mode", label: t("Funzionamento H24"), val, hilight: true }
                );
            }

            if (user.userRoleId === roleConstants.SUPERUSERUMPI && item.radioController) {
                val = item.radioController.configuration && item.radioController.configuration.radioCh;
                data.conf.push(
                    { field: "radioCh", label: t("Canale radio"), val, hilight: true }
                );
            }

            data.gen.push(
                { field: 'label', label: t('Etichetta'), val: item.label }
            );

            // se utente SUPERUSERUMPI o ADMIN visualizzo serial number
            if (user.userRoleId === roleConstants.SUPERUSERUMPI || user.userRoleId === roleConstants.ADMIN) {
                val = (relations.lightpoints_devices && relations.lightpoints_devices[item.id] ? relations.lightpoints_devices[item.id] : null);
                data.gen.push(
                    { field: 'syras_sn', label: t('Numero seriale'), val }
                );
            }

            data.gen = data.gen.concat([
                { field: 'address', label: t('Indirizzo'), val: item.address },
                { field: 'city', label: t('Città'), val: item.city },
                { field: 'note', label: t('Note'), val: item.note },
                { field: 'lat', label: t('Lat'), val: (item.geometry ? item.geometry.lat : 0) },
                { field: 'lng', label: t('Lng'), val: (item.geometry ? item.geometry.lng : 0) }
            ]);

            data.foreignKeys = [
                { field: 'luminareArchiveId', val: item.luminareArchiveId },
                { field: 'supportArchiveId', val: item.supportArchiveId },
                { field: 'lampArchiveId', val: item.lampArchiveId },
                { field: 'driverArchiveId', val: item.driverArchiveId },
            ];

        }

        return {
            hTabs,
            data,
            editing: false,
            originalItem: props.originalItem
        }
    }

    startEditing() {
        this.setState({
            detailsPanelIsOpen: true,
            editing: true
        });
    }

    /**
     * Se ci sono modifiche, restituisce l'oggetto modificato, altrimenti null
     * @param {*} item
     * @param {*} entries
     */
    applyChanges(item, entries) {
        let changed = false;
        let itemCopy = item ? utils.cloneObjDeeply(item) : {};

        for (const entry of entries) {
            if (entry.field === 'lat' || entry.field === 'lng') {
                if (!itemCopy.geometry) {
                    itemCopy.geometry = { lat: 0, lng: 0 };
                }

                itemCopy.geometry[entry.field] = entry.val;
                changed = true;

                if ((itemCopy.geometry.lat === 0 || itemCopy.geometry.lat === '') && (itemCopy.geometry.lng === 0 || itemCopy.geometry.lng === '')) {
                    itemCopy.geometry = null;
                }
            }
            else if (entry.field === 'geometry') {
                if (JSON.stringify(itemCopy.geometry) !== JSON.stringify(entry.val)) {
                    itemCopy[entry.field] = entry.val;
                    changed = true;
                }
            }
            else if (itemCopy[entry.field] !== entry.val) {
                itemCopy[entry.field] = entry.val;
                changed = true;
            }
        }

        if (changed) {
            return itemCopy;
        }
        else {
            return null;
        }
    }

    /**
     *
     */
    async save() {
        // salvataggio e propagazione aggiornamento
        const { item, dispatch, relations, instances, indexes } = this.props;
        const { data, originalItem } = this.state;
        let args = [];

        if (item.type === ItemTypes.CABINET) {
            // cabinet
            let cabinet = this.applyChanges(originalItem, data.gen);

            if (cabinet) {
                // se è stato abbinato un device al cabinet, creo la relazione
                if (cabinet.andros_sn !== null) {
                    let old_andros_sn = (relations.cabinets_devices ? relations.cabinets_devices[item.id] : null);
                    let new_andros_sn = cabinet.andros_sn;
                    let cabinetId = (cabinet.andros_sn === "" ? null : item.id);

                    try {
                        if (old_andros_sn !== new_andros_sn) {
                            relations.cabinets_devices[item.id] = new_andros_sn;

                            if (old_andros_sn) {
                                // elimino la vecchia associazione
                                await loopbackService.patch("archive", "devices", old_andros_sn + "/setLink", { cabinetId: null });

                                // aggiungo il sn all'elenco dei dispositivi associabili
                                if (instances.androsNotLinked === null) {
                                    instances.androsNotLinked = [];
                                }

                                instances.androsNotLinked = instances.androsNotLinked.concat({ sn: old_andros_sn });
                                indexes.androsNotLinked = utils.doIndex(instances['androsNotLinked'], 'sn');
                            }

                            if (new_andros_sn && cabinetId) {
                                // assegno la nuova
                                await loopbackService.patch("archive", "devices", new_andros_sn + "/setLink", { cabinetId });

                                // rimuovo all'elenco dei dispositivi associabili
                                if (instances.androsNotLinked !== null) {
                                    instances.androsNotLinked = instances.androsNotLinked.filter((el) => (el.sn !== new_andros_sn));
                                    indexes.androsNotLinked = utils.doIndex(instances[Models.ANDROS_NOT_LINKED], FieldNames.SN);
                                }
                            }
                        }
                        delete (cabinet.andros_sn);
                    }
                    catch (error) {
                        console.error("Errore, impossibile associare dispositivo a cabinet. ", error);
                        return;
                    }
                }
                args.push({ path: ModelPaths.PLANT, model: Models.CABINETS, instance: cabinet });
            }
        }
        // if ( item.type === ItemTypes.CABINET ) {
        else if (item.type === ItemTypes.ZONE) {
            let gen = data.gen.slice(0);
            gen.push({ field: 'geometry', val: item.geometry });

            // zona
            let zone = this.applyChanges(
                originalItem,
                gen
            );

            if (zone) {
                args.push({ path: ModelPaths.PLANT, model: Models.ZONES, instance: zone });
            }
        }
        else {
            // lightpoint
            let gen = this.applyChanges(originalItem, data.gen);
            let fks = this.applyChanges(originalItem, data.foreignKeys);
            let conf = originalItem.radioController && this.applyChanges(originalItem.radioController.configuration, data.conf);

            let lightpoint = {
                ...gen,
                ...fks
            }

            if (lightpoint.syras_sn !== null) {
                let old_syras_sn = (relations.lightpoints_devices ? relations.lightpoints_devices[item.id] : null);
                let new_syras_sn = lightpoint.syras_sn;
                let lightPointId = (lightpoint.syras_sn === "" ? null : item.id);

                try {
                    if (old_syras_sn !== new_syras_sn) {
                        relations.lightpoints_devices[item.id] = new_syras_sn;

                        if (old_syras_sn) {
                            // elimino la vecchia associazione
                            await loopbackService.patch("archive", "devices", old_syras_sn + "/setLink", { lightPointId: null });

                            // aggiungo il sn all'elenco dei dispositivi associabili
                            if (instances.syrasNotLinked === null) {
                                instances.syrasNotLinked = [];
                            }

                            instances.syrasNotLinked = instances.syrasNotLinked.concat({
                                sn: old_syras_sn,
                                deviceTypeId: lightpoint.deviceType
                            });

                            indexes.syrasNotLinked = utils.doIndex(instances['syrasNotLinked'], 'sn');
                        }

                        if (new_syras_sn && lightPointId) {
                            // assegno la nuova
                            await loopbackService.patch("archive", "devices", new_syras_sn + "/setLink", { lightPointId });

                            // rimuovo dall'elenco dei dispositivi associabili
                            if (instances.syrasNotLinked !== null) {
                                instances.syrasNotLinked = instances.syrasNotLinked.filter((el) => (el.sn !== new_syras_sn));
                                indexes.syrasNotLinked = utils.doIndex(instances[Models.SYRAS_NOT_LINKED], FieldNames.SN);
                            }
                        }
                    }
                } catch (error) {
                    console.error("Errore, impossibile associare dispositivo a cabinet. ", error);
                    return;
                }
                delete (lightpoint.syras_sn);
            }

            if (Object.keys(lightpoint).length > 0 || Object.keys(conf).length > 0) {
                if (lightpoint.radioController && conf) {
                    let lp = JSON.parse(JSON.stringify(lightpoint));
                    delete (lp.device);
                    lp.radioController.configuration = conf;
                    args.push({ path: NavModes.PLANT, model: Models.RADIOCONTROLLERS, instance: lp.radioController });
                    delete (lp.radioController);
                    args.push({ path: NavModes.PLANT, model: Models.LIGHTPOINTS, instance: lp });
                }
                else {
                    args.push({ path: NavModes.PLANT, model: Models.LIGHTPOINTS, instance: lightpoint });
                }
            }
        }

        if (args.length > 0) {
            dispatch(loopbackActions.multiEdit(args));
        } else {
            this.setState({
                editing: false
            });
        }
    }

    cancelEditing() {
        let { data, hTabs } = this.state;
        this.setState(this.stateFromProps(data, hTabs, this.props));
    }

    /**
     * Visualizza le celle in modalità editing.
     * @param {*} cellInfo
     */
    renderEditable(cellInfo) {

        let { data } = this.state;
        let { item, relations, instances, root, handleItemDidChange } = this.props;

        const hTabs = cellInfo.column.className;
        let details = data[hTabs];
        let value = details[cellInfo.index][cellInfo.column.id] || "";

        if (cellInfo.original.field === 'color') {
            return (
                <div className="color-picker-mappa">
                    <ChromePicker
                        color={value}
                        onChange={(e) => {
                            let details = [...data[hTabs]];
                            details[cellInfo.index][cellInfo.column.id] = e.hex;
                            data[hTabs] = details;
                            let { item, handleItemDidChange } = this.props;
                            item[details[cellInfo.index].field] = e.hex;
                            handleItemDidChange(item);
                            this.setState({ data });
                        }}
                    />
                </div>
            )
        }

        else if (cellInfo.original.field === 'andros_sn' || cellInfo.original.field === 'syras_sn') {

            let androsSnOptions = [];
            let syrasSnOptions = [];
            let androsNotLinked = instances.androsNotLinked;
            let syrasNotLinked = instances.syrasNotLinked;

            if (cellInfo.original.field === 'andros_sn' && androsNotLinked) {
                androsSnOptions = androsSnOptions.concat(androsNotLinked.map((elem) => { return { value: elem.sn, label: elem.sn } }));
                let value = (relations.cabinets_devices ? relations.cabinets_devices[item.id] : null);
                if (value !== null) {
                    androsSnOptions.push({ label: value, value });
                }
            }
            else if (cellInfo.original.field === 'syras_sn' && syrasNotLinked) {
                let parentNode = new Tree(root).getParent({ item });

                if (parentNode &&
                    parentNode.data &&
                    parentNode.data.item) {
                    let parentItem = parentNode.data.item;
                    if (parentItem.type === ItemTypes.CABINET) {
                        // lightpoint di un cabinet
                        syrasNotLinked.map((elem) => {
                            if (elem.deviceTypeId !== DeviceTypes.RADIONODE) {
                                syrasSnOptions.push({ value: elem.sn, label: elem.sn });
                            }
                            return false;
                        });
                    }
                    else if (parentItem.deviceType === DeviceTypes.RADIOCONTROLLER) {
                        // lightpoint di un radiocontroller
                        syrasNotLinked.map((elem) => {
                            if (elem.deviceTypeId === DeviceTypes.RADIONODE) {
                                syrasSnOptions.push({ value: elem.sn, label: elem.sn });
                            }
                            return false;
                        });
                    }
                }

                // se ho un device associato -> lo aggiungo alla selezione
                let value = (relations.lightpoints_devices ? relations.lightpoints_devices[item.id] : null);
                if (value !== null) {
                    syrasSnOptions.push({ label: value, value });
                }
            }

            const disabled = item.type !== ItemTypes.CABINET && (new Tree(root)).hasChildren({ item });
            return (
                <span className="input-field-wrapper" >
                    <Select
                        // menuContainerStyle = { {position: 'relative', overflow: 'auto'} }
                        closeOnSelect={true}
                        disabled={disabled}
                        placeholder=""
                        name={cellInfo.original.field === 'andros_sn' ? "andros_sn" : "syras_sn"}
                        value={value}
                        options={cellInfo.original.field === 'andros_sn' ? androsSnOptions : syrasSnOptions}
                        onChange={e => {
                            let details = [...data[hTabs]];
                            const sn = (e ? e.value : "");
                            details[cellInfo.index][cellInfo.column.id] = sn;
                            data[hTabs] = details;
                            let { item, handleItemDidChange } = this.props;
                            item[details[cellInfo.index].field] = sn;
                            handleItemDidChange(item);
                            this.setState({ data });
                        }}

                    />
                </span>
            );
        }

        else if (cellInfo.original.field === "h24mode") {

            value = data.conf[cellInfo.index][cellInfo.column.id];

            return (
                <span className="input-field-wrapper" style={{ textAlign: "center" }}>
                    <Input
                        type="checkbox"
                        checked={value === 1}
                        onChange={(e) => {
                            let newValue = (e.target.checked ? 1 : 0);
                            data.conf[cellInfo.index][cellInfo.column.id] = newValue;
                            item.radioController[cellInfo.original.field] = newValue;
                            handleItemDidChange(item);
                            this.setState({ data });
                        }}
                    /></span>
            );
        }

        else if (cellInfo.original.field === "radioCh") {
            value = data.conf[cellInfo.index][cellInfo.column.id];
            let options = [];
            let channels = [1, 2, 3, 4, 13, 14, 15, 16, 17];
            channels.map((channel) => {
                options.push(
                    { value: channel, label: "ch. " + channel }
                );
                return false;
            })
            return (
                <span className="input-field-wrapper" >
                    <Select
                        clearable={false}
                        value={value}
                        options={options}
                        onChange={option => {
                            if (option) {
                                data.conf[cellInfo.index][cellInfo.column.id] = option.value;
                                item.radioController[cellInfo.original.field] = option.value;
                                handleItemDidChange(item);
                                this.setState({ data });
                            }
                        }}
                    />
                </span>
            );
        }

        // Specialize input on editing
        let inputType     = "text";
        let addPropsInput = (() => {
            switch (cellInfo.original.field) {

                case "power":
                    inputType = "number";
                    return {};

                case "lat":
                    inputType = "number";
                    return {
                        min: -90,
                        max: 90
                    };

                case "lng":
                    inputType = "number";
                    return {
                        min: -180,
                        max: 180
                    };

                default:
                    inputType = "text";
                    return {};

            }
        })();

        return (
            <span className="input-field-wrapper">
                <Input
                    type={inputType}
                    value={value}
                    { ...addPropsInput }
                    onChange={(e) => {
                        // Get new value
                        let value = e.target.value;

                        // Normalize LAT/LNG
                        if (["lng", "lat"].includes(cellInfo.original.field)) {
                            try {
                                value = parseFloat(value);
                                if (isNaN(value)) {
                                    value = "0";
                                } else {
                                    value = value + "";
                                }
                            }
                            catch (exCoordy) {
                                console.error(exCoordy);
                                value = "0";
                            }
                        }

                        let details = [...data[hTabs]];
                        details[cellInfo.index][cellInfo.column.id] = value;
                        data[hTabs] = details;

                        let { item, handleItemDidChange } = this.props;
                        item[details[cellInfo.index].field] = value;
                        handleItemDidChange(item);

                        this.setState({ data });
                    }}
                />
            </span>
        );

    }

    /**
     * Visualizza le celle in modalità sola lettura.
     * @param {*} cellInfo
     */
    renderReadonly(cellInfo) {

        //const { instances, indexes } = this.props;
        let value = cellInfo.value;

        if (cellInfo.original.field === 'power' && value !== null) {
            value += " W";
        }
        else if (cellInfo.original.field === 'color') {
            return (<div className="legenda-square" style={{ backgroundColor: (value ? value : "#000") }} ></div>)
        }

        else if (cellInfo.original.field === 'h24mode') {
            return (
                <span className="input-field-wrapper" style={{ textAlign: "center" }}>
                    <Input
                        type="checkbox"
                        checked={value === 1}
                        readOnly
                    />
                </span>
            );
        }

        else if (cellInfo.original.field === 'radioCh') {
            return (
                <div>{value && "ch. " + value}</div>
            );
        }

        return (
            <div>{value}</div>
        );
    }

    chooseArchive(archive = null) {
        this.setState({ choosingArchve: archive, previewModalOpened: false, mediaPreview: null });
    }

    handleArchiveConfirm(id) {
        const { choosingArchve, data } = this.state;
        data.foreignKeys[this.foreignKeyIndex[choosingArchve]].val = id;
        this.handleArchiveCancel();
    }

    handleArchiveCancel() {
        this.setState({
            choosingArchve: null
        });
    }

    resetForeignKey(archive) {
        const { data } = this.state;
        data.foreignKeys[this.foreignKeyIndex[archive]].val = 0;
        this.setState({ data });
    }

    renderEditingToolbar() {
        const { item, handleSaveItemClick, handleCancelItemClick } = this.props;
        return (
            <div className="actions">
                <Button
                    id={'save_item_' + item.type + '_' + item.id}
                    color="success"
                    size="sm"
                    onClick={(e) => {
                        if (handleSaveItemClick(item)) {
                            this.save();
                        }
                    }}
                >
                    <i className="fa fa-check" />
                </Button>

                <Button
                    id={'cancel_item_' + item.type + '_' + item.id}
                    color="default"
                    size="sm"
                    onClick={(e) => {
                        handleCancelItemClick(item);
                        this.cancelEditing();
                    }}
                >
                    <i className="fa fa-times" />
                </Button>
            </div>
        );
    }

    /**
     * Disegna la toolbar in modalità visualizzazione.
     */
    renderToolbar() {
        const {
            t,
            item,
            root,
            mode,
            handleEditItemClick,
            handleDeleteItemClick,
            //handleAddItemClick,
            handleConfigItemClick,
            handleTrendItemClick,
            handleCloneItemClick,
            handleUpdatePositionFromGPS,
            handleUpdatePositionLightPointsFromGPS,
            handleScheduledCommandsClick,
            handleSendRadioControllerConfigClick
        } = this.props;

        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);
        let configurationUpToDate = item.radioController && item.radioController.configurationUpToDate;

        if (mode === NavModes.PLANT) {
            return (
                <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                    {!isGuest && (item.type === ItemTypes.CABINET) && item.device && item.device.length > 0 &&
                        <ButtonWithTooltip2
                            className="umpi-rounded-btn-toolbar"
                            placement='bottom'
                            id="btn_1"
                            color="white"
                            tooltipText={t("Comandi schedulati")}
                            onClick={(e) => {
                                handleScheduledCommandsClick(item);
                            }}
                        >
                            <i style={{ color: "#666", verticalAlign: "middle" }} className="now-ui-icons ui-1_calendar-60" />
                        </ButtonWithTooltip2>
                    }

                    <ButtonWithTooltip2
                        className="umpi-rounded-btn-toolbar"
                        placement='bottom'
                        id="btn_2"
                        color="white"
                        tooltipText={t("Grafici")}
                        onClick={(e) => {
                            handleTrendItemClick(item);
                        }}
                    >
                        <i style={{ color: "#666", verticalAlign: "middle" }} className="fa fa-bar-chart" />
                    </ButtonWithTooltip2>

                    {!isGuest && (item.type === ItemTypes.CABINET) && item.device && item.device.length > 0 &&
                        <ButtonWithTooltip2
                            className="umpi-rounded-btn-toolbar"
                            placement='bottom'
                            id="btn_3"
                            color="white"
                            tooltipText={t("Configurazione")}
                            onClick={(e) => {
                                handleConfigItemClick(item);
                            }}
                        >
                            <i style={{ color: "#666", verticalAlign: "middle" }} className="now-ui-icons loader_gear" />
                        </ButtonWithTooltip2>
                    }

                    {!isGuest && (item.deviceType === DeviceTypes.RADIOCONTROLLER) &&
                        <ButtonWithTooltip2
                            className="umpi-rounded-btn-toolbar"
                            placement='bottom'
                            id="btn_3"
                            color="white"
                            tooltipText={configurationUpToDate ? t("Invia configurazione") : t("Configurazione non aggiornata, richiesto invio della configurazione")}
                            onClick={(e) => {
                                handleSendRadioControllerConfigClick(item);
                            }}
                        >
                            <i style={{ color: (configurationUpToDate ? "#666" : "orange"), verticalAlign: "middle" }} className="now-ui-icons loader_gear" />
                        </ButtonWithTooltip2>
                    }

                    {!isGuest &&
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-round btn-simple btn-icon umpi-rounded-btn-toolbar"
                                color="default"
                                style={{ borderColor: 'white' }}
                            >
                                <VerticalDots width={15} height={15} fill={"#666"} style={{ verticalAlign: "middle", marginBottom: "2px" }}></VerticalDots>
                            </DropdownToggle>

                            <DropdownMenu right>
                                {item.type === ItemTypes.LIGHTPOINT && <DropdownItem
                                    onClick={(e) => {
                                        handleCloneItemClick(item);
                                    }}
                                ><i className="fa fa-files-o" />{t("Clona punto luce")}</DropdownItem>}

                                <DropdownItem onClick={(e) => {
                                    handleEditItemClick(item);
                                    this.startEditing();
                                }}><i className="fa fa-edit" />{item.type === ItemTypes.CABINET ? t("Modifica quadro") : t("Modifica punto luce")}</DropdownItem>

                                {item.type === ItemTypes.LIGHTPOINT &&
                                    <DropdownItem onClick={() => { handleUpdatePositionFromGPS(item); }}>
                                        <i className="fa fa-map-marker" />{t("Aggiorna posizione da coordinate GPS")}
                                    </DropdownItem>
                                }

                                {item.type === ItemTypes.CABINET &&
                                    <DropdownItem onClick={() => { handleUpdatePositionLightPointsFromGPS(item); }}>
                                        <i className="fa fa-map-marker" />{t("Aggiorna posizione punti luce da coordinate GPS")}
                                    </DropdownItem>
                                }

                                {!(new Tree(root)).hasChildren({ item }) &&
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={(e) => {
                                            handleDeleteItemClick(item);
                                        }}
                                    ><i className="fa fa-minus" />{(item.type === ItemTypes.CABINET ? t("Elimina quadro") : t("Elimina punto luce"))}
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    }
                </div>
            );
        }
        else if (mode === NavModes.ZONES) {
            return (
                <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                    {/* { !isGuest && (item.type === ItemTypes.ZONE) &&
                        <ButtonWithTooltip2
                            placement = 'bottom'
                            id = "btn_1"
                            color = "white"
                            tooltipText = {t("Comandi schedulati")}
                            onClick={(e) => {
                                handleScheduledCommandsClick(item);
                            }}
                        >
                            <i style={{ color: "#666", verticalAlign: "middle" }} className="now-ui-icons ui-1_calendar-60" />
                        </ButtonWithTooltip2>
                    } */}

                    {!isGuest && item.type === ItemTypes.ZONE &&
                        <ButtonWithTooltip2
                            className="umpi-rounded-btn-toolbar"
                            placement='bottom'
                            id="btn_3"
                            color="white"
                            tooltipText={t("Configurazione")}
                            onClick={(e) => {
                                handleConfigItemClick(item);
                            }}
                        >
                            <i style={{ color: "#666", verticalAlign: "middle" }} className="now-ui-icons loader_gear" />
                        </ButtonWithTooltip2>
                    }

                    {!isGuest &&

                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-round btn-simple btn-icon umpi-rounded-btn-toolbar"
                                color="default"
                                style={{ borderColor: 'white' }}
                            >
                                <VerticalDots width={15} height={15} fill={"#666"} style={{ verticalAlign: "middle", marginBottom: "2px" }}></VerticalDots>
                            </DropdownToggle>

                            <DropdownMenu right>

                                {item.type === ItemTypes.ZONE &&
                                    <DropdownItem
                                        className="text-danger"
                                        onClick={(e) => {
                                            handleDeleteItemClick(item);
                                        }}
                                    ><i className="fa fa-minus" />{t("Elimina zona")}
                                    </DropdownItem>
                                }

                                <DropdownItem onClick={(e) => {
                                    handleEditItemClick(item);
                                    this.startEditing();
                                }}><i className="fa fa-edit" />{item.type === ItemTypes.ZONE ? t("Modifica zona") : t("Modifica punto luce")}</DropdownItem>

                            </DropdownMenu>

                        </UncontrolledDropdown>
                    }
                </div>
            );
        }
    }

    handleUpdateDocuments(p_documents) {
        this.setState({ mediaDocuments: p_documents });
    }

    getNormalizeTypeFromProps(type) {
        switch (type) {
            case ItemTypes.CABINET: return "CABINETS";
            case ItemTypes.LIGHTPOINT: return "LIGHTPOINTS";

            default: return null;
        }
    }

    async handleDeleteDocuments(name, type, refId) {
        // Get type
        const p_type = this.getNormalizeTypeFromProps(type);
        if (!(p_type && p_type.length > 0)) { return; }

        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        try {
            // Delete doc media
            await loopbackService.deleteProjectDocumentToModel(
                name,
                selectedProjectId,
                p_type,
                refId
            );
        } catch (e) {
            console.error(e);
        }

        this.setState({
            mediaDocuments: {},
            mediaStorage: [],
            mediaPreview: null
        }, () => {
            // Refresh
            this.loadDocuments(type, refId);
        });
    }

    async handleUploadDocuments(type, refId) {
        // Get type
        const p_type = this.getNormalizeTypeFromProps(type);
        if (!(p_type && p_type.length > 0)) { return; }

        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Get documents
        const documents = this.state.mediaDocuments;

        // Get keys
        let keys = Object.keys(documents);

        // Check for docs into storage
        for (let i = 0; i < keys.length; i++) {
            // Get current media
            let media = documents[keys[i]];

            try {
                // Create doc media
                await loopbackService.pushProjectDocumentToModel(
                    selectedProjectId,
                    {
                        "filename": media.name,
                        "type":     p_type,
                        "deviceId": refId,
                        "file":     media.file
                    }
                );
            } catch (e) {
                console.error(e);
            }
        }

        this.setState({
            mediaDocuments: {},
            mediaStorage: [],
            mediaPreview: null
        }, () => {
            // Close modal
            this.chooseArchive(null);

            // Refresh
            this.loadDocuments(type, refId);
        });
    }

    loadDocuments = async (type, refId) => {
        // Get type
        const p_type = this.getNormalizeTypeFromProps(type);
        if (!(p_type && p_type.length > 0)) { return; }

        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Storage
        let docs = null;

        try {
            // Create doc media
            docs = await loopbackService.listProjectDocumentFromModel(
                selectedProjectId,
                p_type,
                refId
            );
        } catch (e) {
            console.error(e);
        }

        // Save to storage
        this.setState({
            mediaStorage: Array.isArray(docs) ? docs : []
        });
    }

    render() {
        const { item, instances, indexes } = this.props;
        const { data, editing, choosingArchve, detailsPanelIsOpen, mediaStorage, mediaPreview, previewModalOpened } = this.state;
        const { t } = this.props;

        const isLightPoint = item.type === ItemTypes.LIGHTPOINT;

        if (isLightPoint) {
            // lightpoint || radioController || radioNode
            let luminare = ((instances.luminares && instances.luminares[indexes.luminares[data.foreignKeys[this.foreignKeyIndex['luminares']].val]]) || null);
            data.luminares = [
                { field: 'type', label: t('Tipo'), val: (luminare && luminare.type) },
                { field: 'producer', label: t('Produttore'), val: (luminare && luminare.producer) },
                { field: 'model', label: t('Modello'), val: (luminare && luminare.model) },
                { field: 'optics', label: t('Ottiche'), val: (luminare && luminare.optics) }
            ];

            let support = ((instances.supports && instances.supports[indexes.supports[data.foreignKeys[this.foreignKeyIndex['supports']].val]]) || null);
            data.supports = [
                { field: 'type', label: t('Tipo'), val: (support && support.type) },
                { field: 'material', label: t('Materiale'), val: (support && support.material) },
                { field: 'height', label: t('Altezza'), val: (support && support.height) },
            ];

            let lamp = ((instances.lamps && instances.lamps[indexes.lamps[data.foreignKeys[this.foreignKeyIndex['lamps']].val]]) || null);
            data.lamps = [
                { field: 'type', label: t('Tipo'), val: (lamp && lamp.type) },
                { field: 'producer', label: t('Produttore'), val: (lamp && lamp.producer) },
                { field: 'power', label: t('Potenza'), val: (lamp && lamp.power) },
            ];

            let driver = ((instances.drivers && instances.drivers[indexes.drivers[data.foreignKeys[this.foreignKeyIndex['drivers']].val]]) || null);
            data.drivers = [
                { field: 'type', label: t('Tipo'), val: (driver && driver.type) },
                { field: 'producer', label: t('Produttore'), val: (driver && driver.producer) },
                { field: 'model', label: t('Modello'), val: (driver && driver.model) },
            ];
        }

        const titles = {
            luminares: t('Selezionare un corpo illuminante'),
            supports: t('Selezionare un supporto'),
            lamps: t('Selezionare una lampada'),
            drivers: t('Selezionare un driver')
        }

        const selectedArchiveId = (
            data.foreignKeys &&
            data.foreignKeys[this.foreignKeyIndex[choosingArchve]] ?
            data.foreignKeys[this.foreignKeyIndex[choosingArchve]].val :
            0
        );

        return (
            <div className="item-details-table configurations container">
                {choosingArchve !== null &&
                 choosingArchve !== 'documents' &&
                    <ArchiveChooser
                        selectedArchiveId={selectedArchiveId}
                        title={titles[choosingArchve]}
                        instances={instances[choosingArchve]}
                        model={data[choosingArchve]}
                        onCancel={this.handleArchiveCancel}
                        onConfirm={this.handleArchiveConfirm}
                    />
                }

                {choosingArchve !== null &&
                 choosingArchve === 'documents' &&
                    <Modal size="lg" isOpen={true} className="modal-umpi-container">
                        <ModalHeader toggle={() => this.chooseArchive(null)}>
                            {t('Editing documents')}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Documents
                                                canEdit={true}
                                                acceptFile={["pdf", "image"]}
                                                documents={mediaStorage}
                                                onUpdate={this.handleUpdateDocuments}
                                                onDelete={(media) => this.handleDeleteDocuments(media.name, item.type, item.id)}
                                                onPreview={(media) => this.setState({ mediaPreview: media })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={() => this.chooseArchive(null)}>
                                {t("Close")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save" onClick={() => this.handleUploadDocuments(item.type, item.id)}>
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                }

                {previewModalOpened &&
                    <Modal size="lg" isOpen={true} className="modal-umpi-container">
                        <ModalHeader toggle={() => this.chooseArchive(null)}>
                            {t("Preview")}
                        </ModalHeader>
                        <ModalBody style={{ padding: "0" }}>
                            {mediaPreview}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={() => this.chooseArchive(null)}>
                                {t("Close")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                }

                <Row>
                    <Col className="umpi-details-title-section">
                        <span>{item.label}</span>
                    </Col>
                    <Col className="umpi-details-actions-section">
                        <span>{
                            editing ? this.renderEditingToolbar() : this.renderToolbar()
                        }</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="umpi-details-subtitle-section">
                        {item.address && <span className="mr-3">{item.address}</span>}
                        {/*!item.isleaf && */<span className="color-blue-umpi bold underline pointer" onClick={() => this.toggleDetailsPanel()}>{
                            detailsPanelIsOpen ? t("Less details") :  t("More details")
                        }</span>}
                    </Col>
                </Row>
                {(detailsPanelIsOpen /* || item.isleaf*/) &&
                    <Row>
                        <Col>
                            <div className="umpi-details-separator">{/* sep section */}</div>
                            <TabContent
                                activeTab={this.state.hTabs}
                                className="tab-space item-details-inner mt-4">
                                <TabPane tabId="gen">

                                    {isLightPoint && data.conf && data.conf.length > 0 &&
                                        <Fragment>
                                            <div className="detail-table-section-header mt-3" >
                                                <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Configuration")}</span>
                                            </div>

                                            <ReactTable
                                                TheadComponent={props => null}
                                                {...this.props}
                                                pageSize={data.conf && data.conf.length}
                                                data={data.conf}
                                                style={{ border: "1px solid #f2f2f2" }}
                                                columns={[{
                                                    Header: "Configurazione",
                                                    columns: [
                                                        {
                                                            accessor: "label",
                                                            Cell: row => {
                                                                //let a = 1;
                                                                return (
                                                                    <span style={{
                                                                        fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                        color: "gray"
                                                                    }}>{row.value}
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            className: "gen",
                                                            accessor: "val",
                                                            style: { 'whiteSpace': 'unset', "display": "flex" },
                                                            Cell: (editing ? this.renderEditable : this.renderReadonly)
                                                        }
                                                    ]
                                                }]}
                                            />
                                        </Fragment>
                                    }

                                    <Fragment>
                                        <div className="detail-table-section-header mt-3" >
                                            <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("General data")}</span>
                                        </div>

                                        <ReactTable
                                            TheadComponent={props => null}
                                            {...this.props}
                                            pageSize={data.gen && data.gen.length}
                                            data={data.gen}
                                            className="umpi-details-section-table"
                                            columns={[{
                                                Header: "Informazioni",
                                                columns: [
                                                    {
                                                        accessor: "label",
                                                        Cell: row => {
                                                            //let a = 1;
                                                            return (
                                                                <span style={{
                                                                    fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                    color: "gray"
                                                                }}>{row.value}
                                                                </span>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        className: "gen",
                                                        accessor: "val",
                                                        style: { 'whiteSpace': 'unset', "display": "flex" },
                                                        Cell: (editing ? this.renderEditable : this.renderReadonly)
                                                    }
                                                ]
                                            }]}
                                        />
                                    </Fragment>

                                    {item.type !== ItemTypes.ZONE && <Fragment>
                                        <div className="detail-table-section-header mt-4" >
                                            <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Documents")}</span>
                                            {editing &&
                                                <span className="pull-right">
                                                    <Button className="btn-simple umpi-btn-item-properties" onClick={() => { this.chooseArchive('documents'); }} size="sm" style={{ flexGrow: 0 }}>{t('Modify')}</Button>
                                                </span>
                                            }
                                        </div>
                                        <Documents
                                            canEdit={false}
                                            acceptFile={["pdf", "image"]}
                                            documents={mediaStorage}
                                            smallSize={true}
                                            noInPreviewClose={true}
                                            noDataText={t('Nothing to show')}
                                            onPreview={(media) => this.setState({ mediaPreview: media, previewModalOpened: true })}
                                        />
                                    </Fragment>}

                                    {isLightPoint &&
                                        <Fragment>
                                            <div className="detail-table-section-header mt-4" >
                                                <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Corpo illuminante")}</span>{editing &&
                                                    <span className="pull-right">
                                                        <Button className="btn-simple umpi-btn-item-properties" onClick={() => { this.chooseArchive('luminares'); }} size="sm" style={{ flexGrow: 0 }}>{t('Select')}</Button>
                                                        <Button className="btn-simple umpi-btn-item-properties" onClick={() => { this.resetForeignKey('luminares'); }} size="sm" style={{ flexGrow: 0 }}><i className="fa fa-trash" /></Button>
                                                    </span>
                                                }
                                            </div>

                                            <ReactTable
                                                TheadComponent={props => null}
                                                {...this.props}
                                                pageSize={data.luminares && data.luminares.length}
                                                data={data.luminares}
                                                className="umpi-details-section-table"
                                                columns={[{
                                                    Header: "Informazioni",
                                                    columns: [
                                                        {
                                                            accessor: "label",
                                                            Cell: row => {
                                                                //let a = 1;
                                                                return (
                                                                    <span style={{
                                                                        fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                        color: "gray"
                                                                    }}>{row.value}
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            className: "gen",
                                                            accessor: "val",
                                                            style: { 'whiteSpace': 'unset', "display": "flex" },
                                                            Cell: this.renderReadonly
                                                        }
                                                    ]
                                                }]}
                                            />
                                        </Fragment>
                                    }

                                    {isLightPoint &&
                                        <Fragment>
                                            <div className="detail-table-section-header mt-4" >
                                                <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Supporto")}</span>
                                                {editing &&
                                                    <span className="pull-right">
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.chooseArchive('supports'); }} size="sm">{t('Select')}</Button>
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.resetForeignKey('supports'); }} size="sm"><i className="fa fa-trash" /></Button>
                                                    </span>
                                                }
                                            </div>

                                            <ReactTable
                                                TheadComponent={props => null}
                                                {...this.props}
                                                pageSize={data.supports && data.supports.length}
                                                data={data.supports}
                                                className="umpi-details-section-table"
                                                columns={[{
                                                    Header: "Informazioni",
                                                    columns: [
                                                        {
                                                            accessor: "label",
                                                            Cell: row => {
                                                                //let a = 1;
                                                                return (
                                                                    <span style={{
                                                                        fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                        color: "gray"
                                                                    }}>{row.value}
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            className: "gen",
                                                            accessor: "val",
                                                            style: { 'whiteSpace': 'unset', "display": "flex" },
                                                            Cell: this.renderReadonly
                                                        }
                                                    ]
                                                }]}
                                            />
                                        </Fragment>
                                    }

                                    {isLightPoint &&
                                        <Fragment>
                                            <div className="detail-table-section-header mt-4" >
                                                <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Lampada")}</span>
                                                {editing &&
                                                    <span className="pull-right">
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.chooseArchive('lamps'); }} size="sm">{t('Select')}</Button>
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.resetForeignKey('lamps'); }} size="sm"><i className="fa fa-trash" /></Button>
                                                    </span>
                                                }
                                            </div>

                                            <ReactTable
                                                TheadComponent={props => null}
                                                {...this.props}
                                                pageSize={data.lamps && data.lamps.length}
                                                data={data.lamps}
                                                className="umpi-details-section-table"
                                                columns={[{
                                                    Header: "Informazioni",
                                                    columns: [
                                                        {
                                                            accessor: "label",
                                                            Cell: row => {
                                                                //let a = 1;
                                                                return (
                                                                    <span style={{
                                                                        fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                        color: "gray"
                                                                    }}>{row.value}
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            className: "gen",
                                                            accessor: "val",
                                                            style: { 'whiteSpace': 'unset', "display": "flex" },
                                                            Cell: this.renderReadonly
                                                        }
                                                    ]
                                                }]}
                                            />
                                        </Fragment>
                                    }

                                    {isLightPoint &&
                                        <Fragment>
                                            <div className="detail-table-section-header mt-4" >
                                                <span style={{ flexGrow: 1 }} className="detail-table-title color-blue-umpi bold">{t("Driver")}</span>
                                                {editing &&
                                                    <span className="pull-right">
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.chooseArchive('drivers'); }} size="sm">{t('Select')}</Button>
                                                        <Button style={{ flexGrow: 0 }} className="btn-simple umpi-btn-item-properties" onClick={() => { this.resetForeignKey('drivers'); }} size="sm"><i className="fa fa-trash" /></Button>
                                                    </span>
                                                }
                                            </div>

                                            <ReactTable
                                                TheadComponent={props => null}
                                                {...this.props}
                                                pageSize={data.drivers && data.drivers.length}
                                                data={data.drivers}
                                                className="umpi-details-section-table"
                                                columns={[{
                                                    Header: "Informazioni",
                                                    columns: [
                                                        {
                                                            accessor: "label",
                                                            Cell: row => {
                                                                //let a = 1;
                                                                return (
                                                                    <span style={{
                                                                        fontWeight: (row.original && row.original.hilight ? "bold" : "normal"),
                                                                        color: "gray"
                                                                    }}>{row.value}
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            className: "gen",
                                                            accessor: "val",
                                                            style: { 'whiteSpace': 'unset', "display": "flex" },
                                                            Cell: this.renderReadonly
                                                        }
                                                    ]
                                                }]}
                                            />
                                        </Fragment>
                                    }
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                }
            </div>

        )

    }

}

export default ItemProperties = withTranslation()(ItemProperties);

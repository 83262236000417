/*global google*/
import React, { Fragment } from "react";
import { connect } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { utils } from '../../helpers';
import { PanelHeader } from "components";
import ItemProperties from './ItemProperties';
import ErrorModal from "../Components/ErrorModal"
import { roleConstants, ItemTypes, NavModes, ShapeTypes, VertexActions, FieldNames, Models, ModelPaths, ModelRelations, DeviceTypes } from '../../constants';
import { loopbackActions } from "../../actions";
import { history } from '../../helpers';
import { loopbackService } from '../../services';
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import Bell from 'assets/img/bell-53.jsx';
import Bulb from 'assets/img/bulb.jsx';
import { Map, IconPin, mapPin, mapSquare, mapDoubleCircle2, fillForBulb, strokeForBulb, fillForPin, fillForRadio, strokeForPin, fillForAlarm, fillForSquare, strokeForSquare, strokeForAlarm } from './Map';
import { Updater } from './Updater';
import { withTranslation } from 'react-i18next';
import Configurations from "../Configurations/Configurations"
import Trend from "./Trend"
import User from '../../entities/User';
import { ClipLoader } from 'react-spinners';
import ScheduledCommands from "./ScheduledCommands";
import { Tree } from "../../helpers/Tree";
import RadioTower from "assets/img/RadioTower";
import DrawPolygon from "assets/img/DrawPolygon";
import "../../assets/css/context-menu.css";
import { MAP } from "react-google-maps/lib/constants"
import * as turf from "@turf/turf";
import ZoneConfiguration from "./ZoneConfiguration";
import { CabinetEdit } from './CabinetEdit';
import { LightpointEdit } from './LightpointEdit';
import { ZoneEdit } from './ZoneEdit';

import $ from 'jquery';
window.$ = $;

//-------------------------------------------------------------------------|
// HACKFIX: SAMPLING
//-------------------------------------------------------------------------|
// Sampling Refresh limit (millis)
const SAMPLING_REFRESH_OFFSET = 200;
// 5 refresh per second
//-------------------------------------------------------------------------|
// HACKFIX: SAMPLING
//-------------------------------------------------------------------------|

class ModalCloneLightpointsComponent extends React.Component {

    /**
     * Costruttore.
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            inputNumCloneLightpoints: "1"
        };
    }

    render() {
        const { t, onConfirm, onCancel } = this.props;
        const { inputNumCloneLightpoints } = this.state;

        return (
            <Modal isOpen={true} className="modal-umpi-container">
                <ModalHeader toggle={onCancel}>
                    {t("Clonazione punto luce")}
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                        <i className='fa fa-exclamation-circle' />
                    </div>
                    <div style={{ textAlign: "center", fontSize: "1em", margin: "-20px 0 30px 0" }}>
                        <FormGroup className="">
                            <Label>
                                {t("Selezionare il numero di cloni")}:
                            </Label>
                            <Input
                                type="number"
                                min={1}
                                value={inputNumCloneLightpoints}
                                name='inputNumCloneLightpoints'
                                onChange={(e) => {
                                    const { value } = e.target;
                                    this.setState({
                                        inputNumCloneLightpoints: (
                                            (
                                                value.length > 0 &&
                                                value !== "0" &&
                                                !value.startsWith("-")
                                            ) ? value : "1"
                                        )
                                    });
                                }}
                            />
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCancel} color="default" className="umpi-modal-btn-cancel">
                        {t("Annulla")}
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={() => {
                        let occurrences = 1;

                        try {
                            occurrences = parseInt(inputNumCloneLightpoints, 10);
                        } catch (error) {
                            occurrences = 1;
                        }

                        if (isNaN(occurrences) || occurrences < 1) {
                            occurrences = 1;
                        }

                        onConfirm(occurrences);
                    }} color="success" className="umpi-modal-btn-save">
                        {t("Confirm")}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const ModalCloneLightpoints = withTranslation()(connect(mapStateToProps)(ModalCloneLightpointsComponent));

class NavigatorComponent extends React.Component {

    static models = [
        Models.CABINETS,
        Models.LIGHTPOINTS,
        Models.LUMINARES,
        Models.SUPPORTS,
        Models.LAMPS,
        Models.DRIVERS,
        Models.DEVICETYPES,
        Models.DEVICES,
        Models.RADIOCONTROLLERS,
        Models.RADIONODES,
        Models.ZONES,
        Models.TIMER_PROFILE_WEEKLY,
        Models.TIMER_PROFILE_DAILY
    ];

    /**
     * Costruttore.
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            map: null,
            loading: false,
            selectedProject: null,
            errors: [],
            tree: {
                plant: {
                    centeredTreeItem: null,
                    focusedTreeItem: null,
                    selectedTreeItem: null,
                    editingTreeItem: null,
                    originalItem: null,
                    searchTerm: "",
                    scroll: true,
                    hash: "",
                    root: []
                },
                zones: {
                    centeredTreeItem: null,
                    focusedTreeItem: null,
                    selectedTreeItem: null,
                    editingTreeItem: null,
                    originalItem: null,
                    markers: [],
                    searchTerm: "",
                    scroll: true,
                    hash: "",
                    root: [],
                    selectedVertex: 0,
                    overlapped: {}
                }
            },
            treeIndexPath: {},
            editingPeriferal: null,
            configuringItem: null,
            status: {
                cabinets: {},
                lightpoints: {}
            },
            lightpointdevicetypes: {},
            cabinetdevicetypes: {},
            alert: null,
            trendItem: null,
            showingScheduledCommands: false,
            showingZoneConfiguration: false,
            mode: NavModes.PLANT,
            markers: [],
            isCabinetEditModalOpen: false,
            isLightpointEditModalOpen: false,
            isZoneEditModalOpen: false
        };

        this.handleMapMounted = this.handleMapMounted.bind(this);
        this.handleMarkerDragEnd = this.handleMarkerDragEnd.bind(this);
        this.handleMarkerClose = this.handleMarkerClose.bind(this);
        this.handleChangePeriferalStatus = this.handleChangePeriferalStatus.bind(this);
        this.handlePeriferalChangeStatusDesired = this.handlePeriferalChangeStatusDesired.bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);
        this.handleMapRClick = this.handleMapRClick.bind(this);
        this.handleMapDblClick = this.handleMapDblClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onVertexDrag = this.onVertexDrag.bind(this);
        this.onVertexDragEnd = this.onVertexDragEnd.bind(this);
        this.onVertexClick = this.onVertexClick.bind(this);
        this.onVertexRClick = this.onVertexRClick.bind(this);
        this.onBoundsChanged = this.onBoundsChanged.bind(this);

        this.handlePolygonMounted = this.handlePolygonMounted.bind(this);
        this.handleTreeNodeClick = this.handleTreeNodeClick.bind(this);
        this.handleSaveItemClick = this.handleSaveItemClick.bind(this);
        this.handleItemDidChange = this.handleItemDidChange.bind(this);
        this.handleEditItemClick = this.handleEditItemClick.bind(this);
        this.handleCancelItemClick = this.handleCancelItemClick.bind(this);
        this.handleDeleteItemClick = this.handleDeleteItemClick.bind(this);
        //this.handleAddItemClick = this.handleAddItemClick.bind(this);
        this.handleCloneItemClick = this.handleCloneItemClick.bind(this);
        this.handleUpdatePositionFromGPS = this.handleUpdatePositionFromGPS.bind(this);
        this.handleUpdatePositionLightPointsFromGPS = this.handleUpdatePositionLightPointsFromGPS.bind(this);
        this.handleConfigItemClick = this.handleConfigItemClick.bind(this);
        this.handleTrendItemClick = this.handleTrendItemClick.bind(this);
        this.handleConfigClose = this.handleConfigClose.bind(this);
        this.handleTrendClose = this.handleTrendClose.bind(this);
        this.handleStartEditingPeriferal = this.handleStartEditingPeriferal.bind(this);
        this.handleScheduledCommandsClick = this.handleScheduledCommandsClick.bind(this);
        this.handleScheduledCommandsClose = this.handleScheduledCommandsClose.bind(this);
        this.handleZoneConfigurationClose = this.handleZoneConfigurationClose.bind(this);
        this.handleCancelEditingPeriferal = this.handleCancelEditingPeriferal.bind(this);
        this.handleSendRadioControllerConfigClick = this.handleSendRadioControllerConfigClick.bind(this);

        this.treeFocusNode = this.treeFocusNode.bind(this);
        this.treeGoDeep = this.treeGoDeep.bind(this);
        this.treeGoBack = this.treeGoBack.bind(this);
        this.itemPropertiesRemux = this.itemPropertiesRemux.bind(this);

        this.checkSamplingToLifeCycle = this.checkSamplingToLifeCycle.bind(this);

        selectedProjectChangeListeners.push(() => {
            let { tree } = this.state;

            this.cleanMap();

            tree.plant.centeredTreeItem = null;
            tree.plant.focusedTreeItem = null;
            tree.plant.selectedTreeItem = null;
            tree.plant.editingTreeItem = null;
            tree.plant.configuringItem = null;
            tree.plant.scroll = true;

            tree.zones.centeredTreeItem = null;
            tree.zones.focusedTreeItem = null;
            tree.zones.selectedTreeItem = null;
            tree.zones.editingTreeItem = null;
            tree.zones.configuringItem = null;
            tree.zones.scroll = true;
            tree.zones.selectedVertex = 0;
            tree.zones.overlapped = {};

            this.setState({
                tree,
                infowindow: null,
                treeIndexPath: {}
            }, () => {
                if (this.map) {
                    const user = User.current();
                    const selectedProjectId = localStorage.getItem("selectedProjectId");
                    let { z, c } = utils.fromSession(user.id, selectedProjectId);
                    if (z && c) {
                        this.map.setZoom(z);
                        this.map.setCenter(c);
                    }
                }

                this.fetchData();
            });
        });

        this.streamUpdater = new Updater(this);
        this.polygonRefs = {};

        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|
        // Sampling Refresh limit
        this.enableSampling = false;

        // Setup fake updater
        this.samplingTicker = setInterval(() => {
            // Sampling Refresh Limit
            if (this.enableSampling &&
                !this.checkSamplingToLifeCycle()) {
                // Disable concurrent sampling
                this.enableSampling = false;

                // Force refresh for tick
                this.forceUpdate(() => {
                    // Enable sampling post update
                    this.enableSampling = true;
                });
            }
        }, SAMPLING_REFRESH_OFFSET);
        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|
    }

    //-------------------------------------------------------------------------|
    // HACKFIX: SAMPLING
    //-------------------------------------------------------------------------|
    checkSamplingToLifeCycle() {
        // Get data
        const {
            tree,
            mode,
            editingPeriferal,
            configuringItem,
            showingScheduledCommands,
            showingZoneConfiguration,
            isCabinetEditModalOpen,
            isLightpointEditModalOpen,
            isZoneEditModalOpen
        } = this.state;

        // Check for toggle refresh type
        if (!(
            tree[mode].editingTreeItem === null &&
            editingPeriferal === null &&
            configuringItem === null &&
            showingScheduledCommands === false &&
            showingZoneConfiguration === false &&
            isCabinetEditModalOpen === false &&
            isLightpointEditModalOpen === false &&
            isZoneEditModalOpen === false
        )) {
            // Enable lifecycle
            return true;
        }

        // Always false -> Sampling
        return false;
    }
    //-------------------------------------------------------------------------|
    // HACKFIX: SAMPLING
    //-------------------------------------------------------------------------|

    toggleCabinetEditModal = (state) => {
        this.setState({
            isCabinetEditModalOpen: (state === true) ? true : false
        }, () => {
            if (state === false) {
                // this.fetchData();
            }
        });
    }

    toggleLightpointEditModal = (state) => {
        this.setState({
            isLightpointEditModalOpen: (state === true) ? true : false
        }, () => {
            if (state === false) {
                // this.fetchData();
            }
        });
    }

    toggleZoneEditModal = (state) => {
        this.setState({
            isZoneEditModalOpen: (state === true) ? true : false
        }, () => {
            if (state === false) {
                // this.fetchData();
            }
        });
    }

    /**
     *
     * @param {*} item
     */
    handleEditItemClick(item) {

        let { tree, mode } = this.state;

        let editingTreeItem = tree[mode].editingTreeItem;
        if (editingTreeItem && editingTreeItem === item) {
            return;
        }

        this.streamUpdater.stopRefresh();

        editingTreeItem = item;

        tree[mode].editingTreeItem = editingTreeItem;

        tree[mode].originalItem = item.id ? JSON.parse(JSON.stringify(item)) : {};

        if (item.id) {
            this.renderMapFeatureByItem(item.type, item.id);
        }

        if (mode === NavModes.ZONES && this.map) {
            this.map.setOptions({ draggableCursor: "crosshair" });
        }

        this.setState({ tree, infowindow: null });

    }

    /**
     * Restituisce l'elemento selezionabile successivo a quello passato come argomento.
     */
    getNextSelectedTreeItem(node, item) {
        if (node.children) {
            for (let i = 0; i < node.children.length; i++) {
                let child = node.children[i];
                if (child.data && child.data.item === item) {
                    // elemento trovato
                    let nextNode = (i > 0 ? node.children[i - 1] : node);
                    if (nextNode.data && nextNode.data.item) {
                        return nextNode.data.item;
                    }
                }
                let res = this.getNextSelectedTreeItem(child, item);
                if (res)
                    return res;
            }
        }
        return null;

    }

    /**
     *
     * @param {*} item
     */
    handleDeleteItemClick(item) {
        const { t } = this.props;
        const { mode } = this.state;

        let title = null;
        if (mode === NavModes.PLANT && item.type === ItemTypes.CABINET)
            title = t("Elimina quadro");
        else if (mode === NavModes.ZONES && item.type === ItemTypes.ZONE)
            title = t("Elimina zona");
        else
            title = t("Elimina punto luce");

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                        {(mode === NavModes.ZONES && item.type === ItemTypes.ZONE) &&
                            <div style={{ textAlign: "center", fontSize: "1em", margin: "-30px 0 30px 0" }}>
                                {t("Attenzione! La cancellazione della zona comporterà la perdita della programmazione dei punti luce presenti nella zona.")}
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                        const { dispatch } = this.props;
                            if (item && item.id) {
                                if (mode === NavModes.PLANT && item.type === ItemTypes.CABINET) {
                                    await dispatch(loopbackActions._delete(ModelPaths.PLANT, Models.CABINETS, item.id));
                                }
                                else if (mode === NavModes.ZONES && item.type === ItemTypes.ZONE) {
                                    await dispatch(loopbackActions._delete(ModelPaths.PLANT, Models.ZONES, item.id));
                                }
                                else {
                                    await dispatch(loopbackActions._delete(ModelPaths.PLANT, Models.LIGHTPOINTS, item.id));
                                }

                                this.setState({
                                    alert: null
                                }, () => this.treeGoBack());
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    /**
     *
     * @param {*} item
     */
    handleConfigItemClick(item) {

        this.streamUpdater.stopRefresh();

        const { mode } = this.state;

        if (mode === NavModes.PLANT) {
            this.setState({
                configuringItem: item
            });
        }
        else {
            this.setState({
                showingZoneConfiguration: true
            });
        }

    }

    /**
     *
     * @param {*} item
     */
    handleTrendItemClick(item) {
        this.streamUpdater.stopRefresh();
        this.setState({
            trendItem: item
        });
    }

    /**
     *
     * @param {*} item
     */
    handleUpdatePositionFromGPS(item) {
        const { t } = this.props;
        let title = t("Aggiorna posizione da coordinate GPS");

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Attenzione! La posizione del punto luce verrà modificata.")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            if (item && item.id) {
                                let resp = loopbackService.customMethod(ModelPaths.PLANT, Models.LIGHTPOINTS, item.id, 'PATCH', 'setpositionfromgps');
                                resp.then((value) => {
                                    // verifica se operazione eseguita con successo
                                    if (!value.error) {
                                        let newItem = { ...item }
                                        newItem.geometry = value.geometry;

                                        this.handleMarkerClose();
                                        this.replaceItem(this.state, item, newItem);
                                    }
                                    else {
                                        console.log("UPDATE_POSITION", "ERROR", value);
                                    }
                                })
                                this.setState({ alert: null });
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    /**
     *
     * @param {*} item
     */
    handleUpdatePositionLightPointsFromGPS(item) {
        const { t } = this.props;
        const { instances } = this.state;
        let title = t("Aggiorna posizione punti luce da coordinate GPS");

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Attenzione! La posizione del punto luce verrà modificata.")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            if (item && item.id) {
                                let resp = loopbackService.customMethod(ModelPaths.PLANT, Models.CABINETS, item.id, 'PATCH', 'lightpoints/setpositionfromgps');
                                resp.then((value) => {
                                    this.handleMarkerClose();
                                    // verifica se operazione eseguita con successo
                                    if (!value.error) {
                                        if (value.lightpoints && value.lightpoints.length > 0) {
                                            // Ciclo per aggiornamento posizione dei punti luce
                                            for (let lp of value.lightpoints) {
                                                if (lp.result && !lp.result.error) {
                                                    // Trova la corretta istanza del lightpoint
                                                    let lpItem = instances.lightpoints.find(el => el.id === lp.id);
                                                    if (lpItem.id) {
                                                        let newlpItem = { ...lpItem };
                                                        newlpItem.geometry = lp.result.geometry;
                                                        this.replaceItem(this.state, lpItem, newlpItem);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        console.log("UPDATE_POSITION", "ERROR", value);
                                    }
                                })

                                //this.setNextSelectedTreeItem(item);
                                this.setState({
                                    alert: null
                                });
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    /**
     *
     * @param {*} item
     */
    handleCloneItemClick(item) {
        const { t } = this.props;
        this.setState({
            alert: (
                <ModalCloneLightpoints
                    onCancel={() => {
                        this.setState({
                            alert: null
                        });
                    }}
                    onConfirm={(occurrences = 1) => {
                        const { instances, indexes } = this.state;
                        let radiocontrollerId = null;
                        let data = [];

                        if (item.type === ItemTypes.LIGHTPOINT) {
                            if (item.deviceType === DeviceTypes.RADIOCONTROLLER) {
                                let radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[item.id]];
                                radiocontrollerId = radiocontroller.id;
                            }
                            else if (item.deviceType === DeviceTypes.RADIONODE) {
                                radiocontrollerId = item.radioControllerId;
                            }
                        }

                        let itemcopy = JSON.parse(JSON.stringify(item));
                        // numero di punti luce di questo cabinet o radiocontroller
                        let conto = -1, num = 0;

                        if (instances && instances.lightpoints) {
                            instances.lightpoints.map(
                                (lp) => {
                                    if (radiocontrollerId) {
                                        if (lp.radioControllerId === radiocontrollerId) {
                                            conto++;
                                            if (lp.label) {
                                                try {
                                                    let labnum = parseInt(lp.label.match(/\d+$/)[0], 10);
                                                    if (labnum > num)
                                                        num = labnum;
                                                } catch (e) { }
                                            }
                                        }
                                    }
                                    else {
                                        if (lp.cabinetId === item.cabinetId) {
                                            conto++;
                                            if (lp.label) {
                                                try {
                                                    let labnum = parseInt(lp.label.match(/\d+$/)[0], 10);
                                                    if (labnum > num)
                                                        num = labnum;
                                                } catch (e) { }
                                            }
                                        }
                                    }
                                    return false;
                                }
                            );
                        }

                        delete itemcopy.id;
                        delete itemcopy.device;
                        delete itemcopy.deviceType;

                        if (num > conto) {
                            conto = num;
                        }

                        for (let i = 1; i <= occurrences; i++) {
                            data.push(
                                {
                                    ...itemcopy,
                                    // label: itemcopy.label + " " + t("copia") + "  " + (i),
                                    label: t("Nuovo punto luce") + " " + (conto + i),
                                }
                            );
                        }

                        const { dispatch } = this.props;
                        dispatch(loopbackActions.add(ModelPaths.PLANT, Models.LIGHTPOINTS, data));
                        this.setState({ alert: null });
                    }}
                />
            )
        });
    }

    /**
     *
     * @param {*} item
     */
    handleSendRadioControllerConfigClick(item) {
        const { t } = this.props;
        let { instances, indexes } = this.state;
        let radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[item.id]];

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Invia configurazione")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={() => {
                            // Apro uno spinner
                            this.setState({
                                alert: (
                                    <Modal isOpen={true} className="modal-umpi-container">
                                        <ModalHeader toggle={() => {
                                            this.setState({
                                                alert: null
                                            });
                                        }}>
                                            {t("Operazione in corso...")}
                                        </ModalHeader>
                                        <ModalBody>
                                            <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={60}
                                                    color={'#114d8c'}
                                                    loading={true}
                                                />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                )
                            }, async () => {
                                try {
                                    let ret = await loopbackService.postFuncname(ModelPaths.PLANT, 'radiocontrollers/' + radiocontroller.id, 'configuration/upload', {});

                                    this.setState({
                                        alert: (
                                            <Modal isOpen={true} className="modal-umpi-container">
                                                <ModalHeader toggle={() => {
                                                    this.setState({
                                                        alert: null
                                                    });
                                                }}>
                                                    {t("Esito operazione")}
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                        <i className='fa fa-exclamation-circle' />
                                                    </div>
                                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                        {(
                                                            (ret.result === 'SUCCESS') ?
                                                            t("Configurazione inviata con successo") :
                                                            t("Invio configurazione fallito")
                                                        )}
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            alert: null
                                                        });
                                                    }} color="success" className="umpi-modal-btn-save">
                                                        {t("Confirm")}
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        )
                                    });
                                }
                                catch (error) {
                                    this.setState({
                                        alert: null,
                                        errors: [error],
                                        loading: false
                                    });
                                }
                            });
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    /**
     *
     * @param {*} item
     */
    handleSaveItemClick(item) {
        const { t, devices } = this.props;
        let { tree, mode } = this.state;
        let editingTreeItem = tree[mode].editingTreeItem;

        if (editingTreeItem) {
            if (editingTreeItem.type === ItemTypes.ZONE) {
                if (!editingTreeItem.label || editingTreeItem.label.length <= 0) {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Impossibile salvare")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                        <i className='fa fa-exclamation-circle' />
                                    </div>
                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                        {t("Label required")}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.setState({
                                            alert: null
                                        });
                                    }} color="success" className="umpi-modal-btn-save">
                                        {t("Confirm")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        )
                    });
                    return false;
                }
                if (!editingTreeItem.geometry || editingTreeItem.geometry.length < 3) {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Impossibile salvare")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                        <i className='fa fa-exclamation-circle' />
                                    </div>
                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                        {t("Selezionare almeno 3 punti")}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.setState({
                                            alert: null
                                        });
                                    }} color="success" className="umpi-modal-btn-save">
                                        {t("Confirm")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        )
                    });
                    return false;
                }
            }

            tree[mode].editingTreeItem = null;
            tree[mode].originalItem = null;
            this.setState({ tree });
        }
        if (mode === NavModes.ZONES && this.map) {
            this.map.setOptions({ draggableCursor: "default" });
        }
        if (devices) {
            this.streamUpdater.startRefresh(devices.instances);
        }
        return true;
    }

    /**
     *
     * @param {*} item
     */
    async handleItemDidChange(item) {
        let { tree, mode } = this.state;
        let editingTreeItem = tree[mode].editingTreeItem;

        if (editingTreeItem) {

            //let originalItem = tree[mode].originalItem;
            let { instances, indexes } = this.state;

            // if (markers){
            //   for (let marker of markers){
            //       if (marker.item.type==item.type && marker.item.id==item.id){
            //         marker.item = item;
            //         break;
            //       }
            //   }
            // }

            if (item.type === ItemTypes.CABINET) {
                instances.cabinets[indexes.cabinets[item.id]] = item;
            }
            else if (item.type === ItemTypes.LIGHTPOINT) { // || item.type===ItemTypes.RADIOCONTROLLER || item.type===ItemTypes.RADIONODE ){
                instances.lightpoints[indexes.lightpoints[item.id]] = item;
            }
        }

    }

    /**
     *
     * @param {*} item
     */
    handleCancelItemClick(item) {
        const { devices } = this.props;

        let { tree, mode, features } = this.state;

        if (tree[mode].editingTreeItem) {

            let { instances, indexes } = this.state;

            let originalItem = tree[mode].originalItem;

            // ripristino l'originale anche nella feature
            let feature = item.id && features[item.type][item.id];
            if (feature) {
                feature.setProperty("item", originalItem);
                feature.setGeometry(originalItem.geometry);
            }

            switch (item.type) {
                case ItemTypes.CABINET:
                    if (!item.id) {
                        let pos = instances.cabinets.indexOf(item);
                        if (pos !== -1) {
                            instances.cabinets.splice(pos, 1);
                        }
                    }
                    else {
                        instances.cabinets[indexes.cabinets[item.id]] = originalItem;
                    }
                    break;
                case ItemTypes.ZONE:
                    if (!item.id) {
                        let pos = instances.zones.indexOf(item);
                        if (pos !== -1) {
                            instances.zones.splice(pos, 1);
                        }
                    }
                    else {
                        instances.zones[indexes.zones[item.id]] = originalItem;
                    }
                    break;
                case ItemTypes.LIGHTPOINT:
                    if (!item.id) {
                        let pos = instances.lightpoints.indexOf(item);
                        if (pos !== -1) {
                            instances.lightpoints.splice(pos, 1);
                        }
                    }
                    else {
                        instances.lightpoints[indexes.lightpoints[item.id]] = originalItem;
                    }
                    break;
                default: break;
            };

            let t = new Tree(tree[mode].root);

            if (item.id) {
                tree[mode].selectedTreeItem = originalItem;
                let treeItemToFind = tree[mode].editingTreeItem;

                delete treeItemToFind["children"];
                delete treeItemToFind["isleaf"];

                let node = t.search({ item: treeItemToFind });
                node.data.item = originalItem;
            } else {
                tree[mode].selectedTreeItem = null;
                t.removeNode({ item });
                tree[mode].root = t.getRoot();
                tree[mode].hash = utils.cyrb53(JSON.stringify(tree[mode].root));
            }

            tree[mode].editingTreeItem = null;
            tree[mode].originalItem = null;

            this.setState({ tree, instances });
        }

        if (mode === NavModes.ZONES && this.map) {
            this.map.setOptions({ draggableCursor: "default" });
        }

        if (devices)
            this.streamUpdater.startRefresh(devices.instances);
    }

    componentWillUnmount() {
        this.streamUpdater.stopRefresh();

        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|
        // Enable sampling
        this.enableSampling = false;

        // Sampling ticker
        clearTimeout(this.samplingTicker);

        // Sampling Refresh events
        $("div#navigator-sampling-ctx").off("mousedown", false);
        $("div#navigator-sampling-ctx").off("mouseup",   false);
        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|
    }

    shouldComponentUpdate() {
        // Always block -> sampling
        return this.checkSamplingToLifeCycle();
    }

    componentDidMount() {
        //-------------------------------------------------------------------------|
        // HACKFIX: ALWAYS TOP
        //-------------------------------------------------------------------------|
        $("div.wrapper>.main-panel.umpi-perfect-scrollbar-off").scrollTop(0);
        //-------------------------------------------------------------------------|
        // HACKFIX: ALWAYS TOP
        //-------------------------------------------------------------------------|

        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|
        // Sampling Refresh handler
        const setSampling = (status) => {
            // Change status
            this.enableSampling = status;
        };

        // Sampling Refresh events
        $("div#navigator-sampling-ctx").on("mousedown", () => setSampling(false));
        $("div#navigator-sampling-ctx").on("mouseup",   () => setSampling(true));

        // Enable sampling
        this.enableSampling = true;
        //-------------------------------------------------------------------------|
        // HACKFIX: SAMPLING
        //-------------------------------------------------------------------------|

        if ([
            NavModes.PLANT,
            NavModes.ZONES
        ].includes(this.props.renderMode)) {
            this.setState({
                mode: this.props.renderMode,
                infowindow: null
            }, () => {
                this.fetchData();
                //this.buildTestData();
            });
        }
        else {
            this.props.history.push("/");
        }
    }

    buildTestData() {
        try {

            let lat = 43.946046;
            let lng = 12.725988;

            for (let i = 0; i < 100; i++) {

                let metri = 50 * i;
                let coef = metri * 0.0000089;
                lat = (lat - coef);
                lng = (lng - coef / Math.cos(lat * 0.018));

                loopbackService.edit(ModelPaths.PLANT, Models.LIGHTPOINTS, {
                    "label": "luce auto " + i,
                    "lightpoint_internal_code": "codice " + i,
                    "note": "",
                    "geometry": {
                        "lat": lat,
                        "lng": lng
                    },
                    "address": "indirizzo " + i,
                });
            }
        } catch (error) {
            //console.log(error);
        }

        // try {
        //   for (let i=4; i<503; i++){
        //     loopbackService.delete(ModelPaths.PLANT,Models.LIGHTPOINTS,i);
        //   }
        // } catch(error){
        //   console.log(error);
        // }
    }

    /**
     *
     */
    fetchData() {
        const { dispatch, loggedinUser } = this.props;

        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        let args = [
            //{ path: ModelPaths.ADMIN, model: Models.PROJECTS, where: { members: { like: "%\"" + loggedinUser.id + "\"%" } }, limit: null, include: null },
            { path: ModelPaths.ADMIN, model: 'users/' + loggedinUser.id + '/projects', where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.CABINETS, where: null, limit: null, include: ModelRelations.DEVICE },
            { path: ModelPaths.PLANT, model: Models.LIGHTPOINTS, where: null, limit: null, include: [ModelRelations.DEVICE, ModelRelations.RADIOCONTROLLER] },
            { path: ModelPaths.PLANT, model: Models.RADIOCONTROLLERS, where: null, limit: null, include: ModelRelations.NODE },
            { path: ModelPaths.PLANT, model: Models.RADIONODES, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.LUMINARES, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.SUPPORTS, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.LAMPS, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.DRIVERS, where: null, limit: null, include: null },
            { path: ModelPaths.ARCHIVE, model: Models.DEVICES, where: { projectId: selectedProjectId }, limit: null, include: null },
            { path: ModelPaths.ARCHIVE, model: Models.DEVICETYPES, where: { projectId: selectedProjectId }, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.ZONES, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.TIMER_PROFILE_WEEKLY, where: null, limit: null, include: null },
            { path: ModelPaths.PLANT, model: Models.TIMER_PROFILE_DAILY, where: null, limit: null, include: null },

        ];

        const user = User.current();
        if (user.userRoleId === roleConstants.SUPERUSERUMPI || user.userRoleId === roleConstants.ADMIN) {
            args.push(
                { path: ModelPaths.ARCHIVE, model: Models.ANDROS_NOT_LINKED, where: { projectId: selectedProjectId }, limit: null, include: null },
                { path: ModelPaths.ARCHIVE, model: Models.SYRAS_NOT_LINKED, where: { projectId: selectedProjectId }, limit: null, include: null },
            );
        }

        dispatch(loopbackActions.multiList(args));
    }

    /**
     * Callback react, gestisce i cambi di stato generati dalle actions.
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.scheduledcommands) {
            return null;
        }

        if (nextProps && nextProps.alarms) {
            return null;
        }

        if (nextProps.configurations) {
            return;
        }

        let precState = this.state;
        let state = this.stateFromProps(precState, nextProps);

        if (!state) {
            return;
        }
        else if (// Refresh selectedItem and centeredItem post editing
            state.mode === NavModes.ZONES &&
            nextProps.multiEditDidEnd === true
        ) {
            // Exctract updated item
            let newSelectedItem = (() => {
                // Prepare data
                let tree  = state.tree[state.mode];
                let root  = Array.isArray(tree.root) ? tree.root                : [];
                let curId = tree.selectedTreeItem    ? tree.selectedTreeItem.id : null;

                // Search updated zone from ID
                for (let i = 0; i < root.length; i++) {
                    // Check item type and ID
                    if (root[i] &&
                        root[i].data &&
                        root[i].data.item &&
                        root[i].data.item.type === ItemTypes.ZONE &&
                        root[i].data.item.id === curId) {
                        // Send pointer
                        return this.itemPropertiesRemux(root[i]);
                    }
                }

                // Send nothing
                return null;
            })();

            // Check for non NULL item
            if (newSelectedItem) {
                state.tree[state.mode].selectedTreeItem = newSelectedItem;
                state.tree[state.mode].centeredTreeItem = newSelectedItem;
            }
        }

        this.setState(state);

        if (nextProps.devices &&
            nextProps.devices &&
            nextProps.devices.instances &&
            !state.tree[state.mode].editingTreeItem
        ) {
            this.streamUpdater.startRefresh(nextProps.devices.instances);
        }
    }

    /**
     *
     * @param {*} target
     * @param {*} pattern
     */
    matches(target, pattern) {
        return pattern.test(target.label) || pattern.test(target.address);
    }

    /**
     *
     * @param {*} lightpoints
     * @param {*} searchTerm
     */
    applyFilter(lightpoints, searchTerm) {
        let pattern = new RegExp("^" + searchTerm, "i");
        let filtered = lightpoints.filter((lightpoint) => this.matches(lightpoint, pattern));
        return filtered;
    }

    /**
     *
     */
    buildRootZones(instances) {

        let rootZones = [];
        let zonesTreeDataMap = {};

        if (instances.zones) {

            // aggiungo le zone al "rootZones"
            for (let zone of instances.zones) {
                // aggiungo la zona all'albero
                let node = {
                    data: { item: zone },
                    children: []
                };
                rootZones.push(node);
                // cache dei nodi radice, serve per aggiungere i figli
                zonesTreeDataMap[zone.id] = node;
            }
        }

        // inserisco i lightpoints nelle zone
        if (instances.lightpoints) {
            for (let item of instances.lightpoints) {
                // aggiungo il lightpoint all'albero delle zone
                if (item.zoneId > 0) {
                    let node = zonesTreeDataMap[item.zoneId];
                    if (node) {
                        let lightPointNode = { data: { item } };
                        node.children.push(lightPointNode);
                    }
                }
            }
        }

        return rootZones;
    }

    /**
     * Costruisce le strutture dati core a partire dalle props.
     * @param {*} props
     * @param {*} searchTerm
     */
    buildCoreDataFromProps(props, searchTerm) {

        // strutture dati core
        let instances = {}, indexes = {}, rootPlant = [], relations = {}, features = { [ItemTypes.CABINET]: {}, [ItemTypes.LIGHTPOINT]: {}, [ItemTypes.ZONE]: {} };

        if (props.androsNotLinked && props.androsNotLinked.instances) {
            instances[Models.ANDROS_NOT_LINKED] = props.androsNotLinked.instances;
            indexes[Models.ANDROS_NOT_LINKED] = utils.doIndex(instances[Models.ANDROS_NOT_LINKED], FieldNames.SN);
        }

        // creo le strutture dati core: instances e indexes
        for (const model of NavigatorComponent.models) {
            if (props[model] && props[model].instances) {
                if (model === Models.LIGHTPOINTS && searchTerm && searchTerm.trim().length > 0) {
                    instances[model] = this.applyFilter(props[model].instances, searchTerm);
                }
                else {
                    instances[model] = props[model].instances;
                }
                if (model !== Models.DEVICES) {

                    if (model === Models.RADIOCONTROLLERS || model === Models.RADIONODES) {
                        // indicizzo i radioController ed i radionodes per lightPointId
                        indexes[model] = utils.doIndex(instances[model], FieldNames.LIGHTPOINT_ID);
                    }
                    else {
                        // altrimenti per id
                        indexes[model] = utils.doIndex(instances[model], FieldNames.ID);
                    }
                }
            }
        }

        // cache dei nodi padre cabinet
        let cabinetTreeDataMap = {};

        // ordino i cabinet per label e creo la relazione cabinets_devices
        if (instances.cabinets) {

            relations.cabinets_devices = {};

            instances.cabinets.sort((a, b) => {
                if (a.label === b.label) return 0;
                else return (a.label < b.label ? -1 : 1);
            });
            indexes.cabinets = utils.doIndex(instances.cabinets, FieldNames.ID);

            // costruzione del modello dati (rootPlant) della vista ad albero

            // aggiungo i cabinet al rootPlant ed alle features
            for (let cabinet of instances.cabinets) {

                // tipizzo l'item
                cabinet.type = ItemTypes.CABINET;
                cabinet.deviceType = null;

                // se il cabinet ha un device Andros associato, creo la relazione cabinet - device per ottenere il sn
                relations.cabinets_devices[cabinet.id] = null;
                if (cabinet.device) {
                    for (const device of cabinet.device) {
                        if (device.deviceTypeId === DeviceTypes.ANDROS) {
                            relations.cabinets_devices[cabinet.id] = device.sn;
                            cabinet.deviceType = DeviceTypes.ANDROS;
                            break;
                        }
                    }
                }

                // aggiungo il cabinet all'albero dell'impianto
                let cabinetTreeData = {
                    data: { item: cabinet },
                    children: []
                };
                rootPlant.push(cabinetTreeData);

                // aggiorno la cache dei nodi radice, usata poi per aggiungere i figli
                cabinetTreeDataMap[cabinet.id] = cabinetTreeData;

                // se il cabinet è geolocalizzato creo la feature corrispondente
                if (cabinet.geometry && cabinet.geometry.lat !== 0 && cabinet.geometry.lng !== 0) {
                    features[ItemTypes.CABINET][cabinet.id] = new google.maps.Data.Feature({
                        id: cabinet.id,
                        geometry: cabinet.geometry,
                        properties: { item: cabinet }
                    });
                }
            }
        }

        // aggiungo i lightpoint ai rootPlant
        if (instances.lightpoints) {

            // mappa utilizzata per aggiungere nodi al radiocontroller
            let radiocontrollersTreeDataMap = {};

            let radionodeLightpoints = [];

            relations.lightpoints_devices = {};

            // ordino i lightpoint per label
            instances.lightpoints.sort((a, b) => {
                if (a.label === b.label) return 0;
                else return (a.label < b.label ? -1 : 1);
            });

            // e li indicizzo
            indexes.lightpoints = utils.doIndex(instances.lightpoints, FieldNames.ID);

            // creo le features ed i nodi dell'albero
            for (let lightpoint of instances.lightpoints) {

                // tipizzo il lightpoint
                lightpoint.type = ItemTypes.LIGHTPOINT;

                // tipo di dispositivo abbinato al lightpoint
                lightpoint.deviceType = (lightpoint.device ? lightpoint.device.deviceTypeId : null);

                // se il lightpoint è geolocalizzato creo la feature corrispondente
                if (lightpoint.geometry && lightpoint.geometry.lat !== 0) {
                    features[ItemTypes.LIGHTPOINT][lightpoint.id] = new google.maps.Data.Feature({
                        id: lightpoint.id,
                        geometry: lightpoint.geometry,
                        properties: { item: lightpoint }
                    });
                }

                // estraggo la relazione lightpoint - device per ottenere il sn
                relations.lightpoints_devices[lightpoint.id] = null;
                if (lightpoint.device) {
                    relations.lightpoints_devices[lightpoint.id] = lightpoint.device.sn;
                }

                // radionode abbinato
                let radionode = instances.radionodes[indexes.radionodes[lightpoint.id]];
                if (radionode) {
                    // se è abbinato ad un radionode non è figlio di un cabinet --> passo al lightpoint successivo
                    lightpoint.radioControllerId = radionode.radioControllerId;
                    radionodeLightpoints.push(lightpoint);
                    continue;
                }

                // creo il nodo dell'albero dell'impianto
                let lightPointTreeData = { data: { item: lightpoint } };

                // radiocontroller abbinato
                let radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[lightpoint.id]];
                if (radiocontroller) {
                    lightPointTreeData["children"] = [];
                    radiocontrollersTreeDataMap[radiocontroller.id] = lightPointTreeData;
                }

                // cabinet abbinato
                let cabinet = instances.cabinets[indexes.cabinets[lightpoint.cabinetId]];
                if (cabinet) {
                    // il lightpoint/radiocontroller appartiene ad un cabinet
                    let cabinetTreeData = cabinetTreeDataMap[cabinet.id];
                    if (cabinetTreeData) {
                        cabinetTreeData.children.push(lightPointTreeData);
                    }
                } else {
                    // aggiungo il lightpoint alla root
                    rootPlant.push(lightPointTreeData);
                }

            } // for (let item of instances.lightpoints) {

            // inserisco i lightpointRadionodes pendenti
            for (let item of radionodeLightpoints) {
                let radionode = instances.radionodes[indexes.radionodes[item.id]];
                let radiocontrollerTreeData = radiocontrollersTreeDataMap[radionode.radioControllerId];
                if (radiocontrollerTreeData) {
                    // let deviceType = ( item.device ? item.device.deviceTypeId : ItemTypes.LIGHTPOINT ); // in assenza del device default 1
                    let nodeTreeData = { data: { item } };
                    radiocontrollerTreeData.children.push(nodeTreeData);
                }
            }

        } // if (instances.lightpoints)

        // syrasNotLinked --> dispositivi syras assegnabili ai lightPoint
        if (props.syrasNotLinked && props.syrasNotLinked.instances) {
            instances.syrasNotLinked = props.syrasNotLinked.instances;
            indexes.syrasNotLinked = utils.doIndex(instances.syrasNotLinked, 'sn');
        }

        // Zone
        let rootZones = null;
        if (instances.zones) {

            rootZones = this.buildRootZones(instances);

            for (let zone of instances.zones) {
                zone.type = ItemTypes.ZONE;

                // creo la feature
                if (zone.geometry) {
                    let feature = null;
                    switch (zone.geometry.length) {
                        case 0:
                            break;

                        case 1: // un punto
                            feature = new google.maps.Data.Feature({
                                id: zone.id,
                                geometry: zone.geometry,
                                properties: { item: zone }
                            });
                            break;

                        case 2: // una retta
                            feature = new google.maps.Data.Feature({
                                id: zone.id,
                                geometry: new google.maps.Data.PolyLine([zone.geometry]),
                                properties: { item: zone }
                            });
                            break;

                        default: // un poligono
                            feature = new google.maps.Data.Feature({
                                id: zone.id,
                                geometry: new google.maps.Data.Polygon([zone.geometry]),
                                properties: { item: zone }
                            });
                    }
                    if (feature) {
                        features[ItemTypes.ZONE][zone.id] = feature;
                    }
                }
            }
        }

        let { tree, mode } = this.state;
        tree.plant.root = rootPlant;
        tree.zones.root = rootZones;

        return {
            instances,
            indexes,
            relations,
            tree,
            mode,
            features
        }
    }

    /**
     * Rimuove il marker associato all'item passato come argomento
     * @param {*} item
     */
    // removeMarker(state, item){
    //   let { markers } = state;
    //   markers = markers.filter( (marker)=>{
    //     return (!(marker.type === item.type && marker.item.id === item.id));
    //    });
    // }

    /**
    * Elimina un item dalle strutture dati core.
    * @param {*} state
    * @param {*} item
    */
    delItemCoreData(state, item) {

        let { tree, mode } = state;

        if (item.type === ItemTypes.CABINET) {

            // è un cabinet
            state.instances.cabinets = state.instances.cabinets.filter((el) => el.id !== item.id)
            state.indexes.cabinets = utils.doIndex(state.instances.cabinets, FieldNames.ID);

            // elimino i lightpoint collegati
            state.instances.lightpoints = state.instances.lightpoints.filter((el) => {
                let ok = el.cabinetId !== item.id;
                if (!ok) {
                    this.removeItemFeature(el, state);
                    // this.removeMarker(state, el);
                }
                return ok;
            });
            state.indexes.lightpoints = utils.doIndex(state.instances.lightpoints, FieldNames.ID);
        }

        if (item.type === ItemTypes.ZONE) {
            // è una zona
            state.instances.zones = state.instances.zones.filter((el) => el.id !== item.id)
            state.indexes.zones = utils.doIndex(state.instances.zones, FieldNames.ID);
        }

        else if (item.type === ItemTypes.LIGHTPOINT) {

            if (item.deviceType === DeviceTypes.SYRA) {
                // è un syra
                state.instances.lightpoints = state.instances.lightpoints.filter((el) => el.id !== item.id)
                state.indexes.lightpoints = utils.doIndex(state.instances.lightpoints, FieldNames.ID);
            }
            else if (item.deviceType === DeviceTypes.RADIOCONTROLLER) {
                // è un radiocontroller
                let radioController = state.instances.radiocontrollers[state.indexes.radiocontrollers[item.id]];
                if (radioController) {
                    // elimino tutti i lightpoint collegati
                    let radioNode = state.instances.radionodes[state.indexes.radionodes[item.id]];
                    if (radioNode) {
                        state.instances.lightpoints = state.instances.lightpoints.filter((el) => {
                            let curRadioNode = state.instances.radionodes[state.indexes.radionodes[el.id]];
                            if (curRadioNode) {
                                let ok = (curRadioNode.radioControllerId !== radioNode.radioControllerId);
                                if (!ok) {
                                    this.removeItemFeature(el, state);
                                }
                                return ok;
                            } else {
                                return true;
                            }
                        });
                        state.indexes.lightpoints = utils.doIndex(state.instances.lightpoints, FieldNames.ID);
                    }
                }
            }
        }

        // elimino il nodo dall'albero
        let t = new Tree(tree[mode].root);
        t.removeNode({ item });
        let root = t.getRoot();

        // elimino il marker corrispondente
        // if (mode!==NavModes.ZONES || item.type!==ItemTypes.ZONE)
        //   this.removeMarker(state, item);

        this.removeItemFeature(item, state);

        tree[mode].hash = utils.cyrb53(JSON.stringify(root));
        tree[mode].root = root;

        return {
            tree,
            instances: state.instances,
            indexes: state.indexes,
            relations: state.relations,
            infowindow: null
        };
    }

    /**
     * Aggiunge un cabinet alle strutture dati core
     * @param {*} state
     * @param {*} item
     */
    addCabinetCoreData(state, item) {

        let { tree, mode } = state;

        item.type = ItemTypes.CABINET;
        //item.deviceType = DeviceTypes.ANDROS;

        // ho un cabinet da aggiungere alla root
        state.instances.cabinets.push(item);
        state.indexes.cabinets[item.id] = (state.instances.cabinets.length - 1);

        if (tree[mode].root === null) {
            tree[mode].root = [];
        }

        // aggiorno l'albero
        let t = new Tree(tree[mode].root);
        t.addNode({ item });
        tree[mode].root = t.getRoot();

        // aggiungo il marker corrispondente
        if (item.geometry && item.geometry.lat !== 0 && item.geometry.lng !== 0) {

            this.addItemFeature(item, state);

            // if (markers===null){
            //   markers = [];
            // }
            // markers.push({
            //   position: item.geometry,
            //   icon: mapSquare,
            //   id: '0.'+item.id,
            //   type: 0,
            //   item,
            //   isOpen: false
            // });

        }
    }

    /**
     * Aggiunge una zona alle strutture dati core
     * @param {*} state
     * @param {*} item
     */
    addZoneCoreData(state, item) {

        let { tree, mode } = state;
        item.type = ItemTypes.ZONE;
        item.deviceType = null;

        // ho una zona da aggiungere alla root
        state.instances.zones.push(item);
        if (item.id) {
            state.indexes.zones[item.id] = (state.instances.zones.length - 1);
        }

        if (tree[mode].root === null) {
            tree[mode].root = [];
        }

        // aggiorno l'albero
        let t = new Tree(tree[mode].root);
        t.addNode({ item });
        tree[mode].root = t.getRoot();
        tree[mode].hash = utils.cyrb53(JSON.stringify(tree[mode].root));
    }

    /**
     * Aggiunge un array di lightpoint alle strutture dati core
     * @param {*} state
     * @param {*} items
     */
    addLightPointCoreData(state, items) {

        const { tree, instances, indexes, mode } = state;

        // aggiungo il lightpoint alle istanze ed agli indici
        instances.lightpoints = instances.lightpoints.concat(items);
        indexes.lightpoints = utils.doIndex(instances.lightpoints, FieldNames.ID);

        // e all'albero
        let parentData = null;

        let cabinetId = items[0].cabinetId;

        let radiocontrollerId = items[0].radioControllerId;

        if (cabinetId > 0) {

            // i lightpoint vanno aggiunti ad un cabinet
            for (let item of items) {
                item.type = ItemTypes.LIGHTPOINT;
                item.deviceType = null;
            }

            let cabinet = instances.cabinets[indexes.cabinets[cabinetId]];
            parentData = {
                item: cabinet
            }

        }

        else if (radiocontrollerId > 0) {

            // i lightpoint vanno aggiunti ad un radiocontroller
            for (let item of items) {
                item.type = ItemTypes.LIGHTPOINT;
                item.deviceType = null; //DeviceTypes.RADIONODE;
            }

            let radioController = instances.radiocontrollers.find((elem) => {
                return elem.id === radiocontrollerId;
            });
            if (radioController) {
                let lightPointId = (radioController.lightPointId ? radioController.lightPointId : 0);
                let lightpoint = instances.lightpoints[indexes.lightpoints[lightPointId]];
                // cerco il nodo apaprtenente a questo radiocontroller
                parentData = {
                    item: lightpoint
                    // type: ItemTypes.RADIOCONTROLLER,
                    // id: lightPointId
                }
            }
        }

        // aggiungo i lightpoints all'albero dell'impianto
        let t = new Tree(tree[mode].root);
        items.map((item) => {
            // aggiungo il radionode al radiocontroller
            t.addNode({ item }, parentData);
            // e la feature corrispondente
            if (item.geometry && item.geometry.lat !== 0) {
                this.addItemFeature(item, state);
            }
            return false;
        });
        tree[mode].root = t.getRoot();

    }

    /**
     * Aggiunge un item alle strutture dati core.
     * @param {*} state
     * @param {*} type
     * @param {*} item può essere un oggetto singolo o un array di oggetti
     */
    addItemCoreData(state, type, item) {

        let { tree, mode } = state;

        if (item === null || item.length === 0) {
            return {};
        }

        switch (type) {
            case ItemTypes.CABINET:
                this.addCabinetCoreData(state, item);
                break;
            case ItemTypes.ZONE:
                this.addZoneCoreData(state, item);
                break;
            case ItemTypes.LIGHTPOINT:
                this.addLightPointCoreData(state, item);
                break;
            default: break;
        }

        tree[mode].hash = utils.cyrb53(JSON.stringify(tree[mode].root));

        return {
            tree,
            instances: state.instances,
            indexes: state.indexes
        };

    }

    /**
     * Crea la feature e la aggiunge alle features ed alla mappa
     * @param {*} item
     * @param {*} state
     */
    addItemFeature(item, state) {
        const { features, mode } = state;
        let feature = new google.maps.Data.Feature({
            id: item.id,
            geometry: item.geometry,
            properties: { item }
        });
        features[item.type][item.id] = feature;
        if (this.map) {
            let mapBounds = this.map.getBounds();
            let { zoom, scale } = this.getMapZoomAndScale();
            let onlyMacro = (zoom <= 16);
            this.addRemoveMapFeature(feature, mode, onlyMacro, mapBounds, scale);
        }
    }

    /**
     * Rimuove la feature dalle features e dalla mappa
     * @param {*} item
     * @param {*} state
     */
    removeItemFeature(item, state) {
        let { features } = state;
        let feature = item.id && features[item.type][item.id];
        if (feature) {
            if (this.map) {
                this.map.data.remove(feature);
            }
            // let pos = features.indexOf(feature);
            // if (pos !== -1) {
            //   features.splice(pos,1);
            // }
            delete (features[item.type][item.id]);
        }
    }

    /**
     * Rimpiazza un item
     * @param {*} precState
     * @param {*} precItem
     * @param {*} newItem
     */
    replaceItem(precState, precItem, newItem) {

        let { tree, mode, features } = precState;

        // aggiorno l'albero
        let t = new Tree(tree[mode].root);
        let node = t.search({ item: precItem });
        if (node) {
            node.data.item = newItem;
        }

        // riseleziono
        tree[mode].selectedTreeItem = newItem;

        // sincronizzo la feature
        if (this.map) {
            let feature = features[newItem.type][newItem.id];
            if (feature) {
                feature.setProperty("item", newItem);
                feature.setGeometry(newItem.geometry);
                this.renderMapFeatureByItem(newItem.type, newItem.id);
            }
        }
    }

    /**
     * Crea le nuove associazioni zona - punto luce.
     * @param {*} zone
     * @param {*} tree
     * @param {*} instances
     * @param {*} indexes
     */
    syncZones(zone, tree, instances, indexes) {
        // dissocio dalla zona i vecchi punti luce
        if (zone && tree.zones.root) {
            let t = new Tree(tree.zones.root);
            let node = t.search({ item: zone });
            if (node && node.children) {
                node.children.map((child) => {
                    child.data.item.zoneId = 0;
                    return false;
                });
            }
        }
        // ed associo quelli nuovi
        if (zone && zone.lightpoints) {
            zone.lightpoints.map((lp) => {
                let lightpoint = instances.lightpoints[indexes.lightpoints[lp.id]];
                lightpoint.zoneId = lp.zoneId;
                return false;
            });
            delete (zone.lightpoints);
        }

    }

    /**
     * Costruisce un nuovo stato in funzione di quello vecchio e delle nuove props.
     * @param {*} precState
     * @param {*} props
     */
    stateFromProps(precState, props) {

        let errors = [], coreData = null, newItem = null, deletedItem = null, nextSelected = null, newItems = null;

        let tree = precState.tree;
        let mode = precState.mode;

        // azioni sui cabinet
        if (props.cabinets) {

            if (props.cabinets.error) {
                this.setState({ errors: [props.cabinets.error] });
                return null;
            }

            if (props.cabinets.editEnd) {
                // è stato aggiunto un cabinet
                newItem = props.cabinets.instance;
                coreData = this.addItemCoreData(precState, ItemTypes.CABINET, newItem);
                // tree[mode].selectedTreeItem = newItem;
                tree[mode].scroll = true;
                this.setState({ tree, ...coreData });
                return null;
            }

            if (props.cabinets.editing) {
                return null;
            }

            if (props.cabinets.deleting) {
                return null;
            }

            if (props.cabinets.deletedId > 0) {
                // è stato eliminato un quadro
                deletedItem = precState.instances.cabinets[precState.indexes.cabinets[props.cabinets.deletedId]];
                if (deletedItem) {
                    nextSelected = this.getNextSelectedTreeItem(
                        { children: precState.tree[mode].root },
                        deletedItem
                    );
                    coreData = this.delItemCoreData(precState, deletedItem);
                    // tree[mode].selectedTreeItem = nextSelected;
                    tree[mode].scroll = (nextSelected !== null);
                    this.fetchNotLinked(Models.ANDROS_NOT_LINKED);
                    this.setState({ tree, ...coreData });
                }
                return null;
            }
        }

        // azioni sulle zone
        if (props.zones) {

            if (props.zones.error) {
                this.setState({ errors: [props.zones.error] });
                return null;
            }

            if (props.zones.editing) {
                return null;
            }

            if (props.zones.deleting) {
                return null;
            }

            if (props.zones.deletedId > 0) {
                // è stata eliminata una zona
                deletedItem = precState.instances.zones[precState.indexes.zones[props.zones.deletedId]];
                if (deletedItem) {
                    this.syncZones(deletedItem, tree, precState.instances, precState.indexes);
                    nextSelected = this.getNextSelectedTreeItem(
                        { children: precState.tree[mode].root },
                        deletedItem
                    );
                    coreData = this.delItemCoreData(precState, deletedItem);
                    // tree[mode].selectedTreeItem = nextSelected;
                    // tree[mode].centeredTreeItem = nextSelected;
                    tree[mode].scroll = (nextSelected !== null);
                    this.setState({ tree, ...coreData });
                }
                return null;
            }
        }

        // azioni sui punti luce
        if (props.lightpoints) {

            if (props.lightpoints.error) {
                this.setState({ errors: [props.lightpoints.error] });
                return null;
            }

            if (props.lightpoints.editEnd) {
                // sono stati aggiunti dei lightPoints
                newItems = props.lightpoints.instance;
                // se ho aggiunto un nodo radio, trovo il campo radioControllerId valorizzato
                coreData = this.addItemCoreData(precState, ItemTypes.LIGHTPOINT, newItems);
                // tree[mode].selectedTreeItem = newItems[0];
                // tree[mode].scroll = true;
                this.setState({ tree, ...coreData });
                return null;
            }

            if (props.lightpoints.editing) {
                return null;
            }

            if (props.lightpoints.deleting) {
                return null;
            }

            if (props.lightpoints.deletedId > 0) {
                // è stato eliminato un lightPoint
                deletedItem = precState.instances.lightpoints[precState.indexes.lightpoints[props.lightpoints.deletedId]];
                if (deletedItem) {
                    nextSelected = this.getNextSelectedTreeItem(
                        { children: precState.tree[precState.mode].root },
                        deletedItem
                    );
                    coreData = this.delItemCoreData(precState, deletedItem);
                    // tree[mode].selectedTreeItem = nextSelected;
                    // tree[mode].scroll = (nextSelected !== null);
                    // rileggo i syras non abbinati
                    this.fetchNotLinked(Models.SYRAS_NOT_LINKED);
                    this.setState({ tree, ...coreData });
                }
                return null;
            }
        }

        // azione multiedit (scatta quando si preme salva nelle riquadro delle proprietà dell'item)
        if (props.multiEditDidEnd) {

            // è stato editato un cabinet od un punto luce od una zona
            let instances = {
                androsNotLinked: precState.instances.androsNotLinked,
                syrasNotLinked: precState.instances.syrasNotLinked
            };

            let changedZone = null;
            let rebuildTreeZones = false;

            const callbackInstanceLoop = (instances, model) => {
                return instances.map((precItem) => {
                    let newItem = props[model].instance;
                    if (precItem.id === newItem.id || tree[mode].selectedTreeItem === precItem) {
                        // tipizzo il newItem
                        switch (model) {
                            case Models.CABINETS:
                                newItem.type = ItemTypes.CABINET;
                                this.syncRelations(precItem, newItem);
                                this.replaceItem(precState, precItem, newItem);
                                break;
                            case Models.LIGHTPOINTS:
                                newItem.type = precItem.type;
                                this.syncRelations(precItem, newItem);
                                rebuildTreeZones = true;
                                this.replaceItem(precState, precItem, newItem);
                                break;
                            case Models.ZONES:
                                newItem.type = ItemTypes.ZONE;
                                newItem.deviceType = null;
                                changedZone = newItem;
                                this.replaceItem(precState, precItem, newItem);
                                break;
                            case Models.RADIOCONTROLLERS:
                                // cerco il lightpoint associato
                                let lightpoint = precState.instances.lightpoints[precState.indexes.lightpoints[newItem.lightPointId]];
                                lightpoint.radioController = newItem;
                                break;
                            default: break;
                        }
                        return newItem;
                    } else {
                        return precItem;
                    }
                });
            }

            // il ws restituisce l'oggetto editato
            for (const model of NavigatorComponent.models) {
                if (props[model]) {
                    if (props[model].error) {
                        // estraggo l'errore
                        errors.push({ model, error: props[model].error });
                    }
                    if (props[model].instance) {
                        // rimpiazzo l'oggetto vecchio con quello editato
                        instances[model] = callbackInstanceLoop(
                            precState.instances[model],
                            model
                        );

                        if (changedZone) {
                            // ricreo gli indici
                            precState.indexes.zones = utils.doIndex(instances.zones, FieldNames.ID);
                        }

                    } // if (props[model].instance)...
                    else {
                        instances[model] = precState.instances[model];
                    }
                }
                else {
                    instances[model] = precState.instances[model];
                }
            }

            if (changedZone || rebuildTreeZones) {

                if (changedZone) {
                    this.syncZones(changedZone, tree, precState.instances, precState.indexes);
                }
                tree.zones.root = this.buildRootZones(instances);
                tree.zones.hash = utils.cyrb53(JSON.stringify(tree.zones.root));
                tree.zones.scroll = true;
            }

            // tree[mode].selectedTreeItem = selectedTreeItem;
            tree[mode].editingTreeItem = null;

            return {
                ...precState,
                tree,
                instances,
                errors
            }
        }

        // sessione scaduta?
        if (props.projects && props.projects.error) {
            const err = props.projects.error;
            errors.push({ module: Models.PROJECTS, error: err });
            if (err.error && err.error.statusCode === 401) {
                history.push('/pages/login-page');
                return;
            }
        }

        // errori loopback?
        for (const model of NavigatorComponent.models) {
            if (props[model] && props[model].error) {
                errors.push({ model, error: props[model].error });
            }
        }

        coreData = this.buildCoreDataFromProps(props);

        return {
            errors,
            projects: props.projects,
            loggedinUser: props.loggedinUser,
            ...coreData
        };

    }

    /**
     * Legge i dispositivi non abbinati
     * @param {*} model  [ Models.SYRAS_NOT_LINKED | Models.ANDROS_NOT_LINKED ]
     */
    async fetchNotLinked(model) {
        try {

            const user = User.current();
            if (user.userRoleId === roleConstants.SUPERUSERUMPI) {
                let selectedProject = utils.getSelectedProject();
                if (selectedProject) {
                    let result = await loopbackService.list(ModelPaths.ARCHIVE, model, { projectId: selectedProject.id });
                    if (result && Array.isArray(result)) {
                        const { instances, indexes } = this.state;
                        instances[model] = result;
                        indexes[model] = utils.doIndex(instances[model], 'sn');
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }

    }

    /**
     *
     * @param {*} prevItem
     * @param {*} newItem
     */
    replaceFeatureItem(prevItem, newItem) {
        const { features } = this.state;
        let feature = features[prevItem.type][prevItem.id];
        if (feature) {
            this.map.data.remove(feature);
            let newFeature = new google.maps.Data.Feature({
                id: newItem.id,
                geometry: newItem.geometry,
                properties: { item: newItem }
            });
            features[newItem.type][newItem.id] = newFeature;
            this.map.data.add(newFeature);
            this.renderMapFeatureByItem(newItem.type, newItem.id);
        }
    }

    /**
     *
     * @param {*} prevItem
     * @param {*} newItem
     */
    replaceTreeItem(prevItem, newItem) {
        let { tree, mode } = this.state;
        let root = tree[mode].root;
        // aggiorno l'albero
        let t = new Tree(root);
        let node = t.search({ item: prevItem });
        if (node && node.data) {
            node.data.item = newItem;
        }
        tree[mode].hash = utils.cyrb53(JSON.stringify(root));
        return t.getRoot();
    }

    /**
     * Aggiorna la relazione item - device
     * @param {*} prevItem
     * @param {*} newItem
     */
    async syncRelations(prevItem, newItem) {

        try {

            let device = null, devices = null;
            let { instances, indexes, tree, mode } = this.state;
            //let root = tree[mode].root;

            newItem.deviceType = null;

            // leggo il device associato
            if (prevItem.type === ItemTypes.CABINET) {
                devices = await loopbackService.list(ModelPaths.ARCHIVE, Models.DEVICES, { cabinetId: prevItem.id });
            }
            else if (prevItem.type === ItemTypes.LIGHTPOINT) {
                devices = await loopbackService.list(ModelPaths.ARCHIVE, Models.DEVICES, { lightPointId: prevItem.id });
            }

            if (devices && devices.length > 0) {

                if (prevItem.type === ItemTypes.CABINET) {
                    // cerco l'andros
                    for (const curdev of devices) {
                        if (curdev.deviceTypeId === DeviceTypes.ANDROS) {
                            device = curdev;
                            break;
                        }
                    }
                }
                else if (prevItem.type === ItemTypes.LIGHTPOINT) {
                    device = devices[0];
                }

                if (device.deviceTypeId === DeviceTypes.RADIOCONTROLLER) {

                    // rileggo anche l'istanza del radiocontroller associato
                    let radioControllers = await loopbackService.list(ModelPaths.PLANT, Models.RADIOCONTROLLERS, { lightPointId: newItem.id });

                    if (radioControllers && radioControllers.length > 0) {

                        let radioController = radioControllers[0];

                        if (!instances.radiocontrollers) {
                            instances.radiocontrollers = [];
                        }

                        let prevRadiocontroller = instances.radiocontrollers[indexes.radiocontrollers[newItem.id]];
                        if (!prevRadiocontroller) {
                            // se non c'è l'aggiungo
                            instances.radiocontrollers.push(radioController);
                            indexes.radiocontrollers = utils.doIndex(instances.radiocontrollers, FieldNames.LIGHTPOINT_ID);
                        }
                        else {
                            // altrimenti l'aggiorno
                            instances.radiocontrollers[indexes.radiocontrollers[newItem.id]] = radioController;
                        }

                        newItem.radioController = radioController;

                    }
                }

            }

            if (device !== null) {

                if (prevItem.type === ItemTypes.CABINET) {
                    // cabinet
                    newItem.device = [device];
                    newItem.deviceType = device.deviceTypeId;
                }

                else if (prevItem.type === ItemTypes.LIGHTPOINT) {

                    // lightpoint
                    if (!newItem.device || (newItem.device.sn !== device.sn) || (newItem.deviceType !== device.deviceTypeId)) {

                        // se il nuovo item non ha il device assegnato o è cambiato il sn
                        newItem.device = device;
                        newItem.deviceType = device.deviceTypeId;

                        // aggiorno l'albero
                        tree[mode].root = this.replaceTreeItem(prevItem, newItem);

                        // aggiorno la selezione corrente
                        tree[mode].selectedTreeItem = newItem;
                        tree[mode].scroll = true;

                        // aggiorno la feature
                        this.replaceFeatureItem(prevItem, newItem);

                        this.setState({ tree });

                        return;
                    }
                }

            } else { // if (device !== null) {

                // se non è stato associato il device all'item, significa che il sn è stato rimosso
                if (newItem.device) {
                    delete (newItem.device);
                }

                if (newItem.type === ItemTypes.LIGHTPOINT) {

                    // aggiorno l'albero
                    tree[mode].root = this.replaceTreeItem(prevItem, newItem);

                    // aggiorno la selezione corrente
                    tree[mode].selectedTreeItem = newItem;
                    tree[mode].scroll = true;

                    // aggiorno la feature
                    this.replaceFeatureItem(prevItem, newItem);

                    this.setState({ tree });
                }
            }

        } catch (error) {
            console.error(error);
        }

    }

    /**
     *
     * @param {*} type
     * @param {*} item
     */
    handleTreeNodeClick(item) {

        if (this.checkEditing(item)) {
            return;
        }

        // const treeItem = {
        //     type,
        //     item
        // }

        let { tree, mode, features } = this.state;

        let feature = features[item.type][item.id];
        if (feature) {
            this.showInfoWindow(feature);
        }

        tree[mode].centeredTreeItem = item;
        tree[mode].selectedTreeItem = item;
        tree[mode].scroll = false;

        this.setState({
            tree
            // markers
        });
    }

    /**
     *
     * @param {*} marker
     * @param {*} lat
     * @param {*} lng
     */
    handleMarkerDragEnd(marker, lat, lng) {
        let { instances, indexes } = this.state;
        if (marker.item.type === ItemTypes.CABINET) {
            let cabinet = instances.cabinets[indexes.cabinets[marker.item.id]];
            cabinet.geometry = { lat, lng };
        }
        else {
            let lightpoint = instances.lightpoints[indexes.lightpoints[marker.item.id]];
            lightpoint.geometry = { lat, lng };
        }

        this.setState({ instances });
    }

    /**
     * Restituisce true se è attivo l'editing di un item.
     * @param {*} item
     */
    checkEditing(item) {

        const { t } = this.props;

        const { tree, mode } = this.state;

        let editingTreeItem = tree[mode].editingTreeItem;

        if (editingTreeItem && editingTreeItem.type === item.type && editingTreeItem.id === item.id) {
            // click nuovamente sullo stesso item in edit --> non faccio nulla
            return true;
        }

        if (editingTreeItem) {

            // qua ci vorrebbe un controllo, se ci sono dati modificati, chiedere se si vuole salvare...
            let { instances, indexes } = this.state;
            let originalItem = tree[mode].originalItem;
            let current = null;
            if (editingTreeItem.type === ItemTypes.CABINET) {
                current = instances.cabinets[indexes.cabinets[editingTreeItem.id]];

            }
            else if (editingTreeItem.type === 1) {
                current = instances.lightpoints[indexes.lightpoints[editingTreeItem.id]];

            }

            let a = JSON.stringify(current);
            let b = JSON.stringify(originalItem);

            if (a === b) {
                this.handleCancelItemClick(editingTreeItem);
                return false;
            }
            else {
                this.setState({
                    alert: (
                        <Modal isOpen={true} className="modal-umpi-container">
                            <ModalHeader toggle={() => {
                                this.setState({
                                    alert: null
                                });
                            }}>
                                {t("Annulla modifiche")}
                            </ModalHeader>
                            <ModalBody>
                                <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                    <i className='fa fa-exclamation-circle' />
                                </div>
                                <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                    {t("Sei sicuro?")}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }} color="default" className="umpi-modal-btn-cancel">
                                    {t("Annulla")}
                                </Button>
                                &nbsp;&nbsp;
                                <Button onClick={async () => {
                                    this.handleCancelItemClick(editingTreeItem);
                                    this.handleTreeNodeClick(item);
                                    this.setState({
                                        alert: null
                                    });
                                }} color="success" className="umpi-modal-btn-save">
                                    {t("Confirm")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                });
                return true;
            }
        }

        return false;

    }

    /**
     *
     * @param {*} marker
     */
    handleMarkerClick(event) {
        let { tree, mode } = this.state;
        let editingTreeItem = tree[mode].editingTreeItem;

        if (editingTreeItem) {
            return;
        }

        this.showInfoWindow(event.feature);

        let item = event.feature.getProperty("item");
        tree[mode].selectedTreeItem = item;
        tree[mode].scroll = true;

        let reversePath = (new Tree(tree[mode].root)).reversePath({
            item: tree[mode].selectedTreeItem
        })

        this.setState({
            tree,
            treeIndexPath: reversePath
        });
    }

    /**
     *
     * @param {*} target
     */
    handleMarkerClose() {
        // target.isOpen = false;
        // // let { tree } = this.state;
        // let markers = this.state.markers;
        // this.setState({
        //   markers
        // });

        this.setState({
            infowindow: null
        });
    }

    /**
     *
     */
    handleMarkerCloseAll() {
        let { markers } = this.state;
        this.setState({
            markers: markers.map((marker) => {
                return {
                    ...marker,
                    isOpen: false
                }
            })
        });
    }

    /**
     *
     * @param {*} searchTerm
     */
    handleChangeFilter(searchTerm) {

        let coreData = this.buildCoreDataFromProps(this.props, searchTerm);
        if (this.treeTable) {
            this.treeTable.expandAll(2);
        }

        let { tree, mode } = coreData;
        tree[mode].searchTerm = searchTerm;
        this.setState({
            ...coreData
        });
    }

    /**
     *
     */
    getSelectedProject() {
        // Helper findCenter: middle calculator
        const getMiddle = (prop, points) => {
            // Check for empty points
            if (points.length <= 0) {
                // Always ZERO
                return 0;
            }

            // Prepare data
            let values = points.map(m => m[prop]);
            let min = Math.min(...values);
            let max = Math.max(...values);

            // Check for lng and normalize max/min
            if (prop === 'lng' && (max - min > 180)) {
                values = values.map(val => val < max - 180 ? val + 360 : val);
                min = Math.min(...values);
                max = Math.max(...values);
            }

            // Calculate center lng/lat
            let result = (min + max) / 2;

            // Normalize result for lng
            if (prop === 'lng' && result > 180) {
                result -= 360
            }

            // Send back
            return result;
        };

        // Helper findCenter
        const findCenter = (points = []) => {
            // Create geometry object
            return {
                lat: getMiddle('lat', points),
                lng: getMiddle('lng', points)
            }
        };

        // Prepare and resolve geometry
        let allMapCoordinates   = [];
        let arrayProjects       = [];
        let selectedProjectData = null;
        const storageProjects   = localStorage.getItem('projects');
        const selectedProjectId = localStorage.getItem('selectedProjectId');

        try {
            // Try to parse string into json object
            let tmpArr = JSON.parse(storageProjects);
            arrayProjects = Array.isArray(tmpArr) ? tmpArr : [];
        }
        catch (eAp) {
            // Reset on error
            arrayProjects = [];
        }

        // Search project geometry
        for (const prj of arrayProjects) {
            // Check project data
            if (prj.geometry &&
                prj.geometry.lat !== null &&
                prj.geometry.lng !== null) {
                // Check if selected
                if (prj.id === Number(selectedProjectId)) {
                    // Save data project
                    selectedProjectData = prj;
                }

                // Append coordy for center calculation
                allMapCoordinates.push(prj.geometry);
            }
        }

        // Otherwise calculate center of all projects on map
        if (selectedProjectData === null) {
            // Calculate center and create fake project
            selectedProjectData = {
                id:       -1,
                geometry: findCenter(allMapCoordinates),
                zoom:     5
            };
        }

        // Send back to map
        return selectedProjectData;
    }

    /**
     *
     * @param {*} selectedTreeItem
     * @param {*} item
     */
    hasParentSelected(selectedTreeItem, item) {
        if (!selectedTreeItem) {
            return false;
        }

        let { tree, mode } = this.state;
        let root = tree[mode].root;
        let parent = (new Tree(root)).getParent({ item });
        if (parent && parent.data) {
            if (selectedTreeItem === parent.data.item) {
                return true;
            }
            else {
                return this.hasParentSelected(selectedTreeItem, parent.data.item);
            }
        }
        return false;
    }

    /**
     * Restituisce il livello di trasparenza della feature.
     * @param {*} selectedTreeItem
     * @param {*} item
     */
    getFeatureOpacity(selectedTreeItem, item) {
        const { mode } = this.state;
        if (mode === NavModes.PLANT) {
            if (selectedTreeItem && (selectedTreeItem.type === ItemTypes.CABINET || selectedTreeItem.deviceType === DeviceTypes.RADIOCONTROLLER)) {
                if (selectedTreeItem === item) {
                    return 1.0;
                } else {
                    // se è selezionato un cabinet od un radio controller -> evidenzio i nodi dipendenti
                    return (this.hasParentSelected(selectedTreeItem, item) ? 1.0 : 0.5);
                }
            }
            return 1.0;
        }
        else {
            return (selectedTreeItem && selectedTreeItem.zoneId === item.id ? 1.0 : 0.5);
        }
    }

    /**
     *
     */
    getMapZoomAndScale() {
        if (this.map) {
            let zoom = this.map.getZoom();

            if (!zoom) {
                const user = User.current();
                const selectedProject = this.getSelectedProject();
                if (selectedProject) {
                    let { z } = utils.fromSession(user.id, selectedProject.id);
                    if (z) {
                        zoom = z;
                    }
                    else {
                        zoom = selectedProject && selectedProject.zoom;
                    }
                }
                if (!zoom) {
                    zoom = 14;
                }
            }

            let scale = 0.5;

            /*if (zoom <= 15) {
                scale = 0.1;
            }
            else if (zoom <= 16) {
                scale = 0.2;
            }
            else if (zoom <= 17) {
                scale = 0.3;
            }
            else if (zoom <= 18) {
                scale = 0.4;
            }
            else if (zoom <= 19) {
                scale = 0.5;
            }
            else {
                scale = 1;
            }*/

            return { zoom, scale };
        }
        return { zoom: 0, scale: 0 }
    }

    /**
     *
     * @param {*} item
     * @param {*} scale
     */
    getStyleForItem(item, scale) {

        if (!this.map) {
            return;
        }

        let { tree, mode, status } = this.state;
        let isEditing = tree[mode].editingTreeItem === item;
        let isSelected = tree[mode].selectedTreeItem === item;
        let icon = null;

        if (item.type === ItemTypes.CABINET) {
            icon = {
                ...mapSquare,
                scale: scale / 1.5,
                fillColor: fillForSquare(isSelected, isEditing),
                strokeColor: strokeForSquare(isSelected),
                strokeWeight: 1
            };
        }
        else {

            let periferal = status && status.lightpoints && status.lightpoints[item.id];
            let statusReported = periferal && periferal.statusReported;

            if (item.deviceType === DeviceTypes.RADIOCONTROLLER) {
                // radiocontroller (ha un lightpoint collegato)
                let fillColor = fillForPin(statusReported, isSelected, isEditing);
                let strokeColor = strokeForPin(statusReported, isSelected);
                icon = {
                    ...mapDoubleCircle2,
                    scale: scale,
                    fillColor,
                    strokeColor,
                    strokeWeight: 1
                };
            }
            else {
                // lightPoint o radioNode
                icon = {
                    ...mapPin,
                    scale: scale,
                    fillColor: fillForPin(statusReported, isSelected, isEditing),
                    strokeColor: strokeForPin(statusReported, isSelected),
                    strokeWeight: 1
                };
            }
        }

        let opacity = this.getFeatureOpacity(tree[mode].selectedTreeItem, item);

        let visible = true; //!isEditing;

        return { icon, opacity, visible, draggable: isEditing }
    }

    /**
     *
     * @param {*} feature
     */
    overrideStyleForFeature(feature, scale) {

        let item = feature.getProperty("item");
        const style = this.getStyleForItem(item, scale);
        this.map.data.overrideStyle(feature, style);

    }

    /**
     * Applica lo stile alla feature a partire dall'item
     * @param {*} feature
     */
    renderMapFeatureByItem(type, id) {
        const { features } = this.state;
        let feature = features[type][id];
        if (feature && this.map && this.map.data.contains(feature)) {
            let { scale } = this.getMapZoomAndScale();
            this.overrideStyleForFeature(feature, scale);
        }
    }

    /**
     * Disegna le features sulla mappa.
     */
    renderMapFeatures() {
        let { features, mode } = this.state;
        let mapBounds = this.map.getBounds();
        let { zoom, scale } = this.getMapZoomAndScale();
        let onlyMacro = (zoom <= 16);

        if (features) {
            if (mode === NavModes.PLANT) {
                // aggiungo i cabinet
                Object.entries(features[ItemTypes.CABINET]).map(([key, feature]) => {
                    this.addRemoveMapFeature(feature, mode, onlyMacro, mapBounds, scale);
                    return false;
                });
                // rimuovo le zone
                Object.entries(features[ItemTypes.ZONE]).map(([key, feature]) => {
                    this.map.data.remove(feature);
                    return false;
                });
            }
            // lightpoint
            Object.entries(features[ItemTypes.LIGHTPOINT]).map(([key, feature]) => {
                this.addRemoveMapFeature(feature, mode, onlyMacro, mapBounds, scale);
                return false;
            });
        }
    }

    /**
     * Elimina tutte le features dalla mappa
     */
    cleanMap() {
        let { features } = this.state;
        if (this.map && features) {
            // rimuovo i cabinet
            Object.entries(features[ItemTypes.CABINET]).map(([key, feature]) => {
                this.map.data.remove(feature);
                return false;
            });
            // rimuovo le zone
            Object.entries(features[ItemTypes.ZONE]).map(([key, feature]) => {
                this.map.data.remove(feature);
                return false;
            });
            Object.entries(features[ItemTypes.LIGHTPOINT]).map(([key, feature]) => {
                this.map.data.remove(feature);
                return false;
            });
        }
    }

    /**
     * Aggiunge la feature se è nel riquadro visibile, c'è uno zoom adeguato
     * @param {*} item
     * @param {*} onlyMacre
     * @param {*} mapBounds
     */
    addRemoveMapFeature(feature, mode, onlyMacro, mapBounds, scale) {
        let item = feature.getProperty("item");
        if (
            (onlyMacro && mode === NavModes.PLANT && item.type !== ItemTypes.CABINET) ||
            (item.type !== ItemTypes.ZONE && item.geometry && mapBounds && !mapBounds.contains(item.geometry))
        ) {
            this.map.data.remove(feature);
            return false;
        }

        // l'aggiungo se non c'era
        if (!this.map.data.contains(feature)) {
            this.map.data.add(feature);
        }

        // applico lo stile
        this.overrideStyleForFeature(feature, scale);
        return true;
    }

    /**
     * Restituisce i marker che devono comparire nel riquadro corrente della mappa.
     * Gestione zoom:
     * da 1 a 13 --> solo cabinet, simboli small;
     * da 13 a 15 --> simboli small
     * da 16 a 18 --> simboli medium
     * da 19 a 21 --> simboli large
     *
     * @param {*} zoom
     */
    // getMarkers(zoom, mapBounds){
    //   let { tree, mode, status, markers, features } = this.state;
    //   let selectedTreeItem = tree[mode].selectedTreeItem;
    //   let editingTreeItem = tree[mode].editingTreeItem;
    //   let onlyCabinets = (zoom<=13);
    //   let scale = this.getScale(zoom);
    //   let result = [];
    //   for (let marker of markers){
    //     // escludo i Cabinet quando sono nella visualizzazione a zone
    //     if (mode===NavModes.ZONES && marker.type===ItemTypes.CABINET ){
    //         continue;
    //     }
    //     // escludo gli elementi fuori dalla mappa
    //     if (mapBounds && marker.item && marker.item.geometry){
    //       // let position = new google.maps.LatLng(marker.item.geometry.lat, marker.item.geometry.lng);
    //       let position = { lat: marker.item.geometry.lat, lng: marker.item.geometry.lng };
    //       if (!mapBounds.contains(position)){
    //         continue;
    //       }
    //     }
    //     let statusReported = null;
    //     let isEditing = ( editingTreeItem && editingTreeItem.type==marker.item.type && editingTreeItem.id === marker.item.id ? true : false) ;
    //     let isSelected = selectedTreeItem && selectedTreeItem.item === marker.item;
    //     marker.isEditing = isEditing;
    //     marker.position = marker.item.geometry;
    //     if (marker.type===ItemTypes.CABINET){
    //           marker.icon = {
    //             ...mapSquare,
    //             scale: scale/1.5,
    //             fillColor: fillForSquare(isSelected,isEditing),
    //             strokeColor: strokeForSquare(isSelected),
    //             strokeWeight: 1
    //           };
    //           marker.opacity = this.getMarkerOpacity(selectedTreeItem,marker.type,marker.item.id);
    //           marker.map = this.map;
    //           result.push(marker);
    //     }
    //     else if (!onlyCabinets){
    //       if ( !onlyCabinets && marker.type!==0 && status && status.lightpoints && status.lightpoints[marker.item.id]) {
    //         let periferal = status.lightpoints[marker.item.id];
    //         statusReported = periferal && periferal.statusReported;
    //       }
    //       if (marker.type===ItemTypes.RADIOCONTROLLER ){
    //           let fillColor = fillForPin(statusReported,isSelected,isEditing );
    //           let strokeColor = strokeForPin(statusReported,isSelected);
    //           marker['icon'] = {
    //             ...mapDoubleCircle2,
    //             scale: scale,
    //             fillColor,
    //             strokeColor,
    //             strokeWeight: 1
    //         };
    //       }
    //       else {
    //         marker['icon'] = {
    //             ...mapPin,
    //             scale: scale,
    //             fillColor: fillForPin(statusReported,isSelected,isEditing ),
    //             strokeColor: strokeForPin(statusReported,isSelected),
    //             strokeWeight: 1
    //           };
    //       }
    //       marker.opacity = this.getMarkerOpacity(selectedTreeItem,marker.type,marker.item.id);
    //       marker.map = this.map;
    //       result.push(marker);
    //     }
    //   }
    //   return result;
    // }

    treeFocusNode(indexPath, pathPayload = null) {
        let { tree, mode, features, treeIndexPath } = this.state;
        let deepNumbers = Object.keys(treeIndexPath);
        let root = tree[mode].root;
        let node = null;
        let newPath = {
            ...treeIndexPath,
            [deepNumbers.length]: {
                index: indexPath,
                payload: pathPayload
            }
        };

        new Tree(root).traversePath(
            newPath,
            (resultNode) => {
                node = resultNode;
            }
        );

        let item = this.itemPropertiesRemux(node);

        if (item && !this.checkEditing(item)) {
            let feature = features[item.type][item.id];

            if (feature) {
                this.showInfoWindow(feature);
            }

            tree[mode].focusedTreeItem  = (item !== null) ? "id-" + item.type + "-" + item.id : null;
        }
    }

    treeGoDeep(indexPath, pathPayload = null) {
        let { tree, mode, features, treeIndexPath } = this.state;
        let deepNumbers = Object.keys(treeIndexPath);
        let root = tree[mode].root;
        let node = null;
        let newPath = {
            ...treeIndexPath,
            [deepNumbers.length]: {
                index: indexPath,
                payload: pathPayload
            }
        };

        new Tree(root).traversePath(
            newPath,
            (resultNode) => {
                node = resultNode;
            }
        );

        let item = this.itemPropertiesRemux(node);

        if (item && !this.checkEditing(item)) {
            let feature = features[item.type][item.id];

            if (feature) {
                this.showInfoWindow(feature);
            }

            tree[mode].focusedTreeItem  = (item !== null) ? "id-" + item.type + "-" + item.id : null;
            tree[mode].centeredTreeItem = item;
            tree[mode].selectedTreeItem = item;
            tree[mode].scroll = false;
        }

        this.setState({
            tree: tree,
            treeIndexPath: newPath
        });
    }

    treeGoBack() {
        let { tree, mode, features, treeIndexPath } = this.state;
        let deepNumbers = Object.keys(treeIndexPath);
        let root = tree[mode].root;
        let node = null;
        let item = null;

        if (deepNumbers.length > 0) {
            delete treeIndexPath[deepNumbers.pop()];

            if (deepNumbers.length > 0) {
                new Tree(root).traversePath(
                    treeIndexPath,
                    (resultNode) => {
                        node = resultNode;
                    }
                );

                item = this.itemPropertiesRemux(node);

                if (item && !this.checkEditing(item)) {
                    let feature = features[item.type][item.id];

                    if (feature) {
                        this.showInfoWindow(feature);
                    }
                }
            }

            tree[mode].focusedTreeItem  = null;
            tree[mode].centeredTreeItem = item;
            tree[mode].selectedTreeItem = item;
            tree[mode].scroll = false;

            this.setState({
                tree: tree,
                treeIndexPath: treeIndexPath,
                ...(deepNumbers.length <= 0 ? {
                    infowindow: null
                } : {})
            });
        }
    }

    itemPropertiesRemux(node) {
        if (node &&
            node.data &&
            node.data.item
        ) {
            let item = node.data.item;

            item["isleaf"] = true;
            item["children"] = [];

            if (Array.isArray(node.children)) {
                item["isleaf"] = false;
                item["children"] = node.children;
            }

            return item;
        }

        return null;
    }

    renderItemIcon = (item, statusType = null) => {
        let { status } = this.state;

        let statusReported = null;
        let periferal = null;

        if (status && status.lightpoints && status.lightpoints[item.id]) {
            periferal = status.lightpoints[item.id];
            statusReported = periferal && periferal.statusReported;
        }

        if ([
            "zone",
            "cabin",
            "light",
            "zone-lights",
            "zone-radio-lights"
        ].includes(statusType)) {
            switch (statusType) {
                case "zone": return (
                    <div
                        className="umpi-tree-item-status square"
                        style={{ backgroundColor: (
                            item.configurationTimerProfileUpToDate ?
                            fillForPin(statusReported, false, false, (item.color ? item.color : "#000")) :
                            "orange"
                        ) }}
                    ></div>
                );

                case "cabin": return (
                    <div className="umpi-tree-item-status no-border cabin">
                        <svg width="14" height="18" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill="#114D8C"
                                d="M12.5826 19.6633C16.8537 18.5248 20 14.6298 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 14.6298 3.14633 18.5248 7.41744 19.6633L10 23.4483L12.5826 19.6633Z"
                            />
                        </svg>
                    </div>
                );

                case "light": return (
                    <div
                        className="umpi-tree-item-status circle"
                        style={{ backgroundColor: fillForPin(statusReported, false, false) }}
                    ></div>
                );

                case "zone-lights": return (
                    <div>
                        <Bulb
                            height={20}
                            width={18}
                            fill={fillForBulb(statusReported, false)}
                            stroke={strokeForBulb(statusReported, false)}
                            strokeWidth={1}
                        />
                    </div>
                );

                case "zone-radio-lights": return (
                    <div>
                        <RadioTower
                            height={20}
                            width={18}
                            style={{marginRight: "5px"}}
                            fill={fillForRadio(periferal, false)}
                            stroke={fillForRadio(periferal, false)}
                            strokeWidth={1}
                        />
                        <Bulb
                            height={20}
                            width={18}
                            fill={fillForBulb(statusReported, false)}
                            stroke={strokeForBulb(statusReported, false)}
                            strokeWidth={1}
                        />
                    </div>
                );

                default: return null;
            }
        }
        else return (
            <Fragment>
                {item.type === ItemTypes.LIGHTPOINT &&
                    item.deviceType === DeviceTypes.RADIOCONTROLLER ?
                    <RadioTower
                        style={{ marginRight: "3px" }}
                        height={20}
                        width={18}
                        fill={fillForRadio(periferal, false)}
                        stroke={fillForRadio(periferal, false)}
                        strokeWidth={1}
                    />
                    : null}

                {item.type === ItemTypes.ZONE ?
                    <DrawPolygon
                        style={{ marginRight: "3px" }}
                        height={20}
                        width={18}
                        fill={item.configurationTimerProfileUpToDate ? fillForPin(statusReported, false, false, (item.color ? item.color : "#000")) : "orange"}
                        stroke={item.configurationTimerProfileUpToDate ? strokeForPin(statusReported, false, (item.color ? item.color : "#000")) : "orange"}
                        strokeWidth={1}
                    />
                    : null}

                {item.type === ItemTypes.LIGHTPOINT && statusReported ?
                    <Bulb
                        height={20}
                        width={18}
                        fill={fillForBulb(statusReported, false)}
                        stroke={strokeForBulb(statusReported, false)}
                        strokeWidth={1}
                    />
                    : null}

                {(item.type === ItemTypes.LIGHTPOINT && statusReported && statusReported.allarm1 !== 0) ?
                    <Bell
                        height={20}
                        width={18}
                        fill={fillForAlarm(statusReported, statusReported.allarm1, false)}
                        stroke={strokeForAlarm(statusReported, statusReported.allarm1, false)}
                        strokeWidth={1}
                    />
                    : null}

                {(item.type === ItemTypes.LIGHTPOINT && statusReported && statusReported.allarm2 !== 0) ?
                    <Bell
                        height={20}
                        width={18}
                        fill={fillForAlarm(statusReported, statusReported.allarm2, false)}
                        stroke={strokeForAlarm(statusReported, statusReported.allarm2, false)}
                        strokeWidth={1}
                    />
                    : null}

                {item.type === ItemTypes.LIGHTPOINT ?
                    <IconPin
                        width={18}
                        height={20}
                        stroke={strokeForPin(statusReported, false)}
                        strokeWidth={2}
                        fill={fillForPin(statusReported, false)}
                    />
                    : null}

                {item.type === ItemTypes.CABINET ?
                    <Bulb
                        height={20}
                        width={18}
                        fill={fillForBulb(statusReported, false)}
                        stroke={strokeForBulb(statusReported, false)}
                        strokeWidth={1}
                    />
                    : null}
            </Fragment>
        )
    }

    renderSide() {
        let user = User.current();
        let { t } = this.props;
        let { instances, indexes, relations, tree, mode, treeIndexPath } = this.state;

        let root             = tree[mode].root;
        let focusedTreeItem  = tree[mode].focusedTreeItem;
        let selectedTreeItem = tree[mode].selectedTreeItem;
        let editingTreeItem  = tree[mode].editingTreeItem;
        let deepNumbers      = Object.keys(treeIndexPath);

        let isShowSettings = (
            user.userRoleId === roleConstants.SUPERUSERUMPI ||
            user.userRoleId === roleConstants.ADMIN
        );

        let treeData = (
            deepNumbers.length <= 0
        ) ? (
            Array.isArray(root) ? (
                root
                    .map(this.itemPropertiesRemux)
                    .filter(x => x)
            ) : []
        ) : (
            selectedTreeItem ? [
                selectedTreeItem
            ] : []
        );

        return (
            <div className="side-menu">
                <div className="side-menu-wrapper">
                    {isShowSettings && deepNumbers.length <= 0 &&
                        <Button
                            color="primary"
                            size="sm"
                            className="custom-no-margin umpi-tree-btn-add-new"
                            onClick={() => {
                                if (mode === NavModes.PLANT) {
                                    this.toggleCabinetEditModal(true);
                                }
                                else {
                                    this.toggleZoneEditModal(true);
                                }
                            }}>
                            <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                            {mode === NavModes.PLANT ? t("Aggiungi quadro") : t("Aggiungi zona")}
                        </Button>
                    }
                    {isShowSettings && deepNumbers.length === 1 && mode === NavModes.PLANT &&
                        <Button
                            color="primary"
                            size="sm"
                            className="custom-no-margin umpi-tree-btn-add-new"
                            onClick={() => {
                                this.toggleLightpointEditModal(true);
                            }}>
                            <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                            {t("Add lightpoint")}
                        </Button>
                    }
                    {isShowSettings && deepNumbers.length >= 1 && mode === NavModes.PLANT && (
                        // ONLY FOR RADIOCONTROLLER
                        selectedTreeItem && selectedTreeItem.deviceType === DeviceTypes.RADIOCONTROLLER
                    ) &&
                        <Button
                            color="primary"
                            size="sm"
                            className="custom-no-margin umpi-tree-btn-add-new"
                            onClick={() => {
                                this.toggleLightpointEditModal(true);
                            }}>
                            <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                            {t("Add lightpoint")}
                        </Button>
                    }

                    <Card className="full-height noshadow-card overflow-y">
                        <CardHeader className="tree-top navigator-header" style={{ paddingTop: 5 }} >
                            <div className="umpi-tree-header-container">
                                {deepNumbers.length > 0 ?
                                    <div className="umpi-tree-go-back-header clickable" onClick={() => this.treeGoBack()}>
                                        <div className="btn-round umpi-tree-action-btn-go-back">
                                            <i className="fa fa-chevron-left" style={{ marginRight: "10px" }} />
                                        </div>
                                        <span className="color-blue-umpi">{
                                            mode === NavModes.PLANT ? t("Back to the cabins") : t("Back to the zones")
                                        }</span>
                                    </div>
                                    : (
                                        <div className="umpi-tree-go-back-header">
                                            <span className="color-blue-umpi selected-item">{
                                                mode === NavModes.PLANT ? t("Cabins") : t("Zone")
                                            }</span>
                                        </div>
                                    )}
                            </div>
                        </CardHeader>
                        <CardBody className="no-padding tree-and-details-wrapper" style={{ height: "calc( 100vh - 160px)" }}>
                            <div className="container" style={
                                Array.isArray(treeData) &&
                                treeData.length > 0 ?
                                { paddingBottom: "100px" } :
                                { height: "100%" }
                            }>{
                                Array.isArray(treeData) &&
                                treeData.length > 0 ?
                                treeData.map((item, index) => {
                                    // Calc focus id
                                    let focusId = "id-" + item.type + "-" + item.id;

                                    // Render
                                    return (
                                        <Fragment key={index}>
                                            {(deepNumbers.length <= 0) ? (
                                                <Row
                                                    className={"umpi-tree-parent-row" + (
                                                        focusedTreeItem === focusId ? " row-active" : ""
                                                    )}
                                                    key={"tree-row-" + index}
                                                    onClick={() => (
                                                        (mode === NavModes.PLANT) ?
                                                        this.treeFocusNode(index) :
                                                        this.treeGoDeep(index)
                                                    )}
                                                    style={{ cursor: "pointer" }}>
                                                    <Col className="labels-btn-align" sm={8} md={8} lg={8} xl={8}>{
                                                        (mode === NavModes.PLANT) ? ( // CABINE
                                                            <div className="pl-2">
                                                                {this.renderItemIcon(item, "cabin")}
                                                                <b>{item.label}</b><br />
                                                                <span>{item.address}</span>
                                                            </div>
                                                        ) : ( // ZONE
                                                            <div className="pl-2">
                                                                {this.renderItemIcon(item, "zone")}
                                                                <div>{item.label}</div>
                                                            </div>
                                                        )
                                                    }</Col>
                                                    <Col className="actions-btn-align" sm={4} md={4} lg={4} xl={4}>
                                                        <div style={{ display: "flex" }}>
                                                            {this.renderItemIcon(item)}
                                                            {item.children.length > 0 ? item.children.length : 0}
                                                        </div>
                                                        <div
                                                            onClick={() => this.treeGoDeep(index)}
                                                            className="btn-round umpi-tree-action-btn-go"
                                                            style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-chevron-right" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row
                                                    className="umpi-tree-details-row"
                                                    key={"tree-row-" + index}>
                                                    <Col>
                                                        <div className="item-details-wrapper">
                                                            <ItemProperties
                                                                dispatch={this.props.dispatch}
                                                                className="item-details"
                                                                width="100%"
                                                                showPagination={false}
                                                                sortable={false}
                                                                instances={instances}
                                                                relations={relations}
                                                                indexes={indexes}
                                                                item={item}
                                                                type={item.type}
                                                                root={root}
                                                                mode={mode}
                                                                originalItem={tree[mode].originalItem}
                                                                handleEditItemClick={this.handleEditItemClick}
                                                                handleSaveItemClick={this.handleSaveItemClick}
                                                                handleItemDidChange={this.handleItemDidChange}
                                                                handleCancelItemClick={this.handleCancelItemClick}
                                                                handleDeleteItemClick={this.handleDeleteItemClick}
                                                                //handleAddItemClick={this.handleAddItemClick}
                                                                handleCloneItemClick={this.handleCloneItemClick}
                                                                handleUpdatePositionFromGPS={this.handleUpdatePositionFromGPS}
                                                                handleUpdatePositionLightPointsFromGPS={this.handleUpdatePositionLightPointsFromGPS}
                                                                handleConfigItemClick={this.handleConfigItemClick}
                                                                handleTrendItemClick={this.handleTrendItemClick}
                                                                handleSendRadioControllerConfigClick={this.handleSendRadioControllerConfigClick}
                                                                isEditing={editingTreeItem !== null}
                                                                handleScheduledCommandsClick={this.handleScheduledCommandsClick}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            {deepNumbers.length > 0 ? (
                                                <Row key={"tree-row-childrens-" + index}>
                                                    <Col sm={12} md={12} lg={12} xl={12}>{
                                                        Array.isArray(item.children) &&
                                                        item.children.length > 0 ?
                                                        item.children.map((child, sindex) => {
                                                            // Calc focus id
                                                            let focusId = "id-" + child.data.item.type + "-" + child.data.item.id;

                                                            // Render
                                                            return (
                                                                <Row
                                                                    className={"umpi-tree-childs-row" + (
                                                                        focusedTreeItem === focusId ? " row-active" : ""
                                                                    )}
                                                                    key={"tree-row-child-" + sindex}
                                                                    onClick={() => this.treeFocusNode(sindex)}
                                                                    style={{ cursor: "pointer" }}>
                                                                    <Col className="labels-btn-align" sm={8} md={8} lg={8} xl={8}>
                                                                        <div className="pl-2">
                                                                            {this.renderItemIcon(child.data.item, "light")}
                                                                            <div>{child.data.item.label}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="actions-btn-align" sm={4} md={4} lg={4} xl={4}>
                                                                        <div>{
                                                                            (child.data.item.deviceType === DeviceTypes.RADIOCONTROLLER) ?
                                                                            this.renderItemIcon(child.data.item, "zone-radio-lights") :
                                                                            this.renderItemIcon(child.data.item, "zone-lights")
                                                                        }</div>
                                                                        <div
                                                                            onClick={() => this.treeGoDeep(sindex)}
                                                                            className="btn-round umpi-tree-action-btn-go"
                                                                            style={{ cursor: "pointer" }}>
                                                                            <i className="fa fa-chevron-right" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        }) : (deepNumbers.length <= 1) ? (
                                                            <div className="umpi-navigator-no-results mt-4">
                                                                <div className="title">{mode === NavModes.PLANT ?
                                                                    t('No lights associated to plant') :
                                                                    t('No lights associated to zone')
                                                                }</div>
                                                                <div className="descr">{mode === NavModes.PLANT ?
                                                                    t('No lights to plant create new') :
                                                                    t('No lights selected for zone')
                                                                }</div>
                                                            </div>
                                                        ) : null
                                                    }</Col>
                                                </Row>
                                            ) : null}
                                        </Fragment>
                                    );
                                }) : (
                                    <div className="umpi-navigator-no-results">
                                        <div className="title">{mode === NavModes.ZONES ?
                                            t('There are currently no zones set up') :
                                            t('There are currently no cabins set up')
                                        }</div>
                                        <div className="descr">{mode === NavModes.ZONES ?
                                            t('No zone create new') :
                                            t('No cabin create new')
                                        }</div>
                                    </div>
                                )
                            }</div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }

    /**
     *
     */
    renderMap() {
        let { tree, mode, infowindow } = this.state;
        let selectedProject = this.getSelectedProject();
        let center = null;

        if (tree[mode].centeredTreeItem && !tree[mode].editingTreeItem) {
            if (Array.isArray(tree[mode].centeredTreeItem.geometry)) {
                center = tree[mode].centeredTreeItem.geometry[0];
            }
            else {
                center = tree[mode].centeredTreeItem.geometry;
            }
        }
        else {
            if (selectedProject) {
                const user = User.current();
                let { c } = utils.fromSession(user.id, selectedProject.id);
                if (c) {
                    center = c;
                }
                else {
                    center = selectedProject.geometry
                }
            }
        }

        let { scale, zoom } = this.getMapZoomAndScale();
        const key = "np_" + (selectedProject ? selectedProject.id : 0) + "_" + mode;

        // let editingMarker = null;
        // if (tree[mode].editingTreeItem) {
        //   let item = tree[mode].editingTreeItem;
        //   const style = this.getStyleForItem(item, scale);
        //   editingMarker = {
        //     ...style,
        //     visible: true,
        //     position: item.geometry,
        //     item,
        //     key: "em_" + item.type + "." + item.id
        //   }
        // }

        let infowindowMarker = null;
        if (infowindow) {
            let item = infowindow.item;
            const style = this.getStyleForItem(item, scale);
            infowindowMarker = {
                ...style,
                visible: true,
                position: item.geometry,
                item,
                key: "im_" + item.type + "." + item.id
            }
        }

        return (
            <Card className="full-height-mymap">
                <CardBody className="full-height-map no-padding">
                    <Map
                        key={key}
                        disableDefaultUI={false}
                        containerElement={<div style={{ height: "100%", borderLeft: "1px solid #d9d9d9" }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        center={center}
                        zoom={zoom}
                        {...this.state}
                        t={this.props.t}
                        // editingMarker={editingMarker}
                        infowindowMarker={infowindowMarker}
                        // onDragEnd={this.handleMapBoundsChanged}
                        // markers = { markers }
                        handleMapMounted={this.handleMapMounted}
                        onMarkerDragEnd={this.handleMarkerDragEnd}
                        onMarkerClose={this.handleMarkerClose}
                        handleEditItemClick={this.handleEditItemClick}
                        handleSaveItemClick={this.handleSaveItemClick}
                        handleCancelItemClick={this.handleCancelItemClick}
                        handleChangePeriferalStatus={this.handleChangePeriferalStatus}
                        handlePeriferalChangeStatusDesired={this.handlePeriferalChangeStatusDesired}
                        handleStartEditingPeriferal={this.handleStartEditingPeriferal}
                        handleCancelEditingPeriferal={this.handleCancelEditingPeriferal}
                        userDidCancel={this.state.userDidCancel}
                        onMapClick={this.handleMapClick}
                        onMapRClick={this.handleMapRClick}
                        onMapDblClick={this.handleMapDblClick}
                        handlePolygonMounted={this.handlePolygonMounted}
                        onMouseMove={this.onMouseMove}
                        onVertexDrag={this.onVertexDrag}
                        onVertexDragEnd={this.onVertexDragEnd}
                        onVertexClick={this.onVertexClick}
                        onVertexRClick={this.onVertexRClick}
                        onBoundsChanged={this.onBoundsChanged}
                    />

                </CardBody>
            </Card>

        );
    }

    /**
     *
     */
    onBoundsChanged() {
        this.renderMapFeatures();

        if (this.map) {
            const user = User.current();
            const selectedProjectId = localStorage.getItem("selectedProjectId");
            if (selectedProjectId) {
                let zoom = this.map.getZoom();
                let center = this.map.getCenter();
                if (zoom && center) {
                    utils.toSession(user.id, selectedProjectId, { z: zoom, c: center });
                }
            }
        }

        let { infowindow } = this.state;
        if (infowindow) {
            this.setState({ infowindow });
        }
    }

    /**
     *
     * @param {*} map
     */
    handleMapMounted(map) {
        if (map) {
            //const { tree, mode } = this.state;
            if (map && map.context[MAP]) {
                this.map = map.context[MAP];
                if (!google.maps.event.hasListeners(this.map.data, 'click')) {
                    this.map.data.addListener("click", this.handleMarkerClick.bind(this));
                    this.map.data.addListener('setgeometry', this.handleSetGeometry.bind(this));
                }
                this.renderMapFeatures();
            }
        }
    }

    handleSetGeometry(event) {
        let item = event.feature.getProperty("item");

        if (event.newGeometry.getType() === 'Point') {
            event.newGeometry.forEachLatLng((latlng) => {
                item.geometry = { lat: latlng.lat(), lng: latlng.lng() };
            });
        }

        const { instances } = this.state;
        this.setState({ instances });

    }

    /**
     * Visualizza l'infowindow associata alla feature passata come argomento.
     * @param {*} feature
     */
    showInfoWindow(feature) {
        // let { status, alert, infowindow } = this.state;
        const item = feature.getProperty("item");
        this.setState({ infowindow: { item } });
    }

    /**
     *
     * @param {*} key
     * @param {*} polygon
     */
    handlePolygonMounted(key, polygon) {
        if (polygon) {
            this.polygonRefs[key] = polygon;
        }
    }

    /**
     *
     * @param {*} e
     */
    handleMapClick(e) {
        let { mode, infowindow } = this.state;
        if (mode === NavModes.PLANT) {
            if (infowindow) {
                this.setState({ infowindow: null });
                // infowindow.close();
            }
            // this.handleMarkerCloseAll();
        }
        else if (mode === NavModes.ZONES) {

            let { tree } = this.state;

            if (tree.zones.editingTreeItem) {
                const zone = tree.zones.editingTreeItem;
                const point = { lat: e.latLng.lat(), lng: e.latLng.lng() };

                let selectedVertex = tree.zones.selectedVertex;

                if (!this.isOverlappedOrMalformed(selectedVertex, point, zone, VertexActions.ADD)) {
                    if (zone.geometry === null || zone.geometry === undefined || zone.geometry.length === 0) {
                        zone['geometry'] = [{ lat: e.latLng.lat(), lng: e.latLng.lng() }];
                        tree.zones.selectedVertex = 0;
                    }
                    else {
                        let ret = [];

                        zone.geometry.map((point, index) => {
                            ret.push(point);
                            if (index === selectedVertex) {
                                ret.push({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                                tree.zones.selectedVertex = (index + 1);
                            }
                            return false;
                        });
                        zone.geometry = ret;
                    }

                    this.setState({
                        tree
                    });

                }
            }
            else {
                const { instances, tree } = this.state;
                let zone = null;
                for (let zon of instances.zones) {
                    if (zon.geometry) {
                        let poly = new google.maps.Polygon({
                            paths: zon.geometry
                        });
                        if (google.maps.geometry.poly.containsLocation(e.latLng, poly)) {
                            zone = zon;
                            break;
                        }
                    }
                }

                if (zone) {

                    // const treeItem = {
                    //     type: ItemTypes.ZONE,
                    //     item: zone
                    // }

                    tree[mode].selectedTreeItem = zone;
                    tree[mode].scroll = false;
                    this.setState({
                        tree
                    });
                }
            }
        }
    }

    /**
     *
     * @param {*} e
     */
    handleMapRClick(e) {
        const { mode } = this.state;
        if (mode === NavModes.PLANT) {
            this.handleMarkerCloseAll();
        }
    }

    // onMouseUp(e){
    //   const { tree } = this.state;
    //   if (tree.zones.editingTreeItem){
    //     const zone = tree.zones.editingTreeItem.item;
    //     if (zone && this.polygonRefs[zone.id] ){
    //           const path = this.polygonRefs[zone.id].getPath();
    //           if (e.edge){
    //             let ret = [];
    //             for(let i=0;i<path.getLength();i++){
    //               ret.push(path.getAt(i));
    //             }
    //             zone.geometry = ret;
    //             this.setState({ tree });
    //           }
    //       }
    //   }
    // }

    /**
     * Restituisce true se la forma creata dalla action si sovrappone ad una zona esistente od è malformata.
      * @param {*} index indice del vertice da rimuovere o spostare
      * @param {*} srcPoint coordinate del vertice da aggiungere o spostare
      * @param {*} srcZone poligono modificato
      * @param {*} action [ADD | DEL | DRAG]
      */
    isOverlappedOrMalformed(index, srcPoint, srcZone, action) {

        const { instances } = this.state;

        try {

            let srcPoints = srcZone.geometry ? srcZone.geometry.slice(0) : [];

            let srcShape = null;

            if (action === VertexActions.DEL && srcPoints.length < 4) {
                return false;
            }

            if (srcPoints.length === 0) {
                // punto
                srcShape = {
                    elem: turf.point([srcPoint.lat, srcPoint.lng]),
                    type: ShapeTypes.POINT
                }
            }
            else if (srcPoints.length === 1) {
                // segmento
                srcShape = {
                    elem: turf.lineString([[srcPoints[0].lat, srcPoints[0].lng], [srcPoint.lat, srcPoint.lng]]),
                    type: ShapeTypes.LINE
                }

            }
            else {
                // poligono
                if (action === VertexActions.DEL) {
                    srcPoints.splice(index, 1);
                    if (index === 0) {
                        srcPoints.push(srcZone.geometry[1]);
                    }
                    else {
                        srcPoints.push(srcZone.geometry[0]);
                    }
                    srcPoints = srcPoints.map((p) => [p.lat, p.lng]);
                    srcShape = {
                        elem: turf.polygon([srcPoints]),
                        type: ShapeTypes.POLYGON
                    }

                }

                else if (action === VertexActions.DRAG) {

                    // con vertice spostato
                    srcPoints[index] = srcPoint;
                    if (index !== 0)
                        srcPoints.push(srcZone.geometry[0]);
                    else
                        srcPoints.push(srcPoint);
                    srcPoints = srcPoints.map((p) => [p.lat, p.lng]);
                    srcShape = {
                        elem: turf.polygon([srcPoints]),
                        type: ShapeTypes.POLYGON
                    }

                } else if (action === VertexActions.ADD) {
                    // con vertice aggiunto tra il vertice selezionato ed il successivo
                    //let selectedVertex = tree.zones.selectedVertex;
                    let ret = [];
                    srcPoints.map((point, i) => {
                        ret.push([point.lat, point.lng]);
                        if (i === index) {
                            ret.push([srcPoint.lat, srcPoint.lng]);
                        }
                        return false;
                    });
                    ret.push([srcPoints[0].lat, srcPoints[0].lng]);
                    srcShape = {
                        elem: turf.polygon([ret]),
                        type: ShapeTypes.POLYGON
                    }

                }

            }

            for (let dstZone of instances.zones) {
                if (dstZone.id === srcZone.id || dstZone.geometry === null || dstZone.geometry.length < 3) {
                    continue;
                }
                let dstPoints = dstZone.geometry.slice(0);
                dstPoints.push(dstZone.geometry[0]);
                dstPoints = dstPoints.map(p => [p.lat, p.lng]);
                const turfDstPoly = turf.polygon([dstPoints]);

                switch (srcShape.type) {
                    case ShapeTypes.POLYGON:
                        let intersection = turf.intersect(srcShape.elem, turfDstPoly);
                        if (intersection !== null) {
                            return true;
                        }
                        break;
                    case ShapeTypes.LINE:
                        let intersectionPoints = turf.lineIntersect(srcShape.elem, turfDstPoly);
                        if (intersectionPoints.features.length > 0) {
                            return true;
                        }
                        break;
                    case ShapeTypes.POINT:
                        let isInside = turf.booleanPointInPolygon(srcShape.elem, turfDstPoly);
                        if (isInside) {
                            return true;
                        }
                        break;
                    default: break;
                }

            }
        } catch (error) {
            console.error(error);
            return true;
        }

        return false;
    }

    /**
     * Sposta le coordinate di un vertice se la forma creata è consistente o non si sovramppone.
     * @param {*} e
     * @param {*} zone
     * @param {*} index
     */
    onVertexDrag(e, zone, index) {
        const { tree } = this.state;
        let point = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        if (!this.isOverlappedOrMalformed(index, point, zone, VertexActions.DRAG)) {
            zone.geometry[index] = point;
            if (tree.zones.selectedVertex !== index) {
                tree.zones.selectedVertex = index;
            };
        }
        this.setState({ tree });
    }

    /**
     * Riporta il vertice al punto precedente allo spostamento se c'è stata una sovrapposizione o un poligono malformattato.
     * @param {*} e
     * @param {*} zone
     * @param {*} index
     */
    onVertexDragEnd(e, zone, index) {
        const { tree } = this.state;
        let srcPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        if (zone.geometry[index] !== srcPoint) {
            tree.zones.overlapped[zone.id] = new Date().getTime();
            this.setState({ tree });
        }
    }

    /**
     * Seleziona un vertice del poligono
     * @param {*} e
     * @param {*} zone
     * @param {*} index
     */
    onVertexClick(e, zone, index) {
        const { tree } = this.state;
        tree.zones.selectedVertex = index;
        this.setState({ tree });
    }

    /**
     * Elimina un vertice dal poligono
     * @param {*} e
     * @param {*} zone
     * @param {*} index
     */
    onVertexRClick(e, zone, index) {
        const { tree } = this.state;
        if (!this.isOverlappedOrMalformed(index, null, zone, VertexActions.DEL)) {
            zone.geometry.splice(index, 1);
            tree.zones.selectedVertex = (index > 1 ? index - 1 : 0);
            this.setState({
                tree
            });
        }
    }

    /**
     *
     */
    handleMapDblClick() {

    }

    /**
     *
     * @param {*} e
     * @param {*} a
     * @param {*} b
     * @param {*} c
     */
    onMouseMove(e, a, b, c) {

        //Event.preventDefault();
        // e.tb.stopPropagation();
        return false;
    }

    /**
     *
     * @param {*} editingPeriferal
     */
    handleStartEditingPeriferal(editingPeriferal) {
        this.setState({
            editingPeriferal
        });
    }

    /**
     *
     */
    handleCancelEditingPeriferal() {

        let { status, editingPeriferal } = this.state;
        status = this.changeStatusDesired(editingPeriferal, true);
        this.setState({
            status,
            editingPeriferal: null,
            userDidCancel: true
        });
    }

    /**
     *
     * @param {*} elem
     * @param {*} reset
     */
    changeStatusDesired(elem, reset) {
        const { status } = this.state;
        if (elem === null)
            return status;
        if (elem.type === ItemTypes.CABINET) {
            // cabinet
            const items = status[Models.CABINETS][elem.id];
            // assegno il valore
            const itemsKeys = Object.keys(items);
            for (let key of itemsKeys) {
                if (!key.startsWith('undefined')) {
                    let item = items[key];
                    if (item.periferal === elem.periferal) {

                        if (reset) {
                            item.statusDesired = elem.statusDesired;
                        }
                        else {
                            if (item.statusDesired === null || item.statusDesired === undefined) {
                                item.statusDesired = { value: null, timestamp: null };
                            }
                            item.statusDesired.value = elem.value;
                            item.statusDesired.timestamp = new Date().getTime();
                        }

                        break;
                    }
                }
            }
        }
        else {
            // lightpoint
            const item = status[Models.LIGHTPOINTS][elem.id];
            // assegno il valore
            if (reset) {
                item.statusDesired = elem.statusDesired;
            }
            else {
                if (item.statusDesired === null || item.statusDesired === undefined) {
                    item.statusDesired = { value: null, timestamp: null };
                }
                item.statusDesired.value = elem.value;
                item.statusDesired.timestamp = new Date().getTime();
            }

        }
        return status;
    }

    handlePeriferalChangeStatusDesired(elem) {
        let status = this.changeStatusDesired(elem, false);
        this.setState({ userDidCancel: false, status });
    }

    /**
     *
     * @param {*} periferal
     * @param {*} value
     */
    handleChangePeriferalStatus(periferal, value) {
        const { t } = this.props;

        this.setState({
            userDidCancel: false,
            commandResult: "",
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        let { status, editingPeriferal } = this.state;
                        if (editingPeriferal) {
                            status = this.changeStatusDesired(editingPeriferal, true);
                        }
                        this.setState({
                            status,
                            alert: null,
                            userDidCancel: true,
                            editingPeriferal: null
                        });
                    }}>
                        {t("Invio comando")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            let { status, editingPeriferal } = this.state;
                            if (editingPeriferal) {
                                status = this.changeStatusDesired(editingPeriferal, true);
                            }
                            this.setState({
                                status,
                                alert: null,
                                userDidCancel: true,
                                editingPeriferal: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={() => {
                            let body = {
                                macAddress: periferal.deviceId,
                                periferal: (periferal.periferal.split("/"))[1],
                                type: (periferal.periferal.split("/"))[0],
                                value
                            }

                            // Apro uno spinner
                            this.setState({
                                alert: (
                                    <Modal isOpen={true} className="modal-umpi-container">
                                        <ModalHeader toggle={() => {
                                            this.setState({
                                                alert: null
                                            });
                                        }}>
                                            {t("Invio comando in corso...")}
                                        </ModalHeader>
                                        <ModalBody>
                                            <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={60}
                                                    color={'#114d8c'}
                                                    loading={true}
                                                />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                )
                            }, async () => {
                                try {
                                    let res = await loopbackService.postFuncname(ModelPaths.PLANT, 'comunications', 'sendPeriferalCommandAsync', body);
                                    let userDidCancel = (res.response.result !== 'SUCCESS' && res.response.result !== 'REQUEST_SENT');
                                    let { status, editingPeriferal } = this.state;

                                    if (userDidCancel) {
                                        status = this.changeStatusDesired(editingPeriferal, true);
                                    }

                                    let alert = null;

                                    if (res.response.result !== 'SUCCESS' && res.response.result !== 'REQUEST_SENT') {
                                        alert = (
                                            <Modal isOpen={true} className="modal-umpi-container">
                                                <ModalHeader toggle={() => {
                                                    this.setState({
                                                        userDidCancel,
                                                        alert: null
                                                    });
                                                }}>
                                                    {t("Invio comando")}
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                        <i className='fa fa-exclamation-circle' />
                                                    </div>
                                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                        {t("Errore in invio comando")}
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            userDidCancel,
                                                            alert: null,
                                                        });
                                                    }} color="success" className="umpi-modal-btn-save">
                                                        {t("Confirm")}
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        );
                                    }

                                    this.setState({
                                        status,
                                        editingPeriferal: null,
                                        commandResult: res.response.result,
                                        alert
                                    });
                                }
                                catch (error) {
                                    //console.log(error);
                                    let errors = [error];
                                    this.setState({ alert: null, errors });
                                }
                            });
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });

    }

    /**
     *
     */
    handleScheduledCommandsClose() {
        let { tree, mode } = this.state;
        tree[mode].scroll = true;
        this.setState({ showingScheduledCommands: false, tree });
    }

    /**
     *
     */
    handleZoneConfigurationClose() {
        let { tree, mode } = this.state;
        tree[mode].scroll = true;
        this.setState({ showingZoneConfiguration: false, tree });
    }

    /**
     *
     */
    handleConfigClose() {
        let { tree, mode } = this.state;
        tree[mode].scroll = true;
        this.setState({ configuringItem: null, tree });
        this.fetchData();
    }

    /**
     *
     */
    handleTrendClose() {
        let { tree, mode } = this.state;
        tree[mode].scroll = true;
        this.setState({ trendItem: null, tree });
        // this.fetchData();
    }

    /**
     * Disegna l'intera pagina.
     */
    render() {
        let {
            errors,
            configuringItem,
            trendItem,
            instances,
            relations,
            indexes,
            showingScheduledCommands,
            showingZoneConfiguration,
            tree,
            mode,
            status,
            markers
        } = this.state;

        let selectedTreeItem = tree[mode].selectedTreeItem;

        /**CAPUANO
         * Per le chiamate delle satistiche dei SYRA mi occorre il peripheralCode che si trova tra i lightpoints
         * salvati in this.state.status .
         * Per i modem invece mi occorre anche l'oggetto cabinets che contiene i modem
         */
        return (
            <div id="navigator-sampling-ctx" className="full-height-no-header navigator-side-menu">
                {this.state.alert}
                {trendItem &&
                    <Trend lightpoints={this.state.status[Models.LIGHTPOINTS]} cabinets={this.state.status[Models.CABINETS]} item={trendItem}
                        // devices={this.props.devices}
                        handleTrendClose={this.handleTrendClose}
                    />
                }
                <PanelHeader
                    size="sm"
                />
                <div className="content full-height navigator-fullpage">
                    {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}
                    {showingScheduledCommands &&
                        <ScheduledCommands
                            status={status}
                            cabinet={selectedTreeItem}
                            handleScheduledCommandsClose={this.handleScheduledCommandsClose}
                        />
                    }
                    {showingZoneConfiguration &&
                        <ZoneConfiguration
                            zone={selectedTreeItem}
                            instances={instances}
                            indexes={indexes}
                            handleZoneConfigurationClose={this.handleZoneConfigurationClose}
                        />
                    }
                    {configuringItem &&
                        <Configurations
                            {...this.props}
                            devices={instances.devices}
                            cabinet={configuringItem}
                            selectedProject={this.getSelectedProject()}
                            markers={markers}
                            handleConfigClose={this.handleConfigClose}
                        />
                    }
                    <Row className="full-height ">
                        <Col xs={12} sm={4} md={5} lg={4} xl={3} className="">
                            {this.renderSide()}
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={8} xl={9} className="">
                            {this.renderMap()}
                        </Col>
                    </Row>
                </div>
                <CabinetEdit
                    instances={instances}
                    relations={relations}
                    isOpenModal={this.state.isCabinetEditModalOpen}
                    onModalClose={(createdItem) => {
                        this.toggleCabinetEditModal(false)
                        this.addCabinet(createdItem);
                    }}
                />
                <LightpointEdit
                    instances={instances}
                    isOpenModal={this.state.isLightpointEditModalOpen}
                    onModalClose={(createdItem) => {
                        this.toggleLightpointEditModal(false)
                        this.addLightPoint(selectedTreeItem, createdItem);
                    }}
                />
                <ZoneEdit
                    isOpenModal={this.state.isZoneEditModalOpen}
                    onModalClose={(createdItem) => {
                        this.toggleZoneEditModal(false)
                        this.addZone(createdItem);
                    }}
                />
            </div>
        );
    }

    /**
     * Gestore del click sul pulsante dei comandi schedulati.
     * @param {*} item
     */
    handleScheduledCommandsClick(item) {
        this.streamUpdater.stopRefresh();
        this.setState({
            showingScheduledCommands: true
        });
    }

    /**
     * Aggiunge una cabina.
     */
    addCabinet(modalItem = null) {
        const { dispatch } = this.props;

        if (modalItem) {
            if (!(
                modalItem.geometry &&
                ![null, undefined, "", 0].includes(modalItem.geometry.lat) &&
                ![null, undefined, "", 0].includes(modalItem.geometry.lng))) {
                // Get data from session
                const user = User.current();
                const selectedProjectId = localStorage.getItem('selectedProjectId');
                const { c } = utils.fromSession(user.id, selectedProjectId);

                if (c) {
                    // Replace geometry
                    modalItem["geometry"] = c;
                }
            }

            // Edit cabinet
            dispatch(loopbackActions.edit(
                ModelPaths.PLANT,
                Models.CABINETS,
                modalItem,
                null,
                async (response) => {
                    // Get project active
                    let selectedProjectId = localStorage.getItem('selectedProjectId');

                    // Check response
                    if (response &&
                        response.instance &&
                        response.instance.id > 0 &&
                        response.instance.andros_sn) {
                        // Create relation
                        await loopbackService.patch(
                            "archive",
                            "devices",
                            response.instance.andros_sn + "/setLink",
                            { cabinetId: response.instance.id }
                        );
                    }

                    // Check for documents
                    if (response &&
                        response.instance &&
                        response.instance.id > 0 &&
                        response.instance.documents &&
                        selectedProjectId > 0) {
                        // Get keys
                        let keys = Object.keys(response.instance.documents);

                        // Check for docs into storage
                        for (let i = 0; i < keys.length; i++) {
                            // Get current media
                            let media = response.instance.documents[keys[i]];

                            // Create doc media
                            await loopbackService.pushProjectDocumentToModel(
                                selectedProjectId,
                                {
                                    "filename": media.name,
                                    "type":     "CABINETS",
                                    "deviceId": response.instance.id,
                                    "file":     media.file
                                }
                            );
                        }
                    }

                    // Refresh data
                    this.fetchData();
                }
            ));
        }
    }

    /**
     * Aggiunge un lightpoint
     * @param {*} item
     */
    addLightPoint(item, modalItem = null) {
        const { dispatch } = this.props;
        const { tree, mode, instances, indexes } = this.state;

        if (modalItem) {
            let root = tree[mode].root;
            let cabinetId = 0;
            let geometry = null;
            let radiocontroller = null;

            if (item.type === ItemTypes.CABINET) {
                // aggiungo i punti luce avendo cliccato su un quadro
                cabinetId = item.id;
                geometry = item.geometry;
            }
            else {
                if (item.type === ItemTypes.LIGHTPOINT && item.deviceType === DeviceTypes.RADIOCONTROLLER) {
                    // è stato cliccato un radiocontroller
                    radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[item.id]];
                    geometry = item.geometry;
                }
                else {
                    // è stato cliccato un syra o un radionode
                    // cerco il padre dell'item
                    let parentNode = new Tree(root).getParent(item);
                    if (parentNode && parentNode.data && parentNode.data.item) {
                        const deviceType = parentNode.data.item.deviceType;
                        if (deviceType === DeviceTypes.ANDROS) {
                            cabinetId = item.cabinetId;
                            const cabinet = instances.cabinets[indexes.cabinets[cabinetId]];
                            if (cabinet) {
                                geometry = cabinet.geometry;
                            }
                        }
                        else if (deviceType === DeviceTypes.RADIOCONTROLLER) {
                            radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[parentNode.data.id]];
                            geometry = item.geometry;
                        }
                    }
                }
            }

            let parent = (
                radiocontroller ? {
                    radioControllerId: radiocontroller.id
                } : {
                    cabinetId
                }
            );

            if (!(
                modalItem.geometry &&
                ![null, undefined, "", 0].includes(modalItem.geometry.lat) &&
                ![null, undefined, "", 0].includes(modalItem.geometry.lng))) {
                // Get data from session
                const user = User.current();
                const selectedProjectId = localStorage.getItem('selectedProjectId');
                const { c } = utils.fromSession(user.id, selectedProjectId);

                if (c) {
                    // Replace geometry
                    modalItem["geometry"] = c;
                }
            }

            let data = [{
                ...parent,
                geometry,
                ...modalItem
            }];

            dispatch(loopbackActions.add(
                ModelPaths.PLANT,
                Models.LIGHTPOINTS,
                data,
                async (response) => {
                    // Get project active
                    let selectedProjectId = localStorage.getItem('selectedProjectId');

                    console.log(response)

                    // Check response
                    if (response &&
                        Array.isArray(response.instance) &&
                        response.instance.length > 0 &&
                        response.instance[0].id > 0 &&
                        response.instance[0].syra_sn) {
                        // Create relation
                        await loopbackService.patch(
                            "archive",
                            "devices",
                            response.instance[0].syra_sn + "/setLink",
                            { lightPointId: response.instance[0].id }
                        );
                    }

                    // Check for array lights
                    if (response && Array.isArray(response.instance)) {
                        // Loop on lights
                        for (let n = 0; n < response.instance.length; n++) {
                            // Check data for documents
                            if (response.instance[n].id > 0 &&
                                response.instance[n].documents &&
                                selectedProjectId > 0) {
                                // Get keys
                                let keys = Object.keys(response.instance[n].documents);

                                // Check for docs into storage
                                for (let i = 0; i < keys.length; i++) {
                                    // Get current media
                                    let media = response.instance[n].documents[keys[i]];

                                    // Create doc media
                                    await loopbackService.pushProjectDocumentToModel(
                                        selectedProjectId,
                                        {
                                            "filename": media.name,
                                            "type":     "LIGHTPOINTS",
                                            "deviceId": response.instance[n].id,
                                            "file":     media.file
                                        }
                                    );
                                }
                            }
                        }
                    }

                    // Refresh data
                    this.fetchData();
                }
            ));
        }
    }

    /**
     * Aggiunge una zona.
     */
    async addZone(modalItem = null) {
        const { dispatch } = this.props;

        if (modalItem) {
            dispatch(loopbackActions.edit(
                ModelPaths.PLANT,
                Models.ZONES,
                modalItem
            )).then(() => this.fetchData());
        }
    }
}

/**
 * Inserisce nelle proprietà le variabili di stato redux.
 * @param {*} state
 */
function mapStateToProps(state) {

    const { authentication, loopback } = state;

    let {
        configurations,
        projects,
        multiEditDidEnd,
        androsNotLinked,
        syrasNotLinked,
        refresh,
        scheduledcommands,
        alarms
    } = loopback;

    let ret = {
        loggedinUser: authentication.user,
        projects,
        multiEditDidEnd,
        configurations,
        androsNotLinked,
        syrasNotLinked,
        refresh,
        scheduledcommands,
        alarms
    };

    // ciclo sui models
    for (let model of NavigatorComponent.models) {
        ret[model] = loopback[model];
    }

    return ret;
}

const Navigator = withTranslation()(connect(mapStateToProps)(NavigatorComponent));

export default Navigator;
export { strokeForBulb, fillForBulb };

import { connect } from 'react-redux';
import React, { Component } from "react";
import { Card, CardBody, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { PanelHeader } from "components";
import Button from "components/CustomButton/CustomButton";
import { projectActions } from 'actions';
import { permissions } from '../../helpers';
import ReactTable from "react-table";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import User from '../../entities/User'
import { permissionConstants, roleConstants } from '../../constants'
import ErrorModal from "../Components/ErrorModal"
import { withTranslation } from 'react-i18next';
import { ProjectEdit } from './ProjectEdit';
import { refreshSearchEngine } from '../../components/Header/Header';
import { projectService } from '../../services';

class Projects extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            error: null,
            filterProjectByName: null,
            isProjectEditModalOpen: false,
            selectedProjectId: 0,
            projects: props.projects && Array.isArray(props.projects.items) ? props.projects.items : []
        }

        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchProjects();

        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            this.setState({
                filterProjectByName: search_value
            });
        }, this.props.t("Search a project"));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }

        if (nextProps.projects &&
            Array.isArray(nextProps.projects.items) &&
            JSON.stringify(this.state.projects) !== JSON.stringify(nextProps.projects.items)) {
            this.setState({
                projects: nextProps.projects.items
            }, () => {
                this.normalizeProject();
            })
        }
    }

    async fetchProjects() {
        try {
            const { dispatch } = this.props;
            let filter = { include: 'users' };

            if (permissions.grant('SHOW_ONLY_OWN_PROJECTS')) {
                const user = User.current()
                const userId = user.id;
                filter = {
                    include: 'users',
                    where: {
                        members: {
                            like: "%\"" + userId + "\"%"
                        }
                    }
                };
            }

            await dispatch(projectActions.getAll(filter));
            this.normalizeProject();
        } catch (err) {
            //console.log(err);
        }
    }

    toggleProjectEditModal = (state, projectId = 0) => {
        this.setState({
            isProjectEditModalOpen: (state === true) ? true : false,
            selectedProjectId: (state === true) ? projectId : 0
        }, () => {
            if (state === false) {
                this.fetchProjects();
            }
        });
    }

    deleteConfirm(id) {
        const { t } = this.props;

        if (id === 0) {
            this.setState({
                alert: null
            });
        }
        else
            this.setState({
                alert: (
                    <Modal isOpen={true} className="modal-umpi-container">
                        <ModalHeader toggle={() => this.deleteConfirm(0)}>
                            {t("Cancellazione del progetto")}
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                <i className='fa fa-exclamation-circle' />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                {t("Sei sicuro?")}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.deleteConfirm(0)} color="default" className="umpi-modal-btn-cancel">
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button onClick={() => this.delete(id)} color="success" className="umpi-modal-btn-save">
                                {t("Confirm")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            });
    }

    delete(id) {
        const { dispatch } = this.props;
        if (id) {
            this.setState({
                alert: null
            });
            dispatch(projectActions.delete(id));
        }
    }

    normalizeProject = async () => {
        const p_projects = this.state.projects;
        if (Array.isArray(p_projects)) {
            for (let i = 0; i < p_projects.length; i++) {
                // prepare image storage
                p_projects[i]["image"] = null;

                try {
                    // try call
                    let image = await projectService.getImageByProjectId(p_projects[i].id);

                    // Check and save
                    if (image && image.base64) {
                        p_projects[i]["image"] = image.base64;
                    }
                } catch(exImg) {
                    console.error(exImg);
                }
            }
        }

        this.setState({ projects: p_projects })
    }

    render() {
        const { t } = this.props;
        const { error, filterProjectByName, isProjectEditModalOpen, projects } = this.state;
        let isError = (typeof error === "string") ? error !== "{}" : error && JSON.stringify(error) !== "{}";
        let loading = this.props.projects && this.props.projects.loading;

        const data = projects.map((item, key) => {
            if (filterProjectByName &&
                !(item.name && item.name.toLowerCase().includes(
                    filterProjectByName.toLowerCase()
                ))) {
                return null;
            }

            let actions = null;
            if (permissions.grant(permissionConstants.EDIT_PROJECTS)) {
                actions = (
                    <div className="actions-right">
                        <ButtonWithTooltip
                            className="umpi-modal-action-btn-edit"
                            id={"editProject_" + item.id}
                            onClick={() => this.toggleProjectEditModal(true, item.id)}
                            size="sm"
                            icon
                            tooltipText={t("Modifica")}>
                            <i className="fa fa-pencil" />
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                            className="umpi-modal-action-btn-del"
                            id={"deleteProject_" + item.id}
                            onClick={() => { this.deleteConfirm(item.id); }}
                            size="sm"
                            icon
                            tooltipText={t("Elimina")}>
                            <i className="fa fa-times" />
                        </ButtonWithTooltip>
                    </div>
                );
            }
            else if (User.isWithinGroup(this.props.loggedinUser, roleConstants.CAT)) {
                actions = (
                    <div className="actions-right">
                        <ButtonWithTooltip
                            className="umpi-modal-action-btn-edit"
                            id={"editProject_" + item.id}
                            onClick={() => this.toggleProjectEditModal(true, item.id)}
                            size="sm"
                            icon
                            tooltipText={t("Modifica")}>
                            <i className="fa fa-pencil" />
                        </ButtonWithTooltip>
                    </div>
                );
            }

            let image = null;
            if (item.image) {
                image = (
                    <div className="umpi-table-list-image">
                        <img src={item.image} alt="" />
                    </div>
                )
            } else {
                image = (
                    <div className="umpi-table-list-image">&nbsp;</div>
                )
            }

            return {
                id: key,
                image: image,
                name: item.name,
                description: item.description,
                actions: actions
            };
        }).filter(x => x);

        return (
            <div>
                {this.state.alert}
                <PanelHeader size="sm" />
                {isError && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}

                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Elenco progetti")}</h2>
                            <span className="pull-right ">
                                {this.props.additionalTopButtons}
                                {
                                    !this.props.hideNewButton &&
                                    <Button onClick={() => this.toggleProjectEditModal(true)} color="primary" round size="sm" className="custom-no-margin btn-add-new-users">{t("NewProjects")}</Button>
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-padding-paper">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        className="-striped -highlight"
                                        previousText={t('Precedente')}
                                        nextText={t('Successiva')}
                                        noDataText={loading ? '' : (isProjectEditModalOpen ? t('Editing in progress') : t('Nessun risultato'))}
                                        pageText={t('Pagina')}
                                        ofText={t('di')}
                                        rowsText={t('righe')}
                                        LoadingComponent={
                                            () => {
                                                if (loading) {
                                                    return (
                                                        <div className="-loading -active">
                                                            <div className="-loading-inner custom-margin-0-auto">
                                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                        }
                                        columns={[
                                            {
                                                Header: " ",
                                                accessor: "image",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false,
                                                width: 65
                                            },
                                            {
                                                Header: t("Nome"),
                                                accessor: "name",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Descrizione"),
                                                accessor: "description",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                //Header: () => (<div align="right">{t("Azioni")}</div>),
                                                Header: () => (<div align="right">&nbsp;</div>),
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            }
                                        ]} // Definizione delle colonne della tabella (secondo il componente `ReactTable`)
                                        defaultPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <ProjectEdit
                    projectId={this.state.selectedProjectId}
                    isOpenModal={this.state.isProjectEditModalOpen}
                    onModalClose={() => this.toggleProjectEditModal(false)}
                />
            </div>
        );
    }
}

/* Container component */
function mapStateToProps(state) {
    const { authentication, projects } = state;
    return {
        projects,
        loggedinUser: authentication.user,
        error: projects.error
    };
}

const connectedProjects = withTranslation()(connect(mapStateToProps)(Projects));
/* /Container component */

export { connectedProjects as Projects };

const DiscreteValues = {
    "legacy": {
        'min': 0,
        '10%': 10,
        '20%': 20,
        '30%': 30,
        '40%': 40,
        '50%': 50,
        '60%': 60,
        '70%': 70,
        '80%': 80,
        '90%': 90,
        'max': 100
    },
    "default": {
        'min': -10,
        'max': 110
    }
};
export default DiscreteValues;

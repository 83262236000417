
import React from "react";
import { connect } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button
} from "reactstrap";
import { utils } from '../../helpers';
import { PanelHeader } from "components";
import ErrorModal from "../Components/ErrorModal"
import { roleConstants } from '../../constants';
import { loopbackService } from '../../services';
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import { withTranslation } from 'react-i18next';
import "../../assets/css/context-menu.css";
import DailyProfile from "./DailyProfile";
import User from '../../entities/User';
import { TimerProfileEdit } from './TimerProfileEdit';

const MODEL_DAILY = "timerprofiledaily";
const MODEL_WEEKLY = "timerprofileweekly";

class TimerProfilesComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: [],
            alert: null,
            model: MODEL_DAILY,
            profiles: {
                [MODEL_DAILY]: [],
                [MODEL_WEEKLY]: []
            },
            selectedProfile: {
                [MODEL_DAILY]: null,
                [MODEL_WEEKLY]: null
            },
            isEditingProfile: {
                [MODEL_DAILY]: false,
                [MODEL_WEEKLY]: false
            },
            editingProfile: {
                [MODEL_DAILY]: null,
                [MODEL_WEEKLY]: null
            },
            saving: false,
            validationError: null
        };

        selectedProjectChangeListeners.push(() => {
            this.setState({
                selectedProfile: {
                    [MODEL_DAILY]: null,
                    [MODEL_WEEKLY]: null
                },
                isEditingProfile: {
                    [MODEL_DAILY]: false,
                    [MODEL_WEEKLY]: false
                },
                editingProfile: {
                    [MODEL_DAILY]: null,
                    [MODEL_WEEKLY]: null
                },
                profiles: {
                    [MODEL_DAILY]: [],
                    [MODEL_WEEKLY]: []
                }
            });
            this.fetchData(MODEL_DAILY);
        });

        this.handleSavedProfile = this.handleSavedProfile.bind(this);
        this.handleDeletedProfile = this.handleDeletedProfile.bind(this);
        this.handleCancelProfile = this.handleCancelProfile.bind(this);
        this.getLegacyTimerFlag = this.getLegacyTimerFlag.bind(this);
    }

    componentDidMount() {
        this.fetchData(MODEL_DAILY);
    }

    componentWillMount() {
        this.getLegacyTimerFlag();
    }

    getLegacyTimerFlag = function () {
        // Codice per discriminare il funzionamento dei nuovi profili timer
        this.projects = JSON.parse(localStorage.getItem('projects'));
        this.projectId = parseInt(localStorage.getItem('selectedProjectId'));
        this.projectSelected = this.projects.find(p => p.id === this.projectId);
        this.legacyTimer = this.projectSelected.legacyTimerProfiles;
    }

    toggleTimerProfileEditModal = (state) => {
        this.setState({
            isTimerProfileEditModalOpen: (state === true) ? true : false
        }, () => {
            if (state === false) {
                // this.fetchData();
            }
        });
    }

    /**
     * Carica i dati del model passato come argomento.
     * @param {*} model
     */
    async fetchData(model) {
        let { profiles, selectedProfile, editingProfile, isEditingProfile } = this.state;
        let precId = selectedProfile[model] && selectedProfile[model].id;

        // impostazione flag timer legacy
        this.getLegacyTimerFlag();

        selectedProfile[model] = null;
        editingProfile[model] = null;
        isEditingProfile[model] = false;
        profiles[model] = [];

        this.setState({
            model,
            loading: true,
            profiles,
            selectedProfile,
            editingProfile,
            isEditingProfile
        });

        try {
            profiles[model] = await loopbackService.list('plant', model);

            if (precId) {
                for (let profile of profiles[model]) {
                    if (profile.id === precId) {
                        selectedProfile[model] = profile;
                        break;
                    }
                }
            }
            /*else {
                selectedProfile[model] = (profiles[model].length > 0 ? profiles[model][0] : null);
            }*/

            this.setState({ loading: false, profiles, selectedProfile });
        } catch (error) {
            this.setState({
                loading: false,
                errors: [error]
            });
        }
    }

    render() {
        let { errors } = this.state;

        return (
            <div className="full-height-no-header navigator-side-menu">
                {this.state.alert}
                <PanelHeader
                    size="sm"
                />

                <div className="content full-height navigator-fullpage">
                    {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}
                    <Row className="full-height ">
                        <Col xs={12} sm={4} md={5} lg={4} xl={3} className="umpi-timer-profile-navigator">
                            {this.renderProfiles()}
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={8} xl={9} className="umpi-timer-profile-details scrollable">
                            {this.renderProfile()}
                        </Col>
                    </Row>
                </div>
                <TimerProfileEdit
                    isOpenModal={this.state.isTimerProfileEditModalOpen}
                    onModalClose={(createdItem) => {
                        this.toggleTimerProfileEditModal(false)
                        this.addProfile(createdItem);
                    }}
                />
            </div>
        );
    }

    renderProfiles() {
        const { t } = this.props;
        const { model, profiles, selectedProfile, editingProfile, isEditingProfile } = this.state;

        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);

        return (
            <div className="side-menu">
                <div className="side-menu-wrapper">
                    {!isGuest &&
                        <Button
                            color="primary"
                            size="sm"
                            className="custom-no-margin umpi-tree-btn-add-new"
                            onClick={() => this.toggleTimerProfileEditModal(true)}>
                            <i className="fa fa-plus" style={{ marginRight: "10px" }} />
                            {t("Add profile")}
                        </Button>
                    }

                    <Card className="full-height noshadow-card overflow-y">
                        <CardHeader className="tree-top navigator-header" style={{ paddingTop: 5 }} >
                            <div className="umpi-tree-header-container">
                                <div className="umpi-tree-go-back-header">
                                    <span className="color-blue-umpi selected-item">{
                                        t("Timer Profiles")
                                    }</span>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className="no-padding tree-and-details-wrapper">
                            <div className="container" style={
                                Array.isArray(profiles[model]) &&
                                    profiles[model].length > 0 ?
                                    { paddingBottom: "100px" } :
                                    { height: "100%", overflowY: "auto" }
                            }>{
                                    Array.isArray(profiles[model]) &&
                                        profiles[model].length > 0 ?
                                        profiles[model].map((item, index) => {
                                            return (
                                                <Row
                                                    className={"umpi-tree-parent-row " + (
                                                        selectedProfile[model] &&
                                                            selectedProfile[model].id === item.id ?
                                                            "row-active" : ""
                                                    )}
                                                    key={"tree-row-" + index}
                                                    onClick={() => {
                                                        selectedProfile[model] = item;
                                                        // isEditingProfile[model] = false;
                                                        // editingProfile[model] = null;
                                                        this.setState({
                                                            selectedProfile,
                                                            isEditingProfile,
                                                            editingProfile
                                                        });
                                                    }}
                                                    style={{ cursor: "pointer" }}>
                                                    <Col className="labels-btn-align" sm={8} md={8} lg={8} xl={8}>
                                                        <div>{item.label}</div>
                                                    </Col>
                                                    <Col className="actions-btn-align" sm={4} md={4} lg={4} xl={4}>
                                                        <div style={{ display: "flex" }}>&nbsp;</div>
                                                        <div className="btn-round umpi-tree-action-btn-go">
                                                            <i className="fa fa-chevron-right" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        }) : (
                                            <div className="umpi-navigator-no-results">
                                                <div className="title">{
                                                    t('There are currently no profiles set up')
                                                }</div>
                                                <div className="descr">{
                                                    t('No profile create new')
                                                }</div>
                                            </div>
                                        )
                                }</div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }

    renderWeekly() {
        return (
            <p>Settimanale</p>
        );
    }

    renderProfile() {
        const { t } = this.props;
        const { model, selectedProfile, editingProfile, isEditingProfile } = this.state;

        if (!selectedProfile[model] && !editingProfile[model]) {
            return (
                <div style={{ padding: "20px" }}>
                    {t("No Profile Found")}
                </div>
            );
        }

        return (
            <DailyProfile
                editingProfile={editingProfile[MODEL_DAILY]}
                selectedProfile={selectedProfile[MODEL_DAILY]}
                isEditingProfile={isEditingProfile[MODEL_DAILY]}
                handleDeletedProfile={this.handleDeletedProfile}
                handleSavedProfile={this.handleSavedProfile}
                handleCancelProfile={this.handleCancelProfile}
                legacyTimer={this.legacyTimer}
            />
            /*<Card className="">
                <CardBody className="no-padding">{
                    (model === MODEL_DAILY) ? (
                        <DailyProfile
                            editingProfile={editingProfile[MODEL_DAILY]}
                            selectedProfile={selectedProfile[MODEL_DAILY]}
                            isEditingProfile={isEditingProfile[MODEL_DAILY]}
                            handleDeletedProfile={this.handleDeletedProfile}
                            handleSavedProfile={this.handleSavedProfile}
                            handleCancelProfile={this.handleCancelProfile}
                        />
                    ) : (
                        <WeeklyProfile
                            dailyProfiles={profiles[MODEL_DAILY]}
                            editingProfile={editingProfile[MODEL_WEEKLY]}
                            selectedProfile={selectedProfile[MODEL_WEEKLY]}
                            isEditingProfile={isEditingProfile[MODEL_WEEKLY]}
                            handleDeletedProfile={this.handleDeletedProfile}
                            handleSavedProfile={this.handleSavedProfile}
                            handleCancelProfile={this.handleCancelProfile}
                        />
                    )
                }</CardBody>
            </Card>*/
        )
    }

    addProfile(modalItem) {
        if (!(modalItem && modalItem.name)) {
            return;
        }

        let { model, editingProfile, isEditingProfile, selectedProfile } = this.state;
        let currentProjectId = utils.getSelectedProject();

        if (model === MODEL_DAILY) {
            if (this.legacyTimer === true) {
                editingProfile[model] = {
                    "label": modalItem.name,
                    "projectId": currentProjectId,
                    //"transition1": { mode: "sunset", timer: 0, outValue: 100, enable: true, type: "dimmering" },
                    //"transition2": { mode: "absolute", timer: 0, outValue: 100, enable: true, type: "dimmering" },
                    //"transition3": { mode: "absolute", timer: 0, outValue: 100, enable: true, type: "dimmering" },
                    //"transition4": { mode: "absolute", timer: 0, outValue: 100, enable: true, type: "dimmering" },
                    //"transition5": { mode: "sunrise", timer: 0, outValue: 0, enable: true, type: "dimmering" },
                }
            }
            else {
                editingProfile[model] = {
                    "label": modalItem.name,
                    "projectId": currentProjectId,
                    "dailyTransitions": [
                        //{ "id": 1, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 2, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 3, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 4, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 5, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 6, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 7, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 8, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 9, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 10, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 11, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                        //{ "id": 12, "enable": false, "mode": "absolute", "type": "dimmering", "timer": 0, "outValue": 0 },
                    ]
                }
            }
        } /*else if (model === MODEL_WEEKLY) {
            editingProfile[model] = {
                "label": modalItem.name,
                "projectId": currentProjectId,
                "monFriProfileId": 0,
                "satProfileId": 0,
                "sunProfileId": 0
            }
        }*/

        isEditingProfile[model] = true;
        selectedProfile[model] = null;

        this.setState({
            isEditingProfile,
            editingProfile,
            selectedProfile
        }, async () => {
            const savedProfile = await loopbackService.edit('plant', 'timerprofiledaily', editingProfile[model], "PUT");
            this.handleSavedProfile(savedProfile);
        });
    }

    handleCancelProfile() {
        const { model, editingProfile, isEditingProfile } = this.state;
        editingProfile[model] = null;
        isEditingProfile[model] = null;
        this.setState({ editingProfile, isEditingProfile })
    }

    handleDeletedProfile() {
        let { model, profiles, selectedProfile } = this.state;
        let prev = null, ret = [];

        for (let profile of profiles[model]) {
            if (profile === selectedProfile[model]) {
                if (ret.length > 0) prev = ret[ret.length - 1];
            }
            else {
                ret.push(profile);
            }
        }

        if (prev) {
            selectedProfile[model] = prev;
        }
        else if (ret.length > 0) {
            selectedProfile[model] = ret[0];
        }
        else {
            selectedProfile[model] = null;
        }

        profiles[model] = ret;
        this.setState({ profiles, selectedProfile });
    }

    handleSavedProfile(savedProfile) {
        let { model, profiles, selectedProfile, isEditingProfile, editingProfile } = this.state;
        if (!selectedProfile[model]) {
            profiles[model].unshift(savedProfile);
        } else {
            let ret = [];
            for (let profile of profiles[model]) {
                if (profile === selectedProfile[model]) {
                    ret.push(savedProfile);
                }
                else {
                    ret.push(profile);
                }
            }
            profiles[model] = ret;
        }

        selectedProfile[model] = savedProfile;
        isEditingProfile[model] = false;
        editingProfile[model] = null;

        this.setState({
            isEditingProfile,
            editingProfile,
            selectedProfile,
            saving: false,
            profiles
        });

    }

}

function mapStateToProps() {
    return {};
}

const TimerProfiles = withTranslation()(connect(mapStateToProps)(TimerProfilesComponent));
export default TimerProfiles;

import { userConstants } from '../constants';
import { userService } from '../services';
import { history } from '../helpers';
import commonActionCreatorHelper from './commonActionCreatorHelper';

export const userActions = {
    edit,
    add,
    getAll,
    getById,
    /**
     * Questo helper aggiunge le action
     *
     * filterCount,
     * filter,
     * delete
     */
    ...(commonActionCreatorHelper.createCrudActions({
        customizeFilters: function (filters) {
            // if (permissions.grant( permissionConstants.SHOW_ONLY_OWN_USERS) ) {
            //     const user = User.current()
            //     const userId = user.id;
            //     const nestAND = [
            //         { where: {} },
            //         { and: [] }
            //     ];
            //     // Annido filters.
            //     utils.nestedObjectConstructValue(filters, nestAND);

            //     // Azzerro le condizioni ogni volta per ricostruire la query.
            //     filters.where.and = [];

            //     // Metto in OR le condizioni della AND esteriore.
            //     filters.where.and.push({
            //         or: [
            //                 {
            //                     and: [{
            //                         or: [{ userRoleId: roleConstants.ADMIN  }, { userRoleId: roleConstants.GUEST }]
            //                     }]
            //                 },
            //                 // OR l'utente è l'utente correntemente loggato
            //                 { id: userId }
            //         ]
            //     });
            // }
        },
        service: userService,

        filterAllNoLimitRequestActionCreator: function request(filters) { return { type: userConstants.FILTER_ALL_NO_LIMIT_REQUEST, filters } },
        filterAllNoLimitSuccessActionCreator: function success(users) { return { type: userConstants.FILTER_ALL_NO_LIMIT_SUCCESS, users } },
        filterAllNoLimitFailureActionCreator: function failure(error) { return { type: userConstants.FILTER_ALL_NO_LIMIT_FAILURE, error } },

        filterCountRequestActionCreator: function request(filters) { return { type: userConstants.FILTER_COUNT_REQUEST, filters } },
        filterCountSuccessActionCreator: function success(count, limit) { return { type: userConstants.FILTER_COUNT_SUCCESS, count, limit } },
        filterCountFailureActionCreator: function failure(error, limit) { return { type: userConstants.FILTER_COUNT_FAILURE, error, limit } },

        filterRequestActionCreator: function request(filters) { return { type: userConstants.FILTER_REQUEST, filters } },
        filterSuccessActionCreator: function success(users) { return { type: userConstants.FILTER_SUCCESS, users } },
        filterFailureActionCreator: function failure(error, limit) { return { type: userConstants.FILTER_FAILURE, error, limit } },

        deleteRequestActionCreator: function request(id) { return { type: userConstants.DELETE_REQUEST, id } },
        deleteSuccessActionCreator: function success(id) { return { type: userConstants.DELETE_SUCCESS, id } },
        deleteFailureActionCreator: function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } },

    })),
};

function getById(id) {
    return async (dispatch) => {
        dispatch(request(id));
        try {
            let user = await userService.getById(id);
            dispatch(success(user));
        } catch (error) {
            dispatch(failure(error));
        };
    };
    function request(id) { return { type: userConstants.GETBYID_REQUEST, id } }
    function success(user) { return { type: userConstants.GETBYID_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETBYID_FAILURE, error } }
}

function getAll() {
    return async (dispatch) => {
        dispatch(request());
        try {
            let users = await userService.getAll();
            localStorage.setItem('users', JSON.stringify(users));
            dispatch(success(users));
        } catch (error) {
            dispatch(failure(error));
            if (error === 'Unauthorized') {
                history.push('/pages/login-page');
            }
        }
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function add(user, callback = null) {
    return async (dispatch) => {
        dispatch(request(user));
        let response = null;

        try {
            let result = await userService.add(user);

            response = success(result);

            dispatch(this.filterCount({
                filters: null,
                next: (filters) => {
                    let skip = filters.skip;
                    dispatch(this.filter({ ...filters, skip: skip }));
                }
            }));
        }
        catch (error) {
            response = failure(error);
        }
        finally {
            dispatch(response);
            if (typeof callback === "function") {
                callback(response);
            }
        }
    };
    function request(user) { return { type: userConstants.ADD_REQUEST, user } }
    function success(user) { return { type: userConstants.ADD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_FAILURE, error } }
}

function edit(user, goBack = true, callback = null) {
    return async (dispatch) => {
        dispatch(request(user));
        let response = null;

        try {
            await userService.edit(user);

            response = success(user);

            if (goBack) {
                if (history.length >= 1) {
                    history.go(-1)
                }
                else {
                    history.push('/users')
                }
            }
        }
        catch (error) {
            response = failure(error);
        }
        finally {
            dispatch(response);
            if (typeof callback === "function") {
                callback(response);
            }
        }
    };
    function request(user) { return { type: userConstants.EDIT_REQUEST, user } }
    function success(user) { return { type: userConstants.EDIT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.EDIT_FAILURE, error } }
}

import { projectConstants } from '../constants';

export function projects(state = {}, action) {
  switch (action.type) {


    // GETALL
    case projectConstants.GETALL_REQUEST:
      return { ...projectsCommonReducer({
        loading: true
      })};
    case projectConstants.GETALL_SUCCESS:
      return { ...projectsCommonReducer({
        // ...state,
        // loading: false,
        items: action.projects
      })};
    case projectConstants.GETALL_FAILURE:
      return { ...projectsCommonReducer({
        error: action.error
      })};

    // GETBYID
    case projectConstants.GETBYID_REQUEST:
      return { ...projectsCommonReducer({
        ...state,
        // loading: true
      })};
    case projectConstants.GETBYID_SUCCESS:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        project: action.project
      })};
    case projectConstants.GETBYID_FAILURE:
      return { ...projectsCommonReducer({
        error: action.error
      })};

    // EDIT
    case projectConstants.EDIT_REQUEST:
      return { ...projectsCommonReducer({
          ...state,
          loading: true
        })};
    case projectConstants.EDIT_SUCCESS:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
          project: action.project,
      }),
      // Solo in questo reducer qui valorizzo la property a true
      project_just_saved: "project_just_saved" in action.project ? action.project.project_just_saved : true
    };
    case projectConstants.EDIT_FAILURE:
      return { ...projectsCommonReducer({
        error: action.error
      })};

    // DELETE
    case projectConstants.DELETE_REQUEST:
      // add 'deleting:true' property to entity being deleted
      return { ...projectsCommonReducer({
        ...state,
        items: state.items.map(project =>
          project.id === action.id
            ? { ...project, deleting: true }
            : project
        )
      })};
    case projectConstants.DELETE_SUCCESS:
      // remove deleted entity from state
      return { ...projectsCommonReducer({
        items: state.items.filter(project => project.id !== action.id),
        deletedId: action.id
      })};
    case projectConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to entity 
      return { ...projectsCommonReducer({
        ...state,
        items: state.items.map(project => {
          if (project.id === action.id) {
            // make copy of entity without 'deleting:true' property
            const { deleting, ...projectCopy } = project;
            // return copy of entity with 'deleteError:[error]' property
            return { ...projectCopy, deleteError: action.error };
          }

          return project;
        })
      })};

    // FILTER COUNT
    case projectConstants.FILTER_COUNT_REQUEST:
      return { ...projectsCommonReducer({
        // ...state,
        loading: true,
        filters: action.filters
      })}
    case projectConstants.FILTER_COUNT_SUCCESS:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        filtered_count: action.count.count,
        limit: action.limit,
        pages: Math.ceil(action.count.count / action.limit)
      })}
    case projectConstants.FILTER_COUNT_FAILURE:
      return { ...projectsCommonReducer({
        // ...state,
        loading: false,
        error: action.error,
        limit: action.limit
      })}

    // FILTER
    case projectConstants.FILTER_REQUEST:
      return { ...projectsCommonReducer({
        ...state,
        loading: true
      })}
    case projectConstants.FILTER_SUCCESS:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        items: action.projects
      })}
    case projectConstants.FILTER_FAILURE:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        error: action.error
      })}

    // FILTER ALL NO LIMIT
    case projectConstants.FILTER_ALL_NO_LIMIT_REQUEST:
      return { ...projectsCommonReducer({
        ...state,
        loading: true
      })}
    case projectConstants.FILTER_ALL_NO_LIMIT_SUCCESS:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        items: action.projects,
        items_all_no_limit: action.projects,
      })}
    case projectConstants.FILTER_ALL_NO_LIMIT_FAILURE:
      return { ...projectsCommonReducer({
        ...state,
        loading: false,
        error: action.error
      })}

    // DEFAULT
    default:
      return { ...projectsCommonReducer(state) }
  }
}

function projectsCommonReducer(data) {
  return {
    ...data,
    project_just_saved: false
  };
}
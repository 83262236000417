import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, UncontrolledTooltip, Collapse } from "reactstrap";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Button } from "components";
import { permissions } from '../../helpers';
import User from '../../entities/User';
import { withTranslation } from 'react-i18next';
import { userService } from '../../services';

import DefaultAvatar from '../../assets/img/umpi/avatar-default.png';
import NavOpenBars from 'assets/svg/nav-bars.svg';
import NavCloseBars from 'assets/svg/nav-close.svg';
import logo from "assets/img/logo-minosx.png";
import xperienceBrand from "assets/img/xperience-brand-125.png";

var ps;

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openPages: this.activeRoute("/pages") !== "" ? true : false,
            openSettings: false,
            openArchives: false,
            openAvatar: false,
            isSidebarRegular: false,
            user: null
        };

        this.activeRoute.bind(this);
        this.minimizeSidebar = this.minimizeSidebar.bind(this);
        this.normalizeAccordion = this.normalizeAccordion.bind(this);
        this.normalizeUser = this.normalizeUser.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    minimizeSidebar() {
        document.body.classList.toggle("sidebar-mini");
        this.setState({
            isSidebarRegular: !this.state.isSidebarRegular,
            openSettings: false,
            openArchives: false,
            openAvatar: false
        });
    }

    normalizeAccordion(keyName, keyValue) {
        switch (keyName) {

            case "openSettings": return {
                openSettings: keyValue,
                openArchives: false,
                openAvatar: false
            };

            case "openArchives": return {
                openSettings: false,
                openArchives: keyValue,
                openAvatar: false
            };

            case "openAvatar": return {
                openSettings: false,
                openArchives: false,
                openAvatar: keyValue
            };

            default: return {
                openSettings: false,
                openArchives: false,
                openAvatar: false
            };

        }
    }

    async normalizeUser() {
        const p_user = User.current();

        if (p_user) {
            // prepare image storage
            p_user["image"] = null;

            try {
                // try call
                let image = await userService.getImageByUserId(p_user.id);

                // Check and save
                if (image && image.base64) {
                    p_user["image"] = image.base64;
                }
            } catch (exImg) {
                console.error(exImg);
            }
        }

        this.setState({ user: (p_user ? p_user : null) })
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebar, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }

        this.normalizeUser();
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {
        const { t } = this.props;
        const { user } = this.state;

        return (
            <div>
                <NotificationAlert ref="notificationAlert" />
                <div className="sidebar" data-color="blue">
                    <div className="logo">
                        <div className="navbar-minimize visible-on-sidebar-mini btn-on-top-sidebar">
                            <Button
                                simple
                                neutral
                                icon
                                round
                                id="minimizeSidebar"
                                onClick={this.minimizeSidebar}>
                                <img src={NavOpenBars} alt="" />
                            </Button>
                        </div>
                        <br />
                        <a
                            href="https://www.umpi.it/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="simple-text logo-mini">
                            <div className="logo-img">
                                <img src={logo} alt="minosx-logo" />
                            </div>
                        </a>
                        <a
                            href="/dashboard"
                            className={"simple-text logo-normal visible-on-sidebar-regular " + (this.state.isSidebarRegular ? "visible-logo" : "")}>
                            <img src={xperienceBrand} alt="" />
                        </a>
                        <div className="navbar-minimize visible-on-sidebar-regular">
                            <Button
                                simple
                                neutral
                                icon
                                round
                                id="minimizeSidebar"
                                onClick={this.minimizeSidebar}>
                                <img src={NavCloseBars} alt="" />
                            </Button>
                        </div>
                    </div>

                    <div className={"sidebar-wrapper" + (this.state.isSidebarRegular ? " normalize-height" : "")} ref="sidebar">
                        {/* COMMON ROUTES */}
                        <Nav>
                            {this.props.routes.map((prop, key) => {
                                if (prop.invisible) {
                                    return null;
                                }

                                if (prop.permission && !permissions.grant(prop.permission)) {
                                    return null;
                                }

                                if (prop.redirect) {
                                    return null;
                                }

                                if (prop.collapse) {
                                    let st = this.normalizeAccordion(prop["state"], !this.state[prop.state]);

                                    return (
                                        <li className={this.activeRoute(prop.path)} key={key} id={"tp-" + key}>
                                            {/* eslint-disable-next-line */}
                                            <a
                                                href="#"
                                                data-toggle="collapse"
                                                aria-expanded={this.state[prop.state]}
                                                onClick={(e) => {
                                                    if (!this.state.isSidebarRegular) {
                                                        this.minimizeSidebar();
                                                    }
                                                    this.setState(st);
                                                }}>
                                                <i className={"now-ui-icons " + prop.icon} />
                                                <p>
                                                    {t(prop.name)}
                                                    <b className="caret" />
                                                </p>
                                            </a>
                                            <Collapse isOpen={this.state[prop.state]}>
                                                <ul className="nav">
                                                    {prop.views.map((prop, key) => {
                                                        if (prop.redirect) {
                                                            return null;
                                                        }
                                                        if (prop.invisible) {
                                                            return null;
                                                        }
                                                        if (prop.permission && !permissions.grant(prop.permission)) {
                                                            return null;
                                                        }

                                                        return (
                                                            <li
                                                                className={this.activeRoute(prop.path)}
                                                                key={key}>
                                                                <NavLink
                                                                    to={prop.path}
                                                                    activeClassName="active">
                                                                    <span className="sidebar-mini-icon">
                                                                        {prop.mini}
                                                                    </span>
                                                                    <span className="sidebar-normal">
                                                                        {t(prop.name)}
                                                                    </span>
                                                                </NavLink>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Collapse>
                                            {!this.state.isSidebarRegular ?
                                                <UncontrolledTooltip placement={"right"} target={"tp-" + key} delay={0} >
                                                    {t(prop.name)}
                                                </UncontrolledTooltip>
                                                : null}
                                        </li>
                                    );
                                }
                                return (
                                    <li className={this.activeRoute(prop.path)} key={key} id={"tp-" + key}>
                                        <NavLink
                                            to={prop.path}
                                            className="nav-link"
                                            activeClassName="active">
                                            <i className={"now-ui-icons " + prop.icon} />
                                            <p>{t(prop.name)}</p>
                                        </NavLink>
                                        {!this.state.isSidebarRegular ?
                                            <UncontrolledTooltip placement={"right"} target={"tp-" + key} delay={0} >
                                                {t(prop.name)}
                                            </UncontrolledTooltip>
                                            : null}
                                    </li>
                                );
                            })}
                        </Nav>
                        {/* USER NAV */}
                        <Nav className="profile-user">
                            <li>
                                <a
                                    href="# "
                                    className="user-nav"
                                    data-toggle="collapse"
                                    aria-expanded={this.state.openAvatar}
                                    onClick={(e) => {
                                        if (!this.state.isSidebarRegular) {
                                            this.minimizeSidebar();
                                        }
                                        this.setState(this.normalizeAccordion("openAvatar", !this.state.openAvatar));
                                    }}>
                                    <i className="avatar">
                                        <img src={(user && user.image) ? user.image : DefaultAvatar} alt="Avatar" />
                                    </i>
                                    <p>
                                        {
                                            ((user && user.name) ? user.name : "---") +
                                            ' ' +
                                            ((user && user.surname) ? user.surname : "---")
                                        }
                                        <b className="caret" />
                                    </p>
                                </a>
                                <Collapse isOpen={this.state.openAvatar}>
                                    <Nav>
                                        {user && <li
                                            className={this.activeRoute("profile")}
                                            key="profile">
                                            <NavLink
                                                className="nav-link custom-nav-link"
                                                to={"/users/profile/" + user.id}
                                                activeClassName="active">
                                                <span className="sidebar-mini-icon">
                                                    P
                                                </span>
                                                <span className="sidebar-normal">
                                                    {t("Profilo")}
                                                </span>
                                            </NavLink>
                                        </li>}
                                        <li
                                            className=""
                                            key="logout">
                                            <NavLink
                                                className="nav-link custom-nav-link"
                                                to={"/pages/login-page"}
                                                activeClassName="active"
                                                onClick={() => {
                                                    if (!document.body.classList.contains("sidebar-mini")) {
                                                        document.body.classList.add("sidebar-mini");
                                                    }
                                                }}>
                                                <span className="sidebar-mini-icon">
                                                    <i className="now-ui-icons arrows-1_share-66" />
                                                </span>
                                                <span className="sidebar-normal">
                                                    {t("Esci")}
                                                </span>
                                            </NavLink>
                                        </li>
                                    </Nav>
                                </Collapse>
                            </li>
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar = withTranslation()(Sidebar);

import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import ChartDashboard from '../../components/ChartDashboard/ChartDashboard.jsx';

import ErrorModal from "../Components/ErrorModal"
import { loopbackService } from '../../services';
var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

//const COLORS = ["#FFBB28", "#0088FE", "lightgray", "#FF8042"];
//const RADIAN = Math.PI / 180;
/*const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <g>
            {index !== 0 ? null :
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#000'}>
                    PIPPO
                </text>
            }
            <text x={x} y={y} fill={index === 0 ? '#00' : '#0088FE'} textAnchor='middle' dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        </g>
    );
};*/

class Trend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
        }
    }

    componentWillMount() {
        this.fetchData();
    }

    async fetchData() {
        try {
            const projectId = localStorage.getItem('selectedProjectId');
            let { item, devices } = this.props;
            devices = devices.instances;
            let macAddress = "", periferalCode = "";

            for (let device of devices) {
                if (item.type === 0) {
                    if (device.cabinetId === item.id) {
                        macAddress = device.macAddress;
                        break;
                    }
                }

                else if (item.type === 1 || item.type === 3 || item.type === 4) {
                    // if (device.cabinetId === item.cabinetId) {
                    //   macAddress = device.macAddress;
                    // }
                    if (device.lightPointId === item.id) {
                        macAddress = device.macAddress;
                        periferalCode = ('0000' + device.addressCode.toString(16).toUpperCase()).slice(-4);
                        break;
                    }
                }
            }

            let model = "", parameters = { projectId, macAddress };

            if (item.type === 0) {
                model = 'cabinets';
                // macAddress del device padre e periferalCode?
                parameters = {
                    ...parameters,
                    macAddress
                }
            }
            else if (item.type === 1 || item.type === 3 || item.type === 4) {
                model = 'lightpoints';
                // macAddress del device padre e periferalCode?
                parameters = {
                    ...parameters,
                    periferalCode
                }
            }

            let language = "it";

            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                language = user.language;
            }

            let url = await loopbackService.invoke('plant', model, 'getdashboardurl', null, language);
            const height = (window.innerHeight * 85 / 100) + "px";
            const width = this.refs.dashboardContainer.clientWidth + "px";

            if (url.error) {
                this.setState({ errors: [url.error] });
                return;
            }

            var options = {
                url: url.embedUrl,
                container: this.refs.dashboardContainer,
                parameters,
                scrolling: "auto",
                height,
                width
            };

            QuickSightEmbedding.embedDashboard(options);
        } catch (error) {
            this.setState({ errors: [error] })
        }
    }

    render() {
        const { t, item, handleTrendClose } = this.props;
        const { error } = this.state;
        return (
            <Modal size="lg" isOpen={true} className="modal-umpi-container fullscreen-modal-dialog Trend">
                <ModalHeader toggle={handleTrendClose}>
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {t("Misure") + " - " + (item.type === 0 ? t('Cabinet') : t('SYRAS')) + " " + item.label}
                    </div>
                </ModalHeader>
                <ModalBody >
                    <div ref="dashboardContainer" style={{ margin: 0, padding: 0, width: "100%" }} >
                        <ChartDashboard lightpoints={this.props.lightpoints} cabinets={this.props.cabinets} item={item} />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default Trend = withTranslation()(connect(mapStateToProps)(Trend));

import { connect } from 'react-redux';
import React from "react";
import { Row, Col } from "reactstrap";
import { PanelHeader } from "components";
import { withTranslation } from 'react-i18next';
import ErrorModal from "../Components/ErrorModal"
import { dashboardService } from '../../services';
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import { utils } from '../../helpers';
import { chartConstants } from '../../constants';
import Chart from '../../components/ChartDashboard/Chart';

class StatsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loading: true,
            item: {
                id: '',
                name: '',
                description: '',
                databaseName: '',
                geometry: {
                    lat: null,
                    lng: null
                },
                zoom: 11
            },
            annual_stats: {}
        }

        this.handleMapMounted = this.handleMapMounted.bind(this)
        this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this)

        selectedProjectChangeListeners.push(() => {
            window.location.reload();
        });
    }

    makeMarkers(lightpoints) {
        let markers = [];
        let generalMarkerProps = {
            icon: {
                path: 'M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11 M 4,12 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0z',
                fillColor: '#d0a619',
                fillOpacity: 1,
                scale: .7,
            },
            strokeColor: '#094079',
            strokeWeight: 0,
            visible: true,
        };
        lightpoints.forEach(point => {
            markers.push({ ...generalMarkerProps, position: point.geometry, label: point.label })
        });
        return markers;
    }

    async fetchData() {
        try {
            // let language = "it";
            let projectId = localStorage.getItem('selectedProjectId');
            // let user = JSON.parse(localStorage.getItem('user'));

            // if (user) {
            //     language = user.language;
            // }

            // Get annual stats
            const annualStats = await dashboardService.getAnnualRecapStats(projectId);
            let statusCounterObj = {};

            const project = utils.getSelectedProject();
            // let lightpoints = await dashboardService.getLightpoints(projectId); // OLD???
            const lightpoints = await dashboardService.getLightpointsSyraData(projectId);
            const lightpointsGeom = lightpoints.syras.map(lp => {
                if (!statusCounterObj.hasOwnProperty(lp.status)) {
                    statusCounterObj[lp.status] = 0;
                }

                statusCounterObj[lp.status] += 1;

                return {
                    ...lp,
                    lat: lp.geometry ? lp.geometry.lat : null,
                    lng: lp.geometry ? lp.geometry.lng : null
                }
            });

            let markers = this.makeMarkers(lightpointsGeom);

            this.setState({
                project: project,
                annual_stats: annualStats,
                lightpoints: lightpointsGeom,
                lightpointsCount: lightpointsGeom.length.toString(),
                lightpointsPower: [lightpoints.activePower],
                lightpointsStatus: (() => {
                    const keys = Object.keys(statusCounterObj);
                    return keys.map((key) => {
                        return {
                            "name": key + " (" +
                                (Math.floor((
                                    100 * statusCounterObj[key]
                                ) / lightpointsGeom.length)
                            ) + "%)",
                            "value": statusCounterObj[key],
                            "color": (() => {
                                switch (key.toLowerCase()) {
                                    case "ready": return "#65C87A";
                                    case "not_connected": return "#D8DEE5";
                                    case "no_current": return "#E04B4B";
                                    default: return "#F3A93B";
                                }
                            })()
                        }
                    });
                })(),
                markers: markers,
                loading: false,
            })

            //Il problema è che geometry è null e quindi non posso disegnarli sulla mappa
            // let devices = await dashboardService.getDevices(projectId);
        } catch (error) {
            this.setState({
                loading: false,
                // errors: [error]
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    handleMapMounted(mountedMap) {
        this.setState({
            map: mountedMap,
        })
    }

    handleMapBoundsChanged() {
        // const user = User.current()
        // if (User.isWithinGroup(user, roleConstants.CAT)) {
        //     return;
        // }
        const { map } = this.state;
        const coordinates = this.getMapCoordinatesObj(map);
        let item = this.state.item;
        item.zoom = coordinates.zoom;
        item.geometry = coordinates.center;
        this.setState({
            item,
            emptyCoordinatesError: false
        });
    }

    getMapCoordinatesObj(map) {
        const bounds = map.getBounds();
        let coordinatesObj = bounds.toJSON();
        let coordinates = JSON.parse(JSON.stringify(coordinatesObj));
        coordinates.center = map.getCenter().toJSON();
        coordinates.zoom = map.getZoom();
        return coordinates;
    }

    render() {
        const { loading, errors, annual_stats } = this.state
        const { t } = this.props;

        const annual_stats_recap = (() => {
            let tmpLegend = {};
            let tmpData   = [];

            if (annual_stats.hasOwnProperty("consumption")) {
                Object.keys(annual_stats.consumption).map((key, index) => {
                    tmpLegend["consumption_" + index] = t('kwh_measured') + " " + key;

                    annual_stats.consumption[key].map((data, sindex) => {
                        if (!tmpData[sindex]) {
                            tmpData[sindex] = {
                                month: data.month
                            }
                        }

                        tmpData[sindex]["consumption_" + index] = (
                            Math.round(
                                data.value * 10
                            ) / 10
                        );
                        return true;
                    });

                    return true;
                });
            }

            return {
                legends: tmpLegend,
                data:    tmpData
            };
        })();

        const annual_stats_delta = (() => {
            let tmpLegend = {};
            let tmpData   = [];

            if (Array.isArray(annual_stats.deltaConsumption)) {
                tmpLegend["deltaConsumption"] = t('delta_energy');

                annual_stats.deltaConsumption.map((data, sindex) => {
                    if (!tmpData[sindex]) {
                        tmpData[sindex] = {
                            month: data.month
                        }
                    }

                    tmpData[sindex]["deltaConsumption"] = (
                        Math.round(
                            data.value * 10
                        ) / 10
                    );

                    return true;
                });
            }

            return {
                legends: tmpLegend,
                data:    tmpData
            };
        })();

        return (
            <div className='Stats'>
                {this.state.alert}
                <PanelHeader size="sm" />
                {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}

                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Statistics")}</h2>
                        </Col>
                    </Row>
                    <Row style={{ margin: "0px -5px" }}>
                        <Col>
                            <Row>
                                <div className="col col-6">
                                    <Row>
                                        <Chart type={chartConstants.BASIC_CHART}
                                            data={this.state.lightpointsCount}
                                            width='50%'
                                            title={t('Totale punti luce')}
                                            loading={loading} />
                                        <Chart type={chartConstants.BASIC_CHART}
                                            data={this.state.lightpointsPower}
                                            width='50%'
                                            yAxisUnit={'W'}
                                            title={t('Potenza attiva tot.')}
                                            loading={loading} />
                                        <Chart type={chartConstants.PIE_CHART_FULL}
                                            data={this.state.lightpointsStatus}
                                            label
                                            width='100%'
                                            // yAxisUnit={'W'}
                                            title={t('Stato syra')}
                                            loading={loading}>
                                        </Chart>
                                    </Row>
                                </div>
                                <Col className="col col-6 umpi-full-height-map">
                                    <Row className='umpi-annual-stats'>
                                        <Chart
                                            type={chartConstants.BAR_CHART_STATS_ANNUAL}
                                            data={annual_stats_recap.data}
                                            width='100%'
                                            title={t('Consumi Annuali')}
                                            loading={loading}
                                            metrics={Object.keys(annual_stats_recap.legends)}
                                            legendLabels={annual_stats_recap.legends}
                                            colors={['#65c87a', '#f4a93b']}
                                        />
                                        <Chart
                                            type={chartConstants.BAR_CHART_STATS_ANNUAL_DELTA}
                                            data={annual_stats_delta.data}
                                            width='100%'
                                            title={t('Delta Consumi Annuali')}
                                            loading={loading}
                                            metrics={Object.keys(annual_stats_delta.legends)}
                                            legendLabels={annual_stats_delta.legends}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12} style={{ padding: 0 }}>
                                    <Chart type={chartConstants.TABLE_STATUS_COLOR}
                                        data={this.state.lightpoints}
                                        width='100%'
                                        title={t('Elenco Syra gestiti')}
                                        loading={loading}
                                        // tableID={inputType}
                                        // selectedCode={this.state.selectedCode[inputType]}
                                        headers={[
                                            {
                                                header: t("charts.table.syra.label"),
                                                field: "label",
                                                // width: '30%'
                                            },
                                            {
                                                header: t("charts.table.syra.quadro"),
                                                field: "cabinetLabel",
                                                // width: '30%'
                                            },
                                            {
                                                header: t("charts.table.syra.city"),
                                                field: "city",
                                                // width: '30%'
                                            },
                                            {
                                                header: t("charts.table.syra.address"),
                                                field: "address",
                                                // width: '30%'
                                            },
                                            {
                                                header: t("charts.table.syra.status"),
                                                field: "status",
                                                // width: '30%'
                                            },
                                            {
                                                header: t("charts.table.syra.lat"),
                                                field: "lat",
                                                // width: '10%'
                                            },
                                            {
                                                header: t("charts.table.syra.lng"),
                                                field: "lng",
                                                // width: '10%'
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const { users, authentication } = state;
    return {
        users,
        loggedinUser: authentication.user
    };
}

const Stats = withTranslation()(connect(mapStateToProps)(StatsComponent));
export default Stats;

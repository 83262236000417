const DRAW_MODE = {
    NONE: 0,
    POLYGON: 1,
    RECTANGLE: 2
}

const SHOW_MODE = {
    GROUPS: 1,
    STARTING_POINTS: 2
}

const GroupsColorPalette = [
    "#6c17bc",
    "#ad0b1a",
    "#448241",
    "#a2c0a2",
    "#79e5f9",
    "#cf984c",
    "#7fe007",
    "#8e37f2",
    "#ada0cd",
    "#c272b6",
    "#d829ff",
    "#6c17bc",
    "#ad0b1a",
    "#448241",
    "#a2c0a2",
    "#79e5f9",
    "#cf984c",
    "#64febe",
    "#3277fe",
    "#0731d8",
    "#f1be7d",
    "#63a36d",
    "#e7290e",
    "#4b5ecd",
    "#4b5d6c",
    "#255dca",
    "#481324",
    "#98a973",
    "#84e96c",
    "#0ab6f3",
    "#d30aa5",
    "#4c4227"
];

const StartingPointsColorPalette = [
    "#64febe",
    "#3277fe",
    "#0731d8",
    "#f1be7d",
    "#63a36d",
    "#e7290e",
    "#4b5ecd",
    "#4b5d6c",
    "#255dca",
    "#481324",
    "#98a973",
    "#84e96c",
    "#0ab6f3",
    "#d30aa5",
    "#4c4227",
    "#12a52f",
    "#f24895",
    "#db43e8",
    "#1f7efa",
    "#33aa03",
    "#880858",
    "#7fe007",
    "#8e37f2",
    "#ada0cd",
    "#c272b6",
    "#d829ff",
    "#6c17bc",
    "#ad0b1a",
    "#448241",
    "#a2c0a2",
    "#79e5f9",
    "#cf984c",
]

export {
    DRAW_MODE,
    SHOW_MODE,
    GroupsColorPalette,
    StartingPointsColorPalette
};

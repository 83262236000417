import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Button, Documents } from "components";
import { loopbackActions } from '../../actions';
import { loopbackService } from '../../services';
import ErrorModal from "../Components/ErrorModal";
import { withTranslation } from 'react-i18next';
import { roleConstants } from '../../constants';
import User from '../../entities/User';

class DriverEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentDriverId: 0,
            mediaStorage: [],
            mediaPreview: null,
            driver: {
                id: '',
                type: '',
                producer: '',
                model: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuemnts = this.handleDeleteDocuemnts.bind(this);
    }

    componentWillMount() {
        this.loadDriverData(this.props.driverId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentDriverId !== nextProps.driverId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadDriverData(nextProps.driverId);
            });
        }

        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }/*
        else if (nextProps.editEnd) {
            history.push('/drivers');
        }*/
        else if (nextProps.driver) {
            this.setState({
                driver: nextProps.driver
            });
        }
    }

    handleUpdateDocuments(p_documents) {
        let driver = this.state.driver;
        driver.documents = p_documents;
        this.setState({ driver });
    }

    async handleDeleteDocuemnts(name, refId) {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Delete doc media
        await loopbackService.deleteProjectDocumentToModel(
            name,
            selectedProjectId,
            "DRIVERS",
            refId
        );

        // Refresh
        this.loadDocuments(refId);
    }

    loadDocuments = async (driverId) => {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Create doc media
        const docs = await loopbackService.listProjectDocumentFromModel(
            selectedProjectId,
            "DRIVERS",
            driverId
        );

        // Save to storage
        this.setState({
            mediaStorage: Array.isArray(docs) ? docs : []
        });
    }

    loadDriverData = (driverId) => {
        this.setState({
            addMode: driverId === 0,
            currentDriverId: driverId,
            mediaStorage: [],
            mediaPreview: null,
            driver: {
                id: '',
                type: '',
                producer: '',
                model: '',
                documents: {}
            },
            submitted: false,
            error: null
        }, () => {
            if (driverId > 0) {
                this.props.dispatch(loopbackActions.get('plant', 'drivers', driverId));
                this.loadDocuments(driverId);
            }
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { driver } = this.state;
        driver[name] = value;
        this.setState({ driver });
    }

    handleSubmit(e) {
        e.preventDefault();
        let { driver, addMode } = this.state;
        const { dispatch } = this.props;

        this.setState({ submitted: true });
        driver.projectId = localStorage.getItem('selectedProjectId');

        if (addMode) {
            delete driver.id;
        }

        dispatch(loopbackActions.edit(
            'plant',
            'drivers',
            driver,
            null,
            async (response) => {
                // Get project active
                let selectedProjectId = localStorage.getItem('selectedProjectId');

                // Check for documents
                if (response &&
                    response.instance &&
                    response.instance.id > 0 &&
                    response.instance.documents &&
                    selectedProjectId > 0) {
                    // Get keys
                    let keys = Object.keys(response.instance.documents);

                    // Check for docs into storage
                    for (let i = 0; i < keys.length; i++) {
                        // Get current media
                        let media = response.instance.documents[keys[i]];

                        // Create doc media
                        await loopbackService.pushProjectDocumentToModel(
                            selectedProjectId,
                            {
                                "filename": media.name,
                                "type":     "DRIVERS",
                                "deviceId": response.instance.id,
                                "file":     media.file
                            }
                        );
                    }
                }

                // Close modal
                this.closeModal();
            }
        ));
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentDriverId: 0,
            mediaStorage: [],
            mediaPreview: null,
            driver: {
                id: '',
                type: '',
                producer: '',
                model: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    checkPreSubmit() {
        const { driver } = this.state;

        return (!(
            driver &&
            driver.type
        )) ? true : false;
    }

    render() {
        const { t } = this.props;
        const { addMode, error, driver, mediaStorage, mediaPreview, currentDriverId } = this.state;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);

        return (
            <div>
                {error && <ErrorModal
                    error={error}
                    onClickHandler={() => {
                        this.setState({ error: null });
                    }}
                />}
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi driver") : t("Modifica driver"))}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Tipo")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={driver.type}
                                                    name='type'
                                                    onChange={this.handleChange}
                                                    style={(!(driver && driver.type && driver.type.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Produttore")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={driver.producer}
                                                    name='producer'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Modello")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={driver.model}
                                                    name='model'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Documents
                                                canEdit={canEdit}
                                                acceptFile={["pdf", "image"]}
                                                documents={mediaStorage}
                                                onUpdate={this.handleUpdateDocuments}
                                                onDelete={(media) => this.handleDeleteDocuemnts(media.name, currentDriverId)}
                                                onPreview={(media) => this.setState({ mediaPreview: media })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { drivers } = loopback;
    return {
        driver: drivers && drivers.instance,
        error: drivers && drivers.error,
        editEnd: drivers && drivers.editEnd,
    };
}

const connectedDriverEdit = withTranslation()(connect(mapStateToProps)(DriverEdit));
export { connectedDriverEdit as DriverEdit };

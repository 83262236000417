import { userConstants } from '../constants';

function upadate(items, item) {
  try {
    items[items.findIndex(i => i.id === item.id)] = item;
  } catch (err) {
    //console.log(err);
  }
  return items;
}

export function users(state = {}, action) {
  switch (action.type) {

    // GETALL
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    // GETBYID
    case userConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETBYID_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };

    // EDIT
    case userConstants.EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.EDIT_SUCCESS:
      return {
        // user: action.user
        ...state,
        loading: false,
        items: upadate(state.items, action.user)
      };
    case userConstants.EDIT_FAILURE:
      return {
        error: action.error
      };

    // ADD
    case userConstants.ADD_REQUEST:
      return {
        ...state,
        loading: false,
      }
    case userConstants.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        // items: [...state.items, action.user]
      };
    case userConstants.ADD_FAILURE:
      return {
        error: action.error
      };


    // DELETE
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };

    // FILTER COUNT
    case userConstants.FILTER_COUNT_REQUEST:
      return {
        // ...state,
        loading: true,
        filters: action.filters
      }
    case userConstants.FILTER_COUNT_SUCCESS:
      return {
        // ...state,
        loading: true,
        filtered_count: action.count.count,
        limit: action.limit,
        pages: Math.ceil(action.count.count / action.limit)
      }
    case userConstants.FILTER_COUNT_FAILURE:
      return {
        // ...state,
        loading: false,
        error: action.error,
        limit: action.limit
      }

    // FILTER
    case userConstants.FILTER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case userConstants.FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.users
      }
    case userConstants.FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    // FILTER ALL NO LIMIT
    case userConstants.FILTER_ALL_NO_LIMIT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case userConstants.FILTER_ALL_NO_LIMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.users,
        items_all_no_limit: action.users,
      }
    case userConstants.FILTER_ALL_NO_LIMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state
  }
}

/*global google*/
import React, { Component, Fragment } from "react";
// import { compose, withProps, withHandlers } from "recompose";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
    StreetViewPanorama,
    Polygon
} from "react-google-maps";
import ItemStatus from "./ItemStatus";
import { NavModes } from '../../constants';
import { utils } from '../../helpers';
import LeftMouseClick from '../../assets/img/LeftMouseClick';
import RightMouseClick from '../../assets/img/RightMouseClick';
import { CustomMapControl } from "../../components";

class MapComponent extends Component {

    constructor(props) {
        super(props);
        this.map = null;
        this.handleMapMounted = this.handleMapMounted.bind(this);
    }

    handleMapMounted(map) {
        const { handleMapMounted } = this.props;
        if (map) {
            this.map = map;
            handleMapMounted(map);
        }
    }

    renderInfoWindow() {
        const {
            status,
            onMarkerClose,
            handleChangePeriferalStatus,
            handlePeriferalChangeStatusDesired,
            handleStartEditingPeriferal,
            handleCancelEditingPeriferal,
            userDidCancel,
            commandResult,
            alert,
            infowindow
        } = this.props;

        return (
            <InfoWindow
                key={"status" + infowindow.item.type + "." + infowindow.item.id}
                position={infowindow.item.geometry}
                onCloseClick={onMarkerClose}
            >
                <ItemStatus
                    status={status}
                    item={infowindow.item}
                    handleChangePeriferalStatus={handleChangePeriferalStatus}
                    handlePeriferalChangeStatusDesired={handlePeriferalChangeStatusDesired}
                    handleStartEditingPeriferal={handleStartEditingPeriferal}
                    handleCancelEditingPeriferal={handleCancelEditingPeriferal}
                    userDidCancel={userDidCancel}
                    commandResult={commandResult}
                    alert={alert}
                />
            </InfoWindow>
        )
    }

    render() {
        const {
            key,
            t,
            instances,
            tree,
            mode,
            center,
            zoom,
            onDragEnd,
            onZoomChanged,
            onMapClick,
            onMapDblClick,
            onBoundsChanged,
            handlePolygonMounted,
            onMapRClick,
            onVertexDrag,
            onVertexDragEnd,
            onVertexClick,
            onVertexRClick,
            status,
            onMarkerClose,
            handleChangePeriferalStatus,
            handlePeriferalChangeStatusDesired,
            handleStartEditingPeriferal,
            handleCancelEditingPeriferal,
            userDidCancel,
            commandResult,
            alert,
            infowindow,
            infowindowMarker,
            disableDefaultUI
        } = this.props;

        let isVisibleStreetView = false;
        let mapCenter = center;
        let mapZoom = zoom;

        if (this.map) {
            let streetView = this.map.getStreetView();
            isVisibleStreetView = streetView.getVisible();
        }

        return (
            <GoogleMap
                key={"gm_" + key}
                defaultOptions={{
                    scrollwheel: true,
                    fullScreenControl: true,
                    mapTypeControl: mode === NavModes.PLANT ? true : false,
                    streetViewControl: mode === NavModes.PLANT ? true : false,
                    scaleControl: mode === NavModes.PLANT ? true : false,
                    clickableIcons: false,
                    disableDoubleClickZoom: true,
                    // draggableCursor
                    disableDefaultUI: ![undefined, null].includes(disableDefaultUI) ? disableDefaultUI : true,
                    //ho utilizzato https://snazzymaps.com/editor per stilizzare la mappa
                    styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
                }}
                center={mapCenter}
                zoom={mapZoom}
                ref={this.handleMapMounted}
                onDragEnd={onDragEnd}
                onZoomChanged={onZoomChanged}
                onClick={onMapClick}
                onDblClick={onMapDblClick}
                onBoundsChanged={onBoundsChanged}
            >

                {/* { editingMarker &&
            <Marker
              { ...editingMarker }
              draggable = { true }
              onDragEnd = {(e) => { onMarkerDragEnd(editingMarker, e.latLng.lat(), e.latLng.lng()); }}
            />

        } */}

                {infowindow && isVisibleStreetView &&
                    <StreetViewPanorama defaultPosition={center}  >
                        <Marker
                            {...infowindowMarker}
                        >

                            <InfoWindow
                                key={"status" + infowindow.item.type + "." + infowindow.item.id}
                                onCloseClick={onMarkerClose}
                            >
                                <ItemStatus
                                    status={status}
                                    item={infowindow.item}
                                    handleChangePeriferalStatus={handleChangePeriferalStatus}
                                    handlePeriferalChangeStatusDesired={handlePeriferalChangeStatusDesired}
                                    handleStartEditingPeriferal={handleStartEditingPeriferal}
                                    handleCancelEditingPeriferal={handleCancelEditingPeriferal}
                                    userDidCancel={userDidCancel}
                                    commandResult={commandResult}
                                    alert={alert}
                                />
                            </InfoWindow>
                        </Marker>

                    </StreetViewPanorama>
                }

                {infowindow && !isVisibleStreetView &&
                    <Marker
                        {...infowindowMarker}
                    >

                        <InfoWindow
                            key={"status" + infowindow.item.type + "." + infowindow.item.id}
                            onCloseClick={onMarkerClose}
                        >
                            <ItemStatus
                                status={status}
                                item={infowindow.item}
                                handleChangePeriferalStatus={handleChangePeriferalStatus}
                                handlePeriferalChangeStatusDesired={handlePeriferalChangeStatusDesired}
                                handleStartEditingPeriferal={handleStartEditingPeriferal}
                                handleCancelEditingPeriferal={handleCancelEditingPeriferal}
                                userDidCancel={userDidCancel}
                                commandResult={commandResult}
                                alert={alert}
                            />
                        </InfoWindow>
                    </Marker>
                }

                {mode === NavModes.ZONES && instances && Array.isArray(instances.zones) &&
                    instances.zones.map((zone, pos) => {
                        let selected = tree.zones.selectedTreeItem && (tree.zones.selectedTreeItem === zone);

                        return (
                            <Polygon
                                key={zone.geometry ? "zon_" + zone.id + "_" + utils.cyrb53(JSON.stringify(zone.geometry)) : "zon_" + zone.id}
                                paths={zone.geometry}
                                options={{
                                    fillColor: (zone.configurationTimerProfileUpToDate ? zone.color : "orange"),
                                    fillOpacity: selected ? 0.5 : 0.1,
                                    strokeColor: (zone.configurationTimerProfileUpToDate ? zone.color : "orange"),
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    geodesic: false,
                                    zIndex: 1
                                }}
                                ref={(polygon) => { handlePolygonMounted(zone.id, polygon); }}
                                onRightClick={onMapRClick}
                                onDblClick={onMapDblClick}
                            />
                        )
                    })
                }

                {mode === NavModes.ZONES && instances && Array.isArray(instances.zones) &&
                    instances.zones.map((zone) => {
                        let isEditing = tree.zones.editingTreeItem && (tree.zones.editingTreeItem === zone);
                        if (isEditing && zone.geometry) {
                            return (
                                zone.geometry.map((point, index) => {
                                    let color = "white";
                                    let sel = tree.zones.selectedVertex;
                                    if (index === sel || index === (sel + 1) || (index === 0 && sel === (zone.geometry.length - 1))) {
                                        color = "red";
                                    }

                                    return (
                                        <Marker
                                            key={"point_" + zone.id + "_" + index + (tree.zones.overlapped[zone.id] ? "_" + tree.zones.overlapped[zone.id] : "")}
                                            position={point}
                                            icon={mapCircle(color, zoom, 1)}
                                            draggable={true}
                                            onDrag={(e) => { onVertexDrag(e, zone, index); }}
                                            onDragEnd={(e) => { onVertexDragEnd(e, zone, index); }}
                                            onClick={(e) => { onVertexClick(e, zone, index); }}
                                            onRightClick={(e) => { onVertexRClick(e, zone, index); }}
                                        />
                                    );
                                })
                            )
                        }
                        return null;
                    })
                }

                {mode === NavModes.ZONES && tree[mode].editingTreeItem &&
                    <Fragment>
                        <Polygon
                            key={(tree[mode].editingTreeItem.geometry) ?
                                "zon_" + Date.now() + utils.cyrb53(JSON.stringify(tree[mode].editingTreeItem.geometry)) :
                                "zon_" + Date.now()
                            }
                            paths={tree[mode].editingTreeItem.geometry}
                            options={{
                                fillColor: "gray",
                                fillOpacity: 0,
                                strokeColor: "black",
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                geodesic: false,
                                zIndex: 2
                            }}
                        />
                        <CustomMapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
                            <div className="wrapper-comandi-mappa helper-mappa">
                                <LeftMouseClick
                                    width={20}
                                    height={20}
                                    fill={"lightgray"}
                                    stroke={"gray"}
                                    title={t("Aggiunge un vertice")}
                                />{t("Aggiunge un vertice")}
                                &nbsp;&nbsp;
                                <RightMouseClick
                                    width={20}
                                    height={20}
                                    fill={"lightgray"}
                                    stroke={"gray"}
                                    title={t("Rimuove un vertice")}
                                />{t("Rimuove un vertice")}
                            </div>
                        </CustomMapControl>
                    </Fragment>
                }

                {/* { isVisibleStreetView &&
          <StreetViewPanorama defaultPosition = {center}  >
            { markers.map((marker, index) =>
              (<Marker
                {...marker}
                key = {"street_" + marker.item.type+"."+marker.item.id}
                onClick = {() => onMarkerClick(marker)}
                zIndex = {marker.isOpen ? google.maps.Marker.MAX_ZINDEX : index}
              >
                { marker.isOpen && <InfoWindow onCloseClick={() => onMarkerClose(marker)} >
                  <ItemStatus
                    status = { status }
                    marker = { marker }
                    handleChangePeriferalStatus = { handleChangePeriferalStatus }
                    handlePeriferalChangeStatusDesired = { handlePeriferalChangeStatusDesired }
                    handleStartEditingPeriferal = { handleStartEditingPeriferal }
                    handleCancelEditingPeriferal = { handleCancelEditingPeriferal }
                    userDidCancel = { userDidCancel }
                    commandResult = { commandResult }
                    alert = { alert }
                  />
                </InfoWindow>}
              </Marker>))}
          </StreetViewPanorama>
        } */}

                {/* { !isVisibleStreetView && markers.map((marker, index) => {

          return (
            <Marker
              { ...marker }
              key = {"map_" + marker.item.type+"."+marker.item.id }
              onClick = {() => onMarkerClick(marker)}
              zIndex = { marker.isOpen ? google.maps.Marker.MAX_ZINDEX : index }
              draggable = {marker.isEditing}
              onDragEnd = {(e) => { onMarkerDragEnd(marker, e.latLng.lat(), e.latLng.lng()); }}
            >
              { marker.isOpen && !marker.isEditing &&
                <InfoWindow onCloseClick={() => onMarkerClose(marker)} >
                  <ItemStatus
                    status = { status }
                    marker = { marker }
                    handleChangePeriferalStatus = { handleChangePeriferalStatus }
                    handlePeriferalChangeStatusDesired = { handlePeriferalChangeStatusDesired }
                    handleStartEditingPeriferal = { handleStartEditingPeriferal }
                    handleCancelEditingPeriferal = { handleCancelEditingPeriferal }
                    userDidCancel = { userDidCancel }
                    commandResult = { commandResult }
                    alert = { alert }
                  />
                </InfoWindow>
              }
            </Marker>
          )
        })
        } */}
            </GoogleMap>
        );
    }
}

var Color = require('color');

//export const selectedBorderColor = "darkgreen";

const IconSquare = function (props) {
    return (
        <svg onClick={props.onClick} width={props.width} height={props.height} className={props.className} viewBox="-24 -50 52 52">
            <path
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
                fill={props.fill}
                d="M22-48h-44v43h16l6 5 6-5h16z"
            />
        </svg>
    )
};

const IconPin = function (props) {
    return (
        <svg onClick={props.onClick} width={props.width} height={props.height} className={props.className} viewBox="-24 -24 48 48">
            <path
                stroke={props.stroke}
                strokeWidth={props.strokeWidth}
                fill={props.fill}
                //d="M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z"
                d="M-22,0a22,22 0 1,0 44,0a22,22 0 1,0 -44,0"
            //
            />
        </svg>
    )
};

const mapPin = {
    // path: google.maps.SymbolPath.CIRCLE,
    path: "M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12z",
    //anchor: {x:0,y:10},
    fillOpacity: 1,
    scale: 1,
    fillColor: 'red',
    strokeColor: 'white',
    strokeWeight: 2
};

const mapSquare = {
    path: 'M22-48h-44v43h16l6 5 6-5h16z',
    fillColor: 'red',
    fillOpacity: 1,
    scale: 0.5,
    strokeColor: 'white',
    strokeWeight: 1
};

const mapDoubleCircle2 = {
    path: 'M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11 M 4,12 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0z',
    fillColor: 'red',
    fillOpacity: 1,
    scale: 1,
    strokeColor: 'white',
    strokeWeight: 1
};

const mapCircle = (color, zoom, opacity) => {
    return {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: opacity,
        scale: 6,
        strokeColor: 'black',
        strokeWeight: 1
    }
};

function fillForBulb(statusReported, isSelected) {
    let color = null;
    if (statusReported && statusReported.actualLightState > 0) {
        color = Color("#FFD700"); // gold
        color = color.lighten(1 - statusReported.actualLightState / 100);
    }
    else {
        if (statusReported && statusReported.syraState === 'READY') {
            return "none";
        } else {
            color = Color("#D3D3D3"); // ligthGray
        }
    }
    return color.hsl().string();
}

function strokeForBulb(statusReported, isSelected) {
    let color = null;
    if (statusReported && statusReported.actualLightState > 0) {
        color = Color("#FFA500"); // orange
        color = color.lighten(1 - statusReported.actualLightState / 100);
    }
    else {
        color = Color("#808080"); // gray
    }
    return color.hsl().string();
}

/**
 *
 * @param {*} statusReported
 * @param {*} isSelected
 */
function strokeForPin(statusReported, isSelected, overrideColor) {
    let color = overrideColor ? Color(overrideColor) : Color("#808080"); // gray

    if (statusReported) {
        switch (statusReported.syraState) {
            case 'READY': color = Color("#006400"); break; // darkGreen
            case 'NOT_CONNECTED': color = Color("#808080"); break; // gray
            default: color = Color("#8B0000"); // dark red
        };
    }
    if (isSelected) {
        color = color.lighten(0.1);
    }
    return color.hsl().string();
}

/**
 *
 * @param {*} statusReported
 * @param {*} isSelected
 * @param {*} isEditing
 * @param {*} color
 */
function fillForPin(statusReported, isSelected, isEditing, overrideColor) {

    if (isEditing) {
        return Color("#FFC900").hsl().string();
    }

    let color = overrideColor ? Color(overrideColor) : Color("#D3D3D3"); // ligthGray
    if (statusReported) {
        switch (statusReported.syraState) {
            case 'READY': color = Color("#008800"); break; // green
            case 'NOT_CONNECTED': color = Color("#D3D3D3"); break; // lightGray
            default: color = Color("#FF0000"); // red
        };
    }
    if (isSelected) {
        color = color.lighten(0.1);
    }
    return color.hsl().string();
}

/**
 *
 * @param {*} periferal
 * @param {*} isSelected
 * @param {*} isEditing
 * @param {*} color
 */
function fillForRadio(periferal, isSelected) {

    // leggo lo stato dalla periferica CONNECTION
    // se online --> verde
    // altrimenti grigio
    let color = Color("#D3D3D3"); // lightGray
    if (periferal && periferal.connection && periferal.connection.health === 'connect') {
        color = Color("#008800"); // green
    }
    if (isSelected) {
        color = color.lighten(0.1);
    }
    return color.hsl().string();
}

function fillForAlarm(statusReported, value, isSelected) {

    let color = Color("#D3D3D3"); // ligthGray

    if (statusReported.syraState === 'NOT_CONNECTED') {
        color = Color("#D3D3D3"); // ligthGray
    } else {
        if (value === 0) {
            color = Color("#008000"); // green
        }
        else {
            color = Color("#FF0000"); // red
        }
    }

    if (isSelected) {
        color = color.lighten(0.1);
    }
    return color.hsl().string();

}

function strokeForAlarm(statusReported, value, isSelected) {

    let color = Color("#808080"); // gray

    if (statusReported.syraState === 'NOT_CONNECTED')
        color = Color("#808080"); // gray
    else {
        if (value === 0)
            color = Color("#006400"); // darkgreen 	#
        else
            color = Color("#FF4500");  // orangered
    }
    if (isSelected) {
        color = color.lighten(0.1);
    }

    return color.hsl().string();

}

function fillForSquare(isSelected, isEditing) {

    if (isEditing) {
        return Color("#FFC900").hsl().string();
    }

    let color = Color("#008000"); // green
    if (isSelected) {
        color = color.lighten(0.2);
    }
    return color.hsl().string();
}

function strokeForSquare(isSelected) {
    let color = Color("#006400"); // dark green
    if (isSelected) {
        color = color.lighten(0.2);
    }
    return color.hsl().string();
}

// const withGoogleMaps = compose(
//   withProps({
//     // polygonRef: React.createRef(),
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+googleConstants.API_KEY+"&libraries=drawing",
//     loadingElement: <div style={{ height: "100%" }} />,
//     containerElement: <div style={{ height: "100%", borderRadius: "0.1875rem", boxShadow: "0 1px 15px 1px rgba(39, 39, 39, 0.1" }} />,
//     mapElement: <div style={{ height: `100%`, borderRadius: '0.1875rem' }} />
//   }),
//   // withHandlers({
//   //   handleMouseOver: props => event => {
//   //     const polygon = props.polygonRef.current.state[POLYGON]; //polygon instance
//   //     console.log(polygon);
//   //   }
//   // }),
//   //withScriptjs,
//   withGoogleMap
// );

const Map = withGoogleMap(MapComponent);

export {
    Map,
    strokeForSquare,
    fillForSquare,
    strokeForAlarm,
    fillForAlarm,
    fillForPin,
    fillForRadio,
    strokeForPin,
    strokeForBulb,
    fillForBulb,
    IconSquare,
    IconPin,
    mapSquare,
    mapPin,
    mapDoubleCircle2

};

import React, { Component } from "react";
import ReactTable from "react-table";
import { utils } from '../../helpers';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Row,
    Col,
    Label,
    Input,
    Form,
    FormGroup,
    Button
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
import ErrorModal from "../Components/ErrorModal"
import { loopbackService } from '../../services';
import { Models, ModelPaths, DeviceTypes } from '../../constants';
import Datetime from "react-datetime";
import moment from 'moment';
import ZoneConfigurationProgrammingChart from './ZoneConfigurationProgrammingChart';
import { ClipLoader } from 'react-spinners';

const DateFormat = "DD/MM/YYYY";
const TimeFormat = "HH:mm";
const DateTimeFormat = DateFormat + " " + TimeFormat;

class ZoneConfiguration extends Component {

    constructor(props) {
        super(props);

        const { zone, instances } = props;
        let radioControllers = [];

        if (instances.lightpoints) {
            for (let item of instances.lightpoints) {
                // aggiungo il lightpoint all'albero delle zone
                if (item.deviceType === DeviceTypes.RADIOCONTROLLER &&
                    item.zoneId === zone.id &&
                    radioControllers.find((a) => (
                        a.id === item.radioController.id &&
                        a.isAndros === false
                    )) === undefined) {
                    radioControllers.push({
                        id: item.radioController.id,
                        label: item.label,
                        address: item.address,
                        selected: true,
                        isAndros: false
                    });
                }

                // aggiungo il syra RE-C padre del nodo
                if (item.deviceType === DeviceTypes.RADIONODE &&
                    item.zoneId === zone.id &&
                    radioControllers.find((a) => (
                        a.id === item.radioControllerId &&
                        a.isAndros === false
                    )) === undefined) {
                    let rc = instances.radiocontrollers.find((r) => r.id === item.radioControllerId);
                    // recupera il ligtpoint del radio controller e quindi il device
                    let lprc = instances.lightpoints.find((lp) => lp.id === rc.lightPointId);
                    if (lprc && lprc.device) {
                        radioControllers.push({
                            id: rc.id,
                            label: lprc.label,
                            address: lprc.address,
                            selected: true,
                            isAndros: false
                        });
                    }
                }

                // M.C. Controlla il punto luce si trovi nella zona selezionata, che abbia un cabinet associato e che questo abbia un device abbinato
                let cabinet = {}
                if (item.deviceType === DeviceTypes.SYRA &&
                    item.zoneId === zone.id &&
                    item.cabinetId !== null &&
                    radioControllers.find((a) => (
                        a.id === item.cabinetId &&
                        a.isAndros
                    )) === undefined) {
                    cabinet = instances.cabinets.find((c) => c.id === item.cabinetId);
                    if (cabinet.device.length > 0) {
                        radioControllers.push(
                            {
                                id: cabinet.id,
                                label: cabinet.label,
                                address: cabinet.address,
                                selected: true,
                                isAndros: true
                            }
                        );
                    }
                }
            }
        }

        this.state = {
            error: null,
            loading: false,
            validationError: null,
            timerProfileWeeklyId: null,
            timerProfileWeeklyStartDate: props.zone.timerProfileWeeklyStartDate,
            formEditing: false,
            saving: false,
            isNew: false,
            dailyProfiles: [],
            chartView: "week",
            selectedDay: moment(),
            radioControllers,
            readyToSendConfig: true
        }
        this.chartDatePrev = this.chartDatePrev.bind(this);
        this.chartDateNext = this.chartDateNext.bind(this);
        this.getLegacyTimerFlag = this.getLegacyTimerFlag.bind(this);
        this.initTimerProfileWeeklyId = this.initTimerProfileWeeklyId.bind(this);

        this.handleSendRadioControllersConfigClick = this.handleSendRadioControllersConfigClick.bind(this);
    }

    getLegacyTimerFlag = function () {
        // Codice per discriminare il funzionamento dei nuovi profili timer
        this.projects = JSON.parse(localStorage.getItem('projects'));
        this.projectId = parseInt(localStorage.getItem('selectedProjectId'));
        this.projectSelected = this.projects.find(p => p.id === this.projectId);
        this.legacyTimer = this.projectSelected.legacyTimerProfiles;
        //console.log("recupero flag progetto", this.legacyTimer);
    }

    initTimerProfileWeeklyId() {
        const { zone } = this.props;
        this.setState({
            timerProfileWeeklyId: (
                this.legacyTimer ? (
                    zone.timerProfileWeeklyId ? zone.timerProfileWeeklyId : 0
                ) : (
                    zone.timerProfilePendingWeekId ? zone.timerProfilePendingWeekId : 0
                )
            )
        });
    }

    async componentWillMount() {
        const { zone } = this.props;

        // impostazione flag timer legacy
        this.getLegacyTimerFlag();
        // inizializzazione del valore timerProfileWeeklyId
        this.initTimerProfileWeeklyId();

        try {
            this.setState({ loading: true });

            let ret = await loopbackService.invoke(ModelPaths.PLANT, Models.ZONES, zone.id + "/dayList");
            this.setState({ dailyProfiles: ret, loading: false });

        } catch (error) {
            this.setState({ error, loading: false });
        }
    }

    /**
     * Incrementa di uno step la data selezionata
     */
    chartDateNext() {
        let {
            chartView,
            selectedDay
        } = this.state;

        switch (chartView) {
            case "week":
                selectedDay.add(7, 'days');
                break;
            case "day":
                selectedDay.add(1, 'days');
                break;
            case "month":
                selectedDay.add(1, 'month');
                break;
            case "year":
                selectedDay.add(1, 'year');
                break;
            default: break;
        }
        this.setState({ selectedDay });
    }

    /**
     * Decrementa di uno step la data selezionata
     */
    chartDatePrev() {
        let {
            chartView,
            selectedDay
        } = this.state;
        switch (chartView) {
            case "week":
                selectedDay.subtract(7, 'days');
                break;
            case "day":
                selectedDay.subtract(1, 'days');
                break;
            case "month":
                selectedDay.subtract(1, 'month');
                break;
            case "year":
                selectedDay.subtract(1, 'year');
                break;
            default: break;
        }
        this.setState({ selectedDay });
    }

    /**
     *
     */
    render() {

        const { t, zone, instances, indexes, handleZoneConfigurationClose } = this.props;
        let { radioControllers, errors } = this.state;
        const {
            loading,
            error,
            //formEditing,
            timerProfileWeeklyId,
            timerProfileWeeklyStartDate,
            //saving,
            dailyProfiles,
            chartView,
            selectedDay,
            readyToSendConfig
        } = this.state;

        let formattedValue = "";
        if (timerProfileWeeklyStartDate !== null && timerProfileWeeklyStartDate !== "") {
            formattedValue = moment(timerProfileWeeklyStartDate, moment.defaultFormat).format(DateTimeFormat);
        }

        let tpwOptions = [];
        // M.C. Condizione in base a legacyTimer
        if (this.legacyTimer === true) {
            if (instances.timerprofileweekly) {
                instances.timerprofileweekly.map((wp) => {
                    tpwOptions.push(
                        { value: wp.id, label: wp.label }
                    );
                    return true;
                });
            }
        }
        else {
            if (instances.timerprofiledaily) {
                instances.timerprofiledaily.map((wp) => {
                    tpwOptions.push(
                        { value: wp.id, label: wp.label }
                    );
                    return true;
                });
            }
        }

        if (tpwOptions.length === 0) {
            tpwOptions.push(
                { value: 0, label: t("Default - piena potenza") }
            );
        }

        let countSelected = 0;
        radioControllers.forEach((rc) => {
            if (rc.selected)
                countSelected++;
        });

        return (
            <Modal isOpen={true} size="lg" className="modal-umpi-container ZoneConfiguration fullscreen-modal-dialog">
                <ModalHeader toggle={() => handleZoneConfigurationClose()}>
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    {t("Configuration")}
                </ModalHeader>
                <ModalBody >
                    {this.state.alert}
                    {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}
                    <Card>
                        <CardHeader className="config-card-header">
                            <CardTitle className="config-card-title" >
                                <span style={{ flex: 1 }}>
                                    {t("Programmazione")}
                                </span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className="chartRow">
                                <Col>
                                    <div className="chart-toolbar" >
                                        <div
                                            className={"chart-toolbar-tab " + (chartView === "week" ? "active" : "")}
                                            onClick={() => {
                                                this.setState({ chartView: "week" });
                                            }}
                                        >
                                            {t("Settimanale")}
                                        </div>
                                        <div
                                            className={"chart-toolbar-tab " + (chartView === "month" ? "active" : "")}
                                            onClick={() => {
                                                this.setState({ chartView: "month" });
                                            }}
                                        >
                                            {t("Mensile")}
                                        </div>
                                        <div
                                            className={"chart-toolbar-tab " + (chartView === "year" ? "active" : "")}
                                            onClick={() => {
                                                this.setState({ chartView: "year" });
                                            }}
                                        >
                                            &nbsp;{t("Annuale")}
                                        </div>
                                        <div className="chart-toolbar-buttons" >
                                            <ButtonWithTooltip
                                                id={"chart-btn-prev"}
                                                color="warning"
                                                size="sm"
                                                tooltipText={t("Indietro")}
                                                onClick={this.chartDatePrev}
                                            >
                                                <i className="fa fa fa-chevron-left" />
                                            </ButtonWithTooltip>
                                            <Datetime
                                                inputProps={{ disabled: false }}
                                                dateFormat={DateFormat}
                                                timeFormat={TimeFormat}
                                                value={selectedDay.format(DateTimeFormat)}
                                                onChange={(newDate) => {
                                                    if (newDate && newDate instanceof moment) {
                                                        if (!newDate.isValid()) {
                                                            return;
                                                        }
                                                        this.setState({ selectedDay: newDate });
                                                    }
                                                }}
                                                onBlur={(newDate) => {
                                                    if (!(newDate instanceof moment)) {
                                                        newDate = moment(newDate, DateTimeFormat);
                                                        if (!newDate.isValid()) {
                                                            return;
                                                        }
                                                    }
                                                    this.setState({ selectedDay: newDate });
                                                }}
                                            />
                                            <ButtonWithTooltip
                                                id={"chart-btn-next"}
                                                color="warning"
                                                size="sm"
                                                tooltipText={t("Avanti")}
                                                onClick={this.chartDateNext}
                                            >
                                                <i className="fa fa fa-chevron-right" />
                                            </ButtonWithTooltip>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ width: "100%", height: "250px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                        <ZoneConfigurationProgrammingChart
                                            selectedDay={selectedDay}
                                            instances={instances}
                                            indexes={indexes}
                                            zoneTimerProfileWeekly={timerProfileWeeklyId}
                                            zoneTimerProfileWeeklyStartDate={moment(timerProfileWeeklyStartDate, moment.defaultFormat)}
                                            zoneDailyProfiles={dailyProfiles}
                                            view={chartView}
                                            legacyTimer={this.legacyTimer}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mt-3">
                        <CardHeader className="config-card-header">
                            <CardTitle className="config-card-title" >
                                <span style={{ flex: 1 }}>
                                    {t("Timer profile")}
                                </span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form className="">
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {t("Profilo")}
                                                <strong style={{ color: "#990000"}}> ({t("Richiesto")})</strong>
                                            </Label>
                                            <Select
                                                placeholder=""
                                                //disabled={!formEditing}
                                                name="timerProfileWeeklyId"
                                                value={timerProfileWeeklyId}
                                                options={tpwOptions}
                                                onChange={option => {
                                                    if (option) {
                                                        this.setState({ timerProfileWeeklyId: option.value, readyToSendConfig: false })
                                                    }
                                                    else {
                                                        this.setState({ timerProfileWeeklyId: null, readyToSendConfig: false })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <FormGroup>
                                            <Label>{t("Data ora inizio validità")}</Label>
                                            <Datetime
                                                //inputProps={{ disabled: !formEditing }}
                                                dateFormat={DateFormat}
                                                timeFormat={TimeFormat}
                                                value={formattedValue}
                                                onChange={(time) => {
                                                    if (time && time instanceof moment) {
                                                        if (!time.isValid()) {
                                                            return;
                                                        }
                                                        let newValue = time.format(moment.defaultFormat);
                                                        this.setState({ timerProfileWeeklyStartDate: newValue, readyToSendConfig: false });
                                                    }
                                                }}
                                                onBlur={(time) => {
                                                    if (!(time instanceof moment)) {
                                                        time = moment(time, DateTimeFormat);
                                                        if (!time.isValid()) {
                                                            return;
                                                        }
                                                    }
                                                    let newValue = time.format(moment.defaultFormat);
                                                    this.setState({ timerProfileWeeklyStartDate: newValue, readyToSendConfig: false });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mt-3">
                        <CardHeader className="config-card-header">
                            <CardTitle className="config-card-title" >
                                <span style={{ flex: 1 }}>{t("Profilo prioritario")}</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                loading={loading}
                                data={dailyProfiles}
                                className="-striped -highlight configurations"
                                pageSize={dailyProfiles && dailyProfiles.length}
                                showPagination={false}
                                NoDataComponent={() => null}
                                LoadingComponent={() => {
                                    if (loading) {
                                        return (
                                            <div className="-loading -active">
                                                <div className="-loading-inner custom-margin-0-auto">
                                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                }}
                                columns={this.getColumns()}
                            />
                            {dailyProfiles.length < 4 &&
                                <div className="add-profile-container">
                                    <Button
                                        color="default"
                                        onClick={
                                            () => {
                                                if (this.state.isNew) {
                                                    return;
                                                }
                                                let item = {
                                                    startDate: null,
                                                    stopDate: null,
                                                    weekDayMask: 0,
                                                    zoneId: zone.id,
                                                    timerProfileDailyId: 0
                                                };
                                                dailyProfiles.unshift(item);
                                                this.setState({ isNew: true, dailyProfiles, editingIndex: 0, editingItem: item, validationError: null });
                                            }
                                        }>
                                        {t("Add configurator profile day")}
                                    </Button>
                                </div>
                            }
                        </CardBody>
                    </Card>
                    <Card className="mt-3">
                        <CardHeader className="config-card-header">
                            <CardTitle className="config-card-title" >
                                <span style={{ flex: 1 }}>{t("Concentratori radio")}</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                loading={loading}
                                data={radioControllers}
                                className="-striped -highlight configurations"
                                pageSize={radioControllers && radioControllers.length}
                                showPagination={false}
                                NoDataComponent={() => null}
                                LoadingComponent={() => {
                                    if (loading) {
                                        return (
                                            <div className="-loading -active">
                                                <div className="-loading-inner custom-margin-0-auto">
                                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                }}
                                columns={[
                                    {
                                        Header: (
                                            <div className="cb-wrapper">
                                                <Input
                                                    type="checkbox"
                                                    checked={countSelected === (radioControllers.length)}
                                                    onChange={(e) => {

                                                        if (radioControllers) {
                                                            radioControllers.map((rc) => {
                                                                return {
                                                                    ...rc,
                                                                    selected: e.target.checked
                                                                }
                                                            });
                                                            this.setState({ radioControllers });
                                                        }

                                                    }}
                                                /></div>
                                        ),
                                        accessor: "selected",
                                        sortable: false,
                                        filterable: false,
                                        width: 45,
                                        className: "cb-wrapper",
                                        Cell: (row) => {
                                            let isChecked = row.value;
                                            return (
                                                <Input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={(e) => {
                                                        radioControllers[row.index].selected = e.target.checked;
                                                        this.setState({ radioControllers })
                                                    }}
                                                />
                                            )
                                        }
                                    },
                                    {
                                        Header: t("Concentratore"),
                                        accessor: "label",
                                        sortable: false,
                                        filterable: false,
                                        className: "",
                                        Cell: (row) => {
                                            let item = row.original;
                                            let txt = item.label + " - " + item.address;
                                            return (<span>{txt}</span>);
                                        }
                                    },
                                    {
                                        Header: t(""),
                                        accessor: "",
                                        sortable: false,
                                        filterable: false,
                                        className: "",
                                        width: 45,
                                        Cell: (row) => {
                                            return (
                                                <div className="actions-right">
                                                    <ButtonWithTooltip
                                                        id={"config_send_" + row.original.id}
                                                        color="success"
                                                        size="sm"
                                                        tooltipText={t("Invia configurazione")}
                                                        disabled={(timerProfileWeeklyId === null || readyToSendConfig === false) }
                                                        onClick={() => {
                                                            this.handleSendRadioControllersConfigClick([row.original.id]);

                                                        }}
                                                    >
                                                        <i className="fa fa-paper-plane"></i>
                                                    </ButtonWithTooltip>
                                                </div>
                                            );
                                        }
                                    }
                                ]}
                            />
                            {countSelected > 0 &&
                                <div className="add-profile-container">
                                    <Button
                                        color="default"
                                        disabled={(timerProfileWeeklyId === null || readyToSendConfig === false)}
                                        onClick={() => {
                                            let ids = [];
                                            radioControllers.forEach((rc) => {
                                                if (rc.selected)
                                                    ids.push(rc.id);
                                            });
                                            this.handleSendRadioControllersConfigClick(ids);
                                        }}>
                                        <i className="fa fa-paper-plane"></i>
                                        &nbsp;{t("Invia a selezionati") + " (" + countSelected + ")"}
                                    </Button>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" className="umpi-modal-btn-cancel" onClick={() => {
                        // Cancel profile
                        let timerProfileWeeklyId = (this.legacyTimer) ? (this.props.zone.timerProfileWeeklyId ? this.props.zone.timerProfileWeeklyId : 0) : (this.props.zone.timerProfilePendingWeekId ? this.props.zone.timerProfilePendingWeekId : 0);
                        let timerProfileWeeklyStartDate = this.props.zone.timerProfileWeeklyStartDate;

                        // Save all and return
                        this.setState({
                            formEditing: false,
                            timerProfileWeeklyId,
                            timerProfileWeeklyStartDate
                        }, () => {
                            // Send to navigator
                            handleZoneConfigurationClose();
                        });
                    }}>
                        {t("Annulla")}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        disabled={timerProfileWeeklyId === null}
                        color="success" className="umpi-modal-btn-save" onClick={async () => {
                        // Save profile
                        await this.saveTimerProfileWeekly();

                        // reset
                        this.setState({ readyToSendConfig: true });

                        // Send to navigator
                        //handleZoneConfigurationClose();
                    }}>
                        {t("Salva")}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    confirmDelete(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    validateStartStop(startDate, stopDate) {
        if (startDate && stopDate) {
            let start = moment(startDate, moment.defaultFormat);
            let stop = moment(stopDate, moment.defaultFormat);
            return stop >= start;
        }
        return true;
    }

    getColumns() {

        const { t, instances } = this.props;
        const {
            editingIndex,
            editingItem,
            validationError,
            dailyProfiles,
            saving
        } = this.state;

        let tpdOptions = [];
        if (instances.timerprofiledaily) {
            instances.timerprofiledaily.forEach((dp) => {
                tpdOptions.push(
                    { value: dp.id, label: dp.label }
                );
            })
        }

        const weekDays = [t('Lun'), t('Mar'), t('Mer'), t('Gio'), t('Ven'), t('Sab'), t('Dom')];

        let columns = [];

        columns.push({
            Header: t("Profilo"),
            accessor: "timerProfileDailyId",
            filterable: false,
            resizable: false,
            Cell: (row) => {
                const isEditing = (row.index === editingIndex);
                if (isEditing) {

                    const selectedOption = utils.getSelectedOption(tpdOptions, editingItem.timerProfileDailyId);
                    return (
                        <Select
                            placeholder=""
                            name="timerProfileDailyId"
                            value={selectedOption && selectedOption.value}
                            options={tpdOptions}
                            className="zone-config-select-profile"
                            onChange={(option) => {
                                if (option) {
                                    editingItem.timerProfileDailyId = option.value;
                                    this.setState({ editingItem })
                                }
                            }}
                        />
                    );
                }
                else {
                    const selectedOption = utils.getSelectedOption(tpdOptions, row.value);
                    const label = (selectedOption ? selectedOption.label : "");
                    return (<div className="no-overflow" >{label}</div>);
                }
            }
        });

        columns.push({
            Header: t("Data ora inizio"),
            accessor: "startDate",
            sortable: true,
            filterable: false,
            resizable: false,
            Cell: (row) => {
                const isEditing = (row.index === editingIndex);
                if (isEditing) {
                    //let startDate = editingItem.startDate ? moment(editingItem.startDate, moment.defaultFormat).format(DateTimeFormat) : "";
                    let startDate = editingItem.startDate ? moment(editingItem.startDate, moment.defaultFormat).format(DateFormat) : "";

                    return (
                        <React.Fragment>
                            {validationError && validationError.field === "startDate" &&
                                <span className="validationError" >{validationError.error}</span>
                            }

                            <Datetime
                                key={"start_" + this.state.starttm}
                                dateFormat={DateFormat}
                                timeFormat={false}
                                utc={true}
                                value={startDate}
                                className="zone-config-start-date"
                                onChange={(time) => {
                                    if (time && time instanceof moment) {
                                        if (!time.isValid()) {
                                            this.setState({ validationError: { field: 'startDate', error: t("Formato data ora non valido") } });
                                            return;
                                        }

                                        editingItem.startDate = time.format(moment.defaultFormat);

                                        if (!this.validateStartStop(time.format(moment.defaultFormat), editingItem.stopDate)) {
                                            this.setState({ validationError: { field: 'startDate', error: t("Data ora inizio successiva a data ora fine") } });
                                            return;
                                        }
                                        else {
                                            this.setState({ editingItem, validationError: null })
                                        }

                                    }
                                }}
                                onBlur={(time) => {
                                    if (!(time instanceof moment)) {
                                        time = moment(time, DateTimeFormat);
                                        if (!time.isValid()) {
                                            this.setState({ starttm: Date.now(), validationError: { field: 'startDate', error: t("Formato data ora non valido") } })
                                            return;
                                        }
                                    }

                                    editingItem.startDate = time.format(moment.defaultFormat);

                                    if (!this.validateStartStop(time.format(moment.defaultFormat), editingItem.stopDate)) {
                                        this.setState({ starttm: Date.now(), validationError: { field: 'startDate', error: t("Data ora inizio successiva a data ora fine") } });
                                        return;
                                    } else {
                                        this.setState({ starttm: Date.now(), editingItem, validationError: null })
                                    }
                                }}
                            />
                        </React.Fragment>
                    )
                }
                else {
                    //let value = moment(row.value).format(DateTimeFormat);
                    let value = moment(row.value).format(DateFormat);
                    return (<div className="no-overflow" align="">{value}</div>)
                }
            }
        });

        columns.push({
            Header: t("Data ora fine"),
            accessor: "stopDate",
            sortable: true,
            filterable: false,
            resizable: false,
            Cell: (row) => {
                let isEditing = (row.index === editingIndex);
                if (isEditing) {
                    //let stopDate = editingItem.stopDate ? moment(editingItem.stopDate).format(DateTimeFormat) : "";
                    let stopDate = editingItem.stopDate ? moment(editingItem.stopDate).format(DateFormat) : "";

                    return (
                        <React.Fragment>
                            {validationError && validationError.field === 'stopDate' &&
                                <span className="validationError">{validationError.error}</span>
                            }

                            <Datetime
                                key={"stop_" + this.state.stoptm}
                                dateFormat={DateFormat}
                                timeFormat={false}
                                utc={true}
                                value={stopDate}
                                className="zone-config-stop-date"
                                onChange={(time) => {
                                    if (time && time instanceof moment) {
                                        if (!time.isValid()) {
                                            this.setState({ validationError: { field: 'stopDate', error: t("Formato data ora non valido") } });
                                            return;
                                        }

                                        editingItem.stopDate = time.format(moment.defaultFormat);

                                        if (!this.validateStartStop(editingItem.startDate, time.format(moment.defaultFormat))) {
                                            this.setState({ validationError: { field: 'stopDate', error: t("Data ora fine precedente a data ora inizio") } });
                                            return;
                                        } else {
                                            this.setState({ editingItem, validationError: null })
                                        }
                                    }
                                }}
                                onBlur={(time) => {
                                    if (!(time instanceof moment)) {
                                        time = moment(time, DateTimeFormat);
                                        if (!time.isValid()) {
                                            this.setState({ stoptm: Date.now(), validationError: { field: 'stopDate', error: t("Formato non valido") } })
                                            return;
                                        }
                                    }

                                    editingItem.stopDate = time.format(moment.defaultFormat);

                                    if (!this.validateStartStop(editingItem.startDate, time.format(moment.defaultFormat))) {
                                        this.setState({ stoptm: Date.now(), validationError: { field: 'stopDate', error: t("Data ora fine precedente a data ora inizio") } });
                                        return;
                                    }
                                    else {

                                        this.setState({ stoptm: Date.now(), editingItem, validationError: null })
                                    }
                                }}
                            />

                        </React.Fragment>

                    );
                } else {
                    //let value = moment(row.value).format(DateTimeFormat);
                    let value = moment(row.value).format(DateFormat);
                    return (<div className="no-overflow" align="">{value}</div>)
                }
            }
        });

        weekDays.forEach((weekDay, bit) => {
            columns.push({
                Header: t(weekDay),
                accessor: "weekDayMask",
                sortable: false,
                filterable: false,
                resizable: false,
                width: 40,
                className: "cb-wrapper",
                Cell: (row) => {
                    let isEditing = (row.index === editingIndex);

                    if (isEditing) {
                        let isChecked = utils.bitTest(editingItem.weekDayMask, bit);
                        return (
                            <Input
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        editingItem.weekDayMask = utils.bitSet(editingItem.weekDayMask, bit);
                                    }
                                    else {
                                        editingItem.weekDayMask = utils.bitClear(editingItem.weekDayMask, bit);
                                    }

                                    this.setState({ editingItem })
                                }}
                            />
                        )
                    } else {
                        let checked = utils.bitTest(row.value, bit);
                        return <Input type="checkbox" disabled checked={checked} />
                    }
                }
            })
        });

        columns.push({
            Header: "",
            accessor: "actions",
            sortable: false,
            filterable: false,
            resizable: false,
            width: 90,
            Cell: (row) => {
                let isEditing = (row.index === editingIndex);

                if (!isEditing) {
                    return (
                        <div className="actions-right">
                            <ButtonWithTooltip
                                id={"pd_edit_" + row.index}
                                color="warning"
                                size="sm"
                                tooltipText={t("Modifica")}
                                onClick={() => {
                                    this.setState({
                                        isNew: false,
                                        editingIndex: row.index,
                                        editingItem: JSON.parse(JSON.stringify(row.original))
                                    });
                                }}
                            >
                                <i className="fa fa-edit" />
                            </ButtonWithTooltip>
                            {" "}
                            <ButtonWithTooltip
                                id={"pd_del_" + row.index}
                                onClick={() => {
                                    this.confirmDelete(
                                        t("Elimina"),
                                        () => {
                                            this.deleteTimerProfileDaily(row.original.id);
                                        }
                                    );
                                }}
                                color="danger"
                                size="sm"
                                tooltipText={t("Elimina")}
                            >
                                {saving ? <i className="fa fa-spinner" /> : <i className="fa fa-trash" />}
                            </ButtonWithTooltip>
                        </div>
                    )
                } else {
                    let time1 = moment(editingItem.startDate).isValid();
                    let time2 = moment(editingItem.stopDate).isValid();
                    let validateStartStop = this.validateStartStop(editingItem.startDate, editingItem.stopDate);
                    let showSaveButton = (editingItem.timerProfileDailyId > 0 && time1 && time2 && validateStartStop);

                    return (
                        <div className="actions-right">
                            <ButtonWithTooltip
                                id={"pd_edit_" + row.index}
                                color="success"
                                size="sm"
                                disabled={!showSaveButton}
                                tooltipText={t("Salva")}
                                onClick={() => {
                                    this.saveTimerProfileDaily();
                                }}
                            >
                                {saving ? <i className="fa fa-spinner" /> : <i className="fa fa-check" />}
                            </ButtonWithTooltip>
                            {" "}
                            <ButtonWithTooltip
                                id={"pd_del_" + row.index}
                                onClick={() => {
                                    if (this.state.isNew) {
                                        dailyProfiles.splice(row.index, 1);
                                        this.setState({ isNew: false, editingIndex: -1, dailyProfiles });
                                    }
                                    else
                                        this.setState({ editingIndex: -1 });
                                }}
                                color="secondary"
                                size="sm"
                                tooltipText={t("Annulla")}
                            >
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    )
                }
            }
        });

        return columns;
    }

    /**
     *
     */
    async saveTimerProfileWeekly() {
        try {
            this.setState({ saving: true });

            const { t, zone } = this.props;
            const { timerProfileWeeklyId, timerProfileWeeklyStartDate } = this.state;

            // M.C. Data Body JSON
            let dataBody = {}
            if (this.legacyTimer === true) {
                dataBody = {
                    timerProfileWeeklyId: timerProfileWeeklyId === 0 ? null : timerProfileWeeklyId,
                    timerProfileWeeklyStartDate
                }
            }
            else {
                dataBody = {
                    timerProfilePendingWeekId: timerProfileWeeklyId === 0 ? null : timerProfileWeeklyId,
                    timerProfileWeeklyStartDate
                }
            }

            let savedItem = await loopbackService.patch(ModelPaths.PLANT, Models.ZONES, zone.id, dataBody);

            if (savedItem) {
                if (this.legacyTimer === true) {
                    zone.timerProfileWeeklyId = timerProfileWeeklyId;
                }
                else {
                    zone.timerProfilePendingWeekId = timerProfileWeeklyId;
                }
                zone.timerProfileWeeklyStartDate = timerProfileWeeklyStartDate;
                this.setState({
                    formEditing: false,
                    saving: false
                });
            }
            else {
                this.setState({ error: t("Impossibile salvare."), saving: false });
            }
        } catch (error) {
            this.setState({ error, saving: false });
        }
    }

    /**
     *
     */
    async saveTimerProfileDaily() {
        try {
            this.setState({ saving: true });
            const { t, zone } = this.props;
            const { editingIndex, editingItem, dailyProfiles } = this.state;
            let result = null;
            if (editingItem.id) {
                // edit
                result = await loopbackService.putFuncname(ModelPaths.PLANT, Models.ZONES, zone.id + "/dayList/" + editingItem.id, editingItem);
            }
            else {
                // add
                result = await loopbackService.postFuncname(ModelPaths.PLANT, Models.ZONES, zone.id + "/dayList", editingItem);
            }

            if (result) {

                if (editingIndex !== -1 && dailyProfiles) {
                    dailyProfiles[editingIndex] = result;
                }
                this.setState({
                    isNew: false,
                    editingItem: null,
                    validationError: null,
                    editingIndex: -1,
                    loading: false,
                    dailyProfiles,
                    saving: false
                });
            } else {
                this.setState({ error: t("Impossibile salvare."), saving: false });
            }

        } catch (error) {
            this.setState({ error, saving: false });
        }
    }

    /**
     *
     * @param {*} id
     */
    async deleteTimerProfileDaily(id) {

        this.setState({ saving: true });
        const { zone } = this.props;
        let { dailyProfiles } = this.state;

        try {
            await loopbackService.deleteFuncname(ModelPaths.PLANT, Models.ZONES, zone.id + "/dayList/" + id);
        } catch (error) {
            // this.setState({ error, saving: false });
        }

        dailyProfiles = dailyProfiles.filter((a) => (a.id !== id));
        this.setState({
            loading: false,
            dailyProfiles,
            saving: false
        });

        this.setState({ alert: null, saving: false });
    }

    /**
     *
     * @param {*} items
     */
    async handleSendRadioControllersConfigClick(ids) {
        const { t, zone } = this.props;
        let { radioControllers } = this.state;
        let radioControllersId = [];
        let cabinetsId = [];

        if (ids.length === 0) {
            return;
        }

        ids.forEach((id) => {
            //M.C.
            //radiocontroller = instances.radiocontrollers[indexes.radiocontrollers[id]];
            //radioControllersId.push(radiocontroller.id);

            // M.C.
            let dev = radioControllers.find((rc) => rc.id === id);
            if (dev.isAndros) {
                cabinetsId.push(dev.id);
            }
            else {
                radioControllersId.push(dev.id);
            }
        });

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Invia configurazione")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={() => {
                            // Apro uno spinner
                            this.setState({
                                alert: (
                                    <Modal isOpen={true} className="modal-umpi-container">
                                        <ModalHeader toggle={() => {
                                            this.setState({
                                                alert: null
                                            });
                                        }}>
                                            {t("Operazione in corso...")}
                                        </ModalHeader>
                                        <ModalBody>
                                            <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                                <ClipLoader
                                                    sizeUnit={"px"}
                                                    size={60}
                                                    color={'#114d8c'}
                                                    loading={true}
                                                />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                )
                            }, async () => {
                                try {
                                    let ret = await loopbackService.postFuncname(ModelPaths.PLANT, 'zones/' + zone.id, 'sendTimerProfileConfiguration', { "radioControllers": radioControllersId, "cabinets": cabinetsId });

                                    this.setState({
                                        alert: (
                                            <Modal isOpen={true} className="modal-umpi-container">
                                                <ModalHeader toggle={() => {
                                                    this.setState({
                                                        alert: null
                                                    });
                                                }}>
                                                    {t("Esito operazione")}
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                        <i className='fa fa-exclamation-circle' />
                                                    </div>
                                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                        {(
                                                            (ret.result === 'SUCCESS') ?
                                                                t("Configurazione inviata con successo") :
                                                                t("Invio configurazione fallito")
                                                        )}
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            alert: null
                                                        });
                                                    }} color="success" className="umpi-modal-btn-save">
                                                        {t("Confirm")}
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        )
                                    });
                                }
                                catch (error) {
                                    this.setState({
                                        alert: null,
                                        errors: [error],
                                        loading: false
                                    });
                                }
                            });
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }
}

export default ZoneConfiguration = withTranslation()(ZoneConfiguration);

import { projectConstants } from '../constants';
import { projectService } from '../services';
import { history, permissions } from '../helpers';
import User from '../entities/User';
import commonActionCreatorHelper from './commonActionCreatorHelper';

export const projectActions = {
    getAll,
    getById,
    edit,
    /**
     * Questo helper aggiunge le action
     *
     * filterCount,
     * filter,
     * delete
     */
    ...(commonActionCreatorHelper.createCrudActions({
        customizeFilters: function (filters) {
            if (permissions.grant('SHOW_ONLY_OWN_PROJECTS')) {
                const user = User.current()
                const userId = user.id;
                filters.where = filters.where || {};
                filters.where.members = filters.where.members || {};
                filters.where.members.like = "%\"" + userId + "\"%";
            }
        },
        service: projectService,

        filterAllNoLimitRequestActionCreator: function request(filters) { return { type: projectConstants.FILTER_ALL_NO_LIMIT_REQUEST, filters } },
        filterAllNoLimitSuccessActionCreator: function success(projects) { return { type: projectConstants.FILTER_ALL_NO_LIMIT_SUCCESS, projects } },
        filterAllNoLimitFailureActionCreator: function failure(error) { return { type: projectConstants.FILTER_ALL_NO_LIMIT_FAILURE, error } },

        filterCountRequestActionCreator: function request(filters) { return { type: projectConstants.FILTER_COUNT_REQUEST, filters } },
        filterCountSuccessActionCreator: function success(count, limit) { return { type: projectConstants.FILTER_COUNT_SUCCESS, count, limit } },
        filterCountFailureActionCreator: function failure(error, limit) { return { type: projectConstants.FILTER_COUNT_FAILURE, error, limit } },

        filterRequestActionCreator: function request(filters) { return { type: projectConstants.FILTER_REQUEST, filters } },
        filterSuccessActionCreator: function success(projects) { return { type: projectConstants.FILTER_SUCCESS, projects } },
        filterFailureActionCreator: function failure(error) { return { type: projectConstants.FILTER_FAILURE, error } },

        deleteRequestActionCreator: function request(id) { return { type: projectConstants.DELETE_REQUEST, id } },
        deleteSuccessActionCreator: function success(id) { return { type: projectConstants.DELETE_SUCCESS, id } },
        deleteFailureActionCreator: function failure(id, error) { return { type: projectConstants.DELETE_FAILURE, id, error } },

    })),
};

function getById(projectId, callback) {
    return async (dispatch) => {
        dispatch(request(projectId));
        try {
            let result = await projectService.getById(projectId, { include: 'users' });
            dispatch(success(result));
            if (typeof callback === "function") {
                callback(result)
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };
    function request(projectId) { return { type: projectConstants.GETBYID_REQUEST, projectId } }
    function success(project) { return { type: projectConstants.GETBYID_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.GETBYID_FAILURE, error } }
}

function getAll(customFilter = null) {
    return async (dispatch) => {
        dispatch(request());
        try {
            const filter = customFilter ? customFilter : {
                include: {
                    relation: 'users',
                }
            };
            let projects = await projectService.getAll(filter);
            localStorage.setItem('projects', JSON.stringify(projects));
            dispatch(success(projects))
        } catch (error) {
            dispatch(failure(error));
            if (error === 'Unauthorized') {
                history.push('/pages/login-page');
            }
        }
    };
    function request() { return { type: projectConstants.GETALL_REQUEST } }
    function success(projects) { return { type: projectConstants.GETALL_SUCCESS, projects } }
    function failure(error) { return { type: projectConstants.GETALL_FAILURE, error } }
}

function edit(item, toRemoveMembers = [], toAddMembers = [], callback = null) {
    return async (dispatch) => {
        dispatch(request(item));
        let response = null;
        try {
            let project = await projectService.edit(item);

            toAddMembers.map(async (userId) => {
                await projectService.addUser(project.id, userId);
            });

            toRemoveMembers.map(async (userId) => {
                await projectService.delUser(project.id, userId);
            });

            response = success(project);

            dispatch(
                this.filterCount({
                    filters: null,
                    next: (filters) => {
                        let skip = filters.skip;
                        dispatch(this.filter({ ...filters, skip: skip }));
                    }
                }))
            //history.push('/projects');
        }
        catch (error) {
            response = failure(error);
        }
        finally {
            dispatch(response);
            if (typeof callback === "function") {
                callback(response);
            }
        }
    };
    function request(project) { return { type: projectConstants.EDIT_REQUEST, project } }
    function success(project) { return { type: projectConstants.EDIT_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.EDIT_FAILURE, error } }
}

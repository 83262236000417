import { loopbackService } from '../../services';
import { loopbackConstants, ItemTypes } from '../../constants';
import moment from 'moment';
import { accessToken } from '../../helpers';

export class Updater {

    constructor(navigator) {
        this.navigator = navigator;
        this.eventSource = null;
        this.ignoreUpdate = false;
        // this.worker = null;
    }

    formatPeriferalValue(item) {
        if (item.statusReported) {
            const parts = item.periferal.split('/');
            const { status, value, value_u, outstatus, pacttot, pacttotu, consumption, consumptionu } = item.statusReported;
            switch (parts[0]) {
                case 'DIG-IN':
                    return status;
                case 'AN-IN':
                    return value + ' ' + value_u;
                case 'DIG-OUT':
                    return outstatus;
                case 'AN-OUT':
                    return value + ' ' + value_u;
                case 'K1LO':
                    return pacttot + ' ' + pacttotu + ' ' + consumption + ' ' + consumptionu;
                case 'ISTATE':
                    return value;
                default: break;
            }
        }
        return null;
    }

    formatPeriferal(item) {
        let txt = item.periferal;
        if (item.statusReported) {
            txt += "\t" + this.formatPeriferalValue(item);
            txt += " " + moment.unix(item.statusReported.timestamp).format('DD/MM/YYYY HH:mm:ss');
        }
        else {
            txt += "\t-";
            txt += "-";
        }
        txt += "\t";
        if (item.statusDesired) {
            txt += "\t" + item.statusDesired.value;
            txt += " " + moment.unix(item.statusDesired.timestamp).format('DD/MM/YYYY HH:mm:ss');
        }
        else {
            txt += "\t-";
            txt += "-";
        }

        return txt;
    }

    /**
     *
     * @param {*} status
     * @param {*} item
     * @param {*} lightpointdevicetypes
     * @param {*} cabinetdevicetypes
     */
    updateStatus(status, item, lightpointdevicetypes, cabinetdevicetypes) {

        if (this.ignoreUpdate) {
            return;
        }

        if (!item.periferal) {
            return;
        }

        let device = null;
        let parts = item.periferal.split("/");

        if (parts[0] === 'SYRAS') {

            // stato periferica lightpoint
            let addressCode = parseInt(parts[1], 16);

            device = this.devicesByAddressCode[item.deviceId] && this.devicesByAddressCode[item.deviceId][addressCode];

            if (device && device.lightPointId) {

                lightpointdevicetypes[device.lightPointId] = device.deviceTypeId;

                if (this.navigator.state.editingPeriferal && this.navigator.state.editingPeriferal.periferal === item.periferal) {
                    status.lightpoints[device.lightPointId].item.statusReported = item.statusReported;
                }
                else {

                    let connection = null;
                    if (status.lightpoints[device.lightPointId] && status.lightpoints[device.lightPointId].connection) {
                        connection = status.lightpoints[device.lightPointId].connection;
                    }
                    status.lightpoints[device.lightPointId] = item;
                    if (connection) {
                        status.lightpoints[device.lightPointId].connection = connection;
                    }
                }

                this.navigator.renderMapFeatureByItem(ItemTypes.LIGHTPOINT, device.lightPointId);

            }

        }

        else if (parts[0] === 'CONNECTION') {
            // if (item.lightPointId && status.lightpoints[item.lightPointId]){
            if (item.lightPointId) {
                if (!status.lightpoints[item.lightPointId]) {
                    status.lightpoints[item.lightPointId] = { connection: item.statusReported.info };
                } else {
                    status.lightpoints[item.lightPointId].connection = item.statusReported.info;
                }
            }
            // if (item.cabinetId && status.cabinets[item.cabinetId]){
            if (item.cabinetId) {
                if (!status.cabinets[item.cabinetId]) {
                    status.cabinets[item.cabinetId] = { connection: item.statusReported.info }
                }
                else {
                    status.cabinets[item.cabinetId].connection = item.statusReported.info;
                }
            }
        }

        else {

            device = this.devicesByMacaddress[item.deviceId];
            if (device && device.cabinetId) {

                // stato periferica cabinet
                if (!status.cabinets[device.cabinetId]) {
                    status.cabinets[device.cabinetId] = {};
                    cabinetdevicetypes[device.cabinetId] = device.deviceTypeId;
                }

                if (this.navigator.state.editingPeriferal && this.navigator.state.editingPeriferal.periferal === item.periferal) {
                    // aggiorno solo lo status reported
                    status.cabinets[device.cabinetId][item.periferal].statusReported = item.statusReported;
                } else {

                    let connection = null;
                    if (status.cabinets[device.cabinetId][item.periferal] && status.cabinets[device.cabinetId][item.periferal].connection) {
                        connection = status.cabinets[device.cabinetId][item.periferal].connection;
                    }
                    status.cabinets[device.cabinetId][item.periferal] = item;
                    if (connection) {
                        status.cabinets[device.cabinetId][item.periferal].connection = connection;
                    }

                }

                this.navigator.renderMapFeatureByItem(ItemTypes.CABINET, device.cabinetId);
            }
        }

    }

    async startRefresh(devices) {

        // return;

        this.ignoreUpdate = false;

        if (devices.length === 0) {
            return;
        }

        //const selectedProjectId = localStorage.getItem("selectedProjectId");

        // indicizzo i devices
        this.devicesByMacaddress = {};
        this.devicesByAddressCode = {};

        for (let device of devices) {

            // il macaddress viene valorizzato per entrambi i tipi di dispositivo
            if (device.addressCode === null || device.addressCode === 0) { //  device.macAddress
                //this.devicesByMacaddress[selectedProjectId+"/"+device.macAddress] = device;
                this.devicesByMacaddress[device.macAddress] = device;
            }
            else if (device.addressCode) {
                if (!this.devicesByAddressCode[device.macAddress]) {
                    this.devicesByAddressCode[device.macAddress] = {};
                }
                this.devicesByAddressCode[device.macAddress][device.addressCode] = device;
            }

        }

        // leggo lo stato corrente dei dispositivi attraverso il model periferals
        // const macAddresses = Object.keys(this.devicesByMacaddress);

        try {

            let periferalsByDeviceIds = await loopbackService.get('plant', 'peripherals', null);

            if (Array.isArray(periferalsByDeviceIds)) {
                let { status, lightpointdevicetypes, cabinetdevicetypes } = this.navigator.state;
                for (let periferalsByDeviceId of periferalsByDeviceIds) {

                    let periferals = periferalsByDeviceId.periferals;
                    const items = periferals && periferals.Items;
                    if (items) {
                        items.map((item) => {
                            this.updateStatus(status, item, lightpointdevicetypes, cabinetdevicetypes);
                            return false;
                        });
                    }
                    this.navigator.setState({ status });
                }
            }
        } catch (response) {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;
            console.error(error);
        }

        this.startStreaming();

    }

    stopRefresh() {
        //console.log('Stop refresh.');
        this.ignoreUpdate = true;
        this.stopStreaming();
        //clearInterval(this.timerID);
    }

    startStreaming() {

        // if (typeof(Worker) !== "undefined") {

        //   this.worker  = new Worker('worker.js');

        //   this.worker.addEventListener('message', (event)=>{
        //     var data = event.data;
        //     console.log('Ricevo dati',data); // the changed object
        //     let { status, lightpointdevicetypes, cabinetdevicetypes } = this.navigator.state;
        //     this.updateStatus(status, data.data, lightpointdevicetypes, cabinetdevicetypes);
        //     this.navigator.setState({
        //       status,
        //       scroll: false
        //     });

        //     // console.group('Message Received');
        //     // console.log( message );
        //     // console.groupEnd();

        //   }, false);

        //   //  This will trigger a message event in the worker

        //   const selectedProjectId = localStorage.getItem("selectedProjectId");

        //   this.worker.postMessage(selectedProjectId);

        // }
        // else {

        //console.log('Avvio streaming....');
        const selectedProjectId = localStorage.getItem("selectedProjectId");
        const filter = { where: { projectId: selectedProjectId } };
        const options = encodeURIComponent(JSON.stringify(filter))
        let urlToChangeStream = loopbackConstants.BASE_URL + '/plant/peripherals/change-stream/' + accessToken('plant') + '&options=' + options;
        this.eventSource = new EventSource(urlToChangeStream);

        this.eventSource.addEventListener('data', (evt) => {
            //var data = JSON.parse(evt.data);
            var data = this.convertPeripheral(JSON.parse(evt.data).data);

            let { status, lightpointdevicetypes, cabinetdevicetypes } = this.navigator.state;
            this.updateStatus(status, data.data, lightpointdevicetypes, cabinetdevicetypes);
            this.navigator.setState({
                status,
                scroll: false
            });
        });

        // }

    }

    stopStreaming() {
        //console.log('Stop streaming...');
        if (this.eventSource) {
            this.eventSource.close();
        }

        // if (this.worker){
        //   this.worker.terminate();
        //   this.worker = null;
        // }

    }

    /**
     * Funzione di riconversione modello periferal
     * @param {*} oldPeripheral
     */
    convertPeripheral(peripheralData) {
        let dataTmp = {
            target: peripheralData.macAddress + "-" + peripheralData.peripheralType + "/" + peripheralData.peripheralCode,
            data: {
                periferalId: peripheralData.macAddress + "-" + peripheralData.peripheralType + "/" + peripheralData.peripheralCode,
                periferal: peripheralData.peripheralType + "/" + peripheralData.peripheralCode,
                projectId: peripheralData.projectId,
                cabinetId: peripheralData.cabinetId,
                lightPointId: peripheralData.lightPointId,
                deviceId: peripheralData.macAddress,
                statusReported: peripheralData.values
            }
        }
        if (peripheralData && peripheralData.statusDesired) {
            dataTmp.data.statusDesired = peripheralData.statusDesired;
        }
        if (peripheralData && peripheralData.label) {
            dataTmp.data.label = peripheralData.label;
        }
        return dataTmp;
    }
}

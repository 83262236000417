import { connect } from 'react-redux';
import React, { Component } from "react";
import ReactTable from "react-table";
import { Card, CardBody, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { PanelHeader } from "components";
import Button from "components/CustomButton/CustomButton.jsx";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from 'actions';
import { loopbackService } from '../../services';
import ErrorModal from "../Components/ErrorModal"
import Loader from "../Components/Loader";
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import { withTranslation } from 'react-i18next';
import { LuminareEdit } from './LuminareEdit';
import { refreshSearchEngine } from '../../components/Header/Header';
import Documents from '../../components/Documents/Documents';

class Luminares extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            error: null,
            filterLuminareByName: null,
            isLuminareEditModalOpen: false,
        }

        selectedProjectChangeListeners.push(() => {
            this.fetchData();
        });
    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchData();

        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            this.setState({
                filterLuminareByName: search_value
            });
        }, this.props.t("Search a luminare"));
    }

    toggleLuminareEditModal = (state, luminareId = 0) => {
        this.setState({
            isLuminareEditModalOpen: (state === true) ? true : false,
            selectedLuminareId: (state === true) ? luminareId : 0
        }, () => {
            if (state === false) {
                this.fetchData();
            }
        });
    }

    fetchData() {
        const { dispatch } = this.props;
        dispatch(loopbackActions.list('plant', 'luminares'));
    }

    deleteConfirm(id) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Cancellazione del corpo illuminante")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            const { dispatch } = this.props;
                            if (id) {
                                dispatch(loopbackActions._delete('plant', 'luminares', id));
                                this.setState({
                                    alert: null
                                });
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.luminares && nextProps.luminares.error) {
            this.setState({ error: nextProps.luminares.error });
        }
    }

    buildData() {
        const { t, luminares } = this.props;
        const { filterLuminareByName } = this.state;
        let data = [];

        if (luminares && luminares.instances) {
            data = luminares.instances.map((instance, key) => {
                if (filterLuminareByName &&
                    !(instance.type && instance.type.toLowerCase().includes(
                        filterLuminareByName.toLowerCase()
                    )) &&
                    !(instance.producer && instance.producer.toLowerCase().includes(
                        filterLuminareByName.toLowerCase()
                    )) &&
                    !(instance.model && instance.model.toLowerCase().includes(
                        filterLuminareByName.toLowerCase()
                    )) &&
                    !(instance.optics && instance.optics.toLowerCase().includes(
                        filterLuminareByName.toLowerCase()
                    ))) {
                    return null;
                }

                return {
                    index: key,
                    ...instance,
                    documents: (
                        <Documents
                            listMode={true}
                            acceptFile={["pdf", "image"]}
                            loadDocumentsFromCallback={async (resolveDocuments) => {
                                // Get project active
                                let selectedProjectId = localStorage.getItem('selectedProjectId');

                                // Create doc response
                                const resp_documents = await loopbackService.listProjectDocumentFromModel(
                                    selectedProjectId,
                                    "LUMINARES",
                                    instance.id
                                );

                                // Send to component
                                resolveDocuments(resp_documents);
                            }}
                        />
                    ),
                    actions: (
                        <div className="actions-right">
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-edit"
                                id={"editLuminare_" + instance.id}
                                onClick={() => this.toggleLuminareEditModal(true, instance.id)}
                                size="sm"
                                tooltipText={t("Modifica")}
                            >
                                <i className="fa fa-pencil" />
                            </ButtonWithTooltip>
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-del"
                                id={"deleteConfirmLuminare_" + instance.id}
                                onClick={() => {
                                    this.deleteConfirm(instance.id);
                                }}
                                size="sm"
                                tooltipText={t("Elimina")}
                            >
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    )
                };
            }).filter(x => x);
        }
        return data;
    }

    render() {
        const { luminares, t } = this.props;
        const { error, isLuminareEditModalOpen } = this.state;
        let loading = luminares && luminares.loading;
        let data = this.buildData();

        return (
            <div>
                {this.state.alert}
                <PanelHeader size="sm" />
                {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Elenco corpi illuminanti")}</h2>
                            <span className="pull-right ">
                                {this.props.additionalTopButtons}
                                {
                                    !this.props.hideNewButton &&
                                    <Button onClick={() => this.toggleLuminareEditModal(true)} color="primary" round size="sm" className="custom-no-margin btn-add-new-users">{t("NewLuminares")}</Button>
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-padding-paper">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        className="-striped -highlight"
                                        previousText={t("Precedente")}
                                        nextText={t('Successiva')}
                                        noDataText={loading ? '' : (isLuminareEditModalOpen ? t('Editing in progress') : t('Nessun risultato'))}
                                        pageText={t('Pagina')}
                                        ofText={t('di')}
                                        rowsText={t('righe')}
                                        LoadingComponent={() => <Loader loading={loading} />}
                                        columns={[
                                            {
                                                Header: t("Tipo"),
                                                accessor: "type",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Produttore"),
                                                accessor: "producer",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Modello"),
                                                accessor: "model",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Ottiche"),
                                                accessor: "optics",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Documents"),
                                                accessor: "documents",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                //Header: () => (<div align="right">{t("Azioni")}</div>),
                                                Header: () => (<div align="right">&nbsp;</div>),
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </div>
                <LuminareEdit
                    luminareId={this.state.selectedLuminareId}
                    isOpenModal={this.state.isLuminareEditModalOpen}
                    onModalClose={() => this.toggleLuminareEditModal(false)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        luminares: loopback.luminares
    };
}

const connectedLuminares = withTranslation()(connect(mapStateToProps)(Luminares));
export { connectedLuminares as Luminares };

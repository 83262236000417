// Box Annotation implementation
module.exports = function (Chart) {
    var helpers = require('../helpers.js')(Chart);

    var BoxAnnotation = Chart.Annotation.Element.extend({
        setDataLimits: function () {
            var model = this._model;
            var options = this.options;
            var chartInstance = this.chartInstance;

            var xScale = chartInstance.scales[options.xScaleID];
            var yScale = chartInstance.scales[options.yScaleID];
            var chartArea = chartInstance.chartArea;

            // Set the data range for this annotation
            model.ranges = {};

            if (!chartArea) {
                return;
            }

            var min = 0;
            var max = 0;

            if (xScale) {
                min = helpers.isValid(options.xMin) ? options.xMin : xScale.getPixelForValue(chartArea.left);
                max = helpers.isValid(options.xMax) ? options.xMax : xScale.getPixelForValue(chartArea.right);

                model.ranges[options.xScaleID] = {
                    min: Math.min(min, max),
                    max: Math.max(min, max)
                };
            }

            if (yScale) {
                min = helpers.isValid(options.yMin) ? options.yMin : yScale.getPixelForValue(chartArea.bottom);
                max = helpers.isValid(options.yMax) ? options.yMax : yScale.getPixelForValue(chartArea.top);

                model.ranges[options.yScaleID] = {
                    min: Math.min(min, max),
                    max: Math.max(min, max)
                };
            }
        },
        configure: function () {
            var model = this._model;
            var options = this.options;
            var chartInstance = this.chartInstance;

            var xScale = chartInstance.scales[options.xScaleID];
            var yScale = chartInstance.scales[options.yScaleID];
            var chartArea = chartInstance.chartArea;

            // clip annotations to the chart area
            model.clip = {
                x1: chartArea.left,
                x2: chartArea.right,
                y1: chartArea.top,
                y2: chartArea.bottom
            };

            var left = chartArea.left,
                top = chartArea.top,
                right = chartArea.right,
                bottom = chartArea.bottom;

            var min, max;

            if (xScale) {
                min = helpers.isValid(options.xMin) ? xScale.getPixelForValue(options.xMin) : chartArea.left;
                max = helpers.isValid(options.xMax) ? xScale.getPixelForValue(options.xMax) : chartArea.right;
                left = Math.min(min, max);
                right = Math.max(min, max);
            }

            if (yScale) {
                min = helpers.isValid(options.yMin) ? yScale.getPixelForValue(options.yMin) : chartArea.bottom;
                max = helpers.isValid(options.yMax) ? yScale.getPixelForValue(options.yMax) : chartArea.top;
                top = Math.min(min, max);
                bottom = Math.max(min, max);
            }

            // Ensure model has rect coordinates
            model.left = left;
            model.top = top;
            model.right = right;
            model.bottom = bottom;

            // Stylistic options
            model.borderColor = options.borderColor;
            model.borderWidth = options.borderWidth;
            model.backgroundColor = options.backgroundColor;
            model.gradientColorStops = options.gradientColorStops;
            model.globalAlpha = options.globalAlpha;
        },
        inRange: function (mouseX, mouseY) {
            var model = this._model;
            return model &&
                mouseX >= model.left &&
                mouseX <= model.right &&
                mouseY >= model.top &&
                mouseY <= model.bottom;
        },
        getCenterPoint: function () {
            var model = this._model;
            return {
                x: (model.right + model.left) / 2,
                y: (model.bottom + model.top) / 2
            };
        },
        getWidth: function () {
            var model = this._model;
            return Math.abs(model.right - model.left);
        },
        getHeight: function () {
            var model = this._model;
            return Math.abs(model.bottom - model.top);
        },
        getArea: function () {
            return this.getWidth() * this.getHeight();
        },
        draw: function () {
            var view = this._view;
            var ctx = this.chartInstance.chart.ctx;

            ctx.save();

            // Canvas setup
            ctx.beginPath();
            ctx.rect(view.clip.x1, view.clip.y1, view.clip.x2 - view.clip.x1, view.clip.y2 - view.clip.y1);
            ctx.clip();

            ctx.lineWidth = view.borderWidth;
            ctx.strokeStyle = view.borderColor;
            ctx.globalAlpha = view.globalAlpha;

            // Draw
            var width = view.right - view.left,
                height = view.bottom - view.top;

            if (view.gradientColorStops) {
                var my_gradient = ctx.createLinearGradient(view.left, view.bottom, view.right, view.bottom);
                view.gradientColorStops.map((item) => {
                    my_gradient.addColorStop(item.stop, item.color);
                    return false;
                });
                ctx.fillStyle = my_gradient;
            } else {
                ctx.fillStyle = view.backgroundColor;
            }

            ctx.fillRect(view.left, view.top, width, height);
            if (view.borderWidth > 0) {
                ctx.strokeRect(view.left, view.top, width, height);
            }
            ctx.restore();
        }
    });

    return BoxAnnotation;
};

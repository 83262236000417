export const chartConstants = {
    PIE_CHART: 'PIE_CHART',
    BAR_CHART: 'BAR_CHART',
    BAR_CHART_KWH: 'BAR_CHART_KWH',
    BAR_CHART_STATS_ANNUAL: 'BAR_CHART_STATS_ANNUAL',
    BAR_CHART_STATS_ANNUAL_DELTA: 'BAR_CHART_STATS_ANNUAL_DELTA',
    HORIZONTAL_BAR_CHART: 'HORIZONTAL_BAR_CHART',
    LINE_CHART: 'LINE_CHART',
    AREA_CHART: 'AREA_CHART',
    COMPOSED_CHART: 'COMPOSED_CHART',
    TABLE: 'TABLE',
    TABLE_STATUS_COLOR: 'TABLE_STATUS_COLOR',
    MODEM_INFO: 'MODEM_INFO',
    BASIC_CHART: 'BASIC_CHART',
    ANDROS: "andros",
    WATTMETER: "wattmeter",
    DIGITALIO: "digitalIO",
    ANALOGICALIO: "analogicalIO",
    GROUPS: "groups",
    MODEM: "modem",
    MULTI: "multi",
    DIG_IN: "DIG-IN",
    AN_IN: "AN-IN",
    DIG_OUT: "DIG-OUT",
    AN_OUT: "AN-OUT",
    ISTATE: "ISTATE",
    SYRA: "SYRA",
    TABS: [
        "andros",
        "wattmeter",
        "digitalIO",
        "analogicalIO",
        "modem",
        "groups",
        // "multi"
    ]
};

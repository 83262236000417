export const projectConstants = {

    RESET: 'PROJECT_RESET',
    
    GETALL_REQUEST: 'PROJECT_GETALL_REQUEST',
    GETALL_SUCCESS: 'PROJECT_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROJECT_GETALL_FAILURE',

    GETBYID_REQUEST: 'PROJECT_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'PROJECT_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PROJECT_GETBYID_FAILURE',

    EDIT_REQUEST: 'PROJECT_EDIT_REQUEST',
    EDIT_SUCCESS: 'PROJECT_EDIT_SUCCESS',
    EDIT_FAILURE: 'PROJECT_EDIT_FAILURE',
    
    DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROJECT_DELETE_FAILURE',

    FILTER_COUNT_REQUEST: 'PROJECT_FILTER_COUNT_REQUEST',
    FILTER_COUNT_SUCCESS: 'PROJECT_FILTER_COUNT_SUCCESS',
    FILTER_COUNT_FAILURE: 'PROJECT_FILTER_COUNT_FAILURE',

    FILTER_REQUEST: 'PROJECT_FILTER_REQUEST',
    FILTER_SUCCESS: 'PROJECT_FILTER_SUCCESS',
    FILTER_FAILURE: 'PROJECT_FILTER_FAILURE',

    FILTER_ALL_NO_LIMIT_REQUEST: 'PROJECT_FILTER_ALL_NO_LIMIT_REQUEST',
    FILTER_ALL_NO_LIMIT_SUCCESS: 'PROJECT_FILTER_ALL_NO_LIMIT_SUCCESS',
    FILTER_ALL_NO_LIMIT_FAILURE: 'PROJECT_FILTER_ALL_NO_LIMIT_FAILURE',

    getBasePath
};


function getBasePath(){
    if (process.env.NODE_ENV==='development'){
        return '';
    }
    else {
        return '/minoslive';
    }
}
import React from "react";
import { Container } from "reactstrap";
import umpiBrand from "assets/img/umpi-brand-white.png";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"custom-footer footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <nav>
            <ul>
              <li><a href="http://www.umpi.it"><img src={umpiBrand} height="35px" width="94px" alt="umpi-logo" /></a>
              </li>
            </ul>
          </nav>

        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;

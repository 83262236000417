import { connect } from 'react-redux';
import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from "reactstrap";
import { PanelHeader } from "components";
import Clearfix from "../../components/Custom/Clearfix";
import { withTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import moment from 'moment';

class Info extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            error: null
        }
    }

    render() {
        //const { error } = this.state;
        //const { t } = this.props;

        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <h2 className="color-blue-umpi selected-item" style={{ marginBottom: '15px' }}>
                                Umpi X-Perience
                            </h2>
                            <Card className="info-and-user-card">
                                <CardHeader className="config-card-header sub-header">
                                    <CardTitle className="" >
                                        <Clearfix />
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h6 style={{ fontWeight: "bold" }}> Ver. {packageJson.version}</h6>
                                </CardBody>
                                <CardFooter>
                                    <p>&copy; {moment().year()} Umpi</p>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        drivers: loopback.drivers
    };
}

const connectedInfo = withTranslation()(connect(mapStateToProps)(Info));
export { connectedInfo as Info };

import { connect } from 'react-redux';
import React, { Component } from "react";
import ReactTable from "react-table";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Datetime from "react-datetime";
import ErrorModal from "../Components/ErrorModal"
import DiscreteValues from "../../constants/slider.constants";
import Switch from "react-bootstrap-switch";
import Nouislider from "react-nouislider";
import { ClipLoader } from 'react-spinners';

const DateFormat = "DD/MM/YYYY";
const TimeFormat = "HH:mm";
const DateTimeFormat = DateFormat + " " + TimeFormat;

class ScheduledCommands extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            validationError: null,
        }
    }

    componentWillMount() {
        const { dispatch, cabinet } = this.props;
        //dispatch(loopbackActions.list('plant', 'scheduledcommands', { cabinetId: cabinet.id }));
        dispatch(loopbackActions.listRelatedModel('plant', 'cabinets/' + cabinet.id, 'scheduledcommands'));
    }

    componentWillReceiveProps(nextProps) {
        const { t, handleScheduledCommandsClose } = this.props;

        if (nextProps.scheduledcommands) {
            if (nextProps.scheduledcommands.error) {
                // Se il problema è un TIMEOUT di lettura configrazione
                if (nextProps.scheduledcommands.error.code === "ERROR_TIMEOUT_CONFIGURATION_READ") {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({ alert: null, error: null, loading: false, scheduledcommands: [] }, function () {
                                        handleScheduledCommandsClose();
                                    });
                                }}>
                                    {t("Esito operazione")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                        <i className='fa fa-exclamation-circle' />
                                    </div>
                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                        {t("Errore di comunicazione")}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.setState({ alert: null, error: null, loading: false, scheduledcommands: [] }, function () {
                                            handleScheduledCommandsClose();
                                        });
                                    }} color="success" className="umpi-modal-btn-save">
                                        {t("Confirm")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        )
                    });
                }
                else {
                    this.setState({ error: { error: nextProps.scheduledcommands.error } });
                }
            }
            else if (nextProps.scheduledcommands.loading) {
                this.setState({ loading: true });
            }
            else if (nextProps.scheduledcommands.instances) {
                this.setState({ loading: false, scheduledCommands: nextProps.scheduledcommands.instances });
            }
            else if (nextProps.scheduledcommands.instance) {
                // assegno
                let { scheduledCommands, editingIndex } = this.state;

                if (editingIndex !== -1 && scheduledCommands) {
                    scheduledCommands[editingIndex] = nextProps.scheduledcommands.instance;
                }

                this.setState({
                    isNew: false,
                    editingItem: null,
                    validationError: null,
                    editingIndex: -1,
                    loading: false,
                    scheduledCommands
                });
            }
            else if (nextProps.scheduledcommands.deleted) {

                let { scheduledCommands } = this.state;
                if (scheduledCommands) {
                    scheduledCommands = scheduledCommands.filter((a) => (a.id !== nextProps.scheduledcommands.deletedId));
                    this.setState({
                        scheduledCommands
                    });
                }

            }
            else if (nextProps.scheduledcommands.send) {
                if (nextProps.scheduledcommands.send.loading) {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Invio configurazione...")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                        <ClipLoader
                                            sizeUnit={"px"}
                                            size={60}
                                            color={'#114d8c'}
                                            loading={true}
                                        />
                                    </div>
                                </ModalBody>
                            </Modal>
                        )
                    });
                    return;
                }
                if (nextProps.scheduledcommands.send.res) {
                    if (nextProps.scheduledcommands.send.res.response) {
                        this.setState({
                            alert: (
                                <Modal isOpen={true} className="modal-umpi-container">
                                    <ModalHeader toggle={() => {
                                        this.setState({
                                            alert: null
                                        });
                                    }}>
                                        {t("Esito operazione")}
                                    </ModalHeader>
                                    <ModalBody>
                                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                            <i className='fa fa-exclamation-circle' />
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                            {(
                                                (nextProps.scheduledcommands.send.res.response.result === 'SUCCESS') ?
                                                t("Configurazione inviata con successo") :
                                                t("Invio configurazione fallito")
                                            )}
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={() => {
                                            this.setState({
                                                alert: null
                                            });
                                        }} color="success" className="umpi-modal-btn-save">
                                            {t("Confirm")}
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            )
                        });
                    }
                }
            }
        }
    }

    getOutputOptions() {
        const { status, cabinet, t } = this.props;
        const items = status.cabinets[cabinet.id];
        let language = localStorage.getItem('i18nextLng');

        if (items === null) {
            return [];
        }

        const itemsKey = Object.keys(items);
        let outputOptions = [];

        itemsKey.filter((key) => { return !key.startsWith('undefined') }).map((key) => {
            let item = items[key];
            const parts = key.split('/');
            if (parts[0] === 'DIG-OUT') {
                if (item.statusReported && typeof (item.statusReported.outstatus) !== 'undefined') {
                    // Recupero etichetta periferica
                    let peripheralLabel = '';
                    if (item.label && item.label[language] !== undefined) {
                        peripheralLabel = ' ' + item.label[language];
                    }
                    else {
                        peripheralLabel = ' ' + item.label['en'];
                    }
                    outputOptions.push({
                        value: parts[1],
                        label: parts[1] + peripheralLabel,
                        type: 'DIG-OUT'
                    });
                }
            }
            else if (parts[0] === 'AN-OUT') {
                //if (item.statusReported && typeof (item.statusReported.outstatus) !== 'undefined') {
                outputOptions.push({
                    value: parts[1],
                    label: parts[1] + (item.label ? ' ' + item.label : ""),
                    type: 'AN-OUT'
                });
                //}
            }
            else if (parts[0] === 'ISTATE') {
                if (item.statusReported && typeof (item.statusReported.status) !== 'undefined') {
                    if (parts[1].startsWith("001") && parts[1] !== "0010") {
                        // stato dei gruppi
                        let gruppo = parseInt(parts[1].substring(parts[1].length - 1, parts[1].length), 16);
                        outputOptions.push({
                            //value: parts[1],
                            label: t("Gruppo") + " " + gruppo,
                            value: item.statusReported && item.statusReported.value,
                            item,
                            type: 'GRP'
                        });
                    }
                }
            }
            return false;
        });

        return outputOptions;
    }

    getSelected(options, value) {
        if (value === null)
            return null;
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        };
        return null;
    }

    confirmDelete(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    confirmSendCommand(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    render() {
        const { t, cabinet, handleScheduledCommandsClose, dispatch } = this.props;
        const { loading, scheduledCommands, error, editingIndex, editingItem, validationError } = this.state;
        let language = localStorage.getItem('i18nextLng');

        /*
            "startDateTime": "2019-07-11T10:59:42.476Z",
            "endDateTime": "2019-07-11T10:59:42.476Z",
            "output": "string",
            "value": 0,
            "id": 0,
            "cabinetId": 0
        */
        let outputOptions = this.getOutputOptions();

        return (
            <Modal size="lg" isOpen={true} className="modal-umpi-container fullscreen-modal-dialog ScheduledCommands">
                <ModalHeader toggle={handleScheduledCommandsClose}>
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    {t("Comandi schedulati")}
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardHeader className="config-card-header">
                            <CardTitle className="config-card-title" >
                                <span style={{ flex: 1 }}>{t("Elenco")}</span>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {this.state.alert}
                            <ReactTable
                                loading={loading}
                                data={scheduledCommands}
                                className="-striped -highlight configurations"
                                pageSize={scheduledCommands && scheduledCommands.length}
                                showPagination={false}
                                NoDataComponent={() => null}
                                LoadingComponent={() => {
                                    if (loading) {
                                        return (
                                            <div className="-loading -active">
                                                <div className="-loading-inner custom-margin-0-auto">
                                                    <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                }
                                }
                                columns={[
                                    {
                                        Header: t("Uscita"),
                                        accessor: "output",
                                        filterable: false,
                                        Cell: (row) => {

                                            const isEditing = (row.index === editingIndex);

                                            if (isEditing) {

                                                const selectedOption = this.getSelected(outputOptions, editingItem.output);
                                                return (
                                                    <Select
                                                        placeholder={t("Uscita")}
                                                        name="output"
                                                        value={selectedOption && selectedOption.value}
                                                        options={outputOptions}
                                                        onChange={(option) => {
                                                            if (option) {
                                                                editingItem.output = option.value;
                                                                this.setState({ editingItem })
                                                            }
                                                        }}
                                                    />
                                                );
                                            }
                                            else {
                                                const selectedOption = this.getSelected(outputOptions, row.value);
                                                const label = (selectedOption ? selectedOption.label : "");
                                                return (<div className="no-overflow" >{label}</div>);
                                            }
                                        }

                                    },
                                    {
                                        Header: t("Valore"),
                                        accessor: "value",
                                        filterable: false,
                                        Cell: (row) => {

                                            const isEditing = (row.index === editingIndex);

                                            if (isEditing) {

                                                const selectedOption = this.getSelected(outputOptions, editingItem.output);

                                                if (!selectedOption) {
                                                    return null;
                                                }

                                                if (selectedOption.type && selectedOption.type === 'DIG-OUT') {
                                                    // uscita digitale --> switch 0/1
                                                    return (
                                                        <div className="status-table-value" style={{ textAlign: "center" }}>
                                                            <Switch
                                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                                offText={<i className="now-ui-icons ui-1_simple-remove" />}
                                                                onColor={"green"}
                                                                offColor={"lightgray"}
                                                                onChange={(el, state) => {
                                                                    editingItem.value = (state ? 1 : 0);
                                                                    this.setState({ editingItem })
                                                                }}
                                                                value={editingItem.value === 1}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                else if (selectedOption.type && selectedOption.type === 'GRP') {
                                                    // gruppo --> select con range di 15 valori
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", }}>
                                                            <span style={{ flex: 1, paddingLeft: "15px", paddingRight: "15px" }}>
                                                                <Nouislider
                                                                    start={[editingItem.value]}
                                                                    connect={[true, false]}
                                                                    snap={true}
                                                                    range={DiscreteValues.legacy}
                                                                    onSlide={(value) => {
                                                                        //this.setState({ actualLightState: value[0] });
                                                                        editingItem.value = value[0];
                                                                        this.setState({ editingItem })

                                                                    }}
                                                                    onChange={(value) => {
                                                                        editingItem.value = parseInt(value[0], 10);
                                                                        this.setState({ editingItem })
                                                                    }}
                                                                /></span>
                                                            <span style={{ flex: 1, textAlign: "center" }}>
                                                                {(editingItem.value === 0 ? t("SPENTO") : (editingItem.value === 100 ? t("ACCESO") : parseInt(editingItem.value, 10) + " %"))}
                                                            </span>

                                                        </div>
                                                    );
                                                }
                                                else {
                                                    // uscita analogica --> solo valori positivi incluso lo 0
                                                    return (
                                                        <div><Input
                                                            type="number"
                                                            min="0"
                                                            value={editingItem.value}
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                if (value < 0) {
                                                                    let validationError = { field: 'value', error: t('Valori negativi non ammissibili') }
                                                                    this.setState(validationError);
                                                                    return;
                                                                }
                                                                editingItem.value = value;
                                                                this.setState({ editingItem })
                                                            }}
                                                        />
                                                        </div>
                                                    );
                                                }
                                            } else {

                                                const selectedOption = this.getSelected(outputOptions, row.original.output);
                                                if (!selectedOption) {
                                                    return null;
                                                }

                                                if (selectedOption.type && selectedOption.type === 'DIG-OUT') {
                                                    // uscita digitale --> switch 0/1
                                                    return (<div align="center">{row.value ? "ON" : "OFF"}
                                                        {/* {(row.value ?
                                                            <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-check-square-o" aria-hidden="true"></i> :
                                                            <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-square-o" aria-hidden="true"></i>
                                                        )}  */}
                                                    </div>);
                                                }
                                                else if (selectedOption.type && selectedOption.type === 'GRP') {
                                                    // gruppo --> slider
                                                    return (
                                                        <div style={{ textAlign: "center" }}>
                                                            {(row.value === 0 ? t("SPENTO") : (row.value === 100 ? t("ACCESO") : row.value + " %"))}
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    // uscita analogica --> solo valori positivi incluso lo 0
                                                    return (<div className="no-overflow" align="">{row.value}</div>);
                                                }

                                            }
                                        }
                                    },
                                    {
                                        Header: t("Data ora inizio"),
                                        accessor: "startDateTime",
                                        sortable: true,
                                        filterable: false,
                                        Cell: (row) => {

                                            const isEditing = (row.index === editingIndex);

                                            if (isEditing) {

                                                let startDateTime = moment(editingItem.startDateTime, moment.defaultFormatUtc).format(DateTimeFormat);

                                                return (

                                                    <React.Fragment>
                                                        <Datetime
                                                            locale={language}
                                                            dateFormat={DateFormat}
                                                            timeFormat={TimeFormat}
                                                            value={startDateTime}
                                                            className="plant-schedule-config-start-date"
                                                            onChange={(time) => {
                                                                if (time && time instanceof moment) {
                                                                    if (!time.isValid()) {
                                                                        this.setState({ validationError: { field: 'startDateTime', error: t("Formato non valido") } });
                                                                        return;
                                                                    }
                                                                    editingItem.startDateTime = time.format(moment.defaultFormatUtc);
                                                                    this.setState({ editingItem, validationError: null })
                                                                }
                                                            }}
                                                            onBlur={(time) => {
                                                                if (!(time instanceof moment)) {
                                                                    time = moment(time, DateTimeFormat);
                                                                    if (!time.isValid()) {
                                                                        this.setState({ validationError: { field: 'startDateTime', error: t("Formato non valido") } })
                                                                        return;
                                                                    }
                                                                }
                                                                editingItem.startDateTime = time.format(moment.defaultFormatUtc);
                                                                this.setState({ editingItem, validationError: null })
                                                            }}
                                                        />
                                                        {validationError && validationError.field === 'startDateTime' &&
                                                            <span className="validationError" >{validationError.error}</span>
                                                        }
                                                    </React.Fragment>
                                                );
                                            }
                                            else {
                                                let value = moment(row.value, moment.defaultFormatUtc).format(DateTimeFormat);
                                                return (<div className="no-overflow" align="">{value}</div>)
                                            }
                                        }
                                    },
                                    {
                                        Header: t("Data ora fine"),
                                        accessor: "endDateTime",
                                        sortable: true,
                                        filterable: false,
                                        Cell: (row) => {
                                            let isEditing = (row.index === editingIndex);
                                            if (isEditing) {
                                                let endDateTime = moment(editingItem.endDateTime, moment.defaultFormatUtc).format(DateTimeFormat);

                                                return (
                                                    <React.Fragment>
                                                        <Datetime
                                                            locale={language}
                                                            dateFormat={DateFormat}
                                                            timeFormat={TimeFormat}
                                                            value={endDateTime}
                                                            className="plant-schedule-config-stop-date"
                                                            onChange={(time) => {
                                                                if (time && time instanceof moment) {
                                                                    if (!time.isValid()) {
                                                                        this.setState({ validationError: { field: 'endDateTime', error: t("Formato non valido") } });
                                                                        return;
                                                                    }
                                                                    editingItem.endDateTime = time.format(moment.defaultFormatUtc);
                                                                    this.setState({ editingItem, validationError: null })
                                                                }
                                                            }}
                                                            onBlur={(time) => {
                                                                if (!(time instanceof moment)) {
                                                                    time = moment(time, DateTimeFormat);
                                                                    if (!time.isValid()) {
                                                                        this.setState({ validationError: { field: 'endDateTime', error: t("Formato non valido") } })
                                                                        return;
                                                                    }
                                                                }
                                                                editingItem.endDateTime = time.format(moment.defaultFormatUtc);
                                                                this.setState({ editingItem, validationError: null })
                                                            }}
                                                        />
                                                        {validationError && validationError.field === 'endDateTime' &&
                                                            <span className="validationError">{validationError.error}</span>
                                                        }
                                                    </React.Fragment>

                                                );
                                            } else {
                                                let value = moment(row.value, moment.defaultFormatUtc).format(DateTimeFormat);
                                                return (<div className="no-overflow" align="">{value}</div>)
                                            }
                                        }

                                    },
                                    {
                                        Header: "",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false,
                                        resizable: false,
                                        Cell: (row) => {
                                            let isEditing = (row.index === editingIndex);

                                            if (!isEditing) {
                                                return (
                                                    <div className="actions-right">
                                                        <ButtonWithTooltip
                                                            id={"scmd_edit_" + row.index}
                                                            color="warning"
                                                            size="sm"
                                                            tooltipText={t("Modifica")}
                                                            onClick={() => {
                                                                this.setState({
                                                                    isNew: false,
                                                                    editingIndex: row.index,
                                                                    editingItem: JSON.parse(JSON.stringify(row.original))
                                                                });
                                                            }}
                                                        >
                                                            <i className="fa fa-edit" />
                                                        </ButtonWithTooltip>

                                                        {" "}

                                                        <ButtonWithTooltip
                                                            id={"scmd_del_" + row.index}
                                                            onClick={() => {

                                                                this.confirmDelete(
                                                                    t("Elimina comando schedulato"),
                                                                    () => {
                                                                        dispatch(loopbackActions._delete('plant', 'scheduledcommands', row.original.id));
                                                                        this.setState({
                                                                            alert: null
                                                                        });
                                                                    }
                                                                );

                                                            }}
                                                            color="danger"
                                                            size="sm"
                                                            tooltipText={t("Elimina")}
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </ButtonWithTooltip>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="actions-right">
                                                        <ButtonWithTooltip
                                                            id={"scmd_edit_" + row.index}
                                                            color="success"
                                                            size="sm"
                                                            tooltipText={t("Salva")}
                                                            onClick={() => {
                                                                //dispatch(loopbackActions.edit('plant', 'scheduledcommands', editingItem, 'PATCH'));
                                                                if (editingItem.id) {
                                                                    dispatch(loopbackActions.editOwnInstance('plant/cabinets/' + cabinet.id, 'scheduledcommands', editingItem, 'PUT'));
                                                                }
                                                                else {
                                                                    dispatch(loopbackActions.edit('plant/cabinets/' + cabinet.id, 'scheduledcommands', editingItem, 'POST'));
                                                                }
                                                            }}
                                                        >
                                                            <i className="fa fa-check" />
                                                        </ButtonWithTooltip>

                                                        {" "}

                                                        <ButtonWithTooltip
                                                            id={"scmd_del_" + row.index}
                                                            onClick={() => {
                                                                if (this.state.isNew) {
                                                                    scheduledCommands.splice(row.index, 1);
                                                                    this.setState({ isNew: false, editingIndex: -1, scheduledCommands });
                                                                }
                                                                else
                                                                    this.setState({ editingIndex: -1 });
                                                            }}
                                                            color="secondary"
                                                            size="sm"
                                                            tooltipText={t("Annulla")}
                                                        >
                                                            <i className="fa fa-times" />
                                                        </ButtonWithTooltip>
                                                    </div>
                                                )
                                            }
                                        }
                                    }

                                ]}
                            //defaultPageSize={10}
                            />
                            <div className="add-profile-container">
                                <Button
                                    color="default"
                                    onClick={
                                        () => {
                                            if (this.state.isNew) {
                                                return;
                                            }
                                            let item = {
                                                cabinetId: cabinet.id,
                                                startDateTime: moment().add(5, 'minutes'),
                                                endDateTime: moment().add(1, 'hours'),
                                                output: "",
                                                value: ""
                                            };
                                            scheduledCommands.unshift(item);
                                            this.setState({ isNew: true, scheduledCommands, editingIndex: 0, editingItem: item, validationError: null });
                                        }
                                    }>
                                    <i className="fa fa-plus"></i>
                                    &nbsp;&nbsp;
                                    {t("Nuovo")}
                                </Button>
                                <Button
                                    color="default"
                                    onClick={() => {
                                        this.confirmSendCommand(
                                            t("Invia comandi prioritari"),
                                            () => {
                                                dispatch(loopbackActions.invokeOnInstance('plant', 'cabinets', cabinet.id, 'scheduledcommands/send'));
                                                //dispatch(loopbackActions._delete('plant/cabinets/' + cabinet.id, 'prioritycommands', row.original.id));
                                                this.setState({
                                                    alert: null
                                                });
                                            }
                                        );

                                    }}>
                                    <i className="fa fa-paper-plane"></i>
                                    &nbsp;&nbsp;
                                    {t("Send")}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    return {
        scheduledcommands: loopback.scheduledcommands
    };
}

export default ScheduledCommands = withTranslation()(connect(mapStateToProps)(ScheduledCommands));

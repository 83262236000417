import React, { Suspense } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { history } from 'helpers';
import { alertActions } from 'actions';
import indexRoutes from "routes/index.jsx";
import { PrivateRoute, Loader } from 'components';

class App extends React.Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });

    }

    render() {
        // const { alert } = this.props;
        return (
            <Suspense fallback={null}>
                <Router history={history}>
                    <Switch>
                        {indexRoutes.map((prop, key) => {
                            if (prop.isPrivate) {
                                return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
                            }
                            else {
                                return <Route path={prop.path} key={key} component={prop.component} />;
                            }
                        })}
                    </Switch>
                </Router>
                <Loader />
            </Suspense>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };


import React from "react";
export default function Bell(props) {
    return (
        <svg height={props.height} width={props.width} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xmlSpace="preserve">
            <title>bell</title>
            <g fill={props.fill} stroke={props.stroke} strokeLinecap="square" strokeWidth={props.strokeWidth} >
                <path d="M21.8,18.4c0,0-1.8-2.8-1.8-6.4V8c0-4.4-3.6-8-8-8S4,3.6,4,8v4c0,3.6-1.8,6.4-1.8,6.4c-0.2,0.3-0.2,0.7,0,1 C2.3,19.8,2.6,20,3,20h18c0.4,0,0.7-0.2,0.9-0.5C22.1,19.1,22,18.8,21.8,18.4z" fill={props.fill} />
                <path d="M8.6,22c0.7,1.2,2,2,3.4,2s2.7-0.8,3.4-2H8.6z" />
            </g>
        </svg>
    )
}

import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Row,
    Col,
    Input,
    Form,
    FormGroup,
    Label

} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import BaseView from "./BaseView";

class Events extends BaseView {

    constructor(props) {
        super(props);

        let gestioneAllarmeData = this.getGestioneAllarmeData(props);

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            formEditing: false,
            ...gestioneAllarmeData
        }

    }

    getGestioneAllarmeData(props) {
        let emailList = "";
        let emailSubject = "";
        let emailHeader = "";
        let emailFooter = "";
        try {
            emailList = props.conf.configParam.alarmSettings.emailList && props.conf.configParam.alarmSettings.emailList.join(", ");
        } catch (error) {
            //console.log(error);
        }

        try {
            emailSubject = props.conf.configParam.alarmSettings.emailSubject;
            emailHeader = props.conf.configParam.alarmSettings.emailHeader;
            emailFooter = props.conf.configParam.alarmSettings.emailFooter;
        } catch (error) {
            //console.log(error);
        }

        return {
            emailList,
            emailSubject,
            emailHeader,
            emailFooter
        }
    }

    componentWillReceiveProps(nextProps) {

        let gestioneAllarmeData = this.getGestioneAllarmeData(nextProps);
        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem,
            formEditing: false,
            ...gestioneAllarmeData
        });
    }

    renderGestioneAllarme() {
        const { t, dispatch, conf } = this.props;
        let { formEditing, emailList, emailSubject, emailHeader, emailFooter } = this.state;

        return (
            <Card>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >
                        <span style={{ flex: 1 }}>{t("Gestione allarme")}</span>
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    <Form className="form-horizontal">
                        <Row>
                            <Label sm={2}>{t("Invia email a")}</Label>
                            <Col xs={12} sm={10}>
                                <FormGroup>
                                    <Input
                                        disabled={!formEditing}
                                        placeholder={t("email1, email2, email3...")}
                                        name="emailList"
                                        type="text"
                                        value={emailList}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState({ emailList: value });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label sm={2}>{t("Oggetto")}</Label>
                            <Col xs={12} sm={10}>
                                <FormGroup>
                                    <Input
                                        disabled={!formEditing}
                                        name="emailSubject"
                                        type="text"
                                        value={emailSubject}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState({ emailSubject: value });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label sm={2}>{t("Intestazione")}</Label>
                            <Col xs={12} sm={10}>
                                <FormGroup>
                                    <Input
                                        disabled={!formEditing}
                                        name="emailHeader"
                                        type="text"
                                        value={emailHeader}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState({ emailHeader: value });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label sm={2}>{t("Conclusione")}</Label>
                            <Col xs={12} sm={10}>
                                <FormGroup>
                                    <Input
                                        disabled={!formEditing}
                                        name="emailFooter"
                                        type="text"
                                        value={emailFooter}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState({ emailFooter: value });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>

                    {!formEditing &&
                        <div align="right">
                            <ButtonWithTooltip
                                //disabled={true}
                                id={"form_gen_edit"}
                                color="warning"
                                size="sm"
                                tooltipText={t("Modifica")}
                                onClick={() => {
                                    this.setState({
                                        formEditing: true
                                    });
                                }}
                            >
                                <i className="fa fa-edit" />
                            </ButtonWithTooltip>
                        </div>
                    }
                    {formEditing &&
                        <div align="right">
                            <ButtonWithTooltip
                                id={"form_gen_save"}
                                color="success"
                                size="sm"
                                tooltipText={t("Salva")}
                                onClick={() => {
                                    if (!conf.configParam.alarmSettings) {
                                        conf.configParam.alarmSettings = {};
                                    }
                                    emailList = emailList.replace(/ /gi, '');
                                    emailList = emailList.replace(/;/gi, ',');
                                    let emails = emailList.split(',');
                                    conf.configParam.alarmSettings.emailList = emails;
                                    conf.configParam.alarmSettings.emailSubject = emailSubject;
                                    conf.configParam.alarmSettings.emailHeader = emailHeader;
                                    conf.configParam.alarmSettings.emailFooter = emailFooter;
                                    dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                }}
                            >
                                <i className="fa fa-check" />
                            </ButtonWithTooltip>

                            &nbsp;

                            <ButtonWithTooltip
                                id={"form_gen_cancel"}
                                onClick={() => {
                                    let gestioneAllarmeData = this.getGestioneAllarmeData(this.props);
                                    this.setState({ formEditing: false, ...gestioneAllarmeData });
                                }}
                                color="secondary"
                                size="sm"
                                tooltipText={t("Annulla")}
                            >
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    }

                </CardBody>
            </Card>
        );
    }

    renderEventi() {
        const { t, dispatch, loading, conf, params } = this.props;
        const { editingItem, editingIndex } = this.state;

        let optionsPriority = [];
        //let paramsEventTypes = [];

        if (params.androsLiveParams && params.androsLiveParams.eventMessageCustomization) {

            optionsPriority = params.androsLiveParams.eventMessageCustomization.properties.priority.values;

            if (optionsPriority) {
                optionsPriority = optionsPriority.map((option) => {
                    return {
                        value: option.value,
                        label: t(option.label)
                    }
                });
            }

            // attenzione, questo parametro non è utilizzato per ora.
            //paramsEventTypes = params.androsLiveParams.eventMessageCustomization.properties.event.values;

        }

        // eventi memorizzati nella configurazione
        let data = [];
        if (conf && conf.configParam && conf.configParam.eventMessageCustomizations) {
            data = conf.configParam.eventMessageCustomizations.map((evt, key) => {
                return { ...evt, index: key };
            });
        }

        // allieno gli eventi configurati con quelli in configurazione

        return (
            <Card>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >

                        <span style={{ flex: 1 }}>{t("Eventi di sistema")}</span>
                        {/* <Button
                            color="info"
                            onClick={

                                ()=>{
                                if (this.state.isNew){
                                    return;
                                }
                                let item = {
                                    event: "",
                                    label: "",
                                };
                                if (!conf.configParam.eventMessageCustomizations){
                                    conf.configParam.eventMessageCustomizations=[];
                                }
                                conf.configParam.eventMessageCustomizations.unshift(item);
                                this.setState( { isNew: true, conf, editingIndex: 0, editingItem: item });
                                }

                            }
                            style={{  fontSize: 14, fontWeight: 400, margin: 0, padding: '5px'}}
                            >
                            {t("Nuova")}
                            </Button> */}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        data={data}
                        className="-striped -highlight configurations"
                        pageSize={data && data.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[
                            // {
                            //   Header: t("Codice evento"),
                            //   accessor: "event",
                            //   filterable: false,
                            //   Cell: (row)=>{

                            //     const isEditing = (row.original.index===editingIndex);

                            //     if (isEditing){

                            //       const selectedOption = this.getSelected(optionsEvent,editingItem.event);

                            //       return (
                            //           <Select
                            //             // menuContainerStyle = \{ \{position: 'relative'\} \}
                            //             placeholder={t("Codice evento")}
                            //             name="group"
                            //             value={ selectedOption }
                            //             options={ optionsEvent }
                            //             onChange={option => {
                            //               if (option){
                            //                 editingItem.event = option.value;
                            //                 this.setState({ editingItem })
                            //               }
                            //             }}
                            //           />
                            //       );

                            //    } else {
                            //     const selectedOption = this.getSelected(optionsEvent,row.value);
                            //     return (<span>{selectedOption && selectedOption.label}</span>);
                            //    }
                            //   }

                            // },

                            {
                                Header: t("Evento/Allarme"),
                                accessor: "label",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.label}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.label = value;
                                                    this.setState({ editingItem })
                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },
                            {
                                Header: t("Priorità"),
                                accessor: "priority",
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.original.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsPriority, editingItem.priority);

                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Modo")}
                                                name="group"
                                                value={selectedOption}
                                                options={optionsPriority}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.priority = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsPriority, row.value);
                                        return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    }
                                }

                            },

                            {
                                Header: t("Genera allarme"),
                                accessor: "alarm",
                                Cell: (row) => {

                                    if (row.original.event === "READY (HI)" || row.original.event === "READY (LOW)") {
                                        return null;
                                    }

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        if (editingItem.alarm === null) {
                                            editingItem.alarm = false;
                                        }

                                        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >

                                            <Switch
                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                offText={<i className="now-ui-icons ui-1_simple-remove" />}
                                                onColor={"green"}
                                                offColor={"lightgray"}
                                                onChange={(el, state) => {
                                                    editingItem.alarm = state;
                                                    this.setState({ editingItem })
                                                }}
                                                value={editingItem.alarm}
                                            />
                                        </div>
                                        )
                                    }

                                    else {
                                        return (<div align="center">{(row.value ? "ON" : "OFF"
                                            // <i style={{ fontSize: "1.5em", verticalAlign: "middle"}} className="fa fa-check-square-o" aria-hidden="true"></i> :
                                            // <i style={{ fontSize: "1.5em", verticalAlign: "middle"}} className="fa fa-square-o" aria-hidden="true"></i>
                                        )} </div>)

                                    }

                                },
                                filterable: false
                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    //disabled={true}
                                                    id={"evt1_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.original.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"evt1_del_" + row.index}
                                                    onClick={() => {

                                                        this.confirmDelete(
                                                            t("Elimina evento di sistema"),
                                                            () => {

                                                                let eventMessageCustomizations = conf.configParam.eventMessageCustomizations.filter(
                                                                    ((curr, index) => {
                                                                        return (index !== row.original.index)
                                                                    })
                                                                );
                                                                conf.configParam.eventMessageCustomizations = eventMessageCustomizations;
                                                                dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                this.setState({
                                                                    alert: null
                                                                });

                                                            }

                                                        );

                                                    }}
                                                    color="danger"
                                                    size="sm"
                                                    tooltipText={t("Elimina")}
                                                >
                                                    <i className="fa fa-trash" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"evt1_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        conf.configParam.eventMessageCustomizations[row.original.index] = editingItem;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"evt1_del_" + row.index}
                                                    onClick={() => {

                                                        if (this.state.isNew) {
                                                            conf.configParam.eventMessageCustomizations.splice(row.original.index, 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    //defaultPageSize={10}
                    />
                </CardBody>
            </Card>
        );

    }

    render() {
        return (
            <div>
                {this.renderGestioneAllarme()}
                {this.renderEventi()}
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default Events = withTranslation()(connect(mapStateToProps)(Events));

import { connect } from 'react-redux';
import React, { Component } from "react";
// react component for creating dynamic tables
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody } from "reactstrap";
import { PanelHeader } from "components";
import { userActions } from 'actions';
import ReactTable from "react-table";
import { utils } from '../../helpers/utils';
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { withTranslation } from 'react-i18next';
import { UserEdit } from './UserEdit';
import { refreshSearchEngine } from '../../components/Header/Header';
import { userService } from '../../services';
import DefaultAvatar from '../../assets/img/umpi/avatar-default.png';
import { userConstants } from '../../constants';

class Users extends Component {

    constructor(props) {
        super(props);

        this.roles = JSON.parse(localStorage.getItem('userroles'));
        this.rolesIndex = utils.doIndex(this.roles, 'id');

        // this.usersOptions = [];
        // let users = JSON.parse(localStorage.getItem('users'));
        // if (users) {
        //   users.map(
        //     (user) => {
        //       this.usersOptions.push({
        //         value: user.id, label: user.name + " " + user.surname
        //       });
        //     }
        //   );
        // }

        this.state = {
            alert: null,
            filterUserByName: null,
            isUserEditModalOpen: false,
            selectedUserId: 0,
            users: props.users && Array.isArray(props.users.items) ? props.users.items : []
        }
    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchData();
        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            this.setState({
                filterUserByName: search_value
            });
        }, this.props.t("Search an user"));
        this.normalizeUser();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.users &&
            Array.isArray(nextProps.users.items) &&
            JSON.stringify(this.state.users) !== JSON.stringify(nextProps.users.items)) {
            this.setState({
                users: nextProps.users.items
            }, () => {
                this.normalizeUser();
            })
        }
    }

    async fetchData() {
        const { dispatch } = this.props;
        dispatch(request());
        try {
            let users = await userService.getAll();
            localStorage.setItem('users', JSON.stringify(users));
            dispatch(success(users));
        } catch (error) {
            dispatch(failure(error));
        }
        function request() { return { type: userConstants.GETALL_REQUEST } }
        function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
        function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
    }

    toggleUserEditModal = (state, userId = 0) => {
        this.setState({
            isUserEditModalOpen: (state === true) ? true : false,
            selectedUserId: (state === true) ? userId : 0
        });
    }

    deleteConfirm(id) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Cancellazione utente")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            const { dispatch } = this.props;
                            if (id) {
                                dispatch(userActions.delete(id));
                                // const limit = this.props.users.limit;
                                // dispatch(userActions.getAll())
                                this.setState({
                                    alert: null
                                });
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    normalizeUser = async () => {
        const p_users = this.state.users;
        if (Array.isArray(p_users)) {
            for (let i = 0; i < p_users.length; i++) {
                // prepare image storage
                p_users[i]["image"] = null;

                try {
                    // try call
                    let image = await userService.getImageByUserId(p_users[i].id);

                    // Check and save
                    if (image && image.base64) {
                        p_users[i]["image"] = image.base64;
                    }
                } catch(exImg) {
                    console.error(exImg);
                }
            }
        }

        this.setState({ users: p_users })
    }

    render() {
        const { t } = this.props;
        const { filterUserByName, users, isUserEditModalOpen} = this.state;
        let loading = this.props.users && this.props.users.loading;

        let data = users.map((item, key) => {
            if (filterUserByName &&
                !(item.email && item.email.toLowerCase().includes(
                    filterUserByName.toLowerCase()
                )) &&
                !(item.name && item.name.toLowerCase().includes(
                    filterUserByName.toLowerCase()
                )) &&
                !(item.surname && item.surname.toLowerCase().includes(
                    filterUserByName.toLowerCase()
                ))) {
                return null;
            }

            let image = (
                <div className="umpi-table-list-image">
                    <img src={(item.image) ? item.image : DefaultAvatar} alt="" />
                </div>
            );

            // const role = this.roles[this.rolesIndex[item.userRoleId]];
            return {
                id: key,
                image: image,
                email: item.email,
                name: item.name,
                surname: item.surname,
                userRoleId: item.userRoleId,
                isActive: item.isActive,
                actions: (
                    <div className="actions-right">
                        <ButtonWithTooltip
                            className="umpi-modal-action-btn-edit"
                            id={"editUser_" + item.id}
                            onClick={() => this.toggleUserEditModal(true, item.id)}
                            size="sm"
                            icon
                            tooltipText={t("Modifica")}>
                            <i className="fa fa-pencil" />
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                            className="umpi-modal-action-btn-del"
                            id={"deleteConfirmUser_" + item.id}
                            onClick={() => { this.deleteConfirm(item.id); }}
                            size="sm"
                            icon
                            tooltipText={t("Elimina")}>
                            <i className="fa fa-times" />
                        </ButtonWithTooltip>
                    </div>
                )
            };
        }).filter(x => x);

        return (
            <div>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t('Users list')}</h2>
                            <span className="pull-right ">
                                {this.props.additionalTopButtons}
                                {
                                    !this.props.hideNewButton &&
                                    <Button onClick={() => this.toggleUserEditModal(true)} color="primary" round="true" size="sm" className="custom-no-margin btn-add-new-users">{t("NewUsers")}</Button>
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-padding-paper">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        className="-striped -highlight"
                                        previousText={t('Precedente')}
                                        nextText={t('Successiva')}
                                        noDataText={loading ? '' : (isUserEditModalOpen ? t('Editing in progress') : t('Nessun risultato'))}
                                        pageText={t('Pagina')}
                                        ofText={t('di')}
                                        rowsText={t('righe')}
                                        LoadingComponent={
                                            () => {
                                                if (loading) {
                                                    return (
                                                        <div className="-loading -active">
                                                            <div className="-loading-inner custom-margin-0-auto">
                                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                        }
                                        columns={[
                                            {
                                                Header: " ",
                                                accessor: "image",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false,
                                                width: 65
                                            },
                                            {
                                                Header: t("Email"),
                                                accessor: "email",
                                                //filterable: !this.props.users.loading
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Nome"),
                                                accessor: "name",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Cognome"),
                                                accessor: "surname",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Ruolo"),
                                                accessor: "userRoleId",
                                                Cell: row => (this.roles[this.rolesIndex[row.value]].role),
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                                /*Filter: ({ filter, onChange }) =>
                                                    <select
                                                        onChange={event => onChange(event.target.value)}
                                                        style={{ width: "100%" }}
                                                        value={filter ? filter.value : ""}
                                                    >
                                                        <option key={0} value="">-</option>
                                                        {this.roles.map(role => <option key={role.id} value={role.id}>{role.role}</option>)}
                                                    </select>*/

                                            },
                                            {
                                                Header: () => (<div align="center">{t("Attivo")}</div>),
                                                accessor: "isActive",
                                                Cell: (row) => (
                                                    row.value ? (
                                                        <div align="center">
                                                            <i className={"now-ui-icons ui-1_check"} style={{ fontSize: "18px" }} />
                                                        </div>
                                                    ) : ""
                                                ),
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                                /*Filter: ({ filter, onChange }) =>
                                                    <select
                                                        onChange={event => onChange(event.target.value)}
                                                        style={{ width: "100%" }}
                                                        value={filter ? filter.value : ""}
                                                    >
                                                        <option key={0} value="">-</option>
                                                        <option key={1} value={true}>{t("SI")}</option>
                                                        <option key={2} value={false}>{t("NO")}</option>
                                                    </select>*/

                                            },
                                            {
                                                //Header: () => (<div align="right">{t("Azioni")}</div>),
                                                Header: () => (<div align="right">&nbsp;</div>),
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <UserEdit
                    userId={this.state.selectedUserId}
                    isOpenModal={this.state.isUserEditModalOpen}
                    onModalClose={() => this.toggleUserEditModal(false)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication } = state;
    return {
        users,
        loggedinUser: authentication.user
    };
}

const connectedUsers = withTranslation()(connect(mapStateToProps)(Users));
export { connectedUsers as Users };

import React, { Component, Fragment } from 'react'
// import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps"
// import { compose, withProps } from "recompose"

import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from 'reactstrap'
import { withTranslation } from 'react-i18next'
import Chart from '../ChartDashboard/Chart.jsx'
import { dashboardService } from '../../services'
import { chartConstants } from '../../constants'
import Datetime from 'react-datetime'
import moment from 'moment'

const formatDate = momentData => {
  return momentData.format('YYYY-MM-DD HH:mm:ss')
}

//const tabs = chartConstants.TABS;
//const periodOptions = [0, 1, 2, 3, 4];
const periods = [
  {
    type: 'live',
    weeks: 0
  },
  {
    type: 'mid',
    weeks: 2
  },
  {
    type: 'his',
    weeks: 24
  },
  {
    type: 'range',
    weeks: -1
  }
]
const LIVE = 'live'
const MID = 'mid'
const HIS = 'his'
const RANGE = 'range'

const pActiveTotalPrefix = 'pActiveTotal'

const pActiveTotalColors = [
  '#114D8C',
  '#FFD62D',
  '#d73a49',
  '#db4cff',
  '#73d584',
  '#ffa74c'
]

class ChartDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      selectedTab: null,
      showControls: false,
      selectedPeriodValue: 0,
      selectedPeriodType: LIVE,
      selectedPeripheralCodeValue: '',
      peripheralCodes: [],
      selectedCode: {}
    }

    // this.handleMapMounted = this.handleMapMounted.bind(this);
    // this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this)
    this.normalizeTimeSeries = this.normalizeTimeSeries.bind(this)
    this.getFromattedAggregateData = this.getFromattedAggregateData.bind(this)
  }

  componentDidMount () {
    // this.loadDashbord(this.props.item.deviceType === 2 ? this.state.selectedTab : null);
    if (this.props.item.deviceType === 2) {
      this.setState({
        selectedTab: 0
      })
    } else {
      this.setState({
        selectedTab: chartConstants.SYRA
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { selectedTab } = this.state
    if (selectedTab !== prevState.selectedTab) {
      this.load(selectedTab)
    }
  }

  load (selectedTab, areControlsChanged) {
    //areCOntrolsChanged serve a non resettare i parametri quando si vuole effettuare una ricerca
    this.setState(
      {
        data: [],
        loading: true,
        showControls: areControlsChanged,
        selectedPeriodType: areControlsChanged
          ? this.state.selectedPeriodType
          : LIVE,
        selectedPeriodValue: areControlsChanged
          ? this.state.selectedPeriodValue
          : 0,
        selectedPeripheralCodeValue: areControlsChanged
          ? this.state.selectedPeripheralCodeValue
          : '',
        selectedCode: {}
      },
      () => {
        //Carico tutti i peripheral se sono nel tab Andros (0) oppure Wattmeters (1)
        if ((selectedTab === 0 || selectedTab === 1) && !areControlsChanged) {
          //Provvedo a cricare i peripheralCode associati al peripheralType K1LO
          this.loadPeripheralCodes(selectedTab)
        } else {
          this.loadDashbord(selectedTab)
        }
      }
    )
  }

  async loadPeripheralCodes (selectedTab) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          //Da cambiare non appena comincio gli altri tab (digitalIO, ecc..)
          const peripheralType =
            selectedTab === 5
              ? 'ISTATE'
              : selectedTab === 0 || selectedTab === 1
              ? 'K1LO'
              : 'AN-IN'
          let peripheralCodes = await dashboardService.getPeripheralCodes(
            this.props.item.projectId,
            this.props.item.device[0].macAddress,
            peripheralType
          )
          this.setState(
            {
              peripheralCodes: peripheralCodes,
              //Occorre avere sempre un peripheralCode selezionato
              selectedPeripheralCodeValue:
                selectedTab === 1 ? peripheralCodes[0] : ''
              //il flag loading sarà correttamente settato dalla funzione loadDashboard
              // loading: false
            },
            () => this.loadDashbord(selectedTab)
          )
        } catch (err) {
          this.setState({
            loading: false
          })
        }
      }
    )
  }

  loadSyraData () {
    /**Faccio un try-catch in quanto non è detto che esista l'item.id nei lightpoints e non ho ne il tempo
     * ne la voglia di mettermi a gestire tutti i casi.
     * Basta intercettare l'errore e mostrare un placeholder nel caso il punto luce non sia ancora configurato.
     */
    try {
      const { item, lightpoints } = this.props
      const peripheralCode = lightpoints[item.id].periferal.split('/')[1]
      let urls = [
        dashboardService.getSyraData(
          this.props.item.projectId,
          this.props.item.device.macAddress,
          peripheralCode,
          this.state.selectedPeriodValue,
          'activePower,lightState',
          this.state.selectedPeriodType
        ),
        dashboardService.getSyraData(
          this.props.item.projectId,
          this.props.item.device.macAddress,
          peripheralCode,
          this.state.selectedPeriodValue,
          'supplyCurrent',
          this.state.selectedPeriodType
        ),
        dashboardService.getSyraData(
          this.props.item.projectId,
          this.props.item.device.macAddress,
          peripheralCode,
          this.state.selectedPeriodValue,
          'supplyVoltage',
          this.state.selectedPeriodType
        ),
        dashboardService.getSyraData(
          this.props.item.projectId,
          this.props.item.device.macAddress,
          peripheralCode,
          this.state.selectedPeriodValue,
          'temperature',
          this.state.selectedPeriodType
        )
      ]
      Promise.all(urls.map(u => fetch(u)))
        .then(responses => Promise.all(responses.map(res => res.json())))
        .then(data => {
          data = this.formatData(chartConstants.SYRA, data)
          this.setState({
            data: data,
            loading: false
          })
        })
        .catch(err => {
          this.setState({
            loading: false
          })
        })
    } catch (err) {
      this.setState({
        data: [[], [], [], []],
        loading: false
      })
    }
  }

  loadModemData () {
    /**Faccio un try-catch in quanto non è detto che esista l'item.id nei cabinets */
    try {
      const { item, cabinets } = this.props
      let modems = Object.keys(cabinets[item.id])
        .filter(key => key.includes('MODEM'))
        .map(modem => cabinets[item.id][modem])
      /**Per ogni modem l'array data sarà organizzato nel seguente modo. Nelle posizioni pari saranno presenti
       * i dati delle chiamate al BE, in quelle dispari invece i dati dello status che si trovano nella prop cabinets.
       * In realtà non è chiaro quanti modem un device possa avere, ma dato che non vengono passati parametri relativi al modem alle chiamate per
       * le statistiche si potrebbe dedurre che ce ne sia uno solo.
       * In ogni caso mi predispongo per avere più modem.
       * Farò quindi una chiamata per ogni modem.
       */
      let urls = modems.map(modem =>
        dashboardService.getModem(
          this.props.item.projectId,
          this.props.item.device[0].macAddress,
          this.state.selectedPeriodValue,
          this.state.selectedPeriodType
        )
      )
      Promise.all(urls.map(u => fetch(u)))
        .then(responses => Promise.all(responses.map(res => res.json())))
        .then(data => {
          // / DATO MOCKATO
          // data[0] = { "signal": 17 }
          data = this.formatData(chartConstants.MODEM_INFO, data, modems)
          this.setState({
            data: data,
            loading: false
          })
        })
        .catch(err => {
          this.setState({
            loading: false
          })
        })
    } catch (err) {
      //In caso di errore setto data in questo modo in quanto verranno visualizzati il grafico a torta e la tabella senza dati.
      this.setState({
        data: [[], []],
        loading: false
      })
    }
  }

  loadAndrosData () {
    const { peripheralCodes, selectedPeripheralCodeValue } = this.state
    // let totalPowerAndCurrentsURL = dashboardService.getTotalPowerAndCurrentsURL(
    //   this.props.item.projectId,
    //   this.props.item.device[0].macAddress,
    //   this.state.selectedPeriodValue,
    //   this.state.selectedPeriodType
    // )
    // if (peripheralCodes && peripheralCodes.length > 1) {
    let totalPowerAndCurrentsURL =
      dashboardService.getTotalPowerAndCurrentsURL_aggregate(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType,
        selectedPeripheralCodeValue
          ? [selectedPeripheralCodeValue]
          : peripheralCodes
      )
    if (peripheralCodes && peripheralCodes.length === 0) {
      totalPowerAndCurrentsURL = null
    }
    // }
    let urls = [
      totalPowerAndCurrentsURL,
      dashboardService.getInternalTensionsOfAndros(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        3,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getInternalTensionsOfAndros(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        4,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getInternalTensionsOfAndros(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        1,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getInternalTensionsOfAndros(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        2,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getManagedPeripherals(
        this.props.item.projectId,
        this.props.item.device[0].macAddress
      )
    ]

    Promise.all(urls.filter(u => u).map(u => fetch(u)))
      .then(responses =>
        Promise.all(
          responses.map((res, i) => {
            return res.json()
          })
        )
      )
      .then(data => {
        if (peripheralCodes && peripheralCodes.length === 0) {
          data.unshift(null)
        }
        data = this.formatData(chartConstants.ANDROS, data)
        this.setState({
          data: data,
          loading: false
        })
      })
      .catch(err => {
        this.setState({
          loading: false
        })
      })
  }

  loadWattmeterData () {
    let urls = [
      dashboardService.getPieData(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        'voltage',
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        'live' /*this.state.selectedPeriodType*/,
        'last'
      ),
      dashboardService.getPieData(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        'current',
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        'live' /*this.state.selectedPeriodType*/,
        'last'
      ),
      dashboardService.getPieData(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        'power',
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        'live' /*this.state.selectedPeriodType*/,
        'last'
      ),
      dashboardService.getPhaseVoltages(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getLineActivePower(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getCurf(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      ),
      dashboardService.getActiveEnergy(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        this.state.selectedPeripheralCodeValue,
        this.state.selectedPeriodValue,
        this.state.selectedPeriodType
      )
    ]
    Promise.all(urls.map(u => fetch(u)))
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(data => {
        data = this.formatData(chartConstants.WATTMETER, data)
        this.setState({
          data: data,
          loading: false
        })
      })
      .catch(err => {
        this.setState({
          loading: false
        })
      })
  }

  loadIOdata (dataType, peripheralTypeIN, peripheralTypeOUT = null) {
    let urls =
      dataType === chartConstants.GROUPS
        ? [
            dashboardService.getPeripheralCodesURL(
              this.props.item.projectId,
              this.props.item.device[0].macAddress,
              peripheralTypeIN
            )
          ]
        : [
            dashboardService.getPeripheralCodesURL(
              this.props.item.projectId,
              this.props.item.device[0].macAddress,
              peripheralTypeIN
            ),
            dashboardService.getPeripheralCodesURL(
              this.props.item.projectId,
              this.props.item.device[0].macAddress,
              peripheralTypeOUT
            )
          ]
    Promise.all(urls.map(u => fetch(u)))
      .then(responses => Promise.all(responses.map(res => res.json())))
      .then(data => {
        if (data) {
          let promises = []
          let selectedCode = {}
          if (data[0] && data[0].length > 0) {
            promises.push(
              this.loadIOchartData(
                peripheralTypeIN,
                data[0][0].peripheralCode,
                this.state.selectedPeriodValue,
                true,
                this.state.selectedPeriodType
              )
            )
            selectedCode[peripheralTypeIN] = data[0][0].peripheralCode
          }
          if (data[1] && data[1].length > 0) {
            promises.push(
              this.loadIOchartData(
                peripheralTypeOUT,
                data[1][0].peripheralCode,
                this.state.selectedPeriodValue,
                true,
                this.state.selectedPeriodType
              )
            )
            selectedCode[peripheralTypeOUT] = data[1][0].peripheralCode
          }
          Promise.all(promises)
            .then(resps => {
              this.setState({
                data: this.formatData(dataType, data.concat(resps)),
                selectedCode: selectedCode,
                loading: false
              })
            })
            .catch(err => {
              this.setState({
                loading: false
              })
            })
        } else {
          this.setState({
            loading: false
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        })
      })
  }

  async loadIOchartData (
    peripheralType,
    peripheralCode,
    weeks,
    returnPromise,
    type
  ) {
    if (returnPromise) {
      return dashboardService.getIO(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        peripheralType,
        peripheralCode,
        weeks,
        type
      )
    }
    try {
      let chartData = await dashboardService.getIO(
        this.props.item.projectId,
        this.props.item.device[0].macAddress,
        peripheralType,
        peripheralCode,
        weeks,
        type
      )
      // let dataIndex = peripheralType === chartConstants.DIG_IN ? 0 : 1;
      let dataIndex
      switch (peripheralType) {
        case chartConstants.ISTATE:
        case chartConstants.DIG_IN:
        case chartConstants.AN_IN: {
          dataIndex = 1
          break
        }

        case chartConstants.DIG_OUT:
        case chartConstants.AN_OUT: {
          dataIndex = 3
          break
        }

        default:
          break
      }
      let data = this.state.data
      data[dataIndex] = this.normalizeTimeSeries(chartData)
      this.setState({
        data: data,
        loading: false
      })
    } catch (err) {
      this.setState({
        loading: false
      })
    }
  }

  normalizeTimeSeries (data) {
    //----------------------------------| TIMESERIES SUPPORT
    if (Array.isArray(data)) {
      return data
        .map(item => {
          if (item && typeof tsMillis === 'number') {
            return {
              ...item,
              ts: item.tsMillis
            }
          } else if (typeof item.ts === 'string') {
            let tmpunix = moment(item.ts, 'YYYY-MM-DD HH:mm:ss')
            if (tmpunix.isValid()) {
              return {
                ...item,
                ts: tmpunix.valueOf()
              }
            }
          }
          return item
        })
        .filter(x => x)
    } else {
      return data
    }
    //----------------------------------| TIMESERIES SUPPORT
  }

  getFromattedAggregateData (data) {
    const { peripheralCodes } = this.state
    let formattedData = []
    if (data && Array.isArray(peripheralCodes)) {
      peripheralCodes.forEach((code, i) => {
        let codeData = []
        if (data[code]) {
          codeData = data[code].map(d => {
            return {
              ...d,
              // [`${pActiveTotalPrefix}${code}`]: d.pActiveTotal
              [`${pActiveTotalPrefix}`]: d.pActiveTotal
            }
          })
        }
        //Normalizzo il TS e ordino i dati
        // formattedData = formattedData.concat(
        //   this.normalizeTimeSeries(codeData).sort((a, b) =>
        //     a.ts < b.ts ? 1 : -1
        //   )
        // )

        formattedData = this.merge(
          formattedData,
          codeData,
          pActiveTotalPrefix,
          code
        )
      })
    }
    return this.normalizeTimeSeries(formattedData)
  }

  formatData (tab, data, modems = null) {
    let formattedData = []
    switch (tab) {
      case chartConstants.SYRA: {
        //Dati relativi al grafico della Potenza e dimmer lampada
        let data1 = this.normalizeTimeSeries(data[0])
        //Dati relativi al grafico della corrente
        let data2 = this.normalizeTimeSeries(data[1])
        //Dati relativi al grafico delle tensioni
        let data3 = this.normalizeTimeSeries(data[2])
        //Dati relativi al grafico delle temperature
        let data4 = this.normalizeTimeSeries(data[3])
        formattedData = [data1, data2, data3, data4]
        break
      }
      case chartConstants.ANDROS: {
        const { peripheralCodes } = this.state
        let data1 = []
        if (peripheralCodes && peripheralCodes.length) {
          data1 = this.getFromattedAggregateData(data[0])
        } else {
          //Dati relativi al grafico della Potenza totale e corrente
          // data1 = this.normalizeTimeSeries(data[0])
        }
        //Dati relativi al grafico delle tensioni interne Andros
        let data2 = this.normalizeTimeSeries(
          this.merge(data[1], data[2], 'anValue')
        )
        //Dati relativi al grafico delle temperature
        let data3 = this.normalizeTimeSeries(
          this.merge(data[3], data[4], 'anValue')
        )
        //Dati relativi al grafico delle Periferiche gestite
        let data4 = data[5]
        formattedData = [data1, data2, data3, data4]
        break
      }
      case chartConstants.WATTMETER: {
        //Dati relativi al grafico della Tensione
        let dataVoltage = this.getFormattedPieData(data[0], 'volf', 280)
        //Dati relativi al grafico della Corrente
        let dataCurrent = this.getFormattedPieData(data[1], 'curf', 50)
        //Dati relativi al grafico della Potenza
        let dataPower = this.getFormattedPieData(data[2], 'pact', 10)
        //Dati relativi al grafico di Tensioni di fase
        let dataPhaseVoltages = this.normalizeTimeSeries(data[3])
        //Dati relativi al grafico delle Correnti di Linea
        let dataLineActivePower = this.normalizeTimeSeries(data[4])
        //Dati relativi al grafico delle Potenze attive di fase
        let dataCurf = this.normalizeTimeSeries(data[5])
        let dataActiveEnergy = {
          counter: 0,
          consumption: []
        }
        if (data[6]) {
          dataActiveEnergy['counter'] = this.normalizeTimeSeries(
            data[6].maxActiveTot
          )
          dataActiveEnergy['consumption'] = this.normalizeTimeSeries(
            data[6].activeEnergyList
          )
        }
        formattedData = [
          dataVoltage[0],
          dataVoltage[1],
          dataVoltage[2],
          dataCurrent[0],
          dataCurrent[1],
          dataCurrent[2],
          dataPower[0],
          dataPower[1],
          dataPower[2],
          dataPhaseVoltages,
          dataCurf,
          dataLineActivePower,
          dataActiveEnergy['counter'],
          dataActiveEnergy['consumption']
        ]
        break
      }
      case chartConstants.DIGITALIO: {
        //Il parametro data è un array che ha nelle prime due posizione i dati da mostrare nelle tabelle,
        //nelle altre due in vece i dati da mostrare nei grafici
        //Dati relativi alla tabella degli ingressi digitali
        let data1 = this.formatIOdata(data[0])
        //Dati relativi al grafico degli ingressi digitali
        let data2 = this.normalizeTimeSeries(data[2])
        //Dati relativi alla tabella delle uscite digitali
        let data3 = this.formatIOdata(data[1])
        //Dati relativi al grafico delle uscite digitali
        let data4 = this.normalizeTimeSeries(data[3])
        formattedData = [data1, data2, data3, data4]
        break
      }
      case chartConstants.GROUPS: {
        //Il parametro data è un array che ha nelle prime due posizione i dati da mostrare nelle tabelle,
        //nelle altre due in vece i dati da mostrare nei grafici
        //Dati relativi alla tabella degli ingressi digitali
        let data1 = this.formatIOdata(data[0])
        //Dati relativi al grafico degli ingressi digitali
        let data2 = this.normalizeTimeSeries(data[1])
        formattedData = [data1, data2]
        break
      }
      case chartConstants.ANALOGICALIO: {
        //Il parametro data è un array che ha nelle prime due posizione i dati da mostrare nelle tabelle,
        //nelle altre due in vece i dati da mostrare nei grafici
        //Dati relativi alla tabella degli ingressi digitali
        let data1 = this.formatIOdata(data[0])
        //Dati relativi al grafico degli ingressi digitali
        let data2 = this.normalizeTimeSeries(data[2])
        //Dati relativi alla tabella delle uscite digitali
        let data3 = this.formatIOdata(data[1])
        //Dati relativi al grafico delle uscite digitali
        let data4 = this.normalizeTimeSeries(data[3])
        formattedData = [data1, data2, data3, data4]
        break
      }
      case chartConstants.MODEM_INFO: {
        /** In questo caso occorre utilizzare sia data che modems
         * L'array risulatante avrà nelle posizioni pari i dati da mostrare nel grafico a torta,
         * in quelle dispari i dati del modem da mostrare nella tabella a destra.
         * Quindi data e modems avranno la stessa lunghezza. */
        if (modems && modems.length === 0) {
          return [[], []]
        }
        modems.forEach((modem, index) => {
          let pieData = this.getFormattedPieData(
            data[index],
            'signal',
            30,
            true
          )
          formattedData.push(pieData[0]) //posizione pari
          formattedData.push([modem.statusReported]) //posizione dispari
        })
        break
      }
      default:
        break
    }
    return formattedData
  }

  formatIOdata (data) {
    const { i18n } = this.props
    /**Metodo momentaneo che serve a trasforare un array di codici:
     * ['206', '203', '204', '205', '201', '202']
     *
     * in un array di oggetti del seguente tipo:
     * {
     *  code: '206',
     *  label: 'Input 1 Andros'
     * }
     */
    // Fix label based to lang
    let labelName = 'peripheralLabel' + (i18n.language === 'it' ? 'It' : 'En')
    // Loop element
    return data.map(val => {
      return {
        code: val.peripheralCode,
        label: val[labelName]
      }
    })
  }

  /**Per i grafici a torta occorre effettuare una diversa formattazione dei dati.
   * Dal BE arrivano in questo modo:
   * { volf1: 229.97315778730353, volf2: 229.17522453561952, volf3: 229.76309450908346 }
   *
   * Quindi, un unica response che consente di disegnare tutti e tre i grafici a torta.
   *
   * Il componente Pie invece, li vuole nel seguente formato:
   * [ { value: 229.17522453561952, name: 'volf2' }, { value: 50.82477546438048, name: 'volf2' }]
   *
   * Quindi ogni grafico a tora deve avere come dati in ingresso un attay di due elementi.
   * Il primo è il valore da visualizzare, il secondo è la differenza tra il primo e il valore massimo
   * che è possibile rappresentare nel grafico
   */
  getFormattedPieData (data, metric, maxValue, singleKey) {
    let formattedData = []
    Object.values(data).forEach((value, index) => {
      let v = Number(value)
      if (isNaN(v)) {
        v = 0
      }
      let obj = {},
        obj2 = {}
      obj['value'] = v
      obj['name'] = `${metric}${singleKey ? '' : index + 1}`
      let diff = maxValue - v
      obj2['value'] = diff
      obj2['name'] = `${metric}${singleKey ? '' : index + 1}`
      formattedData.push([obj, obj2])
    })
    return formattedData
  }

  merge (a, b, parameter, suffix) {
    let isAempty = a.length === 0
    let isBempty = b.length === 0
    // if (a.length === 0) {
    //     return b;
    // } else if (b.length === 0) {
    //     return a;
    // }
    let merged = []
    let length = a.length > b.length ? b.length : a.length
    if (isAempty) {
      length = b.length
    } else if (isBempty) {
      length = a.length
    }
    for (let i = 0; i < length; i++) {
      let element = {}
      if (isAempty) {
        element = b[i]
        element[`${parameter}${suffix || 2}`] = b[i][parameter]
      } else if (isBempty) {
        element = a[i]
        element[`${parameter}${suffix || 1}`] = a[i][parameter]
      } else {
        if (suffix) {
          element = { ...a[i] }
          element[`${parameter}${suffix}`] = b[i][parameter]
        } else {
          element = a.length > b.length ? b[i] : a[i]
          element[`${parameter}${suffix || 2}`] = b[i][parameter]
          element[`${parameter}${suffix || 1}`] = a[i][parameter]
        }
      }
      merged.push(element)
    }
    return merged
  }

  loadDashbord (tab) {
    switch (tab) {
      //ANDROS
      case 0: {
        // this.fetchTotalPowerAndCurrents();
        this.loadAndrosData()
        break
      }
      //WATTMETER
      case 1: {
        this.loadWattmeterData()
        break
      }
      //DIGITAL IO
      case 2: {
        this.loadIOdata(
          chartConstants.DIGITALIO,
          chartConstants.DIG_IN,
          chartConstants.DIG_OUT
        )
        break
      }
      //ANALOGIC IO
      case 3: {
        this.loadIOdata(
          chartConstants.ANALOGICALIO,
          chartConstants.AN_IN,
          chartConstants.AN_OUT
        )
        break
      }
      //MODEM
      case 4: {
        this.loadModemData()
        break
      }
      //GROUPS
      case 5: {
        this.loadIOdata(chartConstants.GROUPS, chartConstants.ISTATE)
        break
      }
      default: {
        this.loadSyraData()
        break
      }
    }
  }

  setTab (index) {
    //Questo setState triggera il componentDidUpdate che a sua volta caricherà i peripheralCodes e la dashboard successiva
    this.setState({
      data: [],
      loading: true,
      selectedTab: index,
      showControls: false,
      selectedPeriodType: LIVE,
      selectedPeriodValue: 0,
      selectedPeripheralCodeValue: '',
      selectedCode: {}
    })
  }

  toggleControls () {
    this.setState({ showControls: !this.state.showControls })
  }

  selectPeriod (value) {
    this.setState(
      {
        selectedPeriodValue: value
      },
      () => this.load(this.state.selectedTab, true)
    )
  }

  selectPeriodType (value) {
    //caso in cui il tipo di perido selezionato fosse "live"
    let periodValue = this.state.selectedPeriodValue
    if (value === MID) {
      periodValue = 2
    } else if (value === HIS) {
      periodValue = 24
    } else if (value === LIVE) {
      periodValue = 0
    } else if (value === RANGE) {
      periodValue = [
        formatDate(moment().subtract(7, 'days')),
        formatDate(moment())
      ]
    }

    this.setState(
      {
        selectedPeriodType: value,
        selectedPeriodValue: periodValue
      },
      () => this.load(this.state.selectedTab, true)
    )
  }

  selectPeripheralCode (value) {
    this.setState(
      {
        selectedPeripheralCodeValue: value
      },
      () => {
        this.load(this.state.selectedTab, true)
      }
    )
  }

  handleRowClick (tableID, data) {
    let selectedCode = this.state.selectedCode
    selectedCode[tableID] = data
    this.setState(
      {
        selectedCode: selectedCode,
        loading: tableID
      },
      () => {
        this.loadIOchartData(
          tableID,
          data,
          this.state.selectedPeriodValue,
          false,
          this.state.selectedPeriodType
        )
      }
    )
  }

  getPeriodWeeks () {
    let weeks = this.state.selectedPeriodType === MID ? 2 : 24
    let options = []
    for (let i = 1; i <= weeks; i++) {
      options.push(i)
    }
    return options
  }

  getControls () {
    const { t } = this.props
    const { peripheralCodes } = this.state
    let selectedTab = chartConstants.TABS[this.state.selectedTab]
    if (
      this.state.selectedTab > 5 &&
      this.state.selectedTab !== chartConstants.SYRA
    ) {
      return null
    }
    return (
      <Container>
        <Row>
          <Col>
            <div className='container controls'>
              <div className='toggle' onClick={() => this.toggleControls()}>
                <span className='control-title'>
                  {this.state.selectedPeriodType !== LIVE ||
                  this.state.selectedPeriodValue ||
                  this.state.selectedPeripheralCodeValue
                    ? `${
                        this.state.selectedPeriodType === LIVE ? 'LIVE - ' : ''
                      }${t('controls.title')}`
                    : `LIVE - ${t('controls.show')}`}{' '}
                  &nbsp;
                </span>

                <React.Fragment>
                  {this.state.selectedPeriodValue ? (
                    <React.Fragment>
                      <span className='control-value'>
                        <b>{t('controls.field.period')}</b>&nbsp;
                        {t(
                          `controls.field.${this.state.selectedPeriodType}.text`
                        )}
                      </span>
                      {this.state.selectedPeriodType !== RANGE && (
                        <span className='control-value'>
                          <b>{t('controls.field.weeks')}</b>&nbsp;
                          {this.state.selectedPeriodValue}
                        </span>
                      )}
                    </React.Fragment>
                  ) : null}
                  {selectedTab !== 'wattmeter' &&
                  selectedTab !== 'andros' ? null : this.state
                      .selectedPeripheralCodeValue ? (
                    <span className='control-value'>
                      <b>{t('controls.field.peripheralCode')}</b>&nbsp;
                      {
                        // this.state.selectedPeripheralCodeValue ?
                        this.state.selectedPeripheralCodeValue
                        // :
                        // t('controls.field.all')
                      }
                    </span>
                  ) : selectedTab === 'andros' ? (
                    <>
                      <b>{t('controls.field.peripheralCode')}</b>&nbsp;
                      {t('controls.field.all')}
                    </>
                  ) : null}
                </React.Fragment>
              </div>
              <div
                className={`fields ${this.state.showControls ? 'show' : ''}`}
              >
                <UncontrolledButtonDropdown group={false}>
                  {t('controls.field.period')}
                  <DropdownToggle caret className='btn-light' color='light'>
                    {t(`controls.field.${this.state.selectedPeriodType}.text`)}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {periods.map((value, index) => (
                      <DropdownItem
                        key={`period-opt-${index}`}
                        onClick={() =>
                          this.state.selectPeriodType === value.type
                            ? null
                            : this.selectPeriodType(value.type)
                        }
                      >
                        {t(`controls.field.${value.type}.text`)}{' '}
                        {t(`controls.field.${value.type}.option_text`)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                {this.state.selectedPeriodType !== LIVE &&
                this.state.selectedPeriodType !== RANGE ? (
                  <UncontrolledButtonDropdown group={false}>
                    {t('controls.field.weeks')}
                    <DropdownToggle caret className='btn-light' color='light'>
                      {this.state.selectedPeriodValue.toString()}
                    </DropdownToggle>
                    <DropdownMenu className='weeks-dropdown' right>
                      {this.getPeriodWeeks().map((value, index) => (
                        <DropdownItem
                          key={`period-opt-${index}`}
                          onClick={() => this.selectPeriod(value)}
                        >
                          {value}{' '}
                          {value === 1 ? t('Settimana') : t('Settimane')}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                ) : null}
                {this.state.selectedPeriodType === RANGE ? (
                  <Fragment>
                    &nbsp;{t('controls.field.startdate')}
                    <Datetime
                      dateFormat={'YYYY-MM-DD'}
                      timeFormat={'HH:mm:ss'}
                      utc={true}
                      value={this.state.selectedPeriodValue[0]}
                      onChange={time => {
                        let tmpTime = this.state.selectedPeriodValue
                        if (
                          time &&
                          time instanceof moment &&
                          Array.isArray(tmpTime)
                        ) {
                          if (time.isValid()) {
                            tmpTime[0] = formatDate(time)
                            this.setState(
                              {
                                selectedPeriodValue: tmpTime
                              },
                              () => this.load(this.state.selectedTab, true)
                            )
                          }
                        }
                      }}
                      className='inline-datetime'
                    />
                    &nbsp;{t('controls.field.enddate')}
                    <Datetime
                      dateFormat={'YYYY-MM-DD'}
                      timeFormat={'HH:mm:ss'}
                      utc={true}
                      value={this.state.selectedPeriodValue[1]}
                      onChange={time => {
                        let tmpTime = this.state.selectedPeriodValue
                        if (
                          time &&
                          time instanceof moment &&
                          Array.isArray(tmpTime)
                        ) {
                          if (time.isValid()) {
                            tmpTime[1] = formatDate(time)
                            this.setState(
                              {
                                selectedPeriodValue: tmpTime
                              },
                              () => this.load(this.state.selectedTab, true)
                            )
                          }
                        }
                      }}
                      className='inline-datetime'
                    />
                  </Fragment>
                ) : null}
                {selectedTab !== 'wattmeter' &&
                selectedTab !== 'andros' ? null : (
                  <UncontrolledButtonDropdown group={false}>
                    {t('controls.field.peripheralCode')}
                    <DropdownToggle caret className='btn-light' color='light'>
                      {
                        // this.state.selectedPeripheralCodeValue ?
                        this.state.selectedPeripheralCodeValue
                        // :
                        // t('controls.field.all')
                      }
                    </DropdownToggle>
                    <DropdownMenu right>
                      {/* <DropdownItem key={`peripheral-code-opt-default`} onClick={() => this.selectPeripheralCode('')}>
                                                {t('controls.field.all')}
                                            </DropdownItem> */}
                      {selectedTab === 'andros' && (
                        <DropdownItem
                          key={`peripheral-code-opt-all`}
                          onClick={() => this.selectPeripheralCode('')}
                        >
                          {t('controls.field.all')}
                        </DropdownItem>
                      )}
                      {peripheralCodes.map((value, index) => (
                        <DropdownItem
                          key={`peripheral-code-opt-${index}`}
                          onClick={() => this.selectPeripheralCode(value)}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  getAndrosCharts () {
    const { t } = this.props
    return (
      <Row>
        <Col>
          <Container>
            <Row>
              {/* {this.state.peripheralCodes &&
              this.state.peripheralCodes.length > 1 ? ( */}
              <Chart
                type={chartConstants.LINE_CHART}
                data={this.state.data[0]}
                width='100%'
                title={t('Potenza totale')}
                metrics={this.state.peripheralCodes.map(
                  // (code, i) => `${pActiveTotalPrefix}${i + 1}`
                  (code, i) => `${pActiveTotalPrefix}${code}`
                )}
                yAxisUnit={'kW'}
                colors={this.state.peripheralCodes.map(
                  (code, index) =>
                    pActiveTotalColors[index % pActiveTotalColors.length]
                )}
                // showUtils={true}
                loading={this.state.loading}
              />
              {/* ) : (
                <Chart
                  type={chartConstants.LINE_CHART}
                  data={this.state.data[0]}
                  width='100%'
                  title={t('Potenza totale')}
                  metrics={['pActiveTotal']}
                  yAxisUnit={'kW'}
                  // showUtils={true}
                  loading={this.state.loading}
                />
              )} */}
              <Chart
                type={chartConstants.LINE_CHART}
                // data={datatest}
                offsetData={{ value: 1000, step: 200 }}
                data={this.state.data[1]}
                width='50%'
                title={t('Tensioni interne Andros')}
                metrics={['anValue1', 'anValue2']}
                yAxisUnit={'V'}
                // showUtils={true}
                loading={this.state.loading}
                colors={['#094079', '#d0a619']}
              />
              <Chart
                type={chartConstants.LINE_CHART}
                // data={datatest}
                offsetData={{ value: 1000, step: 200 }}
                data={this.state.data[2]}
                width='50%'
                title={t('Temperatura')}
                metrics={['anValue1', 'anValue2']}
                tooltipTitle={t('charts.peripheral_code')}
                legendLabels={{
                  anValue1: '0001',
                  anValue2: '0002'
                }}
                translateRoot='temperature'
                yAxisUnit={'°C'}
                // showUtils={true}
                loading={this.state.loading}
                colors={['#094079', '#d0a619']}
              />
              <Chart
                type={chartConstants.HORIZONTAL_BAR_CHART}
                data={this.state.data[3]}
                width='100%'
                title={t('Periferiche gestite')}
                metrics={['count']}
                loading={this.state.loading}
              />
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }

  getModemCharts () {
    const { t } = this.props
    return (
      <Row>
        <Col>
          <Container>
            <Row>
              {/* Non si sa se ci possono essere più modem, in questo caso ne mostriamo uno.
                        In futuro se serve, potremmo trasformare il MODEM_INFO Chart in TABLE dove gli header saranno le label e
                        le rows conterranno i dati di ogni modem. */}
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[0]}
                width='40%'
                title={t(`Segnale`)}
                metrics={[`signal`]}
                color={'#094079'}
                // yAxisUnit={'V'}
                maxValue={30}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.MODEM_INFO}
                data={this.state.data[1]}
                width='60%'
                title={t('Informazioni')}
                loading={this.state.loading}
                rows={[
                  {
                    label: t('charts.table.modem_info.imei'),
                    field: 'imei'
                  },
                  {
                    label: t('charts.table.modem_info.iccid'),
                    field: 'iccid'
                  },
                  {
                    label: t('charts.table.modem_info.ip'),
                    field: 'ip'
                  },
                  {
                    label: t('charts.table.modem_info.simStateStr'),
                    field: 'simStateStr'
                  },
                  {
                    label: t('charts.table.modem_info.regStateStr'),
                    field: 'regStateStr'
                  },
                  {
                    label: t('charts.table.modem_info.counter'),
                    field: 'counter'
                  }
                ]}
              />
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }

  getWattmeterCharts () {
    const { t } = this.props
    const { selectedPeriodType } = this.state
    return (
      <Row>
        <Col>
          <Container>
            <Row>
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[0]}
                width='33%'
                title={t(`Tensione fase`) + ' 1 (V)'}
                metrics={[`volf1`]}
                color={'#2CAD00'}
                yAxisUnit={'V'}
                maxValue={280}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[1]}
                width='33%'
                title={t(`Tensione fase`) + ' 2 (V)'}
                metrics={[`volf2`]}
                color={'#2CAD00'}
                yAxisUnit={'V'}
                maxValue={280}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[2]}
                width='33%'
                title={t(`Tensione fase`) + ' 3 (V)'}
                metrics={[`volf3`]}
                color={'#2CAD00'}
                yAxisUnit={'V'}
                maxValue={280}
                loading={this.state.loading}
              />
              {/**Data Phase Voltages */}
              <Chart
                type={chartConstants.LINE_CHART}
                // data={datatest}
                // offsetData={{ value: 1000, step: 200 }}
                data={this.state.data[9]}
                width='100%'
                title={t('Tensioni di fase')}
                metrics={['volf1', 'volf2', 'volf3']}
                // tooltipTitle={t('charts.peripheral_code')}
                // legendLabels={{
                //     anValue1: '0001',
                //     anValue2: '0002'
                // }}
                // translateRoot='temperature'
                yAxisUnit={'V'}
                // showUtils={true}
                loading={this.state.loading}
                colors={['#094079', '#d0a619', '#8a50b3']}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[3]}
                width='33%'
                title={t(`Corrente linea`) + ' 1'}
                metrics={[`curf1`]}
                color={'#FF8700'}
                yAxisUnit={'A'}
                maxValue={50}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[4]}
                width='33%'
                title={t(`Corrente linea`) + ' 2'}
                metrics={[`curf2`]}
                color={'#FF8700'}
                yAxisUnit={'A'}
                maxValue={50}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[5]}
                width='33%'
                title={t(`Corrente linea`) + ' 3'}
                metrics={[`curf3`]}
                color={'#FF8700'}
                yAxisUnit={'A'}
                maxValue={50}
                loading={this.state.loading}
              />
              {/**Data Line Active Power */}
              <Chart
                type={chartConstants.LINE_CHART}
                // data={datatest}
                offsetData={{ value: 1000, step: 200 }}
                data={this.state.data[10]}
                width='100%'
                title={t('Correnti di linea')}
                metrics={['curf1', 'curf2', 'curf3']}
                // tooltipTitle={t('charts.peripheral_code')}
                // legendLabels={{
                //     anValue1: '0001',
                //     anValue2: '0002'
                // }}
                // translateRoot='temperature'
                yAxisUnit={'A'}
                // showUtils={true}
                loading={this.state.loading}
                colors={['#094079', '#d0a619', '#8a50b3']}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[6]}
                width='33%'
                title={t(`Potenza fase`) + ' 1 (kW)'}
                metrics={[`pact3`]}
                color={'#6197E2'}
                yAxisUnit={'kW'}
                maxValue={10}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[7]}
                width='33%'
                title={t(`Potenza fase`) + ' 2 (kW)'}
                metrics={[`pact2`]}
                color={'#6197E2'}
                yAxisUnit={'kW'}
                maxValue={10}
                loading={this.state.loading}
              />
              <Chart
                type={chartConstants.PIE_CHART}
                data={this.state.data[8]}
                width='33%'
                title={t(`Potenza fase`) + ' 3 (kW)'}
                metrics={[`pact3`]}
                color={'#6197E2'}
                yAxisUnit={'kW'}
                maxValue={10}
                loading={this.state.loading}
              />
              {/**Data Line Active Power */}
              <Chart
                type={chartConstants.LINE_CHART}
                // data={datatest}
                offsetData={{ value: 1000, step: 200 }}
                data={this.state.data[11]}
                width='100%'
                title={t('Potenze attive di fase')}
                metrics={['pact1', 'pact2', 'pact3']}
                // tooltipTitle={t('charts.peripheral_code')}
                // legendLabels={{
                //     anValue1: '0001',
                //     anValue2: '0002'
                // }}
                // translateRoot='temperature'
                yAxisUnit={'kW'}
                // showUtils={true}
                loading={this.state.loading}
                colors={['#094079', '#d0a619', '#8a50b3']}
              />
              {/**Data Line Power Counter */}
              <Chart
                type={chartConstants.BASIC_CHART}
                data={this.state.data[12]}
                width='33%'
                yAxisUnit={'kWh'}
                title={t('Active energy meter')}
                loading={this.state.loading}
              />
              {/* <Chart type={chartConstants.BASIC_CHART}
                            data={11}
                            width='50%'
                            title={t('Totale punti luce')}
                            loading={this.state.loading} /> */}
              {selectedPeriodType === LIVE ? (
                <div
                  className='Chart container'
                  style={{ width: 'calc(67% - 10px)' }}
                >
                  <Row className='title'>
                    <Col>
                      <h6>{t('Active energy consumption')}</h6>
                    </Col>
                  </Row>
                  <Row className={'wrapper ' + chartConstants.LINE_CHART}>
                    <Col sm={12}>
                      {t('No LIVE supported select MID_HISTORY_RANGE')}
                    </Col>
                  </Row>
                </div>
              ) : (
                <Chart
                  type={chartConstants.LINE_CHART}
                  data={this.state.data[13]}
                  width='67%'
                  title={t('Active energy consumption')}
                  metrics={[
                    'activeEnergyConsumption',
                    'deltaActiveEnergyConsumption'
                  ]}
                  colors={['#094079', '#d0a619']}
                  yAxisUnit={'kWh'}
                  // showUtils={true}
                  loading={this.state.loading}
                />
              )}
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }

  getIOcharts (IOtype) {
    let inputType = chartConstants.DIG_IN
    let outputType = chartConstants.DIG_OUT
    let metricIN = 'status'
    let metricOUT = 'outstatus'
    let inputChartTitle = 'charts.digitalIO.input'
    let outputChartTitle = 'charts.digitalIO.output'
    if (IOtype === chartConstants.ANALOGICALIO) {
      inputType = chartConstants.AN_IN
      outputType = chartConstants.AN_OUT
      metricIN = 'anValue'
      metricOUT = 'anValue'
      inputChartTitle = 'charts.analogicalIO.input'
      outputChartTitle = 'charts.analogicalIO.output'
    }
    const { t } = this.props
    return (
      <Row>
        <Col>
          <Container>
            <Row>
              {/**Digital inputs */}
              <Chart
                type={chartConstants.TABLE}
                data={this.state.data[0]}
                width='30%'
                title={t(inputChartTitle)}
                loading={
                  (this.state.loading === true ||
                    this.state.loading === inputType) &&
                  !this.state.selectedCode[inputType]
                }
                tableID={inputType}
                selectedCode={this.state.selectedCode[inputType]}
                headers={[
                  {
                    header: t('charts.table.peripheral_code'),
                    field: 'code'
                    // width: '30%'
                  },
                  {
                    header: t('charts.table.label'),
                    field: 'label'
                    // width: '70%'
                  }
                ]}
                handleRowClick={this.handleRowClick}
              />
              <Chart
                type={chartConstants.LINE_CHART}
                lineType='linear'
                data={this.state.data[1]}
                width='70%'
                title={t(inputChartTitle)}
                metrics={[metricIN]}
                loading={
                  this.state.loading === true ||
                  this.state.loading === inputType
                }
                colors={['#094079']}
              />
              {/**Digital outputs */}
              <Chart
                type={chartConstants.TABLE}
                data={this.state.data[2]}
                width='30%'
                title={t(outputChartTitle)}
                loading={
                  (this.state.loading === true ||
                    this.state.loading === outputType) &&
                  !this.state.selectedCode[outputType]
                }
                tableID={outputType}
                selectedCode={this.state.selectedCode[outputType]}
                headers={[
                  {
                    header: t('charts.table.peripheral_code'),
                    field: 'code'
                  },
                  {
                    header: t('charts.table.label'),
                    field: 'label'
                  }
                ]}
                handleRowClick={this.handleRowClick}
              />
              <Chart
                type={chartConstants.LINE_CHART}
                lineType='linear'
                data={this.state.data[3]}
                width='70%'
                title={t(outputChartTitle)}
                metrics={[metricOUT]}
                loading={
                  this.state.loading === true ||
                  this.state.loading === outputType
                }
                colors={['#094079']}
              />
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }

  getGroupsCharts () {
    let inputType = chartConstants.ISTATE
    let metricIN = 'anValue'
    let inputChartTitle = 'charts.digitalIO.input'
    const { t } = this.props
    return (
      <Row>
        <Col>
          <Container>
            <Row>
              {/**Digital inputs */}
              <Chart
                type={chartConstants.TABLE}
                data={this.state.data[0]}
                width='30%'
                title={t(inputChartTitle)}
                loading={
                  (this.state.loading === true ||
                    this.state.loading === inputType) &&
                  !this.state.selectedCode[inputType]
                }
                tableID={inputType}
                selectedCode={this.state.selectedCode[inputType]}
                headers={[
                  {
                    header: t('charts.table.peripheral_code'),
                    field: 'code'
                    // width: '30%'
                  },
                  {
                    header: t('charts.table.label'),
                    field: 'label'
                    // width: '70%'
                  }
                ]}
                handleRowClick={this.handleRowClick}
              />
              <Chart
                type={chartConstants.LINE_CHART}
                lineType='linear'
                data={this.state.data[1]}
                width='70%'
                title={t(inputChartTitle)}
                metrics={[metricIN]}
                loading={
                  this.state.loading === true ||
                  this.state.loading === inputType
                }
                colors={['#094079']}
              />
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }

  render () {
    const { t, item } = this.props
    // let datatest = [];
    // if (this.state.data && this.state.data.length) {
    //     this.state.data[1].forEach((element, i) => {
    //         if (i < 60) {
    //             datatest.push(element);
    //         }
    //     });
    // }
    return (
      <div className={`ChartDashboard ${this.state.loading ? 'loading' : ''}`}>
        <div>
          <Nav tabs>
            {item.deviceType === 2 &&
              chartConstants.TABS.map((tab, index) => (
                <React.Fragment key={`NavItemKey-${tab}`}>
                  <NavItem>
                    <NavLink
                      active={this.state.selectedTab === index}
                      onClick={() =>
                        this.state.selectedTab !== index
                          ? this.setTab(index)
                          : null
                      }
                    >
                      {t(tab)}
                    </NavLink>
                  </NavItem>
                  {index > chartConstants.TABS.length - 2 ? null : (
                    <div
                      className={`separator ${
                        this.state.selectedTab !== index &&
                        this.state.selectedTab - 1 !== index
                          ? null
                          : 'hide'
                      }`}
                    />
                  )}
                </React.Fragment>
              ))}
            {item.deviceType === 2 ? null : (
              <NavItem>
                <NavLink active={true}>{t(chartConstants.SYRA)}</NavLink>
              </NavItem>
            )}
            <div className='refresh'>
              <i
                className='fa fa-retweet'
                onClick={() => {
                  this.load(this.state.selectedTab)
                }}
              />
            </div>
          </Nav>
          {item.deviceType === 2 ? (
            <TabContent activeTab={this.state.selectedTab + ''}>
              {this.getControls()}
              <TabPane tabId='0' className='main-section container'>
                {this.getAndrosCharts()}
              </TabPane>
              <TabPane tabId='1' className='main-section container'>
                {this.getWattmeterCharts()}
              </TabPane>
              <TabPane tabId='2' className='main-section container'>
                {this.getIOcharts(chartConstants.DIGITALIO)}
              </TabPane>
              <TabPane tabId='3' className='main-section container'>
                {this.getIOcharts(chartConstants.ANALOGICALIO)}
              </TabPane>
              <TabPane tabId='4' className='main-section container'>
                {this.getModemCharts()}
              </TabPane>
              <TabPane tabId='5' className='main-section container'>
                {this.getGroupsCharts()}
              </TabPane>
            </TabContent>
          ) : (
            <TabContent>
              {this.getControls()}
              <TabPane className='main-section container'>
                <Row>
                  <Col>
                    <Container>
                      <Row>
                        <Chart
                          type={chartConstants.COMPOSED_CHART}
                          data={this.state.data[0]}
                          width='100%'
                          title={t('Dimmer lampada e potenza')}
                          metrics={['activePower', 'lightState']}
                          chartTypes={[
                            chartConstants.AREA_CHART,
                            chartConstants.LINE_CHART
                          ]}
                          translateRoot='dimmer_power'
                          lineType='linear'
                          yAxisUnit={''}
                          yAxisUnit2={'W'}
                          loading={this.state.loading}
                          colors={['#d0a619', '#094079']}
                        />
                        <Chart
                          type={chartConstants.AREA_CHART}
                          data={this.state.data[1]}
                          width='100%'
                          title={t('Corrente')}
                          metrics={['supplyCurrent']}
                          translateRoot='current'
                          lineType='linear'
                          yAxisUnit={'A'}
                          loading={this.state.loading}
                          colors={['#094079']}
                        />
                        <Chart
                          type={chartConstants.LINE_CHART}
                          data={this.state.data[2]}
                          width='50%'
                          title={t('Tensione')}
                          metrics={['supplyVoltage']}
                          translateRoot='voltage'
                          yAxisUnit={'V'}
                          loading={this.state.loading}
                          colors={['#094079']}
                        />
                        <Chart
                          type={chartConstants.AREA_CHART}
                          data={this.state.data[3]}
                          width='50%'
                          title={t('Temperatura')}
                          metrics={['temperature']}
                          translateRoot='temperature'
                          yAxisUnit={'°C'}
                          loading={this.state.loading}
                          colors={['#094079']}
                        />
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          )}
        </div>
      </div>
    )
  }
}

export default ChartDashboard = withTranslation()(ChartDashboard)

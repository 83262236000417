import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

function getCurrentLng() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return user.language;
    }
    return "";
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng:           getCurrentLng(),
        nsSeparator:   false,
        keySeparator:  false,
        fallbackLng:   "en",
        debug:         false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;

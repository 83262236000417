import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import ErrorModal from "../Components/ErrorModal"
import { Button } from "components";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Commands from "./Commands";
import { Line } from "react-chartjs-2";
import suncalc from 'suncalc';
import "../../plugins/chartjs-plugin-annotations";
import "../../plugins/chartjs-plugin-fill-between-lines";
import Datetime from "react-datetime";
import "moment/locale/it";

const HourFormat = "HH:mm";
const DayFormat = "DD/MM";

class CommandsTimerAstro extends Commands {

    constructor(props) {
        super(props);

        const sun = this.getSun(props);

        let locale = "";
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            locale = user.language;
        }

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            locale,
            sun,
            showingCommand: null,
            minsDark: 0,
            minsLight: 0,
            sorted: []
        }

    }

    /*
     *    Restituisce i valori di alba e tramonto per ogni girno dell'anno corrente.
    **/
    getSun(props) {

        // var year = moment().year();
        var newDate = moment("01/01", DayFormat);

        let sun = [];

        for (let i = 0; i < 365; i++) {
            var times = suncalc.getTimes(newDate.toDate(), props.cabinet.geometry.lat, props.cabinet.geometry.lng);
            sun.push({
                sunrise: moment(times.sunrise).format(HourFormat),
                sunset: moment(times.sunset).format(HourFormat),
                day: moment(newDate).format(DayFormat)
            });

            newDate.add(1, 'days');
        }
        return sun;
    }

    componentWillReceiveProps(nextProps) {
        //super.componentWillReceiveProps(nextProps);

        const { showingCommand } = this.state;
        let timers = this.getCommandsByType("EVE_TYPE_ASTRO");
        timers = timers.filter((timer) => (timer.output === showingCommand));
        let result = this.getDatasets(timers);

        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem,
            isNew: false,
            ...result
        });

    }

    render() {
        const { error } = this.state;
        return (
            <div
            // style = { { flex: 1 }}
            >
                {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}

                {this.renderChart()}
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {

        const { loading, conf, t, dispatch } = this.props;
        const { editingIndex, editingItem, validationError, locale } = this.state;

        let dataAst = this.getCommandsByType("EVE_TYPE_ASTRO");
        let optionsOuts = this.getOptionsOuts();

        return (

            <Card>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >

                        <span style={{ flex: 1 }}>{t("Timer astronomici")}</span>
                        <Button
                            disabled={true}
                            color="primary"
                            onClick={

                                () => {
                                    if (this.state.isNew) {
                                        return;
                                    }
                                    let item = {
                                        code: "",
                                        type: 'EVE_TYPE_ASTRO',
                                        mode: 0,
                                        startDate: '01/01',
                                        endDate: '31/12'
                                    };
                                    if (!conf.configParam.commands) {
                                        conf.configParam.commands = [];
                                    }
                                    conf.configParam.commands.unshift(item);
                                    this.setState({ isNew: true, conf, editingIndex: 0, editingItem: item, isNewTarget: conf.configParam.commands, sorted: [] });
                                }

                            }
                            style={{ fontSize: 14, fontWeight: 400, margin: 0, padding: '5px' }}
                        >
                            {t("Nuovo")}
                        </Button>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        data={dataAst}
                        className="-striped -highlight configurations"
                        pageSize={dataAst && dataAst.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        sorted={this.state.sorted}
                        onSortedChange={(newSorted) => { this.setState({ sorted: newSorted }) }}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[
                            {
                                Header: t("Nome"),
                                accessor: "code",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.code}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.code = value;
                                                    this.setState({ editingItem })
                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Data inizio"),
                                accessor: "startDate",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (

                                            <React.Fragment>
                                                {validationError && validationError.field === 'startDate' &&
                                                    <span className="validationError">{validationError.error}</span>
                                                }

                                                <Datetime
                                                    locale={locale}
                                                    dateFormat={DayFormat}
                                                    timeFormat={false}
                                                    value={editingItem.startDate}
                                                    onChange={(time) => {

                                                        if (time === "") {
                                                            editingItem.startDate = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (time && time instanceof moment) {
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'startDate', error: t("Formato non valido, utilizzare DD/MM") } });
                                                                return;
                                                            }
                                                            // controllo che startDate < endDate
                                                            // if (editingItem.endDate){
                                                            //     let endDate = moment(editingItem.endDate,DayFormat);
                                                            //     if (time>endDate){
                                                            //       this.setState({ validationError: { field: 'startDate', error: t("Data inizio > data fine.") } });
                                                            //       return;
                                                            //     }
                                                            // }
                                                            editingItem.startDate = time.format(DayFormat);
                                                            this.setState({ editingItem, validationError: null })

                                                        }
                                                    }}
                                                    onBlur={(time) => {

                                                        if (time === "") {
                                                            editingItem.startDate = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (!(time instanceof moment)) {
                                                            time = moment(time, DayFormat);
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'startDate', error: t("Formato non valido, utilizzare DD/MM") } })
                                                                return;
                                                            }
                                                        }

                                                        editingItem.startDate = time.format(DayFormat);
                                                        this.setState({ editingItem, validationError: null });

                                                    }}

                                                />

                                            </React.Fragment>

                                        );

                                        // <Input
                                        //   type="text"
                                        //   value={editingItem.startDate}
                                        //   onChange={(e) => {
                                        //     const { name, value } = e.target;
                                        //     editingItem.startDate = value;
                                        //     this.setState({ editingItem })
                                        //   }}
                                        // />
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Data fine"),
                                accessor: "endDate",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (

                                            <React.Fragment>
                                                <Datetime
                                                    locale={locale}
                                                    dateFormat={DayFormat}
                                                    timeFormat={false}
                                                    value={editingItem.endDate}
                                                    onChange={(time) => {

                                                        if (time === "") {
                                                            editingItem.endDate = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (time && time instanceof moment) {
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'endDate', error: t("Formato non valido, utilizzare DD/MM") } });
                                                                return;
                                                            }
                                                            editingItem.endDate = time.format(DayFormat);
                                                            this.setState({ editingItem, validationError: null })
                                                        }
                                                    }}
                                                    onBlur={(time) => {

                                                        if (time === "") {
                                                            editingItem.endDate = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (!(time instanceof moment)) {
                                                            time = moment(time, DayFormat);
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'endDate', error: t("Formato non valido, utilizzare DD/MM") } })
                                                                return;
                                                            }
                                                        }
                                                        editingItem.endDate = time.format(DayFormat);
                                                        this.setState({ editingItem, validationError: null })
                                                    }}

                                                />

                                                {validationError && validationError.field === 'endDate' &&
                                                    <span className="validationError">{validationError.error}</span>
                                                }
                                            </React.Fragment>

                                            // <Input
                                            //   type="text"
                                            //   value={editingItem.endDate}
                                            //   onChange={(e) => {
                                            //     const { name, value } = e.target;
                                            //     editingItem.endDate = value;
                                            //     this.setState({ editingItem })
                                            //   }}
                                            // />

                                        );
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Offset alba (min)"),
                                accessor: "offsetSunrise",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="number"
                                                value={editingItem.offsetSunrise}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.offsetSunrise = value;
                                                    if (value) {
                                                        editingItem.timeOff = "";
                                                    }
                                                    this.setState({ editingItem })
                                                }}
                                            />
                                        );
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Offset tramonto (min)"),
                                accessor: "offsetSunset",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="number"
                                                value={editingItem.offsetSunset}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.offsetSunset = value;
                                                    if (value) {
                                                        editingItem.timeOn = "";
                                                    }
                                                    this.setState({ editingItem })
                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Ora spegnimento"),
                                accessor: "timeOff",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (

                                            <React.Fragment>
                                                <Datetime
                                                    dateFormat={false}
                                                    timeFormat={HourFormat}
                                                    value={editingItem.timeOff}
                                                    onChange={(time) => {

                                                        if (time && time === "") {
                                                            editingItem.timeOff = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (time && time instanceof moment) {
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'timeOff', error: t("Formato orario non valido") } });
                                                                return;
                                                            }
                                                            editingItem.timeOff = time.format(HourFormat);
                                                            editingItem.offsetSunrise = "";
                                                            this.setState({ editingItem, validationError: null })

                                                        }
                                                    }}
                                                    onBlur={(time) => {
                                                        if (time && time === "") {
                                                            editingItem.timeOff = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }
                                                        if (!(time instanceof moment)) {
                                                            time = moment(time, HourFormat);
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'timeOff', error: t("Formato orario non valido") } })
                                                                return;
                                                            }
                                                        }
                                                        editingItem.timeOff = time.format(HourFormat);
                                                        editingItem.offsetSunrise = "";
                                                        this.setState({ editingItem, validationError: null })
                                                    }}

                                                />

                                                {validationError && validationError.field === 'time' &&
                                                    <span className="validationError">{validationError.error}</span>
                                                }
                                            </React.Fragment>

                                        );
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Ora accensione"),
                                accessor: "timeOn",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (

                                            <React.Fragment>
                                                <Datetime
                                                    dateFormat={false}
                                                    timeFormat={HourFormat}
                                                    value={editingItem.timeOn}
                                                    onChange={(time) => {

                                                        if (time && time === "") {
                                                            editingItem.timeOn = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }

                                                        if (time && time instanceof moment) {
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'timeOn', error: t("Formato orario non valido") } });
                                                                return;
                                                            }
                                                            editingItem.timeOn = time.format(HourFormat);
                                                            editingItem.offsetSunset = "";
                                                            this.setState({ editingItem, validationError: null })

                                                        }
                                                    }}
                                                    onBlur={(time) => {
                                                        if (time && time === "") {
                                                            editingItem.timeOn = "";
                                                            this.setState({ editingItem, validationError: null });
                                                            return;
                                                        }
                                                        if (!(time instanceof moment)) {
                                                            time = moment(time, HourFormat);
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'timeOn', error: t("Formato orario non valido") } })
                                                                return;
                                                            }
                                                        }
                                                        editingItem.timeOn = time.format(HourFormat);
                                                        editingItem.offsetSunset = "";
                                                        this.setState({ editingItem, validationError: null })
                                                    }}

                                                />

                                                {validationError && validationError.field === 'time' &&
                                                    <span className="validationError">{validationError.error}</span>
                                                }
                                            </React.Fragment>

                                        );
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Comando"),
                                accessor: "output",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsOuts, editingItem.output);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Comando")}
                                                name="group"
                                                value={selectedOption && selectedOption.value}
                                                options={optionsOuts}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.output = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    }
                                    else {
                                        const selectedOption = this.getSelected(optionsOuts, row.value);
                                        const label = (selectedOption ? selectedOption.label : "");

                                        return (<div className="no-overflow" >{label}</div>);
                                    }
                                }
                            },

                            {
                                Header: t("Valore spegnimento"),
                                accessor: "valueSunrise",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {
                                    return this.renderValue('valueSunrise', row, editingIndex, editingItem, optionsOuts);
                                    // let isEditing = (row.index === editingIndex);
                                    // if (isEditing) {
                                    //   return (
                                    //     <Input
                                    //       type="number"
                                    //       value={editingItem.valueSunrise}
                                    //       onChange={(e) => {
                                    //         const { name, value } = e.target;
                                    //         editingItem.valueSunrise = value;
                                    //         this.setState({ editingItem })
                                    //       }}
                                    //     />);
                                    // } else {
                                    //   return (<div className="no-overflow" align="">{row.value}</div>)
                                    // }
                                }

                            },

                            {
                                Header: t("Valore accensione"),
                                accessor: "valueSunset",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {

                                    return this.renderValue('valueSunset', row, editingIndex, editingItem, optionsOuts);
                                    // let isEditing = (row.index === editingIndex);
                                    // if (isEditing) {
                                    //   return (
                                    //     <Input
                                    //       type="number"
                                    //       value={editingItem.valueSunset}
                                    //       onChange={(e) => {
                                    //         const { name, value } = e.target;
                                    //         editingItem.valueSunset = value;
                                    //         this.setState({ editingItem })
                                    //       }}
                                    //     />);
                                    // } else {
                                    //   return (<div className="no-overflow" align="">{row.valueSunset}</div>)
                                    // }
                                }

                            },

                            // {
                            //   Header: t("Sole"),
                            //   accessor: "sunrize",
                            //   sortable: true,
                            //   filterable: false,
                            //   Cell: (row)=>{

                            //     const isEditing = (row.index===editingIndex);

                            //     if (isEditing){

                            //       const selectedOption = this.getSelected(optionsSunrize,editingItem.sunrize);

                            //       return (
                            //           <Select
                            //             // menuContainerStyle = \{ \{position: 'relative'\} \}
                            //             placeholder={t("Tipo")}
                            //             name="group"
                            //             value={ selectedOption }
                            //             options={ optionsSunrize }
                            //             onChange={option => {
                            //               if (option){
                            //                 editingItem.sunrize = option.value;
                            //                 this.setState({ editingItem })
                            //               }
                            //             }}
                            //           />
                            //       );

                            //    } else {
                            //     const selectedOption = this.getSelected(optionsSunrize,row.value);
                            //     return (<span>{selectedOption && selectedOption.label}</span>);
                            //    }
                            //   }

                            // },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com1_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com1_del_" + row.index}
                                                    onClick={() => {

                                                        this.confirmDelete(
                                                            t("Elimina timer astronomico"),
                                                            () => {

                                                                let commands = conf.configParam.commands.filter(
                                                                    ((curr, index) => {
                                                                        return (index !== row.original.index)
                                                                    })
                                                                );
                                                                conf.configParam.commands = commands;
                                                                dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                this.setState({
                                                                    alert: null
                                                                });

                                                            }

                                                        );

                                                    }}
                                                    color="danger"
                                                    size="sm"
                                                    tooltipText={t("Elimina")}
                                                >
                                                    <i className="fa fa-trash" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"com1_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {

                                                        // validazione input
                                                        // se viene inserito un timer con startDate="" ed endDate="" sulla stesso comando --> errore

                                                        if (!editingItem.startDate && !editingItem.endDate) {
                                                            let isValid = true;
                                                            let timers = this.getCommandsByType("EVE_TYPE_ASTRO");
                                                            for (let i = 0; i < timers.length; i++) {
                                                                if (i === row.index) {
                                                                    continue;
                                                                }
                                                                let timer = timers[i];
                                                                if (!timer.startDate && !timer.endDate && timer.output === editingItem.output) {
                                                                    isValid = false;
                                                                    break;
                                                                }
                                                            }
                                                            if (!isValid) {
                                                                this.setState({ error: t("Impossibile salvare, timer duplicato.") });
                                                                return;
                                                            }
                                                        }

                                                        // controllo che offset e valore non siano entrambi valorizzati
                                                        if (editingItem)

                                                            conf.configParam.commands[row.original.index] = editingItem;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"com1_del_" + row.index}
                                                    onClick={() => {

                                                        if (this.state.isNew) {
                                                            conf.configParam.commands.splice(row.original.index, 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    // defaultPageSize={10}
                    />
                </CardBody>
            </Card>

        )

    }

    toTimeString(raw) {
        let min = Math.floor(raw / 60);
        let sec = raw % 60;
        return ("" + min).padStart(2, "0") + ":" + ("" + sec).padStart(2, "0");

    }

    getChartOptions() {
        const { t } = this.props;

        let gridOptions = {

            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
                bodySpacing: 4,
                mode: "nearest",
                intersect: 0,
                position: "nearest",
                xPadding: 10,
                yPadding: 10,
                caretPadding: 10,
                callbacks: {
                    label: (tooltipItem, data) => {
                        let raw = parseInt(tooltipItem.yLabel, 10);
                        return (data.datasets[tooltipItem.datasetIndex]).label + ": " + this.toTimeString(raw);
                    }
                }
            },
            scales: {

                xAxes: [
                    {
                        type: "time",
                        time: {
                            parser: DayFormat,
                            tooltipFormat: DayFormat,
                            labelFormat: DayFormat,
                            unit: 'month',
                            // unitStepSize: 1,
                            displayFormats: {
                                'day': DayFormat,
                                'month': "MMM",
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('Giorno')
                        },
                        gridLines: {
                            // zeroLineColor: "transparent",
                            // drawTicks: false,
                            // display: false,
                            // drawBorder: false
                        },
                        ticks: {
                            callback: function (value) {
                                return "01 " + t(value);
                            }
                        }
                    }
                ],

                yAxes: [
                    {

                        ticks: {
                            min: 0,
                            max: 1440,
                            stepSize: 120,
                            suggestedMin: 0,
                            suggestedMax: 1440,
                            callback: (label) => {
                                let raw = parseInt(label, 10);
                                return this.toTimeString(raw);
                            },

                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('Orario')
                        },
                        gridLines: {
                            // zeroLineColor: "black",
                            // drawTicks: true,
                            // display: true,
                            // drawBorder: true
                        },

                    }
                ],

            },
            layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 }
            }
        };

        return gridOptions;

    }

    getDatasets(timers) {
        const { t } = this.props;
        let datasets = [];

        if (timers.length === 0) {
            return { datasets, minsLight: 0, minsDark: 0 }
        }

        const { sun } = this.state;

        let dataSunRise = [];
        let dataRefSunRise = [];

        let dataSunSet = [];
        let dataRefSunSet = [];

        let minsLight = 0;
        let minsDark = 0;

        let mainTimer = null;
        let otherTimers = [];
        for (let timer of timers) {
            if (!timer.startDate && !timer.endDate) {
                mainTimer = timer;
                continue;
            }
            otherTimers.push(timer);
        }

        for (let item of sun) {

            let day = moment(item.day, DayFormat);

            let matchingTimer = null;

            // cerco il timer applicabile
            for (let otherTimer of otherTimers) {

                let startDate = null;
                if (otherTimer.startDate) {
                    startDate = moment(otherTimer.startDate, DayFormat);
                }

                let endDate = null;
                if (otherTimer.endDate) {
                    endDate = moment(otherTimer.endDate, DayFormat);
                }

                if (
                    (startDate && endDate && day >= startDate && day <= endDate) ||
                    (startDate && !endDate && day >= startDate)
                ) {
                    matchingTimer = otherTimer;
                    break;
                }
            }

            if (!matchingTimer) {
                if (!mainTimer)
                    continue;
                else
                    matchingTimer = mainTimer;
            }

            let sunrise = item.sunrise.split(":");
            let sunset = item.sunset.split(":");

            let r0 = parseInt(sunrise[0], 10) * 60 + parseInt(sunrise[1], 10);
            let r1 = parseInt(sunset[0], 10) * 60 + parseInt(sunset[1], 10);

            let y0 = r0;
            if (matchingTimer.offsetSunrise) {
                y0 += parseInt(matchingTimer.offsetSunrise, 10);
            }
            else if (matchingTimer.timeOff) {
                let timeOff = matchingTimer.timeOff.split(":");
                y0 = parseInt(timeOff[0], 10) * 60 + parseInt(timeOff[1], 10);
            }

            let y1 = r1;
            if (matchingTimer.offsetSunset) {
                y1 += parseInt(matchingTimer.offsetSunset, 10);
            }
            else if (matchingTimer.timeOn) {
                let timeOn = matchingTimer.timeOn.split(":");
                y1 = parseInt(timeOn[0], 10) * 60 + parseInt(timeOn[1], 10);
            }

            minsLight += (y1 - y0);
            minsDark += (y0 + 1440 - y1);

            dataSunRise.push({
                x: item.day,
                y: y0
            });

            dataSunSet.push({
                x: item.day,
                y: y1
            });

            dataRefSunRise.push({
                x: item.day,
                y: r0
            });

            dataRefSunSet.push({
                x: item.day,
                y: r1
            });

        }

        /*for (let item of sun) {
            let day = moment(item.day, DayFormat);
            let sunrise = item.sunrise.split(":");
            let sunset = item.sunset.split(":");
            let y0 = parseInt(sunrise[0], 10) * 60 + parseInt(sunrise[1], 10);
            let y1 = parseInt(sunset[0], 10) * 60 + parseInt(sunset[1], 10);
        }*/

        datasets.push({
            label: t("Alba"),
            borderColor: "red",
            pointBackgroundColor: "red",
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
            pointRadius: 1,
            borderWidth: 1,
            data: dataSunRise,
            fill: false,
        });

        datasets.push({
            label: t("Tramonto"),
            borderColor: "blue",
            pointBackgroundColor: "blue",
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
            pointRadius: 1,
            borderWidth: 1,
            data: dataSunSet,
            fill: false,
        });

        datasets.push({
            label: t("Rif. alba"),
            borderColor: "lightgray",

            pointBackgroundColor: "lightgray",
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
            pointRadius: 1,
            borderWidth: 1,

            data: dataRefSunRise,
            fillBetweenSet: 3,
            fillBetweenColor: "rgba(255,255,0, 0.2)",
            fill: false,
        });

        datasets.push({
            label: t("Rif. tramonto"),
            borderColor: "lightgray",

            pointBackgroundColor: "lightgray",
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
            pointRadius: 1,
            borderWidth: 1,

            data: dataRefSunSet,
            fill: false,
        });

        return { datasets, minsLight, minsDark };

    }

    getChartData() {

        const { datasets } = this.state;
        var year = moment().year();
        let chartData = {
            labels: [
                '01/01/' + year,
                '31/12/' + year
            ],
            datasets
        };

        return chartData;

    }

    refreshTotals() {

    }

    renderChart() {

        const { t } = this.props;
        let { showingCommand, minsLight, minsDark } = this.state;

        const chartOptions = this.getChartOptions();
        const chartData = this.getChartData();

        const optionsOuts = this.getOptionsOuts();

        // tante opzioni quanti sono i timer
        // domanda per Matteo, cosa prendo se ci sono più timer sullo stesso comando
        // senza data inizio e data fine?

        return (
            <div style={{ height: 350, display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", flex: 0, alignItems: "center" }} >
                    <div style={{ flex: 1 }}>{t("ORE DI ACCENSIONE")}: <span style={{ fontWeight: "bold" }}>{Math.ceil(minsLight / 60)}</span></div>
                    <div style={{ flex: 1 }}>{t("ORE DI BUIO")}: <span style={{ fontWeight: "bold" }}>{Math.ceil(minsDark / 60)}</span></div>
                    <div style={{ width: "200px", flow: "" }}><Select
                        placeholder={t("Comando")}
                        name="group"
                        value={showingCommand}
                        options={optionsOuts}
                        onChange={option => {
                            if (option) {
                                let timers = this.getCommandsByType("EVE_TYPE_ASTRO");
                                timers = timers.filter((timer) => (timer.output === option.value));
                                let result = this.getDatasets(timers);
                                this.setState({ ...result, showingCommand: option.value });
                            }
                        }}
                    /></div>
                </div>
                <Line
                    style={{ flex: 1 }}
                    data={chartData}
                    options={chartOptions}
                />

            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default CommandsTimerAstro = withTranslation()(connect(mapStateToProps)(CommandsTimerAstro));

const User = {

    /**
     * Restituisce l'utente corrente.
     * 
     * @return {Object} user corrente.
     */
    current: function() {
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
        }
        return user;
    },

    /**
     * Indica se l'utente appartiene al gruppo passato come argomento.
     *
     * @param {Object} user.
     * @param {String} group.
     * @return bool
     */
    isWithinGroup: function(user, group) {
        return user && [group].includes(user.userRoleId)
    },

    /**
     * Indica se l'utente appartiene ad almeno uno dei gruppi passati come argomento.
     * 
     * @param {Object} user da controllare.
     * @param { String[] } groups di appartenza.
     * @return bool
     */
    isWithinOneOfGroups: function(user, ...roles) {
        return user && roles.includes(user.userRoleId)
    }

}

export default User;
import React, { Component } from "react";
import { utils } from '../../helpers';
import { Line } from "react-chartjs-2";
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import suncalc from 'suncalc';

/**
 * Converte il numero assoluto di minuti nella coordinata x
 * @param {*} absmin
 */
function toX(absmin) {
    if (absmin >= 0 && absmin <= 720) {
        return absmin;
    }
    else {
        return -1440 + absmin;
    }
}

/**
 * Converte il numero assoluto di minuti in time
 * @param {*} absmin
 */
function toTime(absmin) {
    let time = new Date();
    time.setUTCHours(0, 0, 0, 0);
    time.setUTCMinutes(absmin);
    return moment.utc(time).format('HH:mm');
}

/**
 * Converte la coordinata x in numero assoluto di minuti
 * @param {*} x
 */
/*function toAbsmin(x) {
    if (x >= 0 && x <= 720) {
        return x;
    }
    else {
        return 1440 + x;
    }
}*/

/**
 * Converte una stringa "HH:mm" nella coordinata x
 * @param {*} hour
 */
/*function hourToX(hour) {
    let split = hour.split(":");
    let h = parseInt(split[0]);
    let m = parseInt(split[1]);
    let absmin = h * 60 + m;
    return toX(absmin);
}*/

// function getPointsFromTransitions(transitions, lat, lng) {

//   let data = [];
//   let points = [];
//   let suntimes = suncalc.getTimes(moment().toDate(), lat, lng);
//   let sunrise = moment(suntimes.sunrise);
//   let sunset = moment(suntimes.sunset);

//   for (let transition of transitions) {

//     let time = "";
//     let absmin = 0;
//     let offset = 0;
//     let hours = 0;
//     let mins = 0;

//     // considero le tipologie di transizioni
//     switch (transition.mode) {
//       case "absolute":
//         absmin = transition.timer ? parseFloat(transition.timer) : 0;
//         hours = ("" + Math.floor(absmin / 60)).padStart(2, "0");
//         mins = ("" + (absmin % 60)).padStart(2, "0");
//         time = hours + ":" + mins;
//         break;
//       case "sunset": case "sunrise":
//         let tm = (transition.mode === "sunset" ? sunset.clone() : sunrise.clone() );
//         offset = transition.timer != null ? parseInt(transition.timer) : 0;
//         if (offset < 0) {
//           tm.subtract(Math.abs(offset), "minutes");
//         } else if (offset > 0) {
//           tm.add(offset, "minutes");
//         }
//         time = tm.format('HH:mm');
//         let h = parseInt(tm.format('HH'));
//         let m = parseInt(tm.format('mm'));
//         absmin = h * 60 + m;
//         break;
//       case "midnight":
//         offset = transition.timer ? parseInt(transition.timer) : 0;
//         absmin = (1440 + offset) % 1440;
//         hours = ("" + Math.floor(absmin / 60)).padStart(2, "0");
//         mins = ("" + (absmin % 60)).padStart(2, "0");
//         time = hours + ":" + mins;
//         break;
//       default:
//         continue;
//     }

//     let point = {
//       absmin, // minuti espressi in valore assoluto
//       time, // label temporale
//       value: (transition.outValue !== null ? parseInt(transition.outValue) : 0)
//     }
//     points.push(point);

//   }

//   points.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

//   if (points.length == 0) {
//     data.push({
//       absmin: 0,
//       x: "00:00",
//       y: 100
//     });
//     data.push({
//       absmin: 1440,
//       x: "24:00",
//       y: 100
//     });
//   }
//   else {
//     let first = points[0];
//     if (first.time != "00:00") {
//       data.push({
//         absmin: 0,
//         x: "00:00",
//         y: 100
//       });
//     }

//     points.map((point) => {
//       data.push({
//         absmin: point.absmin,
//         x: point.time,
//         y: point.value
//       });
//     });

//     let last = points[points.length - 1];
//     if (last.time != "24:00") {
//       data.push({
//         absmin: 1440,
//         x: "24:00",
//         y: last.value
//       });
//     }
//   }

//   return data;
// }

/**
 *
 * @param {*} profile
 * @param {*} lat
 * @param {*} lng
 */
function getPointsFromDailyProfile(dailyProfile, lat, lng, timezone, day, legacy = true) {
    let transitions = [];
    if (legacy === true) {
        for (let i = 1; i <= 5; i++) {
            let k = 'transition' + i;
            let transition = dailyProfile[k];
            transitions.push(transition);
        }
    }
    else {
        for (let tr of dailyProfile.dailyTransitions) {
            if (tr.type === 'dimmering' && tr.enable === true) {
                transitions.push(tr);
            }
        }
    }
    return getPointsFromTransitions(transitions, lat, lng, timezone, day);
}

/**
 *
 * @param {*} profile
 * @param {*} lat
 * @param {*} lng
 */
function getPointsFromDailyProfileLegacy(dailyProfile, lat, lng, day) {
    let transitions = [];
    for (let i = 1; i <= 5; i++) {
        let k = 'transition' + i;
        let transition = dailyProfile[k];
        transitions.push(transition);
    }
    return getPointsFromTransitionsLegacy(transitions, lat, lng, day);
}

var cache = {};

/**
 * Costruisce l'array dei punti del grafico a partire dall'elenco delle transizioni.
 * @param {*} transitions
 * @param {*} lat
 * @param {*} lng
 */
function getPointsFromTransitionsLegacy(transitions, lat, lng, timezone, day) {

    //let data = [];
    let points = [];
    let suntimes = "";

    if (day) {
        let dt = day.toDate();
        let t = day.format('YYYY-MM-DD');
        let key = `${t}_${lat}_${lng}`;
        if (cache[key]) {
            suntimes = cache[key];
        } else {
            suntimes = suncalc.getTimes(dt, lat, lng);
            cache[key] = suntimes;
        }
    }
    else {
        suntimes = suncalc.getTimes(moment().toDate(), lat, lng);
    }

    let sunrise = moment(suntimes.sunrise);
    let sunset = moment(suntimes.sunset);

    // console.log("day="+day+" sunrise="+sunrise.toLocaleString()+" sunset="+sunset.toLocaleString());
    for (let transition of transitions) {

        //let time = "";
        let absmin = 0;
        let offset = 0;
        //let hours = 0;
        //let mins = 0;
        let x = 0;

        // considero le tipologie di transizioni
        switch (transition.mode) {
            case "absolute":
                absmin = transition.timer ? parseFloat(transition.timer) : 0;
                x = toX(absmin);
                break;
            case "sunset": case "sunrise":
                let tm = (transition.mode === "sunset" ? sunset.clone() : sunrise.clone());
                offset = transition.timer != null ? parseInt(transition.timer) : 0;
                if (offset < 0) {
                    tm.subtract(Math.abs(offset), "minutes");
                } else if (offset > 0) {
                    tm.add(offset, "minutes");
                }
                let h = parseInt(tm.format('HH'));
                let m = parseInt(tm.format('mm'));
                absmin = h * 60 + m;
                x = toX(absmin);
                break;
            case "midnight":
                offset = transition.timer ? parseInt(transition.timer) : 0;
                absmin = (1440 + offset) % 1440;
                x = toX(absmin);
                break;
            default:
                continue;
        }

        let point = {
            x,
            absmin, // minuti espressi in valore assoluto
            y: (transition.outValue !== null ? parseInt(transition.outValue) : 0)
        }
        points.push(point);

    }

    points.sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0));

    if (points.length === 0) {
        points.push({
            absmin: 720,
            x: -720,
            y: 100
        });
        points.push({
            absmin: 1440,
            x: 720,
            y: 100
        });
    }
    else {
        let first = points[0];
        let last = points[points.length - 1];
        if (first.x !== -720) {
            points.unshift({
                absmin: 720,
                x: -720,
                y: last.y,
                interpolate: true
            });
        }
        if (last.x !== 720) {
            points.push({
                absmin: 1440,
                x: 720,
                y: last.y,
                interpolate: true
            });
        }

    }
    return points;

}

/**
 * Costruisce l'array dei punti del grafico a partire dall'elenco delle transizioni.
 * @param {*} transitions
 * @param {*} lat
 * @param {*} lng
 */
function getPointsFromTransitions(transitions, lat, lng, timezone, day) {

    //let data = [];
    let points = [];
    let suntimes = "";

    if (day) {
        let dt = day.toDate();
        let t = day.format('YYYY-MM-DD');
        let key = `${t}_${lat}_${lng}`;
        if (cache[key]) {
            suntimes = cache[key];
        } else {
            suntimes = suncalc.getTimes(dt, lat, lng);
            cache[key] = suntimes;
        }
    }
    else {
        suntimes = suncalc.getTimes(moment().toDate(), lat, lng);
    }

    let sunrise = moment(suntimes.sunrise).tz(timezone);
    let sunset = moment(suntimes.sunset).tz(timezone);

    // console.log("day="+day+" sunrise="+sunrise.toLocaleString()+" sunset="+sunset.toLocaleString());
    for (let transition of transitions) {

        //let time = "";
        let absmin = 0;
        let offset = 0;
        //let hours = 0;
        //let mins = 0;
        let x = 0;

        // considero le tipologie di transizioni
        switch (transition.mode) {
            case "absolute":
                absmin = transition.timer ? parseFloat(transition.timer) : 0;
                x = toTime(absmin);
                //x = toX(absmin);
                break;
            case "sunset": case "sunrise":
                let tm = (transition.mode === "sunset" ? sunset.clone() : sunrise.clone());
                offset = transition.timer !== null ? parseInt(transition.timer) : 0;
                if (offset < 0) {
                    tm.subtract(Math.abs(offset), "minutes");
                } else if (offset > 0) {
                    tm.add(offset, "minutes");
                }
                let h = parseInt(tm.format('HH'));
                let m = parseInt(tm.format('mm'));
                absmin = h * 60 + m;
                x = toTime(absmin);
                //x = toX(absmin);
                break;
            case "midnight":
                offset = transition.timer ? parseInt(transition.timer) : 0;
                absmin = (1440 + offset) % 1440;
                x = toTime(absmin);
                //x = toX(absmin);
                break;
            default:
                continue;
        }

        let point = {
            x,
            //absmin, // minuti espressi in valore assoluto
            y: (transition.outValue !== null ? parseInt(transition.outValue) : 0)
        }
        points.push(point);

    }

    points.sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0));

    /*if (points.length == 0) {
      points.push({
        absmin: 720,
        x: -720,
        y: 100
      });
      points.push({
        absmin: 1440,
        x: 720,
        y: 100
      });
    }
    else {
      let first = points[0];
      let last = points[points.length - 1];
      if (first.x !== -720) {
        points.unshift({
          absmin: 720,
          x: -720,
          y: last.y
        });
      }
      if (last.x !== 720) {
        points.push({
          absmin: 1440,
          x: 720,
          y: last.y
        });
      }
    }*/
    // Aggiunge il primo e l'ultimo punto di congiunzione
    if (points.length > 0) {
        let first = points[0];
        let last = points[points.length - 1];
        if (first.x !== "00:00") {
            points.unshift({
                x: "00:00",
                y: last.y,
                interpolate: true
            });
        }
        if (last.x !== "23:59") {
            points.push({
                x: "23:59",
                y: last.y,
                interpolate: true
            });
        }
    }

    return points;

}

class DailyChartComponent extends Component {

    constructor(props) {
        super(props);

        let project = utils.getSelectedProject();
        let sun = null;
        const { timezone } = project;
        const { lat, lng } = project.geometry;
        sun = utils.getSun(lat, lng, timezone);

        this.state = {
            sun,
            lat,
            lng,
            timezone
        }

    }

    getChartOptions() {

        const { t } = this.props;
        const { sun } = this.state;

        let gridOptions = {

            maintainAspectRatio: false,
            responsive: true,

            /*tooltips: {
              bodySpacing: 4,
               mode: "nearest",
              // mode: "single",
              intersect: 0,
              position: "nearest",
              xPadding: 10,
              yPadding: 10,
              caretPadding: 10,
              callbacks: {
                title: function (tooltipItem, data) {
                  let absmin = toAbsmin(tooltipItem[0].xLabel);
                  return (""+Math.floor(absmin/60)).padStart(2,"0")+":"+(""+(absmin%60)).padStart(2,"0");
                },
                label: (tooltipItem, data) => {
                  return tooltipItem.yLabel;
                }
              }
            },*/

            scales: {

                xAxes: [
                    {
                        type: 'time',
                        ticks: {
                            //min: "00:00",
                            //max: "23:59",
                            //stepSize: 60,
                            min: "00:00",
                            max: "23:59",
                            callback: function (x) {
                                //let absmin = toAbsmin(x);
                                //return (""+(absmin/60)).padStart(2,"0");
                                return x.split(":")[0];
                            }
                        },
                        // type: "time",
                        time: {
                            parser: 'HH:mm',
                            tooltipFormat: 'HH:mm',
                            labelFormat: 'HH:mm',
                            unit: 'hour',
                            displayFormats: {
                                'hour': 'HH:mm',
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('Orario')
                        },
                        gridLines: {}
                    }
                ],

                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: t('Dimmer') + " %"
                        },
                        ticks: {
                            min: 0,
                            max: 100
                        },
                        gridLines: {
                            zeroLineColor: "transparent",
                            drawBorder: false
                        }

                    }
                ],
            },
            layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 }
            },
            legend: {
                display: false
            },
        };

        if (sun) {
            gridOptions.annotation = {
                drawTime: 'beforeDatasetsDraw',
                events: ['click'],
                dblClickSpeed: 350, // ms (default)

                annotations: [
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'day_time_1',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: hourToX("12:01"), //"12:00",
                        //xMax: hourToX(sun.minSet),
                        xMin: "12:00", //"12:00",
                        xMax: sun.minSet,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        backgroundColor: 'yellow',
                        globalAlpha: 0.5
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'day_night_transition',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: hourToX(sun.minSet),
                        //xMax: hourToX(sun.maxSet),
                        xMin: sun.minSet,
                        xMax: sun.maxSet,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        globalAlpha: 0.5,
                        gradientColorStops: [
                            {
                                stop: 0,
                                color: "yellow"
                            },
                            {
                                stop: 1,
                                color: "lightblue"
                            },
                        ]
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_time_2',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: hourToX(sun.maxSet),
                        //xMax: 0, //"24:00",
                        xMin: sun.maxSet,
                        xMax: "23:59:59", //"24:00",
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        globalAlpha: 0.5,
                        backgroundColor: 'lightblue'
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_time_1',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: 0, //"00:00",
                        //xMax: hourToX(sun.minRise),
                        xMin: "00:00", //"00:00",
                        xMax: sun.minRise,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        backgroundColor: 'lightblue',
                        globalAlpha: 0.5
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_day_transition',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: hourToX(sun.minRise),
                        //xMax: hourToX(sun.maxRise),
                        xMin: sun.minRise,
                        xMax: sun.maxRise,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        gradient: true,
                        globalAlpha: 0.5,
                        gradientColorStops: [
                            {
                                stop: 0,
                                color: "lightblue"
                            },
                            {
                                stop: 1,
                                color: "yellow"
                            },
                        ]
                    },
                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'day_time_2',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        //xMin: hourToX(sun.maxRise),
                        //xMax: hourToX("12:00"),
                        xMin: sun.maxRise,
                        xMax: "12:00",
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        backgroundColor: 'yellow',
                        globalAlpha: 0.5
                    }
                ]
            }
        }
        return gridOptions;

    }

    getChartData() {
        let datasets = [this.getDataset('plantOnOff'), this.getDataset('dimmering')];
        let chartData = {
            // labels: [
            //   '12:00',
            //   '13:00',
            //   '14:00',
            //   '15:00',
            //   '16:00',
            //   '17:00',
            //   '18:00',
            //   '19:00',
            //   '20:00',
            //   '21:00',
            //   '22:00',
            //   '23:00',
            //   '00:00',
            //   '01:00',
            //   '02:00',
            //   '03:00',
            //   '04:00',
            //   '05:00',
            //   '06:00',
            //   '07:00',
            //   '08:00',
            //   '09:00',
            //   '10:00',
            //   '11:00',
            //   '12:00'
            // ],
            datasets
        };
        return chartData;
    }

    getDataset(type) {

        const { transitions } = this.props;
        const { lat, lng, timezone } = this.state;

        let label = type;
        let color = "#2CA8FF";
        const pointColor = "#3593d5";

        //punti del grafico
        // calcolo gli orari corrispondenti ad ogni transizione
        let data = getPointsFromTransitions(transitions.filter(t => (t.type === type && t.enable === true)), lat, lng, timezone);

        // Modifica i valori 102 e 104 in caso di transizioni dell'impianto
        if (type === 'plantOnOff') {
            data = data.map(v => {
                if (v.y === 102) {
                    v.y = 0;
                }
                if (v.y === 104) {
                    v.y = 100;
                }
                return v;
            });
        }

        let dataset = {
            label,
            borderColor: color,
            pointBackgroundColor: pointColor,
            pointBorderWidth: 0,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: color,
            borderWidth: 2,
            steppedLine: true,
            data
        };

        if (type === "plantOnOff") {
            // Impostazione colore
            dataset.borderColor = "#ff0000";
            dataset.pointBackgroundColor = "#ff0000";
            dataset.backgroundColor = "#ff0000";
            dataset.fill = false;
        }

        return dataset;
    }

    render() {

        let chartOptions = this.getChartOptions();
        let chartData = this.getChartData();

        return (
            <Line
                data={chartData}
                options={chartOptions}
            />
        )

    }

}

const DailyChart = withTranslation()(DailyChartComponent);
export default DailyChart;
export { getPointsFromDailyProfile };
export { getPointsFromDailyProfileLegacy };

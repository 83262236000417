const ItemTypes = { 
    CABINET: 0,
    LIGHTPOINT: 1,
    ZONE: 2    
  }
  
const DeviceTypes = {
    SYRA: 1,
    ANDROS: 2,
    RADIOCONTROLLER: 3,
    RADIONODE: 4
}


const NavModes = {
  PLANT: "plant",
  ZONES: "zones"
}

const VertexActions = {
  DRAG: 0,
  ADD: 1,
  DEL: 2
}

const ShapeTypes = {
  POINT: 0,
  LINE: 1,
  POLYGON: 2
}

const FieldNames = {
  ID: "id",
  SN: "sn",
  LIGHTPOINT_ID: "lightPointId",
}

const Models = {
  PROJECTS: "projects",
  CABINETS: "cabinets",
  LIGHTPOINTS: "lightpoints",
  LUMINARES: "luminares", 
  SUPPORTS: "supports", 
  LAMPS: "lamps", 
  DRIVERS: "drivers",
  DEVICETYPES: "devicetypes",
  DEVICES: "devices",
  ANDROS_NOT_LINKED: "androsNotLinked",
  SYRAS_NOT_LINKED: "syrasNotLinked",
  RADIOCONTROLLERS: "radiocontrollers",
  RADIONODES: "radionodes",
  ZONES: "zones",
  TIMER_PROFILE_WEEKLY: "timerprofileweekly",
  TIMER_PROFILE_DAILY: "timerprofiledaily"
}

const ModelRelations = {
  DEVICE: "device",
  RADIOCONTROLLER: "radioController",
  NODE: "node"
}

const ModelPaths = {
  ADMIN: "admin",
  PLANT: "plant",
  ARCHIVE: "archive"
}

const PowerLevelColors = {
  '0%': "#EEE",
  '20%': "hsl(50, 100%, 91%)", 
  '30%': "hsl(50, 100%, 88%)",  
  '40%': "hsl(50, 100%, 85%)", 
  '45%': "hsl(50, 100%, 82%)", 
  '50%': "hsl(50, 100%, 79%)", 
  '55%': "hsl(50, 100%, 76%)", 
  '60%': "hsl(50, 100%, 73%)", 
  '65%': "hsl(50, 100%, 70%)", 
  '70%': "hsl(50, 100%, 67%)", 
  '75%': "hsl(50, 100%, 64%)", 
  '80%': "hsl(50, 100%, 61%)", 
  '85%': "hsl(50, 100%, 59%)", 
  '90%': "hsl(50, 100%, 56%)", 
  '95%': "hsl(50, 100%, 53%)", 
  '100%': "hsl(50, 100%, 50%)"
};



export { ItemTypes, NavModes, VertexActions, ShapeTypes, FieldNames, Models, ModelPaths, ModelRelations, DeviceTypes, PowerLevelColors};

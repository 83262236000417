import { connect } from 'react-redux';
import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackConstants } from '../../constants';
import { loopbackActions } from "../../actions";
import { loopbackService } from "../../services";
import { withTranslation } from 'react-i18next';
import ErrorModal from "../Components/ErrorModal"
import Moment from 'react-moment';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { ConfSideMenu, defaultMenuItem } from "./ConfSideMenu";
import Expansions from "./Expansions";
import PeriferalsDigitalInput from './PeriferalsDigitalInput';
import PeriferalsDigitalOutput from './PeriferalsDigitalOutput';
import PeriferalsAnalogInput from './PeriferalsAnalogInput';
import PeriferalsAnalogOutput from './PeriferalsAnalogOutput';
import PeriferalsWattmeter from './PeriferalsWattmeter';
import StartingPointsAndGroups from './StartingPointsAndGroups';
import CommandsTimerAstro from './CommandsTimerAstro';
import CommandsDigitalInput from './CommandsDigitalInput';
import CommandsTimerWeekly from './CommandsTimerWeekly';
import Events from './Events';

class Configurations extends Component {

    // schermate relative ad una configurazione specifica
    views = {
        espansioni: Expansions,
        dig_in: PeriferalsDigitalInput,
        dig_out: PeriferalsDigitalOutput,
        ana_in: PeriferalsAnalogInput,
        ana_out: PeriferalsAnalogOutput,
        wattmetro: PeriferalsWattmeter,
        starting_points_and_groups: StartingPointsAndGroups,
        cmd_timer_astro: CommandsTimerAstro,
        cmd_timer_weekly: CommandsTimerWeekly,
        cmd_dig_in: CommandsDigitalInput,
        events: Events
    }

    constructor(props) {

        super(props);

        this.state = {
            conf: null,
            error: null,
            configurations: null,
            currentTab: "",
            _default: null,
            params: null,
            optionsTipiEspansione: [],
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            selection: {},
            isNewTarget: null,
            selectedMenuItem: defaultMenuItem,
            sorted: [{
                id: 'dateUpload',
                desc: true
            }]
        }
        this.renderCellTimestamp = this.renderCellTimestamp.bind(this);
        this.handleMenuItemClicked = this.handleMenuItemClicked.bind(this);
    }

    componentWillMount() {
        this.fetchData();
    }

    fetchData() {
        const { dispatch, cabinet } = this.props;
        let where = {
            cabinetId: cabinet.id,
            order: 'dateUpload DESC',
        }

        dispatch(loopbackActions.list('plant', 'configurations', where));
        dispatch(loopbackActions.getParam('plant', 'configurations', 'default', null, cabinet.id));
        dispatch(loopbackActions.getParam('plant', 'configurations', 'params'));
    }

    async setDefaultConfiguration(conf) {
        const { t } = this.props;

        try {
            setTimeout(
                () => {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Operazione in corso...")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                        <ClipLoader
                                            sizeUnit={"px"}
                                            size={60}
                                            color={'#114d8c'}
                                            loading={true}
                                        />
                                    </div>
                                </ModalBody>
                            </Modal>
                        )
                    });
                }
            );

            let ret = await loopbackService.patch('plant', 'configurations', 'default', conf);

            this.setState({
                alert: (
                    <Modal isOpen={true} className="modal-umpi-container">
                        <ModalHeader toggle={() => {
                            this.setState({
                                alert: null
                            });
                        }}>
                            {t("Esito operazione")}
                        </ModalHeader>
                        <ModalBody>
                            <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                <i className='fa fa-exclamation-circle' />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                {(
                                    (ret && ret.result && ret.result.state && ret.result.state === "SUCCESS") ?
                                    t("Operazione completata") :
                                    t("Risposta inattesa del webservice.")
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {
                                this.setState({
                                    alert: null
                                });
                            }} color="success" className="umpi-modal-btn-save">
                                {t("Confirm")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            });
        } catch (error) {
            this.setState({ error: { error } });
        }
    }

    componentWillReceiveProps(nextProps) {

        const { t, cabinet, dispatch } = this.props;

        if (nextProps.configurations) {

            if (nextProps.configurations.download) {

                if (nextProps.configurations.download.loading) {
                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Lettura configurazione...")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                        <ClipLoader
                                            sizeUnit={"px"}
                                            size={60}
                                            color={'#114d8c'}
                                            loading={true}
                                        />
                                    </div>
                                </ModalBody>
                            </Modal>
                        )
                    });

                    return;
                }

                else if (nextProps.configurations.download.res) {
                    let downloadedConf = nextProps.configurations.download.res;
                    let { configurations } = this.state;
                    let newConfigurations = [];
                    if (downloadedConf.instance) {
                        for (let cfg of configurations) {
                            if (cfg.id === downloadedConf.instance.id) {
                                newConfigurations.push(downloadedConf.instance);
                            }
                            else
                                newConfigurations.push(cfg);
                        }
                    } else {
                        newConfigurations = configurations;
                    }

                    this.setState({

                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Esito operazione")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                        <i className='fa fa-exclamation-circle' />
                                    </div>
                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                        {(
                                            (nextProps.configurations.download.res.response.result === 'SUCCESS') ?
                                            t("Operazione completata") :
                                            t("Impossibile leggere la configurazione")
                                        )}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.setState({
                                            alert: null
                                        }, () => {
                                            //----------------------------------------------------|
                                            // HACKFIX: Chiusura configurazione download
                                            //----------------------------------------------------|
                                            dispatch({
                                                type: loopbackConstants.INVOKE_ON_INSTANCE_SUCCESS,
                                                path: "plant",
                                                model: "configurations",
                                                id: downloadedConf.instance.id,
                                                funcname: "download",
                                                res: null
                                            });
                                            //----------------------------------------------------|
                                            // HACKFIX: Chiusura configurazione download
                                            //----------------------------------------------------|
                                        });
                                    }} color="success" className="umpi-modal-btn-save">
                                        {t("Confirm")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        ),

                        configurations: newConfigurations,
                        loading: false
                    });
                }
                else if (nextProps.configurations.download.error) {
                    this.setState({ error: nextProps.configurations.download.error, loading: false });
                    return;
                }

            }

            // Invio della configurazione
            else if (nextProps.configurations.upload) {

                if (nextProps.configurations.upload.loading) {

                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Invio configurazione...")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", padding: "30px 0px" }}>
                                        <ClipLoader
                                            sizeUnit={"px"}
                                            size={60}
                                            color={'#114d8c'}
                                            loading={true}
                                        />
                                    </div>
                                </ModalBody>
                            </Modal>
                        )
                    });

                    return;
                }
                else if (nextProps.configurations.upload.res) {

                    let { configurations } = this.state;

                    this.setState({
                        alert: (
                            <Modal isOpen={true} className="modal-umpi-container">
                                <ModalHeader toggle={() => {
                                    this.setState({
                                        alert: null
                                    });
                                }}>
                                    {t("Esito operazione")}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                        <i className='fa fa-exclamation-circle' />
                                    </div>
                                    <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                        {(
                                            (nextProps.configurations.upload.res.response.result === 'SUCCESS') ?
                                            t("Configurazione inviata con successo") :
                                            t("Invio configurazione fallito")
                                        )}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => {
                                        this.setState({
                                            alert: null
                                        });
                                    }} color="success" className="umpi-modal-btn-save">
                                        {t("Confirm")}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        )
                    });

                    if (nextProps.configurations.upload.res.response.result === 'SUCCESS') {
                        // aggiorno la configurazione corrente
                        let confIndex = -1;
                        for (let i = 0; i < configurations.length; i++) {
                            let conf = configurations[i];
                            if (conf.id === nextProps.configurations.upload.id) {
                                confIndex = i;
                                break;
                            }
                        }
                        if (confIndex !== -1) {
                            configurations[confIndex] = nextProps.configurations.upload.res.istance
                        }

                        this.setState({ configurations });
                    }

                    // memorizzo la data di invio
                    // let conf = this.state.configurations.find( (conf)=>{
                    //     return (conf.id===nextProps.configurations.upload.id);
                    // });
                    // if (conf){
                    //     conf.dateUpload = moment().toISOString();
                    //     dispatch(loopbackActions.edit('plant','configurations',conf));
                    // }

                    return;
                }
                else if (nextProps.configurations.upload.error) {

                    let err = null;
                    if (nextProps.configurations.upload.error.message) {
                        err = nextProps.configurations.upload.error.message;
                    }
                    else
                        err = nextProps.configurations.upload.error;

                    this.setState({ alert: null, error: err, loading: false });
                    return;
                }
            }

            if (nextProps.configurations.deletedId > 0) {

                if (cabinet.workingConfigurationId === nextProps.configurations.deletedId) {
                    cabinet.workingConfigurationId = null;
                }

                let configurations = this.state.configurations.filter(
                    (conf) => { return (conf.id !== nextProps.configurations.deletedId) }
                );
                this.setState({ configurations, loading: false });
                return;
            }

            // Configurazione di default
            if (nextProps.configurations.default) {
                if (nextProps.configurations.default.error) {
                    this.setState({ loading: true });
                }
                else if (nextProps.configurations.default.error) {
                    this.setState({ error: nextProps.configurations.default.error });
                }
                else if (nextProps.configurations.default.instance) {
                    this.setState({ _default: nextProps.configurations.default.instance });
                }
                return;
            }

            // Parametri
            else if (nextProps.configurations.params) {
                if (nextProps.configurations.params.error) {
                    this.setState({ loading: true });
                }
                else if (nextProps.configurations.params.error) {
                    this.setState({ error: nextProps.configurations.params.error });
                }
                else if (nextProps.configurations.params.instance) {

                    const params = nextProps.configurations.params.instance;

                    // calcolo i tipi di espansione possibili
                    let optionsTipiEspansione = [];
                    if (params.androsLiveParams) {
                        if (params.androsLiveParams.expansions) {
                            let types = Object.keys(params.androsLiveParams.expansions);
                            types.map((type) => {
                                optionsTipiEspansione.push({
                                    value: type, label: params.androsLiveParams.expansions[type]['name']
                                });
                                return false;
                            });
                        }
                    }
                    // le ordino per nome
                    optionsTipiEspansione.sort((a, b) => {
                        return a.name > b.name;
                    });

                    this.setState({ params: nextProps.configurations.params.instance, optionsTipiEspansione });
                }
                return;
            }

            if (nextProps.configurations.loading) {
                this.setState({ loading: true });
            }

            else if (nextProps.error) {
                this.setState({ error: nextProps.error, loading: false });
            }

            else if (nextProps.configurations.instances) {

                let maxDateUpload = "";
                nextProps.configurations.instances.map((conf) => {
                    if (conf.dateUpload > maxDateUpload) {
                        maxDateUpload = conf.dateUpload;
                    }
                    return false;
                });
                this.setState({ maxDateUpload, configurations: nextProps.configurations.instances, loading: false });
            }

            else if (nextProps.configurations.instance) {

                let edited = nextProps.configurations.instance;

                let configurations = this.state.configurations.map(
                    (conf) => {
                        if (conf.id === edited.id || !conf.id) {
                            return edited;
                        }
                        else {
                            return conf;
                        }
                    }
                );

                let { maxDateUpload } = this.state;
                if (edited.dateUpload > maxDateUpload) {
                    maxDateUpload = edited.dateUpload;
                }

                this.setState({ maxDateUpload, isNew: false, editingItem: null, validationError: null, editingIndex: -1, configurations, loading: false });
            }

        }
    }

    back() {

        const { isNew, isNewTarget } = this.state;
        if (isNew) {
            if (isNewTarget)
                isNewTarget.splice(0, 1);
        }

        this.setState({
            conf: null,
            editingIndex: -1,
            editingItem: null,
            isNew: false,
            isNewTarget: null,
            selectedMenuItem: defaultMenuItem
        });
    }

    editMeta(conf) {
        this.setState({
            isNew: false,
            editingIndex: conf.index,
            editingItem: { name: conf.name, note: conf.note }
        });
    }

    browse(conf) {
        // const { cabinet } = this.props;
        this.setState({
            conf: conf,
            currentTab: "ht1",
            editingIndex: -1
        });
    }

    renderCellTimestamp(row) {
        const { maxDateUpload } = this.state;
        const activeConfig = (maxDateUpload === row.original.dateUpload ? "active-config" : "");
        if (row.value) {
            return (
                <Moment className={activeConfig} >
                    {row.value}
                </Moment>
            )
        }
        return null;
    }

    /*renderConfigurazioniHeader() {
        const { t, cabinet, handleConfigClose } = this.props;
        return (
            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <span style={{ flex: 1 }}>

                </span>
                <Button
                    onClick={handleConfigClose}
                    icon neutral
                    style={{ backgroundColor: "transparent", right: 0, top: 0, position: "relative", flex: 0, }}
                >
                    <i style={{ color: "white", fontSize: 28 }} className="fa fa-times"></i>
                </Button>
            </div>
        );
    }*/

    renderConfigurazioneHeader() {
        const { t, cabinet } = this.props;
        const { conf } = this.state;
        return (
            <Fragment>
                <Button
                    onClick={() => { this.back() }}
                    icon neutral
                    style={{ backgroundColor: "transparent", left: 0, top: "4px" }}>
                    <i
                        style={{ color: "white", fontSize: 32 }}
                        className="fa fa-angle-left">
                    </i>
                </Button>
                <span style={{ marginLeft: "15px" }}>{t("Quadro") + " " + cabinet.label + " - " + conf.name}</span>
            </Fragment>
        );
    }

    renderConfigurazioni() {

        const { t, dispatch, cabinet } = this.props;

        const { loading, editingIndex, editingItem, _default, maxDateUpload, error } = this.state;

        let { configurations } = this.state;

        let data = [];
        if (configurations)
            data = configurations.map((conf, key) => {
                return {
                    ...conf,
                    index: key
                };
            });

        return (
            <div className="main-panel-contextual-list">
                <Card style={{ padding: "10px" }}>
                    <CardHeader className="config-card-header sub-header">
                        <CardTitle className="config-card-title" >
                            <span style={{ flex: 1 }}>{t("Configurazioni")}</span>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {error === null && this.state.alert}
                        <ReactTable
                            className="-striped -highlight configurations"
                            data={data}
                            pageSize={data && data.length}
                            showPagination={false}
                            NoDataComponent={() => null}
                            sorted={this.state.sorted}
                            onSortedChange={(newSorted) => { this.setState({ sorted: newSorted }) }}
                            // defaultSorted={[{
                            //   id: 'dateUpload',
                            //   desc: true
                            // }]}
                            LoadingComponent={() => {
                                if (loading) {
                                    return (
                                        <div className="-loading -active">
                                            <div className="-loading-inner custom-margin-0-auto">
                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null;
                                }
                            }
                            }

                            columns={[
                                {
                                    Header: t("Nome"),
                                    accessor: "name",
                                    filterable: false,
                                    searchable: false,
                                    Cell: (row) => {
                                        let isEditing = (row.index === editingIndex);
                                        if (isEditing) {
                                            return (
                                                <Input
                                                    style={(!(
                                                        editingItem &&
                                                        editingItem.name &&
                                                        editingItem.name.length > 0
                                                    )) ? {
                                                        borderColor: "#990000"
                                                    } : {}}
                                                    type="text"
                                                    maxLength="50"
                                                    value={editingItem.name}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        editingItem.name = value;
                                                        this.setState({ editingItem })
                                                    }}
                                                />);
                                        } else {
                                            const activeConfig = (maxDateUpload === row.original.dateUpload ? "active-config" : "");

                                            return (

                                                <pre
                                                    className={"testo-pre " + activeConfig}
                                                >{row.value}</pre>

                                                // <div align="" className={ activeConfig } >{ row.value }</div>

                                            )
                                        }
                                    }

                                },
                                {
                                    Header: t("Note"),
                                    accessor: "note",
                                    filterable: false,
                                    searchable: false,
                                    Cell: (row) => {
                                        let isEditing = (row.index === editingIndex);
                                        if (isEditing) {
                                            return (
                                                <Input
                                                    type="textarea"
                                                    maxLength="200"
                                                    value={editingItem.note}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        editingItem.note = value;
                                                        this.setState({ editingItem })
                                                    }}
                                                />);
                                        } else {
                                            const activeConfig = (maxDateUpload === row.original.dateUpload ? "active-config" : "");
                                            //const value = row.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                                            return (
                                                // <div className={ activeConfig } >{ value }</div>

                                                <pre
                                                    className={"testo-pre " + activeConfig}
                                                >{row.value}</pre>
                                                // <Input
                                                //   className={ activeConfig }
                                                //   type="textarea"
                                                //   value= {row.value}
                                                // />

                                            )
                                        }
                                    }

                                },
                                {
                                    Header: t("Aggiornata"),
                                    accessor: "dateUpdate",
                                    Cell: this.renderCellTimestamp,
                                    filterable: false,
                                    searchable: false,
                                },
                                {
                                    Header: t("Inviata"),
                                    accessor: "dateUpload",
                                    Cell: this.renderCellTimestamp,
                                    sortable: true,
                                    filterable: false,
                                    searchable: false,
                                },
                                {
                                    Header: "",
                                    width: 300,
                                    accessor: "actions",
                                    sortable: false,
                                    filterable: false,
                                    searchable: false,
                                    resizable: false,
                                    Cell: (row) => {
                                        let isEditing = (row.index === editingIndex);
                                        if (!isEditing)
                                            return (
                                                <div align="right">

                                                    <ButtonWithTooltip
                                                        id={"meta_edit_" + row.index}
                                                        color="warning"
                                                        size="sm"
                                                        tooltipText={t("Cambia nome")}
                                                        onClick={() => {

                                                            this.setState({
                                                                isNew: false,
                                                                editingIndex: row.index,
                                                                editingItem: { name: row.original.name, note: row.original.note }
                                                            });

                                                        }}
                                                    >
                                                        <i className="fa fa-edit" />
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        id={"meta_del_" + row.original.id}
                                                        onClick={() => {

                                                            let message = "";
                                                            if (row.original.dateUpload === maxDateUpload) {
                                                                message = t("Attenzione, stai eliminando la configurazione attiva");
                                                            }
                                                            else {
                                                                message = t("Elimina configurazione");
                                                            }
                                                            this.confirmDelete(
                                                                message,
                                                                () => {

                                                                    if (configurations[row.index]) {
                                                                        let id = configurations[row.index]['id'];
                                                                        dispatch(loopbackActions._delete('plant', 'configurations', id));
                                                                    }
                                                                    this.setState({
                                                                        alert: null
                                                                    });

                                                                }

                                                            );

                                                        }}
                                                        color="danger"
                                                        size="sm"
                                                        tooltipText={t("Elimina")}
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        disabled={true}
                                                        id={"send_" + row.original.id}
                                                        color="success"
                                                        size="sm"
                                                        tooltipText={t("Invia configurazione")}
                                                        onClick={() => {

                                                            this.setState({
                                                                alert: (
                                                                    <Modal isOpen={true} className="modal-umpi-container">
                                                                        <ModalHeader toggle={() => {
                                                                            this.setState({
                                                                                alert: null
                                                                            });
                                                                        }}>
                                                                            {t("Invia configurazione")}
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                                                <i className='fa fa-exclamation-circle' />
                                                                            </div>
                                                                            <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                                                {t("Sei sicuro?")}
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <Button onClick={() => {
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="default" className="umpi-modal-btn-cancel">
                                                                                {t("Annulla")}
                                                                            </Button>
                                                                            &nbsp;&nbsp;
                                                                            <Button onClick={async () => {
                                                                                let conf = configurations[row.index];
                                                                                if (conf) {
                                                                                    dispatch(loopbackActions.invokeOnInstance('plant', 'configurations', conf.id, 'upload'));
                                                                                }
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="success" className="umpi-modal-btn-save">
                                                                                {t("Confirm")}
                                                                            </Button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                )
                                                            });

                                                        }}
                                                    >
                                                        <i className="fa fa-paper-plane"></i>
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        id={"read_" + row.original.id}
                                                        color="success"
                                                        size="sm"
                                                        tooltipText={t("Leggi configurazione")}

                                                        onClick={() => {

                                                            this.setState({
                                                                alert: (
                                                                    <Modal isOpen={true} className="modal-umpi-container">
                                                                        <ModalHeader toggle={() => {
                                                                            this.setState({
                                                                                alert: null
                                                                            });
                                                                        }}>
                                                                            {t("Leggi configurazione")}
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                                                <i className='fa fa-exclamation-circle' />
                                                                            </div>
                                                                            <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                                                {t("Sei sicuro?")}
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <Button onClick={() => {
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="default" className="umpi-modal-btn-cancel">
                                                                                {t("Annulla")}
                                                                            </Button>
                                                                            &nbsp;&nbsp;
                                                                            <Button onClick={async () => {
                                                                                let conf = configurations[row.index];
                                                                                if (conf) {
                                                                                    dispatch(loopbackActions.invokeOnInstance('plant', 'configurations', conf.id, 'download'));
                                                                                }
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="success" className="umpi-modal-btn-save">
                                                                                {t("Confirm")}
                                                                            </Button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                )
                                                            });
                                                        }}
                                                    >
                                                        <i className="fa fa-download"></i>
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        id={"set_default_" + row.original.id}
                                                        color="success"
                                                        size="sm"
                                                        tooltipText={t("Imposta come predefinita")}
                                                        disabled={!(configurations[row.index].configParam) ? "true" : ""}
                                                        onClick={() => {
                                                            this.setState({
                                                                alert: (
                                                                    <Modal isOpen={true} className="modal-umpi-container">
                                                                        <ModalHeader toggle={() => {
                                                                            this.setState({
                                                                                alert: null
                                                                            });
                                                                        }}>
                                                                            {t("Imposta come predefinita")}
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                                                                <i className='fa fa-exclamation-circle' />
                                                                            </div>
                                                                            <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                                                                                {t("Sei sicuro?")}
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <Button onClick={() => {
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="default" className="umpi-modal-btn-cancel">
                                                                                {t("Annulla")}
                                                                            </Button>
                                                                            &nbsp;&nbsp;
                                                                            <Button onClick={async () => {
                                                                                let conf = configurations[row.index];
                                                                                if (conf) {
                                                                                    this.setDefaultConfiguration(conf);
                                                                                }
                                                                                this.setState({
                                                                                    alert: null
                                                                                });
                                                                            }} color="success" className="umpi-modal-btn-save">
                                                                                {t("Confirm")}
                                                                            </Button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                )
                                                            });
                                                        }}
                                                    >
                                                        <i className="fa fa-flag-o"></i>
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        id={"duplicate_" + row.original.id}
                                                        color="info"
                                                        size="sm"
                                                        tooltipText={t("Duplica")}

                                                        onClick={() => {

                                                            if (this.state.isNew) {
                                                                return;
                                                            }
                                                            let duplicato = JSON.parse(JSON.stringify(configurations[row.index]));
                                                            duplicato.id = null;
                                                            duplicato.name = duplicato.name + " " + t("copia");
                                                            duplicato.dateUpdate = null;
                                                            duplicato.dateUpload = null;
                                                            configurations.unshift(duplicato);
                                                            this.setState({
                                                                isNew: true,
                                                                editingIndex: 0,
                                                                editingItem: { name: duplicato.name, note: duplicato.note },
                                                                sorted: []
                                                            });
                                                        }
                                                        }
                                                    >
                                                        <i className="fa fa-clone" />
                                                    </ButtonWithTooltip>
                                                    <span style={{ marginLeft: "20px" }}>
                                                        <ButtonWithTooltip
                                                            id={"browse_" + row.original.id}
                                                            color="warning"
                                                            size="sm"
                                                            tooltipText={t("Modifica parametri")}
                                                            style={{ marginLeft: "20px" }}
                                                            onClick={() => {
                                                                this.browse(row.original);
                                                            }}
                                                        >
                                                            <i className="fa fa-angle-right" />
                                                        </ButtonWithTooltip>
                                                    </span>
                                                </div>
                                            )
                                        else
                                            return (
                                                <div className="actions-right">
                                                    <ButtonWithTooltip
                                                        id={"meta_edit_" + row.index}
                                                        color="success"
                                                        size="sm"
                                                        tooltipText={t("Salva")}
                                                        onClick={() => {
                                                            if (!(
                                                                editingItem &&
                                                                editingItem.name &&
                                                                editingItem.name.length > 0
                                                            )) {
                                                                return;
                                                            }
                                                            let conf = {
                                                                ...configurations[row.index],
                                                                ...editingItem,
                                                                dateUpdate: moment().toISOString(true)
                                                            };
                                                            dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                        }}
                                                    >
                                                        <i className="fa fa-check" />
                                                    </ButtonWithTooltip>
                                                    {" "}
                                                    <ButtonWithTooltip
                                                        id={"meta_del_" + row.original.id}
                                                        onClick={() => {

                                                            if (this.state.isNew) {
                                                                configurations.splice(row.index, 1);
                                                                this.setState({ isNew: false, editingIndex: -1 });
                                                            }
                                                            else
                                                                this.setState({ editingIndex: -1 });
                                                        }}
                                                        color="secondary"
                                                        size="sm"
                                                        tooltipText={t("Annulla")}
                                                    >
                                                        <i className="fa fa-times" />
                                                    </ButtonWithTooltip>
                                                </div>
                                            )
                                    }
                                }
                            ]}
                        />
                        <div className="add-profile-container">
                            <Button
                                color="default"
                                onClick={
                                    () => {
                                        if (this.state.isNew) {
                                            return;
                                        }
                                        if (_default) {
                                            let conf = {
                                                cabinetId: cabinet.id,
                                                index: 0,
                                                name: "",
                                                note: "",
                                                dateUpdate: null,
                                                dateUpload: null,
                                                configParam: JSON.parse(JSON.stringify(_default.androsLive))
                                            };

                                            if (!configurations) {
                                                configurations = [];
                                            }
                                            configurations.unshift(conf);
                                            this.setState({
                                                isNew: true,
                                                editingIndex: 0,
                                                editingItem: { name: conf.name, note: conf.note },
                                                sorted: [],
                                            });
                                        }
                                    }
                                }>
                                {t("Nuova")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    handleMenuItemClicked(selectedMenuItem) {
        this.setState({ selectedMenuItem });
    }

    renderConfigurazioneDetail() {
        const { selectedMenuItem } = this.state;
        const View = (selectedMenuItem && this.views[selectedMenuItem.name]) || Expansions;

        return (
            <div style={{ height: "100%", display: "flex" }}>
                <ConfSideMenu
                    {...this.state}
                    onMenuItemClicked={this.handleMenuItemClicked}
                />
                <div className="main-panel-contextual" style={{
                    //display: "flex",
                    flex: 1
                }}>
                    <View
                        {...this.state}
                        {...this.props}
                    />
                </div>

            </div>
        );
    }

    confirmDelete(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    getSelected(options, value) {
        if (value === null)
            return null;
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        };
        return null;
    }

    renderConfigurazioniFooter() {
        const { t, handleConfigClose } = this.props;
        return (
            <Button
                color="secondary"
                onClick={handleConfigClose}
            >
                {t("Chiudi")}
            </Button>
        );
    }

    renderConfigurazioneFooter() {
        const { t } = this.props;
        return (
            <Button
                color="secondary"
                onClick={() => { }}
            >
                <i className="fa fa-chevron-left"></i>
                {" " + t("Indietro")}
            </Button>
        );
    }

    render() {

        // const { t, handleConfigClose } = this.props;
        const { t, cabinet, handleConfigClose } = this.props;
        const { error, conf } = this.state;
        const showConfigurazioni = !conf;

        return (
            <Modal isOpen={true} size="lg" className="modal-umpi-container Configuration fullscreen-modal-dialog">
                <ModalHeader toggle={() => handleConfigClose()}>
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    {showConfigurazioni ? (t("Quadro") + " " + cabinet.label) : this.renderConfigurazioneHeader()}
                </ModalHeader>
                <ModalBody>
                    {showConfigurazioni ? this.renderConfigurazioni() : this.renderConfigurazioneDetail()}
                </ModalBody>
            </Modal>
            /*<div className="full-height-no-header">
                <PanelHeader
                    size="sm"
                />
                <div className="sidebar-contextual-mini content full-height">
                    <Row>
                        <Col xs={12} md={12} >
                            <Card className="full-height-no-header2">
                                <CardHeader className="config-card-header" >
                                    <CardTitle className="config-card-title">
                                        {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                                        {showConfigurazioni ? this.renderConfigurazioniHeader() : this.renderConfigurazioneHeader()}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody style={{ height: "100%", padding: 0 }}>
                                    {showConfigurazioni ? this.renderConfigurazioni() : this.renderConfigurazioneDetail()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>*/
        )
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default Configurations = withTranslation()(connect(mapStateToProps)(Configurations));

import { accessToken, history } from '../helpers';
import { loopbackConstants } from '../constants';
import { toggleLoader } from '../components/Loader/Loader';

/**
 * Metodi wrapper per le chiamate Loopback.
 */
export const loopbackService = {
    list,
    get,
    edit,
    add,
    delete: _delete,
    count,
    getParam,
    invokeOnInstance,
    invoke,
    patch,
    postFuncname,
    putFuncname,
    deleteFuncname,
    customMethod,
    listRelatedModel,
    pushProjectDocumentToModel,
    deleteProjectDocumentToModel,
    listProjectDocumentFromModel
};

/**
 * Restituisce l'elenco filtrato e paginato delle istanze del modello.
 *
 * @param { string } model nome modello loopback.
 * @param { Object } where filtri.
 * @param { Object } limit paginazione.
 * @return { Object[] } elenco delle istanze.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function listRelatedModel(path, parent, model, where = null, limit = null, include = null, order = null, skip = null) {
    const requestOptions = {
        method: 'GET'
    };

    let filter = {};
    if (where) {
        filter = {
            ...filter,
            where
        }
    }
    if (limit) {
        filter = {
            ...filter,
            limit
        }
    }
    if (include) {
        filter = {
            ...filter,
            include
        }
    }
    if (order) {
        filter = {
            ...filter,
            order
        }
    }
    if (skip) {
        filter = {
            ...filter,
            skip
        }
    }

    let url = null;

    if (model === 'androsNotLinked' || model === 'syrasNotLinked') {
        url = loopbackConstants.BASE_URL + '/archive/devices/project/' + where.projectId + '/' + model + accessToken(path);
    }
    else {
        url = loopbackConstants.BASE_URL + '/' + path + '/' + parent + '/' + model + accessToken(path) + (
            filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : ''
        );
    }

    try {
        let response = await fetch(url, requestOptions);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        throw error;
    }
}

/**
 * Esegue una customMethod.
 * @param { string } path loopback.
 * @param { string } model nome modello loopback.
 * @param { Object } instance istanza da modificare.
 * @return { Object } istanza modificata.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function customMethod(path, model, instance, method, customMethod) {
    if (path && (path === 'plant' || path.includes('plant'))) {
        // const currentProjectId = localStorage.getItem('currentProjectId');
        // instance['currentProjectId']=currentProjectId;
    }

    const requestOptions = {
        method: (method ? method : 'PUT'),
        headers: { 'Content-Type': 'application/json' },
        body: null
    };

    let response = await fetch(
        loopbackConstants.BASE_URL + '/' + path + '/' + model + '/' + instance + '/' + customMethod + accessToken(path),
        requestOptions
    );

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        try {
            let data = await response.json();
            //console.log('edit response ok', data);
            return data;
        } catch (error) {
            console.error('edit error=', error);
        }
    }
    else {
        let error = null;

        if (typeof (response.json) === 'function') {
            error = await response.json();
        }
        else {
            error = response;
        }

        console.error('edit response not ok', error);
        throw error;
    }
}

/**
 * Restituisce l'elenco filtrato e paginato delle istanze del modello.
 *
 * @param { string } model nome modello loopback.
 * @param { Object } where filtri.
 * @param { Object } limit paginazione.
 * @return { Object[] } elenco delle istanze.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function list(path, model, where = null, limit = null, include = null, order = null, skip = null) {
    const requestOptions = {
        method: 'GET'
    };

    let filter = {};
    if (where) {
        filter = {
            ...filter,
            where
        }
    }
    if (limit) {
        filter = {
            ...filter,
            limit
        }
    }
    if (include) {
        filter = {
            ...filter,
            include
        }
    }
    if (order) {
        filter = {
            ...filter,
            order
        }
    }
    if (skip) {
        filter = {
            ...filter,
            skip
        }
    }

    let url = null;
    if (model === 'androsNotLinked' || model === 'syrasNotLinked') {
        url = loopbackConstants.BASE_URL + '/archive/devices/project/' + where.projectId + '/' + model + accessToken(path);
    }
    else {
        url = loopbackConstants.BASE_URL + '/' + path + '/' + model + accessToken(path) + (
            filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : ''
        );
    }

    try {
        toggleLoader(true);
        let response = await fetch(url, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Restituisce l'istanza del modello corrispondente all'id passato come argomento.
 *
 * @param { string } model nome del modello loopback.
 * @param { Number } id identificatore istanza.
 * @param { Object } filter filtro opzionale.
 * @return { Object } istanza.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function get(path, model, id, filter) {
    const requestOptions = {
        method: 'GET'
    };

    let idParam = "";
    if (id !== null) {
        idParam = '/' + id;
    }

    let requestUrl = loopbackConstants.BASE_URL + '/' + path + '/' + model + idParam + accessToken(path)
        + (filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : '');

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let instance = await response.json();
            return instance;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Modifica l'istanza di un modello.
 * @param { string } path loopback.
 * @param { string } model nome modello loopback.
 * @param { Object } instance istanza da modificare.
 * @return { Object } istanza modificata.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function edit(path, model, instance, method) {
    if (path && path === 'plant') {
        // const currentProjectId = localStorage.getItem('currentProjectId');
        // instance['currentProjectId']=currentProjectId;
    }

    const requestOptions = {
        method: (method ? method : 'PUT'),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instance)
    };

    try {
        toggleLoader(true);
        let response = await fetch(
            loopbackConstants.BASE_URL + '/' + path + '/' + model + accessToken(path),
            requestOptions
        )
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            try {
                let data = await response.json();
                return data;
            } catch (error) {
                console.error('edit error=', error);
            }
        }
        else {

            let error = null;
            if (typeof (response.json) === 'function') {
                error = await response.json();
            }
            else {
                error = response;
            }
            console.error('edit response not ok', error);
            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Aggiunge una o più istanze di un modello
 * @param { string } path loopback.
 * @param { string } model nome modello loopback.
 * @param { Object|[] } instance istanz(a|e) da aggiungere.
 * @return { Object } istanza modificata.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function add(path, model, instance) {
    if (path && path === 'plant') {
        const currentProjectId = localStorage.getItem('currentProjectId');
        instance['currentProjectId'] = currentProjectId;
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instance)
    };

    try {
        toggleLoader(true);
        let response = await fetch(
            loopbackConstants.BASE_URL + '/' + path + '/' + model + accessToken(path),
            requestOptions
        )
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;
            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Elimina l'istanza del modello.
* @param { string } path nome modello loopback.
 * @param { string } model nome modello loopback.
 * @param { Number } id del'istanza.
 * @return { Object } risultato dell'eliminazoine.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function _delete(path, model, id) {
    const requestOptions = {
        method: 'DELETE'
    };

    try {
        toggleLoader(true);
        let response = await fetch(loopbackConstants.BASE_URL + '/' + path + '/' + model + '/' + id + accessToken(path), requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

// /**
//  *
//  * @param {*} path
//  * @param {*} model
//  * @param {*} where
//  */
// async function count( path, model, where=null ) {
//     const requestOptions = {
//         method: 'GET'
//     };

//     if (path && path.startsWith('plant')){
//         const parts = path.split('@');
//         path=parts[0];
//     }

//     const requestUrl =  loopbackConstants.BASE_URL+
//         '/'+
//         path+
//         '/'+
//         model+
//         '/count'+
//         accessToken(path) +
//         (where ? '&where=' + encodeURIComponent(JSON.stringify(where)) : '');

//     try {
//         let response = await fetch(
//            requestUrl, requestOptions);
//         if (response.ok){
//             let data = await response.json();
//             return data;
//         }
//         else {
//             let error = null;
//             if (typeof(response.json)==='function')
//                 error = await response.json();
//             else
//                 error = response;

//             throw error;
//         }

//     } catch(error){
//         throw error;
//     }
// }

/**
 * Restituisce il numero di istanze del modello che soddisfano i filtri applicati.
 *
 * @param { string } model nome modello loopback.
 * @param { Object } where filtri applicati.
 * @return { Number } numero di istanze filtrate.
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function count(path, model, where = null, projectId = null) {
    const requestOptions = {
        method: 'GET'
    }

    const requestUrl =
        loopbackConstants.BASE_URL +
        '/' +
        path +
        '/' +
        model +
        '/count' +
        accessToken(path, projectId) +
        (where ? '&where=' + encodeURIComponent(JSON.stringify(where)) : '')
        ;

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;

        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 *  Restituisce l'istanza relativa al param del modello passato come argomento
 *
*/
async function getParam(path, model, param, projectId = null, extra = null) {
    const requestOptions = {
        method: 'GET'
    }

    const requestUrl =
        loopbackConstants.BASE_URL +
        '/' +
        path +
        '/' +
        model +
        '/' +
        param +
        '/' +
        (extra !== null ? extra : "") +
        accessToken(path, projectId)
        ;

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            //console.log();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 *
 * @param {*} path
 * @param {*} model
 * @param {*} id
 * @param {*} funcname
 * @param {*} projectId
 */
async function invokeOnInstance(path, model, id, funcname, projectId = null, body = null) {
    let requestOptions = {
        method: 'POST',
    }

    if (body) {
        requestOptions = {
            ...requestOptions,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }
    }

    const requestUrl =
        loopbackConstants.BASE_URL +
        '/' +
        path +
        '/' +
        model +
        '/' +
        id +
        '/' +
        funcname +
        '/' +
        accessToken(path, projectId)
        ;

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * @param {*} path
 * @param {*} model
 * @param {*} funcname
 * @param string projectId
 * @param string language
 */
async function invoke(path, model, funcname, projectId, language) {
    const requestOptions = {
        method: 'GET'
    }

    const requestUrl =
        loopbackConstants.BASE_URL +
        '/' +
        path +
        '/' +
        model +
        '/' +
        funcname +
        '/' +
        accessToken(path, projectId) +
        (language ? '&language=' + language : "")
        ;

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 *
 * @param {*} path
 * @param {*} model
 * @param {*} modelId
 * @param {*} instance
 * @param {*} projectId
 */
async function patch(path, model, modelId, instance, projectId = null) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(instance)
    }

    const requestUrl =
        loopbackConstants.BASE_URL +
        '/' +
        path +
        '/' +
        model +
        '/' +
        modelId +
        accessToken(path, projectId)
        ;

    try {
        toggleLoader(true);
        let response = await fetch(requestUrl, requestOptions);
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;

            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Invoca una funzione usando il metodo post
 * @throws { Object } risposta in caso d'errore.
 *
 */
async function postFuncname(path, model, funcname, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    try {
        toggleLoader(true);
        let response = await fetch(
            loopbackConstants.BASE_URL + '/' + path + '/' + model + '/' + funcname + accessToken(path),
            requestOptions
        )
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;
            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 *
 * @param {*} path
 * @param {*} model
 * @param {*} funcname
 * @param {*} body
 */
async function putFuncname(path, model, funcname, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    try {
        toggleLoader(true);
        let response = await fetch(
            loopbackConstants.BASE_URL + '/' + path + '/' + model + '/' + funcname + accessToken(path),
            requestOptions
        )
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;
            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 *
 * @param {*} path
 * @param {*} model
 * @param {*} funcname
 * @param {*} body
 */
async function deleteFuncname(path, model, funcname) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        toggleLoader(true);
        let response = await fetch(
            loopbackConstants.BASE_URL + '/' + path + '/' + model + '/' + funcname + accessToken(path),
            requestOptions
        )
        toggleLoader(false);

        if (response.status === 401) {
            history.push('/pages/login-page');
        }

        if (response.ok) {
            let data = await response.json();
            return data;
        }
        else {
            let error = null;
            if (typeof (response.json) === 'function')
                error = await response.json();
            else
                error = response;
            throw error;
        }
    } catch (error) {
        toggleLoader(false);
        throw error;
    }
}

/**
 * Caricamento documento su progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @return {Object} body document
 * @throws {Object} risposta in caso d'errore.
 */
async function pushProjectDocumentToModel(id, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const url = loopbackConstants.BASE_URL + '/document/upload' + accessToken('admin') + '&projectId=' + id;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

/**
 * Rimozione documento su progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @return {Object} body document
 * @throws {Object} risposta in caso d'errore.
 */
async function deleteProjectDocumentToModel(name, id, type, deviceId) {
    const requestOptions = {
        method: 'DELETE'
    };

    const url = loopbackConstants.BASE_URL + '/document/delete' + accessToken('admin') +
    '&name=' + name + '&projectId=' + id + (
        (type && deviceId > 0) ? "&type=" + type + "&deviceId=" + deviceId : ""
    );

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

/**
 * Download lista documenti su progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @return {Object} type media
 * @return {Object} deviceid media
 * @throws {Object} risposta in caso d'errore.
 */
async function listProjectDocumentFromModel(id, type, deviceId) {
    const requestOptions = {
        method: 'GET'
    };

    const url = loopbackConstants.BASE_URL + '/document/list' + accessToken('admin') + '&projectId=' + id + (
        (type && deviceId > 0) ? "&type=" + type + "&deviceId=" + deviceId : ""
    );

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

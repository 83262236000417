import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Header, Sidebar } from "components";
import { connect } from 'react-redux';
import dashboardRoutes from "routes/dashboard.jsx";

class Dashboard extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <Sidebar {...this.props} routes={dashboardRoutes} />
                <div className={"main-panel" + (() => {
                    const disabledViews = [
                        '/zones-navigator',
                        '/plants-navigator',
                        '/timerprofiles',
                    ];

                    if (disabledViews.includes(this.props.location.pathname)) {
                        return " umpi-perfect-scrollbar-off";
                    }

                    return "";
                })()} ref="mainPanel">
                    <Header {...this.props} />
                    <Switch>
                        {dashboardRoutes.map((prop, key) => {
                            if (prop.collapse) {
                                return prop.views.map((prop2, key2) => {
                                    return (
                                        <Route
                                            path={prop2.path}
                                            component={prop2.component}
                                            key={key2}
                                        />
                                    );
                                });
                            }

                            if (prop.redirect) {
                                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                            }

                            return (
                                <Route path={prop.path} component={prop.component} key={key} />
                            );

                        })}
                    </Switch>
                </div>
            </div>
        );
    }
}

//export default Dashboard;

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };

import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import { Button, Documents } from "components";
import ReactJson from 'react-json-view';
import { userService } from '../../services';
import { roleConstants } from '../../constants';
import Select from "react-select";
import User from '../../entities/User'
import { userActions, loopbackActions } from '../../actions';
import { withTranslation } from 'react-i18next';

class UserEdit extends React.Component {

    constructor(props) {
        super(props)

        this.loggedinUser = User.current();
        this.roleOptions = [];
        let roles = JSON.parse(localStorage.getItem('userroles'));
        const user = props.user; // || user;
        let selectedRoleOption = null;
        for (var role of roles) {
            let option = { value: role.id, label: role.role }
            this.roleOptions.push(option);
            if (user && user.userRoleId === role.id) {
                selectedRoleOption = option;
            }
        }

        this.state = {
            currentUserId: 0,
            mediaStorage: [],
            mediaPreview: null,
            user: {
                id: '',
                email: '',
                password: '',
                passwordConfirm: '',
                name: '',
                surname: '',
                emailVerified: true,
                userRoleId: 1,
                isActive: true,
                documents: {}
            },
            submitted: false,
            modalNotice: false,
            emailState: '',
            nameState: '',
            surnameState: '',
            passwordState: '',
            roleState: '',
            selectedRoleOption,
            isOpenModal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuemnts = this.handleDeleteDocuemnts.bind(this);
    }

    componentWillMount() {
        this.loadUserData(this.props.userId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentUserId !== nextProps.userId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadUserData(nextProps.userId);
            });
        }

        if (nextProps.error) {
            this.setState({ error: nextProps.error, modalNotice: true });
        }
        else {
            if (nextProps.user) {
                let user = nextProps.user, selectedRoleOption = null;
                user.password = "";
                user.passwordConfirm = "";

                for (let option of this.roleOptions) {
                    if (user.userRoleId === option.value) {
                        selectedRoleOption = option;
                        break;
                    }
                }

                this.setState({
                    user,
                    selectedRoleOption
                });
            }
        }
    }

    handleUpdateDocuments(p_documents) {
        let user = this.state.user;
        user.documents = p_documents;
        this.setState({ user });
    }

    async handleDeleteDocuemnts(name, refId) {
        // Delete doc media
        await userService.deleteImageByUserId(
            refId,
            name
        );

        // Refresh
        this.loadDocuments(refId);
    }

    loadDocuments = async (userId) => {
        let docs = null;

        try {
            // Create doc media
            docs = await userService.getImageByUserId(userId);
        } catch (err) {
            console.error(err);
            docs = null;
        }

        // Save to storage
        this.setState({
            mediaStorage: (docs && docs.base64) ? [{
                name: "icon.png",
                link: docs.base64
            }] : []
        });
    }

    loadUserData = (userId) => {
        this.setState({
            addMode: userId === 0,
            currentUserId: userId,
            mediaStorage: [],
            mediaPreview: null,
            user: {
                id: '',
                email: '',
                password: '',
                passwordConfirm: '',
                name: '',
                surname: '',
                emailVerified: true,
                userRoleId: 1,
                isActive: true,
                documents: {}
            },
            submitted: false,
            modalNotice: false,
            emailState: '',
            nameState: '',
            surnameState: '',
            passwordState: '',
            selectedRoleOption: null
        }, () => {
            if (userId > 0) {
                this.props.dispatch(loopbackActions.get('admin', 'users', userId, { include: 'projects' }));
                this.loadDocuments(userId);
            }
        });
    }

    handleChange(e) {
        const { name, value } = e.target;

        let user = this.state.user;
        let emailState = "";
        let passwordState = "";

        user[name] = value;

        if (name === 'email') {
            var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value === '') {
                emailState = "";
            }
            else if (emailRex.test(value)) {
                emailState = "has-success";
            }
            else {
                emailState = "has-danger";
            }
        }
        else if (name === 'password' || name === 'passwordConfirm') {
            if (value === '' && (name === 'password' ? this.state.user.passwordConfirm : this.state.user.password) === '') {
                passwordState = "";
            }
            else if (value === (name === 'password' ? this.state.user.passwordConfirm : this.state.user.password)) {
                passwordState = "has-success";
            }
            else {
                passwordState = "has-danger";
            }
        }
        this.setState({ user, emailState, passwordState });
    }

    handleSubmit(e) {
        e.preventDefault();

        let { user, addMode, selectedRoleOption } = this.state;
        let hasValidationErrors = false;

        if (user.email === '') {
            this.setState({ emailState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (user.name === '') {
            this.setState({ nameState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (user.surname === '') {
            this.setState({ surnameState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (addMode && (user.password === '' || user.password !== user.passwordConfirm)) {
            this.setState({ passwordState: 'has-danger' });
            hasValidationErrors = true;
        }

        if ([null, undefined].includes(user.userRoleId) || selectedRoleOption === null) {
            this.setState({ roleState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (hasValidationErrors) {
            return;
        }

        this.setState({ submitted: true });
        const { dispatch } = this.props;

        if (selectedRoleOption) {
            user.userRoleId = selectedRoleOption.value;
        }
        else {
            user.userRoleId = roleConstants.GUEST;
        }

        const userCallback = async (response) => {
            // Check for data
            if (response &&
                response.user &&
                response.user.id > 0) {
                // Check for documents
                if (response.user.documents) {
                    // Get keys
                    let keys = Object.keys(response.user.documents);

                    // Check for docs into storage
                    for (let i = 0; i < keys.length; i++) {
                        // Get current media
                        let media = response.user.documents[keys[i]];

                        // Create doc media
                        await userService.postImageByUserId({
                            "userId": response.user.id,
                            "file":   media.file
                        });
                    }
                }
            }

            // Close modal
            this.closeModal();
        };

        if (addMode) {
            delete user.id;
            user.emailVerified = true;
            dispatch(userActions.add(user, userCallback));
        }
        else {
            if (user.password === "") {
                delete user.password;
                delete user.passwordConfirm;
            }

            user.emailVerified = true;
            dispatch(userActions.edit(user, false, userCallback));
        }
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentUserId: 0,
            mediaStorage: [],
            mediaPreview: null,
            user: {
                id: '',
                email: '',
                password: '',
                passwordConfirm: '',
                name: '',
                surname: '',
                emailVerified: true,
                userRoleId: 1,
                isActive: true,
                documents: {}
            },
            submitted: false,
            modalNotice: false,
            emailState: '',
            nameState: '',
            surnameState: '',
            passwordState: '',
            roleState: '',
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    render() {
        const { t } = this.props;
        const {
            addMode,
            user,
            emailState,
            nameState,
            surnameState,
            passwordState,
            roleState,
            selectedRoleOption,
            mediaStorage,
            mediaPreview,
            currentUserId
        } = this.state;

        const {
            //id,
            email,
            password,
            name,
            surname,
            passwordConfirm,
            isActive,
            documents
        } = user;

        const curUser = User.current();
        const canEdit = (curUser.userRoleId !== roleConstants.GUEST);

        return (
            <div>
                <Modal isOpen={this.state.modalNotice} className="modal-notice" >
                    <ModalHeader className="title-up">
                        {t("ERRORE")}
                    </ModalHeader>
                    <ModalBody>
                        <ReactJson src={this.state.error} />
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="info" round onClick={() => { this.setState({ modalNotice: false }) }}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi utente") : t("Modifica utente") + " " + name)}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    {/*!addMode && <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>ID</Label>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    placeholder=""
                                                    value={id}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>*/}
                                    <Row>
                                        <Col>
                                            <FormGroup className={emailState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Email")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={email}
                                                    name='email'
                                                    onChange={this.handleChange}
                                                    style={(!(email && email.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={passwordState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Password")}</strong>
                                                </Label>
                                                <Input
                                                    type="password"
                                                    value={password}
                                                    name='password'
                                                    onChange={this.handleChange}
                                                    style={(!(password && password.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={passwordState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Conferma password")}</strong>
                                                </Label>
                                                <Input
                                                    type="password"
                                                    value={passwordConfirm}
                                                    name='passwordConfirm'
                                                    onChange={this.handleChange}
                                                    style={(!(passwordConfirm && passwordConfirm.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={nameState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Nome")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={name}
                                                    name='name'
                                                    onChange={this.handleChange}
                                                    style={(!(name && name.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={surnameState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Cognome")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={surname}
                                                    name='surname'
                                                    onChange={this.handleChange}
                                                    style={(!(surname && surname.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={roleState}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Ruolo")}</strong>
                                                </Label>
                                                <Select
                                                    className="primary"
                                                    placeholder={t("Ruolo")}
                                                    name="group"
                                                    value={selectedRoleOption}
                                                    options={this.roleOptions}
                                                    onChange={option =>
                                                        this.setState({ selectedRoleOption: option })
                                                    }
                                                    style={(!(selectedRoleOption)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup style={{ paddingTop: 9 }}>
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Attivo")}</strong>
                                                </Label>
                                                <br />
                                                <Switch
                                                    onText={<i className="now-ui-icons ui-1_check" />}
                                                    offText={
                                                        <i className="now-ui-icons ui-1_simple-remove" />
                                                    }
                                                    onColor={"green"}
                                                    offColor={"lightgray"}
                                                    onChange={(el, state) => {
                                                        user.isActive = state;
                                                        this.setState({ user });
                                                    }}
                                                    value={isActive}
                                                />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Documents
                                                canEdit={canEdit}
                                                disabled={Object.keys(documents ? documents : {}).length > 0}
                                                acceptFile={["image"]}
                                                documents={mediaStorage}
                                                onUpdate={this.handleUpdateDocuments}
                                                onDelete={(media) => this.handleDeleteDocuemnts(media.name, currentUserId)}
                                                onPreview={(media) => this.setState({ mediaPreview: media })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save">
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { users } = loopback;
    return {
        user: users && users.instance,
        error: users && users.error
    };
}

const connectedUserEdit = withTranslation()(connect(mapStateToProps)(UserEdit));
export { connectedUserEdit as UserEdit };

import { accessToken } from '../helpers';
import { loopbackConstants } from '../constants';
import { history } from '../helpers';

export const userroleService = {
    getAll
};

/**
 * Restituisce tutti i ruoli possibili per un utente.
 */
async function getAll() {
    const requestOptions = {
        method: 'GET'
    };
    let response = await fetch(loopbackConstants.BASE_URL+'/admin/userroles'+accessToken('admin'), requestOptions);
    if (response.status===401){
        history.push('/pages/login-page');
    }

    if (response.ok){
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

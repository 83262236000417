import User from '../entities/User'

import {roleConstants, permissionConstants} from '../constants';

export const permissions = {
    grant
};

function grant(permission) {
    const loggedinUser = User.current();
    let ok = false;
    if (!loggedinUser) {
        return false;
    }
    switch (permission) {
        case permissionConstants.SHOW_USERS:
        case permissionConstants.SHOW_SETTINGS:
            ok = User.isWithinOneOfGroups(loggedinUser, roleConstants.SUPERUSERUMPI)
            break;
        case permissionConstants.SHOW_ARCHIVES:
            ok = User.isWithinOneOfGroups(loggedinUser, roleConstants.SUPERUSERUMPI, roleConstants.ADMIN )
        break;
        case permissionConstants.SHOW_ONLY_OWN_PROJECTS:
            ok = (loggedinUser.userRoleId !== roleConstants.SUPERUSERUMPI);
            break;
        case permissionConstants.EDIT_PROJECTS:
        case permissionConstants.CREATE_PROJECTS:
            ok = !User.isWithinOneOfGroups(loggedinUser, roleConstants.GUEST, roleConstants.ADMIN, roleConstants.CAT)
            break;
        case permissionConstants.SHOW_DEVICES:
            ok = (loggedinUser.userRoleId === roleConstants.SUPERUSERUMPI);
            break;
        case permissionConstants.SHOW_ONLY_OWN_USERS:
            ok = User.isWithinGroup(loggedinUser, roleConstants.CAT);
            break;
        default:
            ok = false;
    }
    return ok;
}
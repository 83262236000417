import React from "react";
import BaseView from "./BaseView";
import DiscreteValues from "../../constants/slider.constants";
import Switch from "react-bootstrap-switch";
import Nouislider from "react-nouislider";
import {
  Input,
} from "reactstrap";

const colorsPalette = [
  "#64febe",
  "#3277fe",
  "#0731d8",
  "#f1be7d",
  "#63a36d",
  "#e7290e",
  "#4b5ecd",
  "#4b5d6c",
  "#255dca",
  "#481324",
  "#98a973",
  "#84e96c",
  "#0ab6f3",
  "#d30aa5",
  "#4c4227",
  "#12a52f",
  "#f24895",
  "#db43e8",
  "#1f7efa",
  "#33aa03",
  "#880858",
  "#7fe007",
  "#8e37f2",
  "#ada0cd",
  "#c272b6",
  "#d829ff",
  "#6c17bc",
  "#ad0b1a",
  "#448241",
  "#a2c0a2",
  "#79e5f9",
  "#cf984c",
]

export default class Commands extends BaseView {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getOptionsSunrize() {
    const { t } = this.props;
    return [
      { value: "ALBA", label: t("Alba") },
      { value: "TRAMONTO", label: t("Tramonto") }
    ];
  }

  getOptionsWeekDay() {
    const { t } = this.props;
    return [
      { value: "MON", label: t("Lunedì") },
      { value: "TUE", label: t("Martedì") },
      { value: "WED", label: t("Mercoledì") },
      { value: "THU", label: t("Giovedì") },
      { value: "FRI", label: t("Venerdì") },
      { value: "SAT", label: t("Sabato") },
      { value: "SUN", label: t("Domenica") }
    ];
  }

  getOptionsDay() {
    const { t } = this.props;
    return [
      { value: "ALL", label: t("Tutti") },
      { value: "FERIAL", label: t("Feriali") },
      { value: "FEST", label: t("Festivi") },
      { value: "MON", label: t("Lunedì") },
      { value: "TUE", label: t("Martedì") },
      { value: "WED", label: t("Mercoledì") },
      { value: "THU", label: t("Giovedì") },
      { value: "FRI", label: t("Venerdì") },
      { value: "SAT", label: t("Sabato") },
      { value: "SUN", label: t("Domenica") }
    ];
  }

  getOptionsEvent() {
    const { t } = this.props;
    return [
      { value: "H", label: t("Alto") },
      { value: "L", label: t("Basso") }
    ];
  }

  getCommandsByType(type) {
    const { conf } = this.props;
    let data = [];
    if (conf && conf.configParam && conf.configParam.commands) {
      conf.configParam.commands.forEach((com, key) => {
        if (com.type === type) {
          data.push({ ...com, index: key });
        }
      });
    }
    return data;
  }

  getOptionsOuts() {

    const { t, conf } = this.props;

    let optionsOuts = [];
    let gruppi = {};

    let palette = conf.configParam.groupColorPalette;

    if (!palette)
      palette = colorsPalette;

    if (conf && conf.configParam && conf.configParam.expansions) {

      // ciclo sulle espansioni definite nella configurazione
      conf.configParam.expansions.forEach((expansion, keyexp) => {

        if (expansion.periferals) {

          expansion.periferals.forEach((periferal, keyper) => {

            if (periferal.properties === null) {
              periferal.properties = {
                label: ""
              };
            }

            if (periferal.type === 'AN-OUT' || periferal.type === 'DIG-OUT') {

              let option = {
                value: periferal.code,
                label: (periferal.properties.label === "" ? periferal.type + " - " + periferal.code : periferal.properties.label),
                color: this.hexToRGB(palette[keyper % 32], 0.7),
                type: periferal.type
              };

              optionsOuts.push(option);

            }

            if (periferal.type === 'SYRAS') {
              if (periferal.properties && periferal.properties.group) {
                if (gruppi[periferal.properties.group] === null) {
                  gruppi[periferal.properties.group] = periferal.properties.group;
                  const option = {
                    color: this.hexToRGB(palette[periferal.properties.group], 0.7),
                    value: periferal.properties.group,
                    label: t('Gruppo') + " " + periferal.properties.group,
                    type: 'GRP'
                  }
                  optionsOuts.push(option);
                }
              }
            }

          });
        }
      });
    }

    return optionsOuts;
  }

  getOptionsInps() {
    const { conf } = this.props;
    let optionsInps = [];
    if (conf && conf.configParam && conf.configParam.expansions) {
      // ciclo sulle espansioni definite nella configurazione
      conf.configParam.expansions.forEach((expansion, keyexp) => {
        if (expansion.periferals) {
          expansion.periferals.forEach((periferal, keyper) => {
            if (periferal.properties === null) {
              periferal.properties = {
                label: ""
              };
            }
            if (periferal.type === 'DIG-IN') {
              let option = {
                value: periferal.code,
                label: (periferal.properties.label === "" ? periferal.type + " - " + periferal.code : periferal.properties.label)
              };
              optionsInps.push(option);
            }
          });
        }
      });
    }
    return optionsInps;
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  renderValue(field, row, editingIndex, editingItem, optionsOuts) {

    const { t } = this.props;
    const isEditing = (row.index === editingIndex);

    if (isEditing) {

      const selectedOption = this.getSelected(optionsOuts, editingItem.output);

      if (!selectedOption) {
        return null;
      }

      if (selectedOption.type && selectedOption.type === 'DIG-OUT') {
        // uscita digitale --> switch 0/1
        return (
          <div className="status-table-value" style={{ textAlign: "center" }}>
            <Switch
              onText={<i className="now-ui-icons ui-1_check" />}
              offText={<i className="now-ui-icons ui-1_simple-remove" />}
              onColor={"green"}
              offColor={"lightgray"}
              onChange={(el, state) => {
                editingItem[field] = (state ? 1 : 0);
                this.setState({ editingItem })
              }}
              value={editingItem[field] === 1}
            />
          </div>
        );
      }
      else if (selectedOption.type && selectedOption.type === 'GRP') {
        // gruppo --> select con range di 15 valori
        return (
          <div style={{ display: "flex", alignItems: "center", }}>
            <span style={{ flex: 1, paddingLeft: "15px", paddingRight: "15px" }}>
              <Nouislider
                start={[editingItem[field]]}
                connect={[true, false]}
                snap={true}
                range={DiscreteValues.legacy}
                onSlide={(value) => {
                  //this.setState({ actualLightState: value[0] });
                  editingItem[field] = value[0];
                  this.setState({ editingItem })

                }}
                onChange={(value) => {
                  editingItem[field] = parseInt(value[0], 10);
                  this.setState({ editingItem })
                }}
              /></span>
            <span style={{ flex: 1, textAlign: "center" }}>
              {((editingItem[field] === null || editingItem[field] === 0) ? t("SPENTO") : (editingItem[field] === 100 ? t("ACCESO") : parseInt(editingItem[field], 10) + " %"))}
            </span>

          </div>
        );
      }
      else {
        // uscita analogica --> solo valori positivi incluso lo 0
        return (
          <div><Input
            type="number"
            min="0"
            value={editingItem[field]}
            onChange={(e) => {
              const { value } = e.target;
              if (value < 0) {
                let validationError = { field: 'value', error: t('Valori negativi non ammissibili') }
                this.setState(validationError);
                return;
              }
              editingItem[field] = value;
              this.setState({ editingItem })
            }}
          />
          </div>
        );
      }
    } else {

      const selectedOption = this.getSelected(optionsOuts, row.original.output);
      if (!selectedOption) {
        return null;
      }

      if (selectedOption.type && selectedOption.type === 'DIG-OUT') {
        // uscita digitale --> switch 0/1
        return (<div align="center">{(row.value ? "ON" : "OFF"
          // <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-check-square-o" aria-hidden="true"></i> :
          // <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-square-o" aria-hidden="true"></i>
        )} </div>);
      }
      else if (selectedOption.type && selectedOption.type === 'GRP') {
        // gruppo --> slider
        return (
          <div style={{ textAlign: "center" }}>
            {((row.value === null || row.value === 0) ? t("SPENTO") : (row.value === 100 ? t("ACCESO") : row.value + " %"))}
          </div>
        );
      }
      else {
        // uscita analogica --> solo valori positivi incluso lo 0
        return (<div className="no-overflow" align="">{row.value}</div>);
      }

    }
  }

}

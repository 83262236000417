import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import Select from "react-select";
import { Button, Documents } from "components";
import { withTranslation } from 'react-i18next';
import { googleConstants, roleConstants } from '../../constants';
import BoundsOnChangeMap from './BoundsOnChangeMap';
import { utils } from '../../helpers';
import LocationSearchInput from './react-places-autocomplete/LocationSearchInput';
import User from '../../entities/User';

import $ from 'jquery';
window.$ = $;

function getMapCoordinatesObj(map) {
    const bounds = map.getBounds();
    let coordinatesObj = bounds.toJSON();
    let coordinates = JSON.parse(JSON.stringify(coordinatesObj));
    coordinates.center = map.getCenter().toJSON();
    coordinates.zoom = map.getZoom();
    return coordinates;
}
class CabinetEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mediaStorage: [],
            mediaPreview: null,
            item: {
                label: '',
                address: '',
                andros_sn: '',
                energySupplier: '',
                pod: '',
                clientCode: '',
                power: '',
                note: '',
                documents: {}
            },
            relations:       props.instances && props.instances.relations                      ? props.instances.relations       : null,
            androsNotLinked: props.instances && Array.isArray(props.instances.androsNotLinked) ? props.instances.androsNotLinked : [],
            isOpenModal:     false,
            map: null,
            emptyCoordinatesError: false,
            geometry: {
                lat: null,
                lng: null
            },
            zoom: 11,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCoordy = this.handleChangeCoordy.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.hiddenFileInput = React.createRef();

        this.handleMapMounted = this.handleMapMounted.bind(this);
        this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this);
        this.onLocationSearchInputLatLng = this.onLocationSearchInputLatLng.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal) {
            this.setState({
                isOpenModal:     nextProps.isOpenModal,
                relations:       nextProps.instances && nextProps.instances.relations                      ? nextProps.instances.relations       : null,
                androsNotLinked: nextProps.instances && Array.isArray(nextProps.instances.androsNotLinked) ? nextProps.instances.androsNotLinked : []
            });
        }
    }

    handleUpdateDocuments(p_documents) {
        let item = this.state.item;
        item.documents = p_documents;
        this.setState({ item });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let item = this.state.item;
        item[name] = value;
        this.setState({ item });
    }

    handleChangeCoordy(e) {
        const { name, value } = e.target;
        let geometry = this.state.geometry;
        geometry[name] = value.trim();
        this.setState({ geometry });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.closeModal(true);
    }

    closeModal = (logic) => {
        // Backup
        const { item, geometry } = this.state;
        // reset all
        this.setState({
            mediaStorage: [],
            mediaPreview: null,
            item: {
                label: '',
                address: '',
                andros_sn: '',
                energySupplier: '',
                pod: '',
                clientCode: '',
                power: '',
                note: '',
                documents: {}
            },
            isOpenModal: false,
            map: null,
            emptyCoordinatesError: false,
            geometry: {
                lat: null,
                lng: null
            },
            zoom: 11,
        }, () => {
            // close
            this.props.onModalClose(
                logic && item ? {
                    ...item,
                    geometry: geometry
                } : null
            );
        });
    }

    checkPreSubmit() {
        const { item } = this.state;

        return (!(
            item &&
            item.label
        )) ? true : false;
    }

    handleMapMounted(mountedMap) {
        this.setState({
            map: mountedMap,
        })
    }

    handleMapBoundsChanged() {
        const user = User.current()
        if (User.isWithinGroup(user, roleConstants.CAT)) {
            return;
        }
        const { map } = this.state;
        const coordinates = getMapCoordinatesObj(map);
        let item = this.state.item;
        item.zoom = coordinates.zoom;
        item.geometry = coordinates.center;
        this.setState({
            item,
            emptyCoordinatesError: false
        });
    }

    onLocationSearchInputLatLng(latLng, results) {
        if (results.length <= 0) {
            this.setState({
                item: {
                    ...this.state.item,
                    address: ''
                },
                geometry: {
                    lat: null,
                    lng: null
                },
                zoom: 11
            }, () => {
                this.setState({  emptyCoordinatesError: false });
            });
            return;
        }

        const bounds = results[0].geometry.viewport
        const $mapDiv = $('#projectCoordinatesMap')
        const mapDim = { height: $mapDiv.height(), width: $mapDiv.width() }
        const zoom = utils.googleMapBestZoomLevelFromBounds(bounds, mapDim);

        this.setState({
            item: {
                ...this.state.item,
                address: results[0].formatted_address
            },
            geometry: {
                lat: latLng.lat,
                lng: latLng.lng
            },
            zoom,
            emptyCoordinatesError: false
        });
    }

    render() {
        const { t } = this.props;
        const { item, androsNotLinked, mediaStorage, isOpenModal, map, emptyCoordinatesError, geometry, zoom } = this.state;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);

        const {
            label,
            address,
            andros_sn,
            energySupplier,
            pod,
            clientCode,
            power,
            note
        } = item;

        let androsSnOptions = [];

        if (androsNotLinked) {
            androsSnOptions = androsSnOptions.concat(androsNotLinked.map((elem) => {
                return {
                    value: elem.sn,
                    label: elem.sn
                }
            }));
        }

        // Resolve geometry
        let projectGeometry     = null;
        let arrayProjects       = [];
        const selectedProjectId = localStorage.getItem('selectedProjectId');
        const storageProjects   = localStorage.getItem('projects');

        try {
            let tmpArr = JSON.parse(storageProjects);
            arrayProjects = Array.isArray(tmpArr) ? tmpArr : [];
        }
        catch (eAp) {
            arrayProjects = [];
        }

        // Search project geometry
        arrayProjects.map((prj) => {
            if (prj.id === Number(selectedProjectId) &&
                prj.geometry &&
                prj.geometry.lat !== null &&
                prj.geometry.lng !== null) {
                projectGeometry = prj.geometry;
            }
            return true;
        });

        // Create map geometry ref
        const mapGeometry = (
            geometry &&
            geometry.lat !== null &&
            geometry.lng !== null
        ) ? geometry : projectGeometry;

        const {
            lat,
            lng
        } = geometry;

        return (
            <Modal size="lg" isOpen={isOpenModal} className="modal-umpi-container CabinetEdit">
                <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                    <ModalHeader toggle={() => this.closeModal(false)}>
                        {t("Create plant")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="content">
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong style={{ color: "#990000"}}>{t("Plant name")}</strong>
                                        </Label>
                                        <Input type="text" value={label} name='label' onChange={this.handleChange} style={(!(label && label.length > 0)) ? { borderColor: "#ff7777" } : {}} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Address")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        {map !== null &&
                                            <LocationSearchInput
                                                value={address}
                                                placeholder={' '}
                                                onLatLng={this.onLocationSearchInputLatLng}
                                                customErrorMessagForStatus={(errorStatus) => {
                                                    if (errorStatus === "ZERO_RESULTS") {
                                                        return t("Nessun risultato")
                                                    }
                                                    else if (errorStatus === "OVER_QUERY_LIMIT") {
                                                        return t("Limite di richieste giornaliere superato")
                                                    }
                                                    else {
                                                        return t("Impossibile trovare il luogo cercato")
                                                    }
                                                }}
                                            />
                                        }
                                        {
                                            emptyCoordinatesError && <Label>{t("Selezionare un area grografica")}</Label>
                                        }
                                        {isOpenModal ?
                                            <div id="projectCoordinatesMap" style={{ borderRadius: "25px", overflow: "hidden" }}>
                                                <BoundsOnChangeMap
                                                    id="projectCoordinatesMap"
                                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleConstants.API_KEY + "&libraries=places&language=it&region=IT"}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `260px` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    handleMapMounted={this.handleMapMounted}
                                                    defaultCenter={mapGeometry}
                                                    center={mapGeometry}
                                                    defaultZoom={zoom}
                                                    zoom={zoom}
                                                    onDragEnd={this.handleMapBoundsChanged}
                                                    onZoomChanged={this.handleMapBoundsChanged}
                                                    markers={[
                                                        {
                                                            label: address,
                                                            position: mapGeometry
                                                        }
                                                    ]}
                                                    options={{
                                                        scrollwheel: false,
                                                        fullScreenControl: true,
                                                        // mapTypeControl: mode === NavModes.PLANT ? true : false,
                                                        // streetViewControl: mode === NavModes.PLANT ? true : false,
                                                        // scaleControl: mode === NavModes.PLANT ? true : false,
                                                        clickableIcons: false,
                                                        disableDoubleClickZoom: false,
                                                        // draggableCursor
                                                        streetViewControl: false,
                                                        disableDefaultUI: true,
                                                        //ho utilizzato https://snazzymaps.com/editor per stilizzare la mappa
                                                        styles: [
                                                            {
                                                                "featureType": "administrative",
                                                                "elementType": "all",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "off"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "administrative",
                                                                "elementType": "labels.text",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "on"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "landscape",
                                                                "elementType": "all",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "on"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "poi",
                                                                "elementType": "all",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "off"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "road",
                                                                "elementType": "all",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "on"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "road",
                                                                "elementType": "labels",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "simplified"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "road",
                                                                "elementType": "labels.text",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "on"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "road",
                                                                "elementType": "labels.icon",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "off"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "featureType": "transit",
                                                                "elementType": "all",
                                                                "stylers": [
                                                                    {
                                                                        "visibility": "off"
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }}
                                                />
                                            </div>
                                        : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Latitude")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={lat ? lat : ""} name='lat' onChange={this.handleChangeCoordy} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Longitude")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={lng ? lng : ""} name='lng' onChange={this.handleChangeCoordy} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="content umpi-modal-content-sep">
                            <Row>
                                <Col md={12} xs={12}>
                                    <Documents
                                        canEdit={canEdit}
                                        acceptFile={["pdf", "image"]}
                                        documents={mediaStorage}
                                        onUpdate={this.handleUpdateDocuments}
                                        onDelete={null}
                                        onPreview={null}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="content umpi-modal-content-sep">
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Serial number")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="andros_sn"
                                            clearable={false}
                                            value={andros_sn}
                                            options={androsSnOptions}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["andros_sn"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Energy provider")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={energySupplier} name='energySupplier' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("POD")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={pod} name='pod' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Client code")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={clientCode} name='clientCode' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Power")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={power} name='power' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Note")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={note} name='note' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="umpi-modal-btn-cancel" onClick={() => this.closeModal(false)}>
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                            {t("Salva")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

const connectedCabinetEdit = withTranslation()(CabinetEdit);
export { connectedCabinetEdit as CabinetEdit };

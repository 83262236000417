import {
    loopbackConstants
} from '../constants';

export function loopback(state = {}, action) {
    switch (action.type) {

        // MULTI_LIST
        case loopbackConstants.MULTI_LIST_REQUEST:
            return {
                loading: true
            };

        case loopbackConstants.MULTI_LIST_SUCCESS:
            return {
                ...action.ret
            };

        // case loopbackConstants.MULTI_LIST_FAILURE:
        //   return {
        //       ...state,
        //       error: action.error
        //   };

        // LIST
        case loopbackConstants.LIST_REQUEST:
            return {
                [action.model]: {
                    loading: true,
                    where: action.where,
                    limit: action.limit
                }
            };
        case loopbackConstants.LIST_SUCCESS:
            return {

                [action.model]: {
                    instances: action.instances
                }
            };

        case loopbackConstants.LIST_FAILURE:
            return {
                [action.model]: {
                    error: action.error
                }
            };

        // GET
        case loopbackConstants.GET_REQUEST:
            return {
                [action.model]: {
                    ...state[action.model],
                    loading: true
                }
            };
        case loopbackConstants.GET_SUCCESS:
            return {
                [action.model]: {
                    ...state[action.model],
                    loading: false,
                    instance: action.instance
                }
            };
        case loopbackConstants.GET_FAILURE:
            return {
                [action.model]: {
                    error: action.error
                }
            };

        // EDIT
        case loopbackConstants.EDIT_REQUEST:
            return {
                [action.model]: {
                    // ...state,
                    editing: true
                }
            };
        case loopbackConstants.EDIT_SUCCESS:

            return {
                [action.model]: {
                    instance: action.instance,
                    editEnd: true
                }
            };

        case loopbackConstants.EDIT_FAILURE:
            return {
                [action.model]: {
                    error: action.error
                }
            };

        // ADD
        case loopbackConstants.ADD_REQUEST:
            return {
                [action.model]: {
                    // ...state,
                    editing: true
                }
            };
        case loopbackConstants.ADD_SUCCESS:

            return {
                [action.model]: {
                    instance: action.instance,
                    editEnd: true
                }
            };

        case loopbackConstants.ADD_FAILURE:
            return {
                [action.model]: {
                    error: action.error
                }
            };

        // MULTI EDIT
        case loopbackConstants.MULTI_EDIT_REQUEST:
            return {
                ...state,
                editing: true
            };

        case loopbackConstants.MULTI_EDIT_SUCCESS:
            return {
                multiEditDidEnd: true,
                ...action.ret
            };

        // DELETE
        case loopbackConstants.DELETE_REQUEST:
            if (state[action.model])
                state[action.model]['deleting'] = true;
            return {
                ...state
            };
        case loopbackConstants.DELETE_SUCCESS:
            // remove deleted entity from state
            return {
                ...state,
                [action.model]: {
                    instances: state[action.model] && state[action.model].instances && state[action.model].instances.filter(instance => instance.id !== action.id),
                    deleted: true,
                    deletedId: action.id
                }
            };
        case loopbackConstants.DELETE_FAILURE:
            return {
                ...state,
                [action.model]: {
                    error: action.error
                }
            };

        // COUNT
        case loopbackConstants.COUNT_REQUEST:
            return {
                [action.model]: {
                    loading: true,
                    where: action.where
                }
            }
        case loopbackConstants.COUNT_SUCCESS:
            let ret = {
                [action.model]: {
                    loading: false,
                    count: action.count,
                }
            }

            if (action.path.startsWith('plant')) {
                const parts = action.path.split('@');
                if (parts[1]) {
                    let projectId = parts[1];
                    ret[action.model][projectId] = {
                        loading: false,
                        count: action.count
                    }
                }
            }
            return ret;

        case loopbackConstants.COUNT_FAILURE:
            return {
                [action.model]: {
                    error: action.error
                }
            }

        // MULTI_COUNT
        case loopbackConstants.MULTI_COUNT_REQUEST:
            return {
                loading: true
            };

        case loopbackConstants.MULTI_COUNT_SUCCESS:
            return {
                ...action.ret
            };

        // GET PARAM
        case loopbackConstants.GET_PARAM_REQUEST:

            return {
                [action.model]: {
                    [action.param]: {
                        loading: true
                    }
                }
            };
        case loopbackConstants.GET_PARAM_SUCCESS:
            return {
                [action.model]: {
                    [action.param]: {
                        instance: action.instance
                    }
                }
            };
        case loopbackConstants.GET_PARAM_FAILURE:
            return {
                [action.model]: {
                    [action.param]: {
                        error: action.error
                    }
                }
            };

        // INVOKE_ON_INSTANCE
        case loopbackConstants.INVOKE_ON_INSTANCE_REQUEST:

            return {
                [action.model]: {
                    [action.funcname]: {
                        loading: true
                    }
                }
            };
        case loopbackConstants.INVOKE_ON_INSTANCE_SUCCESS:
            return {
                [action.model]: {
                    [action.funcname]: {
                        res: action.res,
                        id: action.id
                    }
                }
            };
        case loopbackConstants.INVOKE_ON_INSTANCE_FAILURE:
            return {
                [action.model]: {
                    [action.funcname]: {
                        error: action.error
                    }
                }
            };

        // REFRESH
        case loopbackConstants.REFRESH_REQUEST:
            return {
                "refresh": {
                    [action.model]: {
                        loading: true,
                        where: action.where,
                        limit: action.limit
                    }
                }
            };
        case loopbackConstants.REFRESH_SUCCESS:
            return {
                "refresh": {
                    [action.model]: {
                        instances: action.instances
                    }
                }
            };

        case loopbackConstants.REFRESH_FAILURE:
            return {
                "refresh": {
                    [action.model]: {
                        error: action.error
                    }
                }
            };

        default:
            return state
    }
}

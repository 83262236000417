import React from "react";
import { connect } from 'react-redux';
import {
    Card,
    Row,
    Col,
    CardBody,
    CardFooter,
    NavLink,
    UncontrolledTooltip,
    Button
} from "reactstrap";

import {
    PanelHeader
} from "components";
import { withTranslation } from 'react-i18next';
import User from '../../entities/User';
import { dashboardService, projectService } from '../../services';
import ErrorModal from "../Components/ErrorModal";
import { permissions } from '../../helpers';
import { permissionConstants } from "../../constants";
import { history } from '../../helpers';
import { loopbackActions } from "../../actions";
import { refreshHeader, refreshSearchEngine } from '../../components/Header/Header';
import { ProjectEdit } from '../Projects/ProjectEdit';

class DashboardComponent extends React.Component {

    constructor(props) {
        super(props);
        // let prjs = localStorage.getItem('projects');
        // let projects = prjs && JSON.parse(prjs);
        this.state = {
            errors: [],
            projects: null,
            lightpoints: [],
            cabinets: [],
            tot_tech_power: 0,
            tot_max_power_1d: 0,
            tot_cons_power_7d: 0,

            //----->

            isProjectEditModalOpen: false,
            selectedProjectId: 0
        }

        this.shouldSeeUsersTotal = permissions.grant(permissionConstants.SHOW_USERS);
    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchProjects();

        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            // Case-insensitive search
            this.fetchProjects(search_value ? {
                where: {
                    name: {
                        ilike: "%" + search_value + "%"
                    }
                }
            } : {});
        }, this.props.t("Search a project"));

    }

    toggleProjectEditModal = (state, projectId = 0) => {
        this.setState({
            isProjectEditModalOpen: (state === true) ? true : false,
            selectedProjectId: (state === true) ? projectId : 0
        }, () => {
            if (state === false) {
                this.fetchProjects();
            }
        });
    }

    async setSelectedProjectId(projects) {
        let projectIds = [];
        let filteredProjects = [];
        const user = User.current();
        try {
            filteredProjects = projects.filter(prj => prj.users.filter(usr => usr.id === user.id).length > 0);
            projectIds = filteredProjects.map(prj => prj.id);
        } catch (err) {
            console.error(err);
        }
        const url = dashboardService.getGlobalMetrics(projectIds);
        let response = await fetch(url);
        if (response.status === 401) {
            history.push('/pages/login-page');
        }
        if (response.ok) {
            let data = await response.json();
            const tot_tech_power = data.totalPower;
            const tot_max_power_1d = data.maxPower;
            const tot_cons_power_7d = data.deltaEnergy;
            this.setState({ tot_tech_power, tot_max_power_1d, tot_cons_power_7d })
        }
        else {
            throw response;
        }
    }

    async fetchProjects(where = null) {
        try {
            let filter = {
                include: 'users',
                ...(where ? where : {})
                // where: {
                //     members: {
                //         like: "%\"" + userId + "\"%"
                //     }
                // }
            };

            const user = User.current();
            let projects = await projectService.getAllByUser(user, filter);
            this.setSelectedProjectId(projects)

            if (permissions.grant('SHOW_ONLY_OWN_PROJECTS')) {
                projects = projects.filter((project) => {
                    for (const cur of project.users) {
                        if (cur.id === user.id) {
                            return true;
                        }
                    }
                    return false;
                });
                // const userId = user.id;
                // filter = {
                //     where: {
                //         members: {
                //             like: "%\"" + userId + "\"%"
                //         }
                //     }
                // };
            }

            // let args = [];
            // projects.map((project)=>{
            //     args.push({
            //         path: "plant",
            //         model: "alarms",
            //         projectId: project.id,
            //         where: { projectId: project.id, ackByUser: { neq: null} },
            //     });
            // });

            // // leggo il numero di allarmi per ogni progetto
            // let results = args.map(async (arg) => await loopbackService.count(arg.path, arg.model, arg.where, arg.projectId));
            // let alarmsCount = {};
            // for (let i = 0; i < results.length; i++) {
            //     const projectId = args[i].projectId;
            //     try {
            //         let count = await results[i];
            //         alarmsCount[projectId] = { count };
            //     } catch (response) {
            //         let error = null;
            //         if (typeof(response.json)==='function')
            //             error = await response.json();
            //         else
            //             error = response;
            //         //console.log(error);
            //     }
            // }

            // loop projects
            if (Array.isArray(projects)) {
                for (let i = 0; i < projects.length; i++) {
                    // prepare image storage
                    projects[i]["image"] = null;

                    try {
                        // try call
                        let image = await projectService.getImageByProjectId(projects[i].id);

                        // Check and save
                        if (image && image.base64) {
                            projects[i]["image"] = image.base64;
                        }
                    } catch(exImg) {
                        console.error(exImg);
                    }
                }
            }

            localStorage.setItem('projects', JSON.stringify(projects));

            this.setState({ projects });
            this.fetchData(projects);
        } catch (err) {
            console.error(err);
        }
    }

    componentWillReceiveProps(nextProps) {
        // Filter update by properties
        if (nextProps.loopback &&
            nextProps.loopback.cabinets &&
            nextProps.loopback.lightpoints &&
            nextProps.loopback.alarms) {
            const { cabinets, lightpoints, alarms } = nextProps.loopback;
            this.setState({ cabinets, lightpoints, alarms });
        }

        // if (this.state.projects===null && nextProps.projects.items){
        //     let projects = nextProps.projects.items;
        //     this.setState( projects );
        //     setTimeout(()=>{
        //       this.fetchData( projects );
        //     },0);

        // }

        // if (nextProps.errors && nextProps.errors.length>0){
        //   for (const item of nextProps.errors){
        //     if (item.error.error && item.error.error.statusCode===401){
        //       history.push('/pages/login-page');
        //       return;
        //     }
        //   }
        //   this.setState({ errors: nextProps.errors });
        // }
    }

    fetchData(projects) {
        const dispatch = this.props.dispatch;
        let args = [];

        if (projects) {
            projects.forEach((project) => {
                args.push({ path: 'plant', model: 'cabinets', where: null, projectId: project.id });
                args.push({ path: 'plant', model: 'lightpoints', where: null, projectId: project.id });
                args.push({ path: 'plant', model: 'alarms', where: { ackByUserId: null }, projectId: project.id });
            });
            dispatch(loopbackActions.multiCount(args));
        }
    }

    jumpTo(dest, selectedProjectId) {
        localStorage.setItem('selectedProjectId', selectedProjectId);
        refreshHeader(null);
        history.push(dest);
    }

    render() {
        const { t } = this.props;
        const { errors, projects, cabinets, lightpoints, alarms, tot_tech_power, tot_max_power_1d, tot_cons_power_7d } = this.state;
        const user = User.current();

        const roundData = (data, unit = null) => {
            let newData = (
                Math.round(data * 10) / 10
            );

            return unit ? (newData + " " + unit) : newData;
        }

        let tot_projects = Array.isArray(projects) ? projects.length : 0;
        let tot_cabinets = 0;
        let tot_lightpoints = 0;

        if (Array.isArray(projects)) {
            projects.map((item) => {
                if (cabinets &&
                    cabinets[item.id] &&
                    cabinets[item.id].count) {
                    tot_cabinets += cabinets[item.id].count;
                }

                if (lightpoints &&
                    lightpoints[item.id] &&
                    lightpoints[item.id].count) {
                    tot_lightpoints += lightpoints[item.id].count;
                }

                // Hackfix
                return true;
            });
        }

        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t('Dashboard')}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="">
                            <Card className="card-padding-paper">
                                <CardFooter>
                                    <Row >
                                        <Col xs={4} className="project-info" >
                                            <h5 className="color-blue-umpi mb-1">{t('Projects')}</h5>
                                            <h2 className="selected-item">{tot_projects}</h2>
                                        </Col>
                                        <Col xs={4} className="project-info" style={{}}>
                                            <h5 className="color-blue-umpi mb-1">{t('Cabine')}</h5>
                                            <h2 className="selected-item">{tot_cabinets}</h2>
                                        </Col>
                                        <Col xs={4} className="project-info" style={{}}>
                                            <h5 className="color-blue-umpi mb-1">{t('Punti Luce')}</h5>
                                            <h2 className="selected-item">{tot_lightpoints}</h2>
                                        </Col>
                                    </Row>
                                </CardFooter>
                                <hr style={{ padding: 0, margin: "12px 0" }} />
                                <CardFooter>
                                    <Row >
                                        <Col xs={4} className="project-info" >
                                            <h5 className="color-blue-umpi mb-1">{t('Nominal technical power')}</h5>
                                            <h2 className="selected-item">{roundData(
                                                isNaN(tot_tech_power) ? 0 : (tot_tech_power / 1000),
                                                "kW"
                                            )}</h2>
                                        </Col>
                                        <Col xs={4} className="project-info" style={{}}>
                                            <h5 className="color-blue-umpi mb-1">{t('Maximum power previous day')}</h5>
                                            <h2 className="selected-item">{roundData(tot_max_power_1d, "kW")}</h2>
                                        </Col>
                                        <Col xs={4} className="project-info" style={{}}>
                                            <h5 className="color-blue-umpi mb-1">{t('Energy consumed 7 days earlier')}</h5>
                                            <h2 className="selected-item">{roundData(tot_cons_power_7d, "kWh")}</h2>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <div className="umpi-dash-prj-title-container">
                                <div>
                                    <h2 className="color-blue-umpi selected-item">{t('Projects')}</h2>
                                </div>
                                {(user && user.userRoleId === 1) && <div>
                                    <Button onClick={() => this.toggleProjectEditModal(true)} color="primary" round="true" size="sm" className="custom-no-margin btn-dash-add-new-project">{t("DashNewProject")}</Button>
                                </div>}
                            </div>
                        </Col>
                    </Row>

                    {errors.length > 0 &&
                        <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }); }} />
                    }

                    <Row>
                        {projects && projects.map((project, key) => {
                            return (
                                <Col key={key} xs={12} sm={12} md={12} lg={6} xl={6} className="text-center ">
                                    <Card className="card-padding-paper">
                                        <CardBody className="project-title">
                                            <div className="prj-label" >
                                                <div className="project-title-icon">{
                                                    (project.image) ? (
                                                        <img alt="" src={project.image} />
                                                    ) : (
                                                        <i className="now-ui-icons design_app " />
                                                    )
                                                }</div>
                                                <div className="project-title-body">
                                                    <h5 className="color-blue-umpi selected-item"> {project.name} </h5>
                                                    {project.description && <p >{project.description}</p>}
                                                </div>
                                            </div>
                                            <div className="prj-actions prj-actions-rounded-btn" >
                                                <NavLink
                                                    id={"nav_plants_" + project.id}
                                                    className="nav-link"
                                                    onClick={() => {
                                                        this.jumpTo('/plants-navigator', project.id);
                                                        // this.jumpTo('/navigator', project.id);
                                                    }}
                                                >
                                                    <i className="now-ui-icons location_pin" />
                                                    <UncontrolledTooltip placement={"left"} target={"nav_plants_" + project.id} delay={0} >
                                                        {t("Navigatore Cabine")}
                                                    </UncontrolledTooltip>
                                                </NavLink>
                                            </div>
                                            <div className="prj-actions prj-actions-rounded-btn" >
                                                <NavLink
                                                    id={"nav_zones_" + project.id}
                                                    className="nav-link"
                                                    onClick={() => {
                                                        this.jumpTo('/zones-navigator', project.id);
                                                        // this.jumpTo('/navigator', project.id);
                                                    }}
                                                >
                                                    <i className="now-ui-icons location_map-big" />
                                                    <UncontrolledTooltip placement={"left"} target={"nav_zones_" + project.id} delay={0} >
                                                        {t("Navigatore Zone")}
                                                    </UncontrolledTooltip>
                                                </NavLink>
                                            </div>
                                            <div className="prj-actions prj-actions-rounded-btn" >
                                                <NavLink
                                                    id={"eve_" + project.id}
                                                    className="nav-link"
                                                    onClick={() => { this.jumpTo('/events_log', project.id); }}
                                                >
                                                    <i className="now-ui-icons files_paper" />
                                                    <UncontrolledTooltip placement={"left"} target={"eve_" + project.id} delay={0} >
                                                        {t("Eventi")}
                                                    </UncontrolledTooltip>

                                                </NavLink>
                                            </div>
                                            <div className="prj-actions prj-actions-rounded-btn" >
                                                <NavLink
                                                    id={"sta_" + project.id}
                                                    className="nav-link"
                                                    onClick={() => { this.jumpTo('/stats', project.id); }}
                                                >
                                                    <i className="now-ui-icons business_chart-bar-32" />
                                                    <UncontrolledTooltip placement={"left"} target={"sta_" + project.id} delay={0} >
                                                        {t("Statistiche")}
                                                    </UncontrolledTooltip>

                                                </NavLink>
                                            </div>
                                            {(user && user.userRoleId === 1) && <div className="prj-actions prj-actions-rounded-btn-outline" >
                                                <NavLink
                                                    id={"edit_" + project.id}
                                                    className="nav-link"
                                                    onClick={() => this.toggleProjectEditModal(true, project.id)}
                                                >
                                                    <i className="fa fa-pencil" />
                                                    <UncontrolledTooltip placement={"left"} target={"edit_" + project.id} delay={0} >
                                                        {t("Edit")}
                                                    </UncontrolledTooltip>

                                                </NavLink>
                                            </div>}

                                        </CardBody>
                                        <hr style={{ padding: 0, margin: "12px 0" }} />
                                        <CardFooter>
                                            <Row >
                                                <Col xs={4} className="project-info" >
                                                    <h5 className="color-blue-umpi mb-1">{t('Cabine')}</h5>
                                                    <h2>{(
                                                        cabinets &&
                                                        cabinets[project.id] &&
                                                        cabinets[project.id].count
                                                    ) ? cabinets[project.id].count : 0}</h2>
                                                </Col>
                                                <Col xs={4} className="project-info" >
                                                    <h5 className="color-blue-umpi mb-1">{t('Punti Luce')}</h5>
                                                    <h2>{(
                                                        lightpoints &&
                                                        lightpoints[project.id] &&
                                                        lightpoints[project.id].count
                                                    ) ? lightpoints[project.id].count : 0}</h2>
                                                </Col>
                                                <Col xs={4} className="project-info" >
                                                    <h5 className="color-blue-umpi mb-1">{t('Notifications')}</h5>
                                                    <h2>{(
                                                        alarms &&
                                                        alarms[project.id] &&
                                                        alarms[project.id].count
                                                    ) ? alarms[project.id].count : 0}</h2>
                                                </Col>

                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>

                </div>
                {(user && user.userRoleId === 1) && <ProjectEdit
                    projectId={this.state.selectedProjectId}
                    isOpenModal={this.state.isProjectEditModalOpen}
                    onModalClose={() => this.toggleProjectEditModal(false)}
                />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, loopback, projects } = state;
    return {
        loggedinUser: authentication.user,
        loopback,
        projects
    };
}

const Dashboard = withTranslation()(connect(mapStateToProps)(DashboardComponent));
export default Dashboard;

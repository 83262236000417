import React from "react";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import Navigator from "views/Navigator/Navigator.jsx";
import TimerProfiles from "views/TimerProfiles/TimerProfiles.jsx";
import { Projects, ProjectEdit } from "views/Projects";
import { Users, UserEdit, UserProfile } from "views/Users";
import { Luminares } from "views/Luminares";
import { Lamps } from "views/Lamps";
import { Supports } from "views/Supports";
import { Drivers } from "views/Drivers";
import { Info } from "views/Info";
import EventsLog from "views/QuickSight/EventsLog.jsx";
import Stats from "views/QuickSight/Stats.jsx";
import { NavModes } from '../constants';
import { Devices } from "../views/Devices";

class PlantsNavigator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Navigator renderMode={NavModes.PLANT} history={this.props.history} />
        );
    }
}

class ZonesNavigator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Navigator renderMode={NavModes.ZONES} history={this.props.history} />
        );
    }
}

var dashRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        icon: "design_app",
        component: Dashboard
    },

    {
        collapse: true,
        path: "/settings",
        name: "Amministrazione",
        state: "openSettings",
        icon: "ui-1_settings-gear-63",
        permission: 'SHOW_SETTINGS',

        views: [
            // N.B.: le route più specifiche tipo questa devono venire prima!!!
            {
                invisible: true,
                path: "/projects/edit/:id/tab/:active_tab",
                name: "Modifica progetto",
                module: "Progetti",
                component: ProjectEdit
            },
            /*{
                invisible: true,
                path: "/projects/edit/:id",
                name: "Modifica progetto",
                module: "Progetti",
                component: ProjectEdit
            },*/
            {
                path: "/projects",
                name: "Progetti",
                mini: "P",
                component: Projects
            },
            // N.B.: le route più specifiche tipo questa devono venire prima!!!
            {
                invisible: true,
                path: "/users/edit/:id/project/:id_project",
                name: "Modifica profilo utente",
                module: "Utenti",
                component: UserEdit
            },

            {
                invisible: true,
                path: "/users/profile/:id",
                name: "Profilo utente",
                module: "Utenti",
                component: UserProfile
            },

            /*{
                invisible: true,
                path: "/users/edit/:id",
                name: "Modifica profilo utente",
                module: "Utenti",
                component: UserEdit
            },*/
            {
                path: "/users",
                name: "Utenti",
                mini: "U",
                component: Users
            }
        ]
    },

    {
        collapse: true,
        path: "/archives",
        name: "Archivio componenti",
        state: "openArchives",
        icon: "files_box",
        permission: 'SHOW_ARCHIVES',
        views: [

            /*{
                invisible: true,
                path: "/luminares/edit/:id",
                name: "Modifica corpo illuminante",
                module: "Luminares",
                component: LuminareEdit
            },*/
            {
                path: "/luminares",
                name: "Corpi illuminanti",
                mini: "Lu",
                component: Luminares
            },

            /*{
                invisible: true,
                path: "/lamps/edit/:id",
                name: "Modifica lampada",
                module: "Lamps",
                component: LampEdit
            },*/
            {
                path: "/lamps",
                name: "Lampade",
                mini: "La",
                component: Lamps
            },

            /*{
                invisible: true,
                path: "/supports/edit/:id",
                name: "Modifica supporto",
                module: "Supports",
                component: SupportEdit
            },*/
            {
                path: "/supports",
                name: "Supporti",
                mini: "Su",
                component: Supports
            },

            /*{
                invisible: true,
                path: "/drivers/edit/:id",
                name: "Modifica driver",
                module: "Drivers",
                component: DriverEdit
            },*/
            {
                path: "/drivers",
                name: "Driver",
                mini: "Dr",
                component: Drivers
            },
            {
                path: "/devices",
                name: "Devices",
                mini: "Dv",
                component: Devices
            },

        ]
    },

    {
        path: "/plants-navigator",
        name: "Navigatore Cabine",
        icon: "location_pin",
        component: PlantsNavigator
    },

    {
        path: "/timerprofiles",
        name: "Profili timer",
        icon: "ui-2_time-alarm",
        component: TimerProfiles
    },

    {
        path: "/zones-navigator",
        name: "Navigatore Zone",
        icon: "location_map-big",
        component: ZonesNavigator
    },

    {
        path: "/events_log",
        name: "Eventi",
        icon: "files_paper",
        component: EventsLog
    },

    {
        path: "/stats",
        name: "Statistiche",
        icon: "business_chart-bar-32",
        component: Stats
    },

    {
        path: "/info",
        name: "Info",
        icon: "travel_info",
        component: Info
    },

    {
        redirect: true,
        path: "/",
        pathTo: "/dashboard",
        name: "Dashboard"
    }

];
export default dashRoutes;

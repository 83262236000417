/**
 *
 * @param {*} path
 * @param {*} projectId opzionale
 */
export function accessToken(path, projectId = null) {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('token'));

    let ret = "";

    if (token && token.id) {
        ret = "?access_token=" + token.id;
    } else {
        return "";
    }

    if (projectId) {
        ret += "&projectId=" + projectId;
    }
    else {
        if (path && (path === 'plant' || path.includes('plant'))) {
            let selectedProjectId = localStorage.getItem('selectedProjectId');
            if (!selectedProjectId) {
                let projects = JSON.parse(localStorage.getItem('projects'));
                if (projects.length > 0) {
                    selectedProjectId = projects[0].id;
                    localStorage.setItem('selectedProjectId', selectedProjectId);
                }
            }
            ret += "&projectId=" + selectedProjectId;
        }
    }

    return ret;

}


import React from "react";
export default function Circle(props) {
    return (
        <svg height={props.height} width={props.width} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xmlSpace="preserve">
            <title>{props.title}</title>
            <g fill={props.fill} stroke={props.stroke} strokeWidth={props.strokewidth} >
                <circle cx="12" cy="12" r="10" />
            </g>
        </svg>
    )
}

import React from "react";
export default function Clock(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 33 33"
            height={props.height}
            width={props.width}
            y="0px"
            x="0px"
        >
            <title>{props.title}</title>

            <g
                stroke={props.stroke ? props.stroke : "black"}
                strokeWidth={props.strokeWidth ? props.strokeWidth : 1}
            >
                <path

                    fill={props.fill ? props.fill : "black"}
                    d="M16.5,0C7.402,0,0,7.402,0,16.5C0,25.598,7.402,33,16.5,33C25.598,33,33,25.598,33,16.5C33,7.402,25.598,0,16.5,0z
		 M16.5,29C9.607,29,4,23.393,4,16.5C4,9.607,9.607,4,16.5,4C23.393,4,29,9.607,29,16.5C29,23.393,23.393,29,16.5,29z M18.969,7.969
		v9.938c0,1.104-0.896,2-2,2h-6.938c-1.104,0-2-0.896-2-2c0-1.104,0.896-2,2-2h4.938V7.969c0-1.104,0.896-2,2-2
		S18.969,6.863,18.969,7.969z"/>
            </g>

        </svg>
    )
}

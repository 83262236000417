import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import { Button } from "components";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Datetime from "react-datetime";
import Commands from "./Commands";
import { Line } from "react-chartjs-2";
import suncalc from 'suncalc';
import "../../plugins/chartjs-plugin-annotations";

class CommandsTimerWeekly extends Commands {

    constructor(props) {
        super(props);

        const sun = this.getSun(props);
        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            sun,
            showingDay: "MON",
            sorted: []
        }

    }

    /*
     *    Restituisce i valori minimi e massimi di alba e tramonto nell'anno corrente.
    **/
    getSun(props) {

        var year = moment().year();
        var newDate = moment(year + '-01-01', "YYYY-MM-DD");

        let sun = {
            minRise: "06:00",
            maxRise: "06:00",
            minSet: "18:00",
            maxSet: "18:00"
        }

        for (let i = 0; i < 365; i++) {
            var times = suncalc.getTimes(newDate.toDate(), props.cabinet.geometry.lat, props.cabinet.geometry.lng);
            let sunrise = moment(times.sunrise).format('HH:mm');
            let sunset = moment(times.sunset).format('HH:mm');
            if (sunrise > sun.maxRise)
                sun.maxRise = sunrise;
            if (sunrise < sun.minRise)
                sun.minRise = sunrise;
            if (sunset > sun.maxSet)
                sun.maxSet = sunset;
            if (sunset < sun.minSet)
                sun.minSet = sunset;
            newDate.add(1, 'days');
        }
        return sun;
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);
    }

    render() {

        return (
            <div
            // style = { { flex: 1 }}
            >
                {this.renderChart()}
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {

        const { loading, conf, t, dispatch } = this.props;
        const { editingIndex, editingItem, validationError } = this.state;

        let dataWee = this.getCommandsByType("EVE_TYPE_WEEK");
        let optionsOuts = this.getOptionsOuts();
        let optionsDay = this.getOptionsDay();

        return (
            <Card>

                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >

                        <span style={{ flex: 1 }}>{t("Timer settimanali")}</span>
                        <Button
                            disabled={true}
                            color="primary"
                            onClick={

                                () => {
                                    if (this.state.isNew) {
                                        return;
                                    }
                                    let item = {
                                        code: "",
                                        day: 0,
                                        time: "",
                                        type: 'EVE_TYPE_WEEK',
                                        output: "",
                                        value: 0
                                    };
                                    if (!conf.configParam.commands) {
                                        conf.configParam.commands = [];
                                    }
                                    conf.configParam.commands.unshift(item);
                                    this.setState({ isNew: true, isNewTarget: conf.configParam.commands, conf, editingIndex: 0, editingItem: item, sorted: [] });
                                }

                            }
                            style={{ fontSize: 14, fontWeight: 400, margin: 0, padding: '5px' }}
                        >
                            {t("Nuovo")}
                        </Button>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        data={dataWee}
                        className="-striped -highlight configurations"
                        pageSize={dataWee && dataWee.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        sorted={this.state.sorted}
                        onSortedChange={(newSorted) => { this.setState({ sorted: newSorted }) }}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[
                            {
                                Header: t("Nome"),
                                accessor: "code",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.code}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.code = value;
                                                    this.setState({ editingItem })
                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },
                            {
                                Header: t("Giorno"),
                                accessor: "day",
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsDay, editingItem.day);

                                        return (

                                            <Select

                                                // // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Giorno")}
                                                name="group"
                                                value={selectedOption}
                                                options={optionsDay}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.day = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />

                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsDay, row.value);
                                        return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    }
                                }
                            },
                            {
                                Header: t("Ora") + " (hh:mm)",
                                accessor: "time",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        return (

                                            <React.Fragment>
                                                <Datetime

                                                    dateFormat={false}
                                                    timeFormat={"HH:mm"}
                                                    value={editingItem.time}
                                                    onChange={(time) => {
                                                        if (time && time instanceof moment) {
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'time', error: t("Formato orario non valido") } });
                                                                return;
                                                            }
                                                            editingItem.time = time.format("HH:mm");
                                                            this.setState({ editingItem, validationError: null })

                                                        }
                                                    }}
                                                    onBlur={(time) => {
                                                        if (!(time instanceof moment)) {
                                                            time = moment(time, "HH:mm");
                                                            if (!time.isValid()) {
                                                                this.setState({ validationError: { field: 'time', error: t("Formato orario non valido") } })
                                                                return;
                                                            }
                                                        }
                                                        editingItem.time = time.format("HH:mm");
                                                        this.setState({ editingItem, validationError: null })
                                                    }}

                                                />

                                                {validationError && validationError.field === 'time' &&
                                                    <span className="validationError">{validationError.error}</span>
                                                }
                                            </React.Fragment>

                                        );
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                }

                            },
                            {
                                Header: t("Comando"),
                                accessor: "output",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsOuts, editingItem.output);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Comando")}
                                                name="group"
                                                value={selectedOption && selectedOption.value}
                                                options={optionsOuts}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.output = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    }
                                    else {
                                        const selectedOption = this.getSelected(optionsOuts, row.value);
                                        const label = (selectedOption ? selectedOption.label : "");

                                        return (<div className="no-overflow" >{label}</div>);
                                    }
                                }

                            },

                            {
                                Header: t("Valore"),
                                accessor: "value",
                                sortable: true,
                                filterable: false,
                                Cell: (row) => {
                                    return this.renderValue('value', row, editingIndex, editingItem, optionsOuts);

                                    // let isEditing = (row.index === editingIndex);
                                    // if (isEditing) {
                                    //   return (
                                    //     <Input
                                    //       type="number"
                                    //       value={editingItem.value}
                                    //       onChange={(e) => {
                                    //         const { name, value } = e.target;
                                    //         editingItem.value = value;
                                    //         this.setState({ editingItem })
                                    //       }}
                                    //     />);
                                    // } else {
                                    //   return (<div className="no-overflow" align="">{row.value}</div>)
                                    // }
                                }

                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com2_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"com2_del_" + row.index}
                                                    onClick={() => {

                                                        this.confirmDelete(
                                                            t("Elimina timer settimanale"),
                                                            () => {

                                                                let commands = conf.configParam.commands.filter(
                                                                    ((curr, index) => {
                                                                        return (index !== row.original.index)
                                                                    })
                                                                );
                                                                conf.configParam.commands = commands;
                                                                dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                this.setState({
                                                                    alert: null
                                                                });

                                                            }

                                                        );

                                                    }}
                                                    color="danger"
                                                    size="sm"
                                                    tooltipText={t("Elimina")}
                                                >
                                                    <i className="fa fa-trash" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"com2_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        conf.configParam.commands[row.original.index] = editingItem;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"com2_del_" + row.index}
                                                    onClick={() => {

                                                        if (this.state.isNew) {
                                                            conf.configParam.commands.splice(row.original.index, 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    //defaultPageSize={10}
                    />
                </CardBody>
            </Card>

        )

    }

    getChartOptions() {

        const { t } = this.props;
        const { sun } = this.state;

        let gridOptions = {

            maintainAspectRatio: false,
            responsive: true,

            scales: {

                xAxes: [
                    {
                        type: "time",
                        time: {
                            parser: 'HH:mm',
                            tooltipFormat: 'HH:mm',
                            labelFormat: 'HH:mm',
                            unit: 'hour',
                            // unitStepSize: 4,
                            displayFormats: {
                                'hour': 'HH:mm',
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: t('Orario')
                        },
                        gridLines: {
                            // zeroLineColor: "transparent",
                            // drawTicks: false,
                            // display: false,
                            // drawBorder: false
                        }
                    }
                ],

                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: t('Potenza %')
                        },
                        ticks: {
                            min: 0,
                            max: 100
                        },
                        gridLines: {
                            zeroLineColor: "transparent",
                            drawBorder: false
                        }

                    }
                ],
            },
            layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 }
            }
        };

        if (sun) {
            gridOptions.annotation = {
                drawTime: 'beforeDatasetsDraw',
                events: ['click'],
                dblClickSpeed: 350, // ms (default)

                annotations: [

                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_time_1',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        xMin: "00:00",
                        xMax: sun.minRise,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        backgroundColor: 'lightblue',
                        globalAlpha: 0.5
                    },

                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_day_transition',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        xMin: sun.minRise,
                        xMax: sun.maxRise,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        gradient: true,
                        globalAlpha: 0.5,
                        gradientColorStops: [
                            {
                                stop: 0,
                                color: "lightblue"
                            },
                            {
                                stop: 1,
                                color: "white"
                            },

                        ]

                    },

                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'day_night_transition',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        xMin: sun.minSet,
                        xMax: sun.maxSet,
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        globalAlpha: 0.5,
                        gradientColorStops: [
                            {
                                stop: 0,
                                color: "white"
                            },
                            {
                                stop: 1,
                                color: "lightblue"
                            },

                        ]

                    },

                    {
                        type: 'box',
                        drawTime: 'beforeDatasetsDraw',
                        id: 'night_time_2',
                        xScaleID: 'x-axis-0',
                        yScaleID: 'y-axis-0',
                        xMin: sun.maxSet,
                        xMax: "24:00",
                        yMax: 100,
                        yMin: 0,
                        borderWidth: 0,
                        globalAlpha: 0.5,
                        backgroundColor: 'lightblue'
                    }

                ]
            }
        }
        return gridOptions;

    }

    getDataset(label, color, timers, type) {

        // punti del grafico
        // ordino i timer per ora
        timers.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

        let data = [];

        if (timers.length === 0) {
            data.push({
                x: "00:00",
                y: 100
            });
            data.push({
                x: "24:00",
                y: 100
            });
        }
        else {

            let first = timers[0];
            if (first.time !== "00:00") {
                data.push({
                    x: "00:00",
                    y: 100
                });
            }

            timers.map((timer) => {
                data.push({
                    x: timer.time,
                    y: (type === 'DIG-OUT' && timer.value === 1 ? 100 : timer.value)
                });
                return false;
            });

            let last = timers[timers.length - 1];
            if (last !== "24:00") {
                data.push({
                    x: "24:00",
                    y: (type === 'DIG-OUT' && last.value === 1 ? 100 : last.value)
                });
            }

        }
        let dataset = {
            label,
            borderColor: color,
            // pointBorderColor: "#FFF",
            pointBackgroundColor: color,
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: color,
            borderWidth: 2,
            steppedLine: true,
            data

        };

        return dataset;

    }

    getChartData() {

        const { showingDay } = this.state;

        let outputs = {};
        let timerSettimanali = this.getCommandsByType("EVE_TYPE_WEEK");
        timerSettimanali.map((timer) => {
            if (
                timer.day && (
                    (timer.day === showingDay) ||
                    (timer.day === "ALL") ||
                    (timer.day === "FERIAL" && ["MON", "TUE", "WED", "THU", "FRI", "SAT"].includes(showingDay)) ||
                    (timer.day === "FEST" && ["SUN"].includes(showingDay))
                )
            ) {
                if (!outputs[timer.output]) {
                    outputs[timer.output] = [];
                }

                if (timer.value !== null && timer.time) {
                    outputs[timer.output].push(timer);
                }
            }
            return false;
        });

        let datasets = [];

        let keys = Object.keys(outputs);

        let series = [];
        // risolvo le label delle uscite
        let optionsOut = this.getOptionsOuts();
        for (let option of optionsOut) {
            for (let key of keys) {
                if (option.value === key) {
                    series.push(option)
                    break;
                }
            }
        }

        series.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

        for (let serie of series) {
            datasets.push(this.getDataset(serie.label, serie.color, outputs[serie.value], serie.type));
        }

        let chartData = {
            labels: [
                '00:00',
                '12:00',
                '24:00',
            ],
            datasets
        };

        return chartData;

    }

    renderChart() {

        const { t } = this.props;
        let { showingDay } = this.state;

        const data = this.getChartData();
        const options = this.getChartOptions();
        const optionsWeekDay = this.getOptionsDay();

        return (
            <div style={{ height: 350, display: "flex", flexDirection: "column" }}>

                <div style={{ width: "200px", flex: 0, alignSelf: "flex-end" }} >
                    <Select

                        placeholder={t("Giorno")}
                        name="group"
                        value={showingDay}
                        options={optionsWeekDay}
                        onChange={option => {
                            if (option) {
                                this.setState({ showingDay: option.value })
                            }
                        }}
                    />
                </div>
                <Line
                    style={{ flex: 1 }}
                    data={data}
                    options={options}
                />
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default CommandsTimerWeekly = withTranslation()(connect(mapStateToProps)(CommandsTimerWeekly));

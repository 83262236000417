/**
 * Struttura d'appoggio per gestire le modifiche dinamiche all'albero
 */
class Tree {

    constructor(root) {
        if (root) {
            this.root = { children: root };
        }
        else {
            this.root = null;
        }
    }

    getRoot() {
        if (this.root)
            return this.root.children;
        else
            return null;
    }

    traverse(callback) {
        function goThrough(node, n_index = null) {
            if (!callback(node, n_index))
                return;
            if (node.children) {
                node.children.map((child, index) => {
                    goThrough(child, index);
                    return false;
                });
            }
        }
        goThrough(this.root);
    }

    traversePath(pathidx, resolve) {
        function goThrough(node, path, parts) {
            if (!(node &&
                Array.isArray(parts) &&
                parts.length >= 1)) {
                return resolve(node);
            }
            let curPart = parts.shift();
            let curPidx = path[curPart];
            let curTree = (
                curPidx &&
                Array.isArray(node.children) &&
                node.children[curPidx.index]
            ) ? node.children[curPidx.index] : null;
            goThrough(curTree, path, parts);
        }
        goThrough(this.root, pathidx, Object.keys(pathidx));
    }

    reversePath(data) {
        let returnPath = {};
        let found = false;
        function goThrough(node, path = {}) {
            if (node.data && node.data.item === data.item) {
                returnPath = path;
                found = true;
            }
            else if (node.children) {
                node.children.map((child, n_index) => {
                    let npath = {
                        ...path,
                        [Object.keys(path).length]: {
                            index: n_index,
                            payload: null
                        }
                    };
                    goThrough(child, npath);
                    return false;
                });
            }
        }
        goThrough(this.root);
        return found ? returnPath : {};
    }

    addNode(data, parentdata) {
        const newNode = {
            data,
            children: []
        };

        if (this.root === null) {
            this.root = newNode;
            return;
        }

        if (parentdata === null || parentdata === undefined) {
            if (!this.root.children) {
                this.root.children = [];
            }
            this.root.children.push(newNode);
            return;
        }

        this.traverse((node) => {
            if (node.data && parentdata && node.data.item === parentdata.item) {
                if (!node.children)
                    node.children = [];
                node.children.push(newNode);
                return false;
            }
            return true;
        });
    }

    removeNode(data) {
        this.traverse((node) => {
            if (node.children) {
                for (let index = 0; index < node.children.length; index++) {
                    let childNode = node.children[index];
                    if (childNode.data && childNode.data.item === data.item) {
                        node.children.splice(index, 1);
                        return false;
                    }
                }
            }
            return true;
        });
    }

    search(data) {
        let returnNode = null;
        this.traverse((node) => {
            if (node.data && node.data.item === data.item) {
                returnNode = node;
                return false;
            }
            return true;
        });
        return returnNode;
    }

    displayLeafs(parentdata) {
        const parentNode = (parentdata && parentdata.item) ? this.search(parentdata) : parentdata;
        let leafsRet = [];
        if (parentdata.children && !parentdata.children.length) {
            return parentdata;
        }

        parentNode.children.forEach((child) => {
            leafsRet.push(this.displayLeafs(child));
        });

        return leafsRet.flat();
    }

    hasChildren(data) {
        let node = this.search(data);
        return (node && node.children && node.children.length > 0);
    }

    getParent(data) {
        let parentNode = null;
        this.traverse((node) => {
            if (node.children) {
                for (const child of node.children) {
                    if (child.data && child.data.item === data.item) {
                        parentNode = node;
                        return false;
                    }
                }
            }
            return true;
        });
        return parentNode;
    }

}

export { Tree };

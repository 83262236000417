/*global google*/
import React from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Rectangle,
    Polygon
} from "react-google-maps";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Row,
    Col,
    Label,
} from "reactstrap";
import Select from "react-select";
import { ButtonWithTooltip, CustomMapControl } from "../../components";
import { ChromePicker } from 'react-color';

import { DRAW_MODE, SHOW_MODE } from "./MapConstants";

const StartingPointsAndGroupsMap = //withScriptjs(
    withGoogleMap(props => {

        let mapMarkers = props.mapMarkers || [];
        const { map, t, showMode } = props;
        //let isVisibleStreetView = false;
        // let center = props.center;
        if (map) {
            //let streetView = map.getStreetView();
            //isVisibleStreetView = streetView.getVisible();
            for (const marker of mapMarkers) {
                /*if (marker.isOpen) {
                    center = marker.center;
                    //break;
                }*/
                marker.position = marker.item.geometry;
            }
        }

        return (
            <GoogleMap
                // mapTypeId={"street"}
                center={props.center}
                zoom={props.zoom}
                defaultOptions={{
                    scrollwheel: true,
                    disableDefaultUI: false,
                    fullScreenControl: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    scaleControl: false,
                    // clickableIcons: props.drawMode === DRAW_MODE.NONE
                    clickableIcons: false,
                    disableDoubleClickZoom: true,
                    draggableCursor: (props.drawMode === DRAW_MODE.NONE ? "default" : "crosshair")
                }}

                // onDragEnd = { props.onDragEnd }
                // onZoomChanged = { props.onZoomChanged }
                ref={props.handleMapMounted}
                onClick={props.onMapClick}
                onDblClick={props.onMapDblClick}
                onMouseMove={props.onMouseMove}
            >

                {props.rectBounds &&
                    <Rectangle
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                        }}
                        bounds={props.rectBounds}
                        onMouseMove={props.onMouseMove}
                        onClick={props.onMapClick}
                        onDblClick={props.onMapDblClick}
                    />

                }

                {props.polygonPaths &&
                    <Polygon
                        paths={props.polygonPaths}
                        options={{
                            fillColor: "FF0000",
                            fillOpacity: 0.35,
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            geodesic: false,
                            zIndex: 1
                        }}
                        onMouseMove={props.onMouseMove}
                        onClick={props.onMapClick}
                        onDblClick={props.onMapDblClick}
                    />
                }

                {mapMarkers.map((marker, index) => {
                    return (
                        <Marker
                            {...marker}
                            key={"map_" + index}
                            zIndex={marker.isOpen ? google.maps.Marker.MAX_ZINDEX : index}
                            draggable={marker.isEditing}
                            onClick={props.onMapClick}
                            onDblClick={props.onMapDblClick}
                        >
                        </Marker>)
                })
                }

                {props.showEditForm &&
                    <CustomMapControl position={google.maps.ControlPosition.TOP_CENTER}>

                        <Card className="form-mappa">
                            <CardHeader className="config-card-header">
                                <CardTitle className="config-card-title" >
                                    <span style={{ flex: 1 }}>{t("Nuovo assegnamento")}</span>
                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <Form className="form-horizontal">

                                    {props.showMode === SHOW_MODE.STARTING_POINTS &&
                                        <Row>
                                            <Label sm={2}>{t("Partenza")}</Label>
                                            <Col xs={12} sm={10}>
                                                <FormGroup>
                                                    <Select
                                                        name="field_pl"
                                                        value={props.selectedStartingPoint}
                                                        options={props.optionsStartingPoints}
                                                        onChange={(option) => { props.onChangeSelect(SHOW_MODE.STARTING_POINTS, option); }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                    {props.showMode === SHOW_MODE.GROUPS &&
                                        <Row>
                                            <Label sm={2}>{t("Gruppo")}</Label>
                                            <Col xs={12} sm={10}>
                                                <FormGroup className="">
                                                    <Select
                                                        name="field_group"
                                                        value={props.selectedGroup}
                                                        options={props.optionsGroups}
                                                        onChange={(option) => { props.onChangeSelect(SHOW_MODE.GROUPS, option); }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                </Form>

                                <div align="right">
                                    <ButtonWithTooltip
                                        id={"form_gen_save"}
                                        color="success"
                                        size="sm"
                                        tooltipText={t("Salva")}
                                        onClick={props.onSaveClick}
                                    >
                                        <i className="fa fa-check" />
                                    </ButtonWithTooltip>

                                    &nbsp;

                                    <ButtonWithTooltip
                                        id={"form_gen_cancel"}
                                        color="secondary"
                                        size="sm"
                                        tooltipText={t("Annulla")}
                                        onClick={props.onCancelClick}
                                    >
                                        <i className="fa fa-times" />
                                    </ButtonWithTooltip>
                                </div>

                            </CardBody>
                        </Card>

                    </CustomMapControl>
                }

                {!props.showEditForm &&
                    <CustomMapControl
                        position={google.maps.ControlPosition.TOP_LEFT}
                    >
                        <div className="wrapper-comandi-mappa">
                            <div
                                className={"bottone-mappa"}
                                onClick={() => { props.onClickDrawMode(DRAW_MODE.NONE); }}
                            >

                                <i
                                    style={{ color: (props.drawMode === DRAW_MODE.NONE ? "black" : "lightgray") }}
                                    className="fa fa-hand-paper-o" aria-hidden="true">
                                </i>

                            </div>
                            {/*<div
                className={"bottone-mappa"}
                onClick={() => { props.onClickDrawMode(DRAW_MODE.POLYGON); }}
              >
                <PolygonSvg width={"18"} height={"18"} fill={(props.drawMode === DRAW_MODE.POLYGON ? "gray" : "lightgray")} stroke={(props.drawMode === DRAW_MODE.POLYGON ? "black" : "gray")} strokeWidth={1} />
              </div>
              <div
                className={"bottone-mappa"}
                onClick={() => { props.onClickDrawMode(DRAW_MODE.RECTANGLE); }}
              >
                <RectangleSvg width={"16"} height={"16"} fill={(props.drawMode === DRAW_MODE.RECTANGLE ? "gray" : "lightgray")} stroke={(props.drawMode === 2 ? "black" : "gray")} strokeWidth={1} />
              </div>*/}

                        </div>
                    </CustomMapControl>
                }

                {!props.showEditForm &&
                    <CustomMapControl position={google.maps.ControlPosition.TOP_RIGHT}>

                        <div className="wrapper-comandi-mappa">
                            <div
                                className={"bottone-mappa " + (showMode === SHOW_MODE.STARTING_POINTS ? "active" : "")}
                                onClick={() => {
                                    props.onClickShowMode(SHOW_MODE.STARTING_POINTS);
                                }}
                            >
                                {t("Partenze")}
                            </div>

                            <div
                                className={"bottone-mappa " + (showMode === SHOW_MODE.GROUPS ? "active" : "")}
                                onClick={() => {
                                    props.onClickShowMode(SHOW_MODE.GROUPS);
                                }}
                            >
                                {t("Gruppi")}
                            </div>

                        </div>
                    </CustomMapControl>
                }

                <CustomMapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>

                    <div className="legenda-mappa" >

                        {showMode === SHOW_MODE.GROUPS &&
                            props.gruppi.map((gruppo, key) => {
                                return (
                                    <div key={key} className="legenda-riga">
                                        <div className="legenda-label" >
                                            {(gruppo === 0 ? t("Non installato") : t("Gruppo") + " " + gruppo)}
                                        </div>&nbsp;&nbsp;
                                        <div className="legenda-square"
                                            onClick={
                                                () => {
                                                    props.onColorClick(gruppo)
                                                }}
                                            style={{
                                                backgroundColor: props.groupColorPalette[gruppo]
                                            }}
                                        ></div>
                                    </div>
                                )
                            })
                        }
                        {showMode === SHOW_MODE.STARTING_POINTS &&
                            props.partenze.map((partenza, key) => {
                                return (<div key={key} className="legenda-riga">

                                    <div className="legenda-label" >
                                        {t("Partenza") + " " + partenza}
                                    </div>&nbsp;&nbsp;
                                    <div className="legenda-square"
                                        onClick={
                                            () => {
                                                props.onColorClick(partenza)
                                            }}
                                        style={{
                                            backgroundColor: props.plColorPalette[partenza]
                                        }}
                                    ></div>
                                </div>
                                )
                            })
                        }

                    </div>
                </CustomMapControl>

                {props.drawMode === DRAW_MODE.POLYGON && !props.showEditForm &&
                    <CustomMapControl
                        position={google.maps.ControlPosition.BOTTOM_CENTER}
                    >
                        <div className="wrapper-comandi-mappa helper-mappa">
                            <i className="fa fa-yelp" aria-hidden="true"></i>&nbsp;&nbsp;{t("Doppio click per assegnare")}
                        </div>
                    </CustomMapControl>
                }

                {props.showPalette &&
                    <CustomMapControl
                        position={google.maps.ControlPosition.BOTTOM_LEFT}
                    >

                        <div className="color-picker-mappa">
                            <ChromePicker
                                color={(props.showMode === SHOW_MODE.GROUPS ?
                                    props.groupColorPalette[props.selectedColorIndex] :
                                    props.plColorPalette[props.selectedColorIndex])
                                }
                                onChange={props.onColorChange}
                            />

                            <div align="right">
                                <ButtonWithTooltip
                                    id="palette_save"
                                    color="success"
                                    size="sm"
                                    tooltipText={t("Salva")}
                                    onClick={props.onPaletteSaveClick}
                                >
                                    <i className="fa fa-check" />
                                </ButtonWithTooltip>

                                &nbsp;

                                <ButtonWithTooltip
                                    id="palette_cancel"
                                    color="secondary"
                                    size="sm"
                                    tooltipText={t("Annulla")}
                                    onClick={props.onPaletteCancelClick}
                                >
                                    <i className="fa fa-times" />
                                </ButtonWithTooltip>
                            </div>

                        </div>

                    </CustomMapControl>
                }

            </GoogleMap>
        );
    })
//);

export {
    StartingPointsAndGroupsMap,
    DRAW_MODE,
    SHOW_MODE
}

// Extract from vanilla window
const envSettings = window;

// Create constants objects
export const loopbackConstants = Object.freeze({

    //---------------------------------------------------------|
    BASE_URL: (
        // Merge all values into BASE_URL constant
        envSettings.LOOPBACK_PROTOCOL + "://" +
        envSettings.LOOPBACK_HOSTNAME +
        (envSettings.LOOPBACK_PORT   ? ":" + envSettings.LOOPBACK_PORT : "") +
        (envSettings.LOOPBACK_PREFIX ? envSettings.LOOPBACK_PREFIX     : "")
    ),
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    MULTI_LIST_REQUEST: 'MULTI_LIST_REQUEST',
    MULTI_LIST_SUCCESS: 'MULTI_LIST_SUCCESS',
    MULTI_LIST_FAILURE: 'MULTI_LIST_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    LIST_REQUEST: 'LIST_REQUEST',
    LIST_SUCCESS: 'LIST_SUCCESS',
    LIST_FAILURE: 'LIST_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    REFRESH_REQUEST: 'REFRESH_REQUEST',
    REFRESH_SUCCESS: 'REFRESH_SUCCESS',
    REFRESH_FAILURE: 'REFRESH_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    GET_REQUEST: 'GET_REQUEST',
    GET_SUCCESS: 'GET_SUCCESS',
    GET_FAILURE: 'GET_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    EDIT_REQUEST: 'EDIT_REQUEST',
    EDIT_SUCCESS: 'EDIT_SUCCESS',
    EDIT_FAILURE: 'EDIT_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    ADD_REQUEST: 'ADD_REQUEST',
    ADD_SUCCESS: 'ADD_SUCCESS',
    ADD_FAILURE: 'ADD_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    MULTI_EDIT_REQUEST: 'MULTI_EDIT_REQUEST',
    MULTI_EDIT_SUCCESS: 'MULTI_EDIT_SUCCESS',
    MULTI_EDIT_FAILURE: 'MULTI_EDIT_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    COUNT_REQUEST: 'COUNT_REQUEST',
    COUNT_SUCCESS: 'COUNT_SUCCESS',
    COUNT_FAILURE: 'COUNT_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    MULTI_COUNT_REQUEST: 'MULTI_COUNT_REQUEST',
    MULTI_COUNT_SUCCESS: 'MULTI_COUNT_SUCCESS',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    GET_PARAM_REQUEST: 'GET_PARAM_REQUEST',
    GET_PARAM_SUCCESS: 'GET_PARAM_SUCCESS',
    GET_PARAM_FAILURE: 'GET_PARAM_FAILURE',
    //---------------------------------------------------------|

    //---------------------------------------------------------|
    INVOKE_ON_INSTANCE_REQUEST: 'INVOKE_ON_INSTANCE_REQUEST',
    INVOKE_ON_INSTANCE_SUCCESS: 'INVOKE_ON_INSTANCE_SUCCESS',
    INVOKE_ON_INSTANCE_FAILURE: 'INVOKE_ON_INSTANCE_FAILURE'
    //---------------------------------------------------------|

});

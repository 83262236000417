import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import Select from "react-select";
import { Button, Documents } from "components";
import { withTranslation } from 'react-i18next';
import { roleConstants } from '../../constants';
import User from '../../entities/User';

class LightpointEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mediaStorage: [],
            mediaPreview: null,
            item: {
                label:             '',
                luminareArchiveId: 1,
                supportArchiveId:  1,
                driverArchiveId:   1,
                lampArchiveId:     1,
                syra_sn:          '',
                geometry: {
                    lat: null,
                    lng: null
                },
                documents: {}
            },
            syrasNotLinked: props.instances && Array.isArray(props.instances.syrasNotLinked) ? props.instances.syrasNotLinked : [],
            isOpenModal:   false,
            supports:      props.instances && Array.isArray(props.instances.supports)  ? props.instances.supports  : [],
            drivers:       props.instances && Array.isArray(props.instances.drivers)   ? props.instances.drivers   : [],
            lamps:         props.instances && Array.isArray(props.instances.lamps)     ? props.instances.lamps     : [],
            luminares:     props.instances && Array.isArray(props.instances.luminares) ? props.instances.luminares : []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.hiddenFileInput = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal) {
            this.setState({
                isOpenModal:   nextProps.isOpenModal,
                supports:      nextProps.instances && Array.isArray(nextProps.instances.supports)  ? nextProps.instances.supports  : [],
                drivers:       nextProps.instances && Array.isArray(nextProps.instances.drivers)   ? nextProps.instances.drivers   : [],
                lamps:         nextProps.instances && Array.isArray(nextProps.instances.lamps)     ? nextProps.instances.lamps     : [],
                luminares:     nextProps.instances && Array.isArray(nextProps.instances.luminares) ? nextProps.instances.luminares : [],
                syrasNotLinked: nextProps.instances && Array.isArray(nextProps.instances.syrasNotLinked) ? nextProps.instances.syrasNotLinked: []
            });
        }
    }

    handleUpdateDocuments(p_documents) {
        let item = this.state.item;
        item.documents = p_documents;
        this.setState({ item });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let item = this.state.item;
        if (["lat", "lng"].includes(name)) {
            item["geometry"][name] = value.trim();
        } else {
            item[name] = value;
        }
        this.setState({ item });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.closeModal(true);
    }

    closeModal = (logic) => {
        // Backup
        const { item } = this.state;
        // reset all
        this.setState({
            mediaStorage: [],
            mediaPreview: null,
            item: {
                label:             '',
                luminareArchiveId: 1,
                supportArchiveId:  1,
                driverArchiveId:   1,
                lampArchiveId:     1,
                syra_sn:          '',
                geometry: {
                    lat: null,
                    lng: null
                },
                documents: {}
            },
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose(
                logic && item ? item : null
            );
        });
    }

    checkPreSubmit() {
        const { item } = this.state;

        return (!(
            item &&
            item.label
        )) ? true : false;
    }

    render() {
        const { t } = this.props;
        const { item, syrasNotLinked, supports, drivers, lamps, luminares, mediaStorage } = this.state;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);

        let syraSnOptions = [];

        if (syrasNotLinked) {
            syraSnOptions = syraSnOptions.concat(syrasNotLinked.map((elem) => {
                return {
                    value: elem.sn,
                    label: elem.sn
                }
            }));
        }

        const {
            label,
            luminareArchiveId,
            supportArchiveId,
            driverArchiveId,
            lampArchiveId,
            syra_sn,
            geometry
        } = item;

        const {
            lat,
            lng
        } = geometry;

        return (
            <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container LightpointEdit">
                <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                    <ModalHeader toggle={() => this.closeModal(false)}>
                        {t("Create lightpoint")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="content">
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong style={{ color: "#990000"}}>{t("Lightpoint name")}</strong>
                                        </Label>
                                        <Input type="text" value={label} name='label' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="content umpi-modal-content-sep">
                            <Row>
                                <Col md={12} xs={12}>
                                    <Documents
                                        canEdit={canEdit}
                                        acceptFile={["pdf", "image"]}
                                        documents={mediaStorage}
                                        onUpdate={this.handleUpdateDocuments}
                                        onDelete={null}
                                        onPreview={null}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="content umpi-modal-content-sep">
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Serial number")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="syra_sn"
                                            clearable={false}
                                            value={syra_sn}
                                            options={syraSnOptions}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["syra_sn"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Lighting body")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="luminareArchiveId"
                                            clearable={false}
                                            value={luminareArchiveId}
                                            options={luminares.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.type
                                                }
                                            })}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["luminareArchiveId"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Support")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="supportArchiveId"
                                            clearable={false}
                                            value={supportArchiveId}
                                            options={supports.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.type
                                                }
                                            })}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["supportArchiveId"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Driver")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="driverArchiveId"
                                            clearable={false}
                                            value={driverArchiveId}
                                            options={drivers.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.type
                                                }
                                            })}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["driverArchiveId"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Lamp")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Select
                                            closeOnSelect={true}
                                            name="lampArchiveId"
                                            clearable={false}
                                            value={lampArchiveId}
                                            options={lamps.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.type
                                                }
                                            })}
                                            onChange={option => {
                                                if (option && option.value !== null) {
                                                    let item = this.state.item;
                                                    item["lampArchiveId"] = option.value;
                                                    this.setState({ item })
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="content umpi-modal-content-sep">
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Latitude")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={lat ? lat : ""} name='lat' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Longitude")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={lng ? lng : ""} name='lng' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="umpi-modal-btn-cancel" onClick={() => this.closeModal(false)}>
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                            {t("Salva")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

const connectedLightpointEdit = withTranslation()(LightpointEdit);
export { connectedLightpointEdit as LightpointEdit };

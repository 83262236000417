import React, { Fragment } from "react";
import {
    FormGroup,
    Label,
    Row,
    Col
} from "reactstrap";
import { Button } from "components";
import { withTranslation } from 'react-i18next';
import GenericThumg from './../../assets/img/umpi/generic-flat.png';
import PdfThumg from './../../assets/img/umpi/pdf-flat.png';

const __acceptFileCat = {
    "pdf": {
        "mime": [ "application/pdf" ],
        "ext":  [ "pdf" ]
    },
    "image": {
        "mime": [ "image/png", "image/jpg", "image/jpeg" ],
        "ext":  [ "png", "jpg", "jpeg" ]
    },
}

const CloseSvgIcon = ({ onClick = null }) => {
    return (
        <div onClick={onClick}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#880000" />
            </svg>
        </div>
    )
}

const PreviewSvgIcon = ({ onClick = null }) => {
    return (
        <div onClick={onClick}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 14.5H18.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.5 10.5V18.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.9258 21.9248L28.0008 27.9998" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

// ----------------------------------------------------------//
const MAX_UPLOAD_FILE_SIZE = 12582912;                       // 12Mb
// ----------------------------------------------------------//

class Documents extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            acceptFile:    Array.isArray(props.acceptFile) ? props.acceptFile : [],
            listMode:      props.listMode === true         ? true             : false,
            canEdit:       props.canEdit === true          ? true             : false,
            isDisabled:    props.disabled === true         ? true             : false,
            media_storage: Array.isArray(props.documents)  ? props.documents  : [],
            documents:     {}
        }

        this.getCategoryFromFileName   = this.getCategoryFromFileName.bind(this);
        this.getThumbImageFromCategory = this.getThumbImageFromCategory.bind(this);
        this.getBase64                 = this.getBase64.bind(this);
        this.handleFileSelection       = this.handleFileSelection.bind(this);
        this.handleFileRemove          = this.handleFileRemove.bind(this);
        this.handleOnDelete            = this.handleOnDelete.bind(this);
        this.handleOnPreview           = this.handleOnPreview.bind(this);

        this.hiddenFileInput = React.createRef();
    }

    componentDidMount() {
        if (typeof this.props.loadDocumentsFromCallback === "function") {
            this.props.loadDocumentsFromCallback((data) => {
                this.setState({
                    media_storage: Array.isArray(data) ? data : [data],
                });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (Array.isArray(nextProps.documents) &&
            JSON.stringify(this.state.media_storage) !== JSON.stringify(nextProps.documents)) {
            this.setState({
                media_storage: Array.isArray(nextProps.documents) ? nextProps.documents : []
            });
        }

        if (this.props.loadDocumentsFromCallback !== nextProps.loadDocumentsFromCallback) {
            nextProps.loadDocumentsFromCallback((data) => {
                this.setState({
                    media_storage: Array.isArray(data) ? data : []
                });
            });
        }

        if (this.state.listMode !== nextProps.listMode) {
            this.setState({
                listMode: nextProps.listMode === true ? true : false
            });
        }

        if (this.state.canEdit !== nextProps.canEdit) {
            this.setState({
                canEdit: nextProps.canEdit === true ? true : false
            });
        }

        if (this.state.isDisabled !== nextProps.disabled) {
            this.setState({
                isDisabled: nextProps.disabled === true ? true : false
            });
        }

        if (this.state.acceptFile !== nextProps.acceptFile) {
            this.setState({
                acceptFile: Array.isArray(nextProps.acceptFile) ? nextProps.acceptFile : []
            });
        }
    }

    getCategoryFromFileName(fileName) {
        const keys = Object.keys(__acceptFileCat);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const chk = __acceptFileCat[key].ext.filter(x => fileName.endsWith(x));
            if (chk.length > 0) { return key; }
        }
        return null;
    }

    getThumbImageFromCategory(category, fileObj) {
        switch (category) {
            case "image": return fileObj.link;
            case "pdf":   return PdfThumg;
            default:      return GenericThumg;
        }
    }

    getBase64(file) {
        return (new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
            reader.onerror = error => reject(error);
        }));
    }

    handleFileSelection(e) {
        let { t } = this.props;
        let { documents, acceptFile } = this.state;
        let fileSelected = e.target.files[0];

        e.target.value = "";

        if ([
            ...(acceptFile.includes("pdf")   ? __acceptFileCat["pdf"].mime   : []),
            ...(acceptFile.includes("image") ? __acceptFileCat["image"].mime : [])
        ].includes(fileSelected.type)) {
            this.getBase64(fileSelected).then((data64) => {
                // Get buffer from b64url
                const buffer = Buffer.from(data64.substring(data64.indexOf(',') + 1));
                // Check size of file
                if (buffer.length <= MAX_UPLOAD_FILE_SIZE) {
                    documents["file-" + Date.now()] = {
                        "id":   "file-" + Date.now(),
                        "name": fileSelected.name,
                        "type": fileSelected.type,
                        "file": data64
                    };
                    this.setState({ documents }, () => {
                        if (typeof this.props.onUpdate === "function") {
                            this.props.onUpdate(this.state.documents);
                        }
                    });
                } else {
                    alert(t("File size invalid") + ":  12Mb");
                }
            })
            .catch((err) => console.error(err));
        } else {
            alert(t("File invalid"));
        }
    }

    handleFileRemove(index) {
        let documents = this.state.documents;
        delete documents[index];
        this.setState({ documents }, () => {
            if (typeof this.props.onUpdate === "function") {
                this.props.onUpdate(this.state.documents);
            }
        });
    }

    handleOnDelete(item) {
        if (typeof this.props.onDelete === "function") {
            this.props.onDelete(item);
        }
    }

    handleOnPreview(item) {
        const { t, noInPreviewClose } = this.props;
        const category = this.getCategoryFromFileName(item.name);

        if (typeof this.props.onPreview === "function") {
            this.props.onPreview((
                <div className={"umpi-component-documents-offcanvas" + (noInPreviewClose !== true ? "" : " in-preview-close")}>
                    {noInPreviewClose !== true && <div className="title">
                        <div>{t("Preview")}</div>
                        <div>
                            <CloseSvgIcon onClick={() => this.props.onPreview(null)} />
                        </div>
                    </div>}
                    <div className="content">{(() => {
                        switch (category) {
                            case "pdf": return (
                                <iframe title="pdf-preview" src={item.link} />
                            );

                            case "image": return (
                                <img src={item.link} alt="" />
                            );

                            default: return null;
                        }
                    })()}</div>
                </div>
            ));
        }
    }

    render() {
        const { t, smallSize, onPreview, noDataText } = this.props;
        const { isDisabled, listMode, canEdit, documents, media_storage, acceptFile } = this.state;

        let documentKeys  = canEdit ? Object.keys(documents) : [];
        let acceptedExt   = [];

        acceptFile.map((accKey) => {
            acceptedExt = acceptedExt.concat(
                __acceptFileCat[accKey].ext
            );
            return true;
        })

        return (
            <div>{
                listMode ? (
                    <Fragment>
                        {media_storage.length > 0 ? (
                            <div className="umpi-component-documents-lmode-list">{
                                (() => {
                                    let mediaArray = [];

                                    media_storage.map((item, index) => {
                                        const category = this.getCategoryFromFileName(item.name);
                                        const catThumb = this.getThumbImageFromCategory(category, item);
                                        if (index < 2) {
                                            mediaArray.push(
                                                <div key={index}
                                                    className={index > 0 ? "umpi-component-lmode-num-images" : ""}
                                                    style={{ padding: "2px" }}>
                                                    {onPreview && <PreviewSvgIcon onClick={() => this.handleOnPreview(item)} />}
                                                    <img src={catThumb} alt="" />
                                                </div>
                                            );
                                        }
                                        return true;
                                    });

                                    if ((media_storage.length - mediaArray.length) > 0) {
                                        mediaArray.push(
                                            <div key={"rest_images"} className="umpi-component-lmode-num-images">
                                                <span>{(media_storage.length - mediaArray.length)}</span>
                                            </div>
                                        )
                                    }

                                    return mediaArray;
                                })()
                            }</div>
                        ) : null}
                    </Fragment>
                ) : (
                    <Fragment>
                        {canEdit && <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <FormGroup>
                                    <Label>
                                        <strong>{t("Documents")} </strong>
                                        <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                    </Label>
                                    <Button
                                        color="default"
                                        disabled={isDisabled}
                                        className="umpi-modal-btn-documents"
                                        onClick={() => this.hiddenFileInput.current.click()}>
                                        <i className="fa fa-upload mr-2" />{t("Add documents")} ({
                                            acceptedExt.length > 0 ? acceptedExt.join("/") : " --- "
                                        })
                                    </Button>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        ref={this.hiddenFileInput}
                                        onChange={this.handleFileSelection}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="umpi-modal-btn-documents-list">{
                                documentKeys.length > 0 ?
                                documentKeys.map((keyDoc) => {
                                    let itemDoc = documents[keyDoc];

                                    if (itemDoc) {
                                        let itemIcon = (
                                            itemDoc.type === "application/pdf"
                                        ) ? "fa-file" : "fa-image";

                                        return (
                                            <div className="item-row" key={keyDoc}>
                                                <div className="row-icon">
                                                    <i className={"fa " + itemIcon} />
                                                </div>
                                                <div className="row-badge">
                                                    <div onClick={() => this.handleFileRemove(keyDoc)}>
                                                        <i className="fa fa-times" />
                                                    </div>
                                                    <div>{itemDoc.name}</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                                }).filter(x => x) : null
                            }</Col>
                        </Row>}
                        {media_storage.length > 0 ? (
                            <Row>
                                <Col  xs={12} sm={12} md={12} lg={12} xl={12}
                                    className={"umpi-component-documents-list" + (smallSize === true ? " small-size" : "" )}>{
                                    media_storage.map((item, index) => {
                                        const category = this.getCategoryFromFileName(item.name);
                                        const catThumb = this.getThumbImageFromCategory(category, item);

                                        return (
                                            <div key={index}>
                                                <div className="umpi-list-action-sm-preview">
                                                    {onPreview && <PreviewSvgIcon onClick={() => this.handleOnPreview(item)} />}
                                                    <img src={catThumb} alt="" />
                                                </div>
                                                <div className="umpi-list-action-title">{item.name}</div>
                                                {canEdit &&
                                                    <div className="umpi-list-action-btn">
                                                        <CloseSvgIcon onClick={() => this.handleOnDelete(item)} />
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }</Col>
                            </Row>
                        ) : noDataText ? (
                            <Row>
                                <Col md={12} xs={12}>
                                    {noDataText}
                                </Col>
                            </Row>
                        ) : null}
                    </Fragment>
                )
            }</div>
        );
    }
}

export default withTranslation()(Documents);

import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

import { Button, Documents } from "components";
import { projectActions, loopbackActions } from 'actions';
import { projectService } from '../../services';
import BoundsOnChangeMap from './BoundsOnChangeMap';
import { utils } from '../../helpers';
import LocationSearchInput from './react-places-autocomplete/LocationSearchInput';
import { googleConstants, roleConstants } from '../../constants';
import Select from "react-select";
import ErrorModal from "../Components/ErrorModal"
import { withTranslation } from 'react-i18next';
import User from '../../entities/User';

import $ from 'jquery';
window.$ = $;

function getMapCoordinatesObj(map) {
    const bounds = map.getBounds();
    let coordinatesObj = bounds.toJSON();
    let coordinates = JSON.parse(JSON.stringify(coordinatesObj));
    coordinates.center = map.getCenter().toJSON();
    coordinates.zoom = map.getZoom();
    return coordinates;
}

class ProjectEdit extends React.Component {

    constructor(props) {
        super(props);

        this.loggedinUser = User.current();
        this.state = {
            currentProjectId: 0,
            mediaStorage: [],
            mediaPreview: null,
            item: {
                id: '',
                name: '',
                description: '',
                databaseName: '',
                geometry: {
                    lat: null,
                    lng: null
                },
                zoom: 11,
                documents: {}
            },
            members: [],
            submitted: false,
            map: null,
            loading: false,
            nameState: '',
            databaseNameState: '',
            membersState: '',
            emptyCoordinatesError: false,
            error: null,
            isOpenModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMapMounted = this.handleMapMounted.bind(this);
        this.handleMapBoundsChanged = this.handleMapBoundsChanged.bind(this);
        this.onLocationSearchInputLatLng = this.onLocationSearchInputLatLng.bind(this);
        this.onChangeMembers = this.onChangeMembers.bind(this);
        this.getUserOptions = this.getUserOptions.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuemnts = this.handleDeleteDocuemnts.bind(this);
    }

    componentWillMount() {
        this.loadProjectData(this.props.projectId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentProjectId !== nextProps.projectId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadProjectData(nextProps.projectId);
            });
        }

        /*if (nextProps.error) {
            this.setState({ error: nextProps.error, modalNotice: true });
        }
        else if (nextProps.project_just_saved) {
            fireProjectsChanged();
            history.push('/projects');
        }*/
        else if (nextProps.users && nextProps.project) {
            let project = nextProps.project;
            let members = this.state.members;
            if (project.users) {
                //CAPUANO
                //Non cancello i membri, altrimenti risulterà sempre che non ho utenti selezionati
                //In questo modo, durante la fase di modifica del progetto, saranno visibili le tag con gli utenti già selezionati
                members = project.users.map((user) => { return user.id; });

                delete project.users;
            }
            const nextState = {
                item: project,
                members: members,
                precMembers: utils.cloneDeeply(members)
            };
            this.setState(nextState);
        }
    }

    handleUpdateDocuments(p_documents) {
        let item = this.state.item;
        item.documents = p_documents;
        this.setState({ item });
    }

    async handleDeleteDocuemnts(name, refId) {
        // Delete doc media
        await projectService.deleteImageByProjectId(
            name,
            refId
        );

        // Refresh
        this.loadDocuments(refId);
    }

    loadDocuments = async (projectId) => {
        let docs = null;

        try {
            // Create doc media
            docs = await projectService.getImageByProjectId(projectId);
        } catch (err) {
            console.error(err);
            docs = null;
        }

        // Save to storage
        this.setState({
            mediaStorage: (docs && docs.base64) ? [{
                name: "icon.png",
                link: docs.base64
            }] : []
        });
    }

    loadProjectData = (projectId) => {
        this.setState({
            addMode: projectId === 0,
            currentProjectId: projectId,
            mediaStorage: [],
            mediaPreview: null,
            item: {
                id: '',
                name: '',
                description: '',
                databaseName: '',
                geometry: {
                    lat: null,
                    lng: null
                },
                zoom: 11,
                documents: {}
            },
            map: null,
            members: [],
            submitted: false,
            loading: false,
            nameState: '',
            databaseNameState: '',
            membersState: '',
            emptyCoordinatesError: false,
            error: null
        }, async () => {
            if (projectId > 0) {
                await this.props.dispatch(projectActions.getById(projectId));
                this.loadDocuments(projectId);
            }
            // carico gli users
            await this.props.dispatch(loopbackActions.list('admin', 'users'));
        });
    }

    getUserOptions(users, filterByRole = false) {
        //console.log(this.props.project)
        return users ? users.map((user) => {
            if (filterByRole && ![
                1, 2, 3
            ].includes(user.userRoleId)) {
                return null;
            }

            return { value: user.id, label: user.name + ' ' + user.surname }
        }).filter(x => x) : [];
    }

    handleChange(e) {
        const { name, value } = e.target
        let item = this.state.item
        item[name] = value
        this.setState({ item })
    }

    handleMapMounted(mountedMap) {
        this.setState({
            map: mountedMap,
        })
    }

    handleMapBoundsChanged() {
        const user = User.current()
        if (User.isWithinGroup(user, roleConstants.CAT)) {
            return;
        }
        const { map } = this.state;
        const coordinates = getMapCoordinatesObj(map);
        let item = this.state.item;
        item.zoom = coordinates.zoom;
        item.geometry = coordinates.center;
        this.setState({
            item,
            emptyCoordinatesError: false
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        let { item, members, precMembers } = this.state
        this.setState({ submitted: true })

        const { dispatch } = this.props
        let hasErrors = false;

        if (item.name === '') {
            this.setState({ nameState: 'has-danger' });
            hasErrors = true;
        }

        if (item.databaseName === '') {
            var uuid = require("uuid");
            var id = uuid.v4();
            item.databaseName = id;
            // this.setState({ databaseNameState: 'has-danger' });
            // hasErrors = true;
        }

        if (!item.geometry.lat) {
            this.setState({ emptyCoordinatesError: true });
            hasErrors = true;
        }

        if (members.length === 0) {
            this.setState({ membersState: 'has-danger' });
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        // members
        let toKeepMembers = (precMembers ? members.filter(function (n) {
            return precMembers.indexOf(n) > -1;
        }) : []);
        let toRemoveMembers = (precMembers ? precMembers.filter(x => !toKeepMembers.includes(x)) : []);
        let toAddMembers = members.filter(x => !toKeepMembers.includes(x));

        // if (item.id === 0) {
        if (this.state.addMode) {
            // in add mode elimino l'id
            delete item.id;
        }

        dispatch(projectActions.edit(
            item,
            toRemoveMembers,
            toAddMembers,
            async (response) => {
                // Check for data
                if (response &&
                    response.project &&
                    response.project.id > 0) {
                    try {
                        // Try to convert
                        let currentUser = JSON.parse(localStorage.getItem('user'));

                        // Clear session project data for map
                        localStorage.removeItem(
                            "session_" + currentUser.id +
                            "_" + response.project.id
                        );
                    } catch (ex) {
                        console.error(ex);
                    }

                    // Check for documents
                    if (response.project.documents) {
                        // Get keys
                        let keys = Object.keys(response.project.documents);

                        // Check for docs into storage
                        for (let i = 0; i < keys.length; i++) {
                            // Get current media
                            let media = response.project.documents[keys[i]];

                            // Create doc media
                            await projectService.postImageByProjectId(
                                response.project.id,
                                { "file": media.file }
                            );
                        }
                    }
                }

                // Close modal
                this.closeModal();
            }
        ));
    }

    onLocationSearchInputLatLng(latLng, results) {
        if (results.length <= 0) {
            this.setState({
                item: {
                    ...this.state.item,
                    geometry: {
                        lat: null,
                        lng: null
                    },
                    zoom: 11
                }
            }, () => {
                this.setState({  emptyCoordinatesError: false });
            });
            return;
        }

        const bounds = results[0].geometry.viewport
        const $mapDiv = $('#projectCoordinatesMap')
        const mapDim = { height: $mapDiv.height(), width: $mapDiv.width() }
        const zoom = utils.googleMapBestZoomLevelFromBounds(bounds, mapDim)
        // const newCoords = {
        //   ...this.state.item.coordinates,
        //   center: {
        //     lat: latLng.lat,
        //     lng: latLng.lng
        //   },
        //   zoom: zoomLevel
        // };

        this.setState({
            item: {
                ...this.state.item,
                geometry: {
                    lat: latLng.lat,
                    lng: latLng.lng
                },
                zoom
                //coordinates: newCoords
            }
        }, () => {
            //const coordinates = getMapCoordinatesObj(this.state.map);
            this.setState({
                //CAPUANO
                //Ho commentato item in quanto non permetteva alla mappa di aggiornare la propria posizione dopo l'inserimento dell'indirizzo
                // item: {
                //     ...this.state.item,
                //     geometry: coordinates.center,
                //     zoom: coordinates.zoom
                //     //coordinates: coordinates,
                // },
                emptyCoordinatesError: false
            })
        })
    }

    onChangeMembers(options) {
        let newMembers = options.map(option => { return option.value });
        this.setState({ members: newMembers });
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentProjectId: 0,
            mediaStorage: [],
            mediaPreview: null,
            item: {
                id: '',
                name: '',
                description: '',
                databaseName: '',
                geometry: {
                    lat: null,
                    lng: null
                },
                zoom: 11,
                documents: {}
            },
            map: null,
            members: [],
            submitted: false,
            loading: false,
            nameState: '',
            databaseNameState: '',
            membersState: '',
            emptyCoordinatesError: false,
            error: null,
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    render() {
        const { t, users } = this.props;
        const {
            addMode,
            item,
            map,
            nameState,
            emptyCoordinatesError,
            membersState,
            members,
            error,
            isOpenModal,
            mediaStorage,
            mediaPreview,
            currentProjectId
        } = this.state;

        const {
            name,
            description,
            geometry,
            zoom,
            documents
        } = item;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);
        const usersOptions = this.getUserOptions(users, true);

        return (
            <div>
                {error && <ErrorModal
                    error={error}
                    onClickHandler={() => {
                        this.setState({ error: null })
                    }}
                />}
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container ProjectEdit">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi progetto") : t("Modifica progetto") + " " + name)}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col md={12} xs={12}>
                                            {/* PROGETTO */}
                                            {/*!addMode && <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label><strong>{"ID"}</strong></Label>
                                                        <Input type="text" disabled placeholder="" value={id} onChange={() => { }} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col>
                                                    <FormGroup className={nameState}>
                                                        <Label>
                                                            <strong style={{ color: "#990000"}}>{t("Nome")}</strong>
                                                        </Label>
                                                        <Input type="text" value={name} name='name' onChange={this.handleChange} style={(!(name && name.length > 0)) ? { borderColor: "#ff7777" } : {}}  />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            <strong>{t("Descrizione")}</strong>
                                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                        </Label>
                                                        <Input type="text" value={description} name='description' onChange={this.handleChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} xs={12}>
                                                    <Documents
                                                        canEdit={canEdit}
                                                        disabled={Object.keys(documents ? documents : {}).length > 0}
                                                        acceptFile={["image"]}
                                                        documents={mediaStorage}
                                                        onUpdate={this.handleUpdateDocuments}
                                                        onDelete={(media) => this.handleDeleteDocuemnts(media.name, currentProjectId)}
                                                        onPreview={(media) => this.setState({ mediaPreview: media })}
                                                    />
                                                </Col>
                                            </Row>
                                            {/*!addMode && <Row>
                                                <Col>
                                                    <FormGroup className={databaseNameState}>
                                                        <Label><strong>{t("Database")}</strong></Label>
                                                        <Input type="text" readOnly value={databaseName || ''} name='databaseName' onChange={this.handleChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col>
                                                    <FormGroup className={membersState}>
                                                        <Label>
                                                            <strong style={{ color: "#990000"}}>{t("Utenti")}</strong>
                                                        </Label>
                                                        <div className="user-list">
                                                            {/* {users ? users.} */}
                                                        </div>
                                                        <Select
                                                            className="warning"
                                                            multi={true}
                                                            closeOnSelect={false}
                                                            placeholder=""
                                                            name="members"
                                                            value={members}
                                                            options={usersOptions}
                                                            onChange={this.onChangeMembers}
                                                            style={(!(Array.isArray(members) && members.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                        />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            <strong style={{ color: "#990000"}}>{t("Area geografica")}</strong>
                                                        </Label>
                                                        {map !== null &&
                                                            <LocationSearchInput placeholder={t("Cerca sulla mappa...")}
                                                                onLatLng={this.onLocationSearchInputLatLng}
                                                                customErrorMessagForStatus={(errorStatus) => {
                                                                    if (errorStatus === "ZERO_RESULTS") {
                                                                        return t("Nessun risultato")
                                                                    }
                                                                    else if (errorStatus === "OVER_QUERY_LIMIT") {
                                                                        return t("Limite di richieste giornaliere superato")
                                                                    }
                                                                    else {
                                                                        return t("Impossibile trovare il luogo cercato")
                                                                    }
                                                                }}
                                                                style={(!(geometry.lat && geometry.lng)) ? { borderColor: "#ff7777" } : {}}
                                                            />
                                                        }
                                                        {
                                                            emptyCoordinatesError && <Label>{t("Selezionare un area geografica")}</Label>
                                                        }
                                                        {isOpenModal ?
                                                            <div id="projectCoordinatesMap" style={{ borderRadius: "25px", overflow: "hidden" }}>
                                                                <BoundsOnChangeMap
                                                                    id="projectCoordinatesMap"
                                                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleConstants.API_KEY + "&libraries=places&language=it&region=IT"}
                                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                                    containerElement={<div style={{ height: `260px` }} />}
                                                                    mapElement={<div style={{ height: `100%` }} />}
                                                                    handleMapMounted={this.handleMapMounted}
                                                                    defaultCenter={geometry}
                                                                    center={geometry}
                                                                    defaultZoom={zoom}
                                                                    zoom={zoom}
                                                                    onDragEnd={this.handleMapBoundsChanged}
                                                                    onZoomChanged={this.handleMapBoundsChanged}
                                                                    markers={[
                                                                        {
                                                                            label: name,
                                                                            position: geometry
                                                                        }
                                                                    ]}
                                                                    options={{
                                                                        scrollwheel: false,
                                                                        fullScreenControl: true,
                                                                        // mapTypeControl: mode === NavModes.PLANT ? true : false,
                                                                        // streetViewControl: mode === NavModes.PLANT ? true : false,
                                                                        // scaleControl: mode === NavModes.PLANT ? true : false,
                                                                        clickableIcons: false,
                                                                        disableDoubleClickZoom: false,
                                                                        // draggableCursor
                                                                        streetViewControl: false,
                                                                        disableDefaultUI: true,
                                                                        //ho utilizzato https://snazzymaps.com/editor per stilizzare la mappa
                                                                        styles: [
                                                                            {
                                                                                "featureType": "administrative",
                                                                                "elementType": "all",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "off"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "administrative",
                                                                                "elementType": "labels.text",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "on"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "landscape",
                                                                                "elementType": "all",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "on"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "poi",
                                                                                "elementType": "all",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "off"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "road",
                                                                                "elementType": "all",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "on"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "road",
                                                                                "elementType": "labels",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "simplified"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "road",
                                                                                "elementType": "labels.text",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "on"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "road",
                                                                                "elementType": "labels.icon",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "off"
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                "featureType": "transit",
                                                                                "elementType": "all",
                                                                                "stylers": [
                                                                                    {
                                                                                        "visibility": "off"
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }}
                                                                />
                                                            </div>
                                                            : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save">
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { projects, loopback } = state;
    let { project, error, project_just_saved } = projects;
    const { users } = loopback;
    return {
        project,
        users: users && users.instances,
        error,
        project_just_saved
    };
}

const connectedProjectEdit = withTranslation()(connect(mapStateToProps)(ProjectEdit));
export { connectedProjectEdit as ProjectEdit };

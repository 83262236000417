import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ReactJson from 'react-json-view';

import { Button } from "components";


class ErrorModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { error, onClickHandler } = this.props;

    let isString = (typeof error === "string");


    return (<Modal isOpen={true} className="modal-notice" >
      <ModalHeader
        className="title-up"
      >
        ERRORE
      </ModalHeader>
      <ModalBody>
        {!isString && <ReactJson src={error} />}
        {isString && <p>{error}</p>}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          color="info"
          round
          onClick={onClickHandler}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>);
  }

}

export default ErrorModal;

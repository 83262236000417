// import PricingPage from "views/Pages/PricingPage.jsx";
import { LoginPage } from "views/Pages/LoginPage.jsx";
// import RegisterPage from "views/Pages/RegisterPage.jsx";
// import LockScreenPage from "views/Pages/LockScreenPage.jsx";
// import {projectConstants} from '../constants';

const pagesRoutes = [
    // {
    //   path: "/pages/register-page",
    //   name: "Register Page",
    //   short: "Register",
    //   mini: "RP",
    //   icon: "tech_mobile",
    //   component: RegisterPage
    // },
    {
        path: "/pages/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        icon: "users_circle-08",
        component: LoginPage
    },
    // {
    //   path: "/pages/pricing-page",
    //   name: "Pricing Page",
    //   short: "Pricing",
    //   mini: "PP",
    //   icon: "business_money-coins",
    //   component: PricingPage
    // },
    // {
    //   path: "/pages/lock-screen-page",
    //   name: "Lock Screen Page",
    //   short: "Lock",
    //   mini: "LSP",
    //   icon: "ui-1_lock-circle-open",
    //   component: LockScreenPage
    // },
    {
        redirect: true,
        path: "/pages",
        pathTo: "/pages/login-page",
        name: "Login Page"
    }
];

export default pagesRoutes;

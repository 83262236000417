import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Button } from "components";
import { withTranslation } from 'react-i18next';

class ZoneEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: {
                label: '',
                description: ''
            },
            isOpenModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            });
        }
    }

    handleChange(e) {
        const { name, value } = e.target
        let item = this.state.item
        item[name] = (
            name !== "device"
        ) ? value : [value]
        this.setState({ item })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.closeModal(true);
    }

    closeModal = (logic) => {
        // Backup
        const { item } = this.state;
        // reset all
        this.setState({
            item: {
                label: '',
                timerProfile: '',
                date: ''
            },
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose(
                logic && item ? item : null
            );
        });
    }

    checkPreSubmit() {
        const { item } = this.state;

        return (!(
            item &&
            item.label
        )) ? true : false;
    }

    render() {
        const { t } = this.props;
        const { item } = this.state;
        const {
            label,
            description
        } = item;

        return (
            <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container ZoneEdit">
                <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                    <ModalHeader toggle={() => this.closeModal(false)}>
                        {t("Create zone")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="content">
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong style={{ color: "#990000"}}>{t("Zone name")}</strong>
                                        </Label>
                                        <Input type="text" value={label} name='label' onChange={this.handleChange} style={(!(label && label.length > 0)) ? { borderColor: "#ff7777" } : {}} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            <strong>{t("Description")}</strong>
                                            <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                        </Label>
                                        <Input type="text" value={description} name='description' onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" className="umpi-modal-btn-cancel" onClick={() => this.closeModal(false)}>
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                            {t("Salva")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

const connectedZoneEdit = withTranslation()(ZoneEdit);
export { connectedZoneEdit as ZoneEdit };

import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { Button } from "components";
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Periferals from "./Periferals";
import Switch from "react-bootstrap-switch";

class PeriferalsWattmeter extends Periferals {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            editingIndex: -1,
            editingIndex2: -1,
            editingItem2: null,
            isNew: false,
            maxDateUpload: "",
            validationError: null
        }

    }

    componentWillReceiveProps() {
        this.setState({
            isNew: false,
            editingIndex2: -1,
            editingIndex: -1

        });

    }

    renderWattmetro() {

        const { t, dispatch, loading, conf, params, selectedMenuItem } = this.props;
        const { editingIndex, editingItem } = this.state;

        //let optionsEspansioni = this.getOptionsEspansioni();

        // contatori per l'assegnamento del campo code
        let nextCodes = {};

        if (params.androsLiveParams && params.androsLiveParams.periferals) {
            let types = Object.keys(params.androsLiveParams.periferals);
            types.map((type) => {
                const periferal = params.androsLiveParams.periferals[type];

                if (type === 'K1LO') {

                    this.defaultIntegrationTimaAlarm = periferal.properties.integrationTimaAlarm.default;
                    this.defaultChangeFlagTimeAlarm = periferal.properties.changeFlagTimeAlarm.default;
                    this.defaultAlarms = [];
                    const alarms = Object.keys(periferal.properties.alarms.default);
                    for (let alarm of alarms) {
                        this.defaultAlarms.push(
                            {
                                alarm,
                                ...periferal.properties.alarms.default[alarm]
                            }
                        )
                    }

                }

                nextCodes[type] = {
                    codeHi: periferal.addressCodeHi,
                    codeLo: 0
                };

                return false;
            });
        }

        let optionsAnalyzer = [];
        let analyzerNames = [];
        if (params.androsLiveParams) {
            if (params.androsLiveParams.analyzer) {
                let types = Object.keys(params.androsLiveParams.analyzer);
                types.map((type) => {
                    const analyzer = params.androsLiveParams.analyzer[type];
                    optionsAnalyzer.push(
                        { value: analyzer.name, label: analyzer.name }
                    );
                    analyzerNames.push(analyzer.name);
                    return false;
                });
            }
        }

        let key = selectedMenuItem.key;
        let expIndex = selectedMenuItem.expIndex;
        let expansion = conf.configParam.expansions[expIndex];
        let title = this.getExpansionName(expansion.type) + " " + expansion.address;

        let dataPow = [];

        if (expansion.periferals) {
            expansion.periferals.forEach((periferal, keyper) => {
                if (periferal.properties === null) {
                    periferal.properties = {
                        label: ""
                    };
                }
                periferal.index = expIndex + "_" + keyper;
                dataPow.push(periferal);
            });
        }

        return (<Card key={'wattmeter-' + key}>
            <CardHeader className="config-card-header">
                <CardTitle className="config-card-title" >
                    <span style={{ flex: 1 }}>{title}</span>

                    {conf.configParam.expansions[expIndex].periferals.length === 0 &&
                        <Button
                            color="info"
                            onClick={() => {

                                if (this.state.isNew || conf.configParam.expansions === null || conf.configParam.expansions.length === 0) {
                                    return;
                                }

                                //let code = ("" + expansion.address).padStart(2, '0');

                                //

                                if (!analyzerNames || analyzerNames.length === 0) {
                                    return;
                                }

                                let item = {
                                    code: "",
                                    type: "",
                                    properties: {
                                        label: "",
                                        timePolling: 0
                                    }
                                };

                                if (!conf.configParam.expansions[expIndex].periferals) {
                                    conf.configParam.expansions[expIndex].periferals = [];
                                }
                                conf.configParam.expansions[expIndex].periferals.unshift(item);
                                this.setState({ isNew: true, isNewTarget: conf.configParam.expansions[expIndex].periferals, conf, editingIndex: expIndex + "_0", editingItem: item });

                            }}
                            style={{ fontSize: 14, fontWeight: 400, margin: 0, padding: '5px' }}
                        >
                            {t("Nuovo")}
                        </Button>
                    }
                </CardTitle>
            </CardHeader>
            <CardBody>
                {this.state.alert}
                <ReactTable
                    data={dataPow}
                    className="-striped -highlight configurations"
                    pageSize={dataPow && dataPow.length}
                    showPagination={false}
                    NoDataComponent={() => null}
                    LoadingComponent={() => {
                        if (loading) {
                            return (
                                <div className="-loading -active">
                                    <div className="-loading-inner custom-margin-0-auto">
                                        <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return null;
                        }
                    }
                    }
                    columns={[

                        {
                            Header: t("Etichetta"),
                            accessor: "properties.label",
                            filterable: false,
                            sortable: true,
                            Cell: (row) => {
                                let isEditing = (row.original.index === editingIndex);
                                if (isEditing) {
                                    return (
                                        <Input
                                            type="text"
                                            value={editingItem.properties.label}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                editingItem.properties.label = value;
                                                this.setState({ editingItem })

                                            }}
                                        />);
                                } else {
                                    return (<div className="no-overflow" align="left">{row.value}</div>)
                                }
                            }
                        },
                        // {
                        //   Header: t("Espansione"),
                        //   accessor: "code",
                        //   filterable: false,
                        //   Cell: (row)=>{
                        //     const selectedOption = this.getSelectedExpansion(optionsEspansioni,row.value);
                        //     return (<span>{selectedOption && selectedOption.label}</span>);
                        //   }

                        // },

                        {
                            Header: t("Codice"),
                            accessor: "code",
                            filterable: false,
                            Cell: (row) => {

                                let isEditing = (row.original.index === editingIndex);
                                if (isEditing) {
                                    return (
                                        <Input
                                            type="text"
                                            value={editingItem.code}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                editingItem.code = value;
                                                this.setState({ editingItem })

                                            }}
                                        />);
                                } else {
                                    return (<div className="no-overflow" align="left">{row.value}</div>)
                                }
                            }

                        },

                        {
                            Header: t("Tipo"),
                            accessor: "type",
                            filterable: false,
                            Cell: (row) => {

                                const isEditing = (row.original.index === editingIndex);

                                if (isEditing) {

                                    return (
                                        <Select
                                            // menuContainerStyle = \{ \{position: 'relative'\} \}
                                            placeholder={t("Tipo wattmetro")}
                                            name="group"
                                            value={{ value: editingItem.type, label: editingItem.type }}
                                            options={optionsAnalyzer}
                                            onChange={option => {
                                                if (option) {
                                                    editingItem.type = option.value;
                                                    this.setState({ editingItem })
                                                }
                                            }}
                                        />
                                    );

                                } else {

                                    return (<div className="no-overflow" >{row.value}</div>);
                                }
                            }

                        },
                        {
                            Header: t("Tempo polling"),
                            accessor: "properties.timePolling",
                            filterable: false,
                            Cell: (row) => {
                                let isEditing = (row.original.index === editingIndex);
                                if (isEditing) {
                                    return (
                                        <Input
                                            type="text"
                                            value={editingItem.properties ? editingItem.properties.timePolling : 0}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                editingItem.properties.timePolling = value;
                                                this.setState({ editingItem })

                                            }}
                                        />);
                                } else {
                                    return (<div className="no-overflow" align="">{row.value}</div>)
                                }
                            },
                        },

                        {
                            Header: t("Tempo di integrazione"),
                            accessor: "properties.integrationTimaAlarm",
                            filterable: false,
                            Cell: (row) => {

                                if (row.original.type !== 'K1LO') {
                                    return null;
                                }

                                let isEditing = (row.original.index === editingIndex);
                                if (isEditing) {

                                    if (!editingItem.properties) {
                                        editingItem.properties = {};
                                    }

                                    if (editingItem.properties.integrationTimaAlarm === null) {
                                        editingItem.properties.integrationTimaAlarm = this.defaultIntegrationTimaAlarm;
                                    }

                                    return (
                                        <Input
                                            type="number"
                                            value={editingItem.properties.integrationTimaAlarm}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                editingItem.properties.integrationTimaAlarm = value;
                                                this.setState({ editingItem })
                                            }}
                                        />);
                                } else {
                                    return (<div className="no-overflow" align="">{row.value}</div>)
                                }
                            },
                        },

                        {
                            Header: t("Tempo cambio stato"),
                            accessor: "properties.changeFlagTimeAlarm",
                            filterable: false,
                            Cell: (row) => {

                                if (row.original.type !== 'K1LO') {
                                    return null;
                                }

                                let isEditing = (row.original.index === editingIndex);
                                if (isEditing) {

                                    if (!editingItem.properties) {
                                        editingItem.properties = {};
                                    }

                                    if (editingItem.properties.changeFlagTimeAlarm === null) {
                                        editingItem.properties.changeFlagTimeAlarm = this.defaultChangeFlagTimeAlarm;
                                    }

                                    return (
                                        <Input
                                            type="number"
                                            value={editingItem.properties.changeFlagTimeAlarm}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                editingItem.properties.changeFlagTimeAlarm = value;
                                                this.setState({ editingItem })
                                            }}
                                        />);
                                } else {
                                    return (<div className="no-overflow" align="">{row.value}</div>)
                                }
                            },
                        },

                        {
                            Header: "",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            Cell: (row) => {

                                let isEditing = (row.original.index === editingIndex);

                                if (!isEditing)
                                    return (
                                        <div align="right">
                                            <ButtonWithTooltip
                                                disabled={true}
                                                id={"per3_edit_" + row.index}
                                                color="warning"
                                                size="sm"
                                                tooltipText={t("Modifica")}
                                                onClick={() => {

                                                    this.setState({
                                                        isNew: false,
                                                        editingIndex: row.original.index,
                                                        editingItem: JSON.parse(JSON.stringify(row.original))
                                                    });

                                                }}
                                            >
                                                <i className="fa fa-edit" />
                                            </ButtonWithTooltip>

                                            {" "}

                                            <ButtonWithTooltip
                                                disabled={true}
                                                id={"per3_del_" + row.index}
                                                onClick={() => {

                                                    this.confirmDelete(
                                                        t("Elimina wattmetro"),
                                                        () => {

                                                            const index = row.original.index.split("_");
                                                            let periferals = conf.configParam.expansions[index[0]].periferals.filter(
                                                                ((curr, index_per) => {
                                                                    return (index_per !== index[1])
                                                                })
                                                            );
                                                            conf.configParam.expansions[index[0]].periferals = periferals;
                                                            dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                            this.setState({
                                                                alert: null
                                                            });

                                                        }

                                                    );

                                                }}
                                                color="danger"
                                                size="sm"
                                                tooltipText={t("Elimina")}
                                            >
                                                <i className="fa fa-trash" />
                                            </ButtonWithTooltip>

                                        </div>
                                    )
                                else
                                    return (
                                        <div className="actions-right">
                                            <ButtonWithTooltip
                                                id={"per3_edit_" + row.index}
                                                color="success"
                                                size="sm"
                                                tooltipText={t("Salva")}
                                                onClick={() => {
                                                    let index = row.original.index.split("_");
                                                    conf.configParam.expansions[index[0]]['periferals'][index[1]] = editingItem;
                                                    conf.dateUpdate = moment().toISOString(true);
                                                    dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                }}
                                            >
                                                <i className="fa fa-check" />
                                            </ButtonWithTooltip>

                                            {" "}

                                            <ButtonWithTooltip
                                                id={"per3_del_" + row.index}
                                                onClick={() => {
                                                    if (this.state.isNew) {
                                                        let index = row.original.index.split("_");
                                                        conf.configParam.expansions[index[0]]['periferals'].splice(index[1], 1);
                                                        this.setState({ isNew: false, editingIndex: -1, conf });
                                                    }
                                                    else
                                                        this.setState({ editingIndex: -1 });
                                                }}
                                                color="secondary"
                                                size="sm"
                                                tooltipText={t("Annulla")}
                                            >
                                                <i className="fa fa-times" />
                                            </ButtonWithTooltip>
                                        </div>
                                    )

                            }
                        }
                    ]}
                />
            </CardBody>
        </Card>
        )
    }

    renderAllarmi() {

        const { t, dispatch, loading, conf, selectedMenuItem } = this.props;
        const { editingIndex2, editingItem2 } = this.state;

        let key = selectedMenuItem.key;
        let expIndex = selectedMenuItem.expIndex;
        let expansion = conf.configParam.expansions[expIndex];

        if (!expansion.alarms) {
            expansion.alarms = Object.assign([], this.defaultAlarms);
        }

        return (

            <Card key={'alarm-' + key}>
                <CardHeader className="config-card-header">
                    <CardTitle className="config-card-title" >
                        <span style={{ flex: 1 }}>{t("Allarmi")}</span>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <ReactTable
                        data={expansion.alarms}
                        className="-striped -highlight configurations"
                        pageSize={expansion.alarms && expansion.alarms.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[

                            {
                                Header: t("Allarme"),
                                accessor: "alarm",
                                filterable: false,
                                sortable: true,
                                Cell: (row) => {
                                    // let isEditing = (row.index === editingIndex2);
                                    // if (isEditing) {
                                    //   return (
                                    //     <Input
                                    //       type = "text"
                                    //       value = { editingItem2.alarm }
                                    //       onChange={ (e) => {
                                    //         const { name, value } = e.target;
                                    //         editingItem2.alarm = value;
                                    //         this.setState({ editingItem2 })

                                    //       }}
                                    //     />);
                                    // } else {
                                    return (<div className="no-overflow" align="left">{row.value}</div>)
                                    // }
                                }
                            },

                            {
                                Header: t("Etichetta"),
                                accessor: "label",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex2);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem2.label}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem2.label = value;
                                                    this.setState({ editingItem2 })
                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="left">{row.value}</div>)
                                    }
                                }

                            },

                            {
                                Header: t("Minimo"),
                                accessor: "min",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex2);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="number"
                                                value={editingItem2.min}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem2.min = value;
                                                    this.setState({ editingItem2 })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                },
                            },

                            {
                                Header: t("Massimo"),
                                accessor: "max",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex2);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="number"
                                                value={editingItem2.max}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem2.max = value;
                                                    this.setState({ editingItem2 })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                },
                            },

                            {
                                Header: t("Valore"),
                                accessor: "value",
                                filterable: false,
                                Cell: (row) => {
                                    let isEditing = (row.index === editingIndex2);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="number"
                                                value={editingItem2.value}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem2.value = value;
                                                    this.setState({ editingItem2 })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="">{row.value}</div>)
                                    }
                                },
                            },

                            {
                                Header: t("Abilita"),
                                accessor: "enable",
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex2);

                                    if (isEditing) {
                                        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >

                                            <Switch
                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                offText={
                                                    <i className="now-ui-icons ui-1_simple-remove" />
                                                }
                                                onColor={"green"}
                                                offColor={"lightgray"}
                                                onChange={(el, state) => {
                                                    // user.isActive = state;
                                                    // this.setState({ user });
                                                    editingItem2.enable = state;
                                                    this.setState({ editingItem2 })
                                                }}
                                                value={editingItem2.enable}
                                            />

                                        </div>
                                        )
                                    }

                                    else {
                                        return (<div align="center">{(row.value ?
                                            <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-check-square-o" aria-hidden="true"></i> :
                                            <i style={{ fontSize: "1.5em", verticalAlign: "middle" }} className="fa fa-square-o" aria-hidden="true"></i>
                                        )} </div>)

                                    }

                                },
                                filterable: false
                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex2);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"al3_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex2: row.index,
                                                            editingItem2: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {/* {" "}

                      <ButtonWithTooltip
                        id={"al3_del_" + row.index}
                        onClick={() => {

                          this.confirmDelete(
                            t("Elimina wattmetro"),
                            () => {

                              const index = row.original.index.split("_");
                              let periferals = conf.configParam.expansions[index[0]].periferals.filter(
                                ((curr, index_per) => {
                                  return (index_per !== index[1])
                                })
                              );
                              conf.configParam.expansions[index[0]].periferals = periferals;
                              dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                              this.setState({
                                alert: null
                              });

                            }

                          );

                        }}
                        color="danger"
                        size="sm"
                        tooltipText={t("Elimina")}
                      >
                        <i className="fa fa-trash" />
                      </ButtonWithTooltip> */}

                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"al3_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        expansion.alarms[row.index] = editingItem2;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"al3_del_" + row.index}
                                                    onClick={() => {
                                                        if (this.state.isNew) {
                                                            let index = row.index;
                                                            expansion[index].alarms.splice(index[1], 1);
                                                            this.setState({ isNew: false, editingIndex2: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex2: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }
                        ]}
                    />
                </CardBody>
            </Card>
        )
    }

    render() {
        return (
            <div>
                {this.renderWattmetro()}
                {this.renderAllarmi()}
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default PeriferalsWattmeter = withTranslation()(connect(mapStateToProps)(PeriferalsWattmeter));

import { accessToken } from '../helpers';
import { chartConstants, loopbackConstants } from '../constants';
import { history } from '../helpers';
import moment from 'moment';

export const dashboardService = {
    getEventsData,
    getTotalPowerAndCurrentsURL,
    getTotalPowerAndCurrentsURL_aggregate,
    getInternalTensionsOfAndros,
    getManagedPeripherals,
    getPeripheralCodes,
    getPieData,
    getPhaseVoltages,
    getLineActivePower,
    getCurf,
    getActiveEnergy,
    getIO,
    getPeripheralCodesURL,
    getSyraData,
    getLightpoints,
    getLightpointsSyraData,
    getDevices,
    getModem,
    getGlobalMetrics,
    getAnnualRecapStats
};

const formatUtcDate = (dateLocal) => {
    return moment(dateLocal).utc().format('YYYY-MM-DD HH:mm:ss')
}

function getEndDate() {
    let date = moment().endOf('day');
    return formatUtcDate(date.format('YYYY-MM-DD') + " 23:59:59");
}

function getStartDate(weeks) {
    let date = moment().subtract(weeks, 'w').startOf('day');
    return formatUtcDate(date.format('YYYY-MM-DD') + " 23:59:59");
}

function getPeriod(weeks) {
    let period = '';
    if (Array.isArray(weeks) && weeks.length >= 2) {
        period = `&startTime=${formatUtcDate(weeks[0])}&endTime=${formatUtcDate(weeks[1])}`
    } else {
        if (weeks) {
            let startDate = getStartDate(weeks);
            let endDate = getEndDate();
            period = `&startTime=${startDate}&endTime=${endDate}`
        } else {
            let endDate = moment().startOf('day').format('YYYY-MM-DD') + " 23:59:59";
            let startDate = moment().subtract(3, 'd').endOf('day').format('YYYY-MM-DD') + " 23:59:59";
            period = `&startTime=${formatUtcDate(startDate)}&endTime=${formatUtcDate(endDate)}`
        }
    }

    return period;
}

function getTypeAnalytics(type) {
    switch (type) {
        case 'range':
        case 'his': return 'analytics';
        case 'mid': return 'live_aggregation';
        case 'live':
        default: return 'live';
    }
}

function getTotalPowerAndCurrentsURL(projectId, macAddress, weeks, type = 'live') {
    const metrics = 'pActiveTotal';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

function getTotalPowerAndCurrentsURL_aggregate(projectId, macAddress, weeks, type = 'live', peripheralCodes) {
    const metrics = 'pActiveTotal';
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_aggregate_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCodes
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

function getManagedPeripherals(projectId, macAddress) {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/peripherals'
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
    return requestUrl;
}

function getInternalTensionsOfAndros(projectId, macAddress, peripheralCode, weeks, type = 'live') {
    const metrics = 'anValue';
    // const peripheralCode = peripheralCode;
    const peripheralType = 'AN-IN';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}
function getSyraData(projectId, macAddress, peripheralCode, weeks, metrics, type = 'live') {
    // const peripheralCode = peripheralCode;
    const peripheralType = 'AN-IN';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/syra_data_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

async function getEventsData(projectId, startDate, endDate, cabinets = "", events = "", peripherals = "", priorities = "") {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/events/data'
        + accessToken('admin', projectId)
        + '&cabinetId=' + cabinets
        + '&peripheralType=' + peripherals
        + '&eventCode=' + events
        + '&priority=' + priorities
        + '&startTime=' + startDate
        + '&endTime=' + endDate;
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

async function getPeripheralCodes(projectId, macAddress, peripheralType) {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/peripheral_codes'
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralType=' + peripheralType
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data.map(v => v.peripheralCode);
    }
    else {
        throw response;
    }
}

async function getIO(projectId, macAddress, peripheralType, peripheralCode, weeks, type = 'live') {
    let metrics = peripheralType === 'DIG-IN' ? 'status' : 'outstatus';
    switch (peripheralType) {
        case chartConstants.DIG_IN: {
            metrics = 'status';
            break;
        }
        case chartConstants.DIG_OUT: {
            metrics = 'outstatus';
            break;
        }
        case chartConstants.AN_IN: {
            metrics = 'anValue';
            break;
        }
        case chartConstants.AN_OUT: {
            metrics = 'anValue';
            break;
        }
        case chartConstants.ISTATE: {
            metrics = 'anValue';
            break;
        }
        default: break;
    }
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralType=' + peripheralType
        + '&peripheralCode=' + peripheralCode
        + '&metrics=' + metrics
        + getPeriod(weeks);
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

function getPeripheralCodesURL(projectId, macAddress, peripheralType) {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/peripheral_codes'
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralType=' + peripheralType
    return requestUrl;
}

function getPieData(projectId, macAddress, metrics, peripheralCode, weeks, type = 'live', opType = "avg") {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + '/' + opType
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&metrics=' + metrics
        + '&peripheralCode=' + peripheralCode
        + getPeriod(weeks);
    return requestUrl;
}

function getPhaseVoltages(projectId, macAddress, peripheralCode, weeks, type = 'live') {
    const metrics = 'volf1,volf2,volf3';
    // const peripheralCode = peripheralCode;
    const peripheralType = 'K1LO';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

function getCurf(projectId, macAddress, peripheralCode, weeks, type = 'live') {
    const metrics = 'curf1,curf2,curf3';
    // const peripheralCode = peripheralCode;
    const peripheralType = 'K1LO';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

function getLineActivePower(projectId, macAddress, peripheralCode, weeks, type = 'live') {
    const metrics = 'pact1,pact2,pact3';
    // const peripheralCode = peripheralCode;
    const peripheralType = 'K1LO';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_io_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

function getActiveEnergy(projectId, macAddress, peripheralCode, weeks, type = 'live') {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/active_energy_'
        + getTypeAnalytics(type)
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        + '&peripheralCode=' + peripheralCode
        + getPeriod(weeks);
    return requestUrl;
}

/**Get per ottenere i lightpoints per le statistiche in /stats */
async function getLightpoints(projectId) {
    const requestUrl = loopbackConstants.BASE_URL + '/plant/lightpoints'
        + accessToken('admin', projectId)
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**Get per ottenere i lightpoints (watt/syra) per le statistiche in /stats */
async function getLightpointsSyraData(projectId) {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/syras'
        + accessToken('admin', projectId)
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**Get per ottenere i lightpoints per le statistiche in /stats */
async function getDevices(projectId) {
    let filter = { where: { projectId: projectId } };
    const requestUrl = loopbackConstants.BASE_URL + '/archive/devices'
        + accessToken('admin')
        + '&filter=' + JSON.stringify(filter);
    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**Get per ottenere le analisi risparmio annuali */
async function getAnnualRecapStats(projectId) {
    const requestUrl = loopbackConstants.BASE_URL
        + '/umpi-smartlighting/statistics/consumption'
        + accessToken('admin', projectId);

    let response = await fetch(requestUrl);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }
    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

function getModem(projectId, macAddress, weeks, type = 'live') {
    const metrics = 'signal';
    // const peripheralType = 'K1LO';
    //const requestOptions = {};
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/andros_data_modem_'
        + getTypeAnalytics(type)
        + '/avg'
        + accessToken('admin', projectId)
        + '&macAddress=' + macAddress
        // + '&peripheralCode=' + peripheralCode
        // + '&peripheralType=' + peripheralType
        + '&metrics=' + metrics
        + getPeriod(weeks);
    return requestUrl;
}

/**Get per ottenere le metriche globali */
function getGlobalMetrics(projectIds) {
    const requestUrl = loopbackConstants.BASE_URL + '/umpi-smartlighting/globalMetrics'
        + accessToken('admin')
        + (() => {
            if (Array.isArray(projectIds) && projectIds.length > 0) {
                return "&" + projectIds.map((prjId) => {
                    return 'projectId=' + prjId
                }).join("&")
            }
            else {
                return '&projectId=' + projectIds
            }
        })();
    return requestUrl;
}

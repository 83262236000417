import { accessToken } from '../helpers';
import { loopbackConstants } from '../constants';
import { history } from '../helpers';

export const projectService = {
    getAll,
    getAllByUser,
    getById,
    getImageByProjectId,
    postImageByProjectId,
    deleteImageByProjectId,
    edit,
    delete: _delete,
    filterAllNoLimit,
    filterCount,
    filter,
    addUser,
    delUser
};

/**
 * Restituisce tutti i progetti.
 *
 * @return {Object[]} projects.
 * @throws {Object} risposta in caso d'errore.
 */
 async function getAll(filter = null) {
    const requestOptions = {
    };

    const requestUrl = loopbackConstants.BASE_URL + '/admin/projects' + accessToken('admin') + (
        filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : ''
    );

    let response = await fetch(requestUrl, requestOptions);

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce tutti i progetti.
 *
 * @return {Object[]} projects.
 * @throws {Object} risposta in caso d'errore.
 */
async function getAllByUser(user = null, filter = null) {
    const requestOptions = {
    };

    let requestUrl = '';

    if (user && user.userRoleId === 1) {
        requestUrl = loopbackConstants.BASE_URL + '/admin/projects' + accessToken('admin');
    }
    else {
        requestUrl = loopbackConstants.BASE_URL + '/admin/users/' + user.id + "/projects" + accessToken('admin');
    }

    let response = await fetch(requestUrl + (filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : ''), requestOptions);

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        // Codice per ordinamento array progetti
        if (data !== null) {
            data.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
        }
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce il progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project
 * @throws {Object} risposta in caso d'errore.
 */
async function getById(id, filter = null) {
    const requestOptions = {
        method: 'GET'
    };

    const url = loopbackConstants.BASE_URL + '/admin/projects/' + id + accessToken('admin') + (filter ? '&filter=' + encodeURIComponent(JSON.stringify(filter)) : '');

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function getImageByProjectId(id) {
    const requestOptions = {
        method: 'GET'
    };

    const url = loopbackConstants.BASE_URL + '/document/icon' + accessToken('admin') + '&projectId=' + id;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function deleteImageByProjectId(name, id) {
    const requestOptions = {
        method: 'DELETE'
    };

    const url = loopbackConstants.BASE_URL + '/document/delete' + accessToken('admin') +
        '&name=' + name + '&projectId=' + id;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function postImageByProjectId(id, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const url = loopbackConstants.BASE_URL + '/document/upload/icon' + accessToken('admin') + '&projectId=' + id;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce i progetti che soddisfano i filtri passati come argomenti senza paginazione.
 *
 * @param {Object} filtersToUse
 * @return {Object[]} projects
 * @throws {Object} risposta in caso d'errore.
 */
async function filterAllNoLimit(filtersToUse) {
    const requestOptions = {
        method: 'GET'
    };
    let filters = false
    if (filtersToUse && filtersToUse.where) {
        filters = {
            where: filtersToUse.where
        }
    }
    let response = await fetch(
        (loopbackConstants.BASE_URL + '/admin/projects' + accessToken('admin') + (
            filters ? '&filter=' + encodeURIComponent(JSON.stringify(filters)) : ''
        )
        ), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce il numero di record che soddisfano i filtri.
 *
 * @param {Object} filters.
 * @return {Number} numero di record che soddisfano i filtri.
 * @throws {Object} risposta in caso d'errore.
 */
async function filterCount(filters) {
    const requestOptions = {
        method: 'GET'
    }
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/projects/count' + accessToken('admin') + (
        filters && filters.where ? '&where=' + encodeURIComponent(JSON.stringify(filters.where)) : ''
    ), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce i progetti che soddisfano i filtri passati come argomento.
 *
 * @param {Object} filters filtri di ricerca.
 * @return {Object[]} projects.
 * @throws {Object} risposta in caso d'errore.
 */
async function filter(filters) {
    const requestOptions = {
        method: 'GET'
    };
    let response = await fetch(
        (loopbackConstants.BASE_URL + '/admin/projects' + accessToken('admin') + (
            filters ? '&filter=' + encodeURIComponent(JSON.stringify(filters)) : ''
        )
        ), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Modifica un progetto.
 *
 * @param { Object } item progetto da modificare.
 * @return { Object } project progetto modificato.
 * @throws { Object } risposta in caso d'errore.
 */
async function edit(item) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item)
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/projects' + accessToken('admin'), requestOptions);
    let data = await response.json();
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return data;
    }
    else {
        throw data;
    }
}

async function addUser(projectId, userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/projects/' + projectId + '/users/rel/' + userId + accessToken('admin'), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    let data = await response.json();
    if (response.ok) {
        return data;
    }
    else {
        throw data;
    }
}

async function delUser(projectId, userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/projects/' + projectId + '/users/rel/' + userId + accessToken('admin'), requestOptions);

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.status !== 204)
        throw new Error("Impossibile eliminare il record");

    // let data = await response.json();

    // if (response.ok) {
    //     return data;
    // }
    // else {
    //     throw data;
    // }
}

/**
 * Elimina un progetto dato il suo id.
 *
 * @param { Number } id del progetto da eliminare.
 * @return { Object } risultato dell'eliminazione.
 * @throws { Object } risposta in caso d'errore.
 */
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE'
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/projects/' + id + accessToken('admin'), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        let error = null;
        if (typeof (response.json) === 'function')
            error = await response.json();
        else
            error = response;

        throw error;

    }

}

import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Button, Documents } from "components";
import { loopbackActions } from '../../actions';
import { loopbackService } from '../../services';
import ErrorModal from "../Components/ErrorModal"
import { withTranslation } from 'react-i18next';
import { roleConstants } from '../../constants';
import User from '../../entities/User';

class LampEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentLampId: 0,
            mediaStorage: [],
            mediaPreview: null,
            lamp: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkPreSubmit = this.checkPreSubmit.bind(this);

        this.handleUpdateDocuments = this.handleUpdateDocuments.bind(this);
        this.handleDeleteDocuemnts = this.handleDeleteDocuemnts.bind(this);
    }

    componentWillMount() {
        this.loadLampData(this.props.lampId);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isOpenModal !== nextProps.isOpenModal ||
            this.state.currentLampId !== nextProps.lampId) {
            this.setState({
                isOpenModal: nextProps.isOpenModal
            }, () => {
                this.loadLampData(nextProps.lampId);
            });
        }

        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }/*
        else if (nextProps.editEnd) {
            history.push('/lamps');
        }*/
        else if (nextProps.lamp) {
            this.setState({
                lamp: nextProps.lamp
            });
        }
    }

    handleUpdateDocuments(p_documents) {
        let lamp = this.state.lamp;
        lamp.documents = p_documents;
        this.setState({ lamp });
    }

    async handleDeleteDocuemnts(name, refId) {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Delete doc media
        await loopbackService.deleteProjectDocumentToModel(
            name,
            selectedProjectId,
            "LAMPS",
            refId
        );

        // Refresh
        this.loadDocuments(refId);
    }

    loadDocuments = async (lampId) => {
        // Get project active
        let selectedProjectId = localStorage.getItem('selectedProjectId');

        // Create doc media
        const docs = await loopbackService.listProjectDocumentFromModel(
            selectedProjectId,
            "LAMPS",
            lampId
        );

        // Save to storage
        this.setState({
            mediaStorage: Array.isArray(docs) ? docs : []
        });
    }

    loadLampData = (lampId) => {
        this.setState({
            addMode: lampId === 0,
            currentLampId: lampId,
            mediaStorage: [],
            mediaPreview: null,
            lamp: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null
        }, () => {
            if (lampId > 0) {
                this.props.dispatch(loopbackActions.get('plant', 'lamps', lampId));
                this.loadDocuments(lampId);
            }
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        let { lamp } = this.state;
        lamp[name] = value;
        this.setState({ lamp });
    }

    handleSubmit(e) {
        e.preventDefault();
        let { lamp, addMode } = this.state;
        const { dispatch } = this.props;

        this.setState({ submitted: true });
        lamp.projectId = localStorage.getItem('selectedProjectId');

        if (addMode) {
            delete lamp.id;
        }

        dispatch(loopbackActions.edit(
            'plant',
            'lamps',
            lamp,
            null,
            async (response) => {
                // Get project active
                let selectedProjectId = localStorage.getItem('selectedProjectId');

                // Check for documents
                if (response &&
                    response.instance &&
                    response.instance.id > 0 &&
                    response.instance.documents &&
                    selectedProjectId > 0) {
                    // Get keys
                    let keys = Object.keys(response.instance.documents);

                    // Check for docs into storage
                    for (let i = 0; i < keys.length; i++) {
                        // Get current media
                        let media = response.instance.documents[keys[i]];

                        // Create doc media
                        await loopbackService.pushProjectDocumentToModel(
                            selectedProjectId,
                            {
                                "filename": media.name,
                                "type":     "LAMPS",
                                "deviceId": response.instance.id,
                                "file":     media.file
                            }
                        );
                    }
                }

                // Close modal
                this.closeModal();
            }
        ));
    }

    closeModal = () => {
        // reset all
        this.setState({
            currentLampId: 0,
            mediaStorage: [],
            mediaPreview: null,
            lamp: {
                id: '',
                type: '',
                producer: '',
                power: '',
                documents: {}
            },
            submitted: false,
            error: null,
            isOpenModal: false
        }, () => {
            // close
            this.props.onModalClose();
        });
    }

    checkPreSubmit() {
        const { lamp } = this.state;

        return (!(
            lamp &&
            lamp.type
        )) ? true : false;
    }

    render() {
        const { t } = this.props;
        const { addMode, error, lamp, mediaStorage, mediaPreview, currentLampId } = this.state;

        const user = User.current();
        const canEdit = (user.userRoleId !== roleConstants.GUEST);

        return (
            <div>
                {error && <ErrorModal
                    error={error}
                    onClickHandler={() => {
                        this.setState({ error: null });
                    }}
                />}
                <Modal size="lg" isOpen={this.state.isOpenModal} className="modal-umpi-container">
                    <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <ModalHeader toggle={this.closeModal}>
                            {(addMode ? t("Aggiungi lampada") : t("Modifica lampada"))}
                        </ModalHeader>
                        <ModalBody style={mediaPreview ? { padding: "0" }: {}}>
                            {mediaPreview ? mediaPreview : (
                                <div className="content">
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong style={{ color: "#990000"}}>{t("Tipo")}</strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={lamp.type}
                                                    name='type'
                                                    onChange={this.handleChange}
                                                    style={(!(lamp && lamp.type && lamp.type.length > 0)) ? { borderColor: "#ff7777" } : {}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Produttore")}</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    value={lamp.producer}
                                                    name='producer'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label>
                                                    <strong>{t("Potenza")} (W)</strong>
                                                    <span style={{ color: "#ccc" }}> ({t("Optional")})</span>
                                                </Label>
                                                <Input
                                                    type="number"
                                                    min="0"
                                                    value={lamp.power}
                                                    name='power'
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Documents
                                                canEdit={canEdit}
                                                acceptFile={["pdf", "image"]}
                                                documents={mediaStorage}
                                                onUpdate={this.handleUpdateDocuments}
                                                onDelete={(media) => this.handleDeleteDocuemnts(media.name, currentLampId)}
                                                onPreview={(media) => this.setState({ mediaPreview: media })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" className="umpi-modal-btn-cancel" onClick={this.closeModal}>
                                {t("Annulla")}
                            </Button>
                            &nbsp;&nbsp;
                            <Button color="success" className="umpi-modal-btn-save" disabled={this.checkPreSubmit()}>
                                {t("Salva")}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { lamps } = loopback;
    return {
        lamp: lamps && lamps.instance,
        error: lamps && lamps.error,
        editEnd: lamps && lamps.editEnd,
    };
}

const connectedLampEdit = withTranslation()(connect(mapStateToProps)(LampEdit));
export { connectedLampEdit as LampEdit };

import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export default class BaseView extends Component {

    componentWillReceiveProps(nextProps) {
        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem,
            isNew: false
        });
    }

    getSelected(options, value) {
        if (value === null)
            return null;
        if (options === null)
            return null;
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        };
        return null;
    }

    confirmDelete(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    getExpansionName(type) {
        const { params } = this.props;
        if (params && params.androsLiveParams.expansions && params.androsLiveParams.expansions[type])
            return params.androsLiveParams.expansions[type]['name'];
        else
            return "";
    }

}

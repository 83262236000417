import React, { Component } from "react";
import { utils } from '../../helpers';
import { Chart, Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { withTranslation } from 'react-i18next';
import { getPointsFromDailyProfile } from '../TimerProfiles/DailyChart';
import { PowerLevelColors } from '../../constants/global.constants';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';

const DECIMATE_DAYS = 6;

class ZoneConfigurationProgrammingChart extends Component {

    constructor(props) {
        super(props);

        let project = utils.getSelectedProject();
        const { timezone } = project;
        const { lat, lng } = project.geometry;
        this.state = {
            lat,
            lng,
            timezone,
            loading: false,
            entities: {
                weeklyProfile: null,
                dailyProfiles: null
            }
        }
    }

    getChartOptionsBubble() {

        let that = this;
        const { view } = this.props;

        Chart.defaults.global.defaultFontSize = 10;

        let gridOptions = {

            maintainAspectRatio: false,
            responsive: true,

            scales: {

                xAxes: [
                    {
                        stacked: true,
                        gridLines: {
                            display: false
                        }
                    }],

                yAxes: [{
                    stacked: false,
                    display: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 1440,
                        stepSize: 120,
                        reverse: true,
                        callback: function (value, index, values) {
                            return utils.formatAbsMin(value);
                        }
                    }
                }],
            },
            layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 }
            },
            legend: {
                display: false,

            },

            animation: {
                defaultFontSize: 10,
                duration: 0.1,
                onProgress: function () {
                    if (view === 'week') {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                        ctx.textAlign = 'center';
                        ctx.fillStyle = "#AAA";
                        ctx.textBaseline = 'top';
                        this.data.datasets.forEach(function (dataset, i) {
                            let meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                let label = that.labels[i];
                                if (typeof dataset.data[index] !== 'undefined' && dataset.data[index] > 0) {
                                    ctx.fillText(label, bar._model.x, bar._model.y - 10);
                                }
                            });
                        });
                    }
                }
            },

            tooltips: {
                // mode: "index",
                callbacks: {
                    title: (items, data) => {
                        let title = data.datasets[items[0].datasetIndex].label;
                        return title;
                    },
                    label: (item, data) => {
                        let label = data.datasets[item.datasetIndex].label;
                        var powlevel = that.powLevels[label];
                        return powlevel + "%"; //utils.formatAbsMin(absmin);
                    }
                }
            },
        };
        return gridOptions;
    }

    getChartOptions() {

        let that = this;
        const { view } = this.props;

        Chart.defaults.global.defaultFontSize = 10;

        let gridOptions = {

            maintainAspectRatio: false,
            responsive: true,

            scales: {

                xAxes: [
                    {
                        stacked: true,
                        gridLines: {
                            display: false
                            // borderDash: [, 4]

                        }
                    }],

                yAxes: [{
                    stacked: false,
                    display: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 1440,
                        stepSize: 120,
                        reverse: true,
                        callback: function (value, index, values) {
                            return utils.formatAbsMin(value);
                        }
                    }
                }],
            },
            layout: {
                padding: { left: 0, right: 0, top: 15, bottom: 15 }
            },
            legend: {
                display: false,

            },

            animation: {
                defaultFontSize: 10,
                duration: 0.1,
                onProgress: function () {
                    if (view === 'week') {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                        ctx.textAlign = 'center';
                        ctx.fillStyle = "#AAA";
                        ctx.textBaseline = 'top';
                        this.data.datasets.forEach(function (dataset, i) {
                            let meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                let label = that.labels[i];
                                if (typeof dataset.data[index] !== 'undefined' && dataset.data[index] > 0) {
                                    ctx.fillText(label, bar._model.x, bar._model.y - 10);
                                }
                            });
                        });
                    }
                }
            },

            tooltips: {
                // mode: "index",
                callbacks: {
                    title: (items, data) => {
                        let title = data.datasets[items[0].datasetIndex].label;
                        return title;
                    },
                    label: (item, data) => {
                        let label = data.datasets[item.datasetIndex].label;
                        var powlevel = that.powLevels[label];
                        return powlevel + "%"; //utils.formatAbsMin(absmin);
                    }
                }
            },

            plugins: {
                // datalabels: {
                //     anchor: "top",
                //     clamp: true,

                //     formatter: function(value, context) {
                //         return that.labels[context.dataIndex];
                //         // if (value===0)
                //         //   return "";
                //         // if (value===1440)
                //         //   return "";
                //         // return context.chart.data.labels[context.dataIndex]+" "+value;
                //     }
                // }
            }

        };

        return gridOptions;

    }

    /**
     * Restituisce il profilo timer giornaliero attivo nella zona per il giorno passato come argomento
     * @param {*} day
     */
    lookupDailyProfile(day) {
        const { instances, indexes, zoneTimerProfileWeekly, zoneTimerProfileWeeklyStartDate, zoneDailyProfiles, legacyTimer } = this.props;
        let daynum = day.format('d');
        let d = daynum === "0" ? 6 : parseInt(daynum, 10) - 1;

        let dailyProfile = null;

        if (legacyTimer === true) {
            // profilo settimanale
            if (zoneTimerProfileWeeklyStartDate <= day) {
                let weeklyProfile = instances.timerprofileweekly[indexes.timerprofileweekly[zoneTimerProfileWeekly]];
                switch (d) {
                    case 0: case 1: case 2: case 3: case 4:
                        // lun - ven
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[weeklyProfile.monFriProfileId]];
                        break;
                    case 5:
                        // sab
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[weeklyProfile.satProfileId]];
                        break;
                    case 6:
                        // dom
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[weeklyProfile.sunProfileId]];
                        break;
                    default: break;
                }
            }
        }
        else {
            // profilo settimanale
            if (zoneTimerProfileWeeklyStartDate <= day) {
                switch (d) {
                    case 0: case 1: case 2: case 3: case 4:
                        // lun - ven
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[zoneTimerProfileWeekly]];
                        break;
                    case 5:
                        // sab
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[zoneTimerProfileWeekly]];
                        break;
                    case 6:
                        // dom
                        dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[zoneTimerProfileWeekly]];
                        break;
                    default: break;
                }
            }
        }

        // profzoneTimerProfileWeeklyili giornalieri
        for (let zoneDailyProfile of zoneDailyProfiles) {
            let start = moment(zoneDailyProfile.startDate, moment.defaultFormat).set({ hours: 0 });
            let stop = moment(zoneDailyProfile.stopDate, moment.defaultFormat).set({ hours: 23, minutes: 59, seconds: 59 });

            /*if (start <= day) {
                //console.log("Condizione di inizio valida")
            }
            if (stop >= day) {
                //console.log("Condizione di fine valida")
            }*/

            if (start <= day && stop >= day && utils.bitTest(zoneDailyProfile.weekDayMask, d)) {
                dailyProfile = instances.timerprofiledaily[indexes.timerprofiledaily[zoneDailyProfile.timerProfileDailyId]];
                break;
            }
        }

        return dailyProfile;

    }

    getDatasets() {

        const { selectedDay, view, legacyTimer } = this.props;
        const { lat, lng, timezone } = this.state;

        // range di date da graficare
        let startDay = moment(selectedDay).startOf(view);
        let curDay = moment(startDay);
        let endDay = moment(selectedDay).endOf(view);
        let prevDay = moment(startDay).subtract(1, 'days');

        let lastPointPrevDay = undefined;
        let curPoints = undefined;
        let prevPoints = undefined;
        let prevDailyPofile = undefined;
        let curDailyPofile = undefined;
        let points = [];

        let datasets = [];
        this.powLevels = {};
        this.labels = [];
        let data = [];
        let i = 0;

        let doDecimate = endDay.diff(startDay, 'days') > 30;

        while (curDay <= endDay) {
            points = [];

            // Preparazione dei profili timer e array dei punti per il giorno precedente e quello attuale

            // Selezione del profilo giornaliero applicabile al giorno precedente
            // Operazione valida per il primo giorno da visualizzare
            prevDailyPofile = this.lookupDailyProfile(prevDay);
            // Esiste un profilo valido per il giorno precedente?
            if (prevDailyPofile !== undefined && prevDailyPofile !== null) {
                // Si -> recupera il dato dell'ultima transizione
                // In caso contrario lastPointPrevDay rimane undefined
                prevPoints = getPointsFromDailyProfile(prevDailyPofile, lat, lng, timezone, curDay, legacyTimer)
                // Integrazione array punti transizioni
                prevPoints.map(t => t.absmin = utils.toAbsMinutes(t.x));
                lastPointPrevDay = prevPoints[(prevPoints.length - 1)];
            }

            //Selezione del profilo giornaliero applicabile solo al giorno corrente
            curDailyPofile = this.lookupDailyProfile(curDay);
            // Esiste un profilo giornaliero applicabile al giorno corrente?
            if (curDailyPofile !== undefined && prevDailyPofile !== null) {
                curPoints = getPointsFromDailyProfile(curDailyPofile, lat, lng, timezone, curDay, legacyTimer);
                // Integrazione array punti transizioni
                curPoints.map(t => t.absmin = utils.toAbsMinutes(t.x));

                // Se l'ultima transizione del giorno precedente non è definita
                // Allora scorre l'array dei punti per trovare la prima valida
                if (lastPointPrevDay === undefined && curPoints[0] && curPoints[0].interpolate && curPoints[0].interpolate === true) {
                    curPoints.shift(0);
                }

                // Se l'ultima trasizione del giorno precedente è valida e la prima del giorno attuale è interpolata
                // Allora sostituisce la prima del giorno corrente con l'ultima del giorno precedente
                if (lastPointPrevDay !== undefined && curPoints[0] && curPoints[0].interpolate && curPoints[0].interpolate === true) {
                    curPoints[0].y = lastPointPrevDay.y;
                }
            }

            if (Array.isArray(curPoints)) {
                // Costruzione dell'arrai dei punti
                let curPoint = undefined;
                for (curPoint of curPoints) {
                    let p = { mins: curPoint.absmin, level: curPoint.y };
                    points.push(p);
                }
                lastPointPrevDay = curPoint;
            }

            // Set del profilo timer e punti transizioni del giorno precedente
            prevDailyPofile = curDailyPofile;
            prevPoints = curPoints;

            /*
            if (prevDailyPofile) {
              prevPoints = getPointsFromDailyProfile(prevDailyPofile, lat, lng, curDay, legacyTimer);

              // suddivido i punti del giorno prima (abstime >= 0)

              for (let prevPoint of prevPoints) {
                let p = { mins: prevPoint.x, level: prevPoint.y };
                if (prevPoint.x > 0) {
                  // punti del mattino
                  if (!prevAmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                    prevAmPoints.push(p);
                  }
                }
                else {
                  // punti del pomeriggio
                  p = { mins: prevPoint.x + 1440, level: prevPoint.y };
                  if (!prevPmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                    prevPmPoints.push(p);
                  }
                }
              }
            }
            // se non ho il primo punto del mattino, lo aggiungo
            if (prevPmPoints.length > 0 && prevAmPoints.length > 0 && prevAmPoints[0].mins !== 0) {
              prevAmPoints.unshift({ mins: 0, level: prevPmPoints[prevPmPoints.length - 1].level });
            }

            //M.C. Definita a inizio ciclo
            //let curDailyPofile = this.lookupDailyProfile(curDay);

            if (curDailyPofile) {
              // M. C. Richiamata a inizio ciclo
              //let curPoints = getPointsFromDailyProfile(curDailyPofile, lat, lng, curDay, legacyTimer);

              // Controllo se il profilo è cambiato rispetto al giorno precedente
              //if (prevDailyPofile.id != curDailyPofile.id) {
                //prevPoints = curPoints;
                //prevAmPoints[0] = { mins: 0, level: prevPmPoints[prevPmPoints.length - 1].level}
              //}

              // considero i punti della sera del giorno corrente (abstime > 0)
              for (let curPoint of curPoints) {
                if (curPoint.x <= 0) {
                  let p = { mins: curPoint.x + 1440, level: curPoint.y };
                  if (!pmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                    pmPoints.push(p);
                  }
                }
              }
            }

            // ultimo punto
            if (pmPoints.length > 0 && pmPoints[pmPoints.length - 1].mins !== 1440) {
              pmPoints.push({ mins: 1440, level: pmPoints[pmPoints.length - 1].level });
            }

            // M.C. Salvo l'ultima transizione della giornata
            lastPointPrevDay = pmPoints.find(x => x.mins==1440);

            let points = [...prevAmPoints, ...pmPoints];
            */

            // merge dei punti contigui di pari potenza
            let mergedPoints = [];

            data[i] = {};
            if (points.length > 0) {
                let prec = null;
                for (let p = 0; p < points.length; p++) {
                    if (p === 0) {
                        prec = points[p];
                    }
                    //else if (points[p].level !== prec.level) {
                    // BUGFIX per giorno con transizione sempre uguale da inizio a fine
                    else if (points[p].level !== prec.level || p === (points.length - 1)) {
                        mergedPoints.push(points[p]);
                        let labeli = utils.formatAbsMin(prec.mins) + " -" + utils.formatAbsMin(points[p].mins);
                        this.powLevels[labeli] = prec.level;
                        if (!this.labels.includes(labeli)) {
                            this.labels.push(labeli);
                        }
                        data[i][labeli] = points[p].mins;
                        prec = points[p];
                    }
                }
                if (mergedPoints.length > 0 && mergedPoints[mergedPoints.length - 1].mins !== 1439) {
                    let label = utils.formatAbsMin(mergedPoints[mergedPoints.length - 1].mins) + " - 23:59";
                    this.powLevels[label] = mergedPoints[mergedPoints.length - 1].level;
                    if (!this.labels.includes(label)) {
                        this.labels.push(label);
                    }
                    data[i][label] = 1440;
                }

            }

            // dataset.push(points);

            prevDay = curDay;
            // curDay = moment(curDay).add(1, 'days');

            if (doDecimate) {
                curDay = moment(curDay).add(DECIMATE_DAYS, 'days');
            } else {
                curDay = moment(curDay).add(1, 'days');
            }

            i++;
        }

        this.labels.sort();

        for (let label of this.labels) {
            let values = [];
            let curDay = moment(selectedDay).startOf(view);
            let t = 0;
            while (curDay <= endDay) {

                values.push(data[t] ? data[t][label] : 0);

                if (doDecimate) {
                    curDay = moment(curDay).add(DECIMATE_DAYS, 'days');
                } else {
                    curDay = moment(curDay).add(1, 'days');
                }

                // curDay = moment(curDay).add(1, 'days');
                t++;
            }
            let color = PowerLevelColors[this.powLevels[label] + "%"];

            let dataset = {
                label,
                fill: true,
                backgroundColor: color,
                hoverBackgroundColor: color,
                borderColor: color,
                hoverBorderColor: color,
                borderWidth: 2,
                data: values
            };
            datasets.push(dataset);
        }

        return datasets;
    }

    getDatasetsOld() {
        const { selectedDay, view, legacyTimer } = this.props;
        const { lat, lng, timezone } = this.state;

        // range di date da graficare
        let startDay = moment(selectedDay).startOf(view);
        let curDay = moment(startDay);
        let endDay = moment(selectedDay).endOf(view);
        let prevDay = moment(startDay).subtract(1, 'days');
        //let lastPointPrevDay = undefined;

        let datasets = [];
        this.powLevels = {};
        this.labels = [];
        let data = [];
        let i = 0;

        let doDecimate = endDay.diff(startDay, 'days') > 30;

        while (curDay <= endDay) {

            let prevAmPoints = [];
            let prevPmPoints = [];
            let pmPoints = [];

            // selezione del profilo giornaliero applicabile al giorno corrente ed a quello precendente
            let prevDailyPofile = this.lookupDailyProfile(prevDay);

            //M.C. selezione del profilo giornaliero applicabile solo al giorno corrente
            let curDailyPofile = this.lookupDailyProfile(curDay);
            let curPoints = getPointsFromDailyProfile(curDailyPofile, lat, lng, timezone, curDay, legacyTimer)

            if (prevDailyPofile) {
                let prevPoints = getPointsFromDailyProfile(prevDailyPofile, lat, lng, timezone, curDay, legacyTimer);

                // suddivido i punti del giorno prima (abstime >= 0)
                /*if (prevDailyPofile.id != curDailyPofile.id) {
                  prevPoints = curPoints;
                  //prevAmPoints[0] = { mins: 0, level: lastPointPrevDay.level}
                }*/

                for (let prevPoint of prevPoints) {
                    let p = { mins: prevPoint.x, level: prevPoint.y };
                    if (prevPoint.x > 0) {
                        // punti del mattino
                        if (!prevAmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                            prevAmPoints.push(p);
                        }
                    }
                    else {
                        // punti del pomeriggio
                        p = { mins: prevPoint.x + 1440, level: prevPoint.y };
                        if (!prevPmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                            prevPmPoints.push(p);
                        }
                    }
                }
            }
            // se non ho il primo punto del mattino, lo aggiungo
            if (prevPmPoints.length > 0 && prevAmPoints.length > 0 && prevAmPoints[0].mins !== 0) {
                prevAmPoints.unshift({ mins: 0, level: prevPmPoints[prevPmPoints.length - 1].level });
            }

            //M.C. Definita a inizio ciclo
            //let curDailyPofile = this.lookupDailyProfile(curDay);

            if (curDailyPofile) {
                // M. C. Richiamata a inizio ciclo
                //let curPoints = getPointsFromDailyProfile(curDailyPofile, lat, lng, curDay, legacyTimer);

                // Controllo se il profilo è cambiato rispetto al giorno precedente
                //if (prevDailyPofile.id != curDailyPofile.id) {
                //prevPoints = curPoints;
                //prevAmPoints[0] = { mins: 0, level: prevPmPoints[prevPmPoints.length - 1].level}
                //}

                // considero i punti della sera del giorno corrente (abstime > 0)
                for (let curPoint of curPoints) {
                    if (curPoint.x <= 0) {
                        let p = { mins: curPoint.x + 1440, level: curPoint.y };
                        if (!pmPoints.find((el) => (el.mins === p.mins && el.level === p.level))) {
                            pmPoints.push(p);
                        }
                    }
                }
            }

            // ultimo punto
            if (pmPoints.length > 0 && pmPoints[pmPoints.length - 1].mins !== 1440) {
                pmPoints.push({ mins: 1440, level: pmPoints[pmPoints.length - 1].level });
            }

            // M.C. Salvo l'ultima transizione della giornata
            //lastPointPrevDay = pmPoints.find(x => x.mins == 1440);

            let points = [...prevAmPoints, ...pmPoints];

            // merge dei punti contigui di pari potenza
            let mergedPoints = [];

            data[i] = {};
            if (points.length > 0) {
                let prec = null;
                for (let p = 0; p < points.length; p++) {
                    if (p === 0) {
                        prec = points[p];
                    }
                    //else if (points[p].level !== prec.level) {
                    // BUGFIX per giorno con transizione sempre uguale da inizio a fine
                    else if (points[p].level !== prec.level || p === (points.length - 1)) {
                        mergedPoints.push(points[p]);
                        let labeli = utils.formatAbsMin(prec.mins) + " -" + utils.formatAbsMin(points[p].mins);
                        this.powLevels[labeli] = prec.level;
                        if (!this.labels.includes(labeli)) {
                            this.labels.push(labeli);
                        }
                        data[i][labeli] = points[p].mins;
                        prec = points[p];
                    }
                }
                if (mergedPoints.length > 0 && mergedPoints[mergedPoints.length - 1].mins !== 1440) {
                    let label = utils.formatAbsMin(mergedPoints[mergedPoints.length - 1].mins) + " - 24:00";
                    this.powLevels[label] = mergedPoints[mergedPoints.length - 1].level;
                    if (!this.labels.includes(label)) {
                        this.labels.push(label);
                    }
                    data[i][label] = 1440;
                }

            }

            // dataset.push(points);

            prevDay = curDay;
            // curDay = moment(curDay).add(1, 'days');

            if (doDecimate) {
                curDay = moment(curDay).add(DECIMATE_DAYS, 'days');
            } else {
                curDay = moment(curDay).add(1, 'days');
            }

            i++;
        }

        this.labels.sort();

        for (let label of this.labels) {
            let values = [];
            let curDay = moment(selectedDay).startOf(view);
            let t = 0;
            while (curDay <= endDay) {

                values.push(data[t] ? data[t][label] : 0);

                if (doDecimate) {
                    curDay = moment(curDay).add(DECIMATE_DAYS, 'days');
                } else {
                    curDay = moment(curDay).add(1, 'days');
                }

                // curDay = moment(curDay).add(1, 'days');
                t++;
            }
            let color = PowerLevelColors[this.powLevels[label] + "%"];

            let dataset = {
                label,
                fill: true,
                backgroundColor: color,
                hoverBackgroundColor: color,
                borderColor: color,
                hoverBorderColor: color,
                borderWidth: 2,
                data: values
            };
            datasets.push(dataset);
        }

        return datasets;

    }

    /**
     *
     */
    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <ClipLoader
                    style={{ flex: 1 }}
                    sizeUnit={"px"}
                    size={88}
                    margin={"5px"}
                    color={'#ddd'}
                    loading={loading}
                />
            );
        }
        else {

            let datasets = this.getDatasets();
            let labels = this.getLabels();

            let chartData = {
                labels,
                datasets
            };
            /*let chartData = {
              datasets: [
                {
                  label: 'Tr',
                  data: [
                    {x: 1, y: 400, r: 10},
                    {x: 1, y: 500, r: 5},
                    {x: 1, y: 600, r: 10},
                    {x: 2, y: 400, r: 10},
                    {x: 2, y: 500, r: 5},
                    {x: 3, y: 600, r: 10},
                  ]
                }
              ]
            };*/
            let chartOptions = this.getChartOptions();

            return (
                <Bar
                    key={'bar'}
                    data={chartData}
                    options={chartOptions}
                />
            )
            /* Test con grafico a bolle
            return (
              <Bubble
                key={'bar'}
                data={chartData}
                options={chartOptions}
              />
            )
            */

        }
        //return null;
    }

    getLabels() {
        const { view, selectedDay } = this.props;
        let labels = [];

        let curDay = moment(selectedDay).startOf(view);
        let endDay = moment(selectedDay).endOf(view);

        // Gestione locals
        let language = localStorage.getItem('i18nextLng');
        curDay.locale(language);

        let doDecimate = endDay.diff(curDay, 'days') > 30;

        while (curDay <= endDay) {

            switch (view) {
                case 'week':
                    labels.push(curDay.format('dddd'));
                    break;
                case 'month':
                    labels.push(curDay.format('D'));
                    break;
                case 'year':
                    labels.push(curDay.format('DD/MM'));
                    break;
                default: break;
            }

            if (doDecimate) {
                curDay = moment(curDay).add(DECIMATE_DAYS, 'days');
            } else {
                curDay = moment(curDay).add(1, 'days');
            }
        }

        // if (view==='week'){
        //   // loop sui giorni della settimana
        // }
        // else if (view==='month'){
        //   // loop sui giorni del mese
        //   labels = [];
        // }
        // else if (view==='year'){
        //   // loop sui giorni dell'anno

        // }

        return labels;
    }

}

export default ZoneConfigurationProgrammingChart = withTranslation()(ZoneConfigurationProgrammingChart);

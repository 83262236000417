import React from "react";
import ReactTable from "react-table";
import Moment from 'react-moment';
import Circle from 'assets/img/Circle';
import {
    Row,
    Col,
    Input,
} from "reactstrap";
import "../../assets/css/react-table.css";
import Bulb from 'assets/img/bulb.jsx';
import Warning from 'assets/img/warning.jsx';
import Check from 'assets/img/check.jsx';
import Bell from 'assets/img/bell-53.jsx';
import Nouislider from "react-nouislider";
import { withTranslation } from 'react-i18next';
import { strokeForBulb, fillForBulb } from "./Navigator";
import Switch from "react-bootstrap-switch";
import DiscreteValues from "../../constants/slider.constants";
import User from '../../entities/User';
import { roleConstants, DeviceTypes } from '../../constants';
import SignalBars from 'assets/img/signal-bars';
import LinkImg from 'assets/img/link-img';
import UnlinkImg from 'assets/img/unlink-img';
import Clock from 'assets/img/clock';

Moment.globalLocale = 'it';
Moment.globalFormat = 'DD/MM/YY HH:mm:ss';
Moment.globalElement = 'span';

// var Color = require('color');

class ItemStatus extends React.Component {

    constructor(props) {
        super(props);

        const { status, item } = props;

        // let periferal = null;
        // let actualLightState = 0;
        let cabinetPeriferals = {};

        if (item.type === 0 && status && status.cabinets && status.cabinets[item.id]) {
            // cabinet
            cabinetPeriferals = this.getCabinetPeriferals(props, null);
        }

        // else if (marker.type === 1 && status && status.lightpoints && status.lightpoints[marker.item.id]) {
        //     // lightpoint
        //     periferal = status.lightpoints[marker.item.id];
        //     actualLightState = periferal.statusReported.actualLightState;
        // }

        this.state = {
            // actualLightState,
            alert: null,
            ...cabinetPeriferals
        }

        this.renderItemStatus = this.renderItemStatus.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    assignPeriferalValue(props, periferal, value) {
        const { status, item } = props;
        const items = status.cabinets[item.id];
        const itemsKey = Object.keys(items);
        itemsKey.filter((key) => { return !key.startsWith('undefined') }).forEach((key) => {
            let item = items[key];
            if (item.periferal === periferal) {
                item.statusReported.value = value;
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        let { status, item, editingPeriferal } = nextProps;
        if (item.type === 0 && status && status.cabinets && status.cabinets[item.id]) {
            let cabinetPeriferals = this.getCabinetPeriferals(nextProps, editingPeriferal);
            this.setState({
                // actualLightState,
                ...cabinetPeriferals
            });
        }
        // else {
        //     if (marker.type === 1 && status && status.lightpoints && status.lightpoints[marker.item.id]) {
        //         periferal = status.lightpoints[marker.item.id];
        //         if (nextProps.userDidCancel) {
        //             actualLightState = periferal.statusReported.actualLightState
        //         }
        //     }
        // }

    }

    getValueDesired(item) {
        if (item.statusReported && item.statusReported !== null && item.statusDesired !== null && (item.statusDesired && item.statusDesired.timestamp > item.statusReported.timestamp)) {
            return item.statusDesired.value;
        }
        return null;
    }

    getCabinetPeriferals(props) {
        const { status, item, t, editingPeriferal, i18n } = props;
        const items = status.cabinets[item.id];
        const itemsKey = Object.keys(items);

        let maxTimestamp = 0;
        let dataIngressi = [];
        let dataUscite = [];
        let dataGruppi = [];
        let dataWattmetri = [];
        let dataPlant = [];
        // let dataReteMobile = [];

        itemsKey.filter((key) => { return !key.startsWith('undefined') }).forEach((key) => {
            let item = items[key];
            let valueDesired = this.getValueDesired(item);
            let pendingStatus = (valueDesired !== null);

            if (editingPeriferal && (editingPeriferal.periferal === item.periferal)) {
                // se sto editando la periferica sono sicuramente in stato pendente
                pendingStatus = true;
            }

            if (item.statusReported && item.statusReported.timestamp > maxTimestamp) {
                maxTimestamp = item.statusReported.timestamp;
            }

            const parts = key.split('/');

            if (parts[0] === 'DIG-IN') {
                if (item.statusReported && typeof (item.statusReported.status) !== 'undefined') {
                    dataIngressi.push({
                        type: parts[0],
                        code: parts[1],
                        name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                        status: item.statusReported && item.statusReported.statusLabel,
                        value: item.statusReported && item.statusReported.status,
                        pendingStatus
                    });
                }
            }
            else if (parts[0] === 'AN-IN') {
                dataIngressi.push({
                    type: parts[0],
                    code: parts[1],
                    name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                    status: item.statusReported && item.statusReported.status,
                    value: item.statusReported && item.statusReported.value,
                    value_u: item.statusReported && item.statusReported.value_u,
                    pendingStatus
                });
            }
            else if (parts[0] === 'DIG-OUT') {
                if (item.statusReported && typeof (item.statusReported.outstatus) !== 'undefined') {
                    let value = pendingStatus ? valueDesired : item.statusReported.outstatus;
                    dataUscite.push({
                        type: parts[0],
                        code: parts[1],
                        name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                        status: item.statusReported && item.statusReported.statusLabel,
                        value,
                        pendingStatus,
                        item
                    });
                }
            }
            else if (parts[0] === 'AN-OUT') {
                let value = pendingStatus ? valueDesired : item.statusReported.value;
                dataUscite.push({
                    type: parts[0],
                    code: parts[1],
                    name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                    status: item.statusReported && item.statusReported.status,
                    value,
                    value_u: item.statusReported && item.statusReported.value_u,
                    item,
                    pendingStatus
                });
            }
            else if (parts[0] === 'K1LO') {
                if (item.statusReported && item.statusReported.status) {
                    dataWattmetri.push({
                        type: parts[0],
                        code: parts[1],
                        name: t('Potenza attiva tot.'),
                        status: item.statusReported && item.statusReported.status,
                        value: item.statusReported && item.statusReported.pacttot,
                        value_u: item.statusReported && item.statusReported.pacttotu,
                        pendingStatus
                    });

                    dataWattmetri.push({
                        type: parts[0],
                        code: parts[1],
                        name: t('Energia consumata'),
                        status: item.statusReported && item.statusReported.status,
                        value: item.statusReported && item.statusReported.eacttot,
                        value_u: item.statusReported && item.statusReported.eacttotu,
                        pendingStatus
                    });
                }
            }
            // else if (parts[0] === 'KOS') {
            //     dataReteMobile.push({
            //         type: parts[0],
            //         name: parts[1] + (item.label ? ' ' + item.label : ""),
            //         status: item.statusReported && item.statusReported.status,
            //         value: item.statusReported && item.statusReported.p_norm,
            //         value_u: ""
            //     });
            // }
            else if (parts[0] === 'ISTATE') {
                // STATO DEI GRUPPI
                if (item.statusReported && typeof (item.statusReported.status) !== 'undefined') {
                    let value = pendingStatus ? valueDesired : item.statusReported.value;
                    if (parts[1] === "0010") {
                        if (parts[1] === "0010") {
                            dataPlant.push({
                                type: parts[0],
                                code: parts[1],
                                name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                                status: item.statusReported && item.statusReported.status,
                                value: (item.statusReported.value === 0 ? 0 : 1),
                                item,
                                pendingStatus
                            });
                        }
                    }
                    if (parts[1].startsWith("001") && parts[1] !== "0010") {
                        dataGruppi.push({
                            type: parts[0],
                            code: parts[1],
                            name: (item.label ? (i18n.language in item.label ? (item.label[i18n.language] !== "" ? item.label[i18n.language] : parts[1]) : (item.label['en'] !== "" ? item.label['en'] : parts[1])) : parts[1]),
                            status: item.statusReported && item.statusReported.status,
                            value,
                            item,
                            pendingStatus
                        });
                    }
                }
            }
        });

        dataIngressi.sort((a, b) => a.type === b.type ?
            a.code.toString().localeCompare(b.code) :
            b.type.toString().localeCompare(a.type));

        dataUscite.sort((a, b) => a.type === b.type ?
            a.code.toString().localeCompare(b.code) :
            b.type.toString().localeCompare(a.type));

        dataWattmetri.sort((a, b) => a.type === b.type ?
            a.code.toString().localeCompare(b.code) :
            a.type.toString().localeCompare(b.type));

        dataGruppi.sort((a, b) => a.type === b.type ?
            a.code.toString().localeCompare(b.code) :
            a.type.toString().localeCompare(b.type));

        return {
            maxTimestamp,
            dataIngressi,
            dataUscite,
            dataWattmetri,
            dataGruppi,
            dataPlant
        }
    }

    renderCell(cellInfo) {
        const row = cellInfo.row;
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span style={{ fontWeight: "bold" }} >{cellInfo.value}</span>{row._original.uom && <span style={{}}>&nbsp;{row._original.uom}</span>}</div>
        );
    }

    renderItem(cellInfo) {

        const { t, handleChangePeriferalStatus, handlePeriferalChangeStatusDesired, handleStartEditingPeriferal, handleCancelEditingPeriferal, item } = this.props;
        const row = cellInfo.row;

        // if (row._original.item){
        //     row.value+( row._original.pendingStatus?" pending":""),row._original.item.statusReported,row._original.item.statusDesired);
        // }

        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);
        let uom = cellInfo.row._original.value_u;

        if (uom) {
            switch (uom) {
                case "_'C": uom = "°C"; break;
                case "d_V": uom = "V"; break;
                case "d_I": uom = "V"; break;
                default: break;
            }
        }

        if (row._original.type === 'DIG-IN') {
            return (
                <div className="status-table-value" style={{ textAlign: "center" }}>
                    {row.value === 1 && <Circle title="ON" height={24} width={24} fill="green" stroke="lightgray" strokeWidth={1} />}
                    {row.value === 0 && <Circle title="OFF" height={24} width={24} fill="white" stroke="lightgray" strokeWidth={1} />}
                </div>
            )
        }
        // si possono cambiare i valori le uscite digitali
        else if (row._original.type === 'DIG-OUT' || (row._original.type === 'ISTATE' && (row._original.code === "0010"))) {
            let wrapperClass = "";

            if (row._original.pendingStatus) {
                wrapperClass = "dig-out";
            }
            else {
                wrapperClass = row.value === 1 ? 'on-color' : 'off-color';
            }

            // Controllo del tipo di periferica per impostazione valore di accensione
            let onValue = 1;

            if(row._original.type === 'ISTATE') {
                onValue = 100;
            }

            return (
                <div className="status-table-value">
                    <Switch
                        wrapperClass={wrapperClass}
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        // onColor = { "green" }
                        // offColor = { "lightgray" }
                        disabled={isGuest}
                        onChange={(el, state) => {
                            let editingPeriferal = {
                                type: item.type,
                                id: item.id,
                                periferal: row._original.item.periferal,
                                statusDesired: row._original.item.statusDesired !== null && row._original.item.statusDesired !== undefined ?
                                    JSON.parse(JSON.stringify(row._original.item.statusDesired))
                                    : null
                            };

                            handleStartEditingPeriferal(editingPeriferal);
                            handlePeriferalChangeStatusDesired({
                                type: item.type,
                                id: item.id,
                                periferal: row._original.item.periferal,
                                value: (state ? onValue : 0)
                            });
                            handleChangePeriferalStatus(row._original.item, (state ? onValue : 0));
                        }}
                        value={row.value === 1}
                    />
                </div>
            );
        }
        // si possono cambiare i valori dei gruppi
        else if ((row._original.type === 'ISTATE' && !(row._original.code === "0010"))) {
            return (
                <div className="status-table-value">
                    <div style={{ display: "flex", alignItems: "center", }}>
                        <span className={"slider " + (row._original.pendingStatus ? "slider-primary" : "slider-neutral")} style={{ flex: 1, paddingLeft: "15px", paddingRight: "15px" }}>
                            <Nouislider
                                start={[parseInt(row.value, 10)]}
                                connect={[true, false]}
                                snap={true}
                                range={DiscreteValues.legacy}
                                disabled={isGuest}
                                onStart={() => {
                                    let editingPeriferal = {
                                        type: item.type,
                                        id: item.id,
                                        periferal: row._original.item.periferal,
                                        statusDesired: row._original.item.statusDesired !== null && row._original.item.statusDesired !== undefined ?
                                            JSON.parse(JSON.stringify(row._original.item.statusDesired))
                                            : null
                                    };
                                    handleStartEditingPeriferal(editingPeriferal);
                                }}
                                onSlide={(value) => {
                                    handlePeriferalChangeStatusDesired({
                                        type: item.type,
                                        id: item.id,
                                        periferal: row._original.item.periferal,
                                        value: value[0]
                                    });
                                }}
                                onChange={(value) => {
                                    let val = parseInt(value[0], 10);
                                    handleChangePeriferalStatus(row._original.item, val);
                                }}
                            /></span>
                        <span style={{ flex: 1, textAlign: "center" }}>
                            {(row.value === 0 ? t("SPENTO") : (row.value === 100 ? t("ACCESO") : parseInt(row.value, 10) + " %"))}
                        </span>

                    </div>

                </div>
            );
        }
        else if (row._original.type === 'AN-OUT') {
            // si possono cambiare i valori delle uscite analogiche
            return (
                <div className="status-table-value">
                    <Input
                        className={row._original.pendingStatus ? "an-out" : ""}
                        type="number"
                        min="0"
                        value={row.value}
                        readOnly={isGuest}
                        onFocus={() => {

                            let editingPeriferal = {
                                type: item.type,
                                id: item.id,
                                periferal: row._original.item.periferal,
                                statusDesired: row._original.item.statusDesired !== null && row._original.item.statusDesired !== undefined ?
                                    JSON.parse(JSON.stringify(row._original.item.statusDesired))
                                    : null
                            };

                            handleStartEditingPeriferal(editingPeriferal);

                        }}
                        onBlur={() => {
                            if (row.value === row._original.item.statusReported.value) {
                                handleCancelEditingPeriferal();
                            } else {
                                handleChangePeriferalStatus(row._original.item, row.value);
                            }
                        }}
                        onChange={(e) => {
                            const { value } = e.target;

                            // let { dataUscite } = this.state;
                            // dataUscite[cellInfo.index].value = value;
                            // this.setState({
                            //     dataUscite
                            // });

                            handlePeriferalChangeStatusDesired({
                                type: item.type,
                                id: item.id,
                                periferal: row._original.item.periferal,
                                value
                            });

                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                if (row.value < 0) {
                                    return;
                                }
                                handleChangePeriferalStatus(row._original.item, row.value);
                            }
                        }}
                    />
                </div>
            );
        }
        else {
            return (
                <div className="status-table-value">{cellInfo.value} {uom && (<span style={{ fontWeight: "normal", color: 'gray' }}>&nbsp;{uom}</span>)}</div>
            );
        }
    }

    renderItemStatus(cellInfo) {
        return <div className="pull-right">{this.renderSyraState(cellInfo.value, 24)}</div>;

    }

    renderCabinet(periferals) {
        const { t, item } = this.props;
        const {
            maxTimestamp,
            dataIngressi,
            dataUscite,
            dataGruppi,
            dataWattmetri,
            dataPlant
        } = this.state;

        const columns = [
            {
                columns: [
                    {
                        accessor: "name",
                        width: 150
                    },
                    {
                        accessor: "value",
                        Cell: this.renderItem,
                        width: 120
                    },
                    {
                        accessor: "status",
                        Cell: this.renderItemStatus,
                        width: 40
                    },
                ]
            }
        ];

        let strength = 0;
        let connected = false;
        let modulesDevice = [];
        if (periferals.connection) {
            strength = periferals.connection.sgn;
            connected = periferals.connection.health === 'connect';
            modulesDevice = Object.keys(periferals);
        }

        return (

            <div className={this.props.className} style={{ maxHeight: "480px" }}>

                <div className="status-top-bar" >

                    <div className="status-top-bar-wrapper" >
                        {/* <span className="" style={{ fontSize: 10 }}><Moment unix>{maxTimestamp}</Moment></span> */}
                        {/* <span className="status-timestamp">{ periferal.periferal }</span>                     */}

                        {maxTimestamp > 0 ?
                            <span className="status-timestamp"><Clock fill={"darkgray"} stroke={"darkgray"} strokeWidth={1} width={9} height={9} />&nbsp;<Moment unix>{maxTimestamp}</Moment></span>
                            :
                            <span className="status-timestamp-ko">{t("In attesa dello stato")}</span>
                        }

                    </div>

                    {(
                        item.deviceType &&
                        item.deviceType === DeviceTypes.ANDROS &&
                        modulesDevice.includes("MODEM3G/0006") &&
                        strength > 0 &&
                        connected
                    ) &&
                        <span className="signal-strength">
                            <SignalBars strength={strength} title={"Signal Strength: " + strength} step={6} fill={"black"} stroke={"black"} strokeWidth={1} width={30} height={25} />
                        </span>
                    }

                    {!connected &&
                        <span className="signal-link"><UnlinkImg fill={"darkgray"} stroke={"darkgray"} strokeWidth={1} width={30} height={25} /></span>
                    }
                    {connected &&
                        <span className="signal-link"><LinkImg fill={"black"} stroke={"black"} strokeWidth={1} width={30} height={25} /></span>
                    }

                </div>

                {dataPlant.length > 0 &&
                    <div><p className="cabinet-block-title" >{t("IMPIANTO")}</p>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            resizable={false}
                            pageSize={dataPlant && dataPlant.length}
                            columns={columns}
                            data={dataPlant}
                            className="info-window-react-table"
                            TheadComponent={props => null}
                        />
                    </div>
                }

                {dataIngressi.length > 0 &&
                    <div><p className="cabinet-block-title" >{t("INGRESSI")}</p>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            resizable={false}
                            pageSize={dataIngressi && dataIngressi.length}
                            columns={columns}
                            data={dataIngressi}
                            className="info-window-react-table"
                            TheadComponent={props => null}
                        />
                    </div>
                }
                {dataUscite.length > 0 &&
                    <div><p className="cabinet-block-title">{t("USCITE")}</p>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            pageSize={dataUscite && dataUscite.length}
                            columns={columns}
                            data={dataUscite}
                            className="info-window-react-table"
                            TheadComponent={props => null}
                        />
                    </div>
                }

                {dataGruppi.length > 0 &&

                    <div><p className="cabinet-block-title">{t("GRUPPI")}</p>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            pageSize={dataGruppi && dataGruppi.length}
                            columns={columns}
                            data={dataGruppi}
                            className="info-window-react-table"
                            TheadComponent={props => null}
                        />
                    </div>
                }

                {/* <p className="cabinet-block-title">{t("RETE 3G")}</p>
                <ReactTable
                    showPagination={false}
                    sortable={false}
                    pageSize={dataReteMobile && dataReteMobile.length}
                    columns={columns}
                    data={dataReteMobile}
                    className="info-window-react-table"
                    TheadComponent={props => null}
                /> */}

                {dataWattmetri.length > 0 &&
                    <div>
                        <p className="cabinet-block-title">{t("WATTMETRI")}</p>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            pageSize={dataWattmetri && dataWattmetri.length}
                            columns={columns}
                            data={dataWattmetri}
                            className="info-window-react-table"
                            TheadComponent={props => null}
                        />
                    </div>
                }

            </div>
        );
    }

    renderSyraState(status, width = 48) {
        if (status && status.startsWith('READY')) {
            return (
                <Check title={status} width={width} fill="green" stroke="darkGreen" strokeWidth={1} />
            );
        }
        else if (status && status === 'NOT_CONNECTED') {
            return (
                <Warning title={status} height={width} width={width} fill="lightGray" stroke="gray" strokeWidth={1} />
            );
        }
        else return (
            <Warning title={status} height={width} width={width} fill="red" stroke="darkred" strokeWidth={1} />
        );
    }

    renderAlarm(syraState, value) {
        if (syraState && syraState.startsWith('READY')) {
            if (value === 1)
                return (
                    <Bell title={syraState} height={40} width={35} fill="red" stroke="darkred" strokeWidth={1} />
                );

        }
        return <Bell title={syraState} height={40} width={35} fill="lightGray" stroke="gray" strokeWidth={1} />
    }

    renderLightpoint(periferal) {

        const { t, status, item, editingPeriferal, handleChangePeriferalStatus, handlePeriferalChangeStatusDesired, handleStartEditingPeriferal } = this.props;
        // const { actualLightState } = this.state;

        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);

        const columns = [
            { Header: null, accessor: "name", width: 120 },
            { Header: null, accessor: "value", Cell: this.renderCell },
        ];

        const lp = status.lightpoints[item.id];
        let valueDesired = this.getValueDesired(lp);
        let pendingStatus = (valueDesired !== null);
        if (editingPeriferal && (editingPeriferal.periferal === lp.periferal)) {
            // se sto editando la periferica sono sicuramente in stato pendente
            pendingStatus = true;
        }
        let value = null;
        if (pendingStatus) {
            value = valueDesired;
        } else {
            value = lp.statusReported && lp.statusReported.actualLightState;
        }

        let data = [
            // { value: item.periferal, name: "Nome" },
            { value: periferal.statusReported && (periferal.statusReported.supplyVoltage), name: t("Tensione"), uom: "V" },
            { value: periferal.statusReported && (periferal.statusReported.supplyCurrent), name: t("Corrente"), uom: "A" },
            { value: periferal.statusReported && (periferal.statusReported.activePower), name: t("Potenza"), uom: "W" },
            { value: periferal.statusReported && periferal.statusReported.powerFactor, name: t("Fattore di potenza"), uom: "" },
            { value: periferal.statusReported && (periferal.statusReported.temperature), name: t("Temperatura"), uom: "°C" },
            { value: (periferal.statusReported ? periferal.statusReported.operatingHours + periferal.statusReported.operatingDimmingHours : null), name: t("Ore vita lampada"), uom: "h" },
        ];

        let colWidth = 6;

        if (periferal.statusReported) {
            if (periferal.statusReported.allarm1 === 1 && periferal.statusReported.allarm2 === 1) {
                colWidth = 3;
            }
            else if (periferal.statusReported.allarm1 === 1 || periferal.statusReported.allarm2 === 1) {
                colWidth = 4;
            }
        }

        let strength = 0;
        let connected = false;
        if (periferal.connection) {
            strength = periferal.connection.sgn;
            connected = periferal.connection.health === 'connect';
        }

        // if ( connection

        return (
            <div className={"status-popup-wrapper " + this.props.className}  >
                <Row>
                    <Col className="status-top-bar" >
                        <div className="status-top-bar-wrapper" >
                            <span className="status-timestamp">{periferal.periferal}</span>
                            {periferal.statusReported ?
                                <span className="status-timestamp"><Clock fill={"darkgray"} stroke={"darkgray"} strokeWidth={1} width={9} height={9} />&nbsp;<Moment unix>{periferal.statusReported.timestamp}</Moment></span>
                                :
                                <span className="status-timestamp-ko">{t("In attesa dello stato")}</span>
                            }

                        </div>
                        {item.deviceType && item.deviceType === DeviceTypes.RADIOCONTROLLER && connected &&
                            <span className="signal-strength">
                                <SignalBars strength={strength} title={"Signal Strength: " + strength} step={6} fill={"black"} stroke={"black"} strokeWidth={1} width={30} height={25} />
                            </span>
                        }

                        {item.deviceType && item.deviceType === DeviceTypes.RADIOCONTROLLER && !connected &&
                            <span className="signal-link"><UnlinkImg fill={"darkgray"} stroke={"darkgray"} strokeWidth={1} width={30} height={25} /></span>
                        }
                        {item.deviceType && item.deviceType === DeviceTypes.RADIOCONTROLLER && connected &&
                            <span className="signal-link"><LinkImg fill={"black"} stroke={"black"} strokeWidth={1} width={30} height={25} /></span>
                        }
                        {/* { item.deviceType && item.deviceType===DeviceTypes.RADIOCONTROLLER && !connected &&
                            <span className="signal-strength"><UnlinkImg fill={ "darkgray" } stroke = { "darkgray" } strokeWidth = { 1 } width = { 30 } height = { 25 }/></span>
                        } */}

                        {this.state.alert}
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col md={colWidth} className="lightpoint-block-column" >
                        <Bulb height={60} width={40} fill={fillForBulb(periferal.statusReported)} stroke={strokeForBulb(periferal.statusReported)} strokeWidth={1} />
                        <div className={"slider " + (pendingStatus ? "slider-primary" : "slider-neutral") + " lightpoint-block-slider"} >
                            <Nouislider
                                // start={[actualLightState]}
                                start={[parseInt(value, 10)]}
                                connect={[true, false]}
                                snap={true}
                                range={DiscreteValues.legacy}
                                disabled={isGuest}
                                onStart={() => {
                                    let editingPeriferal = {
                                        type: item.type,
                                        id: item.id,
                                        periferal,
                                        statusDesired: periferal.statusDesired !== null && periferal.statusDesired !== undefined ?
                                            JSON.parse(JSON.stringify(periferal.statusDesired))
                                            : null
                                    };
                                    handleStartEditingPeriferal(editingPeriferal);
                                }}
                                onSlide={(value) => {
                                    //this.setState({ actualLightState: value[0] });
                                    handlePeriferalChangeStatusDesired({
                                        type: item.type,
                                        id: item.id,
                                        periferal,
                                        value: value[0]
                                    });
                                }}
                                onChange={(value) => {
                                    let val = parseInt(value[0], 10);
                                    handleChangePeriferalStatus(periferal, val);
                                }}

                            />
                        </div>
                        {<span style={{ paddingTop: 10, paddingBottom: 10 }}>{
                            (value === 0 ? t("SPENTO") : (value === 100 ? t("ACCESO") : parseInt(value, 10) + " %"))
                        }</span>}
                    </Col>
                    <Col md={colWidth} className="lightpoint-block-column" >
                        {this.renderSyraState(periferal.statusReported && periferal.statusReported.syraState)}
                        {periferal.statusReported && periferal.statusReported.syraState &&
                            <div style={{ paddingTop: 5 }}>{t(periferal.statusReported.syraState)}</div>
                        }
                    </Col>
                    {(periferal.statusReported && periferal.statusReported.allarm1 === 1) &&
                        <Col md={colWidth} className="lightpoint-block-column" >
                            {this.renderAlarm(periferal.statusReported.syraState, periferal.statusReported.allarm1)}
                            <div style={{ paddingTop: 5 }}>{t("ALLARME") + " 1"}</div>
                        </Col>
                    }
                    {(periferal.statusReported && periferal.statusReported.allarm2 === 1) &&
                        <Col md={colWidth} className="lightpoint-block-column" >
                            {this.renderAlarm(periferal.statusReported.syraState, periferal.statusReported.allarm2)}
                            <div style={{ paddingTop: 5 }}>{t("ALLARME") + " 2"}</div>
                        </Col>
                    }

                </Row>

                <Row>
                    <Col md={12} style={{ padding: 0 }}>
                        <ReactTable
                            showPagination={false}
                            sortable={false}
                            pageSize={data && data.length}
                            columns={columns}
                            data={data}
                            TheadComponent={props => null}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { status, item, t } = this.props;
        if (item.type === 0 && status && status.cabinets && status.cabinets[item.id] && item.device && item.device.length > 0) {
            return this.renderCabinet(status.cabinets[item.id]);
        }
        else {
            // lightpoint
            if (status && status.lightpoints && status.lightpoints[item.id]) {
                return this.renderLightpoint(status.lightpoints[item.id]);
            }
            else if (item.device) {
                return (<div className={this.props.className}>{t("In attesa dello stato")}</div>);
            }
        }
        return (
            <div className={this.props.className}>{t("Dispositivo di controllo non installato")}</div>
        );
    }

}

// function mapStateToProps(state) {
//     const { authentication, loopback } = state;
//     return {
//         authentication,
//         configurations: loopback.configurations
//     };
// }

// export default ItemStatus = withTranslation()(connect(mapStateToProps)(ItemStatus));
export default ItemStatus = withTranslation()(ItemStatus);

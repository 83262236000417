import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Container,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Alert
} from "reactstrap";
import { authenticationActions } from "actions";
import { Button } from "components";
//import nowLogo from "assets/img/minosx-system-brand.png";
import nowLogo from "assets/img/xperience-brand.png";
import bgImage from "assets/img/login-page-bg.jpg";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { TimelineLite, Linear } from 'gsap';
import { utils } from "../../helpers";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.props.dispatch(authenticationActions.logout());

        this.state = {
            username: '',
            password: '',
            loginFormSubmitted: false,
            userHasDismissedError: false
        };

        this.form = null
        this.setForm = (element) => {
            this.form = element
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ userHasDismissedError: true });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            loginFormSubmitted: true,
            userHasDismissedError: false
        });

        const { username, password } = this.state;
        const { dispatch } = this.props;

        if (username && password) {
            dispatch(authenticationActions.login(username, password));
        }
    }

    componentDidMount() {
        var animation = new TimelineLite()
        animation.to(this.form, .8, {
            opacity: 1,
            ease: Linear.easeInOut
        })
    }

    render() {
        const { loggingIn, error } = this.props;
        const { username, password } = this.state;

        return (
            <div>
                <div className="custom-full-page-content full-page-content">
                    <div className="custom-login-page login-page">
                        <Container>
                            <NotificationAlert ref="notificationAlert" />

                            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">

                                <form ref={this.setForm} style={{
                                    opacity: 0,
                                    position: 'relative'
                                }} onSubmit={this.handleSubmit}>

                                    {
                                        error && !this.state.userHasDismissedError && !loggingIn &&
                                        <Alert
                                            color="danger"
                                            className="alert-with-icon custom-alert"
                                            isOpen={(error && true) || false}
                                            toggle={this.onDismiss}
                                        >
                                            <span
                                                data-notify="icon"
                                                className="now-ui-icons ui-1_bell-53"
                                            />
                                            <span data-notify="message">{(function (error) {
                                                let message = "Errore sconosciuto!";
                                                if (error) {
                                                    // ATTENZIONE: error.status non è valorizzato quando il server risponde con un 503 (Service Temporarily Unavailable)
                                                    switch (error.status) {
                                                        case -101:
                                                            message = error.message;
                                                            break;
                                                        case 401:
                                                            message = "Utente sconosciuto!";
                                                            break
                                                        default:
                                                            if (!utils.isNetworkReachable()) {
                                                                message = "Offline. Impossibile accedere alla rete!"
                                                            }
                                                            else {
                                                                message = "Servizio momentaneamente non disponibile! (" + error.message + ")"
                                                            }
                                                            break
                                                    }
                                                }
                                                return message;
                                            }(error))}</span>
                                        </Alert>
                                    }

                                    <Card className="card-login card-plain">
                                        <CardHeader>
                                            <div className="logo-container">
                                                <img src={nowLogo} alt="now-logo" />
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <InputGroup
                                                size="lg"
                                                className={"no-border " + (this.state.firstnameFocus ? "input-group-focus" : "")}
                                            >
                                                <InputGroupAddon>
                                                    <i className="now-ui-icons users_circle-08" />
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    name="username"
                                                    placeholder="Utente"
                                                    onFocus={e => this.setState({ firstnameFocus: true })}
                                                    onBlur={e => this.setState({ firstnameFocus: false })}
                                                    value={username}
                                                    onChange={this.handleChange}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                size="lg"
                                                className={"no-border " + (this.state.lastnameFocus ? "input-group-focus" : "")}
                                            >
                                                <InputGroupAddon>
                                                    <i className="now-ui-icons ui-1_lock-circle-open" />
                                                </InputGroupAddon>

                                                <Input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    onFocus={e => this.setState({ lastnameFocus: true })}
                                                    onBlur={e => this.setState({ lastnameFocus: false })}
                                                    value={password}
                                                    onChange={this.handleChange}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                        <CardFooter>
                                            <Button
                                                color="primary"
                                                size="lg"
                                                block
                                                round
                                            >
                                                Entra
                                            </Button>
                                            <div className="text-center" style={{ position: 'relative' }}>
                                                {loggingIn &&
                                                    <img alt="" style={{
                                                        position: 'absolute',
                                                        left: '50%',
                                                        marginLeft: '-8px'
                                                    }} src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </form>

                            </Col>
                        </Container>
                    </div>
                </div>
                <div
                    className="custom-full-page-background full-page-background"
                    style={{ backgroundImage: "url(" + bgImage + ")" }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, loginErrorMessage } = state;
    const { loggingIn, error } = state.authentication;
    return {
        loggingIn,
        alert,
        loginErrorMessage,
        error
    };
}
const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };

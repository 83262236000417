import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import ChartLoader from '../../views/Components/ChartLoader'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  Bar,
  ComposedChart,
  Pie,
  PieChart,
  Cell,
  Brush,
  Label,
  AreaChart,
  ReferenceLine
} from 'recharts'
import { chartConstants } from '../../constants'
import moment from 'moment'

/*class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={5}
                    dx={0}
                    dy={0}
                    textAnchor="end"
                    transform="rotate(-60)">
                    {payload.value ? payload.value.split(' ')[0] + '...' : ''}
                </text>
            </g >
        );
    }
}*/

//const tabs = ['andros', 'wattmeter', 'digitalIO', 'analogicalIO', 'modem', 'multi'];
const color = '#094079'
const OFFSET_DATA = {
  value: 100,
  step: 10
}

class ChartDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      offset: OFFSET_DATA.value,
      step: OFFSET_DATA.step,
      startIndex: 0,
      data: [],
      interval: 0
    }

    this.brushOnChange = this.brushOnChange.bind(this)
    this.brushTickFormatter = this.brushTickFormatter.bind(this)
  }

  componentDidMount () {
    //let data = this.props.data;
  }

  componentDidUpdate (prevProps) {
    if (prevProps.loading && !this.props.loading) {
      if (this.props.data && this.props.data.length) {
        this.mountComponent()
      }
    }
  }

  mountComponent () {
    let data = [...this.props.data]
    //Per dataset molto piccoli (inferiori a 1000 elementi setto un intervallo di 1)
    let interval = data.length > 1000 ? Math.trunc(data.length / 10) : 1
    if (this.props.width === '100%') {
      //Se passo un interval come prop allora forzo il suo settaggio
      interval = data.length > 1000 ? Math.trunc(data.length / 25) : 1
    }
    let brushGap = data.length > 1000 ? Math.trunc(data.length / 100) : 1
    if (!this.props.showUtils) {
      //Utilizzo del Brush
      this.setState({
        data: data,
        interval: interval,
        brushGap: brushGap
      })
      return
    } else {
      //Prima dell'utilizzo del Brusch (pulsanti +,-,<,>)
      const { offsetData } = this.props
      let step = OFFSET_DATA.step
      let offset = OFFSET_DATA.value
      if (offsetData) {
        step = offsetData.step
        offset = offsetData.value
      }
      // let offset = this.state.offset;
      if (offset > data.length) {
        offset = data.length
      }
      let startIndex = Math.trunc(data.length / 2) - Math.trunc(offset / 2)
      data = data.slice(startIndex, offset + startIndex)
      if (!data) {
        data = []
      }
      this.setState({
        startIndex: startIndex,
        step: step,
        offset: offset,
        data: data,
        interval: interval
      })
    }
  }

  editOffset (val) {
    //Val ha valore 1 o -1. Nel primo caso incrementiamo l'offset, nel secondo caso lo decrementiamo
    let offset = this.state.offset + val * this.state.step
    let startIndex =
      Math.trunc(this.props.data.length / 2) - Math.trunc(offset / 2)
    this.setState(
      {
        offset: offset,
        startIndex: startIndex
      },
      () => this.updateData()
    )
  }

  editStartIndex (val) {
    const { offset } = this.state
    //Val ha valore 1 o -1. Nel primo caso incrementiamo l'indice di partenza, nel secondo caso lo decrementiamo
    let startIndex = this.state.startIndex + val * this.state.step
    if (val === -1 && startIndex < 0) {
      startIndex = 0
    } else if (val === 1 && startIndex + offset >= this.props.data.length) {
      startIndex = this.props.data.length - offset
    }
    this.setState(
      {
        startIndex: startIndex
      },
      () => this.updateData()
    )
  }

  updateData () {
    let { offset, startIndex } = this.state

    let data = [...this.props.data].slice(startIndex, offset + startIndex)
    this.setState({
      data: data
    })
  }

  getPlaceholder (loading) {
    const { t } = this.props
    return loading ? (
      <Col sm={12} className='nodata'>
        <ChartLoader loading={loading} />
      </Col>
    ) : (
      <Col sm={12} className='nodata'>
        <b>{t('charts.nodata.title')}</b>
        {t('charts.nodata.text')}
      </Col>
    )
  }

  getXAxis (dataKey, dataType = 'number') {
    if (this.state.data && this.state.data.length > 0) {
      return (
        <XAxis
          dataKey={dataKey}
          height={45}
          // ONLY FOR TYPE = NUMBER
          {...(dataType === 'number'
            ? {
                domain: ['auto', 'auto'],
                tickFormatter: unixTime =>
                  moment(unixTime).format('DD-MM-YYYY HH:mm:ss'),
                type: dataType
              }
            : {})}
        />
      )
    } else {
      return (
        <XAxis
          dataKey={dataKey}
          height={45}
          interval={0}
          // ONLY FOR TYPE = NUMBER
          {...(dataType === 'number'
            ? {
                domain: ['auto', 'auto'],
                tickFormatter: unixTime =>
                  moment(unixTime).format('DD-MM-YYYY HH:mm:ss'),
                type: dataType
              }
            : {})}
        />
      )
    }
  }
  getYAxis (label = null, isDouble = false) {
    const tmp = isDouble ? { yAxisId: 'left' } : {}
    return (
      <YAxis
        {...tmp}
        // domain={[dataMin => (Math.trunc(dataMin / 1.05)), dataMax => (Math.trunc(dataMax * 1.05))]}
        tickFormatter={tick => {
          if (this.props.yAxisUnit) {
            return `${tick}${this.props.yAxisUnit}`
          }
          return tick
        }}
      >
        {!label ? null : (
          <Label value={label} angle={-90} offset={0} dx={-30} />
        )}
      </YAxis>
    )
  }
  getYAxis2 (label = null) {
    return (
      <YAxis
        yAxisId='right'
        orientation='right'
        // domain={[dataMin => (Math.trunc(dataMin / 1.05)), dataMax => (Math.trunc(dataMax * 1.05))]}
        tickFormatter={tick => {
          if (this.props.yAxisUnit2) {
            return `${tick}${this.props.yAxisUnit2}`
          }
          return tick
        }}
      >
        {!label ? null : (
          <Label value={label} angle={-90} offset={0} dx={-30} />
        )}
      </YAxis>
    )
  }

  getTooltip () {
    return <Tooltip content={this.tooltipFormatter} />
  }

  tooltipFormatter = ({ active, payload, label }) => {
    const { t, translateRoot, legendLabels, tooltipTitle } = this.props
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <div className='title'>
            {tooltipTitle ? tooltipTitle : t('charts.legend')}
          </div>
          <div className='sub-title'>
            {moment(label).isValid()
              ? moment(label).format('DD-MM-YYYY HH:mm:ss')
              : label}
          </div>
          {payload.map((p, index) => {
            let field = p.dataKey
            if (legendLabels) {
              field = legendLabels[p.dataKey]
            } else if (translateRoot) {
              field = t(`charts.${translateRoot}.${p.dataKey}`)
            }
            return (
              <div className='info' key={`key-${label}-${index}`}>
                <b style={{ color: p.stroke }}>{field}</b>: {p.value}
              </div>
            )
          })}
          {/* <p className="label">{`${label} : ${payload[0].value}`}</p>
                    <p className="intro">{getIntroOfPage(label)}</p>
                    <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      )
    }
    return null
  }

  getLegend () {
    return (
      <Legend
        align='left'
        verticalAlign='bottom'
        // content={this.renderLegend}
        formatter={this.legendFormatter}
        margin={{ top: 0, left: 20, right: 0, bottom: 0 }}
      />
    )
  }

  legendFormatter = (value /*, entry*/) => {
    //const { } = entry;
    const { t, translateRoot, legendLabels } = this.props

    // return <span style={{ color }}>{value}</span>;
    if (legendLabels) {
      return <b style={{ color: '#2c2c2c' }}>{legendLabels[value]}</b>
    }
    if (translateRoot) {
      return (
        <b style={{ color: '#2c2c2c' }}>
          {t(`charts.${translateRoot}.${value}`)}
        </b>
      )
    }
    return <b style={{ color: '#2c2c2c' }}>{value}</b>
  }

  getBrush (dataKey) {
    if (this.props.showUtils) {
      return null
    }
    return (
      <Brush
        className='brush'
        dataKey={dataKey}
        height={15}
        y={295}
        // height={10}
        // y={315}
        x={62}
        stroke='#094079'
        onChange={this.brushOnChange}
        gap={this.state.brushGap}
        tickFormatter={() => ''}
        // onMouseLeave={this.brushOnChange}
      />
    )
  }

  brushOnChange (props) {
    // setTimeout(() => {
    //     const { start, end } = props;
    //     let diff = end - start;
    //     this.setState({
    //         interval: Math.trunc(diff / 10)
    //     })
    // }, 1000)
  }

  brushTickFormatter (value, dataKey) {}

  renderPieLabel = props => {
    const RADIAN = Math.PI / 180
    const { cx, cy, innerRadius, outerRadius, payload, index, endAngle } = props
    if (index !== 0 || payload.value === 0) {
      return ''
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    //Label posizionata all'interno e alla fine della cell
    // const x = cx + radius * Math.cos(-endAngle * RADIAN);
    // const y = cy + radius * Math.sin(-endAngle * RADIAN);
    //Label posizionata all'interno e al centro della cell
    // const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //Label posizionata alla fine e all'esterno della cell
    const x = cx + radius * 1.5 * Math.cos(-endAngle * RADIAN)
    const y = cy + radius * 1.5 * Math.sin(-endAngle * RADIAN)
    return (
      <text
        x={x}
        y={y}
        // fill={this.props.color}
        // fill={this.props.color}
        textAnchor='middle'
        dominantBaseline='central'
        fontWeight='bold'
      >
        {`${Math.round(payload.value * 100) / 100}${
          this.props.yAxisUnit ? this.props.yAxisUnit : ''
        }`}
        {/* {`${(percent * 100).toFixed(0)}%`} */}
      </text>
    )
  }

  getChart (data, type, metrics, colors) {
    const { t } = this.props
    switch (type) {
      case chartConstants.PIE_CHART_FULL: {
        const { data } = this.props;
        return (
          <ResponsiveContainer>
            <PieChart data={data}>
              <Pie
                isAnimationActive={false}
                dataKey='value'
                data={data}
                outerRadius={100}
                cx='50%'
                cy='50%'
                labelLine={true}
                label
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.PIE_CHART: {
        const { data, maxValue, yAxisUnit, withPieLabel } = this.props
        const pieProps = {
          // PIE LABEL
          ...(withPieLabel === true ? { label: this.renderPieLabel } : {})
        }
        return (
          <ResponsiveContainer>
            <PieChart data={data}>
              <Pie
                isAnimationActive={false}
                dataKey='value'
                startAngle={220}
                endAngle={-40}
                data={data}
                outerRadius={100}
                innerRadius={60}
                labelLine={false}
                {...pieProps}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    className={`pie-cell ${index === 0 ? 'colored' : ''}`}
                    fill={index === 0 ? this.props.color : ''}
                  />
                ))}
              </Pie>
              <g>
                <text
                  x={'20%'}
                  y={'85%'}
                  fill='#000'
                  textAnchor='middle'
                  dominantBaseline='central'
                >
                  {`0${yAxisUnit ? yAxisUnit : ''}`}
                </text>
              </g>
              <g>
                <text
                  x={'50%'}
                  y={'45%'}
                  textAnchor='middle'
                  dominantBaseline='central'
                  fontWeight='bold'
                  fontSize='1.2rem'
                >
                  {`${Math.round(data[0].value * 100) / 100}${
                    yAxisUnit ? yAxisUnit : ''
                  }`}
                </text>
              </g>
              <g>
                <text
                  x={'80%'}
                  y={'85%'}
                  fill='#000'
                  textAnchor='middle'
                  dominantBaseline='central'
                >
                  {`${maxValue}${yAxisUnit ? yAxisUnit : ''}`}
                </text>
              </g>
            </PieChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.BAR_CHART: {
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('tsMillis')}
              {this.getYAxis(t('Totale kW') + ' (' + t('Medio') + ')')}
              {this.getLegend()}
              {this.getTooltip()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Bar
                      dataKey={field}
                      fill={colors ? colors[index] : color}
                      key={`bar-${field}`}
                    />
                  )
                })}
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.BAR_CHART_KWH: {
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('tsMillis')}
              {this.getYAxis(t('Totale kW') + 'h (' + t('Medio') + ')')}
              {this.getLegend()}
              {this.getTooltip()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Bar
                      dataKey={field}
                      fill={colors ? colors[index] : color}
                      key={`bar-${field}`}
                    />
                  )
                })}
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.BAR_CHART_STATS_ANNUAL: {
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('month', 'string')}
              {this.getYAxis('kWh')}
              {this.getLegend()}
              {this.getTooltip()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Bar
                      dataKey={field}
                      fill={colors ? colors[index] : color}
                      key={`bar-${field}`}
                    />
                  )
                })}
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.BAR_CHART_STATS_ANNUAL_DELTA: {
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('month', 'string')}
              {this.getYAxis('kWh')}
              {this.getLegend()}
              {this.getTooltip()}
              {metrics &&
                metrics.map(field => {
                  return (
                    <Bar dataKey={field} key={`bar-${field}`} fill='#888'>
                      {data.map((entry, index) => (
                        <Cell
                          key={`bar-cel-${field}-${index}`}
                          fill={entry[field] >= 0 ? '#fd3333' : '#65c87a'}
                        />
                      ))}
                    </Bar>
                  )
                })}
              <ReferenceLine y={0} stroke='#000' />
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.BASIC_CHART: {
        const { data, yAxisUnit } = this.props
        return (
          <ResponsiveContainer>
            <div className='basic-chart'>{`${data}${yAxisUnit || ''}`}</div>
          </ResponsiveContainer>
        )
      }
      case chartConstants.HORIZONTAL_BAR_CHART: {
        return (
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              layout='vertical'
              margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 40
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              {/* {this.getXAxis('ts')}
                            {this.getYAxis('pActivTotal (Median)')} */}
              <XAxis type='number' />
              <YAxis dataKey='type' type='category' />
              {/* {this.getLegend()} */}
              {this.getTooltip()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Bar
                      dataKey={field}
                      fill={colors ? colors[index] : color}
                      key={`bar-${field}`}
                    />
                  )
                })}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.LINE_CHART: {
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('tsMillis')}
              {this.getYAxis()}
              {this.getTooltip()}
              {this.getLegend()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Line
                      dataKey={field}
                      stroke={colors ? colors[index] : color}
                      type={
                        this.props.lineType ? this.props.lineType : 'monotone'
                      }
                      strokeWidth={2}
                      dot={false}
                      key={`bar-${field}`}
                    />
                  )
                })}
              {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
              {/* <Scatter dataKey="cnt" fill="red" /> */}
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.AREA_CHART: {
        return (
          <ResponsiveContainer>
            <AreaChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('tsMillis')}
              {this.getYAxis()}
              {this.getTooltip()}
              {this.getLegend()}
              {metrics &&
                metrics.map((field, index) => {
                  return (
                    <Area
                      dataKey={field}
                      stroke={colors ? colors[index] : color}
                      fill={colors ? colors[index] : color}
                      type={
                        this.props.lineType ? this.props.lineType : 'monotone'
                      }
                      strokeWidth={2}
                      dot={false}
                      key={`bar-${field}`}
                    />
                  )
                })}
              {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
              {/* <Scatter dataKey="cnt" fill="red" /> */}
              {this.getBrush()}
            </AreaChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.COMPOSED_CHART: {
        const { chartTypes } = this.props
        if (chartTypes && metrics.length !== chartTypes.length) {
          return <div></div>
        }
        return (
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <CartesianGrid stroke='#f5f5f5' />
              {this.getXAxis('tsMillis')}
              {this.getYAxis(null, true)}
              {this.getYAxis2(null)}
              {this.getTooltip()}
              {this.getLegend()}
              {metrics &&
                metrics.map((field, index) => {
                  if (chartTypes[index] === chartConstants.LINE_CHART) {
                    return (
                      <Line
                        yAxisId='left'
                        dataKey={field}
                        stroke={colors ? colors[index] : color}
                        fill={colors ? colors[index] : color}
                        type={
                          this.props.lineType ? this.props.lineType : 'monotone'
                        }
                        strokeWidth={2}
                        dot={false}
                        key={`bar-${field}`}
                      />
                    )
                  }
                  if (chartTypes[index] === chartConstants.BAR_CHART) {
                    return (
                      <Bar
                        yAxisId='right'
                        dataKey={field}
                        fill={colors ? colors[index] : color}
                        key={`bar-${field}`}
                      />
                    )
                  }
                  if (chartTypes[index] === chartConstants.AREA_CHART) {
                    return (
                      <Area
                        yAxisId='right'
                        dataKey={field}
                        stroke={colors ? colors[index] : color}
                        fill={colors ? colors[index] : color}
                        type={
                          this.props.lineType ? this.props.lineType : 'monotone'
                        }
                        strokeWidth={2}
                        dot={false}
                        key={`bar-${field}`}
                      />
                    )
                  }
                  // return <div key={`key-chart-${index}`}></div>;
                  return null
                })}
              {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
              {/* <Scatter dataKey="cnt" fill="red" /> */}
              {this.getBrush()}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }
      case chartConstants.MODEM_INFO: {
        const { rows } = this.props
        return (
          <div className='table'>
            <div className='tbody'>
              <br />
              {rows.map((element, i) => {
                return (
                  <div
                    key={`key-row-${element.field}`}
                    className={`trow noevents`}
                  >
                    <div className={`th tc`} title={element.label}>
                      {element.label}
                    </div>
                    <div
                      className={`tc`}
                      title={
                        data[0] && data[0][element.field]
                          ? data[0][element.field]
                          : 'N/A'
                      }
                    >
                      {data[0] && data[0][element.field]
                        ? data[0][element.field]
                        : 'N/A'}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      case chartConstants.TABLE: {
        const { headers, tableID, selectedCode, handleRowClick } = this.props
        return (
          <div className='table'>
            <div className='theader'>
              {headers.map(h => {
                return (
                  <div
                    key={`key-header-${h.header}`}
                    className='tc'
                    style={{ width: h.width }}
                  >
                    {h.header}
                  </div>
                )
              })}
            </div>
            <div className='tbody'>
              {data.map((d, i) => {
                return (
                  <div
                    key={`key-row-${i}`}
                    className={`trow
                                    ${
                                      selectedCode !== undefined &&
                                      selectedCode === d.code
                                        ? 'selected'
                                        : ''
                                    }
                                    ${handleRowClick ? '' : 'noevents'}`}
                    onClick={() => {
                      if (handleRowClick) {
                        handleRowClick(tableID, d.code)
                      }
                    }}
                  >
                    {headers.map((h, j) => {
                      return (
                        <div
                          key={`key-col-${i}-${j}`}
                          className={`tc ${d[h.field] ? '' : 'missing'}`}
                          style={{ width: h.width }}
                          title={d[h.field]}
                        >
                          {d[h.field] ? d[h.field] : 'N/A'}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      case chartConstants.TABLE_STATUS_COLOR: {
        const { headers, tableID, selectedCode, handleRowClick } = this.props
        return (
          <div className='table'>
            <div className='theader'>
              {headers.map(h => {
                return (
                  <div
                    key={`key-header-${h.header}`}
                    className='tc'
                    style={{ width: h.width }}
                  >
                    {h.header}
                  </div>
                )
              })}
            </div>
            <div className='tbody'>
              {data.map((d, i) => {
                return (
                  <div
                    key={`key-row-${i}`}
                    className={`trow
                                        ${
                                          selectedCode !== undefined &&
                                          selectedCode === d.code
                                            ? 'selected'
                                            : ''
                                        }
                                        ${handleRowClick ? '' : 'noevents'}`}
                    style={(() => {
                      if (d['status']) {
                        switch (d['status'].toLowerCase()) {
                          case 'ready':
                            return { color: '#39ad52' }
                          case 'not_connected':
                            return { color: '#3a3a3a' }
                          case 'no_current':
                            return { color: '#b11c1c' }
                          default:
                            return { color: '#ef9309' }
                        }
                      }
                      return {}
                    })()}
                    onClick={() => {
                      if (handleRowClick) {
                        handleRowClick(tableID, d.code)
                      }
                    }}
                  >
                    {headers.map((h, j) => {
                      return (
                        <div
                          key={`key-col-${i}-${j}`}
                          className={`tc ${d[h.field] ? '' : 'missing'}`}
                          style={{ width: h.width }}
                          title={d[h.field]}
                        >
                          {d[h.field] ? d[h.field] : 'N/A'}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      default:
        return null
    }
  }

  renderLegend = props => {
    const { payload } = props
    return (
      <div className='legend'>
        <div className='title'>{payload.title}</div>
        <ul className='list'>
          {payload.map((entry, index) => (
            <li key={`item-${index}`}>{entry.value}</li>
          ))}
        </ul>
      </div>
    )
  }

  render () {
    const { width, title, data, loading, type, metrics, colors, showUtils } =
      this.props
    const { offset, startIndex } = this.state
    return (
      <div
        className='Chart container'
        style={{ width: `calc(${width} - 10px)` }}
      >
        <React.Fragment>
          <Row className='title'>
            <Col>
              <h6>{title}</h6>
            </Col>
            {!showUtils ? null : data && data.length ? (
              <Col className='buttons-wrapper'>
                <div className='buttons'>
                  <i
                    className={`fa fa-plus buttons ${
                      offset - this.state.step < this.state.step
                        ? 'disabled'
                        : ''
                    }`}
                    // <i className={`fa fa-plus buttons ${offset - this.state.step < this.state.step ? 'disabled' : ''}`}
                    onClick={() => this.editOffset(-1)}
                  />
                  <i
                    className={`fa fa-minus buttons ${
                      offset + this.state.step > data.length ? 'disabled' : ''
                    }`}
                    onClick={() => this.editOffset(1)}
                  />
                </div>
                <div
                  className={`buttons ${
                    data.length === offset ? 'disabled' : ''
                  }`}
                >
                  <i
                    className={`fa fa-chevron-left ${
                      !(data.length === offset) && startIndex === 0
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={() => this.editStartIndex(-1)}
                  />
                  <i
                    className={`fa fa-chevron-right ${
                      !(data.length === offset) &&
                      startIndex + offset >= data.length
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={() => this.editStartIndex(1)}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <Row className={`wrapper ${type}`}>
            {(typeof data === 'number' || (data && data.length)) && !loading ? (
              <Col sm={12}>
                {this.getChart(this.state.data, type, metrics, colors)}
              </Col>
            ) : (
              this.getPlaceholder(loading)
            )}
          </Row>
        </React.Fragment>
      </div>
    )
  }
}

export default ChartDashboard = withTranslation()(ChartDashboard)

import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label
} from "reactstrap";
import { Button } from "components";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import BaseView from "./BaseView";

class Expansions extends BaseView {

    constructor(props) {
        super(props);

        let lampsOn = props.conf.configParam.configlampsOn;
        let manualMode = props.conf.configParam.manualMode;

        // let lampsOn = props.conf.configParam ? props.conf.configParam.configlampsOn : {};
        // let manualMode = props.conf.configParam ? props.conf.configParam.manualMode : {};

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null,
            formEditing: false,
            generalParams: null,
            lampsOn,
            manualMode,
            sorted: []
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem,
            lampsOn: nextProps.conf.configParam.configlampsOn,
            manualMode: nextProps.conf.configParam.manualMode,
            formEditing: false,
            isNew: false
        });
    }

    render() {
        return (
            <div>
                {this.renderParametriGenerali()}
                {this.renderEspansioni()}
            </div>
        )
    }

    getOptionsLampsOn() {
        const { conf } = this.props;
        let result = [];

        if (conf && conf.configParam && conf.configParam.expansions) {
            // ciclo sulle espansioni definite nella configurazione
            conf.configParam.expansions.forEach((expansion) => {
                if (expansion.type === 'default' && expansion.periferals) {
                    expansion.periferals.forEach((periferal) => {
                        if (periferal.type === 'DIG-IN' && periferal.code === "0101") {
                            let option = {
                                value: periferal.code,
                                label: (periferal.properties.label === "" ? periferal.type + " - " + periferal.code : periferal.properties.label)
                            };
                            result.push(option);
                        }
                    });
                }
            });
        }

        if (conf && conf.configParam && conf.configParam.commands) {
            conf.configParam.commands.map((com) => {
                if (com.type === "EVE_TYPE_ASTRO") {
                    result.push({ value: com.code, label: com.code });
                }
                return false;
            });
        }

        return result;
    }

    getOptionsManualMode() {
        const { conf } = this.props;

        let result = [];

        if (conf && conf.configParam && conf.configParam.expansions) {
            // ciclo sulle espansioni definite nella configurazione
            conf.configParam.expansions.forEach((expansion) => {
                if (expansion.type === 'default' && expansion.periferals) {
                    expansion.periferals.forEach((periferal) => {
                        if (periferal.type === 'DIG-IN' && periferal.code !== "0101") {
                            let option = {
                                value: periferal.code,
                                label: (periferal.properties.label === "" ? periferal.type + " - " + periferal.code : periferal.properties.label)
                            };
                            result.push(option);
                        }
                    });
                }
            });
        }

        return result;

    }

    renderParametriGenerali() {

        const { t, dispatch, conf } = this.props;
        const { formEditing, lampsOn, manualMode } = this.state;

        // optionsModiFunzionamento x select
        let optionsLampsOn = this.getOptionsLampsOn();
        let optionsManualMode = this.getOptionsManualMode();

        return (
            <Card>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title " >
                        <span style={{ flex: 1 }}>{t("Parametri generali")}</span>
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    <Form className="form-horizontal">
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup>
                                    <Label>{t("Segnalazione impianto ON/OFF")}</Label>
                                    <Select
                                        name="lampsOn"
                                        value={lampsOn}
                                        disabled={!formEditing}
                                        options={optionsLampsOn}
                                        onChange={option => {
                                            if (option) {
                                                this.setState({ lampsOn: option.value })
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <FormGroup>
                                    <Label>{t("Segnalazione impianto Automatico/Manuale")}</Label>
                                    <Select
                                        name="manualMode"
                                        disabled={!formEditing}
                                        value={manualMode}
                                        options={optionsManualMode}
                                        onChange={option => {
                                            if (option) {
                                                this.setState({ manualMode: option.value })
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>

                    {!formEditing &&
                        <div align="right">
                            <ButtonWithTooltip
                                disabled={true}
                                id={"form_gen_edit"}
                                color="warning"
                                size="sm"
                                tooltipText={t("Modifica")}
                                onClick={() => {
                                    this.setState({
                                        formEditing: true
                                    });
                                }}
                            >
                                <i className="fa fa-edit" />
                            </ButtonWithTooltip>
                        </div>
                    }
                    {formEditing &&
                        <div align="right">
                            <ButtonWithTooltip
                                id={"form_gen_save"}
                                color="success"
                                size="sm"
                                tooltipText={t("Salva")}
                                onClick={() => {
                                    conf.configParam.configlampsOn = lampsOn;
                                    conf.configParam.manualMode = manualMode;
                                    dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                }}
                            >
                                <i className="fa fa-check" />
                            </ButtonWithTooltip>

                            &nbsp;

                            <ButtonWithTooltip
                                id={"form_gen_cancel"}
                                onClick={() => {
                                    let lampsOn = conf.configParam.configlampsOn;
                                    let manualMode = conf.configParam.manualMode;
                                    this.setState({ formEditing: false, lampsOn, manualMode });
                                }}
                                color="secondary"
                                size="sm"
                                tooltipText={t("Annulla")}
                            >
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    }

                </CardBody>
            </Card>
        );
    }

    renderEspansioni() {

        const { t, dispatch, loading, conf, params, optionsTipiEspansione } = this.props;

        const { editingItem, editingIndex } = this.state;

        let data = [];

        let optionsTipi = optionsTipiEspansione && optionsTipiEspansione.filter((exp) => { return (exp.value !== 'default') });

        if (conf && conf.configParam && conf.configParam.expansions) {

            data = conf.configParam.expansions.map((expansion, expIndex) => {
                expansion.index = expIndex;
                return expansion;
            });

        }

        let addressCodeHis = {};

        if (params.androsLiveParams.periferals) {

            let types = Object.keys(params.androsLiveParams.periferals);
            types.map((type) => {
                const periferal = params.androsLiveParams.periferals[type];
                addressCodeHis[type] = (periferal.addressCodeHi !== null ? periferal.addressCodeHi : periferal.addressCodeHI);
                return false;
            });

        }

        return (
            <Card>
                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title " >
                        <span style={{ flex: 1 }}>{t("Espansioni Andros Live")}</span>
                        <Button
                            disabled={true}
                            color="primary"
                            onClick={() => {

                                if (this.state.isNew) {
                                    return;
                                }

                                let address = (data ? Math.max.apply(Math, data.map((o) => { return o.address; })) + 1 : 0);
                                let item = {
                                    type: null,
                                    address,
                                    enabled: false
                                };

                                if (!conf.configParam.expansions) {
                                    conf.configParam.expansions = [];
                                }
                                conf.configParam.expansions.unshift(item);
                                this.setState({ isNew: true, conf, editingIndex: 0, editingItem: item, sorted: [] });
                            }}
                            style={{ fontSize: 14, fontWeight: 400, margin: 0, padding: '5px' }}
                        >
                            {t("Nuova")}
                        </Button>
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    {this.state.alert}
                    <ReactTable
                        className="configurations"
                        data={data}
                        pageSize={data && data.length}
                        showPagination={false}
                        NoDataComponent={() => null}
                        sorted={this.state.sorted}
                        onSortedChange={(newSorted) => { this.setState({ sorted: newSorted }) }}
                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[
                            {
                                Header: t("Tipo"),
                                accessor: "type",
                                filterable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex);
                                    if (isEditing) {
                                        const selectedOption = this.getSelected(optionsTipi, editingItem.type);
                                        return (
                                            <Select
                                                placeholder={t("Tipo espansione")}
                                                name="group"
                                                value={selectedOption}
                                                options={optionsTipi}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.type = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    } else {
                                        const selectedOption = this.getSelected(optionsTipiEspansione, row.value);
                                        return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    }
                                }
                            },
                            {
                                Header: t("Indirizzo"),
                                accessor: "address",
                                Cell: (row) => {
                                    //let isEditing = (row.index === editingIndex);
                                    return (<div align="">{row.value}</div>)
                                },
                                filterable: false
                            },
                            {
                                Header: t("Abilitata"),
                                accessor: "enabled",
                                Cell: (row) => {

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {
                                        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >

                                            <Switch
                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                offText={
                                                    <i className="now-ui-icons ui-1_simple-remove" />
                                                }
                                                onColor={"green"}
                                                offColor={"lightgray"}
                                                onChange={(el, state) => {
                                                    // user.isActive = state;
                                                    // this.setState({ user });
                                                    editingItem.enabled = state;
                                                    this.setState({ editingItem })
                                                }}
                                                value={editingItem.enabled}
                                            />

                                            {/* { editingItem.enabled ? <SwitchOn height={40} width={80} fill="gray" stroke="white" strokeWidth={1}/>
                        : <SwitchOff height={40} width={80} fill="gray" stroke="white" strokeWidth={1}/> } */}

                                        </div>
                                        )
                                    }

                                    else {
                                        return (<div align="center">{(row.value ? "ON" : "OFF"
                                            // <i style={{ fontSize: "1.5em", verticalAlign: "middle"}} className="fa fa-check-square-o" aria-hidden="true"></i> :
                                            // <i style={{ fontSize: "1.5em", verticalAlign: "middle"}} className="fa fa-square-o" aria-hidden="true"></i>
                                        )} </div>)

                                    }

                                },
                                filterable: false
                            },

                            {
                                Header: "H24",
                                accessor: "h24",
                                Cell: (row) => {

                                    if (row.original.type !== "androsPls") {
                                        return null;
                                    }

                                    const isEditing = (row.index === editingIndex);

                                    if (isEditing) {
                                        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >

                                            <Switch
                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                offText={
                                                    <i className="now-ui-icons ui-1_simple-remove" />
                                                }
                                                onColor={"green"}
                                                offColor={"lightgray"}
                                                onChange={(el, state) => {
                                                    editingItem.h24 = state;
                                                    this.setState({ editingItem })
                                                }}
                                                value={editingItem.h24}
                                            />

                                        </div>
                                        )
                                    }

                                    else {
                                        return (<div align="center">{row.value && <i className={"now-ui-icons ui-1_check"} />} </div>)
                                    }

                                },
                                filterable: false
                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.index === editingIndex);

                                    if (!isEditing)
                                        return (

                                            <div align="right">
                                                {row.original.type !== 'default' &&
                                                    <ButtonWithTooltip
                                                        disabled={true}
                                                        id={"exp_edit_" + row.index}
                                                        color="warning"
                                                        size="sm"
                                                        tooltipText={t("Modifica")}
                                                        onClick={() => {

                                                            this.setState({
                                                                isNew: false,
                                                                editingIndex: row.index,
                                                                editingItem: JSON.parse(JSON.stringify(row.original))
                                                            });

                                                        }}
                                                    >
                                                        <i className="fa fa-edit" />
                                                    </ButtonWithTooltip>
                                                }
                                                {" "}

                                                {row.original.type !== 'default' &&
                                                    <ButtonWithTooltip
                                                        disabled={true}
                                                        id={"exp_del_" + row.index}
                                                        onClick={() => {

                                                            this.confirmDelete(
                                                                t("Elimina espansione"),
                                                                () => {

                                                                    let expansions = conf.configParam.expansions.filter(
                                                                        ((curr, index) => {
                                                                            return (index !== row.index)
                                                                        })
                                                                    );
                                                                    conf.configParam.expansions = expansions;
                                                                    dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                                    this.setState({
                                                                        alert: null
                                                                    });

                                                                }

                                                            );

                                                        }}
                                                        color="danger"
                                                        size="sm"
                                                        tooltipText={t("Elimina")}
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </ButtonWithTooltip>
                                                }
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"exp_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {

                                                        // convalida indirizzo
                                                        // non sono accettate cose diverse da 00 --> FF
                                                        // non vale un indirizzo già assegnato
                                                        // let error = this.validateExpansionAddress(editingItem,data);
                                                        // if (error !== ""){
                                                        //   this.setState({
                                                        //     validationError: { field: 'address', error}});
                                                        //   return;
                                                        // }

                                                        let oldEspansion = conf.configParam.expansions[row.index];

                                                        if (editingItem.type === 'androsPls' || editingItem.type === 'androsPlr') {

                                                            if (this.state.isNew || (oldEspansion.type !== editingItem.type)) {
                                                                let filtered = data.filter((x) => (x.type === 'androsPls' || x.type === 'androsPlr'));
                                                                editingItem.code = addressCodeHis['PLS'] + ("" + filtered.length).padStart(2, '0');
                                                            }

                                                        }
                                                        else {
                                                            if (editingItem.code)
                                                                delete (editingItem.code);
                                                        }

                                                        conf.configParam.expansions[row.index] = editingItem;

                                                        // è cambiato il tipo?
                                                        if (oldEspansion.type !== editingItem.type || editingItem.periferals === null) {
                                                            // si --> prendo le nuove periferiche
                                                            // aggiungo le periferiche della nuova espansione
                                                            if (params.androsLiveParams && params.androsLiveParams.expansions) {
                                                                const paramExpansion = params.androsLiveParams.expansions[editingItem.type];
                                                                if (paramExpansion && paramExpansion.periferals) {

                                                                    conf.configParam.expansions[row.index].periferals = [];
                                                                    paramExpansion.periferals.forEach((per, key) => {
                                                                        const clonedPeriferal = JSON.parse(JSON.stringify(per));
                                                                        clonedPeriferal.subCode = ("" + key).padStart(2, '0');
                                                                        clonedPeriferal.code = addressCodeHis[clonedPeriferal.type] + clonedPeriferal.subcode;

                                                                        conf.configParam.expansions[row.index].periferals.push(clonedPeriferal);
                                                                    });

                                                                }
                                                                else {
                                                                    conf.configParam.expansions[row.index].periferals = [];
                                                                }
                                                            }

                                                        }

                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"exp_del_" + row.index}
                                                    onClick={() => {

                                                        if (this.state.isNew) {
                                                            conf.configParam.expansions.shift();
                                                            this.setState({ isNew: false, editingIndex: -1, conf, validationError: null });
                                                        }
                                                        else
                                                            this.setState({ validationError: null, editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    // defaultPageSize={10}
                    />
                </CardBody>
            </Card>
        );
    }

    validateExpansionAddress(editingItem, data) {

        const { t } = this.props;

        // validazione sintattica
        try {
            let intval = parseInt(editingItem.address, 16);
            if (isNaN(intval) || intval < 0 || intval > 255) {
                return t("Inserire un valore compreso tra 00 e FF");
            }
            editingItem.address = intval.toString(16).padStart(2, '0').toUpperCase();
        } catch (err) {
            return t("Inserire un valore compreso tra 00 e FF");
        }

        // controllo esclusività
        for (let datum of data) {
            if (editingItem.index === datum.index) {
                continue;
            }
            if (datum.address === editingItem.address) {
                return t("Indirizzo occupato");
            }
        }

        return "";
    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default Expansions = withTranslation()(connect(mapStateToProps)(Expansions));

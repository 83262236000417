import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PagesHeader, Footer } from "components";
import pagesRoutes from "routes/pages.jsx";
import { connect } from 'react-redux';

class Pages extends React.Component {
    render() {
        return (
            <div>
                <PagesHeader {...this.props} />
                <div className={"wrapper wrapper-full-page" + (() => {
                    const disabledViews = [
                        '/zones-navigator',
                        '/plants-navigator',
                        '/timerprofiles',
                    ];

                    if (disabledViews.includes(this.props.location.pathname)) {
                        return " umpi-perfect-scrollbar-off";
                    }

                    return "";
                })()} ref="fullPages">
                    <div className="full-page section-image">
                        <Switch>
                            {pagesRoutes.map((prop, key) => {
                                if (prop.redirect)
                                    return (
                                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                                    );
                                return (
                                    <Route
                                        path={prop.path}
                                        component={prop.component}
                                        key={key}
                                    />
                                );
                            })}
                        </Switch>
                        <Footer fluid />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedPages = connect(mapStateToProps)(Pages);
export { connectedPages as Pages };

import { connect } from 'react-redux';
import React, { Component } from "react";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import moment from 'moment';
import ErrorModal from "../../views/Components/ErrorModal"
import { loopbackService } from '../../services';
import User from '../../entities/User';
import { roleConstants } from '../../constants';

const DateFormat = "DD/MM/YYYY";
const TimeFormat = "HH:mm";

const PAGE_SIZE = 4;

class Alarms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            data: [],
            pages: -1
        }

        this.table = React.createRef();
    }

    componentWillMount() {
        this.countAlarms();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.alarms) {
            if (nextProps.alarms.error) {
                this.setState({ error: { error: nextProps.alarms.error } });
            }
            else if (nextProps.alarms.loading) {
                this.setState({ loading: true });
            }
            // else if (nextProps.alarms.instances) {
            //   this.setState({ loading: false, alarms: nextProps.alarms.instances });
            // }
            else if (nextProps.alarms.instance) {
                this.countAlarms();
            }
        }
    }

    async countAlarms() {
        try {
            let data = await loopbackService.list('plant', 'alarms', { deleted: false }, null, ["ackByUser", "cabinet"], null, null);
            this.setState({ data });
        } catch (error) {
            //console.log(error);
        }
    }

    confirmDelete(title, onConfirm) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={onConfirm} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    render() {

        const { t, handleAlarmsClose, dispatch } = this.props;
        const { loading, error, data } = this.state;
        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);

        /*

        "ts": "2019-07-19T08:13:11.456Z",
        "sourceMacAddress": "string",
        "sourcePeriferalType": "string",
        "sourcePeriferalCode": "string",
        "eventLabel": "string",
        "eventCode": "string",
        "priority": "string",
        "ackByUserId": 0

        "id": 0,
        "projectId": 0,
        "cabinetId": 0,
        "lightPointId": 0,

      }

        */

        return (
            <Modal
                isOpen={true}
                className=" modal-umpi-container"
                size="lg"
            >

                <ModalHeader toggle={handleAlarmsClose}>
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    {t("Cronologia allarmi")}
                </ModalHeader>

                <ModalBody style={{ padding: "5px 10px" }}>
                    <Card style={{ boxShadow: "unset" }}>
                        <CardBody>
                            {this.state.alert}
                            <ReactTable
                                ref={this.table}
                                className="-striped -highlight configurations"
                                loading={loading}
                                data={data}
                                pageSize={PAGE_SIZE}
                                showPagination={true}
                                noDataText={loading ? '' : this.props.noRecordText}
                                previousText={t('Precedente')}
                                nextText={t("Successiva")}
                                pageText={t("Pagina")}
                                ofText={t("di")}
                                rowsText={t("righe")}
                                showPageSizeOptions={false}
                                LoadingComponent={() => {
                                    if (loading) {
                                        return (
                                            <div className="-loading -active">
                                                <div className="-loading-inner custom-margin-0-auto">
                                                    <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                }
                                }
                                columns={[
                                    {
                                        Header: t("Data ora"),
                                        // accessor: "tsMillis",
                                        accessor: "ts",
                                        filterable: false,
                                        width: 150,
                                        Cell: (row) => {
                                            return (
                                                <div className="no-overflow" align="left">
                                                    {row.value && <Moment>{row.value}</Moment>}
                                                </div>);
                                        }
                                    },

                                    {
                                        Header: t("Cabina"),
                                        accessor: "cabinet.label",
                                        filterable: false,
                                        sortable: true,
                                        Cell: (row) => {
                                            return (<div className="no-overflow" align="">{row.value}</div>);
                                        }
                                    },

                                    {
                                        Header: t("Città"),
                                        accessor: "cabinet.city",
                                        filterable: false,
                                        sortable: true,
                                        Cell: (row) => {
                                            return (<div className="no-overflow" align="">{row.value}</div>);
                                        }
                                    },

                                    {
                                        Header: t("Allarme"),
                                        accessor: "eventLabel",
                                        sortable: true,
                                        filterable: false,
                                        Cell: (row) => {
                                            let text = this.getEventLabel(row.original);
                                            return (<div className="no-overflow" align="">{text}</div>)
                                        }
                                    },

                                    {
                                        Header: t("Riconoscimento"),
                                        accessor: "tsAck",
                                        sortable: true,
                                        filterable: false,
                                        Cell: (row) => {

                                            let text = "";
                                            if (row.original.ackByUser) {
                                                text = row.value ? moment(row.value, moment.defaultFormat).format(DateFormat + " " + TimeFormat) + " - " : "";
                                                text += (row.original.ackByUser ? (row.original.ackByUser.surname + " " + row.original.ackByUser.name) : row.id);
                                                return (
                                                    <div className="no-overflow" >
                                                        {text}
                                                    </div>
                                                );
                                            } // {row.value && <Moment>{row.value}</Moment>}
                                            else {
                                                return (
                                                    <div style={{ textAlign: "center" }}>
                                                        <ButtonWithTooltip
                                                            id={"alrm_edit_" + row.index}
                                                            color="success"
                                                            size="sm"
                                                            tooltipText={t("Riconosci")}
                                                            disabled={isGuest}
                                                            onClick={() => {
                                                                let user = User.current();
                                                                dispatch(loopbackActions.edit('plant', 'alarms', {
                                                                    id: row.original.id,
                                                                    ackByUserId: user.id,
                                                                    tsAck: moment().format(moment.defaultFormat)
                                                                }, 'PATCH'));
                                                            }}
                                                        >
                                                            <i className="fa fa-check" />
                                                        </ButtonWithTooltip>
                                                    </div>
                                                );

                                            }
                                        }
                                    },

                                    {
                                        Header: t(""),
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false,
                                        resizable: false,
                                        Cell: (row) => {

                                            if (!row.original.ackByUser)
                                                return null;

                                            return (

                                                <div className="actions-right">
                                                    <ButtonWithTooltip
                                                        id={"alrm_del_" + row.original.id}
                                                        disabled={isGuest}
                                                        onClick={() => {

                                                            this.confirmDelete(
                                                                t("Elimina allarme"),
                                                                () => {
                                                                    dispatch(loopbackActions.edit('plant', 'alarms', {
                                                                        id: row.original.id,
                                                                        deleted: true
                                                                    }, 'PATCH'));
                                                                    this.setState({
                                                                        alert: null
                                                                    });
                                                                }
                                                            );

                                                        }}
                                                        color="danger"
                                                        size="sm"
                                                        tooltipText={t("Elimina")}
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </ButtonWithTooltip>
                                                </div>

                                            )
                                        }
                                    },

                                    // {
                                    //   Header: t(""),
                                    //   accessor: "",
                                    //   sortable: false,
                                    //   filterable: false,
                                    //   resizable: false,
                                    //   width: 80,
                                    //   Cell: (row) => {
                                    //     if (row.original.ackByUserId) {
                                    //       return null;
                                    //     }
                                    //     return (
                                    //       <div style={{ textAlign: "center" }}>
                                    //         <ButtonWithTooltip
                                    //           id={"alrm_edit_" + row.index}
                                    //           color="success"
                                    //           size="sm"
                                    //           tooltipText={t("Riconosci")}
                                    //           onClick={() => {
                                    //             // conf.configParam.commands[row.original.index] = editingItem;
                                    //             // conf.dateUpdate = moment().toISOString(true);
                                    //             let user = User.current();
                                    //             //dispatch(loopbackActions.('plant', 'alarms', row.original.id, 'ackByUser', { id: user.id } ));
                                    //             dispatch(loopbackActions.edit('plant', 'alarms', {
                                    //               id: row.original.id,
                                    //               ackByUserId: user.id,
                                    //               tsAck: moment().format(moment.defaultFormat)

                                    //             }, 'PATCH'));
                                    //           }}
                                    //         >
                                    //           <i className="fa fa-check" />
                                    //         </ButtonWithTooltip>
                                    //       </div>
                                    //     )
                                    //   }
                                    // }

                                ]}
                            //defaultPageSize={10}
                            />
                        </CardBody>
                    </Card>

                </ModalBody>
            </Modal >

        )

    }

    getEventLabel(alarm) {
        let text = alarm.sourcePeriferalType ? alarm.sourcePeriferalType + " / " : "";
        text += alarm.sourcePeriferalCode ? alarm.sourcePeriferalCode + " - " : "";
        text += alarm.eventLabel;
        return text;
    }

}

function mapStateToProps(state) {
    const { loopback } = state;
    return {
        alarms: loopback.alarms
    };
}

export default Alarms = withTranslation()(connect(mapStateToProps)(Alarms));

import { loopbackConstants } from '../constants';
import { loopbackService } from '../services';
import { history } from '../helpers';

export const loopbackActions = {
    multiList,
    list,
    get,
    edit,
    editOwnInstance,
    multiEdit,
    _delete,
    count,
    multiCount,
    add,
    getParam,
    invokeOnInstance,
    refresh,
    customMethod,
    listRelatedModel
}

/**
 *
 * @param {*} path
 * @param {*} model
 * @param {*} instance
 * @param {*} method
 * @param {*} instanceId
 * @returns
 */
function customMethod(path, model, instance, method, customMethod) {
    return async (dispatch) => {
        dispatch(request(path, model, instance, customMethod));
        try {
            let changed = await loopbackService.customMethod(path, model, instance, method, customMethod);
            dispatch(success(path, model, changed));
        } catch (error) {
            dispatch(failure(path, model, error))
        }
    };

    function request(path, model, instance, customMethod) {
        return {
            type: loopbackConstants.EDIT_REQUEST,
            path,
            model,
            instance,
            customMethod
        }
    }

    function success(path, model, changed) {
        return {
            type: loopbackConstants.EDIT_SUCCESS,
            path,
            model,
            instance: changed
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.EDIT_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} where
 * @param {*} limit
 * @param {*} include
 * @param {*} order
 */
function listRelatedModel(path, parent, model, where = null, limit = null, include = null, order = null) {
    return async (dispatch) => {
        dispatch(request(path, model, where, limit));
        try {
            let instances = await loopbackService.listRelatedModel(path, parent, model, where, limit, include, order);
            dispatch(success(path, model, instances));
        } catch (error) {
            dispatch(failure(path, model, error));
            if (error === 'Unauthorized') {
                history.push('/pages/login-page');
            }
        }
    };

    function request(path, model, where, limit) {
        return {
            type: loopbackConstants.LIST_REQUEST,
            path,
            model,
            where,
            limit
        }
    }

    function success(path, model, instances) {
        return {
            type: loopbackConstants.LIST_SUCCESS,
            path,
            model,
            instances
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.LIST_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} instance
 * @param method = { PUT | PATCH }
 */
function editOwnInstance(path, model, instance, method) {
    return async (dispatch) => {
        dispatch(request(path, model, instance));
        try {
            let changed = await loopbackService.edit(path, (model + "/" + instance.id), instance, method);
            dispatch(success(path, model, changed));
        } catch (error) {
            dispatch(failure(path, model, error))
        }
    };

    function request(path, model, instance) {
        return {
            type: loopbackConstants.EDIT_REQUEST,
            path,
            model,
            instance
        }
    }

    function success(path, model, changed) {
        return {
            type: loopbackConstants.EDIT_SUCCESS,
            path,
            model,
            instance: changed
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.EDIT_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 */
function multiList(args) {
    return async (dispatch) => {
        dispatch(request(args));
        let results = args.map(async (arg) => await loopbackService.list(arg.path, arg.model, arg.where, arg.limit, arg.include));
        let ret = {};
        for (let i = 0; i < results.length; i++) {
            const model = args[i].model;
            try {
                let instances = await results[i];
                ret[model] = { instances };
            } catch (response) {
                let error = null;
                if (typeof (response.json) === 'function')
                    error = await response.json();
                else
                    error = response;
                ret[model] = {
                    error
                };
            }
        }
        dispatch(success(ret));
    };

    function request(args) {
        return {
            type: loopbackConstants.MULTI_LIST_REQUEST,
            args
        }
    }

    function success(ret) {
        return {
            type: loopbackConstants.MULTI_LIST_SUCCESS,
            ret
        }
    }

    //function failure(args, error) { return { type: loopbackConstants.MULTI_LIST_FAILURE, args, error } }
}

/**
 *
 * @param {*} model
 * @param {*} where
 * @param {*} limit
 * @param {*} include
 * @param {*} order
 */
function list(path, model, where = null, limit = null, include = null, order = null) {
    return async (dispatch) => {
        dispatch(request(path, model, where, limit));
        try {
            let instances = await loopbackService.list(path, model, where, limit, include, order);
            dispatch(success(path, model, instances));
        } catch (error) {
            dispatch(failure(path, model, error));
            if (error === 'Unauthorized') {
                history.push('/pages/login-page');
            }
        }
    };

    function request(path, model, where, limit) {
        return {
            type: loopbackConstants.LIST_REQUEST,
            path,
            model,
            where,
            limit
        }
    }

    function success(path, model, instances) {
        return {
            type: loopbackConstants.LIST_SUCCESS,
            path,
            model,
            instances
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.LIST_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 * @param {*} model
 * @param {*} id
 * @param {*} filter
 */
function get(path, model, id, filter = null) {
    return async (dispatch) => {
        dispatch(request(path, model, id, filter));
        try {
            let instance = await loopbackService.get(path, model, id, filter);
            dispatch(success(path, model, instance));
        } catch (error) {
            dispatch(failure(path, model, filter, error));
        }
    };

    function request(path, model, id, filter) {
        return {
            type: loopbackConstants.GET_REQUEST,
            path,
            model,
            id,
            filter
        }
    }

    function success(path, model, instance) {
        return {
            type: loopbackConstants.GET_SUCCESS,
            path,
            model,
            instance
        }
    }

    function failure(path, model, filter, error) {
        return {
            type: loopbackConstants.GET_FAILURE,
            path,
            model,
            filter,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} instance
 */
function add(path, model, instance, callback = null) {
    return async (dispatch) => {
        dispatch(request(path, model, instance));
        let response = null;
        try {
            let changed = await loopbackService.add(path, model, instance);
            response = success(path, model, changed);
        }
        catch (error) {
            response = failure(path, model, error);
        }
        finally {
            dispatch(response);
            if (typeof callback === "function") {
                callback(response);
            }
        }
    };

    function request(path, model, instance) {
        return {
            type: loopbackConstants.ADD_REQUEST,
            path,
            model,
            instance
        }
    }

    function success(path, model, changed) {
        return {
            type: loopbackConstants.ADD_SUCCESS,
            path,
            model,
            instance: changed
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.ADD_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} instance
 * @param method = { PUT | PATCH }
 */
function edit(path, model, instance, method, callback = null) {
    return async (dispatch) => {
        dispatch(request(path, model, instance));
        let response = null;
        try {
            let changed = await loopbackService.edit(path, model, instance, method);
            response = success(path, model, changed);
        }
        catch (error) {
            response = failure(path, model, error);
        }
        finally {
            dispatch(response);
            if (typeof callback === "function") {
                callback(response);
            }
        }
    };

    function request(path, model, instance) {
        return {
            type: loopbackConstants.EDIT_REQUEST,
            path,
            model,
            instance
        }
    }

    function success(path, model, changed) {
        return {
            type: loopbackConstants.EDIT_SUCCESS,
            path,
            model,
            instance: changed
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.EDIT_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} args
 */
function multiEdit(args) {
    return async (dispatch) => {
        dispatch(request(args));

        let tasks = args.map(async (arg) => await loopbackService.edit(arg.path, arg.model, arg.instance));
        let ret = {};
        for (let i = 0; i < tasks.length; i++) {
            const model = args[i].model;
            try {
                let instance = await tasks[i];
                ret[model] = { instance };
            } catch (response) {
                let error = null;
                console.error('multiEdit error=', response);
                if (typeof (response.json) === 'function') {
                    error = await response.json();
                }
                else {
                    error = response;
                }
                ret[model] = {
                    error
                };
            }
        }
        dispatch(success(ret));
    };

    function request(args) {
        return {
            type: loopbackConstants.MULTI_EDIT_REQUEST,
            args
        }
    }

    function success(ret) {
        return {
            type: loopbackConstants.MULTI_EDIT_SUCCESS,
            ret
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} id
 */
function _delete(path, model, id) {
    return async (dispatch) => {
        dispatch(request(path, model, id));
        try {
            await loopbackService.delete(path, model, id);
            dispatch(success(path, model, id));
        } catch (error) {
            dispatch(failure(path, model, error))
        }
    };

    function request(path, model, id) {
        return {
            type: loopbackConstants.DELETE_REQUEST,
            path,
            model,
            id
        }
    }

    function success(path, model, id) {
        return {
            type: loopbackConstants.DELETE_SUCCESS,
            path,
            model,
            id
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.DELETE_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} where
 */
function count(path, model, where = null, projectId = null) {
    return async (dispatch) => {
        dispatch(request(path, model, where));
        try {
            let data = await loopbackService.count(path, model, where);
            if (data.count) {
                dispatch(success(path, model, data.count));
            }
            else {
                throw new Error('count non restituito.');
            }
        } catch (error) {
            dispatch(failure(path, model, error));
        }
    };

    function request(path, model, where) {
        return {
            type: loopbackConstants.COUNT_REQUEST,
            path,
            model,
            where
        }
    }

    function success(path, model, count) {
        return {
            type: loopbackConstants.COUNT_SUCCESS,
            path,
            model,
            count
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.COUNT_FAILURE,
            path,
            model,
            error
        }
    }
}

/**
 *
 * @param {*} args
 */
function multiCount(args) {
    return async (dispatch) => {
        dispatch(request(args));
        let results = args.map(async (arg) => await loopbackService.count(arg.path, arg.model, arg.where, arg.projectId));
        let ret = {};
        for (let i = 0; i < results.length; i++) {
            const model = args[i].model;
            let projectId = args[i].projectId;
            if (!ret[model]) {
                ret[model] = {};
            }
            try {
                let data = await results[i];
                if (data.count)
                    ret[model][projectId] = { count: data.count };
                else
                    throw new Error("Impossibile leggere count");
            } catch (response) {
                let error = null;
                if (typeof (response.json) === 'function')
                    error = await response.json();
                else
                    error = response;
                ret[model][projectId] = {
                    error
                };
            }
        }
        dispatch(success(ret));
    };

    function request(args) {
        return {
            type: loopbackConstants.MULTI_COUNT_REQUEST,
            args
        }
    }

    function success(ret) {
        return {
            type: loopbackConstants.MULTI_COUNT_SUCCESS,
            ret
        }
    }

}

/**
 * @param {*} path
 * @param {*} model
 * @param {*} param
 * @param {*} extra
 */
function getParam(path, model, param, projectId = null, extra = null) {
    return async (dispatch) => {
        dispatch(request(path, model, param));
        try {
            let instance = await loopbackService.getParam(path, model, param, projectId, extra);
            dispatch(success(path, model, param, instance));
        } catch (error) {
            dispatch(failure(path, model, param, error));
        }
    };

    function request(path, model, param) {
        return {
            type: loopbackConstants.GET_PARAM_REQUEST,
            path,
            model,
            param
        }
    }

    function success(path, model, param, instance) {
        return {
            type: loopbackConstants.GET_PARAM_SUCCESS,
            path,
            model,
            param,
            instance
        }
    }

    function failure(path, model, param, error) {
        return {
            type: loopbackConstants.GET_PARAM_FAILURE,
            path,
            model,
            param,
            error
        }
    }
}

/**
 *
 * @param {*} model modello
 * @param {*} id id istanza
 * @param {*} funcname funzione da invocare sull'istanza del modello
 * @param {*} projectId
 * @param {*} body
 */
function invokeOnInstance(path, model, id, funcname, projectId = null, body = null) {
    return async (dispatch) => {
        dispatch(request(path, model, id, funcname));
        try {
            let res = await loopbackService.invokeOnInstance(path, model, id, funcname, projectId, body);
            dispatch(success(path, model, id, funcname, res));
        } catch (error) {
            dispatch(failure(path, model, id, funcname, error));
        }
    };

    function request(path, model, id, funcname) {
        return {
            type: loopbackConstants.INVOKE_ON_INSTANCE_REQUEST,
            path,
            model,
            id,
            funcname
        }
    }

    function success(path, model, id, funcname, res) {
        return {
            type: loopbackConstants.INVOKE_ON_INSTANCE_SUCCESS,
            path,
            model,
            id,
            funcname,
            res
        }
    }

    function failure(path, model, id, funcname, error) {
        return {
            type: loopbackConstants.INVOKE_ON_INSTANCE_FAILURE,
            path,
            model,
            id,
            funcname,
            error
        }
    }
}

/**
 *
 * @param {*} model
 * @param {*} where
 * @param {*} limit
 */
function refresh(path, model, where = null, limit = null) {
    return async (dispatch) => {
        dispatch(request(path, model, where, limit));
        try {
            let instances = await loopbackService.list(path, model, where, limit);
            dispatch(success(path, model, instances));
        } catch (error) {
            dispatch(failure(path, model, error));
            if (error === 'Unauthorized') {
                history.push('/pages/login-page');
            }
        }
    };

    function request(path, model, where, limit) {
        return {
            type: loopbackConstants.REFRESH_REQUEST,
            path,
            model,
            where,
            limit
        }
    }

    function success(path, model, instances) {
        return {
            type: loopbackConstants.REFRESH_SUCCESS,
            path,
            model,
            instances
        }
    }

    function failure(path, model, error) {
        return {
            type: loopbackConstants.REFRESH_FAILURE,
            path,
            model,
            error
        }
    }
}

import { connect } from 'react-redux';
import React from "react";
import ReactTable from "react-table";
import {
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Input,
} from "reactstrap";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-select";
import Periferals from "./Periferals";

class PeriferalsDigitalOutput extends Periferals {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            editingIndex: -1,
            isNew: false,
            maxDateUpload: "",
            validationError: null
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            editingIndex: nextProps.editingIndex,
            editingItem: nextProps.editingItem
        });
    }

    render() {

        const { t, dispatch, loading, conf, params } = this.props;
        const { editingIndex, editingItem } = this.state;

        // opzioni per funzioni
        let optionsDigOutPre = [];

        if (params.androsLiveParams && params.androsLiveParams.periferals) {
            let types = Object.keys(params.androsLiveParams.periferals);
            types.map((type) => {
                const periferal = params.androsLiveParams.periferals[type];
                if (type === 'DIG-OUT') {
                    if (periferal.properties && periferal.properties.preset && periferal.properties.preset.values) {
                        let values = periferal.properties.preset.values;
                        values.map((el) => {
                            optionsDigOutPre.push({
                                value: el.value, label: t(el.label)
                            });
                            return false;
                        });
                        optionsDigOutPre.sort((a, b) => {
                            return a.label > b.label;
                        });
                    }
                }
                return false;
            });
        }

        // opzioni per espansioni presenti in configurazione
        let optionsEspansioni = this.getOptionsEspansioni();

        let dataDigOut = this.getPeriferalsByType("DIG-OUT");

        return (
            <Card>

                <CardHeader className="config-card-header sub-header">
                    <CardTitle className="config-card-title" >
                        <span style={{ flex: 1 }}>{t("Uscite digitali")}</span>
                        {/* <Button
                      color="info"
                      onClick={ ()=>{

                        if (this.state.isNew || conf.configParam.expansions===null || conf.configParam.expansions.length===0){
                          return;
                        }

                        let code = (""+conf.configParam.expansions[0].address).padStart(2, '0');

                        let item = {
                          code,
                          subCode: "",
                          type: 'DIG-OUT',
                          properties: {
                             preset: ""
                          }
                        };

                        if (!conf.configParam.expansions[0].periferals){
                          conf.configParam.expansions[0].periferals=[];
                        }

                        conf.configParam.expansions[0]['periferals'].unshift(item);
                        this.setState( { isNew: true, conf, editingIndex: "0_0", editingItem: item });

                      }}
                      style={{  fontSize: 14, fontWeight: 400, margin: 0, padding: '5px'}}
                    >
                    {t("Nuova")}
                    </Button> */}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <ReactTable
                        data={dataDigOut}
                        className="-striped -highlight configurations"
                        pageSize={dataDigOut && dataDigOut.length}
                        showPagination={false}
                        NoDataComponent={() => null}

                        LoadingComponent={() => {
                            if (loading) {
                                return (
                                    <div className="-loading -active">
                                        <div className="-loading-inner custom-margin-0-auto">
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA===" />
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return null;
                            }
                        }
                        }
                        columns={[

                            {
                                Header: t("Etichetta"),
                                accessor: "properties.label",
                                filterable: false,
                                sortable: true,
                                Cell: (row) => {
                                    let isEditing = (row.original.index === editingIndex);
                                    if (isEditing) {
                                        return (
                                            <Input
                                                type="text"
                                                value={editingItem.properties.label}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    editingItem.properties.label = value;
                                                    this.setState({ editingItem })

                                                }}
                                            />);
                                    } else {
                                        return (<div className="no-overflow" align="left">{row.value}</div>)
                                    }
                                }
                            },
                            {
                                Header: t("Espansione"),
                                accessor: "code",
                                filterable: false,
                                Cell: (row) => {

                                    //   const isEditing = (row.original.index===editingIndex);

                                    //   if (isEditing){

                                    //     const selectedOption = this.getSelectedExpansion(optionsEspansioni,editingItem.code);

                                    //     return (
                                    //         <Select
                                    //           // menuContainerStyle = \{ \{position: 'relative'\} \}
                                    //           placeholder={t("Espansione")}
                                    //           name="group"
                                    //           value={ selectedOption }
                                    //           options={ optionsEspansioni }
                                    //           onChange={option => {
                                    //             if (option){
                                    //               let code = (""+option.value).padStart(2, '0');
                                    //               editingItem.code = code.concat(editingItem.subCode);
                                    //               this.setState({ editingItem })
                                    //             }
                                    //           }}
                                    //         />
                                    //     );

                                    //  } else {
                                    let index = row.original.index.split("_");
                                    const selectedOption = this.getSelected(optionsEspansioni, index[0]);
                                    return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                    //  }
                                }

                            },
                            {
                                Header: t("Codice"),
                                accessor: "code",
                                filterable: false,
                                Cell: (row) => {
                                    // let isEditing = (row.original.index===editingIndex);
                                    // if (isEditing){
                                    //   return (
                                    //     <Input
                                    //           type="text"
                                    //           value= {editingItem.subCode}
                                    //           onChange = { (e)=>{
                                    //             const { name, value } = e.target;
                                    //             editingItem.subCode = value;
                                    //             let code = editingItem.code.substring(0,2);
                                    //             code = code.concat(value);
                                    //             editingItem.code=code;
                                    //             this.setState({ editingItem })

                                    //           }}
                                    //       />);
                                    //   } else {
                                    return (<div align="">{row.value}</div>)
                                    // }
                                },
                            },
                            {
                                Header: t("Preset"),
                                accessor: "properties.preset",
                                filterable: false,
                                Cell: (row) => {

                                    const isEditing = (row.original.index === editingIndex);

                                    if (isEditing) {

                                        const selectedOption = this.getSelected(optionsDigOutPre, editingItem.properties.preset);
                                        return (
                                            <Select
                                                // menuContainerStyle = \{ \{position: 'relative'\} \}
                                                placeholder={t("Preset")}
                                                name="group"
                                                value={selectedOption && selectedOption.value}
                                                options={optionsDigOutPre}
                                                onChange={option => {
                                                    if (option) {
                                                        editingItem.properties.preset = option.value;
                                                        this.setState({ editingItem })
                                                    }
                                                }}
                                            />
                                        );

                                    }
                                    else {
                                        const selectedOption = this.getSelected(optionsDigOutPre, row.value);
                                        const label = (selectedOption ? selectedOption.label : "");

                                        return (<div className="no-overflow" >{label}</div>);
                                    }
                                }
                            },

                            {
                                Header: "",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                Cell: (row) => {

                                    let isEditing = (row.original.index === editingIndex);

                                    if (!isEditing)
                                        return (
                                            <div align="right">
                                                <ButtonWithTooltip
                                                    disabled={true}
                                                    id={"per2_edit_" + row.index}
                                                    color="warning"
                                                    size="sm"
                                                    tooltipText={t("Modifica")}
                                                    onClick={() => {

                                                        this.setState({
                                                            isNew: false,
                                                            editingIndex: row.original.index,
                                                            editingItem: JSON.parse(JSON.stringify(row.original))
                                                        });

                                                    }}
                                                >
                                                    <i className="fa fa-edit" />
                                                </ButtonWithTooltip>

                                                {/* {" "}

                                <ButtonWithTooltip
                                  id={"per2_del_" + row.index}
                                  onClick={() => {

                                    this.confirmDelete(
                                      t("Elimina uscita digitale"),
                                      () => {

                                        let index = row.original.index.split("_");

                                        let periferals = conf.configParam.expansions[index[0]].periferals.filter(
                                          ( (curr, index_per)=>{
                                            return (index_per!==index[1])
                                          } )
                                        );
                                        conf.configParam.expansions[index[0]].periferals=periferals;
                                        dispatch( loopbackActions.edit('plant','configurations',conf,'PATCH'));
                                        this.setState({
                                          alert: null
                                        });

                                      }

                                    );

                                  }}
                                  color="danger"
                                  size="sm"
                                  tooltipText={t("Elimina")}
                                >
                                  <i className="fa fa-trash" />
                                </ButtonWithTooltip> */}
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="actions-right">
                                                <ButtonWithTooltip
                                                    id={"per2_edit_" + row.index}
                                                    color="success"
                                                    size="sm"
                                                    tooltipText={t("Salva")}
                                                    onClick={() => {
                                                        let index = row.original.index.split("_");
                                                        conf.configParam.expansions[index[0]]['periferals'][index[1]] = editingItem;
                                                        conf.dateUpdate = moment().toISOString(true);
                                                        dispatch(loopbackActions.edit('plant', 'configurations', conf, 'PATCH'));
                                                    }}
                                                >
                                                    <i className="fa fa-check" />
                                                </ButtonWithTooltip>

                                                {" "}

                                                <ButtonWithTooltip
                                                    id={"per2_del_" + row.index}
                                                    onClick={() => {
                                                        if (this.state.isNew) {
                                                            let index = row.original.index.split("_");
                                                            conf.configParam.expansions[index[0]]['periferals'].splice(index[1], 1);
                                                            this.setState({ isNew: false, editingIndex: -1, conf });
                                                        }
                                                        else
                                                            this.setState({ editingIndex: -1 });
                                                    }}
                                                    color="secondary"
                                                    size="sm"
                                                    tooltipText={t("Annulla")}
                                                >
                                                    <i className="fa fa-times" />
                                                </ButtonWithTooltip>
                                            </div>
                                        )

                                }
                            }

                        ]}
                    // defaultPageSize={10}
                    />
                </CardBody>
            </Card>
        )

    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

export default PeriferalsDigitalOutput = withTranslation()(connect(mapStateToProps)(PeriferalsDigitalOutput));

import { accessToken } from '../helpers';
import { loopbackConstants } from '../constants';
import User from '../entities/User'
import { history } from '../helpers';

export const userService = {
    login,
    logout,
    add,
    edit,
    getAll,
    getById,
    delete: _delete,
    filterAllNoLimit,
    filterCount,
    filter,
    getImageByUserId,
    postImageByUserId,
    deleteImageByUserId
};

/**
 * Restituisce e memorizza nel local storage il token di autenticazione.
 *
 * @param {String} email utente.
 * @param {String} password utente.
 * @return {Object} token di autenticazione.
 * @throws {Object} risposta in caso di fallimento.
 */
async function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/users/login', requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (!response.ok) {
        throw response;
    }
    let token = await response.json();
    if (token && token.id) {
        localStorage.setItem('token', JSON.stringify(token));
        return token;
    }
    else {
        throw Object.assign(
            new Error('Impossibile salvare il token di autenticazione!'),
            { status: -101 }
        );
    }
}

/**
 *  Restituisce l'elenco degli utenti senza limitazioni al numero di record restituiti.
 *
 *  @param {Object} filtri di ricerca.
 *  @return {Object[]} utenti filtrati
 *  @throws {Object} risposta in caso d'errore.
 */
async function filterAllNoLimit(filtersToUse) {
    const requestOptions = {
        method: 'GET'
    };
    let filters = false
    if (filtersToUse && filtersToUse.where) {
        filters = {
            where: filtersToUse.where
        }
    }
    let response = await fetch(
        (loopbackConstants.BASE_URL + '/admin/users' + accessToken('admin') + (
            filters ? '&filter=' + encodeURIComponent(JSON.stringify(filters)) : ''
        )
        ), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response.json();
    }
    else {
        throw response;
    }
}

/**
 *  Conta il numero di record che soddisfano i filtri passati come argomento.
 *
 *  @param {Object} filtri di ricerca.
 *  @return {Number} numero di record.
 *  @throws {Object} risposta in caso d'errore.
 */
async function filterCount(filters) {
    const requestOptions = {
        method: 'GET'
    }

    let response = await fetch(loopbackConstants.BASE_URL + '/admin/users/count' + accessToken('admin') + (
        filters && filters.where ? '&where=' + encodeURIComponent(JSON.stringify(filters.where)) : ''
    ), requestOptions);

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 *  Elenca gli utenti che soddisfano i filtri di ricerca passati come argomento.
 *
 *  @param {Object} filtri di riderca.
 *  @return {Object []} utenti filtrati.
 *  @throws {Object} risposta in caso di fallimento.
 */
async function filter(filters) {
    const requestOptions = {
        method: 'GET'
    };
    let response = await fetch(
        (loopbackConstants.BASE_URL + '/admin/users' + accessToken('admin') + (
            filters ? '&filter=' + encodeURIComponent(JSON.stringify(filters)) : ''
        )
        ), requestOptions);

    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 *  Disconnette l'utente dal sistema.
 */
 function logout() {
    // Rimuove l'utente dal local storage.
    localStorage.removeItem('user');
    localStorage.removeItem('selectedProjectId');
}

/**
 *    Restituisce il record associato all'id utente passato come argomento.
 *
 *    @param id number
 *        identificatore utente;
 *    @return {Object} user
 *    @throw {Object} risposta in caso di fallimento.
 */
async function getById(id) {
    const requestOptions = {
        method: 'GET'
    };
    let response = await fetch(
        loopbackConstants.BASE_URL + '/admin/users/' + id + accessToken('admin'),
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (!response.ok) {
        throw response;
    }
    let user = await response.json();
    return user;
}

/**
 * Restituisce tutti gli utenti.
 *
 *  @return {Object[]} array di user.
 *  @throws {Object} risposta in caso di fallimento.
 */
async function getAll() {
    const requestOptions = {
        method: 'GET'
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/users' + accessToken('admin'), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 *  Crea un nuovo utente.
 *
 *   @param {Object} user da inserire.
 *   @return {Object} user inserito.
 *   @throws {Object} risposta in caso di fallimento.
 */

async function add(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    let response = await fetch(
        loopbackConstants.BASE_URL + '/admin/users' + accessToken('admin'),
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    let data = await response.json();
    if (response.ok) {
        return data;
    }
    else {
        throw data;
    }
}

/**
 *  Modifica un utente esistente.
 *
 * @param {Object} user da modificare.
 * @return {Object} user modificato.
 * @throws {Object} risposta in caso di fallimento.
 */
async function edit(user) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    let response = await fetch(
        loopbackConstants.BASE_URL + '/admin/users/' + user.id + accessToken('admin'),
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    let data = await response.json();
    if (response.ok) {
        const loggedinUser = User.current();
        // Se l'utente modificato è l'utente corrente, aggiorno la cache locale.
        if (Number(loggedinUser.id) === Number(data.id)) {
            localStorage.setItem('user', JSON.stringify(data));
        }
        return data;
    }
    else {
        throw data;
    }
}

/**
 * Elimina un utente.
 *
 *  @param {Number} id utente da eliminare.
 *  @return {Object} risultato dell'eliminazione.
 *  @throws {Object} risposta in caso di fallimento.
 */
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE'
    };
    let response = await fetch(loopbackConstants.BASE_URL + '/admin/users/' + id + accessToken('admin'), requestOptions);
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function getImageByUserId(id) {
    const requestOptions = {
        method: 'GET'
    };

    const url = loopbackConstants.BASE_URL + '/document/icon' + accessToken('admin') + '&userId=' + id;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        let data = await response.json();
        return data;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function deleteImageByUserId(id, name) {
    const requestOptions = {
        method: 'DELETE'
    };

    const url = loopbackConstants.BASE_URL + '/document/delete' + accessToken('admin') + '&userId=' + id + '&name=' + name;

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

/**
 * Restituisce l'icona progetto corrispondente all'id passato come argomento.
 *
 * @param {Number} id
 * @return {Object} project media
 * @throws {Object} risposta in caso d'errore.
 */
async function postImageByUserId(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const url = loopbackConstants.BASE_URL + '/document/upload/icon' + accessToken('admin');

    let response = await fetch(
        url,
        requestOptions
    );
    if (response.status === 401) {
        history.push('/pages/login-page');
    }

    if (response.ok) {
        return response;
    }
    else {
        throw response;
    }
}

// function handleResponse(response) {
//     if (!response.ok) {
//         // Anche questa reject serve (generica).
//         return Promise.reject(response);
//     }
//     return response.json();
// }

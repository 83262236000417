import React, { Component } from "react";
import { utils } from '../../helpers';
import { withTranslation } from 'react-i18next';
import {
    Card,
    CardBody,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Select from "react-select";
import moment from 'moment';
import Datetime from "react-datetime";
import DiscreteValues from "../../constants/slider.constants";
import Nouislider from "react-nouislider";
import DailyChart from "./DailyChart";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import ErrorModal from "../Components/ErrorModal"
import ReactTable from "react-table";
import { loopbackService } from '../../services';
import logoDelete from "assets/img/delete.svg";
import { roleConstants } from '../../constants';
import User from '../../entities/User';

class DailyProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            alert: null,
            selectedProfile: props.selectedProfile,
            isEditingProfile: props.isEditingProfile,
            editingProfile: props.editingProfile,
            saving: false,
            validationError: null
        };

        const { t } = props;
        this.transitionModeOptions = [
            { label: t("Alba"), value: "sunrise" },
            { label: t("Tramonto"), value: "sunset" },
            // { label: t("Mezzanotte"), value: "midnight" },
            { label: t("Assoluto"), value: "absolute" }
        ];

        this.checkPreSubmit = this.checkPreSubmit.bind(this);
        this.createNewTransition = this.createNewTransition.bind(this);
        this.deleteTransition = this.deleteTransition.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    render() {
        let { errors } = this.state;

        return (
            <div>
                {this.state.alert}
                {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}
                {this.renderTransitions()}
            </div>
            /*<Card>
                <CardHeader className="main-wrapper">
                    <CardTitle>
                        <div className="form-name">
                            <Input
                                name="label"
                                type="text"
                                value={label}
                                disabled={!isEditingProfile}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    editingProfile[name] = value;
                                    this.setState({ editingProfile });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.saveProfile();
                                    }
                                }}
                            />

                            <div className="form-actions">
                                {!isEditingProfile &&
                                    <ButtonWithTooltip
                                        id="profile_edit"
                                        color="warning"
                                        tooltipText={t("Modifica")}
                                        size="sm"
                                        disabled={isGuest}
                                        onClick={() => {
                                            this.setState({
                                                isEditingProfile: true,
                                                editingProfile: JSON.parse(JSON.stringify(selectedProfile))
                                            })
                                        }}
                                    ><i className="fa fa-edit" />
                                    </ButtonWithTooltip>
                                }
                                {!isEditingProfile &&
                                    <ButtonWithTooltip
                                        id="profile_delete"
                                        color="danger"
                                        size="sm"
                                        tooltipText={t("Elimina")}
                                        disabled={isGuest}
                                        onClick={() => { this.confirmDeleteProfile(); }}
                                    >
                                        <i className="fa fa-trash" />
                                    </ButtonWithTooltip>
                                }
                                {isEditingProfile &&
                                    <ButtonWithTooltip
                                        id="profile_save"
                                        color="success"
                                        size="sm"
                                        disabled={saving}
                                        tooltipText={t("Salva")}
                                        onClick={() => {
                                            this.saveProfile();
                                        }}
                                    >
                                        {!saving && <i className="fa fa-check" />}
                                        {saving && <i style={{ position: 'unset' }} className="fa fa-refresh fa-spin" />}
                                    </ButtonWithTooltip>
                                }
                                {isEditingProfile &&
                                    <ButtonWithTooltip
                                        id="profile_cancel"
                                        color="default"
                                        size="sm"
                                        tooltipText={t("Annulla")}
                                        onClick={() => {
                                            handleCancelProfile();
                                            //this.setState({ editingProfile: null, isEditingProfile: false })
                                        }}
                                    >
                                        <i className="fa fa-times" />
                                    </ButtonWithTooltip>
                                }
                            </div>
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardBody className="no-padding">
                    {this.state.alert}
                    {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}
                    {this.renderTransitions()}
                </CardBody>
            </Card>*/
        );
    }

    checkPreSubmit() {
        const { selectedProfile } = this.props;
        const { isEditingProfile, editingProfile } = this.state;
        let label = isEditingProfile ? editingProfile !== null ? editingProfile.label : null : selectedProfile !== null ? selectedProfile.label : null;

        return (!(label)) ? true : false;
    }

    /**
     */
    renderTransitions() {
        const { selectedProfile, t, handleCancelProfile, legacyTimer } = this.props;
        const { isEditingProfile, editingProfile, saving, validationError } = this.state;

        const user = User.current();
        const isGuest = (user.userRoleId === roleConstants.GUEST);

        let label = isEditingProfile ? editingProfile !== null ? editingProfile.label : "" : selectedProfile !== null ? selectedProfile.label : "";
        let transitions = [];

        if (editingProfile === null && selectedProfile === null)
            return null;

        // Controllo se valorizzato il parametro dailyTransitions
        let profile = isEditingProfile ? editingProfile : selectedProfile;
        if (profile['dailyTransitions'] === undefined) {
            for (let i = 1; i <= 5; i++) {
                let key = 'transition' + i;
                let transition = profile[key];
                if (transition) {
                    transitions.push(transition);
                }
            }
        }
        else {
            // Nuova gestione profili timer
            for (let trans of profile['dailyTransitions']) {
                /*if(trans.type=='plantOnOff') {
                  if (trans.outValue == 104) {
                    trans.outValue = 100;
                  }
                  if (trans.outValue == 102) {
                    trans.outValue = 0;
                  }
                }*/
                transitions.push(trans);
            }
        }

        // Render valore outValue
        const renderOutValue = (val) => {
            switch (val) {
                case null: return t("SPENTO");
                case 0: return t("SPENTO");
                case -10: return t("PLANT OFF");
                case 110: return t("PLANT ON");
                case 126: return t("DISABLED");
                default: return val + "%";
            }
        }

        return (
            <div className="details-wrapper">
                <div className="form-wrapper">
                    <div className="form-label">
                        <Input
                            name="label"
                            type="text"
                            value={label}
                            disabled={!isEditingProfile}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                editingProfile[name] = value;
                                this.setState({ editingProfile });
                            }}
                            style={(!(label && label.length > 0)) ? { borderColor: "#ff7777" } : {}}
                        />
                    </div>
                    <div className="form-actions">
                        {!isEditingProfile &&
                            <ButtonWithTooltip
                                id="profile_edit"
                                className="umpi-rounded-btn-toolbar"
                                color="white"
                                tooltipText={t("Modifica")}
                                disabled={isGuest || this.checkPreSubmit()}
                                onClick={() => {
                                    this.setState({
                                        isEditingProfile: true,
                                        editingProfile: JSON.parse(JSON.stringify(selectedProfile))
                                    })
                                }}>
                                <i className="fa fa-pencil" />
                            </ButtonWithTooltip>
                        }
                        {!isEditingProfile &&
                            <ButtonWithTooltip
                                id="profile_delete"
                                className="umpi-rounded-btn-toolbar"
                                color="white"
                                tooltipText={t("Elimina")}
                                disabled={isGuest}
                                onClick={() => { this.confirmDeleteProfile(); }}>
                                <i className="fa fa-trash" />
                            </ButtonWithTooltip>
                        }
                        {isEditingProfile &&
                            <ButtonWithTooltip
                                id="profile_save"
                                className="umpi-rounded-btn-toolbar"
                                color="white"
                                disabled={saving || this.checkPreSubmit()}
                                tooltipText={t("Salva")}
                                onClick={() => {
                                    this.saveProfile();
                                }}>
                                {!saving && <i className="fa fa-check" />}
                                {saving && <i style={{ position: 'unset' }} className="fa fa-refresh fa-spin" />}
                            </ButtonWithTooltip>
                        }
                        {isEditingProfile &&
                            <ButtonWithTooltip
                                id="profile_cancel"
                                className="umpi-rounded-btn-toolbar"
                                color="white"
                                tooltipText={t("Annulla")}
                                onClick={() => {
                                    handleCancelProfile();
                                    //this.setState({ editingProfile: null, isEditingProfile: false })
                                }}
                            >
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        }
                    </div>
                </div>

                <div className="chart-wrapper" >
                    <DailyChart transitions={transitions} />
                </div>

                <Card className="transaction-wrapper">
                    {/*<CardHeader className="config-card-header">
                        <CardTitle className="config-card-title" >
                            <span style={{ flex: 1 }}>{t("Transizioni")}</span>
                        </CardTitle>
                    </CardHeader>*/}
                    <CardBody className="no-padding">
                        {transitions.length > 0 ? (
                            <ReactTable
                                data={transitions}
                                className="-striped -highlight configurations"
                                pageSize={transitions && transitions.length}
                                showPagination={false}
                                NoDataComponent={() => null}
                                columns={[
                                    ...[
                                        {
                                            Header: "",
                                            accessor: "",
                                            filterable: false,
                                            sortable: false,
                                            width: 55,
                                            Cell: (row) => {
                                                let disabledFlag = "disabled";
                                                if (isEditingProfile && legacyTimer === false) {
                                                    disabledFlag = "";
                                                }
                                                return (
                                                    <div className="no-overflow" align="">
                                                        <input
                                                            type="checkbox"
                                                            name={(row.value && row.value.id > 0) ? row.value.id : 0}
                                                            checked={(row.value && row.value.enable === true) ? "checked" : ""}
                                                            disabled={disabledFlag}
                                                            onChange={option => {
                                                                let profile = undefined;
                                                                if (option && option.target !== null) {
                                                                    if (legacyTimer === true) {
                                                                        let key = 'transition' + (row.index + 1);
                                                                        profile = editingProfile[key];
                                                                    } else {
                                                                        profile = editingProfile.dailyTransitions[row.index];
                                                                    }
                                                                    profile.enable = option.target.checked;
                                                                    this.setState({ editingProfile });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            Header: "#",
                                            accessor: "",
                                            filterable: false,
                                            sortable: false,
                                            width: 60,
                                            Cell: (row) => {
                                                return (<div className="no-overflow" align="">{row.index + 1}</div>)
                                            }
                                        },
                                        {
                                            Header: t("Modo"),
                                            accessor: "mode",
                                            filterable: false,
                                            sortable: false,
                                            minWidth: 100,
                                            maxWidth: 150,
                                            Cell: (row) => {
                                                const selectedOption = utils.getSelectedOption(this.transitionModeOptions, row.value);
                                                if (isEditingProfile) {
                                                    return (
                                                        <Select
                                                            closeOnSelect={true}
                                                            placeholder={t("Modo")}
                                                            name="mode"
                                                            clearable={false}
                                                            value={selectedOption}
                                                            options={this.transitionModeOptions}
                                                            onChange={option => {
                                                                let profile = undefined;
                                                                if (option && option.value !== null) {
                                                                    if (legacyTimer === true) {
                                                                        let key = 'transition' + (row.index + 1);
                                                                        profile = editingProfile[key];
                                                                    } else {
                                                                        profile = editingProfile.dailyTransitions[row.index];
                                                                    }
                                                                    profile.mode = option.value;
                                                                    if (profile.mode === "sunset" || profile.mode === "sunrise" || profile.mode === "absolute") {
                                                                        profile.timer = 0;
                                                                    }
                                                                    this.setState({ editingProfile });
                                                                }
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    return (<div className="no-overflow" >{selectedOption && selectedOption.label}</div>);
                                                }
                                            }
                                        },
                                        {
                                            Header: t("Time / Offset"),
                                            accessor: "timer",
                                            filterable: false,
                                            sortable: false,
                                            minWidth: 80,
                                            maxWidth: 190,
                                            Cell: (row) => {

                                                let value = "";
                                                // let key = "timer_"+row.index+ (validationError && validationError.field == 'timer' ? moment().unix(): "");

                                                if (row.original.mode === 'absolute') {

                                                    let raw = row.value ? parseFloat(row.value) : 0;
                                                    let hours = ("" + Math.floor(raw / 60)).padStart(2, "0");
                                                    let mins = ("" + (raw % 60)).padStart(2, "0");

                                                    value = hours + ":" + mins;

                                                    if (isEditingProfile) {
                                                        return (
                                                            <React.Fragment>
                                                                <Datetime
                                                                    // key = { key }
                                                                    dateFormat={false}
                                                                    timeFormat={"HH:mm"}
                                                                    value={value}
                                                                    inputProps={{
                                                                        disabled: ((editingProfile.dailyTransitions && editingProfile.dailyTransitions.length > 0 && editingProfile.dailyTransitions[row.index].enable === false) ? true : false)
                                                                    }}
                                                                    onChange={(time) => {
                                                                        this.assignTime(row, time);
                                                                    }}
                                                                    onBlur={(time) => {
                                                                        if (!this.assignTime(row, time)) {
                                                                            if (legacyTimer === true) {
                                                                                let key = "transition" + (row.index + 1);
                                                                                editingProfile[key].timer = null;
                                                                            }
                                                                            else {
                                                                                editingProfile.dailyTransitions[row.index].timer = null;
                                                                            }
                                                                            this.setState({ editingProfile });
                                                                        }
                                                                    }}
                                                                />
                                                                {validationError && validationError.field === 'timer' &&
                                                                    <span className="validationError">{validationError.error}</span>
                                                                }
                                                            </React.Fragment>

                                                        );

                                                    }

                                                } else {

                                                    value = row.value;

                                                    if (isEditingProfile) {

                                                        return (
                                                            <Input
                                                                type="number"
                                                                value={value !== null ? value : ""}
                                                                disabled={(editingProfile.dailyTransitions && editingProfile.dailyTransitions.length > 0 && editingProfile.dailyTransitions[row.index].enable === false) ? true : false}
                                                                onChange={(e) => {
                                                                    const { value } = e.target;

                                                                    if (legacyTimer === true) {
                                                                        let key = "transition" + (row.index + 1);
                                                                        editingProfile[key].timer = parseInt(value);
                                                                    }
                                                                    else {
                                                                        editingProfile.dailyTransitions[row.index].timer = parseInt(value);
                                                                    }
                                                                    this.setState({ editingProfile })
                                                                }}
                                                            />
                                                        );
                                                    }
                                                }
                                                return (<div className="no-overflow" >{value}</div>);
                                            }
                                        },
                                        {
                                            Header: t("Valore"),
                                            accessor: "outValue",
                                            filterable: false,
                                            sortable: false,
                                            Cell: (row) => {

                                                // Adattamento valori
                                                if (row.value === 102) {
                                                    row.value = -10;
                                                }
                                                if (row.value === 104) {
                                                    row.value = 110;
                                                }

                                                if (isEditingProfile) {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", }}>
                                                            <span style={{ flex: 1, paddingLeft: "15px", paddingRight: "15px" }}>
                                                                <Nouislider
                                                                    start={[(row.value === null ? 0 : row.value)]}
                                                                    connect={[true, false]}
                                                                    snap={(legacyTimer === true) ? true : false}
                                                                    disabled={(editingProfile.dailyTransitions && editingProfile.dailyTransitions.length > 0 && editingProfile.dailyTransitions[row.index].enable === false) ? true : false}
                                                                    step={10}
                                                                    range={(legacyTimer === true) ? DiscreteValues.legacy : DiscreteValues.default}
                                                                    onSlide={(value) => {
                                                                        if (legacyTimer === true) {
                                                                            let key = "transition" + (row.index + 1);
                                                                            editingProfile[key].outValue = value[0];
                                                                            this.setState({ editingProfile })
                                                                        }
                                                                        else {
                                                                            let val = parseInt(value[0]);
                                                                            if (val === -10) {
                                                                                editingProfile.dailyTransitions[row.index].outValue = 102;
                                                                            }
                                                                            else if (val === 110) {
                                                                                editingProfile.dailyTransitions[row.index].outValue = 104;
                                                                            }
                                                                            else {
                                                                                editingProfile.dailyTransitions[row.index].outValue = val;
                                                                            }
                                                                            //editingProfile.dailyTransitions[row.index].outValue = parseInt(value[0])
                                                                            if (editingProfile.dailyTransitions[row.index].outValue === 102 || editingProfile.dailyTransitions[row.index].outValue === 104) {
                                                                                editingProfile.dailyTransitions[row.index].type = 'plantOnOff';
                                                                            } else {
                                                                                editingProfile.dailyTransitions[row.index].type = 'dimmering';
                                                                            }
                                                                            this.setState({ editingProfile })
                                                                        }
                                                                    }}
                                                                    onChange={(value) => {
                                                                        if (legacyTimer === true) {
                                                                            let key = "transition" + (row.index + 1);
                                                                            editingProfile[key].outValue = parseInt(value[0]);
                                                                            this.setState({ editingProfile })
                                                                        }
                                                                        else {
                                                                            let val = parseInt(value[0]);
                                                                            if (val === -10) {
                                                                                editingProfile.dailyTransitions[row.index].outValue = 102;
                                                                            }
                                                                            else if (val === 110) {
                                                                                editingProfile.dailyTransitions[row.index].outValue = 104;
                                                                            }
                                                                            else {
                                                                                editingProfile.dailyTransitions[row.index].outValue = val;
                                                                            }
                                                                            //editingProfile.dailyTransitions[row.index].outValue = parseInt(value[0])
                                                                            if (editingProfile.dailyTransitions[row.index].outValue === 102 || editingProfile.dailyTransitions[row.index].outValue === 104) {
                                                                                editingProfile.dailyTransitions[row.index].type = 'plantOnOff';
                                                                            }
                                                                            else {
                                                                                editingProfile.dailyTransitions[row.index].type = 'dimmering';
                                                                            }
                                                                            this.setState({ editingProfile })
                                                                        }
                                                                    }}
                                                                /></span>
                                                            <span style={{ flex: 1, textAlign: "center" }}>
                                                                {
                                                                    //((row.value == null || row.value == 0) ? t("SPENTO") : (row.value == 100 ? t("ACCESO") : parseInt(row.value) + " %"))
                                                                    (row.original.enable && row.original.enable === true) ? renderOutValue(row.value) : renderOutValue(126)
                                                                    //renderOutValue(row.value)
                                                                }
                                                            </span>

                                                        </div>
                                                    );
                                                }
                                                else {

                                                    return (
                                                        <div style={{ textAlign: "center" }}>
                                                            {
                                                                //((row.value == null || row.value == 0) ? t("SPENTO") : (row.value == 100 ? t("ACCESO") : row.value + " %"))
                                                                //(row.row._original && row.row._original.enable && row.row._original.enable===false) ? renderOutValue(126) : renderOutValue(row.value)
                                                                (row.original.enable && row.original.enable === true) ? renderOutValue(row.value) : renderOutValue(126)
                                                                //renderOutValue(row.value)
                                                            }
                                                        </div>
                                                    );
                                                }
                                            }
                                        }
                                    ],
                                    ...((isEditingProfile) ? [{
                                        Header: "",
                                        accessor: "",
                                        filterable: false,
                                        sortable: false,
                                        width: 80,
                                        Cell: (row) => {
                                            return (
                                                <div className="no-overflow" align="right">
                                                    <ButtonWithTooltip
                                                        className="umpi-modal-action-btn-del"
                                                        id={"deleteTransition_" + (row.index + 1)}
                                                        onClick={() => {
                                                            if (legacyTimer === true) {
                                                                this.deleteTransition("transition" + (row.index + 1))
                                                            } else {
                                                                this.deleteTransition(row.value.id)
                                                            }
                                                        }}
                                                        size="sm"
                                                        icon
                                                        tooltipText={t("Elimina")}>
                                                        <img className="table-button img-grayscale" src={logoDelete} alt="Elimina" />
                                                    </ButtonWithTooltip>
                                                </div>
                                            )
                                        }
                                    }] : [])
                                ]}
                            />
                        ) : (
                            <div align="center" style={{ padding: "20px" }}>
                                {t('No transition available')}
                            </div>
                        )}
                        {isEditingProfile ? (
                            <div align="center">
                                <Button
                                    disabled={
                                        (legacyTimer === true) ?
                                        transitions.length >= 5 :
                                        transitions.length >= 12
                                    }
                                    className="btn-simple umpi-btn-item-properties"
                                    onClick={() => this.createNewTransition()}
                                    size="sm">
                                    + {t("Add new transition")}
                                </Button>
                            </div>
                        ) : null}
                    </CardBody>
                </Card>
            </div>
        );
    }

    createNewTransition() {
        const { legacyTimer } = this.props;
        let { editingProfile } = this.state;

        if (legacyTimer === true) {
            let keysEditingProfile = Object.keys(editingProfile).filter(x => x.startsWith("transition") && editingProfile[x]);
            let newKey = "transition" + (keysEditingProfile.length + 1);

            if (keysEditingProfile.length < 5) {
                editingProfile[newKey] = {
                    mode: "sunrise",
                    timer: 0,
                    outValue: 0,
                    enable: true,
                    type: "dimmering"
                };
            }
        } else {
            if (editingProfile["dailyTransitions"].length < 12) {
                editingProfile["dailyTransitions"].push({
                    "id": (editingProfile["dailyTransitions"].length + 1),
                    "enable": false,
                    "mode": "absolute",
                    "type": "dimmering",
                    "timer": 0,
                    "outValue": 0
                });
            }
        }

        this.setState({ editingProfile });
    }

    deleteTransition(transitionKey) {
        const { legacyTimer } = this.props;
        let { editingProfile } = this.state;

        if (legacyTimer === true) {
            delete editingProfile[transitionKey];
        } else {
            editingProfile["dailyTransitions"] = editingProfile[
                "dailyTransitions"
            ].filter((item) => {
                return item.id !== transitionKey;
            });
        }

        this.setState({ editingProfile });
    }

    assignTime(row, time) {
        const { t, legacyTimer } = this.props;
        let { editingProfile } = this.state;
        if (time && time instanceof moment) {
            if (!time.isValid()) {
                this.setState({ validationError: { field: 'timer', error: t("Formato orario non valido") } });
                return false;
            }
            let hours = parseInt(time.format("HH"));
            let mins = parseInt(time.format("mm"));
            if (legacyTimer === true) {
                let key = "transition" + (row.index + 1);
                editingProfile[key].timer = hours * 60 + mins;
            }
            else {
                editingProfile.dailyTransitions[row.index].timer = hours * 60 + mins;
            }
            this.setState({ editingProfile, validationError: null })
            return true;
        }
        return false;
    }

    async saveProfile() {
        try {
            let { handleSavedProfile } = this.props;
            let { editingProfile } = this.state;
            this.setState({ saving: true });

            let savedProfile = await loopbackService.edit('plant', 'timerprofiledaily', editingProfile, "PUT");
            handleSavedProfile(savedProfile);

            this.setState({
                isEditingProfile: false,
                editingProfile: null,
                saving: false
            });
        } catch (error) {
            this.setState({ errors: [error], saving: false });
        }
    }

    confirmDeleteProfile() {
        const { t } = this.props;
        const { selectedProfile } = this.state;

        if (!selectedProfile) {
            return;
        }

        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Elimina") + " " + selectedProfile.label}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={() => {
                            this.deleteProfile();
                            this.setState({
                                alert: null
                            });
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    async deleteProfile() {
        let { selectedProfile, handleDeletedProfile, t } = this.props;
        try {

            let res = await loopbackService.list("plant", "timerprofileweekly", {
                or: [
                    { monFriProfileId: selectedProfile.id },
                    { satProfileId: selectedProfile.id },
                    { sunProfileId: selectedProfile.id }
                ]
            });

            if (res.length > 0) {

                this.setState({
                    alert: (
                        <Modal isOpen={true} className="modal-umpi-container">
                            <ModalHeader toggle={() => {
                                this.setState({
                                    alert: null
                                });
                            }}>
                                {t("Impossibile eliminare il profilo")}
                            </ModalHeader>
                            <ModalBody>
                                <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                                    <i className='fa fa-exclamation-circle' />
                                </div>
                                <div style={{ textAlign: "center", fontSize: "1em", margin: "-30px 0 30px 0" }}>
                                    {t("Viene utilizzato nel profilo settimanale") + ": " + res[0].label}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={async () => {
                                    this.setState({
                                        alert: null
                                    });
                                }} color="success" className="umpi-modal-btn-save">
                                    {t("Confirm")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                });

                return;
            }

            await loopbackService.delete("plant", "timerprofiledaily", selectedProfile.id);
            handleDeletedProfile();
        } catch (error) {
            this.setState({
                errors: [error]
            })
        }
    }

}

export default DailyProfile = withTranslation()(DailyProfile);

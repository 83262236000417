import React from "react";
export default function Bulb(props) {
	return (


		<svg  height={props.height} width={props.width} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xmlSpace="preserve">


		<defs>
			<linearGradient id="grad1"  y2="0%" x2="100%"  >
				<stop offset="0%" stopColor="yellow" stopOpacity="100%" />
				<stop offset="100%" stopColor="red" stopOpacity="100%" />
			</linearGradient>

			<linearGradient id="grad2"  y2="0%" x2="100%"  >
				<stop offset="0%" stopColor="yellow" stopOpacity="100%" />
				<stop offset="100%" stopColor="red" stopOpacity="100%" />
			</linearGradient>

		</defs>


			<g fill={props.fill} stroke={props.stroke} strokeLinecap="square" strokeWidth={props.strokeWidth} >
				<path 
					d="M19 6.734c0 4.672-4.25 7.079-4.25 12.266h-5.5c0-5.187-4.25-7.594-4.25-12.266 0-4.343 3.498-6.734 6.996-6.734 3.502 0 7.004 2.394 7.004 6.734zm-4.75 13.266h-4.5c-.276 0-.5.224-.5.5s.224.5.5.5h4.5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-5l1.451 1.659c.19.216.464.341.753.341h.593c.288 0 .563-.125.752-.341l1.451-1.659z"
				/>
			</g>
		</svg>

	)
}

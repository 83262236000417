import { connect } from 'react-redux';
import React, { Component } from "react";
import {
    Nav,
    NavLink,
    Collapse
} from "reactstrap";
import { withTranslation } from 'react-i18next';

var defaultMenuItem = {
    name: "espansioni",
    icon: "files_single-copy-04",
}

class ConfSideMenuComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillReceiveProps() {
    }

    isActiveMenuItem(label) {
        const { selectedMenuItem } = this.props;
        return (selectedMenuItem && selectedMenuItem.label === label) ? "active" : "";
    }

    render() {
        const { t, onMenuItemClicked } = this.props;

        const menuData = [];

        defaultMenuItem.label = t("Generale/Espansioni");

        // Espansioni
        menuData.push(
            defaultMenuItem
        );

        // Periferiche
        let views = [];

        views.push(
            {
                name: "dig_in",
                label: t("Ingressi digitali"),
                mini: "DI",
            }
        );

        views.push(
            {
                name: "dig_out",
                label: t("Uscite digitali"),
                mini: "DO",
            }
        );

        views.push(
            {
                name: "ana_in",
                label: t("Ingressi analogici"),
                mini: "AI"
            }
        );

        const { conf } = this.props;
        let showAnaOut = false;
        let wattmeterExpansions = [];

        let lampDeviceExpansions = [];

        if (conf && conf.configParam && conf.configParam.expansions) {
            conf.configParam.expansions.forEach((expansion, keyexp) => {
                if (showAnaOut === false && (expansion.type === 'andros2a' || expansion.type === 'andros4a')) {
                    showAnaOut = true;
                }
                // wattmetri
                if (expansion.type === "wattmeter") {
                    wattmeterExpansions.push(keyexp);
                }
                // dispositivi lampada
                else if (expansion.type === "androsPls" || expansion.type === "androsPlr") {
                    if (lampDeviceExpansions.length === 0 && expansion.periferals && expansion.periferals.length > 0) {
                        lampDeviceExpansions.push(keyexp);
                    }
                }
            })
        }

        if (showAnaOut) {
            views.push(
                {
                    name: "ana_out",
                    label: t("Uscite analogiche"),
                    mini: "AO"
                }
            );
        }

        wattmeterExpansions.map((expIndex, key) => {
            let expansion = conf.configParam.expansions[expIndex];
            views.push(
                {
                    name: "wattmetro",
                    label: t("Wattmetro") + " " + expansion.address,
                    mini: "W" + expansion.address,
                    expIndex,
                    key
                }
            );
            return false;
        });

        menuData.push(
            {
                collapse: true,
                name: "periferiche",
                label: t("Periferiche"),
                icon: "shopping_credit-card",
                views
            }
        );

        lampDeviceExpansions.map((expIndex, key) => {
            //let expansion = conf.configParam.expansions[expIndex];
            menuData.push(
                {
                    name: "starting_points_and_groups",
                    label: t("Partenze e gruppi"),
                    icon: "business_bulb-63",
                    expIndex,
                    key
                }
            );
            return false;
        });

        // Comandi
        views = [];

        views.push(
            {
                name: "cmd_timer_weekly",
                label: t("Timer settimanali"),
                mini: "WT"
            }
        );

        views.push(
            {
                name: "cmd_timer_astro",
                label: t("Timer astronomici"),
                mini: "AT"
            }
        );

        views.push(
            {
                name: "cmd_dig_in",
                label: t("Cmd ingressi digitali"),
                mini: "TD"
            }
        );

        menuData.push(
            {
                collapse: true,
                name: "comandi",
                label: t("Comandi"),
                icon: "media-1_button-play",
                views
            }
        );

        // Eventi/allarmi
        menuData.push(
            {
                name: "events",
                label: t("Eventi/Allarmi"),
                icon: "ui-1_bell-53"
            }
        );

        return (

            <div className="sidebar-contextual" data-color="blue" >
                <div className="sidebar-contextual-wrapper" ref="sidebar-contextual">
                    <Nav >
                        {menuData.map((menuItem, key) => {

                            if (menuItem.collapse) {

                                var st = {};

                                st[menuItem.label] = !this.state[menuItem.label];

                                return (

                                    <li className={this.isActiveMenuItem(menuItem.label)} key={key}>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            data-toggle="collapse"
                                            aria-expanded={this.state[menuItem.label]}
                                            onClick={() => this.setState(st)}
                                        >
                                            <i className={"now-ui-icons " + menuItem.icon} />
                                            <p>
                                                {menuItem.label}
                                                <b className="caret" />
                                            </p>
                                        </a>

                                        <Collapse isOpen={this.state[menuItem.label]}>
                                            <ul className="nav">
                                                {menuItem.views.map((subMenuItem, subKey) => {

                                                    return (
                                                        <li
                                                            className={this.isActiveMenuItem(subMenuItem.label)}
                                                            key={subKey}
                                                        >
                                                            <NavLink
                                                                onClick={() => {
                                                                    onMenuItemClicked(subMenuItem);
                                                                }}
                                                            >
                                                                <span className="sidebar-contextual-mini-icon">
                                                                    {subMenuItem.mini}
                                                                </span>
                                                                <span className="sidebar-contextual-normal">
                                                                    {subMenuItem.label}
                                                                </span>
                                                            </NavLink>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Collapse>
                                    </li>

                                )

                            }
                            else {
                                return (
                                    <li className={this.isActiveMenuItem(menuItem.label)} key={key}>
                                        <NavLink
                                            className="nav-link"
                                            // activeClassName = "active"
                                            onClick={() => {
                                                onMenuItemClicked(menuItem);
                                            }}
                                        >
                                            <i className={"now-ui-icons " + menuItem.icon} />
                                            <p>{menuItem.label}</p>
                                        </NavLink>
                                    </li>
                                );
                            }
                        })}
                    </Nav>
                </div>
            </div>
        )

    }

}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        configurations: loopback.configurations
    };
}

var ConfSideMenu = withTranslation()(connect(mapStateToProps)(ConfSideMenuComponent));

export {
    ConfSideMenu,
    defaultMenuItem
}

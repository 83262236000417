import { connect } from 'react-redux';
import React, { Component } from "react";
import ReactTable from "react-table";
import { Card, CardBody, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { PanelHeader } from "components";
import Button from "components/CustomButton/CustomButton.jsx";
import ButtonWithTooltip from '../../components/Custom/ButtonWithTooltip';
import { loopbackActions } from 'actions';
import { loopbackService } from '../../services';
import ErrorModal from "../Components/ErrorModal"
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import { withTranslation } from 'react-i18next';
import { DriverEdit } from './DriverEdit';
import { refreshSearchEngine } from '../../components/Header/Header';
import Documents from '../../components/Documents/Documents';

class Drivers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            error: null,
            filterDriverByName: null,
            isDriverEditModalOpen: false,
            selectedDriverId: 0
        }

        selectedProjectChangeListeners.push(() => {
            this.fetchData();
        });

    }

    componentWillUnmount() {
        // Disable SearchEngine
        refreshSearchEngine();
    }

    componentDidMount() {
        this.fetchData();

        // Change SearchEngine
        refreshSearchEngine((search_value) => {
            this.setState({
                filterDriverByName: search_value
            });
        }, this.props.t("Search a driver"));
    }

    toggleDriverEditModal = (state, driverId = 0) => {
        this.setState({
            isDriverEditModalOpen: (state === true) ? true : false,
            selectedDriverId: (state === true) ? driverId : 0
        }, () => {
            if (state === false) {
                this.fetchData();
            }
        });
    }

    fetchData() {
        const { dispatch } = this.props;
        dispatch(loopbackActions.list('plant', 'drivers'));
    }

    deleteConfirm(id) {
        const { t } = this.props;
        this.setState({
            alert: (
                <Modal isOpen={true} className="modal-umpi-container">
                    <ModalHeader toggle={() => {
                        this.setState({
                            alert: null
                        });
                    }}>
                        {t("Cancellazione driver")}
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ textAlign: "center", fontSize: "8em", color: "rgb(238, 162, 54)" }}>
                            <i className='fa fa-exclamation-circle' />
                        </div>
                        <div style={{ textAlign: "center", fontSize: "2em", margin: "-30px 0 30px 0" }}>
                            {t("Sei sicuro?")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState({
                                alert: null
                            });
                        }} color="default" className="umpi-modal-btn-cancel">
                            {t("Annulla")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button onClick={async () => {
                            const { dispatch } = this.props;
                            if (id) {
                                dispatch(loopbackActions._delete('plant', 'drivers', id));
                                this.setState({
                                    alert: null
                                });
                            }
                        }} color="success" className="umpi-modal-btn-save">
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.drivers && nextProps.drivers.error) {
            this.setState({ error: nextProps.drivers.error });
        }
    }

    buildData() {
        const { t, drivers } = this.props;
        const { filterDriverByName } = this.state;
        let data = [];

        if (drivers && drivers.instances) {
            data = drivers.instances.map((instance, key) => {
                if (filterDriverByName &&
                    !(instance.type && instance.type.toLowerCase().includes(
                        filterDriverByName.toLowerCase()
                    )) &&
                    !(instance.producer && instance.producer.toLowerCase().includes(
                        filterDriverByName.toLowerCase()
                    )) &&
                    !(instance.model && instance.model.toLowerCase().includes(
                        filterDriverByName.toLowerCase()
                    ))) {
                    return null;
                }

                return {
                    index: key,
                    ...instance,
                    documents: (
                        <Documents
                            listMode={true}
                            acceptFile={["pdf", "image"]}
                            loadDocumentsFromCallback={async (resolveDocuments) => {
                                // Get project active
                                let selectedProjectId = localStorage.getItem('selectedProjectId');

                                // Create doc response
                                const resp_documents = await loopbackService.listProjectDocumentFromModel(
                                    selectedProjectId,
                                    "DRIVERS",
                                    instance.id
                                );

                                // Send to component
                                resolveDocuments(resp_documents);
                            }}
                        />
                    ),
                    actions: (
                        <div className="actions-right">
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-edit"
                                id={"editLamp_" + instance.id}
                                onClick={() => this.toggleDriverEditModal(true, instance.id)}
                                size="sm"
                                tooltipText={t("Modifica")}>
                                <i className="fa fa-pencil" />
                            </ButtonWithTooltip>
                            <ButtonWithTooltip
                                className="umpi-modal-action-btn-del"
                                id={"deleteConfirmLamp_" + instance.id}
                                onClick={() => {
                                    this.deleteConfirm(instance.id);
                                }}
                                size="sm"
                                tooltipText={t("Elimina")}>
                                <i className="fa fa-times" />
                            </ButtonWithTooltip>
                        </div>
                    )
                };
            }).filter(x => x);
        }
        return data;
    }

    render() {
        const { error, isDriverEditModalOpen } = this.state;
        const { t, drivers } = this.props;
        let loading = drivers && drivers.loading;
        let data = this.buildData();

        return (
            <div>
                {this.state.alert}
                <PanelHeader size="sm" />
                {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Elenco driver")}</h2>
                            <span className="pull-right ">
                                {this.props.additionalTopButtons}
                                {
                                    !this.props.hideNewButton &&
                                    <Button onClick={() => this.toggleDriverEditModal(true)} color="primary" round size="sm" className="custom-no-margin btn-add-new-users">{t("NewDrivers")}</Button>
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-padding-paper">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        className="-striped -highlight"
                                        previousText={t('Precedente')}
                                        nextText={t('Successiva')}
                                        noDataText={loading ? '' : (isDriverEditModalOpen ? t('Editing in progress') : t('Nessun risultato'))}
                                        pageText={t('Pagina')}
                                        ofText={t('di')}
                                        rowsText={t('righe')}
                                        LoadingComponent={
                                            () => {
                                                if (loading) {
                                                    return (
                                                        <div className="-loading -active">
                                                            <div className="-loading-inner custom-margin-0-auto">
                                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            }
                                        }
                                        columns={[
                                            {
                                                Header: t("Tipo"),
                                                accessor: "type",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Produttore"),
                                                accessor: "producer",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Modello"),
                                                accessor: "model",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                Header: t("Documents"),
                                                accessor: "documents",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            },
                                            {
                                                //Header: () => (<div align="right">{t("Azioni")}</div>),
                                                Header: () => (<div align="right">&nbsp;</div>),
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                resizable: false
                                            }

                                        ]}
                                        defaultPageSize={10}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </div>
                <DriverEdit
                    driverId={this.state.selectedDriverId}
                    isOpenModal={this.state.isDriverEditModalOpen}
                    onModalClose={() => this.toggleDriverEditModal(false)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, loopback } = state;
    return {
        authentication,
        drivers: loopback.drivers
    };
}

const connectedDrivers = withTranslation()(connect(mapStateToProps)(Drivers));
export { connectedDrivers as Drivers };

import { connect } from 'react-redux';
import React from "react";
import { Card, CardBody, Row, Col, Label } from "reactstrap";
import { PanelHeader } from "components";
import { withTranslation } from 'react-i18next';
import ErrorModal from "../Components/ErrorModal"
import { dashboardService, loopbackService } from '../../services';
import { selectedProjectChangeListeners } from '../../components/Header/Header';
import ReactTable from "react-table";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from 'moment';

const formatDate = (momentData) => {
    return momentData.format("YYYY-MM-DD HH:mm:ss");
}

const formatUtcDate = (dateLocal) => {
    return moment(dateLocal).utc().format('YYYY-MM-DD HH:mm:ss')
}

class EventsLogComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            cabinets: [],
            events: [],
            peripherals: [],
            priorities: [],
            selected: {
                startDate: formatDate(moment().subtract(7, "days")),
                endDate: formatDate(moment()),
                cabinets: "",
                peripherals: "",
                events: "",
                priorities: "",
            }
        }

        selectedProjectChangeListeners.push(() => {
            window.location.reload();
        });

        this.formatForSelect = this.formatForSelect.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        try {
            let projectId  = localStorage.getItem('selectedProjectId');
            let urlFilters = await loopbackService.invoke('umpi-smartlighting', 'events', 'filters', projectId);
            let urlData    = await dashboardService.getEventsData(
                projectId,
                formatUtcDate(this.state.selected.startDate),
                formatUtcDate(this.state.selected.endDate),
                this.state.selected.cabinets,
                this.state.selected.events,
                this.state.selected.peripherals,
                this.state.selected.priorities
            );

            if (urlFilters.error) {
                this.setState({ loading: false, errors: [urlFilters.error] });
                return;
            }

            if (urlData.error) {
                this.setState({ loading: false, errors: [urlData.error] });
                return;
            }

            this.setState({
                loading:     false,
                data:        urlData,
                cabinets:    (Array.isArray(urlFilters.cabinets) ? urlFilters.cabinets.map(
                    (data) => this.formatForSelect(data, "cabinets")
                ).filter(x => x) : []),
                events:      (Array.isArray(urlFilters.events) ? urlFilters.events.map(
                    (data) => this.formatForSelect(data, "events")
                ).filter(x => x) : []),
                peripherals: (Array.isArray(urlFilters.peripherals) ? urlFilters.peripherals.map(
                    (data) => this.formatForSelect(data, "peripherals")
                ).filter(x => x) : []),
                priorities:  (Array.isArray(urlFilters.priorities) ? urlFilters.priorities.map(
                    (data) => this.formatForSelect(data, "priorities")
                ).filter(x => x) : [])
            });
        } catch (error) {
            this.setState({ loading: false, errors: [error] })
        }
    }

    formatForSelect(data, type) {
        switch (type) {
            case "cabinets": return {
                "value": data.id,
                "label": data.label
            };
            case "events": return {
                "value": data.code,
                "label": data.code
            };
            case "peripherals": return {
                "value": data.type,
                "label": data.type
            };
            case "priorities": return {
                "value": data.label,
                "label": data.label
            };
            default: return null;
        }
    }

    onSelectChange = (type, event) => {
        let selected = this.state.selected;
        selected[type] = event && event.value ? event.value : "";
        this.setState({ selected }, () => this.fetchData());
    }

    getCellStyleByPriority = (priority) => {
        switch (priority) {
            case "HI":
            case "HIGH": return {color: "#db0000"};
            case "MEDIUM": return {color: "#ff9800"};
            case "LOW": return {color: "#000"};
            case "DEBUG":
            default: return {color: "#aaa"};
        }
    }

    render() {
        const { t } = this.props;
        const { loading, data, selected, cabinets, events, peripherals, priorities, errors } = this.state;
        /*const weeks = ((many) => {
            let tmp = [];
            for (let i = 0; i < many; i++) {
                tmp.push({
                    "value": (i + 1),
                    "label": (i + 1)
                });
            }
            return tmp;
        })(4);*/

        return (
            <div className="EventsLog">
                <PanelHeader size="sm" />
                {errors && errors.length > 0 && <ErrorModal error={errors} onClickHandler={() => { this.setState({ errors: [] }) }} />}

                <div className="content">
                    <Row>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: "-15px", marginTop: "20px" }}>
                            <h2 className="color-blue-umpi selected-item">{t("Events")}</h2>
                        </Col>
                    </Row>
                    <Row style={{ margin: "5px -5px 0px -5px" }}>
                        <Col>
                            <Row>
                                <Col xs={12} md={12} lg={12} style={{ padding: "0 5px" }}>
                                    <Card className="card-padding-paper">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <Label><strong>{t("Start date")}</strong></Label>
                                                    <Datetime
                                                        dateFormat={"YYYY-MM-DD"}
                                                        timeFormat={"HH:mm:ss"}
                                                        utc={true}
                                                        value={selected.startDate}
                                                        onChange={(time) => {
                                                            if (time && time instanceof moment) {
                                                                if (time.isValid()) {
                                                                    this.onSelectChange("startDate", {
                                                                        value: formatDate(time)
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label><strong>{t("End date")}</strong></Label>
                                                    <Datetime
                                                        dateFormat={"YYYY-MM-DD"}
                                                        timeFormat={"HH:mm:ss"}
                                                        utc={true}
                                                        value={selected.endDate}
                                                        onChange={(time) => {
                                                            if (time && time instanceof moment) {
                                                                if (time.isValid()) {
                                                                    this.onSelectChange("endDate", {
                                                                        value: formatDate(time)
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label><strong>{t("Cabinet")}</strong></Label>
                                                    <Select
                                                        className="warning"
                                                        closeOnSelect={true}
                                                        clearable={true}
                                                        placeholder=""
                                                        name="cabinets"
                                                        value={selected.cabinets}
                                                        options={cabinets}
                                                        onChange={(e) => this.onSelectChange("cabinets", e)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label><strong>{t("Peripheral")}</strong></Label>
                                                    <Select
                                                        className="warning"
                                                        closeOnSelect={true}
                                                        clearable={true}
                                                        placeholder=""
                                                        name="peripherals"
                                                        value={selected.peripherals}
                                                        options={peripherals}
                                                        onChange={(e) => this.onSelectChange("peripherals", e)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label><strong>{t("Label")}</strong></Label>
                                                    <Select
                                                        className="warning"
                                                        closeOnSelect={true}
                                                        clearable={true}
                                                        placeholder=""
                                                        name="events"
                                                        value={selected.events}
                                                        options={events}
                                                        onChange={(e) => this.onSelectChange("events", e)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label><strong>{t("Priority")}</strong></Label>
                                                    <Select
                                                        className="warning"
                                                        closeOnSelect={true}
                                                        clearable={true}
                                                        placeholder=""
                                                        name="priorities"
                                                        value={selected.priorities}
                                                        options={priorities}
                                                        onChange={(e) => this.onSelectChange("priorities", e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12} style={{ padding: "0 5px" }}>
                                    <Card className="card-padding-paper">
                                        <CardBody>
                                            <ReactTable
                                                data={Array.isArray(data) ? data.map((item) => {
                                                    item["peripheralFullname"] = (
                                                        '(' +  (item["peripheralType"] ? item["peripheralType"] : "---") +
                                                        '/' +  (item["peripheralCode"] ? item["peripheralCode"] : "---") +
                                                        ') ' + (item["peripheralname"] ? item["peripheralname"] : "---")
                                                    );

                                                    item["eventFullName"] = (
                                                        item["assignedlabel"] ? item["assignedlabel"] : item["eventcode"]
                                                    );

                                                    return item;
                                                }) : []}
                                                className="-striped -highlight mb-3"
                                                previousText={t('Precedente')}
                                                nextText={t('Successiva')}
                                                noDataText={loading ? '' : t('Nessun risultato')}
                                                pageText={t('Pagina')}
                                                ofText={t('di')}
                                                rowsText={t('righe')}
                                                LoadingComponent={
                                                    () => {
                                                        if (loading) {
                                                            return (
                                                                <div className="-loading -active">
                                                                    <div className="-loading-inner custom-margin-0-auto">
                                                                        <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    }
                                                }
                                                columns={[
                                                    {
                                                        Header: t("Date time"),
                                                        accessor: "ts",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            let value = moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{value}</span>
                                                            );
                                                        }
                                                    },
                                                    {
                                                        Header: t("City"),
                                                        accessor: "city",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{row.value}</span>
                                                            );
                                                        }
                                                    },
                                                    {
                                                        Header: t("Cabin"),
                                                        accessor: "cabinetLabel",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{row.value}</span>
                                                            );
                                                        }
                                                    },
                                                    {
                                                        Header: t("Peripheral Name"),
                                                        accessor: "peripheralFullname",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{row.value}</span>
                                                            );
                                                        }
                                                    },
                                                    {
                                                        Header: t("Event Code"),
                                                        accessor: "eventFullName",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{row.value}</span>
                                                            );
                                                        }
                                                    },
                                                    {
                                                        Header: t("Priority"),
                                                        accessor: "priority",
                                                        sortable: false,
                                                        filterable: false,
                                                        resizable: true,
                                                        Cell: (row) => {
                                                            return (
                                                                <span style={this.getCellStyleByPriority(
                                                                    row.original.priority
                                                                )}>{row.value}</span>
                                                            );
                                                        }
                                                    },
                                                ]}
                                                defaultPageSize={10}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const { users, authentication } = state;
    return {
        users,
        loggedinUser: authentication.user
    };
}

const EventsLog = withTranslation()(connect(mapStateToProps)(EventsLogComponent));
export default EventsLog;

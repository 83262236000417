import { connect } from 'react-redux';
import React from "react";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col

} from "reactstrap";
import { PanelHeader, Button } from "components";
import Select from "react-select";
import User from '../../entities/User'
import { loopbackActions } from '../../actions';
import { withTranslation } from 'react-i18next';
import ErrorModal from "../Components/ErrorModal";
import NotificationAlert from "react-notification-alert";

class UserProfile extends React.Component {

    constructor(props) {
        super(props)
        const { t } = props;

        // Languages
        this.languageOptions = [
            { value: 'ca', label: t('i18n_ca') },
            { value: 'cz', label: t('i18n_cz') },
            { value: 'en', label: t('i18n_en') },
            { value: 'fr', label: t('i18n_fr') },
            { value: 'it', label: t('i18n_it') },
            { value: 'sk', label: t('i18n_sk') },
            { value: 'es', label: t('i18n_es') }
        ];

        // User
        this.loggedinUser = User.current();

        // State
        this.state = {
            user: {
                id: '',
                password: '',
                passwordConfirm: '',
                name: '',
                surname: '',
                language: ''
            },
            submitted: false,
            modalNotice: false,
            //emailState: '',
            nameState: '',
            surnameState: '',
            passwordState: '',
            //selectedRoleOption
            selectedLanguage: '',
            password: '',
            passwordConfig: '',
            passwordUpdateButtonDisabled: true
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
    }

    componentWillMount() {
        // const id = this.props.match.params.id
        // this.setState({
        //   addMode: id === 0
        // })
    }

    componentDidMount() {
        const id = this.props.match.params.id
        if (id > 0) {
            this.props.dispatch(loopbackActions.get('admin', 'users', id, { include: 'projects' }));
        }
    }

    notify() {
        const { t } = this.props;
        var options = {}, type = "info";
        options = {
            place: 'tr',
            message: (
                <div>
                    <div>
                        {t("Operazione completata")}
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this.setState({ error: nextProps.error });
        }
        else if (nextProps.user) {

            const { i18n } = this.props;
            const { submitted } = this.state;

            let user = nextProps.user;
            let selectedLanguage = null;
            for (let option of this.languageOptions) {
                if (user.language === option.value) {
                    selectedLanguage = option;
                    break;
                }
            }

            if (submitted) {
                localStorage.setItem('user', JSON.stringify(user));
                i18n.changeLanguage(user.language);
                this.notify();
            }

            this.setState({
                user,
                // selectedRoleOption
                selectedLanguage,
                submitted: false
            });
        }

    }

    handleChange(e) {
        const { name, value } = e.target;
        let user = this.state.user;
        let passwordState = "";
        let passwordUpdateButtonDisabled = true;

        user[name] = value;

        if (name === 'password' || name === 'passwordConfirm') {
            if (value === '' && (name === 'password' ? this.state.user.passwordConfirm : this.state.user.password) === '') {
                passwordState = "";
            }
            else if (value === (name === 'password' ? this.state.user.passwordConfirm : this.state.user.password)) {
                passwordState = "has-success";
                passwordUpdateButtonDisabled = false;
            }
            else {
                passwordState = "has-danger";
            }
        }

        this.setState({ user, passwordState, passwordUpdateButtonDisabled });
    }

    handleChangePassword(e) {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                this.setState({ 'password': value });
                break;
            case 'passwordConfirm':
                this.setState({ 'passwordConfirm': value });
                break;
            default: break;
        }
    }

    async handleSubmit(e) {
        e.preventDefault();

        let { user
            , addMode
            // , selectedRoleOption
            , selectedLanguage
        } = this.state;

        let hasValidationErrors = false;

        // if (user.email === '') {
        //   this.setState({ emailState: 'has-danger' });
        //   hasValidationErrors = true;
        // }

        if (user.name === '') {
            this.setState({ nameState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (user.surname === '') {
            this.setState({ surnameState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (addMode && (user.password === '' || user.password !== user.passwordConfirm)) {
            this.setState({ passwordState: 'has-danger' });
            hasValidationErrors = true;
        }

        if (hasValidationErrors)
            return;

        this.setState({ submitted: true });

        const { dispatch } = this.props;

        // if (selectedRoleOption) {
        //   user.userRoleId = selectedRoleOption.value;
        // }
        // else {
        //   user.userRoleId = roleConstants.GUEST;
        // }

        if (selectedLanguage && user.language !== selectedLanguage.value) {
            user.language = selectedLanguage.value;
        }

        // if (addMode) {
        //   delete user.id;
        //   user.emailVerified = true;
        //   dispatch(userActions.add(user));

        // }
        // else {

        if (user.password === "") {
            delete user.password;
            delete user.passwordConfirm;
        }

        // user.emailVerified = true;
        //dispatch(userActions.edit(user));

        //await dispatch(loopbackActions.edit('admin', 'users', user, 'PATCH'));
        await dispatch(loopbackActions.editOwnInstance('admin', 'users', user, 'PATCH'));
        window.location.reload(); // Force reload langs

        // }
    }

    render() {
        const { loading, user, passwordState, selectedLanguage, error } = this.state;
        const { email, name, surname } = user;
        const { t } = this.props;

        return (
            <div className='UserProfile'>
                <NotificationAlert ref="notificationAlert" />
                <PanelHeader size="sm" />
                <div className="content modal-umpi-container">
                    {error && <ErrorModal error={error} onClickHandler={() => { this.setState({ error: null }) }} />}
                    <Row className="mt-1">
                        <Col md={12} xs={12}>
                            <h2 className="color-blue-umpi selected-item" style={{ marginBottom: '15px' }}>
                                {t("Profilo utente")}
                            </h2>
                            <Card className="info-and-user-card">
                                <CardHeader className="config-card-header sub-header">
                                    <CardTitle className="" >
                                        {/* {t("Profilo utente")} */}
                                        {loading && <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />}
                                    </CardTitle>
                                </CardHeader>
                                <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                                    <CardBody>

                                        <Row>
                                            <Label sm={2}>{t("Email")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className="''">
                                                    <Input
                                                        type="text"
                                                        value={email || ''}
                                                        name='email'
                                                        disabled
                                                        onChange={() => { }}
                                                    // autoComplete="username email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm={2}>{t("Nome")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className={this.state.nameState}>
                                                    <Input
                                                        type="text"
                                                        value={name}
                                                        name='name'
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm={2}>{t("Cognome")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className={this.state.surnameState}>
                                                    <Input
                                                        type="text"
                                                        value={surname}
                                                        name='surname'
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm={2}>{t("Lingua")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className={this.state.language}>

                                                    <Select
                                                        className="primary"
                                                        placeholder="Lingua"
                                                        name="language"
                                                        value={selectedLanguage}
                                                        options={this.languageOptions}
                                                        onChange={option =>
                                                            this.setState({ selectedLanguage: option })
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                        <Label sm={2}>Ruolo</Label>
                                        <Col xs={12} sm={7}>
                                            <FormGroup>
                                            <Select
                                                className="primary"
                                                placeholder="Ruolo"
                                                name="group"
                                                value={selectedRoleOption}
                                                options={this.roleOptions}
                                                onChange={option =>
                                                this.setState({ selectedRoleOption: option })
                                                }
                                            />
                                            </FormGroup>
                                        </Col>
                                        </Row> */}

                                        {/* <Row>
                                        <Label className="col-form-label" sm={2}>Attivo</Label>
                                        <Col xs={12} sm={7}>
                                            <FormGroup style={ { paddingTop: 9}}>
                                            <Switch
                                                onText={<i className="now-ui-icons ui-1_check" />}
                                                offText={
                                                <i className="now-ui-icons ui-1_simple-remove" />
                                                }
                                                onChange={(el, state) => {
                                                user.isActive = state;
                                                this.setState({ user });
                                                } }
                                                value={isActive}
                                            />

                                            </FormGroup>
                                        </Col>
                                        </Row> */}

                                    </CardBody>
                                    <CardFooter className="text-right modal-footer">
                                        <Button color="success" className="umpi-modal-btn-save">
                                            {t("Salva")}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12} xs={12}>
                            <h2 className="color-blue-umpi selected-item" style={{ marginBottom: '15px' }}>
                                {t("Cambio password")}
                            </h2>
                            <Card className="info-and-user-card">
                                <Form className="form-horizontal" onSubmit={this.handleSubmit}>
                                    <CardHeader className="config-card-header sub-header">
                                        <CardTitle>
                                            {/* {t("Cambio password")} */}
                                            {loading && <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm={2}>{t("Nuova password")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className={passwordState}>
                                                    <Input
                                                        type="password"
                                                        name='password'
                                                        onChange={this.handleChange}
                                                    // autoComplete="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm={2}>{t("Conferma password")}</Label>
                                            <Col xs={12} sm={7}>
                                                <FormGroup className={this.state.passwordState}>
                                                    <Input
                                                        type="password"
                                                        name='passwordConfirm'
                                                        onChange={this.handleChange}
                                                    // autoComplete="new-password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right modal-footer">
                                        <Button
                                            color="success"
                                            className="umpi-modal-btn-save"
                                            disabled={this.state.passwordUpdateButtonDisabled}
                                        >
                                            {t("Aggiorna password")}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loopback } = state;
    const { users } = loopback;
    return {
        user: users && users.instance,
        editEnd: users && users.editEnd,
        error: users && users.error
    };
}

const connectedUserProfile = withTranslation()(connect(mapStateToProps)(UserProfile));
export { connectedUserProfile as UserProfile };

import moment from 'moment-timezone';
import suncalc from 'suncalc';

export const utils = {
    deepObjectCompare: function (obj1, obj2) {
        var toString = Object.prototype.toString,
            objectToStringStr = toString.call({});
        var arrayToStringStr = toString.call([]);
        for (var property in obj1) {
            if (!(property in obj2)) {
                // obj2[property] non continene la property
                return false;
            }
            else if (objectToStringStr === toString.call(obj1[property])) {
                // obj1[property] è un oggetto
                if (
                    objectToStringStr !== toString.call(obj2[property])
                    ||
                    !this.deepObjectCompare(obj1[property], obj2[property])
                ) {
                    // obj2[property] non è un oggetto oppure i rami sono diversi
                    return false;
                }
            }
            else if (arrayToStringStr === toString.call(obj1[property])) {
                // obj1[property] è un array
                if (
                    arrayToStringStr !== toString.call(obj2[property])
                    ||
                    !this.deepArrayCompare(obj1[property], obj2[property])
                ) {
                    // obj2[property] non è un array oppure i due array sono diversi.
                    return false;
                }
            }
            else {
                // obj1[property] non è un oggetto e non è un array.
                if (obj1[property] !== obj2[property]) {
                    return false;
                }
            }
        }
        // obj1 è uguale a obj2 (ha le stesse property con gli stessi valori).
        return true;
    },

    deepArrayCompare: function (arr1, arr2) {
        var toString = Object.prototype.toString,
            objectToStringStr = toString.call({});
        var arrayToStringStr = toString.call([]);
        for (var i = 0; i < arr1.length; i++) {
            if (!(i in arr2)) {
                return false;
            }
            else if (objectToStringStr === toString.call(arr1[i])) {
                if (
                    objectToStringStr !== toString.call(arr2[i])
                    ||
                    !this.deepObjectCompare(arr1[i], arr2[i])
                ) {
                    return false;
                }
            }
            else if (arrayToStringStr === toString.call(arr1[i])) {
                if (
                    arrayToStringStr !== toString.call(arr2[i])
                    ||
                    !this.deepArrayCompare(arr1[i], arr2[i])
                ) {
                    return false;
                }
            }
            else {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }
        }
        return true;
    },

    /**
     * Nests the properties of an object using an array of props definitions and defaults.
     * Returns the leaf.
     * Example:
     *
     *      var obj = {};
     *      var nest = [{a: {}}, {b: {}}, {c: {}}, {d: {}}, {e: {}}, {f: {}}, {g: {}}, {h: {}} ];
     *      var leaf = nestedObjectConstructValue(obj, nest);
     *      leaf.i = 'i';
     *      leaf.l = { m: "m" };
     *      JSON.stringify(obj); // {"a":{"b":{"c":{"d":{"e":{"f":{"g":{"h":{"i":"i","l":{"m":"m"}}}}}}}}}}
     *      nest = nest.concat([ {n: {}}, {o: {}}, {p: {}} ]);
     *      var pLeaf = nestedObjectConstructValue(obj, nest);
     *      pLeaf.q = 'q';
     *      nest = nest.concat([ {q: {}} ]);
     *      nestedObjectConstructValue(obj, nest); // 'q'
     *
     * @param Object root
     * @param Array nestedPropsDef
     * @param Boolean isRootArrayIfRootFalsy
     * @returns Anything
     */
    nestedObjectConstructValue: function nestedObjectConstructValue(root, nestedPropsDef, isRootArrayIfRootFalsy) {
        root = root || (isRootArrayIfRootFalsy ? [] : {});
        var leaf = root;
        for (var i = 0; i < nestedPropsDef.length; i++) {
            var propDef = nestedPropsDef[i];
            var propKey = Object.keys(propDef)[0];
            var propDefault = propDef[propKey];
            leaf[propKey] = leaf[propKey] || propDefault;
            leaf = leaf[propKey];
        }
        return leaf;
    },

    isNetworkReachable: function isNetworkReachable() {
        // Handle IE and more capable browsers
        var xhr = new (window.ActiveXObject || XMLHttpRequest)("Microsoft.XMLHTTP");

        // Open new request as a HEAD to the root hostname with a random param to bust the cache
        xhr.open("HEAD", "//" + window.location.hostname +
            (window.location.port !== 80 ? ':' + window.location.port : '') +
            "/?rand=" + Math.floor((1 + Math.random()) * 0x10000), false
        );

        // Issue request and handle response
        try {
            xhr.send();
            return (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304));
        } catch (error) {
            return false;
        }
    },

    isEmpty: function isEmpty(data) {
        return !data || data.length === 0;
    },

    randomDifferentFromValue(value) {
        let ret = Math.random()
        while (value === ret) {
            ret = Math.random()
        }
        return ret
    },

    unshiftArray: function (arr, item) {
        var len = arr.length;
        while (len) { arr[len] = arr[len - 1]; len-- }
        arr[0] = item;
    },

    msToTime: function msToTime(ms) {
        const date = new Date(ms); // Or the date you'd like converted.
        const isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        return isoDate.slice(11, -1);
    },

    cloneDeeply: function (obj) {
        return JSON.parse(JSON.stringify(obj))
    },

    /**
     * Tests whether the given value is a reference type or not.
     *
     * @param {Anything} value Any value which can be an object or a primitive type.
     * @returns {Boolean} True if the given value is a reference type, false otherwise.
     */
    isReferenceType: function (value) {
        return (value === Object(value))
    },

    /**
     * Tests whether the given value is a primitive type or not.
     *
     * @param {Anything} value Any value which can be an object or a primitive type.
     * @returns {Boolean} True if the given value is a primitive type, false otherwise.
     */
    isPrimitiveType: function (value) {
        return (value !== Object(value));
    },

    /**
     * Clones an object deeply and returns the clone.
     *
     * @param {Object} object The object to clone.
     * @returns {Object|undefined} The clone or undefined if even only one property is of an unkown type (this should never happen)
     *                                               or a circular reference is detected.
     */
    cloneObjDeeply: function (object) {
        var newObject = new (object.constructor)();
        for (var prop in object) {
            // If the property is defined on the prototype, ignore it. We don't want to assign it for each clone instance.
            if (!Object.prototype.hasOwnProperty.call(object, prop)) {
                continue;
            }

            var property = object[prop];
            if (this.isPrimitiveType(property)) {
                newObject[prop] = property;
            }
            else if (this.isReferenceType(property)) {
                if (!this.hasCyclicReference(property)) {
                    var clone = this.cloneObjDeeply(property);
                    newObject[prop] = clone;
                }
                else {
                    console.error("Error: circular reference detected inside of property '" + prop + "': ", property, " in object: ", object);
                    return;
                }
            }
            else {
                console.error("Error: Oops! Unknown type for property '" + prop + "': ", property, " in object: ", object);
                return;
            }
        }
        return newObject;
    },

    /**
     * Deep object extension implementation.
     * Nothing is returned, but the destination object will be modified and merged with the source object
     * so that properties of the source object which are objects will recursively merge with the corresponding
     * destination property while the other properties with all the other types will replace the properties of the
     * destination object.
     * Note that this method should not be used for inheritance via the Prototypal Combination Inheritance pattern.
     * Also, this method doesn't perform a deep object cloning, it just extends the destinationObject by adding properties
     * it doesn't have in a deep way.
     *
     * @param {Object} destinationObject The destination object which will be modified and merged with the source object.
     * @param {Object} sourceObject The source object which will be used to extend the destination object.
     * @returns {undefined}
     */
    deepObjectExtend: function (destinationObject, sourceObject) {
        var toString = Object.prototype.toString,
            objectToStringStr = toString.call({});
        for (var property in sourceObject) {
            if (sourceObject[property] && objectToStringStr === toString.call(sourceObject[property])) {
                destinationObject[property] = destinationObject[property] || {};
                this.deepObjectExtend(destinationObject[property], sourceObject[property]);
            } else {
                destinationObject[property] = sourceObject[property];
            }
        }
    },

    /**
     * Deep object cloning extension implementation. If the source objects contain a property with a reference type, a clone object
     * of the same type of that property will be created and then merged with the property object of the destination object.
     *
     * @param {Object} destinationObject The destination object which will be modified and merged with the source object.
     * @param {Object...} sourceObject One or more objects which will be used to extend the destination object.
     * @returns {undefined}
     */
    deepObjectCloningExtend: function (destinationObject, sourceObject /*, sourceObject... */) {
        var toString = Object.prototype.toString,
            objectToStringStr = toString.call({});
        for (var i = 1; arguments[i]; i++) {
            sourceObject = arguments[i];
            for (var property in sourceObject) {
                if (sourceObject[property] && objectToStringStr === toString.call(sourceObject[property])) {
                    destinationObject[property] = destinationObject[property] || {};
                    this.deepObjectExtend(destinationObject[property], this.cloneObjDeeply(sourceObject[property]));
                } else {
                    destinationObject[property] = sourceObject[property];
                }
            }
        }
    },

    /**
     * Checks whether an object has a cyclic reference.
     *
     * @param {Object} obj The object to check for a cyclic reference.
     * @returns {Boolean} True if the object has a cyclic reference, false otherwise.
     */
    hasCyclicReference: function (obj) {
        var stackSet = [];
        var detected = false;

        function detect(obj) {
            if (detected) {
                return;
            }

            if (typeof obj !== 'object') {
                return;
            }

            var indexOfObj = stackSet.indexOf(obj);
            if (indexOfObj !== -1) {
                detected = true;
                return;
            }

            stackSet.push(obj);
            for (var k in obj) {
                if (obj.hasOwnProperty(k)) {
                    detect(obj[k], k);
                }
            }

            stackSet.splice(indexOfObj, 1);
            return;
        }
        detect(obj, 'obj');
        return detected;
    },

    cloneArray: function (arr) {
        return arr.slice(0)
    },

    arraySliceFromTo: function (arr, from, to) {
        const ret = []
        let include = false
        for (const value of arr) {
            if (!include && value === from) {
                ret.push(value)
                include = true
            }
            else if (include && value === to) {
                ret.push(value)
                break
            }
            else if (include) {
                ret.push(value)
            }
        }
        return ret
    },

    /**
     * Like Array.prototype.includes, but with type coercion.
     *
     * @param {Array} The array
     * @param {Anything} The value
     * @returns {boolean} True if the value is included within the array (checking with type coercion`==`)
     */
    includesTypeCoercion: function (array, value) {
        for (const valueOfArray of array) {
            if (valueOfArray === value) {
                return true
            }
        }
        return false
    },

    /**
     * Tests to see whether something is an array.
     *
     * @param {Anything} something A variable to check wether it is an array.
     * @returns {boolean} True if the passed in parameter is an array, false otherwise.
     */
    isArray: function (something) {
        return (Object.prototype.toString.call(something) === Object.prototype.toString.call([]));
    },

    /**
     * Determina il zoom level migliore per una mappa Google dati i parametri
     * `bounds` (google.maps.LatLngBounds) e `mapDim` (oggetto JS avente height e width: { height: xxx, width: yyy };
     * dove `xxx` indica l'altezza del div che contiene la mappa e `yyy` indica la larghezza del div che contiene la mappa).
     */
    googleMapBestZoomLevelFromBounds: function (bounds, mapDim) {
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21;

        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }

        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    },

    doIndex: function (elems, key) {
        let ret = {};
        if (!elems)
            return ret;
        for (let i = 0; i < elems.length; i++) {
            let elem = elems[i];
            ret[elem[key]] = i;
        }
        return ret;
    },

    cyrb53: function (str, seed = 0) {
        var h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (var i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16, 2246822507)) ^ Math.imul(h2 ^ (h2 >>> 13, 3266489909));
        h2 = Math.imul(h2 ^ (h2 >>> 16, 2246822507)) ^ Math.imul(h1 ^ (h1 >>> 13, 3266489909));
        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },

    /**
     * Restituisce il progetto selezionato, oppure il primo della lista
     * se non ci sono progetti selezionati.
     * Recupera da localStorage l'elenco dei progetti.
     */
    getSelectedProject: function () {
        const projects = JSON.parse(localStorage.getItem('projects'));
        let selectedProjectId = localStorage.getItem('selectedProjectId');
        let selectedProject = null;
        if (projects) {
            for (let prj of projects) {
                if (prj.id === parseInt(selectedProjectId)) {
                    selectedProject = prj;
                    break;
                }
            }
            if (selectedProject === null) {
                if (projects.length > 0) {
                    selectedProject = projects[0];
                    //localStorage.setItem('selectedProjectId', selectedProject.id);
                }
            }
        }
        return selectedProject;
    },

    getSelectedOption(options, value) {
        if (value === null)
            return null;
        if (options === null)
            return null;
        for (let option of options) {
            if (option.value === value) {
                return option;
            }
        };
        return null;
    },

    /*
      *    Restituisce i valori minimi e massimi di alba e tramonto nell'anno corrente.
      **/
    getSun: function (lat, lng, timezone) {

        var year = moment().year();
        var newDate = moment(year + '-01-01', "YYYY-MM-DD");

        let sun = {
            minRise: "06:00",
            maxRise: "06:00",
            minSet: "18:00",
            maxSet: "18:00"
        }

        for (let i = 0; i < 365; i++) {
            var times = suncalc.getTimes(newDate.toDate(), lat, lng);
            //M.C. let sunrise = moment(times.sunrise).format('HH:mm');
            //M.C. let sunset = moment(times.sunset).format('HH:mm');
            let sunrise = moment(times.sunrise).tz(timezone).format('HH:mm');
            let sunset = moment(times.sunset).tz(timezone).format('HH:mm');
            if (sunrise > sun.maxRise)
                sun.maxRise = sunrise;
            if (sunrise < sun.minRise)
                sun.minRise = sunrise;
            if (sunset > sun.maxSet)
                sun.maxSet = sunset;
            if (sunset < sun.minSet)
                sun.minSet = sunset;
            newDate.add(1, 'days');
        }
        return sun;
    },

    bitTest(num, bit) {
        return ((num >> bit) % 2 !== 0);
    },

    bitSet(num, bit) {
        return num | (1 << bit);
    },

    bitClear(num, bit) {
        return num & ~(1 << bit);
    },

    toSession(userid, projectid, payload) {
        localStorage.setItem("session_" + userid + "_" + projectid, JSON.stringify(payload));
    },

    fromSession(userid, projectid) {
        let str = localStorage.getItem("session_" + userid + "_" + projectid);
        let payload = {};
        if (str) {
            payload = JSON.parse(str);
        }
        return payload;
    },

    formatAbsMin(absmin) {
        let hours = ("" + Math.floor(absmin / 60)).padStart(2, "0");
        let mins = ("" + (absmin % 60)).padStart(2, "0");
        // if (absmin===1440){
        //     return " 00:00";
        // }
        // else {
        return " " + hours + ":" + mins;
        // }
    },

    toAbsMinutes(time) {
        let timeAr = time.split(":");
        return parseInt(timeAr[0], 10) * 60 + parseInt(timeAr[1], 10);
    }

};

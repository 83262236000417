import React from "react";
import { connect } from 'react-redux';
import {
    Navbar,
    Container,
    InputGroup,
    InputGroupAddon,
    Input
} from "reactstrap";

import Select from "react-select";
import dashboardRoutes from "routes/dashboard.jsx";
import { projectActions } from "../../actions";
import { withTranslation } from 'react-i18next';
import { loopbackService } from "../../services";
import Alarms from "./Alarms";
import NotificationAlert from "react-notification-alert";
import { loopbackConstants } from '../../constants';
import moment from 'moment';
import User from '../../entities/User';
import { accessToken } from '../../helpers';

let selectedProjectChangeListeners = [];
const DateFormat = "DD/MM/YYYY";
const TimeFormat = "HH:mm";

let fireProjectsChanged = function () {
    try {
        this.onProjectsChanged();
    }
    catch (e) { /* DO NOTHING */ }
}

let refreshHeader = function (projects) {
    try {
        this.onRefreshHeader(projects);
    }
    catch (e) { /* DO NOTHING */ }
}

let refreshSearchEngine = function (cbSearch = null, cbTitle = null) {
    try {
        this.onSearchEngineChanged(cbSearch, cbTitle);
    }
    catch (e) { /* DO NOTHING */ }
}

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            dropdownOpen: false,
            color: "transparent",
            projectOptions: [],
            selectedProject: null,
            alarmsCount: 0,
            showAlarms: false,
            ignoreAlarmsUpdate: false,
            searchEntityValue: "",
            searchEngineTitle: "",
            searchEngineCb: null
        };

        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.handleChangeProject = this.handleChangeProject.bind(this);

        fireProjectsChanged = fireProjectsChanged.bind(this);
        refreshHeader = refreshHeader.bind(this);
        refreshSearchEngine = refreshSearchEngine.bind(this);
    }

    componentWillMount() {
        //let projects = JSON.parse(localStorage.getItem('projects'));
        this.buildProjectOptions();
    }

    onRefreshHeader() {
        this.buildProjectOptions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));

        this.countAlarms();
        this.startStreaming();
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.refs.sidebarToggle.classList.toggle("toggled");
        }

        if (this.props.location.pathname !== e.location.pathname) {
            this.countAlarms();
        }
    }

    onSearchEngineChanged(cbSearch, cbTitle) {
        this.setState({
            searchEngineCb: cbSearch,
            searchEngineTitle: cbTitle ? cbTitle : this.props.t('Type for search')
        }, () => {
            console.warn("Search engine changed!")
        })
    }

    onProjectsChanged() {
        projectActions.getAll();
    }

    async countAlarms() {
        //const { dispatch } = this.props;
        let selectedProjectId = localStorage.getItem('selectedProjectId');
        try {
            let res = await loopbackService.count('plant', 'alarms', { ackByUserId: null }, selectedProjectId);
            if (res && res.count) {
                this.setState({ alarmsCount: res.count });
            }
            else {
                this.setState({ alarmsCount: 0 });
            }
        } catch (error) {
            console.error(error);
        }

    }

    buildProjectOptions() {
        let projectOptions = [];
        let selectedProjectId = localStorage.getItem('selectedProjectId');
        let selectedProject = null;
        let user = User.current();

        let prjs = localStorage.getItem('projects');
        let projects = prjs && JSON.parse(prjs);

        if (projects) {

            projects.map((project) => {
                let skip = true;
                if (project.users) {
                    for (let prjUser of project.users) {
                        if (user && prjUser.id === user.id) {
                            skip = false;
                            break;
                        }
                    }
                }

                if (!skip) {
                    let cur = {
                        value: project.id, label: project.name
                    };
                    if (!selectedProjectId) {
                        selectedProjectId = project.id;
                        localStorage.setItem('selectedProjectId', selectedProjectId);
                        selectedProjectChangeListeners.map((listener) => {
                            listener();
                            return false;
                        });
                    }
                    if (+project.id === +selectedProjectId) {
                        selectedProject = cur;
                    }
                    projectOptions.push(cur);
                }
                return false;
            });
        }
        this.setState({ projectOptions, selectedProject });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.projects && nextProps.projects.items) {
            //this.buildProjectOptions(nextProps.projects.items);
            this.buildProjectOptions();
        }
    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "white"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    dropdownToggle(e) {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getBrand() {

        const { t } = this.props;

        var name, module;

        dashboardRoutes.map((prop, key) => {
            let a = prop.path.split('/'), b = this.props.location.pathname.split('/');
            if (prop.collapse) {
                prop.views.map((prop, key) => {
                    let a = prop.path.split('/'), b = this.props.location.pathname.split('/');
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }
                    else if (a[1] === b[1] && prop.module) {
                        module = prop.module;
                    }

                    return null;
                });
            } else {
                if (prop.redirect) {
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }
                } else {
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }

                    else if (a[1] === b[1] && prop.module) {
                        module = prop.module;
                    }
                }
            }
            return null;
        });

        if (!name) {
            name = module;
        }

        return t(name);
    }
    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        if (this.refs.sidebarToggle) {
            this.refs.sidebarToggle.classList.toggle("toggled");
        }
    }
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "white"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    async handleChangeProject(item) {
        if (item === null) {
            return;
        }

        localStorage.setItem('selectedProjectId', item.value);

        this.countAlarms();

        selectedProjectChangeListeners.map((listener) => {
            listener();
            return false;
        });

        let projects = JSON.parse(localStorage.getItem('projects'));

        for (const project of projects) {
            if (project.id === item.value) {
                this.setState({ selectedProject: item });
                break;
            }
        }
    }

    searchEntityOnChange = (ev) => {
        this.setState({
            searchEntityValue: ev.target.value
        });
    }

    searchEntityOnKeyUp = async (ev) => {
        const searchValue = (
            this.state.searchEntityValue &&
                typeof this.state.searchEntityValue === "string" &&
                this.state.searchEntityValue.trim().length > 0 ?
                this.state.searchEntityValue.trim() :
                null
        );

        if (ev.key === 'Enter' || searchValue === null) {
            const searchEngine = this.state.searchEngineCb;

            if (searchEngine) {
                searchEngine(searchValue);
            }
        }
    }

    render() {
        const { t } = this.props;
        let { searchEngineCb, projectOptions, selectedProject, alarmsCount, showAlarms } = this.state;

        //let showSelectedProject = (this.props.location.pathname!='/projects');
        const enabledViews = [
            '/zones-navigator',
            '/plants-navigator',
            '/timerprofiles',
            '/luminares',
            '/lamps',
            '/supports',
            '/drivers',
            '/devices',
            '/events_log',
            '/stats'
        ];

        let showSelectedProject = (enabledViews.includes(this.props.location.pathname));

        return (
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                color="white"
                expand="lg"
                className={
                    this.props.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute fixed-top"
                        : "navbar-absolute fixed-top "
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref="sidebarToggle"
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}>
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>
                    </div>

                    {showSelectedProject &&
                        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }} className="justify-content-start">
                            <Select
                                className="no-border project-select umpi-header-sel-project"
                                placeholder={t("Select a project")}
                                name="project_id"
                                closeOnSelect={true}
                                clearable={false}
                                options={projectOptions}
                                value={selectedProject}
                                onChange={value => this.handleChangeProject(value)}
                            />
                            <div className="badge-notify-alert-icon" onClick={() => this.setState({ showAlarms: true })}>
                                <i className="fa fa-bell" />
                                <span className="badge badge-primary">
                                    {alarmsCount > 0 ? alarmsCount : ""}
                                </span>
                            </div>
                            <NotificationAlert ref="notificationAlert" />
                            {showAlarms &&
                                <Alarms
                                    handleAlarmsClose={() => this.setState({ showAlarms: false })}
                                />
                            }
                        </div>
                    }

                    {
                        searchEngineCb ?
                            <InputGroup size="md" className={"header-project-search" + (
                                this.state.searchEntityValue &&
                                    typeof this.state.searchEntityValue === "string" &&
                                    this.state.searchEntityValue.length > 0
                                    ? " expanded" : ""
                            )}>
                                <InputGroupAddon className="header-project-search-icon">
                                    <i className="fa fa-search" />
                                </InputGroupAddon>
                                <Input className="header-project-search-input"
                                    type="text"
                                    name="username"
                                    placeholder={this.state.searchEngineTitle}
                                    value={this.state.searchEntityValue}
                                    onChange={this.searchEntityOnChange}
                                    onKeyUp={this.searchEntityOnKeyUp}
                                />
                            </InputGroup>
                            : null
                    }

                    {/*<a
                        href="https://www.umpi.it/"
                        target="_blank"
                        className="simple-text logo-mini"
                    >
                        <div className="">
                        <img src={logoUmpi} style={{ marginLeft: 20, maxWidth: 70, width: 70, height: 20 }} alt="UMPI-logo" />
                        </div>
                    </a>*/}

                </Container>
            </Navbar>
        );
    }

    getEventLabel(alarm) {
        const { t } = this.props;
        let text = alarm.ts ? moment(alarm.ts, moment.defaultFormat).format(DateFormat + " " + TimeFormat) + ": " : "";
        text += alarm.sourcePeriferalType ? alarm.sourcePeriferalType + " / " : "";
        text += alarm.sourcePeriferalCode ? alarm.sourcePeriferalCode + " - " : "";
        text += alarm.eventLabel;
        if (alarm.deleted) {
            text += " - " + t("Eliminato");
        }
        else if (alarm.tsAck) {
            text += " - " + t("Riconosciuto");
        }
        return text;
    }

    startStreaming() {

        //console.log('Avvio streaming allarmi....');
        const selectedProjectId = localStorage.getItem("selectedProjectId");
        const filter = { where: { projectId: selectedProjectId } };
        const options = encodeURIComponent(JSON.stringify(filter))

        //let urlToChangeStream = loopbackConstants.BASE_URL + '/plant/alarms/change-stream?options=' + options;
        let urlToChangeStream = loopbackConstants.BASE_URL + '/plant/alarms/change-stream/' + accessToken('plant') + '&options=' + options;

        this.eventSource = new EventSource(urlToChangeStream);
        this.eventSource.addEventListener('data', (evt) => {
            var alarm = JSON.parse(evt.data);
            this.notify(alarm.data);
            this.countAlarms();
        });

    }

    stopStreaming() {
        //console.log('Stop streaming...');
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    notify(alarm) {
        let text = this.getEventLabel(alarm);
        var options = {};
        options = {
            place: "tr",
            message: (
                <div>
                    <div>
                        {text}
                    </div>
                </div>
            ),
            type: alarm.tsAck ? 'success' : 'danger',
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    }

}

function mapStateToProps(state) {
    const { authentication, projects } = state;
    return {
        authentication,
        projects
    };
}

const Header = withTranslation()(connect(mapStateToProps)(HeaderComponent));
export default Header;
export { fireProjectsChanged, refreshHeader, refreshSearchEngine, selectedProjectChangeListeners };

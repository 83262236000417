import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import React from 'react';
import { classnames } from './helpers';

import "./component.css";

class LocationSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            errorMessage: '',
            latitude: null,
            longitude: null,
            isGeocoding: false,
        };

        this.handleSelect = this.handleSelect.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(address) {
        this.setState({
            address,
            latitude: null,
            longitude: null,
            errorMessage: '',
        })
    }

    handleSelect(address) {
        this.setState({ isGeocoding: true, address: address, errorMessage: '' });

        const onLatLng = this.props.onLatLng || ((latLng, results) => { console.info('Success', latLng, results) })
        const onErr = this.props.onError || ((error) => { console.error('Error', error) })
        let resultsReference = null

        geocodeByAddress(address)
            .then((results) => {
                resultsReference = results
                return getLatLng(results[0])
            })
            .then((latLng) => {
                this.setState({
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                    isGeocoding: false,
                }, () => {
                    onLatLng(latLng, resultsReference)
                });
            })
            .catch(error => {
                this.setState({ isGeocoding: false });
                onErr(error)
                console.error('Error', error)
            })
    }

    handleCloseClick = () => {
        const onLatLng = this.props.onLatLng || ((latLng, results) => { console.info('Success', latLng, results) })
        this.setState({
            address: '',
            latitude: null,
            longitude: null,
            errorMessage: ''
        }, () => {
            onLatLng({ lat: null, lng: null }, [])
        });
    };

    handleError = (status, clearSuggestions) => {
        console.error('Error from Google Maps API', status);
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    render() {
        const placeholder = this.props.placeholder || 'Search Places ...'

        const {
            address,
            errorMessage,
            isGeocoding,
        } = this.state;

        const loadingComponent = this.props.loadingComponent || (
            <div className="ReactPlacesAutocomplete__loading-component -loading -active custom-position-relative custom-min-height-40">
                <div className="-loading-inner custom-margin-0-auto">
                    <img alt="" className="custom-position-absolute-center-horizontally custom-position-absolute-center-vertically custom-center-vertically-horizontally custom-position-absolute"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
            </div>
        )

        return (
            <div>
                <PlacesAutocomplete
                    onChange={this.handleChange}
                    value={address}
                    onSelect={this.handleSelect}
                    onError={this.handleError}
                    shouldFetchSuggestions={address.length > 2}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        return (
                            <div className="ReactPlacesAutocomplete__search-bar-container">
                                <div className="ReactPlacesAutocomplete__search-input-container">
                                    <input
                                        {...getInputProps({
                                            placeholder,
                                            className: 'ReactPlacesAutocomplete__search-input',
                                        })}
                                        style={this.props.style ? this.props.style : {}}
                                    />
                                    {this.state.address.length > 0 && (
                                        <button
                                            className="ReactPlacesAutocomplete__clear-button"
                                            onClick={this.handleCloseClick}
                                        >
                                            <i className="fa fa-times" />
                                        </button>
                                    )}
                                </div>
                                {(suggestions.length > 0 || isGeocoding || errorMessage.length > 0) && (
                                    <div className={"ReactPlacesAutocomplete__autocomplete-container" + (errorMessage.length > 0 ? " ReactPlacesAutocomplete__autocomplete-container-has-error" : "")}>
                                        {isGeocoding && loadingComponent}
                                        {errorMessage.length > 0 && !isGeocoding && !(suggestions.length > 0) && (
                                            <div className="ReactPlacesAutocomplete__error-message">{this.props.customErrorMessagForStatus ? this.props.customErrorMessagForStatus(this.state.errorMessage) : this.state.errorMessage}</div>
                                        )}
                                        {!isGeocoding && suggestions.length > 0 && suggestions.map(suggestion => {
                                            const className = classnames('ReactPlacesAutocomplete__suggestion-item', {
                                                'ReactPlacesAutocomplete__suggestion-item--active': suggestion.active,
                                            });

                                            return (
                                                /* eslint-disable react/jsx-key */
                                                <div {...getSuggestionItemProps(suggestion, { className })} key={`key-suggestion-item-${suggestion.index}`}>
                                                    <strong>
                                                        {suggestion.formattedSuggestion.mainText}
                                                    </strong>{' '}
                                                    <small>
                                                        {suggestion.formattedSuggestion.secondaryText}
                                                    </small>
                                                </div>
                                            );
                                            /* eslint-enable react/jsx-key */
                                        })}
                                        <div className="ReactPlacesAutocomplete__dropdown-footer">
                                            {this.props.dropdownFooterComponent}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
            </div>
        );
    }
}

export default LocationSearchInput;
